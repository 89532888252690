<!-- 指令案例提示 -->
<template>
<article :style="{width: size === 'small' ? '100%' : '900px'}" :class="['yxtbiz-ai-robot-guess-prompt', size === 'small'? 'font-size-12' : 'font-size-14']"> 
  <simpleAnswer :msg="getMsg" />
  <template v-if="!msg.isHistory && isShow">
    <header class="mt20">{{$t('pc_biz_lbl_guess_want_ask').d('猜测你可能想问：')}}</header>
    <main>
      <ul class="yxtbiz-ai-robot-guess-prompt__examples">
        <li v-for="(item, index) in getContent" :key="index" @click="handleExampleClick(item)" :class="['yxtbiz-ai-robot-guess-prompt__examples-item',`yxtbiz-ai-robot-guess-prompt__examples-item--${size}`]">
          <div class="ellipsis-2">
            {{item}}
          </div>
        </li>
      </ul>
    </main>
  </template>
</article>
</template>

<script>
import simpleAnswer from './simpleAnswer.vue';

export default {
  components: {
    simpleAnswer
  },
  props: {
    msg: {
      type: Object,
      required: true,
      default: () => ({})
    },
    size: {
      type: String,
      default: 'small',
      validator: val => ['small', 'large'].includes(val)
    }
  },
  data() {
    return {
      examples: [],
      isShow: true
    };
  },
  computed: {
    getMsg() {
      return {
        contentDetail: {content: this.$t('pc_biz_msg_sorry_help_info').d('很抱歉，我没有理解您的意思。请您重新表述您的问题或提供更多背景信息，以便我更好地帮助您？')}
      };
    },
    getContent() {
      return JSON.parse(this.msg.contentDetail && this.msg.contentDetail.content || '[]');
    }
  },
  methods: {
    handleExampleClick(example) {
      this.isShow = false;
      this.$emit('PromotClick', example, this.msg.bizAppId || '', 0);
    }
  }
};
</script>

<style lang="scss" scoped>
.yxtbiz-ai-robot-guess-prompt {
  header {
    color: #8C8C8C;
  }
  &__examples {
    display: flex;
    flex-wrap: wrap;
    &-item {
      color: #757575;
      line-height: 22px;
      padding: 5px 16px;
      background: #FFFFFF;
      border-radius: 16px;
      margin-right: 12px;
      margin-top: 12px;
      font-size: 14px;
      word-break: break-all;
      cursor: pointer;

      &:last-of-type {
        margin-right: 0;
      }
      &--small {
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
}
</style>
