var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.type === "createCourse" ||
    _vm.type === "createCourseware" ||
    _vm.type === "uploadKnowledge"
    ? _c("click-to-jump", {
        attrs: {
          datas: _vm.datas,
          message: _vm.message,
          targetUrl: _vm.targetUrl,
        },
      })
    : _vm.type === "createSpace"
    ? _c(
        "div",
        {
          staticClass: "ph12",
          class: _vm.size === "small" ? "standard-size-14" : "standard-size-16",
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.$t("pc_biz_lbl_createspace")))]),
          _c(
            "yxt-button",
            {
              staticClass: "ml8",
              attrs: { type: "text" },
              on: { click: _vm.toDetail },
            },
            [_vm._v(_vm._s(_vm.$t("pc_biz_bbs_lbl_viewDetail")))]
          ),
        ],
        1
      )
    : !_vm.isEmptyData
    ? _c(
        "div",
        { staticClass: "yxtbiz-ai-robot__kng standard-size-14" },
        [
          _c(
            "div",
            {
              staticClass: "mb12 ml12 color-gray-7",
              class:
                _vm.size === "small" ? "standard-size-14" : "standard-size-16",
            },
            [_vm._v(_vm._s(_vm.title))]
          ),
          _vm.type === "searchNote"
            ? [
                _c("div", { staticClass: "mt4 mb4 ph12 yxtbiz-flex-center" }, [
                  _c(
                    "div",
                    {
                      staticClass: "yxtbiz-ai-robot__kng-tab",
                      class:
                        _vm.noteTab === 0
                          ? "yxtbiz-ai-robot__kng-tab--active"
                          : "",
                      on: {
                        click: function ($event) {
                          _vm.noteTab = 0
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("pc_biz_lbl_mypublish" /** 我发布的 */))
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "yxtbiz-ai-robot__kng-tab ml12",
                      class:
                        _vm.noteTab === 1
                          ? "yxtbiz-ai-robot__kng-tab--active"
                          : "",
                      on: {
                        click: function ($event) {
                          _vm.noteTab = 1
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("pc_biz_lbl_mypraise" /** 我点赞的 */))
                      ),
                    ]
                  ),
                ]),
                _vm._l(_vm.dataList, function (item) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      staticClass: "yxtbiz-ai-robot__kng-note-cell",
                      on: {
                        click: function ($event) {
                          return _vm.toDetail(item)
                        },
                      },
                    },
                    [
                      _c("yxtf-portrait", {
                        staticClass: "mr12 flex-shrink-0",
                        attrs: {
                          size: "48px",
                          imgUrl: item.userUrl,
                          username: item.fullName,
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "flex-1 width60" },
                        [
                          _c(
                            "div",
                            { staticClass: "yxtbiz-flex-center over-hidden" },
                            [
                              _c("yxtbiz-user-name", {
                                staticClass: "yxt-weight-5 ellipsis",
                                attrs: { name: item.fullName },
                              }),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "color-gray-7 standard-size-12 ml12 ellipsis",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.handleDateString(item.createTime)
                                    )
                                  ),
                                ]
                              ),
                              item.featured
                                ? _c(
                                    "yxt-tag",
                                    {
                                      staticClass: "ml4",
                                      attrs: { type: "warning", size: "mini" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "yxtbiz_ai_comments_lbl_excellent" /** 精选 */
                                          )
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              item.reported
                                ? _c(
                                    "yxt-tag",
                                    {
                                      staticClass: "ml4",
                                      attrs: { type: "danger", size: "mini" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "yxtbiz_ai_mgmt_lbl_status_report" /** 被举报 */
                                          )
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              item.privat
                                ? _c(
                                    "yxt-tag",
                                    {
                                      staticClass: "ml4",
                                      attrs: { type: "info", size: "mini" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "pc_biz_lbl_private" /** 私密 */
                                          )
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("note-item-cell", {
                            attrs: {
                              isInCourse: item.kngType === _vm.KNG_TYPE.COURSE,
                              note: item,
                              effect: "light",
                            },
                          }),
                          item.kngName
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "mt8 color-gray-7 standard-size-12",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("pc_biz_lbl_coursename", [
                                        item.kngName,
                                      ])
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                _vm.dataList.length === 0
                  ? _c("yxt-empty", {
                      attrs: { "empty-text": _vm.$t("pc_biz_lbl_nonotedata") },
                    })
                  : _vm._e(),
              ]
            : _vm._l(_vm.dataList, function (item) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "yxtbiz-ai-robot__kng-cell",
                    on: {
                      click: function ($event) {
                        return _vm.toDetail(item)
                      },
                    },
                  },
                  [
                    item.coverUrl
                      ? _c("yxt-image", {
                          staticClass: "yxtbiz-ai-robot__kng-cover",
                          attrs: { src: item.coverUrl, fit: "cover" },
                        })
                      : _vm._e(),
                    _c("div", { staticClass: "ml12 flex-1 over-hidden" }, [
                      _c(
                        "div",
                        {
                          staticClass: "ellipsis-2 break",
                          class:
                            _vm.size === "small"
                              ? "standard-size-14"
                              : "standard-size-16",
                        },
                        [
                          _vm.type === "selfCourse" && item.leaderRecommend
                            ? _c(
                                "yxt-tag",
                                {
                                  staticClass: "mr8",
                                  attrs: { type: "warning", size: "mini" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("pc_kng_studyplan_lbl_leadre")
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(_vm._s(item.title)),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "kng-flex-center kng-flex-wrap",
                          class: _vm.size === "small" ? "mt4" : "mt8",
                        },
                        [
                          _vm.type === "selfCourse"
                            ? [
                                item.schedule < 100
                                  ? _c(
                                      "div",
                                      { staticClass: "w155 kng-flex-center" },
                                      [
                                        _c("yxt-progress", {
                                          staticClass: "flex-1",
                                          attrs: {
                                            percentage: item.schedule,
                                            size: "small",
                                            "show-text": false,
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "ml8 standard-size-12 color-gray-8",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "pc_kng_plancard_lbl_have_leaned" /** 已学{0}% */,
                                                  [parseInt(item.schedule || 0)]
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      { staticClass: "kng-flex-center" },
                                      [
                                        _c("yxtf-svg", {
                                          attrs: {
                                            width: "16px",
                                            height: "16px",
                                            "icon-class":
                                              "biz/result-success-icon",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "ml4 standard-size-12 color-gray-8",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "pc_kng_study_lbl_have_leaned" /** 已完成学习 */
                                                  )
                                                )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                _vm.size !== "small"
                                  ? _c("yxt-divider", {
                                      attrs: { direction: "vertical" },
                                    })
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "standard-size-12 color-gray-7",
                                    class:
                                      _vm.size === "small"
                                        ? "mt2 width-percent-100"
                                        : "",
                                  },
                                  [_vm._v(_vm._s(_vm.getPlanTime(item)))]
                                ),
                              ]
                            : [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "standard-size-12 color-gray-7 mr20 kng-inline-flex-center over-hidden",
                                  },
                                  [
                                    _c("yxtbiz-user-name", {
                                      staticClass: "ellipsis-1",
                                      attrs: { name: item.contributorsName },
                                    }),
                                    _c("yxt-divider", {
                                      attrs: { direction: "vertical" },
                                    }),
                                    _c("span", { staticClass: "kng-no-wrap" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "pc_kng_common_lbl_update_time",
                                            [_vm.getTimeString(item.updateTime)]
                                          )
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "standard-size-12 color-gray-7 ellipsis-1 kng-no-wrap",
                                    class:
                                      _vm.size === "small"
                                        ? "mt2 width-percent-100"
                                        : "",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("pc_biz_lbl_studycount", [
                                          item.studyCount,
                                        ])
                                      )
                                    ),
                                  ]
                                ),
                              ],
                        ],
                        2
                      ),
                    ]),
                  ],
                  1
                )
              }),
          _vm.showMore
            ? _c(
                "div",
                {
                  staticClass: "yxtbiz-ai-robot__kng-more color-gray-8",
                  on: { click: _vm.toMore },
                },
                [
                  _vm._v(
                    "\n    " +
                      _vm._s(_vm.$t("pc_biz_lbl_common_viewmore")) +
                      "\n    "
                  ),
                  _c("yxt-svg", {
                    attrs: {
                      "remote-url": `${_vm.$staticBaseUrl}ufd/55a3e0`,
                      width: "16px",
                      height: "16px",
                      "icon-class": "arrow_right",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      )
    : _c("empty-data")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }