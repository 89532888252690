<template>
  <section class="yxtbiz-mport-proc">
    <ul class="yxt-upload-explain-list">
      <li>
        <span class="yxt-upload-explain-num color-gray-8">1</span>
        <div class="standard-size-14 color-gray-10 yxt-upload-explain-title">
          {{$t('pc_biz_import_tip_clickdownload'/* 点击“下载”按钮下载需要导入的模板文件。 */)}}
        </div>
        <div class="yxt-upload-explain-summary color-gray-8">
          <span class="color-red-6">{{$t('pc_biz_import_tip_readme'/* 请仔细阅读模板中的说明， */)}}</span>{{$t('pc_biz_import_tip_improtformat'/* 并按要求格式填写导入的数据。 */)}}
        </div>
        <yxt-button
          class="mt16"
          type="primary"
          @click="downloadExcel"
        >
          {{$t('pc_biz_import_btn_download'/* 下载 */)}}
        </yxt-button>
      </li>
      <li>
        <span class="yxt-upload-explain-num color-gray-8">2</span>
        <div class="standard-size-14 color-gray-10 yxt-upload-explain-title">
          {{$t('pc_biz_import_tip_uploadmethod'/* 完成文件的填写后，点击或将文件拖拽到上传区域进行上传。 */)}}
        </div>
        <div class="yxt-upload-explain-summary color-gray-8">
          {{$t('pc_biz_import_tip_uploadverify'/* 系统会校验填写数据的正确性，并提示错误的数据；在校验无错误数据的情况下，系统将数据导入到系统中。 */)}}
        </div>
      </li>
    </ul>
    <div class="yxt-upload-container">
      <slot
        v-if="stateType<importState.uploaded"
        name="upload"
      />
      <template v-else-if="stateType===importState.uploaded && $slots.uploaded">
        <slot name="uploaded" />
      </template>
      <ul
        v-else
        class="yxt-upload-item-list"
      >
        <li class="is-completed color-primary-6">
          <span class="yxt-upload-status" />
          <yxt-row
            type="flex"
            align="middle"
            class="color-gray-10 yxt-upload-item-title"
          >
            {{ file && file.name }}
            <yxt-button
              v-if="stateType!==importState.verifying"
              type="primary"
              @click="reset"
            >
              {{stateType===importState.completed && ($slots.success || (completeData && completeData.isSuccess)) ?$t('pc_biz_import_btn_continueupload'/* 继续上传 */):$t('pc_biz_import_btn_uploadagain'/* 重新上传 */) }}
            </yxt-button>
          </yxt-row>
          <yxt-row
            type="flex"
            align="middle"
            class="standard-size-12 lh20 mt4"
          >
            <yxt-link
              :underline="false"
              type="success"
            >
              <i class="yxt-icon-success" />
            </yxt-link>
            <span class="color-gray-8 ml3">{{$t('pc_biz_import_tip_uploadcompleted'/* 上传成功 */)}}</span>
          </yxt-row>
        </li>
        <li
          v-if="stateType===importState.verifying"
          class="is-active color-primary-6"
        >
          <span class="yxt-upload-status" />
          <div
            class="color-gray-10 yxt-upload-item-title"
          >
            {{$t('pc_biz_import_tip_verifyingformat'/* 正在检验数据格式 */)}}
          </div>
          <yxt-row
            type="flex"
            align="middle"
            class="standard-size-12 lh20 mt4"
          >
            <span class="color-gray-8 ml16">{{$t('pc_biz_import_tip_verifying'/* 检查中... */)}}</span>
          </yxt-row>
        </li>
        <template v-else-if="stateType===importState.completed">
          <li class="color-primary-6" :class="isSuccess?'is-completed':'is-active'">
            <span class="yxt-upload-status" />
            <template v-if="$slots.error">
              <slot name="error" />
            </template>
            <template v-else-if="completeData && !completeData.isSuccess">
              <yxt-row
                type="flex"
                align="middle"
                class="color-gray-10 yxt-upload-item-title"
              >
                {{ completeData.title }}
              </yxt-row>
              <yxt-row
                type="flex"
                align="middle"
                class="standard-size-12 lh20 mt4"
              >
                <yxt-link
                  :underline="false"
                  type="danger"
                >
                  <i class="yxt-icon-error" />
                </yxt-link>
                <span class="color-gray-8 ml3">{{ completeData.message }}</span>
                <yxt-link
                  v-if="completeData.linkHandler || completeData.linkUrl"
                  class="standard-size-12 ml8"
                  type="primary"
                  :underline="false"
                  @click="linkHandler(completeData)"
                >
                  {{ completeData.linkMessage || $t('pc_biz_import_btn_downloaderrorfile',/* 下载失败数据 */) }}
                </yxt-link>
              </yxt-row>
            </template>
            <template v-else>
              <yxt-row
                type="flex"
                align="middle"
                class="color-gray-10 yxt-upload-item-title"
              >
                {{ $t('pc_biz_import_tip_verifyfile',/* 检查文件 */) }}
              </yxt-row>
              <yxt-row
                type="flex"
                align="middle"
                class="standard-size-12 lh20 mt4"
              >
                <yxt-link
                  :underline="false"
                  type="success"
                >
                  <i class="yxt-icon-success" />
                </yxt-link>
                <span class="color-gray-8 ml3">{{ $t('pc_biz_import_tip_verifysuccess',/* 检查通过 */) }}</span>
              </yxt-row>
            </template>
          </li>
          <li
            v-if="isSuccess"
            class="is-completed color-primary-6"
          >
            <span class="yxt-upload-status" />
            <template v-if="$slots.success">
              <slot name="success" />
            </template>
            <template v-else-if="completeData && completeData.isSuccess">
              <yxt-row
                type="flex"
                align="middle"
                class="color-gray-10 yxt-upload-item-title"
              >
                {{ completeData.title }}
              </yxt-row>
              <yxt-row
                type="flex"
                align="middle"
                class="standard-size-12 lh20 mt4"
              >
                <yxt-link
                  :underline="false"
                  type="success"
                >
                  <i class="yxt-icon-success" />
                </yxt-link>
                <span class="color-gray-8 ml3">{{ $t('pc_biz_import_tip_importsuccess',/* 导入成功 */) }}</span>
                <yxt-link
                  v-if="completeData.linkHandler || completeData.linkUrl"
                  class="standard-size-12 ml8"
                  type="primary"
                  :underline="false"
                  @click="linkHandler(completeData)"
                >
                  {{ completeData.linkMessage||$t('pc_biz_import_btn_downloadfile',/* 下载数据 */) }}
                </yxt-link>
              </yxt-row>
            </template>
            <template v-else>
              <yxt-row
                type="flex"
                align="middle"
                class="color-gray-10 yxt-upload-item-title"
              >
                {{ $t('pc_biz_import_tip_importfinish',/* 导入完成 */) }}
              </yxt-row>
              <yxt-row
                type="flex"
                align="middle"
                class="standard-size-12 lh20 mt4"
              >
                <yxt-link
                  :underline="false"
                  type="success"
                >
                  <i class="yxt-icon-success" />
                </yxt-link>
                <span class="color-gray-8 ml3">{{ $t('pc_biz_import_tip_importsuccess',/* 导入成功 */) }}</span>
              </yxt-row>
            </template>
          </li>
          <li
            v-else
            class="color-primary-6"
          >
            <span class="yxt-upload-status" />
            <div class="color-gray-8 yxt-upload-item-title">
              {{ $t('pc_biz_import_tip_importdata',/* 导入数据 */) }}
            </div>
          </li>
        </template>
      </ul>
    </div>
  </section>
</template>
<script>
import { Link, Button, Row } from 'yxt-pc';
// 定义导入流程各种状态
const importState = {
  beforeUpload: 0, // 上传前，选择文件页面
  uploading: 1, // 上传中
  uploaded: 2, // 上传后
  verifying: 3, // 验证中
  completed: 4 // 完成：完成后有两种结果，成功/失败
};
const importStateEnum = Object.keys(importState);
export default {
  props: {
    templateUrl: {
      type: String,
      default: ''
    }
  },
  components: {
    YxtLink: Link,
    YxtButton: Button,
    YxtRow: Row
  },
  data() {
    return {
      importState: importState,
      stateType: importState.beforeUpload,
      completeData: null, // isSuccess:是不是成功导入，title:阶段标题，message:阶段描述，linkUrl：链接url, linkMessage：链接描述
      file: null,
      processing: false
    };
  },
  computed: {
    isSuccess() {
      return this.stateType === importState.completed &&
      (this.$slots.success || (this.completeData && this.completeData.isSuccess));
    }
  },
  watch: {
    stateType(value) {
      this.processing = ![importState.beforeUpload, importState.completed].includes(value);
      this.$emit('onProcess', this.processing);
    }
  },
  created() {
    this.$emit('ready',
      Object.assign(...['file', 'next', 'reset', ...importStateEnum].map(d => ({ [d]: this[d] })))
    );
  },
  methods: {
    ...Object.assign(...importStateEnum.map((d, i) => {
      return { [d]: function(data) { this.toStep(i, data); }
      };
    })),
    // 下一个流程
    next(data) {
      this.toStep(this.stateType + 1, data);
    },
    // 跳转至指定流程
    toStep(type, data) {
      if (type > importState.completed) return;
      let next = this.next;
      switch (type) {
        case importState.uploaded: {
          // 不允许从uploading之外进入uploaded阶段
          if (type - 1 !== importState.uploading) {
            return;
          }
          this.file = data;
          if (!this.$slots.uploaded) {
            next = this.completed;
            this.$nextTick(()=>{
              this.next();
            });
          }
          break;
        }
        case importState.completed:
        {
          this.completeData = data;
          break;
        }
      }
      this.stateType = type;
      this.$emit(importStateEnum[type], next, data);
      this.$emit('procEmits', importStateEnum[type], next, data);
    },
    // 重新上传
    reset() {
      this.stateType = importState.beforeUpload;
      this.file = null;
      this.$emit('reset');
    },
    throttle(method) {
      clearTimeout(method.tId);
      method.tId = setTimeout(() => {
        method();
      }, 1000);
    },
    linkHandler(completeData) {
      if (typeof completeData.linkHandler === 'function') {
        completeData.linkHandler();
      } else {
        this.downloadError(completeData.linkUrl);
      }
    },
    // 下载异常说明
    downloadError(url) {
      if (!this.clicked) {
        window.location.href = url;
      } else {
        this.$message.warning(this.$t('pc_biz_import_msg_downloading'/* 正在下载，请稍后 */));
      }
      this.clicked = true;
      this.throttle(() => {
        this.clicked = false;
      });
    },
    downloadExcel() {
      if (this.templateUrl) {
        window.location.href = this.templateUrl;
      } else {
        this.$emit('onTemplateUrl');
      }
    }
  }
};
</script>
