<template>
  <div class="yxtbiz-ai-robot-demo__dialog">
    <!-- 对话框头部 -->
    <div class="yxtbiz-ai-robot-demo__dialog--header">
      <div class="yxtbiz-ai-robot-demo__dialog--header-left">
        <yxtf-portrait v-if="!bFullscreen && settings.assistHeadImg" class="mr12" size="small" username="AI" :imgUrl="settings.assistHeadImg" />
        <span>{{ title }}</span>
      </div>
      <div class="yxtbiz-ai-robot-demo__dialog--header-right">
        <yxtf-popconfirm
          :title="$t('pc_biz_ai_log_clear_confirm').d('确定清空此对话消息记录？')"
          hide-icon
          @confirm="clearHistory"
        >
          <header-icon slot="reference" :size="iconSize" icon-name="clear" :pop-text="$t('pc_biz_ai_clear_log').d('清空记录')" remote />
        </yxtf-popconfirm>
        <header-icon :size="iconSize" @click="share" icon-name="share" :pop-text="$t('pc_biz_ai_share').d('分享')" remote/>
        <Share v-if="showShare" :bFullscreen="bFullscreen" />
        <template v-if="!max">
          <header-icon
            remote
            :size="iconSize"
            @click="changeFullSize"
            :icon-name="bFullscreen ? 'downsize' : 'fullscreen'"
            :pop-text="bFullscreen ? $t('pc_biz_ai_small_window').d('小窗口') : $t('pc_biz_ai_full_screen').d('全屏')"
          />
          <!-- 已有的关闭icon尺寸比设计图的小2px -->
          <header-icon remote :size="iconSize + 2" @click="$emit('close')" icon-name="close" :pop-text="$t('pc_biz_ai_close').d('关闭')" />
        </template>
      </div>
    </div>
    <!-- 对话框主体 -->
    <div class="yxtbiz-ai-robot-demo__dialog--body" :style="aiBodyStyle">
      <yxtf-scrollbar ref="scrollbar" :fit-height="true">
        <!-- <infinite-scroll v-if="!noMoreHistory" :element="$refs.dialogBody" @more="getHistory"></infinite-scroll> -->
        <ul ref="dialogBody" class="is-msg-list">
          <li>
            <div class="yxtbiz-ai-robot-demo__dialog--msg is-answer" :class="{'fullscreen': bFullscreen}">
              <div class="mr12" v-if="bFullscreen" >
                <yxtf-portrait size="28px" img-url="https://stc.yxt.com/ufd/407a24/assistent/pc/img/avatar.jpg"></yxtf-portrait>
              </div>
              <div class="is-content">
                <div class="font-size-16">你好，我是Talent Ai, 很高兴为你服务~</div>
                <div class="mt12 font-size-16">你可以试着这样问我：</div>
                <div class="mt8" :class="{'welcome-group': bFullscreen }">
                  <div class="welcome-item hand" @click="quickSend(0)">○ 销售总监岗位中达到高准备度的继任员工占比</div>
                  <div class="welcome-item hand" @click="quickSend(1)">○ 销售管理部的继任情况</div>
                  <div class="welcome-item hand" @click="quickSend(2)">○ 拥有集团及事业部复合经验标签的员工有哪些</div>
                </div>
              </div>
            </div>
          </li>
          <li
            v-for="(msg, index) in msgList"
            :key="index"
            :class="{ 'yxtbiz-ai-robot-demo__dialog--msg-question': msg.type }"
          >
            <Message :msg="msg" :isWaitAnswer="isWaitAnswer" :fullscreen="bFullscreen" @feedback="showFeedbackDialog(index)"  @resend="(val) => resend(msg, val, index)" ></Message>
          </li>
        </ul>
      </yxtf-scrollbar>
    </div>
    <!-- 底部输入框 -->
    <div class="yxtbiz-ai-robot-demo__dialog--footer" ref="aiFooter">
      <dropLine @drag="onDrag" @start="startDrag"></dropLine>
      <textarea ref="textarea" v-model="question" @input="resizeTextarea" maxlength="2000" :placeholder="$t('pc_biz_ai_input_problem').d('请输入您要咨询的问题')" @keydown="keydown" :style="textareaStyle"></textarea>
      <div class="yxtbiz-ai-robot-demo__dialog--footer-btn">
        <div :class="{'ai-robot-btn-send': true, 'disbale-send': isWaitAnswer}" v-if="question" @click="sendMsg"><img src="https://stc.yxt.com/ufd/407a24/assistent/pc/svg/send.svg"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  submitQuestionFeedback
} from '../service';
import Message from './message.vue';
import HeaderIcon from './headerIcon.vue';
import InfiniteScroll from './infiniteScroll.vue';
import Share from './share.vue';
import dropLine from './dropLine.vue';

export default {
  name: 'MsgDialog',
  components: {
    InfiniteScroll,
    Message,
    HeaderIcon,
    Share,
    dropLine
  },
  props: {
    max: {
      type: Boolean,
      default: false
    },
    sid: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default() {
        return this.$t('pc_biz_ai_title').d('企业AI助手');
      }
    },
    visible: {
      type: Boolean,
      default: false
    },
    bFullscreen: {
      type: Boolean,
      default: false
    }
  },
  inject: ['settings'],
  data() {
    return {
      isFirstLoad: true,
      maxCreateTime: '',
      excludeIds: [],
      resendMsg: null,
      showShare: false,
      isWaitAnswer: false,
      textareaStyle: '',
      offsetY: 0,
      sBodyHeight: 162, // 窗体初始化的差值，窗体拖拉会用到该值计算
      sFooterHeight: 54, // 窗体初始化的差值，窗体拖拉会用到该值计算
      question: '',
      lastQuestion: '',
      msgList: [],
      askHistory: [],
      currentFeedbackIndex: 0,
      noMoreHistory: !this.sid // 无更多历史消息
    };
  },
  computed: {
    AIdisabled() {
      return (this.isWaitAnswer || !this.settings.factorOpenFlag || !this.settings.assistEnabled || !this.settings.accessEnabled);
    },
    aiBodyStyle() {
      return { height: 'calc( 100% - ' + (this.sBodyHeight + this.offsetY) + 'px )' };
    },
    aiFooterStyle() {
      let fHeight = this.offsetY + this.sFooterHeight;
      this.resizeTextarea();
      if (this.bFullscreen) {
        return { height: fHeight < 160 ? 160 : fHeight + 'px'};
      } else {
        return { height: fHeight < 54 ? 54 : fHeight + 'px'};
      }

    },
    iconSize() {
      return this.bFullscreen ? 24 : 20;
    },
    closeIconSize() {
      const size = this.bFullscreen ? 26 : 22;
      return `${size}px`;
    }
  },
  watch: {
    question() {
      this.$nextTick(this.resizeTextarea);
    }
  },
  created() {
    if (!this.sessionId) {
      this.sessionId = new Date().getTime().toString();
      this.msgList = [];
    }
  },
  mounted() {
    document.body.addEventListener('click', () => {
      this.showShare = false;
    });
    if (this.bFullscreen) {
      this.sFooterHeight = 160;
    } else {
      this.sFooterHeight = 54;
    }
  },
  methods: {
    quickSend(index) {
      this.question = ['销售总监岗位中达到高准备度的继任员工占比', '销售管理部的继任情况', '拥有集团及事业部复合经验标签的员工有哪些'][index];
      this.sendMsg();
    },
    getHistory() {
      // const dialogBody = this.$refs.dialogBody;
      this.msgList.unshift({ // 舔加加载历史等待效果
        answerStatus: 0,
        chatType: 1
      });

      this.msgList.shift(); // 清除加载的数据
      this.msgList.unshift({
        chatType: 2,
        content: this.$t('pc_biz_ai_no_more').d('暂无更多')
      });
      this.noMoreHistory = true;
      this.isFirstLoad = false;
    },
    resizeTextarea() {
      const textarea = this.$refs.textarea;
      if (textarea) {
        const height = textarea.scrollHeight;
        this.textareaStyle = {
          height: height > 67 ? (height + 'px') : '54px',
          overflow: 'auto'
        };
      }
    },
    keydown(e) {
      if (e.shiftKey || e.altKey) return;
      if (e.keyCode === 13) {
        e.preventDefault();
        if (e.ctrlKey) {
          console.log('keydown', e);
          this.question += '\n';
        } else {
          this.sendMsg();
        }
      }
    },
    resend(msg, resendType, index) {
      if (resendType) {
        console.log('askInternet', this.msgList[index - 1]);

        for (let i = 0; i < this.msgList.length; i++) { // 往后找 找到第一个终止
          if (this.msgList[index - i] && this.msgList[index - i].chatType === 0) {
            this.resendMsg = {
              content: this.msgList[index - i].content,
              chatType: 0
            };
            this.send(resendType);
            break;
          }
        }
      } else {
        this.resendMsg = msg;
        this.send();
      }
    },
    sendMsg() {
      let self = this;
      // 答案等待中，不可发消息
      if (self.isWaitAnswer) { // 等待中
        this.$message.warning('暂时不能发送，请稍后再试！');
      }
      const str = this.question.replace(/(^\s*)|(\s*$)/g, '');

      // 临时写死匹配逻辑
      let mathIndex = -1;
      if (str.includes('准备度')) {
        mathIndex = 0;
      } else if (str.includes('继任')) {
        mathIndex = 1;
      } else if (str.includes('标签')) {
        mathIndex = 2;
      }
      if (!str && !this.resendMsg) {
        this.$message(this.$t('pc_biz_ai_input_not_empty').d('发送内容不能为空！'));
        this.question = '';
        return;
      }
      self.isWaitAnswer = true;
      self.addQuestion();
      // #region 模拟接口返回
      self.addAnswer(); // loadding...
      // TODO: 接口对接
      this.resizeTextarea();
      setTimeout(() => {
        this.submitQuestion(mathIndex);
      }, 2000);

    },
    submitQuestion(index) {
      if (index === -1) {
        return this.addAnswer({
          answerStatus: 3, // 错误消息
          chatType: 1, // 消息类型 1回答 0 提问
          contentDetail: {
            content: '未能匹配，请稍后再试~',
            linkList: []
          }
        });
      }
      const imgs = [
        '<div class="img-1"><div>',
        '<div class="img-2"><div>',
        '<div class="img-3"><div>'
      ];
      this.addAnswer({
        answerStatus: 1,
        chatCorrectEnabled: 0, // 是否启用纠错
        chatLikeFlag: 0, // 是否评价过
        chatContentId: '', // 消息id
        chatType: 1, // 消息类型 1回答 0 提问
        contentDetail: {
          content: imgs[index] || '',
          linkList: []
        }
      });
      this.isWaitAnswer = false;
    },
    // 提问题
    addAnswer(answer) {
      if (answer) {
        console.log('answer', answer);
        if (this.msgList.length && this.msgList.length > 1) { //
          // let lastMessage = this.msgList[this.msgList.length - 1]; // 数组最后一项
          // 创建新回答
          this.msgList.splice(-1, 1, answer);
          this.scrollToEnd();
        } else {
          // 加载答案
          this.msgList.splice(-1, 1, answer);
          this.scrollToEnd();
        }
      } else {
        // 加载等待消息
        this.msgList.push({
          answerStatus: 0,
          chatType: 1
        });
      }
    },
    // 获取查询所需的历史记录
    getQueryAskHistory(newlog) {
      if (newlog) {
        if (this.askHistory.length < 3) {
          this.askHistory.push(newlog);
        } else {
          this.askHistory.push(newlog);
          this.askHistory.shift();
        }
      }
      console.log('this.msgList', this.msgList);

    },
    // 添加提问记录
    addQuestion() { // 添加提问记录
      this.msgList.push(this.resendMsg || {
        chatType: 0,
        content: this.question
      });
      this.lastQuestion = this.question;
      // 清空输入框
      this.question = '';
      // 重置输入框高度
      this.textareaStyle = {
        height: 'auto',
        overflow: 'hidden'
      };
      this.scrollToEnd();
    },
    // 改变全屏尺寸
    changeFullSize() {
      let bFullscreen = !this.bFullscreen;
      if (bFullscreen) {
        this.sFooterHeight = 160;
      } else {
        this.sFooterHeight = 54;
      }
      this.$emit('fullscreen', bFullscreen);
      this.scrollToEnd();
    },
    // 分享功能
    share(event) {
      console.log('share');
      this.showShare = !this.showShare;
    },
    // 清空历史记录接口
    clearHistory() {
      this.msgList = [];
    },
    // 展示反馈弹窗
    showFeedbackDialog(index) {
      this.currentFeedbackIndex = index;
      this.$emit('feedback');
    },
    // 更新反馈状态
    updateFeedback(content) {
      submitQuestionFeedback({ chatContentId: this.msgList[this.currentFeedbackIndex].chatContentId, correctContent: content})
        .then(_res => {
          this.msgList[this.currentFeedbackIndex].chatCorrectEnabled = 1; // 更新反馈状态
        });
    },
    // 滑动到指定元素
    scrollTo(p) {
      const scrollElement = this.$refs.scrollbar && this.$refs.scrollbar.$el.firstChild;
      scrollElement && scrollElement.scrollTo(0, p);
    },
    // 滑动底部
    scrollToEnd() {
      this.$nextTick(() => {
        this.$refs.dialogBody.scrollIntoView({
          block: 'end',
          behavior: 'smooth'
        });
      });
    },
    onDrag(val) {
      console.log('onDrag', val);
      let maxHeight = this.sFooterHeight + this.offsetY;
      if (this.bFullscreen) { // 全屏幕状态
        if (maxHeight > (document.body.offsetHeight * 0.7) && val > 0) return ; // 最小高度54 最大高度为body内容的70%
      } else { // 非全屏
        if (maxHeight > 400 && val > 0) return; // 最小高度54 最大高度为400
      }
      if (maxHeight < 54 && val < 0) return;
      this.offsetY = val;
    },
    startDrag() {
      this.sBodyHeight = this.sBodyHeight + this.offsetY;
      this.sFooterHeight = this.sFooterHeight + this.offsetY;
      this.offsetY = 0;
    }
  }
};
</script>
