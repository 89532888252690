var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: !_vm.isLoadedNav,
          expression: "!isLoadedNav",
        },
      ],
      staticClass: "yxtbiz-nav-main clearfix",
      class:
        _vm.navDisplayType === 0
          ? "yxtbiz-nav-main--front"
          : "yxtbiz-nav-main--manager",
      style: `visibility: ${_vm.isLoadedNav ? "visible" : "hidden"}`,
      attrs: { id: _vm.navId },
    },
    [
      _vm.navDisplayType === 1
        ? [
            _c(
              "yxt-layout",
              {
                attrs: {
                  "auto-fit": _vm.isShowLeftNav || _vm.autoFit,
                  root: "",
                },
              },
              [
                _vm.isShowTopNav
                  ? _c(
                      "yxt-layout-header",
                      { style: { "z-index": _vm.topNavZindex } },
                      [
                        _vm.isWorkBenchNav
                          ? _c("nav-top-workbench")
                          : _c("yxtbiz-nav-top", {
                              attrs: {
                                isRedirectFirstPage: _vm.isRedirectFirstPage,
                                firstPageCode: _vm.topPageCode,
                                mainPathname: _vm.mainPathname,
                                navDatas: _vm.navDatas,
                                mainPageCode: _vm.functionCode,
                                tinyNav: _vm.tinyNav,
                                "lang-list": _vm.langList,
                                isOpenI18n: _vm.enableI18n,
                                allPath: _vm.allPath,
                              },
                            }),
                        _vm.OSMEnable === 1
                          ? _c("stop-notice", {
                              attrs: { isManager: true },
                              on: { show: (s) => (_vm.osmShow = s) },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "yxt-layout-main",
                  [
                    _vm.isShowLeftNav
                      ? _c(
                          "yxt-layout-side",
                          {
                            staticClass: "yxtbiz-nav-main__side",
                            class: {
                              "yxtbiz-nav-main__side--hover":
                                _vm.hovered && _vm.collapse,
                              "yxtbiz-nav-main__side--loading": !_vm.leftLoaded,
                            },
                            attrs: {
                              "inner-class": "yxtbiz-nav-main__side-inner",
                              width: _vm.collapse ? 56 : 184,
                              "main-nav": "",
                            },
                            nativeOn: {
                              mouseenter: function ($event) {
                                return _vm.leftEnter.apply(null, arguments)
                              },
                              mouseleave: function ($event) {
                                return _vm.leftLeave.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c("yxtbiz-nav-left", {
                              class: [
                                _vm.isShowTopNav
                                  ? ""
                                  : "yxtbiz-nav-main__left-notop",
                              ],
                              attrs: {
                                hovered: _vm.hovered,
                                mainPathname: _vm.mainPathname,
                                mainPageCode: _vm.functionCode,
                                appNavLeft: _vm.appNavLeft,
                              },
                              on: {
                                enterBottom: _vm.enterBottom,
                                leaveBottom: _vm.leaveBottom,
                                collapseLeft: _vm.collapseLeftInner,
                                collapseOperated: function ($event) {
                                  _vm.collapseOperated = true
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "leftbottom",
                                    fn: function (scope) {
                                      return [
                                        _vm._t("leftbottom", null, {
                                          collapse: scope.collapse,
                                          width: scope.collapse ? 56 : 184,
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isLoadedNav ? _vm._t("default") : _vm._e(),
                  ],
                  2
                ),
              ],
              1
            ),
          ]
        : _vm.navDisplayType === 11
        ? [
            _c(
              "div",
              { staticClass: "yxtbiz-nav-main__body" },
              [
                _c(
                  "yxtbiz-nav-immersive",
                  {
                    attrs: {
                      isRedirectFirstPage: _vm.isRedirectFirstPage,
                      firstPageCode: _vm.topPageCode,
                      mainPathname: _vm.mainPathname,
                      navDatas: _vm.navDatas,
                      mainPageCode: _vm.functionCode,
                      isShowTopNav: _vm.isShowTopNav,
                      allPath: _vm.allPath,
                    },
                  },
                  [_vm.isLoadedNav ? _vm._t("default") : _vm._e()],
                  2
                ),
              ],
              1
            ),
          ]
        : [
            _c(
              "div",
              {
                staticClass: "yxtbiz-nav-main__stu-container",
                style: { "min-width": _vm.minWidth + "px" },
              },
              [
                _vm.isShowTopNav && _vm.isWorkBenchNav
                  ? _c("nav-top-workbench")
                  : _vm.isShowTopNav
                  ? _c(
                      "yxtbiz-nav-top-stu",
                      {
                        attrs: {
                          isRedirectFirstPage: _vm.isRedirectFirstPage,
                          firstPageCode: _vm.topPageCode,
                          mainPathname: _vm.mainPathname,
                          navDatas: _vm.navDatas,
                          mainPageCode: _vm.functionCode,
                          decorateModule: _vm.decorateModule,
                          isKngNav: _vm.isKngNav,
                          OSMEnable: _vm.OSMEnable,
                          "lang-list": _vm.langList,
                          isOpenI18n: _vm.enableI18n,
                          previewMode: _vm.previewMode,
                          allPath: _vm.allPath,
                          showIm: _vm.showIm,
                        },
                      },
                      [
                        _c(
                          "template",
                          { slot: "imbell" },
                          [_vm._t("imbell")],
                          2
                        ),
                        _c(
                          "template",
                          { slot: "announcement" },
                          [_vm._t("announcement")],
                          2
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    class: [
                      "yxtbiz-nav-main__stu yxtbiz-nav-main__stu--buildin",
                      _vm.isShowTopNav && _vm.isContentPt
                        ? ""
                        : "yxtbiz-nav-main__stu--notop",
                      _vm.isShowLeftNav ? "" : "",
                    ],
                  },
                  [
                    !_vm.isShowLeftNav
                      ? [
                          _vm.fullWidth
                            ? _vm._t("default")
                            : _c(
                                "div",
                                { staticClass: "yxtbiz-nav-main__stu-content" },
                                [_vm._t("default")],
                                2
                              ),
                        ]
                      : _c(
                          "div",
                          {
                            staticClass:
                              "yxtbiz-nav-main__container yxtbiz-nav-main__container--buildin",
                          },
                          [
                            _c("yxtbiz-nav-left-stu", {
                              class: _vm.showBreadcrumb ? "mt45" : "",
                              attrs: {
                                mainPathname: _vm.mainPathname,
                                functionCode: _vm.functionCode,
                              },
                              on: { toPage: _vm.toPage },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "yxtbiz-nav-main__stu-right",
                                class: _vm.showBreadcrumb
                                  ? "show-breadcrumb"
                                  : "",
                              },
                              [
                                _vm.showBreadcrumb
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "yxtbiz-nav-main__stu-breadcrumb",
                                      },
                                      [
                                        _c(
                                          "yxtf-breadcrumb",
                                          {
                                            attrs: {
                                              "separator-class":
                                                "yxtf-icon-arrow-right",
                                            },
                                          },
                                          _vm._l(
                                            _vm.stuBreadcrumb,
                                            function (item, index) {
                                              return _c(
                                                "yxtf-breadcrumb-item",
                                                _vm._b(
                                                  { key: index },
                                                  "yxtf-breadcrumb-item",
                                                  item,
                                                  false
                                                ),
                                                [_vm._v(_vm._s(item.name))]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._t("default"),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                  ],
                  2
                ),
                _vm.isShowFooter && !_vm.isWorkBenchNav
                  ? _c("yxtbiz-nav-footer")
                  : _vm._e(),
              ],
              1
            ),
          ],
      _vm.isShowHelpCenter && _vm.type === 2 && _vm.isZh
        ? _c("yxtbiz-support-sidebar", {
            attrs: {
              isExistTopNav:
                _vm.isShowTopNav && _vm.type === 2 && _vm.displayType === 1,
              functionCode: _vm.functionCode,
              tinyNav: _vm.tinyNav,
            },
          })
        : _vm._e(),
      _vm.OSMEnable === 1
        ? _c("global-notice", {
            attrs: { isManager: true },
            on: { show: (s) => (_vm.osmShow = s) },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }