var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "yxtbiz-import-proc",
    {
      ref: "importProc",
      attrs: { "app-code": "udp", "template-url": _vm.templateUrl },
      on: {
        ready: _vm.ready,
        reset: _vm.resetData,
        uploaded: _vm.uploaded,
        onProcess: _vm.onProcess,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "yxt-upload-container",
          attrs: { slot: "uploaded" },
          slot: "uploaded",
        },
        [
          _c(
            "yxt-row",
            {
              staticClass: "color-gray-10 yxt-upload-item-title font-size-12",
              attrs: { type: "flex", align: "middle" },
            },
            [
              _vm._v("\n      " + _vm._s(_vm.file.name) + "\n      "),
              _c("i", {
                staticClass:
                  "yxt-icon-close hand standard-size-12 color-gray-8 hover-primary-6 ml16",
                on: { click: _vm.reset },
              }),
            ]
          ),
          _c(
            "yxt-row",
            { staticClass: "mt24" },
            [
              _c(
                "yxt-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleImport } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "pc_biz_udp_import_btn_confirmimport" /* 确定导入 */
                      )
                    )
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.importResult && _vm.importResult.isSuccess
        ? _c(
            "div",
            { attrs: { slot: "success" }, slot: "success" },
            [
              _c(
                "yxt-row",
                {
                  staticClass: "color-gray-10 yxt-upload-item-title",
                  attrs: { type: "flex", align: "middle" },
                },
                [
                  _c("span", { staticClass: "yxt-color-success" }, [
                    _vm._v(_vm._s(_vm.importResult.successCount)),
                  ]),
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.$t(
                          "pc_biz_udp_import_tip_successcount" /* 条导入成功 */
                        )
                      ) +
                      "\n      "
                  ),
                  _vm.importResult.failedCount
                    ? [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("pc_biz_udp_tip_comma" /* ， */))
                        ),
                        _c("span", { staticClass: "yxt-color-danger" }, [
                          _vm._v(_vm._s(_vm.importResult.failedCount)),
                        ]),
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.$t(
                                "pc_biz_udp_import_tip_failedcount" /* 条导入失败 */
                              )
                            ) +
                            "\n      "
                        ),
                      ]
                    : _vm._e(),
                  _vm.importResult.repeatCount
                    ? [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("pc_biz_udp_tip_comma" /* ， */))
                        ),
                        _c("span", { staticClass: "yxt-color-danger" }, [
                          _vm._v(_vm._s(_vm.importResult.repeatCount)),
                        ]),
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.$t(
                                "pc_biz_udp_import_tip_repeatcount" /* 条重复数据 */
                              )
                            ) +
                            "\n      "
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "yxt-row",
                {
                  staticClass: "standard-size-12 lh20 mt4",
                  attrs: { type: "flex", align: "middle" },
                },
                [
                  _c(
                    "yxt-link",
                    { attrs: { underline: false, type: "success" } },
                    [_c("i", { staticClass: "yxt-icon-success" })]
                  ),
                  _c("span", { staticClass: "color-gray-8 ml3" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("pc_biz_import_tip_importsuccess" /* 导入成功 */)
                      )
                    ),
                  ]),
                  _vm.importResult.filePath
                    ? _c(
                        "yxt-link",
                        {
                          staticClass: "standard-size-12 ml8",
                          attrs: { type: "primary", underline: false },
                          on: {
                            click: function ($event) {
                              return _vm.downloadError(
                                _vm.importResult.filePath
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.$t(
                                  "pc_biz_udp_import_btn_downloadfaileddata" /* 下载失败数据 */
                                )
                              ) +
                              "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }