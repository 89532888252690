<template>
<div class="broadside-content">
    <div class="ai-robot-info">
        <yxtf-portrait v-if="settings.assistHeadImg" class="mr12" size="medium" username="AI" :imgUrl="settings.assistHeadImg" />
        <span class="mt12 ai-title">{{ title }}</span>
    </div>
    <div class="action-btns">
        <div class="action-btns-item">
            <yxtf-popconfirm :title="$t('pc_biz_ai_log_clear_confirm').d('确定清空此对话消息记录？')" hide-icon @confirm="clearHistory">
                <div slot="reference" class="item-section">
                    <span class="mr5">
                      <yxt-svg
                        :remote-url='svgUrl'
                        class="color-gray-7 hover-primary-6 hand"
                        :width="22"
                        :height="22"
                        icon-class="clear"
                      />
                    </span>
                    <span>{{ $t('pc_biz_ai_clear_log').d('清空记录') }}</span>
                </div>
            </yxtf-popconfirm>
        </div>
        <div class="action-btns-item">
            <div class="item-section"  @click.stop="share">
              <span class="mr5">
                  <yxt-svg
                    :remote-url='svgUrl'
                    class="color-gray-7 hover-primary-6 hand"
                    :width="22"
                    :height="22"
                    icon-class="share"
                  />
                </span>
                <span>{{ $t('pc_biz_ai_share_friends').d('分享好友') }}</span>
            </div>
            <Share v-if="showShare" :bFullscreen="bFullscreen" />
        </div>
    </div>
</div>
</template>

<script>
import HeaderIcon from './headerIcon.vue';
import Share from './share.vue';
export default {
  props: {
    bFullscreen: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  },
  components: {
    HeaderIcon,
    Share
  },
  inject: ['settings'],
  data() {
    return {
      showShare: false,
      svgUrl: `${this.$staticBaseUrl}assets/14a8350a/9a77b0e7/`
    };
  },
  mounted() {
    document.body.addEventListener('click', () => {
      console.log('share-false');
      this.showShare = false;
    });
  },
  methods: {
    // 分享功能
    share(event) {
      console.log('share');
      this.showShare = !this.showShare;
    },
    clearHistory() {
      this.$emit('clearHistory');
    }
  }
};
</script>

<style lang="scss" scoped>
.broadside-content {
    width: 252px;
    height: 100%;
    background: rgba(255, 255, 255, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .ai-robot-info {
        margin-top: 56px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .ai-title {
            font-size: 20px;
            font-weight: 500;
            color: #262626;
            line-height: 28px;
        }
    }
    .action-btns {
        display: flex;
        flex-direction: column;
        align-items: center;
       
        .action-btns-item {
            position: relative;
            margin-bottom: 40px;
            cursor: pointer;
            .item-section {
                display: flex;
                align-items: center;
                &:hover {
                 span {
                  color: var(--color-primary-6) !important;
                 }
                 .yxt-svg-icon {
                    color: var(--color-primary-6) !important;
                  }
                }
                .mr5 {
                  margin-bottom: -4px;
                }
                span {
                  line-height: 24px;
                  font-size: 16px;
                  font-weight: 400;
                  color: #262626;
                }
            }
        }
    }
}
</style>
