var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.size === "large"
        ? _c(
            "yxt-dialog",
            {
              attrs: {
                visible: _vm.customVisible,
                "custom-class": "yxtbiz-ai-robot__treasure",
                "modal-append-to-body": false,
                cutline: false,
              },
              on: {
                closed: function ($event) {
                  return _vm.toggleDomClass(false)
                },
                "update:visible": function ($event) {
                  _vm.customVisible = $event
                },
              },
            },
            [
              _c("template", { slot: "title" }, [
                _c("div", { staticClass: "yxtbiz-ai-robot__treasure--title" }, [
                  _vm._v(
                    _vm._s(_vm.$t("pc_biz_lbl_treasure_chest").d("百宝箱"))
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "yxtbiz-ai-robot__treasure--search" },
                  [
                    _c(
                      "yxt-input",
                      {
                        attrs: {
                          placeholder: _vm
                            .$t("pc_biz_msg_placeholder_txt")
                            .d("请输入搜索关键词"),
                          searchNoIcon: "",
                        },
                        on: { search: _vm.postTreasureList },
                        model: {
                          value: _vm.content,
                          callback: function ($$v) {
                            _vm.content =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "content",
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "yxt-input__icon yxt-icon-search hand",
                          attrs: { slot: "suffix" },
                          on: { click: _vm.postTreasureList },
                          slot: "suffix",
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "yxtbiz-ai-robot__treasure--tab" },
                  [
                    _c(
                      "yxt-tabs",
                      {
                        on: { "tab-click": _vm.handleTabsClick },
                        model: {
                          value: _vm.activeTab,
                          callback: function ($$v) {
                            _vm.activeTab = $$v
                          },
                          expression: "activeTab",
                        },
                      },
                      [
                        _c("yxt-tab-pane", {
                          attrs: {
                            label: _vm.$t("pc_biz_lbl_winnow").d("精选"),
                            name: "tab1",
                          },
                        }),
                        _c("yxt-tab-pane", {
                          attrs: {
                            label: _vm.$t("pc_biz_lbl_usual").d("我的常用"),
                            name: "tab2",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "yxtbiz-ai-robot__treasure-body" },
                [
                  _vm.loading
                    ? _c("div", {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                        staticClass: "pos-center",
                        attrs: { "yxt-loading-background": "transparent" },
                      })
                    : _c("Treasure-list", {
                        attrs: { list: _vm.list },
                        on: {
                          toggleCollect: _vm.toggleCollect,
                          operation: _vm.handleOperation,
                        },
                      }),
                ],
                1
              ),
            ],
            2
          )
        : _c(
            "div",
            [
              _c("transition", { attrs: { name: "slide" } }, [
                _vm.customVisible
                  ? _c(
                      "div",
                      { staticClass: "yxtbiz-ai-robot__treasure-small" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "yxtbiz-ai-robot__treasure-small--header",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "yxtbiz-ai-robot__treasure-small--back",
                                on: {
                                  click: function ($event) {
                                    _vm.customVisible = false
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "yxt-icon-arrow-left font-size-24 font-bold",
                                }),
                                _c("span", { staticClass: "ml2" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm
                                          .$t("pc_comp_pageHeader_title")
                                          .d("返回")
                                      ) +
                                      "\n            "
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm
                                    .$t("pc_biz_lbl_treasure_chest")
                                    .d("百宝箱")
                                ) +
                                "\n        "
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "yxtbiz-ai-robot__treasure-small--nav",
                          },
                          [
                            _c(
                              "yxt-tabs",
                              {
                                attrs: { "height-size": "small" },
                                on: { "tab-click": _vm.handleTabsClick },
                                model: {
                                  value: _vm.activeTab,
                                  callback: function ($$v) {
                                    _vm.activeTab = $$v
                                  },
                                  expression: "activeTab",
                                },
                              },
                              [
                                _c("yxt-tab-pane", {
                                  attrs: {
                                    label: _vm
                                      .$t("pc_biz_lbl_winnow")
                                      .d("精选"),
                                    name: "tab1",
                                  },
                                }),
                                _c("yxt-tab-pane", {
                                  attrs: {
                                    label: _vm
                                      .$t("pc_biz_lbl_usual")
                                      .d("我的常用"),
                                    name: "tab2",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "yxtbiz-ai-robot__treasure-small--search",
                              },
                              [
                                _c(
                                  "yxt-input",
                                  {
                                    attrs: {
                                      placeholder: _vm
                                        .$t("pc_biz_msg_placeholder_txt")
                                        .d("请输入内容文字"),
                                      searchNoIcon: "",
                                    },
                                    on: { search: _vm.postTreasureList },
                                    model: {
                                      value: _vm.content,
                                      callback: function ($$v) {
                                        _vm.content =
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                      },
                                      expression: "content",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "yxt-input__icon yxt-icon-search hand",
                                      attrs: { slot: "suffix" },
                                      on: { click: _vm.postTreasureList },
                                      slot: "suffix",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "yxtbiz-ai-robot__treasure-small--body",
                          },
                          [
                            _c(
                              "yxt-scrollbar",
                              {
                                ref: "scrollbar",
                                staticClass: "yxtbiz-ai-robot-scrollbar",
                                attrs: { "fit-height": true },
                              },
                              [
                                _vm.loading
                                  ? _c("div", {
                                      directives: [
                                        {
                                          name: "loading",
                                          rawName: "v-loading",
                                          value: _vm.loading,
                                          expression: "loading",
                                        },
                                      ],
                                      staticClass: "pos-center",
                                      attrs: {
                                        "yxt-loading-background": "transparent",
                                      },
                                    })
                                  : _c("Treasure-list", {
                                      attrs: { list: _vm.list },
                                      on: {
                                        toggleCollect: _vm.toggleCollect,
                                        operation: _vm.handleOperation,
                                      },
                                    }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }