var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showCustom && _vm.customCode
    ? _c("code-viewer", {
        staticClass: "yxtbiz-nav-footer",
        attrs: { source: _vm.customCode },
      })
    : _c("div", { staticClass: "yxtbiz-nav-footer" }, [
        _vm.showLinks
          ? _c("div", { staticClass: "yxtbiz-nav-footer__links" }, [
              _c("span", { staticClass: "yxtbiz-nav-footer__link" }, [
                _c(
                  "a",
                  {
                    staticClass: "hover-primary-6",
                    attrs: { href: _vm.urlMap.userhome },
                  },
                  [_vm._v(_vm._s(_vm.$t("pc_biz_nav_btn_websitindex")))]
                ),
              ]),
              !_vm.hideUsercenterBtn
                ? _c("span", { staticClass: "yxtbiz-nav-footer__link" }, [
                    _c(
                      "a",
                      {
                        staticClass: "hover-primary-6",
                        attrs: { href: _vm.urlMap.uinfo },
                      },
                      [_vm._v(_vm._s(_vm.$t("pc_biz_nav_btn_usercenter")))]
                    ),
                  ])
                : _vm._e(),
              _vm.isSecretprotocol
                ? _c("span", { staticClass: "yxtbiz-nav-footer__link" }, [
                    _c(
                      "a",
                      {
                        staticClass: "hover-primary-6",
                        attrs: { href: _vm.urlMap.ua },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("pc_biz_nav_btn_confidentialityagreement")
                          )
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm.showFeedback
                ? _c("span", { staticClass: "yxtbiz-nav-footer__link" }, [
                    _c(
                      "a",
                      {
                        staticClass: "hover-primary-6",
                        attrs: { href: _vm.urlMap.feedback },
                      },
                      [_vm._v(_vm._s(_vm.$t("pc_biz_nav_btn_feedback")))]
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        !_vm.isCeibsonline && _vm.isShowCopyright
          ? _c("div", { staticClass: "yxtbiz-nav-footer__copyright" }, [
              _c("span", [
                _vm._v(
                  "© " +
                    _vm._s(new Date().getFullYear()) +
                    " " +
                    _vm._s(_vm.$t("pc_biz_nav_lbl_copyright1")) +
                    "\n      "
                ),
                _c(
                  "a",
                  {
                    staticClass: "hover-primary-6",
                    attrs: {
                      href: "https://beian.miit.gov.cn/",
                      target: "_blank",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("pc_biz_nav_lbl_copyright2")))]
                ),
              ]),
              _c("span", [
                _c(
                  "a",
                  {
                    staticClass: "hover-primary-6",
                    attrs: { href: "https://www.yxt.com/", target: "_blank" },
                  },
                  [_vm._v(_vm._s(_vm.$t("pc_biz_nav_lbl_websitname")))]
                ),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("pc_biz_nav_lbl_tecsupport")) +
                    "\n      " +
                    _vm._s(_vm.$t("pc_biz_nav_lbl_permitnumber"))
                ),
              ]),
            ])
          : _vm._e(),
        _vm.isCeibsonline
          ? _c("div", { staticClass: "yxtbiz-nav-footer__copyright" }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("pc_biz_nav_lbl_zhongoucopyright1")) +
                    "\n      "
                ),
                _c(
                  "a",
                  {
                    staticClass: "hover-primary-6",
                    attrs: {
                      href: "https://beian.miit.gov.cn/",
                      target: "_blank",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("pc_biz_nav_lbl_zhongoucopyright2")))]
                ),
              ]),
            ])
          : _vm._e(),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }