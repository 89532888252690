var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "yxt-select",
    {
      staticClass: "wd100p",
      attrs: {
        slot: "reference",
        "collapse-tags": "",
        "allow-create": "",
        "popper-class": "yxtbiz-select-text-wrap",
        clearable: "",
        multiple: "",
      },
      on: { change: _vm.change },
      slot: "reference",
      model: {
        value: _vm.selected,
        callback: function ($$v) {
          _vm.selected = $$v
        },
        expression: "selected",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "select-text-wrap",
          attrs: { slot: "empty" },
          slot: "empty",
        },
        _vm._l(_vm.selected, function (item, index) {
          return _c("div", { key: index, staticClass: "tag-item" }, [
            _c("span", { staticClass: "ellipsis" }, [_vm._v(_vm._s(item))]),
            _c("i", {
              staticClass: "yxt-icon-delete hover",
              on: {
                click: function ($event) {
                  return _vm.deleteTag(item)
                },
              },
            }),
          ])
        }),
        0
      ),
      _c("div", { attrs: { slot: "fixed-footer" }, slot: "fixed-footer" }, [
        _c(
          "div",
          { staticClass: "tag-options" },
          [
            _c("yxt-input", {
              attrs: {
                size: "small",
                maxlength: "50",
                placeholder: _vm.$t("pc_biz_core_tip_placeholder").d("请输入"),
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.addText.apply(null, arguments)
                },
              },
              model: {
                value: _vm.tagValue,
                callback: function ($$v) {
                  _vm.tagValue = $$v
                },
                expression: "tagValue",
              },
            }),
            _c(
              "yxt-button",
              {
                staticStyle: { "margin-left": "5px" },
                attrs: { type: "text" },
                on: { click: _vm.addText },
              },
              [_vm._v(_vm._s(_vm.$t("pc_biz_btn_add").d("添加")))]
            ),
            _vm.maxLength
              ? _c("yxt-button", { attrs: { type: "text" } }, [
                  _vm._v(
                    _vm._s(_vm.selected.length) + "/" + _vm._s(_vm.maxLength)
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
        _vm.errorMessage
          ? _c("div", { staticClass: "error-message" }, [
              _vm._v(_vm._s(_vm.errorMessage)),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }