<!-- 指令案例提示 -->
<template>
<article v-loading="initLoading" :style="{width: size === 'small' ? '432px' : '900px'}" yxt-loading-size="small" :class="['yxtbiz-ai-robot-instruction-prompt', size === 'small'? 'font-size-14 lh24' : 'font-size-16 lh28',]"> 
    <header class="color-gray-10">{{ (msg.contentDetail && msg.contentDetail.content) || '' }}</header>
    <main v-if="examples.length > 0" class="mt12">
      <div class="yxtbiz-ai-robot-instruction-prompt__try">
        <div>{{$t('pc_biz_have_a_try' /* 你可以试试： */)}}</div>
        <yxt-button @click="haveAChange" :loading="btnLoading" type="text2"><yxt-svg v-if="!btnLoading" class="v-mid mr8 mb4" width="16px" height="16px" icon-class="right-rotate" />{{$t('pc_biz_ai_change_promot' /* 换一换 */)}}</yxt-button>
      </div>
      <ul class="yxtbiz-ai-robot-instruction-prompt__examples">
        <li v-for="example in examples" :key="example" @click="handleExampleClick(example)" :class="['yxtbiz-ai-robot-instruction-prompt__examples-item',`yxtbiz-ai-robot-instruction-prompt__examples-item--${size}`]">
          <div class="ellipsis-2">
            {{ example }}
          </div>
        </li>
      </ul>
    </main>
</article>
</template>

<script>
import {getInstructionExamples} from '../../service.js';

export default {
  props: {
    msg: {
      type: Object,
      required: true,
      default: () => ({})
    },
    size: {
      type: String,
      default: 'small',
      validator: val => ['small', 'large'].includes(val)
    }
  },
  data() {
    this.firstLoadData = true; // 首次加载数据时候需要向上滚动到底部
    return {
      btnLoading: false,
      initLoading: false,
      examples: []
    };
  },
  mounted() {
    !this.msg.isHistory && this.initFetchExamples();
  },
  computed: {
    limit: ({size}) => size === 'small' ? 3 : 6
  },
  methods: {
    initFetchExamples() {
      this.initLoading = true;
      this.fetchExamples();
    },
    handleExampleClick(example) {
      this.$emit('PromotClick', example, this.msg.bizAppId || '');
    },
    fetchExamples() {
      this.btnLoading = true;
      const { limit } = this;
      getInstructionExamples({bizAppId: this.msg.bizAppId, limit})
        .then(res => (this.examples = res || []))
        .finally(() => {
          this.initLoading = false;
          this.btnLoading = false;
          if (this.firstLoadData) {
            this.$emit('scrollToEnd');
            this.firstLoadData = false;
          }
        });
    },
    haveAChange() { // 换一换
      this.fetchExamples();
    }
  }
};
</script>

<style lang="scss" scoped>
.yxtbiz-ai-robot-instruction-prompt {
  background: #fff;
  border-radius: 0px 12px 12px 12px;
  padding: 12px 20px 16px;
  box-sizing: border-box;


  &__try {
    color: #595959;
    display: flex;
    align-content: center;
    justify-content: space-between;
  }

  &__change {
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
  }

  &__examples {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  &__examples-item--small {
    width: 100%;
  }

  &__examples-item--large {
    width: calc((100% - 24px) / 3); /* 一行三列，每列宽度为 (100% - 24px) / 3 */
    margin-right: 12px;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  &__examples-item {
    color: #262626;
    margin-top: 8px; 
    background: #F4F5F6;
    border-radius: 0px 12px 12px 12px;
    padding: 8px 16px;
    box-sizing: border-box;
    cursor: pointer;
  }
}
</style>
