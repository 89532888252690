
import { i18n } from 'yxt-biz-pc/packages/common-util/changeLangs';
import dayjs from 'dayjs';
// 学习计划页面时间处理
export const getPlanTime = (item) => {
  const curTime = new Date();
  if (item.planFlag === 0) { // 未制定计划（无截止日期）
    return i18n.t('pc_kng_studyplan_lbl_unlimitedtime'/** 不限时间 */);
  } else { // 已制定计划（有截止日期）
    const fTime = new Date(item.planFinishTime.replace(/-/g, '/'));
    const cTime = new Date(item.createTime.replace(/-/g, '/'));
    if (item.schedule >= 100) { // 已完成
      return i18n.t('pc_kng_common_lbl_timelimit'/** {0} ～ {1} */, [getShowTime(cTime, curTime), getShowTime(fTime, curTime)]);
    } else { // 未完成
      if (curTime.getTime() > fTime.getTime()) {
        return i18n.t('pc_kng_studyplan_lbl_timeover'/** 已逾期，原计划{0}完成 */, [getShowDate(fTime, curTime)]);
      } else {
        const day = (new Date(item.planFinishTime.replace(/-/g, '/')).setHours(0, 0, 0, 0) - new Date().setHours(0, 0, 0, 0)) / (1000 * 3600 * 24);
        if (day === 0) {
          return i18n.t('pc_kng_studyplan_lbl_timetoday'/** 今天{0}截止 */, [formatDate(fTime, 'HH:mm')]);
        } else {
          return i18n.t('pc_kng_studyplan_lbl_deadline'/** {0}截止 */, [getShowTime(fTime, curTime)]);
        }
      }
    }
  }
};
// 处理时间显示
export const handleDateString = (date) => {
  if (!date) return '';
  return date.slice(0, 16).replaceAll('-', '/');
};

export const getTimeString = dataStr => {
  const time = new Date(dataStr.replace(/-/g, '/'));
  return getShowTime(time);
};

export const formatDate = (date, format = 'YYYY-MM-DD HH:mm') => {
  if (!date) return '';
  return dayjs(date).format(format);
};

// 跨年 YYYY-MM-DD HH:mm  今年 MM-DD HH:mm
export const getShowTime = (date, curTime = new Date()) => {
  return date.getFullYear() === curTime.getFullYear() ? formatDate(date, 'MM-DD HH:mm') : formatDate(date, 'YYYY-MM-DD HH:mm');
};
// 跨年 YYYY-MM-DD  今年 MM-DD
export const getShowDate = (date, curTime = new Date()) => {
  return date.getFullYear() === curTime.getFullYear() ? formatDate(date, 'MM-DD') : formatDate(date, 'YYYY-MM-DD');
};

export const getMediaTimeStr = seconds => {
  const h = parseInt(seconds / (60 * 60), 10); // 时
  const m = parseInt((seconds - h * 60 * 60) / 60, 10); // 分
  const s = seconds - h * 60 * 60 - m * 60;
  let data = [];
  if (h > 0) data.push(h < 10 ? `0${h}` : h);
  data.push(m < 10 ? `0${m}` : m);
  data.push(s < 10 ? `0${s}` : s);
  return data.join(':');
};
