var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "article",
    { staticClass: "yxtbiz-ai-robot-joined-project" },
    [
      _vm.projectList.length === 0
        ? _c("EmptyData")
        : [
            _c(
              "header",
              {
                class: [
                  "color-gray-7",
                  _vm.size === "small"
                    ? "font-size-14 lh24"
                    : "font-size-16 lh28",
                ],
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "pc_biz_following_project_found" /* 为您找到以下培训项目： */
                    )
                  )
                ),
              ]
            ),
            _c(
              "main",
              [
                _c(
                  "ul",
                  _vm._l(_vm.projectList, function (project) {
                    return _c(
                      "li",
                      {
                        key: project.projectId,
                        staticClass: "yxtbiz-ai-robot-joined-project__item",
                        on: {
                          click: function ($event) {
                            return _vm.handleProjectClick(project)
                          },
                        },
                      },
                      [
                        _c("yxt-image", {
                          staticClass: "yxtbiz-ai-robot-joined-project__cover",
                          attrs: { src: project.imageUrl },
                        }),
                        _c("div", { staticClass: "ml12 over-hidden" }, [
                          _c(
                            "div",
                            {
                              class: [
                                _vm.size === "small"
                                  ? "ellipsis-2 font-size-14 lh22"
                                  : "ellipsis font-size-16  lh24",
                                "color-gray-10",
                              ],
                            },
                            [_vm._v(_vm._s(project.name))]
                          ),
                          _c(
                            "div",
                            {
                              class: [
                                _vm.size === "small" ? "mt4" : "mt8",
                                "yxtbiz-flex-center lh20 color-gray-7 font-size-12",
                              ],
                            },
                            [
                              !project.completeStatus
                                ? _c(
                                    "div",
                                    { staticClass: "yxtbiz-flex-center" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          class: [
                                            "mr12",
                                            {
                                              "color-orange-6":
                                                project.processRate > 0,
                                            },
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "pc_biz_learned_progress" /* 已学{0}% */,
                                                [project.processRate]
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _c("div", { staticClass: "mr4" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "pc_biz_appraise_finish_status"
                                            )
                                          )
                                        ),
                                      ]),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(_vm.finishedProgress(project))
                                        ),
                                      ]),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "yxtbiz-flex-center" },
                                    [
                                      _c("yxt-svg", {
                                        staticClass: "mr4",
                                        attrs: {
                                          width: "12px",
                                          height: "12px",
                                          "icon-class":
                                            "biz/mobile-result-success",
                                        },
                                      }),
                                      _c("div", { staticClass: "mr12" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "pc_biz_shoptour_lbl_finished" /* 已完成 */
                                            )
                                          )
                                        ),
                                      ]),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(_vm.finishedProgress(project))
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                              _vm.size === "large"
                                ? [
                                    _c("yxt-divider", {
                                      attrs: { direction: "vertical" },
                                    }),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDateTimeFormatted({
                                            startTime: project.startTime,
                                            endTime: project.endTime,
                                          })
                                        )
                                      ),
                                    ]),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm.size === "small"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "mt2 lh20 color-gray-7 font-size-12",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getDateTimeFormatted({
                                        startTime: project.startTime,
                                        endTime: project.endTime,
                                      })
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ],
                      1
                    )
                  }),
                  0
                ),
                _vm.count > 3
                  ? _c("ViewMoreButton", {
                      on: { click: _vm.handleViewMoreClick },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }