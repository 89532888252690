<template>
<div :class="{'ai-source-content': true, 'ai-source-content-fullscreen': fullscreen, 'ai-source-content-search': isSearch  }">
    <div v-if="!msg.isFunction" class="ai-content-source">{{ $t('pc_biz_ai_content_source').d('内容来源:') }}</div>
    <div v-for="(source, index) in contentDetail.linkList" :key="index" @click="goUrl(source.goUrl)">
        <template v-if="source.goUrl">
            <div class="link-content" v-if="source.sourceType">
                <div class="d-content-link">
                    <yxt-svg class="link-svg" width="26px" height="26px" :remote-url='h5Svg' icon-class="ai-link" />
                    <span class="link-text">{{ source.goUrl }}</span>
                </div>
            </div>
            <div class="d-content"  v-else>
                <div class="d-content-left">
                    <img v-if="source.coverUrl" :src="source.coverUrl" />
                    <img v-else :src="linkBgUrl">
                </div>
                <div :class="{'d-content-right': true, 'd-content-right-2':!source.description }">
                    <div :class="{'source-title': true, 'source-title-2': !source.description }">{{ source.name }}</div>
                    <div class="source-description" v-if="source.description">{{ getSource(source.description) }}</div>
                    <div v-if="source.lecturesName || source.contributorsName || source.createFullName" :class="{'source-learning-info': true, 'source-learning-info-2': !source.description}">
                        <template v-if="source.lecturesName">{{ $t('pc_biz_ai_lecturer').d('讲师') + ': ' + source.lecturesName }}</template>
                        <template v-else-if="source.contributorsName">{{ $t('pc_biz_ai_contributor').d('贡献者') + ': ' +  source.contributorsName }}</template>
                        <template v-else-if="source.createFullName">{{ $t('pc_biz_ai_founder').d('创建人') + ': ' + source.createFullName }}</template>
                    </div>
                </div>
                <yxt-svg class="link-right" icon-class="arrow-right" />
            </div>
        </template>
    </div>
</div>
</template>

<script>
import { getStaticBaseUrl } from '../service';
export default {
  name: 'sourceContent',
  props: {
    msg: {
      type: Object,
      default: () => {
        return {};
      }
    },
    contentDetail: {
      type: Object,
      default: () => {
        return {};
      }
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    isSearch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      h5Svg: `${getStaticBaseUrl()}ufd/407a24/assistent/h5/svg/`,
      linkBgUrl: `${getStaticBaseUrl()}ufd/407a24/assistent/pc/img/link-bg.png`
    };
  },
  methods: {
    getSource(info) {
      return info.replace(/<[^>]+>/g, '');
    },
    goUrl(url) {
      window.open(url, '_blank');
    }
  }
};
</script>

<style lang="scss" scoped>
.ai-source-content {
    .d-content {
        display: flex;
        padding: 8px;
        box-sizing: border-box;
        margin-left: -8px;
        margin-right: -8px;
        background: #fff;
        border-radius: 8px;
        margin-bottom: 10px;
        position: relative;
        cursor: pointer;

        .link-right {
            display: inline-block;
            font-size: 16px;
            color: #8c8c8c;
            width: 16px;
            height: 16px;
            margin-top: 28px;
        }

        &:hover {
            background: #f4f5f6;
        }

        &:first-child {
            margin-top: 4px;
            margin-bottom: 20px;
        }

        &:last-child {
            margin-bottom: 4px;
        }

        .d-content-left {
            width: 121px;
            height: 68px;
            margin-right: 12px;

            img {
                width: 121px;
                height: 68px;
                border-radius: 4px;
                object-fit: contain;
            }
        }

        .d-content-right {
            height: 68px;
            flex: 1;

            &.d-content-right-2 {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            .source-title {
                font-size: 14px;
                font-weight: 400;
                color: #262626;
                line-height: 24px;
                max-width: 240px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                &.source-title-2 {
                    margin: 5px 0px;
                }
            }

            .source-description {
                font-size: 12px;
                font-weight: 400;
                color: #757575;
                max-width: 240px;
                line-height: 22px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .source-learning-info {
                font-size: 12px;
                font-weight: 400;
                color: #8C8C8C;
                line-height: 18px;
                max-width: 240px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                &.source-learning-info-2 {
                    margin: 5px 0px;
                }
            }
        }
    }
    .link-content {
        display: flex;
        padding: 8px;
        box-sizing: border-box;
        margin-left: -8px;
        margin-right: -8px;
        background: #fff;
        border-radius: 8px;
        margin-bottom: 10px;
        position: relative;
        cursor: pointer;
        &:last-child {
            margin-bottom: 0px;
        }
        .d-content-link {
            display: flex;
            align-items: center;
            box-sizing: border-box;
            padding: 8px 12px;
            overflow: hidden;
            background: #f4f5f6;
            border-radius: 4px;

            .link-svg {
                margin-bottom: -3px;
            }

            .link-text {
                flex: 1;
                margin-left: 10px;
                overflow: hidden;
                color: #262626;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }

    &.ai-source-content-fullscreen {
        .d-content {
            display: flex;
            margin-bottom: 10px;

            .link-right {
                display: inline-block;
                font-size: 20px;
                width: 20px;
                height: 20px;
                margin-top: 32px;
            }

            &:first-child {
                margin-top: 4px;
                margin-bottom: 20px;
            }

            &:last-child {
                margin-bottom: 4px;
            }

            .d-content-left {
                width: 142px;
                height: 80px;
                margin-right: 12px;

                img {
                    width: 142px;
                    height: 80px;
                    border-radius: 4px;
                    object-fit: contain;
                }
            }

            .d-content-right {
                height: 80px;
                flex: 1;

                .source-title {
                    font-size: 16px;
                    font-weight: 400;
                    color: #262626;
                    line-height: 24px;
                    max-width: 680px;
                }

                .source-description {
                    margin-top: 4px;
                    font-size: 14px;
                    font-weight: 400;
                    max-width: 680px;
                }

                .source-learning-info {
                    font-size: 14px;
                    margin-top: 12px;
                    max-width: 680px;
                }
            }
        }
    }
    &.ai-source-content-search {
        .d-content {
            background: #F5F5F5;
            &:hover {
                background: #fff;
            }
        }
        .link-content {
            background: #F5F5F5;
            .d-content-link  {
                background: #fff;
            }
        }
    }
}
</style>
