var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "over-hidden" }, [
    _c("div", { staticClass: "yxtbiz-ai-robot-comment-item mb4 break" }, [
      _c(
        "div",
        {
          class: [
            _vm.size === "small"
              ? "ellipsis-3 font-size-14 lh22"
              : "ellipsis-2 font-size-16  lh24",
            "color-gray-10",
          ],
        },
        [
          _vm.item.sticky === 1
            ? _c(
                "yxtf-tag",
                {
                  staticClass: "mr8",
                  attrs: { type: "warning", size: "mini" },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("yxtbiz_ai_comments_lbl_excellent")) +
                      "\n      "
                  ),
                ]
              )
            : _vm._e(),
          _vm.handlerStatusToText(_vm.item.auditConclusion, _vm.item.status) &&
          _vm.typeTabsValue !== "3"
            ? _c(
                "yxtf-tag",
                { staticClass: "mr8", attrs: { type: "info", size: "mini" } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.handlerStatusToText(
                          _vm.item.auditConclusion,
                          _vm.item.status
                        )
                      ) +
                      "\n      "
                  ),
                ]
              )
            : _vm._e(),
          _vm._t("title", null, { content: _vm.item }),
        ],
        2
      ),
    ]),
    _c(
      "div",
      { staticClass: "mb4 yxtbiz-ai-robot-comment-item" },
      [
        _vm.isCourse
          ? _c("rate", {
              staticClass: "mr14",
              attrs: { score: _vm.item.score },
            })
          : _vm._e(),
        _c("div", { staticClass: "lh20 color-gray-7 font-size-12" }, [
          _vm._v(_vm._s(_vm.item.createTime)),
        ]),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass:
          "yxtbiz-ai-robot-comment-item lh20 color-gray-7 font-size-12",
      },
      [
        _c(
          "div",
          {
            staticClass: "ellipsis mr4 hand maxwidth200 hover-primary-6",
            class: [_vm.size === "small" ? "maxwidth200" : "maxwidth300"],
            on: {
              click: function ($event) {
                return _vm.openKng(_vm.item)
              },
            },
          },
          [
            _vm._v(
              "\n      【" + _vm._s(_vm.handlerTypeToText(_vm.item)) + "】"
            ),
            _c("span", {
              domProps: { innerHTML: _vm._s(_vm.item.targetName) },
            }),
          ]
        ),
        _c("yxt-divider", { attrs: { direction: "vertical" } }),
        _c(
          "div",
          { staticClass: "ellipsis" },
          [_vm._t("catalogName", null, { catalogName: _vm.item })],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }