<template>
    <yxt-select
      multiple
      prop-label="name"
      hidden-option
      class="yxtbiz-user-tree-select"
      :placeholder='placeholder'
      :value-key="prop"
      :collapse-tags="true"
      size="small"
      v-model="selected"
      :clearable="true"
      slot="reference"
      @clear="clear"
      :popper-append-to-body="false"
      @remove-tag="removeTag"
    >
    <template v-if="isOpenData" slot='tag' slot-scope='{ data }'>
      <yxtbiz-dept-name :name='data.currentLabel'></yxtbiz-dept-name>
    </template>
  </yxt-select>
</template>

<script>
import { Select } from 'yxt-pc';
import { isOpenData } from 'yxt-biz-pc/src/utils/shared';
import DeptName from 'yxt-biz-pc/packages/dept-name';

export default {
  components: {
    YxtSelect: Select,
    YxtbizDeptName: DeptName
  },
  props: {
    value: {
      default: () => [],
      type: Array
    },
    placeholder: {
      type: String
    },
    prop: {
      type: String,
      default: 'id'
    },
    label: {
      type: String,
      default: 'name'
    }
  },
  data() {
    return {
      selected: []
    };
  },
  computed: {
    isOpenData() {
      return isOpenData();
    }
  },
  created() {
    this.setSelected(this.value);
  },
  methods: {
    setSelected(v) {
      if (this.value.length !== 0) {
        this.selected = this.value;
      } else {
        this.selected = [];
      }
    },
    clear() {
      this.$emit('input', []);
    },
    removeTag(tag) {
      this.$emit('remove-tag', tag);
    }
  },
  watch: {
    value(v) {
      this.setSelected(v);
    }
  }
};
</script>

<style lang="scss" scoped></style>
