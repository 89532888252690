<template functional>
  <search-live v-bind="props" :isTrack="true"></search-live>
</template>

<script>
import CommonMixin from '../common/props-mixin';
import SearchLive from '../search-live/index.vue';

export default {
  name: 'TrackLive',
  mixins: [CommonMixin],
  components: {
    SearchLive
  }
};
</script>
