var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yxtbiz-ai-robot-click-jump color-gray-10" },
    [
      _c("span", { staticClass: "yxtbiz-ai-robot-click-jump__text" }, [
        _vm._v(_vm._s(_vm.message)),
      ]),
      _vm.showCountdown
        ? _c("span", { staticClass: "ml8 yxtbiz-ai-robot-click-jump__text" }, [
            _vm._v(_vm._s(_vm.timeInSecond) + "s"),
          ])
        : _vm._e(),
      _vm.showCountdown
        ? _c(
            "yxt-button",
            {
              staticClass: "ml8 yxtbiz-ai-robot-click-jump__button",
              attrs: { type: "text" },
              on: { click: _vm.handleCancelTimerClick },
            },
            [_vm._v(_vm._s(_vm.$t("pc_biz_ai_btn_cancel" /* 取消 */)))]
          )
        : _c(
            "yxt-button",
            {
              staticClass: "ml8 yxtbiz-ai-robot-click-jump__button",
              attrs: { type: "text" },
              on: { click: _vm.goToTargetPage },
            },
            [_vm._v(_vm._s(_vm.$t("pc_biz_click_to_jump" /* 点击跳转 */)))]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }