<template>
  <div class="yxtbiz-global-notice">
    <!-- 停服预告 1 +停服公告 0 -->
    <div class="yxtbiz-nav-global-notice" v-if="topNotice && topNotice.content">
      {{topNotice.content}}
      <div class="pull-right hand" @click="closeNotice(topNotice)" v-if="showClose" >
        <i class="yxt-icon-close"></i>
      </div>
    </div>
  </div>
</template>

<script>
import noticeMixin from './noticeMixin';
// 全局公告组件
export default {
  name: 'YxtbizStopNotice',
  mixins: [noticeMixin]
};
</script>
