var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "article",
    { staticClass: "yxtbiz-ai-robot-track-project" },
    [
      _vm.projectList.length === 0
        ? _c("EmptyData")
        : [
            _c(
              "header",
              {
                class: [
                  "color-gray-7",
                  _vm.size === "small"
                    ? "font-size-14 lh24"
                    : "font-size-16 lh28",
                ],
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.$t("pc_biz_found_projects" /* 为您找到以下项目：*/)
                  )
                ),
              ]
            ),
            _c(
              "main",
              [
                _c(
                  "ul",
                  _vm._l(_vm.projectList, function (project) {
                    return _c(
                      "li",
                      {
                        key: project.projectId,
                        staticClass: "yxtbiz-ai-robot-track-project__item",
                        on: {
                          click: function ($event) {
                            return _vm.handleProjectClick(project)
                          },
                        },
                      },
                      [
                        _c("yxt-image", {
                          staticClass: "yxtbiz-ai-robot-track-project__cover",
                          attrs: {
                            src:
                              project.imageUrl ||
                              `${_vm.$staticBaseUrl}ufd/55a3e0/o2o/pc/other/project.png`,
                          },
                        }),
                        _c("div", { staticClass: "ml12 over-hidden" }, [
                          _c(
                            "div",
                            {
                              class: [
                                _vm.size === "small"
                                  ? "ellipsis-2 font-size-14 lh22"
                                  : "ellipsis font-size-16  lh24",
                                "color-gray-10",
                              ],
                            },
                            [
                              _c(
                                "yxt-tag",
                                {
                                  staticClass:
                                    "mr8 yxtbiz-ai-robot-track-project__item-tag",
                                  attrs: {
                                    type: project.status === 2 ? "" : "info",
                                    size: "mini",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.filterProjectStatus(project.status)
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(_vm._s(project.name)),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              class: [
                                _vm.size === "small" ? "mt4" : "mt8",
                                "yxtbiz-flex-center lh20 color-gray-7 font-size-12",
                              ],
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "pc_biz_learner_num" /* 学员人数：{0} */,
                                      [project.stuCount]
                                    )
                                  )
                                ),
                              ]),
                              _vm.size === "large"
                                ? [
                                    _c("yxt-divider", {
                                      attrs: { direction: "vertical" },
                                    }),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "pc_biz_time" /* 时间：{0} */,
                                            [
                                              _vm.getDateTimeFormatted({
                                                startTime: project.startTime,
                                                endTime: project.endTime,
                                              }),
                                            ]
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm.size === "small"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "mt2 lh20 color-gray-7 font-size-12",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("pc_biz_time" /* 时间：{0} */, [
                                        _vm.getDateTimeFormatted({
                                          startTime: project.startTime,
                                          endTime: project.endTime,
                                        }),
                                      ])
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ],
                      1
                    )
                  }),
                  0
                ),
                _vm.count > 3
                  ? _c("ViewMoreButton", {
                      on: { click: _vm.handleViewMoreClick },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }