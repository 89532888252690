<template>
  <div class="checkedlist">
    <div class="header">
      <span>
        <span>{{ $t('pc_biz_signup_lbl_checked').d('已选') }}</span>
        <span style="color: #999">
          <span style="color: #262626">：{{selectedCount}}</span>
        </span>
      </span>
      <yxt-button type="text" @click="clearAll" v-if="showClearAllBtn">{{$t('pc_biz_labelling_clearall').d('清空')}}</yxt-button>
    </div>
    <main class="checkedlist-main">
      <div class="checkedlist-main-placeholder" v-if="!showClearAllBtn">{{$t('biz_udp_please_select').d('请选择')}}...</div>
      <div class="group-main" v-for="(group, index) in selected" :key="index">
        <div class="group-main-title" v-if="group.selected.length">
          <span>{{group.name}}：</span>
          <span>{{group.selected.length}}</span>
        </div>
        <div class="group-main-content">
          <yxt-tag
          v-for="(name, key) in group.selected"
          :key="key"
          class="mr5"
          size="small"
          type="info"
          :disableTransitions="true"
          style="margin-bottom: 8px;max-width: 100%;"
          closable
          @close="deleteItem(group.id, name)"
          >
            <span class="checkedlist-tag">
              <span>
                <yxt-tooltip  class="item" effect="dark" placement="top">
                  <template slot="content">
                    <span>{{name}}</span>
                  </template>
                  <span>{{name}}</span>
                </yxt-tooltip>
              </span>
            </span>
          </yxt-tag>
        </div>
      </div>
    </main>
  </div>
</template>
<script>

export default {
  props: {
    selected: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    showClearAllBtn() {
      if (!this.selected.length) return false;
      return this.selectedCount > 0;
    },
    selectedCount() {
      if (!this.selected) return 0;
      let count = 0;
      // 已选数量
      this.selected.forEach(node => {
        const len = node.selected.length;
        if (len > 0) {
          count += len;
        };
      });
      return count;
    }
  },
  data() {
    return {
    };
  },
  created() {

  },
  methods: {
    deleteItem(type, name) {
      this.$emit('change', { type, deleteItem: name });
    },
    clearAll() {
      // 清除所有已选项
      this.$emit('change', null);
    }
  }
};
</script>
<style lang="scss" scoped>
  .checkedlist {
    padding: 24px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .header {
      display: flex;
      padding-bottom: 16px;
      justify-content: space-between;
      > span {
        font-weight: 500;
        color: #262626;
        line-height: 22px;
      }
    }
    .checkedlist-main {
      flex: 1;
      height: 100%;
      padding-right: 24px;
      margin-right: -24px;
      overflow: auto;
      .checkedlist-main-placeholder {
        color: #bfbfbf;
        font-size: 14px;
      }
    }
    .group-main {
      margin-bottom: 16px;
      &-title {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #595959;
        line-height: 22px;
        margin-bottom: 8px;
      }
      &—content {
        display: flex;
        justify-content: space-around;
      }
    }
    .checkedlist-tag{
      text-overflow: ellipsis;
      flex: 1 1 auto;
      overflow: hidden;
    }
  }
</style>