import { getNotice, setReadedClosed } from './service';

export default {
  props: {
    // 是否是管理端
    isManager: {
      default: false
    }
  },
  data() {
    return {
      topNotice: null, // 顶部停服预告+停服公告
      notice: null,
      optsNotice: null,
      timer: null,
      showClose: false
    };
  },
  computed: {
    sensors() {
      return window.sensors || window.sensorsDataAnalytic201505;
    }
  },
  created() {
    this.getRecentNotice();
  },
  methods: {
    getRecentNotice() { // 根据应用（管理端、学员端）、当前页面、用户id获取最近一条公告
      this.notice = null;
      this.topNotice = null;
      const noticeType = this.isManager ? null : 0;
      getNotice({
        isManage: this.isManager ? 1 : 0,
        noticeType: noticeType,
        edition: -1
      }).then(res=>{
        res.datas.map(item=>{
          if (item.noticeType === 3) { // 运营公告
            this.optsNotice = item;
            if (item.title && item.content) {
              if (item.operateType === 1) {
                this.isOptsBig = true;
              } else {
                this.isOptsSmall = true;
              }
            }
          } else if (item.noticeType === 2) { // 发版公告
            this.notice = item;
            this.dialogVisible = true;
          } else {
            if (!this.topNotice) {
              this.topNotice = item;
              this.showClose = !(this.isManager && item.noticeType === 0);// 除管理端停服公告不能关闭外，其他均能关闭
              this.setReaded(item);
              this.$emit('show', true);
            }
          }
        });
        this.getRelationDetail && this.getRelationDetail();
      }).catch(e=>{
        this.$emit('show', false);
      });
    },
    setReaded(notice) {
      // if (!this.debounce(notice.id)) return;
      // console.log('设置已读', new Date().getTime());
      setReadedClosed({
        noticeId: notice.id,
        read: 1
      }).then(res=>{
        this.setLastReadedId(notice.id);
        setTimeout(() => {
          this.$store.dispatch('navManageStore/getUnreadNoticeCnt');
        }, 300);
      });
    },
    debounce(id) {
      if (this.getReadedId() === id) return false;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.clearLastReadedId();
      }, 500);
      return true;
    },
    getReadedId() {
      const lastReadedNotice = JSON.parse(localStorage.getItem('lastReadedNotice') || '{}');
      if (lastReadedNotice.userId !== localStorage.getItem('userId')) {
        this.clearLastReadedId();
        return '';
      }
      return lastReadedNotice.noticeId || '';
    },
    clearLastReadedId() {
      localStorage.removeItem('lastReadedNotice');
    },
    setLastReadedId(id) {
      localStorage.setItem('lastReadedNotice', JSON.stringify({
        userId: localStorage.getItem('userId'),
        noticeId: id
      }));
    },
    closeNotice(notice) { // 仅关闭公告----停服预告停服公告，发版公告关闭
      // 神策埋点-公告关闭
      this.submitSensors('msgclosed', { msgcode: notice.id, msgtl: notice.title });
      if (notice.noticeType === 2) {
        this.notice = null;
        this.dialogVisible = false;
      } else if (notice.noticeType === 3) {
        if (notice.operateType === 1) {
          this.isOptsBig = false;
        } else {
          this.isOptsSmall = false;
        }
        // this.optsNotice = null;
      } else {
        this.topNotice = null;
      }
      // if (!this.debounce(notice.id)) return;
      const _self = this;
      setReadedClosed({
        noticeId: notice.id,
        close: 1
      }).then(res=>{
        this.setLastReadedId(notice.id);
        if (notice.noticeType !== 2 && notice.noticeType !== 3) {
          setTimeout(() => {
            _self.getRecentNotice();
          }, 500);
        }
      });
    },
    /**
     * 提交神策日志
     * @param {string} eventName 神策事件名
     * @param {object} params 提交内容属性
     */
    submitSensors(eventName, params) {
      if (!this.sensors || !this.sensors.track || typeof this.sensors.track !== 'function') {
        return console.log('Current page no sensors');
      }
      this.sensors.track(eventName, params || {});
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer);
    setTimeout(() => {
      this.clearLastReadedId();
    }, 500);
  }
};
