var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "checked_list" }, [
    _c(
      "div",
      { staticClass: "checked_list_box" },
      [
        !_vm.virtualRender
          ? _c("yxt-scrollbar", { attrs: { "fit-height": true } }, [
              _c(
                "div",
                {
                  staticStyle: {
                    "margin-bottom": "17px",
                    height: "calc(100% - 17px)",
                  },
                },
                [
                  _vm._l(_vm.dataList, function (source, index) {
                    return [
                      index === 500
                        ? _c(
                            "yxt-tag",
                            {
                              key: "other_list",
                              attrs: {
                                "disable-transitions": "",
                                size: "small",
                                type: "info",
                              },
                            },
                            [_vm._v(_vm._s(source.name))]
                          )
                        : _c("check-list-tag", {
                            key: source.id,
                            staticClass: "mr8",
                            staticStyle: { "margin-bottom": "8px" },
                            attrs: { source: source, index: index },
                            on: {
                              close: function ($event) {
                                return _vm.handleClose(source, index)
                              },
                            },
                          }),
                    ]
                  }),
                ],
                2
              ),
            ])
          : _c(
              "div",
              {
                staticStyle: {
                  "margin-bottom": "17px",
                  height: "calc(100% - 17px)",
                },
              },
              [
                _c("yxtbiz-virtual-list", {
                  staticStyle: { height: "100%", overflow: "hidden" },
                  attrs: {
                    "is-scrollbar": "",
                    "data-sources": _vm.dataList,
                    "data-key": "id",
                    "estimate-size": 32,
                    keeps: 50,
                    "item-tag": "div",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ source, index }) {
                        return [
                          index === 500
                            ? _c(
                                "yxt-tag",
                                {
                                  attrs: {
                                    "disable-transitions": "",
                                    size: "small",
                                    type: "info",
                                  },
                                },
                                [_vm._v(_vm._s(source.name))]
                              )
                            : _c("check-list-tag", {
                                staticClass: "mr8",
                                staticStyle: { "margin-bottom": "8px" },
                                attrs: { source: source, index: index },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleClose(source, index)
                                  },
                                },
                              }),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
      ],
      1
    ),
    _vm.list.length === 0
      ? _c("span", { staticClass: "check-list__placeholder" }, [
          _vm._v(_vm._s(_vm.$t("biz_udp_please_select")) + "..."),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "operation" },
      [
        _c(
          "yxt-button",
          {
            staticClass: "font-size-12 color-gray-9 hover-primary-6",
            attrs: { type: "text", disabled: _vm.list.length === 0 },
            on: { click: _vm.clear },
          },
          [_vm._v(_vm._s(_vm.$t("biz_udp_clear_all")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }