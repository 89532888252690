var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "yxt-scrollbar",
    { ref: "componentScrollBar", staticClass: "page-component__scroll" },
    [
      _c(
        "div",
        { staticClass: "page-container page-component" },
        [
          _c(
            "yxt-scrollbar",
            { staticClass: "page-component__nav" },
            [
              _c("side-nav", {
                attrs: {
                  data: _vm.navsData[_vm.lang],
                  base: `/${_vm.lang}/component`,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "page-component__content" },
            [_c("router-view", { staticClass: "content" }), _c("footer-nav")],
            1
          ),
          _vm.showBackToTop
            ? _c("yxt-backtop", {
                attrs: {
                  target: ".page-component__scroll .yxt-scrollbar__wrap",
                  right: 100,
                  bottom: 150,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }