<template>
  <div class="yxtbiz-dept-manager-tree">
    <yxt-tree :data="data"
              v-bind="$attrs"
              v-on="$listeners"
              ref="tree"
              node-key="id"
              wrap-width="100%"
              :show-checkbox="showCheckbox"
              >
      <template slot-scope="{data, node}">
        <span>
          <yxtbiz-dept-name :name="data.name"></yxtbiz-dept-name>
          <span v-if="deptCountAvailable && data.parentId">({{data.userCount}})</span>
          <template>
            <yxt-tag size="mini" class="ml12" v-if="data.manager === 1">{{$t('biz_tag_director')}}</yxt-tag>
            <yxt-tag size="mini" type="info" v-if="data.manager === 2" class="ml12">{{$t('biz_tag_parttime_manage')}}</yxt-tag>
        </template>
        </span>
      </template>
    </yxt-tree>
  </div>
</template>
<script>
import { getManageDeptTree } from '../../dept-tree/src/service';

export default {
  name: 'YxtbizDeptManagerTree',
  props: {
    showCheckbox: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      data: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      deptCountAvailable: false
    };
  },
  created() {
    getManageDeptTree().then(res => {
      this.data = res.datas;
      if (this.showCheckbox) {
        res.datas[0] && (res.datas[0].disabled = true);
      }
      this.deptCountAvailable = res.datas[0] && res.datas[0].deptCountAvailable;
      this.$emit('loaded');
    });
  },
  mounted() {
  },
  methods: {
    getRef() {
      return this.$refs['tree'];
    }
  }
};
</script>
