<template>
  <a :style="{ display: mode }" v-if="pageUrl" class="init-a-style" :href="pageUrl" @click="e => e.preventDefault()">
    <slot></slot>
  </a>
  <span v-else>
    <slot></slot>
  </span>
</template>
<script>
// 该组件使用时不能在外部添加事件，需要在该组件标签外部或里面在包一层加自定义事件
export default {
  name: 'ALink',
  props: {
    url: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'inline'
    },
    pageNode: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    pageUrl() {
      if (this.url) return this.url;
      if (this.pageNode) return this.getPageUrl(this.pageNode);
      return '';
    }
  },
  methods: {
    getPageUrl(pageNode) {
      if (
        !pageNode.pageUrl &&
        pageNode.subMenuInfoList &&
        pageNode.subMenuInfoList.length > 0
      ) {
        const pageUrl = this.getPageUrl(pageNode.subMenuInfoList[0]);
        if (pageUrl) return pageUrl;
      } else {
        return pageNode.pageUrl;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
  .init-a-style {
    color: inherit;
    outline: none;
    text-decoration: none;
    user-select: none;
    word-break: keep-all;
  }
  .init-a-style:link {
    text-decoration: none;
  }
  .init-a-style:link:visited {
    text-decoration: none;
  }
  .init-a-style:hover {
    text-decoration: none;
  }
  .init-a-style:active {
    text-decoration: none;
  }
  .init-a-style:focus {
    text-decoration: none;
  }
</style>