<template>
  <yxt-row type='flex' class="yxt-user-selector-container yxt-user-multi-container">
    <div class="yxtbiz-user-search-wrap">
      <yxt-input
        class="keyword"
        @keyup.enter.native="getRoleData(true)"
        v-model="params.keyWords"
        size="small"
        :placeholder="$t('biz_udp_enter_rolename')"
        style="width: 240px;"
        searchable
        @search='getRoleData(true)'
      >
      </yxt-input>
    </div>
    <yxt-table
      :height="tableHeight"
      :ref="refTable"
      class="mt16 yxt-user-table"
      @select="selectRole"
      @select-all="selectAllRole"
      row-key="id"
      v-loading="loading"
      :data="role"
      style="width:100%;"
    >
      <yxt-table-column
        v-if="multiple"
        :show-overflow-tooltip="false"
        class-name="yxtbiz-table-selection"
        type="selection"
        width="42"
        reserve-selection
        clear-padding="right"
      ></yxt-table-column>
      <yxt-table-column
        v-else
        :show-overflow-tooltip="false"
        width="40"
      >
        <template slot-scope="scope">
          <yxt-radio v-model="singleSelect" @change="selectRole(scope.row.id, scope.row)" :label="scope.row.id"></yxt-radio>
        </template>
      </yxt-table-column>
      <yxt-table-column
        width="18"
        align="right"
        clear-padding="left-right"
        v-if="multiple"
        >
        <template slot="header">
          <yxt-dropdown placement="bottom-start" @command="tableHeaderCommand">
              <span> </span>
              <yxt-dropdown-menu slot="dropdown">
                <yxt-dropdown-item command="selectCurrent">{{$t('pc_biz_enroll_select_current_page').d('选择本页')}}</yxt-dropdown-item>
                <yxt-dropdown-item command="selectAll" >{{$t('pc_biz_enroll_select_all').d('选择全部')}}</yxt-dropdown-item>
                <yxt-dropdown-item command="clearSelect" >{{$t('pc_biz_enroll_clear_all').d('清空所选')}}</yxt-dropdown-item>
              </yxt-dropdown-menu>
          </yxt-dropdown>
        </template>
      </yxt-table-column>
      <yxt-table-column
        prop="name"
        show-overflow-tooltip
        :label="$t('biz_udp_role')"
        min-width="180"
      >
      </yxt-table-column>
      <yxt-table-column
        show-overflow-tooltip
        prop="remark"
        :label="$t('pc_biz_udp_lbl_description')"
        min-width="180"
      >
      </yxt-table-column>
      <yxt-table-column
        prop="userCount"
        :label="$t('pc_biz_udp_lbl_userCount')"
        align='right'
      >
      </yxt-table-column>
      <template slot="empty">
        <div style="line-height:normal">
          <yxt-empty />
        </div>
      </template>
    </yxt-table>
    <yxt-row type='flex' align='middle' class="yxtbiz-user-selector-mask mt16">
      <yxt-row class="col-flex-1" type='flex' align='middle'>
      </yxt-row>
      <yxt-pagination
        small
        class="pages"
        :page-size="params.limit"
        :page-sizes="[5, 10, 20, 30, 50, 100]"
        @size-change="handleSizeChange"
        :current-page.sync="currentPage"
        layout="total, prev, pager, next"
        @current-change="handleCurrentChange"
        :total="total"
        simple-total
      >
      </yxt-pagination>
    </yxt-row>
  </yxt-row>
</template>

<script>
import { getRoleList } from '../service';
import echoMixin from '../../mixins/echo';
import resizeTable from '../../mixins/resizeTable';
import authMixin from '../../mixins/auth';
import commonMixin from '../../mixins/common';
import { Table, TableColumn, Pagination, Empty } from 'yxt-pc';

export default {
  name: 'Role',
  components: {
    YxtTable: Table,
    YxtTableColumn: TableColumn,
    YxtPagination: Pagination,
    YxtEmpty: Empty
  },
  mixins: [echoMixin, resizeTable, authMixin, commonMixin],
  data() {
    return {
      tableHeight: 0,
      refTable: 'roleTable',
      currentPage: 1,
      total: 0,
      loading: true,
      params: {
        keyWords: '',
        limit: 20,
        offset: 0
      },
      role: [],
      defaultOptions: {
        isBoss: 0
      }
    };
  },
  mounted() {
    this.getRoleData();
    this.updateSelected(this.selectData.role);
  },
  computed: {
    optionsComputed() {
      if (!this.options) {
        return this.defaultOptions;
      } else {
        return Object.assign(this.defaultOptions, this.options);
      }
    }
  },
  watch: {
    selectData: {
      deep: true,
      handler(result) {
        this.$refs[this.refTable].clearSelection();
        const position = [...result.role];
        position.forEach(row => {
          this.$refs[this.refTable].toggleRowSelection(row, true);
        });
        this.role = [...this.role];
      }
    }
  },
  methods: {
    tableHeaderCommand(row) {
      this[row]();
    },
    selectCurrent() {
      // 选择本页
      this.selectCurrData('role');
    },
    selectAll() {
      // 选择所有
      this.loadAll();
    },
    clearSelect() {
      // 清空所选
      this.$refs[this.refTable].clearSelection();
      this.$emit('change', [], 'role');
    },
    updateSelected(selected) {
      // 获取已选中数据并回显
      if (selected) {
        this.$refs[this.refTable].clearSelection();
        selected.forEach(row => this.$refs[this.refTable].toggleRowSelection(row, true));
      }
    },
    selectAllRole(item) {
      this.$emit('change', item, 'role');
    },
    selectRole(item, row) {
      // 选中数据
      if (Array.isArray(item)) {
        // 多选
        this.$emit('change', item, 'role');
      } else {
        this.$emit('change', [row], 'role');
      }
    },
    loadAll() {
      this.loading = true;
      const params = Object.assign({}, this.params, {
        limit: this.total,
        offset: 0
      });
      params.isBoss = this.optionsComputed.isBoss;
      params.navCode = this.functionCode;
      params.dataPermissionCode = this.dataPermissionCode;
      getRoleList(params).then(res => {
        if (res) {
          let data = res.datas.map(item => {
            item['_type'] = 3;
            return item;
          });
          this.$refs[this.refTable].clearSelection();
          data.forEach(row => this.$refs[this.refTable].toggleRowSelection(row, true));
          this.selectAllData(data, 'role');
        }
      }).catch(e => {
        console.error(e);
      }).then(() => {
        this.loading = false;
      });
    },
    getRoleData(reset = false) {
      this.loading = true;
      if (reset) {
        this.params.offset = 0;
        this.currentPage = 1;
      }
      this.params.isBoss = this.optionsComputed.isBoss;
      this.params.navCode = this.functionCode;
      this.params.dataPermissionCode = this.dataPermissionCode;
      getRoleList(this.params).then(res => {
        if (res) {
          this.role = res.datas.map(item =>{
            item['_type'] = 3;
            return item;
          });
          this.total = res.paging.count;
          this.update();
        }
      }).catch(e => {
        console.error(e);
      }).then(() => {
        this.loading = false;
      });

    },
    handleCurrentChange(val) {
      this.params.offset = (val - 1) * this.params.limit;
      this.getRoleData();
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.params.limit = val;
      this.getRoleData();
    }
  }
};
</script>
