var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.currentNav && _vm.currentNav.subMenuInfoList.length > 0
    ? _c("div", { staticClass: "yxtbiz-nav-main__stu-left" }, [
        _c(
          "div",
          { staticClass: "yxtbiz-nav-left-stu" },
          [
            _c(
              "div",
              {
                staticClass: "yxtbiz-nav-left-stu__title",
                attrs: { title: _vm._f("Globalize")(_vm.cnavs[0], "name") },
              },
              [
                _c("h1", [
                  _vm._v(_vm._s(_vm._f("Globalize")(_vm.cnavs[0], "name"))),
                ]),
              ]
            ),
            _c("yxtf-divider"),
            _c(
              "ul",
              { staticClass: "yxtbiz-nav-left-stu__navs" },
              _vm._l(_vm.currentNav.subMenuInfoList, function (menu) {
                return _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: menu.showed,
                        expression: "menu.showed",
                      },
                    ],
                    key: menu.id,
                    staticClass: "hover-primary-6-i",
                    class: { "color-primary-6-i": _vm.isMenuSelected(menu) },
                    attrs: { name: menu.id, index: menu.code },
                    on: {
                      click: function ($event) {
                        return _vm.toPage(menu)
                      },
                    },
                  },
                  [
                    _c(
                      "a-link",
                      { attrs: { url: menu.pageUrl, "page-node": menu } },
                      [_vm._v(_vm._s(_vm._f("Globalize")(menu, "name")))]
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }