<template>
  <div :class="['yxtbiz-nav-top', tinyNav && 'yxtbiz-nav-top-tiny']">
    <div @click="goStuIndex">
      <a-link 
        v-if="!tinyNav"
        :url="`${defaultProductPath}/#/studyindex?norequest=1`"
        mode="flex"
        class="yxtbiz-nav-top__logo hand"
        :style="`background-image:url(${logo})`"
      >
      </a-link>
    </div>
    <div :class="['yxtbiz-nav-top__menus', tinyNav && 'yxtbiz-nav-top-tiny__menus']">
      <ul :id="navMenusAllId">
        <li
          class="yxtbiz-nav-top__menu hover-primary-6-i yxtbiz-nav-top__menu-item"
          :class="{
            'yxtbiz-nav-top__menu--active': menu.code === pageCodes[0],
            'color-primary-6-i': menu.code === pageCodes[0]
          }"
          v-for="(menu, index) in navsOnMenu"
          :key="menu.id"
          @click="toSubPage(menu)"
          v-show="index <= menuSet.max -1 "
        > 
          <yxt-popover v-if="menu.id === '_app_all'"
                       ref="appPopper"
                       trigger="hover"
                       :visible-arrow="false"
                       placement="bottom-start"
                       @show="changeAppPopper"
                       @hide="changeAppPopper"
                       :popper-class="`yxtbiz-nav-top__sub-menus ${tinyNav? 'yxtbiz-nav-top-tiny__sub-menus' : ''}`">
            <div class="yxtbiz-nav-top__apps">
              <div class="yxtbiz-nav-top__block" v-for="col in [0, 1]" :key="col">
                <template v-for="(smenu, index) in menu.subMenuInfoList">
                    <a-link
                      :key="smenu.id"
                      v-if="index % 2 === col && smenu.subMenuInfoList && smenu.subMenuInfoList.length >0"
                      class="yxtbiz-nav-top__app"
                      mode="flex"
                      :url="smenu.pageUrl"
                      :page-node="smenu"
                    >
                      <div style="display: flex;" @click="toSubPage(smenu)">
                        <yxt-image
                          :class="['yxtbiz-nav-top__app-icon', tinyNav && 'yxtbiz-nav-top-tiny__app-icon']"
                          :src="smenu.icon">
                        </yxt-image>
                        <div class="yxtbiz-nav-top__app-menu">
                          <span>{{ smenu | Globalize('name') }}</span>
                          <span v-if="smenu.remark">{{ smenu.remark }}</span>
                        </div>
                      </div>
                    </a-link>
                </template>
              </div>
            </div>
            <template slot="reference">
              <span :class="['yxtbiz-nav-top__menu-name', tinyNav && 'yxtbiz-nav-top-tiny__menu-name']">
                <template
                  v-if="tinyNav && menu.code === pageCodes[0] && cnavs && cnavs[1]">{{ cnavs[1] | Globalize('name')
                  }}</template>
                <template v-else>{{ $t('pc_biz_nav_btn_allapplication') }}</template>
                <i :class="showAppPopper ? 'yxt-icon-arrow-up' : 'yxt-icon-arrow-down'"></i>
              </span>
            </template>
          </yxt-popover>
          <span :class="['yxtbiz-nav-top__menu-name', tinyNav && 'yxtbiz-nav-top-tiny__menu-name']"
                v-else><a-link :url="menu.pageUrl" :page-node="menu">{{ menu | Globalize('name') }}</a-link></span>
        </li>
        <!-- 管理端展开更多 -->
        <li
          v-show="menuSet.more"
          class="yxtbiz-nav-top__menu_more yxtbiz-nav-top__menu"
          :id="navMenuMoreId"
          >
          <yxt-dropdown
              @visible-change="isShowMore = !isShowMore"
              placement="bottom-start"
              custom-elm
              trigger="hover"
              class=""
            >
            <span class="yxtbiz-nav-top__menu-name hover-primary-6-i" :class="{ 'yxtbiz-nav-top-tiny__menu-name':tinyNav ,'yxtbiz-nav-top-tiny__menu-name-font':tinyNav}">
            {{ $t('pc_biz_nav_btn_more')}}
              <i :class="!isShowMore ? 'yxt-icon-arrow-down' : 'yxt-icon-arrow-up'"
              />
            </span>
            <yxt-dropdown-menu
                slot="dropdown"
                class=""
              >
                <template v-for="(sMenu, sIndex) in navsOnMenu">
                  <yxt-dropdown-item
                    v-if="sIndex + 1 > menuSet.max"
                    v-show="sMenu.showed"
                    :key="sMenu.id"
                    @click.native="() => toSubPage(sMenu)"
                    >
                    <a-link :url="sMenu.pageUrl" :page-node="sMenu">{{
                        sMenu | Globalize('name')
                      }}</a-link>
                  </yxt-dropdown-item>
                </template>
            </yxt-dropdown-menu>
          </yxt-dropdown>
         </li>
      </ul>
    </div>
    <yxt-row class="yxtbiz-nav-top__my-panel" type="flex" align="middle">
      <!-- 产品切换入口 -->
      <nav-production-enter :tiny="tinyNav"></nav-production-enter>
      <!-- ai助手 -->
      <nav-ai-helper  :isXxv2="isXxv2" :tiny="tinyNav"></nav-ai-helper>
      <!-- 通知中心 -->
      <yxt-tooltip :content="$t('biz_core_notice_center').d('通知中心')">
        <a-link mode="block" :url="`${defaultProductAllPath}/#/user/noticecenter`" class="yxtbiz-nav-top__notice-bell">
          <notice-bell />
        </a-link>
      </yxt-tooltip>
      <!-- 头像多语言等切换-->
      <yxt-dropdown trigger="hover" :hide-on-click="false">
        <yxt-avatar class="hand yxtbiz-nav-top__my-avatar" shape="circle" :size="`${tinyNav?'20':'30'}px`"
                    :username="userInfo.userShortName" :imgUrl="userInfo.headPictureUrl">
        </yxt-avatar>
        <span :class="['color-gray-9 yxtbiz-nav-top__avatar-name', tinyNav && 'yxtbiz-nav-top-tiny__avatar-name' ]">
          <span><yxtbiz-user-name :name="userInfo.userShortName" /></span>
        </span>
        <yxt-dropdown-menu slot="dropdown" class="yxtbiz-nav-top__my-menus" :scrollable="false">
          <nav-org-list :className="['yxtbiz-nav-top__my-menu', tinyNav && 'yxtbiz-nav-top-tiny__my-menu', 'color-gray-9']"></nav-org-list>
          <!-- 学员端 -->
          <yxt-dropdown-item @click.native="goStuIndex" :disabled="goStudentButtonLoading">
            <span
              :class="['yxtbiz-nav-top__my-menu', tinyNav && 'yxtbiz-nav-top-tiny__my-menu']">{{ $t('pc_biz_nav_btn_gostudent')
              }}</span>
          </yxt-dropdown-item>
          <yxt-dropdown-item @click.native="personCenter" v-if="!EDITION_TOOL">
            <a-link
              :url="isOpenData() ? `${allPath}/#/user/noticecenter` : `${allPath}/#/userinfo`"
              :class="['yxtbiz-nav-top__my-menu', tinyNav && 'yxtbiz-nav-top-tiny__my-menu']">{{ $t('pc_biz_nav_btn_usercenter')
              }}</a-link>
          </yxt-dropdown-item>
          <yxt-dropdown-item v-if="$attrs.isOpenI18n && isXxv2" class="yxtbiz-nav-top__hover-lang">
            <yxtbiz-nav-lang v-bind="$attrs" placement="left" :append-to-body="false">
              <span
                :class="['yxtbiz-nav-top__my-menu', tinyNav && 'yxtbiz-nav-top-tiny__my-menu', 'color-gray-9']">{{ $t('pc_biz_core_showlanguage')
                }}</span>
            </yxtbiz-nav-lang>
          </yxt-dropdown-item>
          <yxt-dropdown-item @click.native="logout" v-if="!isISV && !isHideLogout">
            <span
              :class="['yxtbiz-nav-top__my-menu', tinyNav && 'yxtbiz-nav-top-tiny__my-menu']">{{ $t('pc_biz_nav_btn_logout')
              }}</span>
          </yxt-dropdown-item>
        </yxt-dropdown-menu>
      </yxt-dropdown>
      <!-- 工作台 -->
      <work-bench  :mainPathname="mainPathname" :tiny="tinyNav"></work-bench>
    </yxt-row>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

import {
  Avatar,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Popover,
  Image
} from 'yxt-pc';
import {
  Globalize,
  goSubNavPage,
  getShortName,
  uerLogout,
  platformDirect,
  goFirstPage,
  SOURCE_100_WXISV,
  EDITION_TOOL,
  checkNavMore
} from './common';
import avt from './img/avatar.png';
import NoticeBell from './notice-bell.vue';
import { isOpenData } from 'yxt-biz-pc/src/utils/shared';
// commonUtils没有使用 导致jenkins编译报错，注释
// import commonUtils from 'yxt-biz-pc/packages/common-util/index';
import AuditCenterEnter from './audit-center-enter.vue';
import NavOrgList from './nav-org-list.vue';
import WorkBench from './work-bench.vue';
import { getNavBytokenV2Catch } from './userNavs';
import navProductionEnter from './nav-production-enter';
import { common } from 'yxt-biz-pc/packages/common-util/index.common';
import { productionUtil } from '../../../common-util/productionUtil';
import navAiHelper from './nav-ai-helper';
import ALink from './alink.vue';
import { defaultProductAllPath, defaultProductPath } from './const';
export default {
  name: 'YxtbizNavTop',
  components: {
    YxtAvatar: Avatar,
    YxtDropdown: Dropdown,
    YxtDropdownMenu: DropdownMenu,
    YxtDropdownItem: DropdownItem,
    YxtPopover: Popover,
    YxtImage: Image,
    NoticeBell,
    AuditCenterEnter,
    NavOrgList,
    WorkBench,
    navProductionEnter,
    navAiHelper,
    ALink
  },
  data() {
    return {
      defaultProductAllPath,
      defaultProductPath,
      EDITION_TOOL,
      SOURCE_100_WXISV,
      defaultAvatar: avt,
      currentRoute: [],
      currentPageCode: '',
      navLoaded: false,
      // msgCount: 0, // 未读消息数量
      menuSet: {
        max: 999,
        more: false
      },
      showAppPopper: false,
      isShow: false,
      goStudentButtonLoading: false,
      isShowMore: false
    };
  },
  props: {
    // 是否导初始页
    isRedirectFirstPage: {
      type: Boolean,
      default: false
    },
    // 一级导航的code，导初始页时会寻找第一个此导航下有权限的页面
    firstPageCode: {
      type: String,
      default: ''
    },
    mainPageCode: {
      type: String,
      default: ''
    },
    mainPathname: {
      type: String,
      default: ''
    },
    // 静态的导航数据，传入时不再读取数据库的导航信息
    navDatas: {
      type: Object,
      default: null
    },
    tinyNav: {
      type: Boolean,
      default: false
    },
    // 页面跳转路径
    allPath: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState({
      collapse: state => state.navManageStore.collapse
    }),
    ...mapGetters('navManageStore', ['isISV']),
    logo() {
      return window.localStorage.logoUrl;
    },
    schedules() {
      return this.$store.state.navManageStore.schedules;
    },
    navConfigs() {
      return this.$store.state.navManageStore.schedules;
    },
    homepage() {
      let url = '/';
      if (this.$store.state.navManageStore.navs.enableAppMenus.length &&
        this.$store.state.navManageStore.navs.enableAppMenus[0].pageUrl) {
        url = this.$store.state.navManageStore.navs.enableAppMenus[0].pageUrl;
      }
      return url;
    },
    userInfo() {
      return {
        userName: window.localStorage.username,
        userShortName: window.localStorage.fullname,
        orgName: window.localStorage.orgName,
        headPictureUrl: window.localStorage.imgUrl
      };
    },
    elpsName() {
      let n = '';
      if (this.userInfo && this.userInfo.userShortName) {
        n = getShortName(this.userInfo.userShortName);
      }
      return n;
    },
    pageCodes() {
      return this.$store.state.navManageStore.pageCodes;
    },
    cnavs() {
      return this.$store.state.navManageStore.cnavs;
    },
    currentNav() {
      if (!this.$store.state.navManageStore.lastUpdateNavStamp ||
        this.$store.state.navManageStore.navs.enableAppMenus.length === 0 ||
        this.$store.state.navManageStore.navType !== 2) {
        this.navLoaded = false;
        return {};
      }
      this.navLoaded = true;
      return this.$store.state.navManageStore.navs;
    },
    // 所有显示在导航上的菜单
    navsOnMenu() {
      if (!this.currentNav || !this.currentNav.enableAppMenus) {
        return [];
      }
      console.log('navsOnMenu', this.currentNav.enableAppMenus.filter((m) => {
        return m.showed;
      }));
      return this.currentNav.enableAppMenus.filter((m) => {
        return m.showed;
      });
    },
    msgCount() {
      return this.$store.state.navManageStore.messageCount;
    },
    isHideLogout() {
      return this.$store.state.navManageStore.isHideLogout;
    },
    navMenuMoreId() {
      return 'navMenuMoreManage';
    },
    navMenusAllId() {
      return 'navMenusAllManage';
    },
    // 是否是绚星
    isXxv2() {
      return productionUtil.isOneProduct('xxv2');
    }
  },
  watch: {
    'navLoaded'(val, oldVal) {
      if (!oldVal && val) {
        this.toFirstPage();
      }
      this.$nextTick(() => {
        this.setMoreShow();
      });
    },
    'isRedirectFirstPage'() {
      this.toFirstPage();
    },
    navsOnMenu() {
      this.$nextTick(() => {
        this.setMoreShow();
      });
    }
  },
  mounted() {
    console.log(this.$refs.appPopper);
    this.addResizeWatch();
  },
  methods: {
    ...mapActions('navManageStore', ['setCollapse']),
    isOpenData() {
      return isOpenData();
    },
    showMoreMenu() {
      this.moreMenuVisible = true;
    },
    hideMoreMenu() {
      this.moreMenuVisible = false;
    },
    redirectIndex() {
      location.href = this.homepage;
    },
    toSubPage(page) {
      goSubNavPage(page, this.mainPathname, this);
    },
    toFirstPage() {
      if (this.isRedirectFirstPage && this.navLoaded) {
        goFirstPage(this.currentNav.enableAppMenus, this.mainPathname, this, this.firstPageCode);
      }
    },
    logout() {
      window.sessionStorage.removeItem('yxt_factors');
      uerLogout(1); // 退出登录
    },
    personCenter() {
      window.location.href = isOpenData() ? `${this.allPath}/#/user/noticecenter` : `${this.allPath}/#/userinfo`; // 个人中心
    },
    workBench() {
      window.location.href = `${this.allPath}/#/index`; // 工作台
    },
    goMsgCenter() {
      window.location.href = `${this.allPath}/#/inbox`; // 站内信
    },
    async goStuIndex() {
      try {
        // 先校验是否有学员端导航在跳转
        this.goStudentButtonLoading = true;
        // 奇点和绚星都去绚星的学员端
        window.goProduct = 'xxv2';
        const res = await getNavBytokenV2Catch(1, undefined, undefined, window.goProduct);
        window.goProduct = '';
        const nav = res.datas;
        this.goStudentButtonLoading = false;
        if (nav && !nav.find(item => item.showed && item.parentId === window.localStorage.getItem('orgId'))) {
          // this.$message.warning('无学员端导航权限');
          throw new Error('无学员端导航权限');
        }
        // 有权限才能删除工作台数据
        this.$store.dispatch('navManageStore/setWorkbenchNavList', []);
        platformDirect(2, '/#/studyindex?norequest=1');
      } catch (e) {
        this.goStudentButtonLoading = false;
        this.$message.warning(e.message);
      }
    },
    changeAppPopper() {
      this.showAppPopper = !this.showAppPopper;
    },
    // 计算是否显示更多
    setMoreShow() {
      if (this.navLoaded) {
        Object.assign(
          this.menuSet,
          checkNavMore(
            this.navMenusAllId,
            this.navMenuMoreId,
            this.navMenusAllId,
            true,
            'yxtbiz-nav-top__menu-item',
            20
          )
        );
      }
    },
    // 添加监听
    addResizeWatch() {
      const dom = document.getElementById(this.navMenusAllId);
      if (dom) {
        common.addResizeListener(dom, this.setMoreShow);
      }
    }
  },
  filters: {
    Globalize(data, key) {
      return Globalize(data, key);
    }
  },
  beforeDestroy() {
    const dom = document.getElementById(this.navMenusAllId);
    if (dom) {
      common.removeResizeListener(dom, this.setMoreShow);
    }
  }
};
</script>

<style lang="scss" scoped></style>
