<template>
  <div class="yxtbiz-select-queslib">
    <div class="clearfix font-size-14">
      <!-- 题库分类 -->
      <catalogDropdown
        :data="datasCatalog"
        class="pull-left mr12"
        :dropToolTip="
          $t('pc_biz_lbl_selectcatalogforsearch', [
            $t('pc_biz_ote_lbl_quesbank')
          ])
        "
        :catalogId.sync="datasSearch.catalogId"
        :catalogName.sync="datasSearch.catalogName"
        @change="selectCatalog"
      ></catalogDropdown>
      <yxt-input
        style="width: 240px"
        class="pull-left"
        :placeholder="
          $t('pc_biz_lbl_inputnameforsearch', [
            $t('pc_biz_ote_lbl_quesbankname')
          ])
        "
        v-model.trim="datasSearch.name"
        searchable
        @search="nameSearch"
        maxlength="50"
      >
      </yxt-input>
    </div>
    <yxt-table
      :ref="refTable"
      :height="tableHeight"
      v-loading="loading"
      class="mt16"
      :data="datasList"
      :default-sort="{ prop: 'updateTime', order: 'desc' }"
      @sort-change="sortChange"
      @select-all="selectAll"
      @select="select"
    >
      <yxt-table-column
        :selectable="selectable"
        type="selection"
        :show-overflow-tooltip="false"
        width="38"
        align="right"
        clear-padding="left-right"
      >
      </yxt-table-column>
      <yxt-table-column
        :label="$t('pc_biz_ote_lbl_quesbankname')"
        :show-overflow-tooltip="false"
      >
        <template slot-scope="scope">
          <yxt-tooltip
            open-filter
            :max-width="300"
            effect="dark"
            class="ellipsis"
            :content="scope.row.name"
            placement="top"
          >
            <span
              @click="goQuesLibPreview(scope.row.id)"
              type="primary"
              class="color-primary-6 hand v-mid"
            >
              {{ scope.row.name }}
            </span>
          </yxt-tooltip>
          <yxt-tag v-if="scope.row.secrecy === 1"
                    key="保密"
                    type="warning"
                    size="mini"
                    class="ml8 v-mid">
            {{ $t('pc_ote_tip_secrecy') }}
          </yxt-tag>
        </template>
      </yxt-table-column>
      <yxt-table-column
        :label="$t('pc_biz_ote_lbl_queslibcatalog')"
        prop="catalogName"
        width="140"
        align="left"
        show-overflow-tooltip
      ></yxt-table-column>
    </yxt-table>
    <div class="mt16 clearfix">
      <yxt-pagination
        class="pull-right"
        :page-size="datasSearch.limit"
        :total="count"
        :current-page="page"
        layout="total, prev, pager, next"
        @current-change="pageChange"
        @size-change="sizeChange"
      ></yxt-pagination>
    </div>
    <!-- 题库查看 -->
    <yxt-drawer
      ref="queslibViewDrawer"
      size="640px"
      :title="$t('pc_biz_ote_lbl_queslibview')"
      :visible.sync="queslibDetailData.dialogVisible"
      :before-close="handleClose"
      :destroyOnClose="true"
    >
      <ques-lib-view :id="queslibDetailData.id"></ques-lib-view>
    </yxt-drawer>
  </div>
</template>

<script>
import { getQuesLibListOG, getCatalogListForSelectOG } from './service';
import resizeTable from '../../user-selector/mixins/resizeTable';
import { Table, TableColumn, Pagination, Tooltip, Tree } from 'yxt-pc';
import catalogDropdown from '../../question-selector/src/selector/catalogDropdown.vue';
import rootCatalog from '../../question-selector/src/selector/rootCatalog';
import quesLibView from './quesLibView.vue';

export default {
  name: 'YxtbizQuesLibsSelector',
  components: {
    quesLibView,
    catalogDropdown,
    YxtTable: Table,
    YxtTableColumn: TableColumn,
    YxtPagination: Pagination,
    YxtTooltip: Tooltip,
    YxtTree: Tree
  },
  mixins: [resizeTable, rootCatalog],
  props: {
    value: {
      type: Array,
      default: () => []
    },
    selectable: {
      type: Function,
      default: () => true
    }
  },
  watch: {
    value(v) {
      this.formatModel(v);
    },
    catalogSearch(val) {
      this.$refs.tree.filter(val);
    }
  },
  data() {
    return {
      isAdmin: window.localStorage.admin, // 超管标识
      userId: window.localStorage.userId, // 当前用户ID
      catalogSearch: '',
      refTable: 'stb',
      tableHeight: 0,
      page: 1,
      queslibDetailData: {
        dialogVisible: false,
        id: ''
      },
      datasSearch: {
        disabled: 0,
        catalogId: '',
        name: '',
        limit: 20,
        offset: 0,
        orderby: 'updateTime',
        direction: 'DESC'
      },
      loading: true,
      totalPage: 0,
      count: 0,
      datasList: [],
      datasCatalog: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      selected: []
    };
  },
  created() {
    console.log(this.value);
    this.formatModel(this.value);
    this.getCatalogList();
    this.getQuesLibList();
  },
  methods: {
    formatModel(v) {
      this.selected = {};
      if (v) {
        v.forEach(obj => {
          this.selected[obj.id] = obj;
        });
        this.setChecked();
      }
    },
    setChecked() {
      this.datasList.forEach(obj => {
        if (this.selected.hasOwnProperty(obj.id)) {
          this.$refs[this.refTable].toggleRowSelection(obj, true);
        } else {
          this.$refs[this.refTable].toggleRowSelection(obj, false);
        }
      });
    },
    filterNode(value, data) {
      return !value || data.label.indexOf(value) !== -1;
    },
    selectCatalog(item) {
      this.page = 1;
      this.datasSearch.offset = 0;
      this.datasSearch.catalogId = item.id;
      this.datasSearch.routingPath = item.routingPath;
      this.getQuesLibList();
    },
    getCatalogList() {
      this.setCatalogType(0);
      this.datasCatalog = this.wrapRootCatalog();
      getCatalogListForSelectOG()
        .then(catalogs => {
          this.datasCatalog[0].children = [];
          this.getCatasOfTree(this.datasCatalog[0].children, '', catalogs);
        })
        .catch(e => {
          console.log(e);
        });
    },
    getQuesLibList(isReset) {
      if (isReset) {
        this.page = 1;
        this.datasSearch.offset = 0;
      }
      this.datasSearch.name = this.datasSearch.name.trim();
      this.loading = true;
      getQuesLibListOG(this.datasSearch)
        .then(result => {
          this.datasList = result.datas;
          this.datasList.forEach(data => {
            delete data.sourceType;
          });
          this.totalPage = result.paging.pages;
          this.count = result.paging.count;
          this.loading = false;
          this.$nextTick(() => {
            this.setChecked();
          });
        })
        .catch(e => {
          console.log(e);
        });
    },
    nameSearch() {
      this.datasSearch.searchKey = this.catalogSearch;
      this.getQuesLibList(true);
    },
    sortChange(sort) {
      this.datasSearch.direction = sort.order;
      this.datasSearch.orderby = sort.prop;
      this.getQuesLibList();
      return false;
    },
    sizeChange(size) {
      this.datasSearch.limit = size;
      this.getQuesLibList(true);
    },
    pageChange(page) {
      this.page = page;
      this.datasSearch.offset = (page - 1) * this.datasSearch.limit;
      this.getQuesLibList();
    },
    getCatasOfTree(arrNew, pId, cataAll) {
      cataAll.forEach(cata => {
        if (cata.parentId === pId || (!pId && !cata.parentId)) {
          cata.label = cata.name;
          cata.children = [];
          this.getCatasOfTree(cata.children, cata.id, cataAll);
          arrNew.push(cata);
        }
      });
    },
    goQuesLibPreview(id) {
      this.queslibDetailData.id = id;
      this.queslibDetailData.dialogVisible = true;
      return;
    },
    // 选择某个
    select(item, row) {
      let isAdd = item.some(v => {
        return v.id === row.id;
      });
      if (!isAdd) {
        delete this.selected[row.id];
      } else {
        this.selected[row.id] = row;
      }
      this.outPut();
    },
    // 全选
    selectAll(items) {
      let isAdd = items.filter((item) => {
        return this.selectable(item);
      }).length !== 0;
      this.datasList.forEach(obj => {
        if (this.selectable(obj)) {
          if (!isAdd) {
            delete this.selected[obj.id];
          } else {
            this.selected[obj.id] = obj;
          }
        }
      });
      this.outPut();
    },
    outPut() {
      const selArray = Object.values(this.selected);

      this.$emit('input', selArray);
      this.$emit('select', selArray);
    },
    handleClose(done) {
      done();
    }
  }
};
</script>
