var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.msg.answerStatus === 0
    ? _c("div", { staticClass: "is-answer-loading w122" }, [
        _c("div", { staticClass: "is-answer-loading-text" }, [
          _vm._v(_vm._s(_vm.$t("pc_biz_ai_thinking").d("正在思考"))),
        ]),
        _vm._m(0),
      ])
    : _vm.msg.answerStatus === 2
    ? _c(
        "div",
        { staticClass: "is-content origin-content" },
        [
          _vm._v("\n  " + _vm._s(_vm.content) + "\n  "),
          _vm.otherSourceAnswer
            ? [
                _c("div", { staticClass: "online-title color-gray-7 mt12" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "pc_biz_ass_lbl_onlineasktitle" /** 我们为您从互联网中找到相关解答： */
                      )
                    )
                  ),
                ]),
                _c("div", {
                  staticClass: "mt4",
                  domProps: { innerHTML: _vm._s(_vm.otherSourceAnswer) },
                }),
              ]
            : _vm._e(),
        ],
        2
      )
    : _vm.msg.answerStatus === 6
    ? _c(
        "div",
        { staticClass: "is-content" },
        [
          _vm._v(_vm._s(_vm.content)),
          _c(
            "yxt-button",
            {
              staticClass: "internet-btn",
              attrs: { type: "text" },
              on: {
                click: function ($event) {
                  return _vm.goUrl(
                    "https://yxt.yunxuetang.cn/tlive/#/detail?id=1714835430057140226"
                  )
                },
              },
            },
            [_vm._v(" " + _vm._s("查看详情"))]
          ),
        ],
        1
      )
    : _c("div", { staticClass: "is-content" }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.content ||
                _vm.settings.assistWele ||
                _vm
                  .$t("pc_biz_ai_question_default_tips")
                  .d("你好，我是AI助手，很高兴为你服务！")
            ) +
            "\n"
        ),
      ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "answer-loading" }, [_c("div")])
  },
]
render._withStripped = true

export { render, staticRenderFns }