var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yxtbiz-funs-tree__slot" },
    [
      _c(
        "yxt-tooltip",
        { attrs: { placement: "top-start", "max-width": 500 } },
        [
          _c(
            "span",
            { attrs: { slot: "content" }, slot: "content" },
            [_c("yxtbiz-dept-name", { attrs: { name: _vm.node.label } })],
            1
          ),
          _c(
            "span",
            [
              _vm._t(
                "default",
                function () {
                  return [_vm._v(_vm._s(_vm.node.label))]
                },
                { data: _vm.data, node: _vm.node }
              ),
            ],
            2
          ),
        ]
      ),
      _c(
        "span",
        {
          staticClass: "tree-tools",
          on: {
            click: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [
          _vm.functions && _vm.funs.length > 0
            ? _c(
                "yxt-dropdown",
                {
                  attrs: {
                    "custom-elm": "",
                    dropdownToBody: _vm.dropdownToBody,
                  },
                  on: { "visible-change": _vm.visibleChange },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "color-gray-6 hover-primary-6 yxtbiz-funs-tree__set",
                    },
                    [
                      _c("yxt-svg", {
                        attrs: {
                          width: "16px",
                          height: "16px",
                          "icon-class": "set",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "yxt-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    _vm._l(_vm.funs, function (btn, index) {
                      return _c(
                        "yxt-dropdown-item",
                        {
                          key: index,
                          attrs: { disabled: btn.disabled || false },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.dropdownItemHandle(btn)
                            },
                          },
                        },
                        [
                          btn.icon
                            ? _c("i", { staticClass: "mr5", class: btn.icon })
                            : _vm._e(),
                          _c("span", [_vm._v(_vm._s(btn.label))]),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }