var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "yxtbiz-ai-robot-empty-data" }, [
    _vm._v(
      _vm._s(
        _vm.taskName
          ? _vm.$t(
              "pc_biz_search_no_result",
              [_vm.taskName] /** 好的，已为您进行{0}查询，但未找到相关内容 */
            )
          : _vm.$t("pc_biz_sorry_no_result" /* 抱歉，没有找到相关内容 */)
      )
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }