<template>
<div class="yxtbiz-ai-robot__dialog--msg is-answer" :class="{'is-long-size': isSearch, 'bbs-search': mode === 'bbs', 'fullscreen': fullscreen}">
    <div class="yxtbiz-ai-robot__biz-answer--chat" v-if="!msg.isHistory && msg.contentDetail && msg.contentDetail.aiTips">{{msg.contentDetail.aiTips}}</div>
    <div class="yxtbiz-ai-robot__dialog--container">
      <div :class="{'head-img':isSearch, 'mr12': !isSearch}" v-if="showPortrait">
        <yxtf-portrait size="28px" class="yxtbiz-ai-robot__dialog--portrait" @click="isSearch && !isWaitAnswer && $emit('launch')" :class="{ 'hand': isSearch}" :imgUrl="settings.assistHeadImg"></yxtf-portrait>
        <span v-if="isSearch">{{$t('pc_biz_ai_ask').d('问一问')}}</span>
    </div>
      <biz-answer 
        v-if="msg.type === 3" 
        :msg="msg"
        :incorrectText="aiIncorrectText"
        :lastMsg="lastMsg"
        :isWaitAnswer="isWaitAnswer"
        @resend="(val) => $emit('resend', val)"
      >
        <component
          :is="anserComponents" 
          :mode="clientType"
          :datas="responeseData"
          :params="requestData"
          :size="fullscreen ? 'large': 'small'">
          <span class="ph12">{{ $t('pc_biz_msg_error_refersh').d('抱歉，系统发生异常，请稍后再试') }}</span>
        </component>
      </biz-answer>
      <component
        v-else
        :is="anserComponents" 
        :msg="msg"
        :isWaitAnswer="isWaitAnswer"
        :isSearch="isSearch"
        :fullscreen="fullscreen"
        :lastMsg="lastMsg"
        :size="fullscreen ? 'large': 'small'"
        @resend="(val) => $emit('resend', val)"
        @feedback="$emit('feedback')"  
        @PromotClick="PromotClick"
        @submit="(data)=>$emit('submit', data)"
        @promotEmpty="$emit('promotEmpty')"
        @scrollToEnd="$emit('scrollToEnd')" />
    </div>
</div>
</template>

<script>
import questionPromot from './answers//questionPromot.vue';
import commonAnswer from './answers/commonAnswer.vue';
import simpleAnswer from './answers/simpleAnswer.vue';
import formAnswer from './answers/formAnswer.vue';
import bizAnswer from './answers/bizAnswer.vue';
import instructionPrompt from './answers/instruction-prompt.vue';
import guessPrompt from './answers/guess-prompt.vue';

const files = require.context('../biz-components', true, /\.vue$/);
const components = {};

files.keys().forEach(key => {
  const name = key.replace(/^.+\/([^/]+)\/[^/]+\.vue/, '$1');
  if (['common'].includes(name)) return;
  const file = files(key).default || files(key);
  components[file.name] = file;
});

export default {
  name: 'Answer',
  components: {
    questionPromot,
    commonAnswer,
    simpleAnswer,
    formAnswer,
    bizAnswer,
    instructionPrompt,
    guessPrompt,
    ...components
  },
  props: {
    isWaitAnswer: {
      type: Boolean,
      default: false
    },
    showPortrait: {
      type: Boolean,
      default: false
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    msg: {
      type: Object,
      default: () => ({})
    },
    isSearch: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'base'
    },
    lastMsg: {
      type: Boolean,
      default: false
    }
  },
  inject: ['settings', 'clientType'],
  data() {
    return {
      requestData: {},
      responeseData: {},
      aiIncorrectText: ''
    };
  },
  watch: {
    msg: {
      handler(val) {
        this.handleMsgPramas(val);
      },
      immediate: true
    }
  },
  computed: {
    anserComponents() {
      // 计算当前应该应该展示哪种类型的消息组件
      if ([3, 4, 5, 6, 8].includes(this.msg.type)) {
        switch (this.msg.type) {
          case 3:
            return this.msg.bizModuleCode;
          case 4:
          case 5:
            return 'formAnswer';
          case 6:
            return 'instructionPrompt';
          case 8:
            return 'guessPrompt';
        }
      } else {
        switch (this.msg.answerStatus) {
          case 0:
          case 2:
          case 3:
          case 6:
            return 'simpleAnswer';
          case 1:
            return 'commonAnswer';
          case 4:
            return 'questionPromot';
          case 5:
            return 'formAnswer';
          default:
            return 'simpleAnswer';
        }
      }
    }
  },
  methods: {
    handleMsgPramas(val) {
      console.log(val, '---- 123123 -------');
      if (val.type === 3) {
        try {
          const content = JSON.parse(val.contentDetail.content || '{}');

          this.aiIncorrectText = content.aiParamsIncorrectText || '';

          this.requestData = JSON.parse(content.request || '{}');
          this.responeseData = JSON.parse(content.response || '{"datas": [], "paging": {}}');
          // 历史记录 默认不作跳转
          this.responeseData.isHistory = val.isHistory;
        } catch (e) {
          this.requestData = {};
          this.responeseData = {};
          this.responeseData.isHistory = val.isHistory;
        }
      }
    },
    PromotClick(...info) {
      this.$emit('promot', ...info);
    }
  }
};
</script>
