<template>
  <div>
    <yxt-row type='flex' class="yxt-user-selector-container yxt-user-multi-container">
      <yxt-table
        :ref="refTable"
        @select-all="selectAllGroupPerson"
        @select="selectGroupPerson"
        v-loading="loading"
        :height="tableHeight"
        class="yxt-user-table"
        :data="tableData"
        row-key="id"
      >
        <template slot="empty">
          <div style="line-height:normal">
            <yxt-empty />
          </div>
        </template>
        <yxt-table-column
          :show-overflow-tooltip="false"
          class-name="yxtbiz-table-selection"
          type="selection"
          reserve-selection
        ></yxt-table-column>
        <yxt-table-column :label="$t('pc_biz_core_lbl_name')" :show-overflow-tooltip="true" :popover-html="true">
          <template slot-scope="scope">
            <yxtbiz-user-name v-if='isOpenData' :name='scope.row.fullname' />
            <span
              v-else
              v-html="scope.row.fullname + '(' + scope.row.username + ')'"
            ></span>
          </template>
        </yxt-table-column>
        <yxt-table-column
          :formatter="formatUserNo"
          :label="$t('pc_biz_udp_lbl_userNo')"
          :show-overflow-tooltip="true"
          prop="userNo"
        >
        </yxt-table-column>
        <yxt-table-column
          :label="$t('biz_udp_position')"
          :show-overflow-tooltip="true"
          :formatter="formatPostion"
        ></yxt-table-column>
      </yxt-table>
      <yxt-row v-if="isShowPage" align='middle' class="yxtbiz-user-selector-mask mt16 pagin-con" type='flex'>
        <!-- <yxt-row type='flex' align='middle' class="col-flex-1"></yxt-row> -->
        <div class="page-checkout">
          <yxt-button 
            :disabled="prevIsDisable" 
            class="page-prev"
            @click="onClickPrev"
            :class="prevIsDisable ? 'page-btn-disable' : ''">
            <i class="yxt-icon yxt-icon-arrow-left"></i>
          </yxt-button>
          <div class="page-numb" 
            @click="onChangePage('INIT')"
            v-if="isShowFirst">1</div>
          <div 
            @click="onClickPrev"
            class="page-numb page-more"
            v-if="isShowFirst">
            <i class="yxt-icon-more" />
            <i class="yxt-icon-d-arrow-left"/>
          </div>
          <div 
            class="page-numb"
            @click="onChangePage(0)"
            :class="currentSelect()">{{currentPage()}}</div>
          <div 
            class="page-numb"
            @click="onChangePage(1)"
            v-if="isShowPageNumBtn(2)"
            :class="currentSelect(1)">{{currentPage(1)}}</div>
          <div 
            class="page-numb"
            @click="onChangePage(2)"
            v-if="isShowPageNumBtn(3)"
            :class="currentSelect(2)">{{currentPage(2)}}</div>
          <div 
            @click="onClickNext"
            class="page-numb page-more-right"
            v-if="isShowNextMore">
            <i class="yxt-icon-more" />
            <i class="yxt-icon-d-arrow-right"/>
          </div>
          <yxt-button
            @click="onClickNext"
            :disabled="NextIsDisable || loading" 
            :class="(NextIsDisable || loading) ? 'page-btn-disable' : ''"
            class="page-next">
            <i class="yxt-icon yxt-icon-arrow-right"></i>
          </yxt-button>
        </div>
      </yxt-row>
    </yxt-row>
  </div>
</template>

<script>
import { getDDGroupList } from '../service';
import resizeTable from '../../mixins/resizeTable';
import { Pagination, Table, TableColumn, Input, Empty, Button } from 'yxt-pc';
import openDataMixin from '../../mixins/openData';
import groupMixin from '../../mixins/group';
import commonMixin from '../../mixins/common';
const CREATE = 'CREATE';
const INIT = 'INIT';

export default {
  components: {
    YxtPagination: Pagination,
    YxtTable: Table,
    YxtTableColumn: TableColumn,
    YxtInput: Input,
    YxtEmpty: Empty,
    YxtButton: Button
  },
  mixins: [resizeTable, openDataMixin, groupMixin, commonMixin],
  props: {
    functionCode: {
      type: String,
      default: ''
    },
    dataPermissionCode: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'persons'
    },
    isShowDeptment: {
      type: Boolean,
      default: true
    },
    isOrgSelectAlone: {
      type: Boolean,
      default: false
    },
    projectGroupId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      refTable: 'userTable',
      tableHeight: 0,
      users: [],
      loading: false,
      pageInfo: {
        prevIsDisable: false,
        NextIsDisable: false
      },
      params: {
        groupId: this.projectGroupId,
        nextToken: '',
        orgId: localStorage.getItem('orgId')
      },
      dataSrouce: [],
      pageNumb: 1,
      pageSize: 20,
      paginationNumb: 1,
      tableData: [],
      hasMore: true,
      nextToken: '',
      selectedPersons: {},
      isCancelSelect: false,
      isErrorBack: false,
      formatPostion: row => {
        if (row.positionName === undefined) {
          return (
            row.positionSet
              .map(item => {
                return item.name;
              })
              .join(';') || '--'
          );
        } else {
          const parttimePosition = row.parttimePositionSet ? Array.from(row.parttimePositionSet) : [];
          const posList = parttimePosition.map(item => item.name);
          posList.push(row.positionName || '');
          return posList.filter(item => item !== '').join(';') || '--';
        }
      },
      formatUserNo: row => {
        return row.userNo || '--';
      },
      timeStamp: ''
    };
  },
  computed: {
    prevIsDisable() {
      return this.pageNumb === 1;
    },
    NextIsDisable() {
      if (!this.hasMore && this.isNeedGetMoreData) {
        return true;
      }
      return false;
    },
    isLasterPage() {
      if (this.dataSrouce.length) {
        return this.isNeedGetMoreData;
      }
      return true;
    },
    isNeedGetMoreData() {
      return this.pageNumb === Math.ceil(this.dataSrouce.length / this.pageSize);
    },
    isShowPage() {
      return this.dataSrouce.length > this.pageSize;
    },
    currentPage() {
      return (val = 0) => {
        return this.paginationNumb + val;
      };
    },
    isShowFirst() {
      return this.pageNumb > 3;
    },
    isShowPageNumb() {
      return (val = 0) => {
        return (this.pageNumb + val) * this.pageSize <= this.dataSrouce.length;
      };
    },
    currentSelect() {
      return (val = 0) => {
        return (this.pageNumb === (this.paginationNumb + val)) ? 'page-select' : '';
      };
    },
    isShowNextMore() {
      if (this.loading) {
        return true;
      }
      if (this.dataSrouce.length <= this.pageSize * 3) return false;
      return this.currentPage(2) * 20 < this.dataSrouce.length;
    },
    isShowPageNumBtn() {
      return (v) => {
        if (this.dataSrouce.length > this.pageSize * (v - 1)) {
          return true;
        }
        return false;
      };
    }
  },
  created() {
    this.getDDGroupList();
  },
  mounted() {
    this.updateSelected(this.selectData.groupPerson);
  },
  methods: {
    selectAllGroupPerson(item) {
      this.$emit('change', item, 'groupPerson');
    },
    selectGroupPerson(item) {
      // 选中数据
      this.$emit('change', item, 'groupPerson');
    },
    updateSelected(selected) {
      // 获取已选中数据并回显
      if (selected) {
        this.$refs[this.refTable].clearSelection();
        selected.forEach(row => this.$refs[this.refTable].toggleRowSelection(row, true));
      }
    },
    onChangePage(v) {
      let pageNumb = 1;
      let paginationNumb = 1;
      if (v === INIT) {
        pageNumb = 1;
      } else {
        pageNumb = this.currentPage(v);
      }
      if (pageNumb > 3) {
        paginationNumb = pageNumb - 2;
      } else {
        paginationNumb = 1;
      }
      this.pageNumb = pageNumb;
      this.paginationNumb = paginationNumb;
    },
    async getDDGroupList(type) {
      this.isCancelSelect = false;
      if (type === CREATE) {
        delete this.params.nextToken;
      } else {
        this.params.nextToken = this.nextToken;
      }
      const data = this.params;
      let res = {};
      if ((this.isLasterPage && this.hasMore) || type) {
        this.loading = true;
        try {
          res = await getDDGroupList(data);
          this.hasMore = res ? res.hasMore : this.hasMore;
          this.isErrorBack = false;
        } catch (error) {
          this.isErrorBack = true;
        }
      }
      if (res && Array.isArray(res.groupUsers) && res.groupUsers.length) {
        this.dataSrouce = this.dataSrouce.concat(res.groupUsers);
        this.nextToken = res.nextToken;
      }
      this.handleResConcatData(this.handlePageData);
    },
    handleResConcatData(callback) {
      if (!this.hasMore) {
        return callback();
      }
      if (this.dataSrouce.length > this.pageSize * 3) {
        if (this.isLasterPage && (this.dataSrouce.length % this.pageSize) <= this.pageSize) {
          return this.hasMore && this.getDDGroupList();
        }
        callback();
      } else if (this.hasMore) {
        if (!this.isErrorBack) {
          this.getDDGroupList(INIT);
        }
      }
    },
    handlePageData() {
      const dataSrouce = this.dataSrouce;
      const start = (this.pageNumb - 1) * this.pageSize;
      const end = (this.pageNumb - 1) * this.pageSize + this.pageSize;
      this.tableData = dataSrouce.slice(start, end);
      this.$nextTick(() => {
        this.loading = false;
      });
    },
    onClickPrev() {
      this.pageNumb--;
      if (this.pageNumb > 2) {
        this.paginationNumb--;
      }
      this.getDDGroupList();
    },
    onClickNext() {
      this.pageNumb++;
      if (this.pageNumb > 3) {
        this.paginationNumb++;
      }
      this.getDDGroupList();
    }
  },
  watch: {
    selectData: {
      deep: true,
      handler(result) {
        this.$refs[this.refTable].clearSelection();
        const groupPerson = [...result.groupPerson];
        groupPerson.forEach(row => {
          this.$refs[this.refTable].toggleRowSelection(row, true);
        });
        this.tableData = [...this.tableData];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.page-checkout {
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  .page-prev,.page-next {
    padding: 0 8px;
    background: 50% no-repeat;
    background-size: 16px;
    cursor: pointer;
    margin: 0;
    color: #595959;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    height: 28px;
    width: 28px;
    margin-right: 4px;
    &:hover {
      color: var(--color-primary);
      border: 1px solid var(--color-primary);
    }
  }
  .page-btn-disable {
    color: #bfbfbf;
    background-color: #fff;
    cursor: not-allowed;
    &:hover {
      color: #bfbfbf;
      border: 1px solid #d9d9d9;
    }
  }
  .page-numb {
    vertical-align: top;
    display: inline-block;
    font-size: 14px;
    min-width: 22px;
    height: 26px;
    line-height: 26px;
    cursor: pointer;
    text-align: center;
    margin: 0 4px;
    border: 1px solid #d9d9d9;
    box-sizing: content-box;
    padding: 0 2px;
    border-radius: 4px;
    white-space: nowrap;
    padding: 0 5px;
    color: #595959;
    &:hover {
      color: var(--color-primary);
      border: 1px solid var(--color-primary);
    }
  }
  .page-more {
    line-height: 28px;
    color: #bfbfbf;
    border: 1px solid transparent;
    .yxt-icon-more {
      display: inline-block;
    }
    .yxt-icon-d-arrow-left {
      display: none;
    }
    &:hover {
      .yxt-icon-more {
        display: none;
      }
      .yxt-icon-d-arrow-left {
        display: inline-block;
      }
    }
  }
  .page-more-right {
    line-height: 28px;
    color: #bfbfbf;
    border: 1px solid transparent;
    .yxt-icon-more {
      display: inline-block;
    }
    .yxt-icon-d-arrow-right {
      display: none;
    }
    &:hover {
      .yxt-icon-more {
        display: none;
      }
      .yxt-icon-d-arrow-right {
        display: inline-block;
      }
    }
  }
  .page-select {
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
  }
}
.pagin-con {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
