var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "yxtbiz-user-center-nav" }, [
    _c(
      "div",
      { staticClass: "user-block" },
      [
        _c("yxtf-portrait", {
          staticClass: "mb12",
          staticStyle: { display: "block" },
          attrs: { size: "80px", username: _vm.fullname, imgUrl: _vm.imgUrl },
        }),
        _c(
          "yxtf-tooltip",
          { attrs: { effect: "ellipsis", placement: "top" } },
          [
            _c(
              "div",
              { attrs: { slot: "content" }, slot: "content" },
              [_c("yxtbiz-user-name", { attrs: { name: _vm.fullname } })],
              1
            ),
            _c(
              "div",
              { staticClass: "standard-size-18 mb12 yxtf-weight-5 fullname" },
              [_c("yxtbiz-user-name", { attrs: { name: _vm.fullname } })],
              1
            ),
          ]
        ),
        _c(
          "yxtf-tooltip",
          { attrs: { effect: "ellipsis", placement: "top" } },
          [
            _c(
              "div",
              { attrs: { slot: "content" }, slot: "content" },
              [
                _c("yxtbiz-dept-name", { attrs: { name: _vm.deptName } }),
                _vm.deptName && _vm.positionName
                  ? _c("span", [_vm._v(" | ")])
                  : _vm._e(),
                _vm._v(_vm._s(_vm.positionName) + "\n      "),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "color-gray-8 mb24 department" },
              [
                _c("yxtbiz-dept-name", { attrs: { name: _vm.deptName } }),
                _vm.deptName && _vm.positionName
                  ? _c("span", [_vm._v(" | ")])
                  : _vm._e(),
                _c("span", [_vm._v(_vm._s(_vm.positionName))]),
              ],
              1
            ),
          ]
        ),
        !_vm.hiddenUserLink
          ? _c(
              "div",
              { staticClass: "text-center" },
              [
                _c(
                  "yxtf-button",
                  {
                    staticStyle: { height: "36px", "font-size": "14px" },
                    attrs: { type: "primary", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.go("/#/userindex")
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("biz_core_homepage_title") /** 个人主页 */)
                    ),
                  ]
                ),
                !_vm.hiddenMyArchive
                  ? _c(
                      "yxtf-button",
                      {
                        staticStyle: {
                          height: "36px",
                          "font-size": "14px",
                          "margin-left": "8px",
                        },
                        attrs: { type: "primary", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.go(
                              `/archive/#/archivedetail?id=${_vm.userId}`
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("biz_core_homepage_archive") /** 我的档案 */
                          )
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _c(
      "ul",
      { staticClass: "nav-list" },
      [
        _vm._l(_vm.navListFilter(_vm.navList1), function (item) {
          return _c(
            "li",
            {
              key: item.pageCode,
              staticClass: "item mb20",
              class: { active: _vm.pageCode === item.pageCode },
              on: {
                click: function ($event) {
                  return _vm.go(item.url)
                },
              },
            },
            [
              _c("span", { staticClass: "nav-name standard-size-16" }, [
                _vm._v(_vm._s(_vm.$t(item.name))),
              ]),
            ]
          )
        }),
        _c("div", { staticClass: "nav-divide-line" }),
        _vm._l(_vm.navListFilter(_vm.navList2), function (item) {
          return _c(
            "li",
            {
              key: item.pageCode,
              staticClass: "item",
              class: { active: _vm.pageCode === item.pageCode },
              on: {
                click: function ($event) {
                  return _vm.go(item.url)
                },
              },
            },
            [
              _c("span", { staticClass: "nav-name standard-size-16" }, [
                _vm._v(_vm._s(_vm.$t(item.name))),
              ]),
            ]
          )
        }),
        _c("div", { staticClass: "nav-divide-line" }),
        _vm._l(_vm.navListFilter(_vm.navList3), function (item) {
          return _c(
            "li",
            {
              key: item.pageCode,
              staticClass: "item",
              class: { active: _vm.pageCode === item.pageCode },
              on: {
                click: function ($event) {
                  return _vm.go(item.url)
                },
              },
            },
            [
              _c("span", { staticClass: "nav-name standard-size-16" }, [
                _vm._v(_vm._s(_vm.$t(item.name))),
              ]),
            ]
          )
        }),
        _c("div", { staticClass: "nav-divide-line" }),
        _vm._l(_vm.navListFilter(_vm.navList4), function (item) {
          return _c(
            "li",
            {
              key: item.pageCode,
              staticClass: "item",
              class: { active: _vm.pageCode === item.pageCode },
              on: {
                click: function ($event) {
                  return _vm.go(item.url)
                },
              },
            },
            [
              _c("span", { staticClass: "nav-name standard-size-16" }, [
                _vm._v(_vm._s(_vm.$t(item.name))),
              ]),
            ]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }