var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "SelectorMain",
    {
      ref: "selectorMain",
      attrs: {
        multiple: _vm.multiple,
        tabs: _vm.tabs,
        functionCode: _vm.functionCode,
        dataPermissionCode: _vm.dataPermissionCode,
        selected: _vm.selected,
        limit: _vm.limit,
        disabledOrgIds: _vm.disabledOrgIds,
        visibleOrgIds: _vm.visibleOrgIds,
        targetOrgId: _vm.targetOrgId,
        enableGroupCorp: _vm.enableGroupCorp,
        visibleOrgSelector: _vm.visibleOrgSelector,
        isOrgSelectAlone: _vm.isOrgSelectAlone,
        isShowDeptment: _vm.isShowDeptment,
        showTab: _vm.showTab,
      },
      on: { onProcess: _vm.onProcess, change: _vm.selectChange },
    },
    [_vm._t("customTab", null, { slot: "customTab" })],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }