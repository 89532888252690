var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-list", {
    attrs: {
      dataList: _vm.datas,
      size: _vm.size,
      tagLists: _vm.tagLists,
      searchName: "pc_biz_o2o_lbl_practice",
      tagType: "upmStatus",
      listId: "praId",
      listName: "praName",
    },
    on: { "to-list": _vm.toList, "to-more": _vm.toListMore },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ list }) {
          return [
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("pc_biz_ote_lbl_minutes" /* 时间 */)) +
                  "：" +
                  _vm._s(
                    _vm.getDateTimeFormatted({
                      startTime: list.entryStartTime,
                      endTime: list.entryEndTime,
                    })
                  )
              ),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }