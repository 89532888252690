var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "article",
    { staticClass: "yxtbiz-ai-robot-track-projectplan" },
    [
      !_vm.isNotEmpty
        ? _c("EmptyData")
        : [
            _c(
              "header",
              {
                class: [
                  "color-gray-7",
                  _vm.size === "small"
                    ? "font-size-14 lh24"
                    : "font-size-16 lh28",
                ],
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$t(
                        "pc_biz_found_projectsplan" /* 为您找到以下培训计划：*/
                      )
                    ) +
                    "\n    "
                ),
              ]
            ),
            _c(
              "main",
              [
                _vm.isShowTab
                  ? _c(
                      "div",
                      { staticClass: "mb4" },
                      [
                        _c(
                          "yxtf-tag",
                          {
                            attrs: {
                              effect: "point",
                              type: _vm.selectTab === 1 ? "info" : "",
                            },
                            on: {
                              click: function ($event) {
                                _vm.selectTab = 0
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "pc_biz_lbl_organizationaltrainplan" /* 组织培训计划*/
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "yxtf-tag",
                          {
                            attrs: {
                              effect: "point",
                              type: _vm.selectTab === 0 ? "info" : "",
                            },
                            on: {
                              click: function ($event) {
                                _vm.selectTab = 1
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "pc_biz_lbl_Individualtrainplan" /* 个人培训计划*/
                                )
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "ul",
                  _vm._l(_vm.projectList, function (project) {
                    return _c(
                      "li",
                      {
                        key: project.projectId,
                        staticClass: "yxtbiz-ai-robot-track-projectplan__item",
                        on: {
                          click: function ($event) {
                            return _vm.handleProjectClick(project)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "over-hidden" }, [
                          _c(
                            "div",
                            {
                              class: [
                                _vm.size === "small"
                                  ? "ellipsis-2 font-size-14 lh22"
                                  : "ellipsis font-size-16  lh24",
                                "color-gray-10",
                              ],
                            },
                            [
                              _c(
                                "yxt-tag",
                                {
                                  staticClass:
                                    "mr6 yxtbiz-ai-robot-track-projectplan__item-tag",
                                  attrs: {
                                    type:
                                      project.showStatus === 1 ? "" : "info",
                                    size: "mini",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.filterProjectStatus(
                                        project.showStatus
                                      )
                                    )
                                  ),
                                ]
                              ),
                              project.delayStatus === 1
                                ? _c(
                                    "yxt-tag",
                                    {
                                      staticClass:
                                        "mr6 yxtbiz-ai-robot-track-projectplan__item-tag",
                                      attrs: { type: "danger", size: "mini" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("pc_biz_lbl_delay" /* 已延期*/)
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(
                                "\n              " +
                                  _vm._s(project.planName) +
                                  "\n            "
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              class: [
                                _vm.size === "small" ? "mt4" : "mt8",
                                "yxtbiz-flex-center lh20 color-gray-7 font-size-12",
                              ],
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t(
                                        "pc_biz_lbl_completionStatus" /* 完成状态：*/
                                      )
                                    ) +
                                    "\n                " +
                                    _vm._s(
                                      project.finishedStatus
                                        ? _vm.$t(
                                            "pc_biz_study_lbl_complete" /* 已完成*/
                                          )
                                        : _vm.$t(
                                            "pc_biz_study_lbl_uncompleted" /* 未完成*/
                                          )
                                    ) +
                                    "\n              "
                                ),
                              ]),
                              _c("yxt-divider", {
                                attrs: { direction: "vertical" },
                              }),
                              _c("div", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t(
                                        "pc_biz_lbl_finishRate" /* 完成率：*/
                                      )
                                    ) +
                                    "\n                " +
                                    _vm._s(
                                      project.completeRate
                                        ? project.completeRate
                                        : 0
                                    ) +
                                    "%\n              "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
                _vm.count > 3
                  ? _c("ViewMoreButton", {
                      on: { click: _vm.handleViewMoreClick },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }