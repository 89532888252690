<template>
  <yxtf-dropdown-item v-if="enableGroupCorp && orgList.length > 1" style="padding-right:8px;">
      <yxtf-dropdown  v-bind="$attrs" placement="left-start" :append-to-body="false">
        <span type="text" class="ell">
          {{orgName}}
        </span>
        <yxtf-dropdown-menu
          slot="dropdown"
          :append-to-body="false"
          :popperOptions="{
            modifiers:[ 'shift', 'offset', 'keepTogether', 'arrow', 'flip', 'applyStyle'],
            gpuAcceleration: false
          }"
        >
          <yxtf-dropdown-item
            v-for="item in orgList"
            :key="item.orgId"
            :command="item.orgId"
            :class="['yxtbiz-nav-lang__default-lang', {'yxtbiz-nav-lang__active-langs': activeId == item.orgId}]"
            @click.native="handleChange(item)"
          >
            {{ item.orgName }}
            <i v-if="activeId == item.orgId" class="yxtf-icon-check"></i>
          </yxtf-dropdown-item>
        </yxtf-dropdown-menu>
      </yxtf-dropdown>
  </yxtf-dropdown-item>
</template>

<script>
import NavOrgListMixin from './nav-org-list-mixin';

export default {
  name: 'YxtbizNavOrgListStu',
  mixins: [NavOrgListMixin],
  data() {
    return {
      activeId: localStorage.getItem('orgId'),
      orgList: []
    };
  }
};
</script>

<style lang="scss" scoped>
.ell {
  overflow:hidden;  
  display: inline-block;
  white-space:nowrap;
	text-overflow:ellipsis;
  max-width: 112px;
  color:#000000;
  vertical-align: bottom;
  padding-right: 0;
}
.ell:hover {
  color: var(--color-primary-6);
}
</style>
