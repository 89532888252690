<template>
  <div :class="[ns.b(), { small: isSmall }]">
    <div :class="[ns.e('msg'), 'color-gray-10']">
      {{
        empty
          ? $t('pc_biz_tcm_searchEmpty' /** 抱歉，未找到相关内容*/)
          : $t('pc_biz_tcm_searchResult' /** 为您找到以下微课：*/)
      }}
    </div>
    <div
      :class="ns.e('card')"
      v-for="item in list"
      :key="item.id"
      @click="toTcmDetail(item)"
    >
      <div :class="ns.e('cover')">
        <yxt-image :src="item.courseCover || defaultCover" fit="cover" lazy />
      </div>
      <div :class="ns.e('detail')">
        <div :class="ns.e('title')">
          <span :class="ns.e('title-con')">{{ item.courseName }}</span>
        </div>
        <div :class="ns.e('subtitle')">
          <div :class="ns.e('creator')">
            {{
              $t('pc_biz_tcm_creator', [item.createUserName] /** 创建者：{0} */)
            }}
          </div>
          <div :class="ns.e('line')">|</div>
          <div :class="ns.e('time')">
            {{
              $t(
                'pc_biz_tcm_time',
                [
                  getDateTimeFormatted({ startTime: item.createTime })
                ] /** 时间：{0} */
              )
            }}
          </div>
        </div>
      </div>
    </div>
    <div :class="ns.e('more')" v-if="hasMore" @click="toMyTcm">
      <span>{{ $t('pc_biz_tcm_viewmore' /* 查看更多 */) }}</span>
      <yxt-svg
        :class="ns.e('more-icon')"
        width="16px"
        height="16px"
        icon-class="arrow_right"
      />
    </div>
  </div>
</template>

<script>
import utils from 'yxt-biz-pc/packages/common-util/utils.js';
import CommonMixin from '../common/props-mixin';
import { useNamespace } from '../search-live/bem';

export default {
  name: 'TrackTcm',
  mixins: [CommonMixin],
  data() {
    return {
      records: this.datas.datas,
      ns: useNamespace('track-tcm'),
      list: this.datas.datas.slice(0, 3),
      defaultCover: `${
        this.$staticBaseUrl
      }/assets/76a41088/060a0664/default-micro.png`,
      getDateTimeFormatted: utils.getDateTimeFormatted
    };
  },
  computed: {
    isSmall() {
      return this.size === 'small';
    },
    empty() {
      return this.records.length === 0;
    },
    hasMore() {
      return this.records.length > 3;
    }
  },
  methods: {
    toTcmDetail(item) {
      window.open(
        `${window.location.origin}/tcm/#/detail?id=${item.id}`,
        '_blank'
      );
    },
    toMyTcm() {
      // 默认值
      this.params = {
        courseName: '',
        courseStatus: null,
        ...this.params
      };

      localStorage.setItem('aiRobotTrackTcmQuery', JSON.stringify(this.params));
      window.open(`${window.location.origin}/tcm/#/list`, '_blank');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../search-live/bem.scss';

@include b(track-tcm) {
  &__msg {
    line-height: 28px;
    font-size: 16px;
    padding: 0 12px;
    color: #8c8c8c;
  }

  &.small &__msg {
    line-height: 24px;
    font-size: 14px;
  }

  &__card {
    padding: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: #f4f5f6;
    }
  }

  &__msg + &__card {
    margin-top: 12px;
  }

  &__cover {
    position: relative;
    width: 113px;
    height: 64px;
    border-radius: 4px;
    overflow: hidden;
  }

  &__detail {
    flex: 1;
    margin-left: 12px;
  }

  &__title {
    font-size: 16px;
    line-height: 24px;
    color: #262626;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;

    &-con {
      vertical-align: middle;
    }
  }

  &.small &__title {
    line-height: 20px;
    font-size: 14px;
    -webkit-line-clamp: 2;
  }

  &__subtitle {
    margin-top: 8px;
    font-size: 12px;
    line-height: 20px;
    color: #8c8c8c;
    display: flex;
    flex-direction: row;
  }

  &.small &__subtitle {
    margin-top: 4px;
    line-height: 18px;
    flex-direction: column;
  }

  &__line,
  &__time {
    margin-left: 8px;
  }

  &.small &__line {
    margin-left: 0;
    display: none;
  }

  &.small &__time {
    margin-left: 0;
    margin-top: 4px;
  }

  &__more {
    padding: 8px 0;
    color: #757575;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 22px;
    border-radius: 8px;
    cursor: pointer;

    &-icon {
      margin-left: 4px;
    }

    &:hover {
      background-color: #f4f5f6;
    }
  }
}
</style>
