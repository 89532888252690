<template>
  <span @click="showCaptcha">
    <slot></slot>
  </span>
</template>
<script>
import { captchaId } from 'yxt-biz-pc/packages/api';
import { getLanguage } from 'yxt-i18n/es';
import { enmuFunctions } from '../../function-points';
import { preCheckFunctions, checkTimeOutFnc } from '../../common-util/getFactor';

const langMap = {
  'zh': 'zh-cn',
  'ha': 'zh-hk',
  'en': 'en'
};

export default {
  name: 'YxtbizCaptcha',
  props: {
    appId: {
      default: '',
      type: String
    },
    visible: {
      type: Boolean,
      default: false
    },
    lang: {
      type: String,
      default: ''
    },
    module: {
      type: String,
      default: 'xuanxing'
    }
  },
  data() {
    return {
      url: 'https://turing.captcha.qcloud.com/TCaptcha.js',
      loaded: false,
      openFactory: false // 是否开启滑块要素
    };
  },
  computed: {
    configId() {
      return this.appId || captchaId || '2025627896';
    }
  },
  async created() {
    if (this.module === 'xuanxing') {
    // 3.4滑块验证码接入去版本
      await preCheckFunctions(enmuFunctions);
      const val = checkTimeOutFnc(enmuFunctions.CAPTCHA);
      this.openFactory = val === 2;
    } else {
      this.openFactory = 1;
    }
    if (!this.openFactory) return this.init();
    if (window.TencentCaptcha === undefined && !window.loadingCaptcha) {
      window.loadingCaptcha = true; // 加载标识，避免同一个页面引入多个验证码时重复加载js
      this.getScript(this.init);
    } else {
      this.init();
    }
  },
  methods: {
    showCaptcha() {
      if (this.loaded) {
        if (!this.openFactory) return this.$emit('success', {ticket: '', randstr: ''});
        /* eslint-disable no-new */
        let forceLang = langMap[this.lang || getLanguage()] || 'zh';
        new window.TencentCaptcha(this.configId, this.captchaSuccess, { forceLang }).show();
        this.$emit('open');
      }
    },
    captchaSuccess(res) {
      if (res.ret === 2) {
        // 手动关闭
        this.$emit('update:visible', false);
      }
      if (res.ret === 0) {
        this.$emit('success', res);
      }
    },
    init() {
      this.loaded = true;
      if (this.visible) this.showCaptcha();
    },
    getScript(callback) {
      let self = this;
      let HEAD =
        document.getElementsByTagName('head').item(0) ||
        document.documentElement;
      let script = document.createElement('script');
      script.setAttribute('type', 'text/javascript');
      script.onload = function() {
        window.loadingCaptcha = false;
        callback();
      };
      script.onreadystatechange = function() {
        if (this.readyState === 'loaded' || this.readyState === 'complete') {
          callback();
        }
      };

      script.setAttribute('src', self.url);
      HEAD.appendChild(script);
    }
  },
  destroyed() {
    window.loadingCaptcha = false;
  },
  watch: {
    visible: {
      immediate: true,
      handler(v) {
        if (v === true) {
          this.showCaptcha();
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
