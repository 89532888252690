<template>
  <yxtf-dialog
    :visible.sync="visible"
    :show-close="false"
    :close-on-click-modal="false"
    class="yxtbiz-nav-global-notice-opts"
    top="100px"
    padding-size='empty'
    width="440px"
    :modal-append-to-body='false'
  >
    <div class="yxtbiz-nav-global-notice-opts__title">
      <div class="yxtbiz-nav-global-notice-opts__title-ctx">{{ content.title }}</div>
      <div class="yxtbiz-nav-global-notice-opts__title-btn">
        <yxt-button type="text" @click="closeNotice">
          <i class="yxt-icon-close" width="16px" height="16px"></i>
        </yxt-button>
      </div>
    </div>
    <div class="yxtbiz-nav-global-notice-opts-big__wrapper">
      <yxt-scrollbar :fit-height="true">
        <div v-html="content.content" class="wrapper-rich-content"></div>
      </yxt-scrollbar>
    </div>
    <div class="yxtbiz-nav-global-notice-opts__footer">
      <yxt-button @click="closeNotice">{{ $t('pc_biz_notice_btn_close'/* 关闭 */) }}</yxt-button>
      <!-- buttonDesc不为空则按钮名字不改变，否则按钮名字为buttonDesc字段的名字 -->
      <yxt-button type="primary" @click="checkNotice">{{content.buttonDesc?content.buttonDesc:$t('pc_biz_notice_btn_check'/* 查看 */) }}</yxt-button>
    </div>
  </yxtf-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    content: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    closeNotice() {
      this.$emit('update:visible', false);
      this.$emit('closeNotice', this.content);
    },
    checkNotice() {
      this.$emit('check', this.content);
    }
  }
};
</script>
