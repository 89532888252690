<template>
  <div class="ai-robot-chats" :class="{'ai-robot-chats--small': smallClient}">
    <transition name="slide">
      <div
        v-if="(visible || !smallClient) && !isCollapse"
        class="broadside-content"
        :class="{'broadside-content--small': smallClient}"
      >
        <div class="flex-1 height-0 ai-robot-chat__container">
          <div
            v-if="smallClient"
            class="back-wrap flex align-items-center"
            @click="back"
          >
            <i class="yxt-icon-arrow-left font-size-20 font-bold"></i>
            <span class="ml2">
              {{$t('pc_comp_pageHeader_title'/** 返回 */)}}
            </span>
          </div>
          <div class="ai-robot-info">
            <yxtf-portrait
              v-if="settings.assistHeadImg"
              size="medium"
              username="AI"
              :imgUrl="settings.assistHeadImg"
            />
            <span class="mt12 ai-title">{{ title }}</span>
            <yxt-button
              type="second"
              class="ai-robot-create__btn"
              @click="createChat"
            >
              <yxt-svg
                class="v-mid mb4"
                width="14px"
                height="14px"
                icon-class="plus"
              />
              {{$t('pc_biz_ai_createChat'/** 新建对话 */)}}
            </yxt-button>
            <yxt-divider line-color="#D9D9D9">
              <template v-if="chatList.length">
                {{$t('pc_biz_ai_displayLimit_tips',[30]/** 仅展示最近{0}条对话 */)}}
              </template>
              <template v-else>
                {{$t('pc_biz_ai_no_history'/** 暂无历史对话 */)}}
              </template>
            </yxt-divider>
          </div>
          <div class="flex-1 height-0 overflow-hidden">
            <yxt-scrollbar :fit-height="true">
              <ul class="ai-robot-chat__list">
                <li
                  v-for="chat in chatList"
                  :key="chat.chatSessionId"
                  class="flex"
                  :class="{'active':chatId === chat.chatSessionId, 'chat-edit': chat.isEdit}"
                  @click="selectChat(chat)"
                >
                  <yxt-svg
                    v-if="!isBatchDelete"
                    :remote-url='mediaPath'
                    icon-class="ai-chat-icon"
                    class="chat-icon mr12 mt10"
                    width="24px"
                    height="24px"
                  ></yxt-svg>
                  <!-- 批量删除复选框 start -->
                  <yxt-checkbox
                    v-else
                    v-model="chat.selected"
                    class="batch-checkbox"
                    size="medium"
                    @change="handleChecked"
                    @click.stop.native
                  ></yxt-checkbox>
                  <!-- 批量删除复选框 end -->
                  <div class="flex-1 chat-content">
                    <div class="flex align-items-center chat-name__wrap">
                      <div
                        class="standard-size-14 ellipsis flex-1"
                        v-if="!chat.isEdit"
                      >
                        {{chat.name}}
                      </div>
                      <yxt-input
                        v-model="chat.name"
                        size="small"
                        class="chat-name__input flex-1"
                        :placeholder="$t('pc_biz_lbl_please_input_name'/** 请输入名称 */)"
                        @click.native.stop
                        maxlength="50"
                        show-word-limit
                        v-else
                      ></yxt-input>
                      <div
                        v-if="!chat.isEdit && !isBatchDelete"
                        class="operate-icons color-gray-8 align-items-center standard-size-16"
                      >
                        <i
                          class="yxt-icon-edit"
                          @click.stop="edit(chat)"
                        ></i>
                        <yxtf-popconfirm
                          placement="top"
                          :title="$t('pc_biz_ai_deleteChat_confirm'/** 删除后无法恢复，是否继续删除？ */)"
                          :confirm-button-text="$t('pc_biz_ai_delete'/** 删除 */ )"
                          @confirm="deleteChat(chat)"
                          @click.native.stop
                        >
                          <i
                            slot="reference"
                            class="yxt-icon-delete ml8"
                          ></i>
                        </yxtf-popconfirm>
                      </div>
                      <div
                        class="f-mt5 flex align-items-center operate-icons operate-icons--edit standard-size-16"
                        v-else-if="chat.isEdit"
                      >
                        <i
                          class="yxt-icon-check mr4"
                          @click.stop="saveName(chat)"
                        ></i>
                        <i
                          class="yxt-icon-close"
                          @click.stop="saveName(chat,false)"
                        ></i>
                      </div>
                    </div>
                    <div class="color-gray-7 standard-size-12 mt4">
                      {{ chat.lastTalkTime }}
                    </div>
                  </div>
                </li>
              </ul>
            </yxt-scrollbar>
          </div>
        </div>
        <div class="batch-container color-gray-9">
          <template v-if="!isBatchDelete">
            <div class="flex-1">
              <span
                class="hover-primary-6"
                @click="batchDelete"
              >
                <i
                  class="yxt-icon-delete ml8 hand font-size-16"
                  v-if="chatList.length"
                ></i>
                <span
                  class="hand"
                  v-if="chatList.length"
                >
                  {{$t('pc_biz_ai_batchDelete'/** 批量删除 */)}}
                </span>
              </span>
            </div>
            <!-- 收起 start -->
            <yxt-svg
              v-if="!smallClient"
              :remote-url='mediaPath'
              icon-class="ai-robot-retract"
              class="hand hover-primary-6"
              width="20px"
              height="20px"
              @click.native="isCollapse = true"
            ></yxt-svg>
            <!-- 收起 end -->
          </template>
          <template v-else>
            <div class="flex-1">
              <yxt-checkbox
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange"
              >
                {{$t('pc_biz_udp_lbl_selectAll'/** 全选 */)}}
              </yxt-checkbox>
            </div>
            <div class="batch-confirm">
              <yxt-button
                type="text2"
                class="color-gray-9"
                @click="cancel"
              >
                {{$t('pc_biz_ai_btn_cancel'/** 取消 */)}}
              </yxt-button>
              <yxt-divider direction="vertical"></yxt-divider>
              <yxtf-popconfirm
                placement="top"
                :title="$t('pc_biz_ai_deleteChat_confirm'/** 删除后无法恢复，是否继续删除？ */)"
                :confirm-button-text="$t('pc_biz_ai_delete'/** 删除 */ )"
                @confirm="submitBatchDelete"
              >
                <yxt-button
                  slot="reference"
                  type="text2"
                  :disabled="!checkedChatList.length"
                  class="color-gray-9"
                >
                  {{$t('pc_biz_ai_confirmDelete'/** 确认删除 */)}}
                </yxt-button>
              </yxtf-popconfirm>
            </div>
          </template>
        </div>
      </div>
    </transition>
    <!-- 展开 start -->
    <div
      class="ai-robot-chats__expand"
      v-if="isCollapse && !smallClient"
      @click="isCollapse = false"
    >
      <div class="expand-text">
        {{$t('pc_comp_sideBar_open'/** 展开 */)}}
      </div>
      <yxt-svg
        :remote-url='mediaPath'
        icon-class="ai-robot-expand"
        width="20px"
        height="20px"
      ></yxt-svg>
    </div>
    <!-- 展开 end -->
  </div>
</template>

<script>
import HeaderIcon from './headerIcon.vue';
import Share from './share.vue';
import { getStaticBaseUrl, getAIChartList, switchChat, updateChatName, deleteChat } from '../service';
export default {
  props: {
    bFullscreen: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    // 是否在等待回答
    waitAnswer: {
      type: Boolean,
      default: false
    },
    // 发送消息后数据
    updateMessage: {
      type: Object,
      default: () => { }
    },
    visible: {
      type: Boolean,
      default: true
    },
    smallClient: {
      type: Boolean,
      default: false
    }
  },
  components: {
    HeaderIcon,
    Share
  },
  inject: ['settings'],
  data() {
    return {
      showShare: false,
      svgUrl: `${getStaticBaseUrl()}assets/14a8350a/9a77b0e7/`,
      chatList: [], // 窗口列表
      chatId: '', // 当前窗口id
      isBatchDelete: false, // 是否在批量删除状态
      isIndeterminate: false, // 是否半选
      checkAll: false, // 是否全选
      isCollapse: false, // 是否收起
      editChat: {} // 编辑对象
    };
  },
  created() {
    this.getChartList();
  },
  computed: {
    mediaPath: ({ $staticBaseUrl }) => `${$staticBaseUrl}ufd/55a3e0/o2o/pc/svg`,
    checkedChatList() {
      return this.chatList.filter((item) => !!item.selected);
    }
  },
  methods: {
    getChartList(isNew = false) {
      getAIChartList().then((res) => {
        let hasCurrent = false;
        res.forEach((item) => {
          item.isEdit = false;
          item.selected = false;
          if (item.currentWindow === 1) {
            this.selectChat(item, isNew, false);
            hasCurrent = true;
          }
        });
        this.chatList = res;
        if (!hasCurrent) {
          this.$emit('selectChat', { chatSessionId: '' });
        }
      });
    },
    // 小窗口返回
    back() {
      // 返回清空编辑状态
      this.resetEdit();
      this.cancel();
      this.$emit('update:visible', false);
    },
    createChat() {
      if (!this.chatId) {
        this.$message.warning(this.$t('pc_biz_ai_newest_chat'/* 当前已是最新对话 */));
        return;
      }
      this.chatId = '';
      this.$emit('selectChat', { chatSessionId: '' });
    },
    selectChat(chat, isNew = false, isTip = true) {
      if (this.waitAnswer) {
        isTip && this.$message({
          showClose: false,
          message: this.$t('pc_biz_ai_busyTips'/* 当前问题正在回答，请稍后再试 */),
          type: 'warning'
        });
        return;
      }
      // 编辑中切换窗口，做取消操作
      this.resetEdit();
      if (chat.chatSessionId !== this.chatId || this.chatId === '') {
        this.chatList.forEach(item => { item.isEdit = false; });
        this.chatId = chat.chatSessionId;
        switchChat({ chatSessionId: this.chatId });
        this.$emit('selectChat', chat, isNew);
      }
    },
    // 重置编辑状态
    resetEdit() {
      if (Object.keys(this.editChat).length) {
        const chat = this.chatList.find(item => item.isEdit);
        this.saveName(chat, false);
      }
    },
    // 修改名称提交API
    saveName(chat, isSave = true) {
      if (!isSave) {
        chat.name = this.editChat.name;
        chat.isEdit = false;
        this.editChat = {};
        return;
      }
      if (!chat.name.trim()) {
        this.$message.warning(this.$t('pc_biz_nameRequired'/* 名称不能为空 */));
        return;
      }
      const params = {
        name: chat.name,
        chatSessionId: chat.chatSessionId
      };
      updateChatName(params).then(() => {
        chat.isEdit = false;
        this.editChat = {};
      });
    },
    // 修改标题
    edit(item) {
      item.isEdit = true;
      this.editChat = JSON.parse(JSON.stringify(item));
    },
    // 取消批量删除
    cancel() {
      this.isBatchDelete = false;
      this.checkAll = false;
      this.handleCheckAllChange(false);
    },
    // 删除窗口
    deleteChat(chat) {
      const params = {
        chatSessionIds: [chat.chatSessionId]
      };
      deleteChat(params).then(() => {
        this.getChartList();
      });
    },
    // 批量删除
    batchDelete() {
      this.isBatchDelete = true;
    },
    // 处理单个选中
    handleChecked(val) {
      this.isIndeterminate = this.checkedChatList.length && this.checkedChatList.length < this.chatList.length;
    },
    // 处理批量删除全选
    handleCheckAllChange(val) {
      this.isIndeterminate = false;
      this.chatList.forEach((item) => {
        item.selected = val;
      });
    },
    // 提交批量删除数据
    submitBatchDelete() {
      const params = {
        chatSessionIds: this.checkedChatList.map((item) => item.chatSessionId)
      };
      deleteChat(params).then(() => {
        this.getChartList();
        this.cancel();
      });
    }
  },
  watch: {
    // 发送消息回答后，根据对话时间前端排序
    updateMessage(val) {
      if (!val.chatSessionId) {
        this.getChartList(true);
        return;
      }
      this.chatList.forEach((item) => {
        if (item.chatSessionId === val.chatSessionId) {
          item.lastTalkTime = val.time;
        }
      });
      this.chatList.sort((a, b) => {
        return new Date(b.lastTalkTime) - new Date(a.lastTalkTime);
      });
    }
  }
};
</script>

<style lang="scss">
.ai-robot-chats {
  position: relative;

  .slide-enter-active {
    animation: slide-in 0.4s;
  }

  .slide-leave-active {
    animation: slide-out 0.4s;
  }
  @keyframes slide-in {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes slide-out {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  .ai-robot-chats__expand {
    position: absolute;
    left: 0;
    bottom: 60px;
    z-index: 9999;
    padding: 0 20px;
    height: 52px;
    background-color: #fff;
    box-shadow: 0px 4px 24px 0px rgba(41, 49, 54, 0.12);
    border-radius: 0px 100px 100px 0px;
    display: flex;
    align-items: center;
    color: #595959;
    cursor: pointer;

    .expand-text {
      display: none;
      white-space: nowrap;
      margin-right: 8px;
    }

    &:hover {
      background-color: var(--color-primary-6);
      color: #fff;
      padding-left: 16px;

      .expand-text {
        display: block;
      }
    }
  }

  .yxtf-popover {
    padding: 16px;
  }

  .broadside-content {
    width: 280px;
    height: 100%;
    background: rgba(255, 255, 255, 0.4);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .yxt-divider__text {
      .text-line-right,
      .text-line-left {
        background-color: #d8d8d8;
      }
    }

    &.broadside-content--small {
      width: 480px;
      background: transparent;
      position: relative;

      .back-wrap {
        position: absolute;
        top: 20px;
        left: 24px;
        cursor: pointer;
      }

      .ai-robot-chat__list {
        & > li {
          margin: 10px 24px 0 24px;
        }
      }

      .ai-robot-info {
        padding: 0 24px;
      }
      .batch-container {
        background-color: rgba(255, 255, 255, 0.5);
      }
    }

    .overflow-hidden {
      overflow: hidden;
    }

    .batch-checkbox {
      margin-right: 16px !important;
      margin-left: 4px;
      margin-top: 14px;
      position: relative;
      z-index: 2;
    }

    .chat-name__wrap {
      height: 22px;
    }

    .align-items-center {
      align-items: center;
    }

    .yxt-scrollbar__wrap {
      overflow-x: hidden;
    }

    .height-0 {
      height: 0;
    }

    .ai-robot-chat__container {
      display: flex;
      flex-direction: column;
    }
    .ai-robot-info {
      margin-top: 56px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 12px;

      .ai-title {
        font-size: 20px;
        font-weight: 500;
        color: #262626;
        line-height: 28px;
      }
    }
    .ai-robot-create__btn {
      border-radius: 16px;
      background-color: transparent;
      margin-top: 32px;
      margin-bottom: 13px;

      &:hover {
        color: var(--color-primary-6);
        border-color: var(--color-primary-6);
      }
    }

    // ai窗口列表 start
    .ai-robot-chat__list {
      padding-bottom: 20px;

      & > li {
        padding: 8px 12px;
        // border: 1px solid transparent;
        border-radius: 8px;
        position: relative;
        margin-top: 10px;
        height: 62px;
        box-sizing: border-box;
        margin: 10px 12px 0 12px;
        cursor: pointer;

        &:hover {
          // border-color: #d9d9d9;
          background: rgba(38,38,38,0.04);

          .operate-icons {
            display: flex;
          }
        }

        &.active {
          // border-color: var(--color-primary-6);
          background-color: #fff;
          // &::after {
          //   content: '';
          //   width: 100%;
          //   height: 100%;
          //   position: absolute;
          //   background-color: #fff;
          //   top: 0;
          //   left: 0;
          //   border-radius: 8px;
          //   z-index: -1;
          // }

          .chat-icon {
            color: var(--color-primary-6);
          }
        }

        &.chat-edit {
          background: rgba(38,38,38,0.04);
        }

        .chat-name__input {
          width: 146px;
          position: relative;
          z-index: 2;
          margin-right: 8px;
          margin-top: -5px;

          .yxt-input__inner {
            &:focus {
              box-shadow: none;
            }
          }
        }
        .f-mt5 {
          margin-top: -5px;
        }
      }

      .operate-icons {
        position: relative;
        z-index: 2;
        display: none;

        &--edit {
          display: flex;
        }

        i {
          cursor: pointer;
          &:hover {
            color: var(--color-primary-6);
          }
        }
      }

      .chat-icon {
        color: #bdbdbd;
      }

      .chat-content {
        width: 0;
        text-align: left;
      }
    }

    // 底部批量删除容器
    .batch-container {
      height: 52px;
      background-color: #fff;
      box-shadow: inset 0px 1px 0px 0px #e9e9e9;
      display: flex;
      align-items: center;
      padding: 0 16px;
      text-align: left;
    }
  }
}

.ai-robot-chats--small .slide-leave-active {
  &.broadside-content {
      width: 480px;
    }
}
</style>
