var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yxtbiz-check-person-range" },
    [
      _vm.setTabs.length > 1
        ? _c(
            "yxt-tabs",
            {
              attrs: { "drawer-top": true, "before-leave": _vm.tabBeforeLeave },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            _vm._l(_vm.setTabs, function (item, index) {
              return _c(
                "yxt-tab-pane",
                {
                  key: item.name,
                  attrs: { label: item.label, name: item.name },
                },
                [
                  _vm.activeName === item.name
                    ? [
                        _vm.$slots[item.name]
                          ? _vm._t(item.name)
                          : _c(item.component, {
                              ref: item.name,
                              refInFor: true,
                              tag: "component",
                              attrs: {
                                identify: index,
                                options: _vm.options[item.name],
                                list: _vm.personRange,
                                functionCode: _vm.functionCode,
                                dataPermissionCode: _vm.dataPermissionCode,
                                enableGroupCorp: _vm.enableGroupCorp,
                                strongManageMode: _vm.strongManageMode,
                                targetOrgId: _vm.targetOrgId,
                                disabled: _vm.disabledOrgSelect,
                                visibleOrgIds: _vm.visibleOrgIds,
                                visibleOrgSelector: _vm.visibleOrgSelector,
                                isShowDeptment: _vm.isShowDeptment,
                              },
                              on: {
                                update: _vm.updateList,
                                updateDepartmentByType:
                                  _vm.updateDepartmentByType,
                                onProcess: _vm.onProcess,
                                closeTag: _vm.closeTag,
                                "org-change": _vm.handleOrgChange,
                              },
                            }),
                      ]
                    : _vm._e(),
                ],
                2
              )
            }),
            1
          )
        : _vm._l(_vm.setTabs, function (item) {
            return _c(item.component, {
              key: item.name,
              ref: item.name,
              refInFor: true,
              tag: "component",
              class: { "single-table": !_vm.hiddenCheckList },
              attrs: {
                identify: item.index,
                options: _vm.options[item.name],
                list: _vm.personRange,
                functionCode: _vm.functionCode,
                dataPermissionCode: _vm.dataPermissionCode,
                disabled: _vm.disabledOrgSelect,
                enableGroupCorp: _vm.enableGroupCorp,
                strongManageMode: _vm.strongManageMode,
                targetOrgId: _vm.targetOrgId,
                visibleOrgIds: _vm.visibleOrgIds,
                visibleOrgSelector: _vm.visibleOrgSelector,
                isShowDeptment: _vm.isShowDeptment,
              },
              on: {
                update: _vm.updateList,
                updateDepartmentByType: _vm.updateDepartmentByType,
                onProcess: _vm.onProcess,
                closeTag: _vm.closeTag,
                "org-change": _vm.handleOrgChange,
              },
            })
          }),
      !_vm.hiddenCheckList
        ? _c(
            "div",
            {
              staticClass: "yxt-list__wrapper",
              class: _vm.setTabs.length <= 1 ? "yxt-list__wrapper-single" : "",
            },
            [
              _c("checked-list", {
                attrs: {
                  list: _vm.personRange,
                  virtualRender: _vm.virtualRender,
                },
                on: { clear: _vm.clear, close: _vm.closeTag },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }