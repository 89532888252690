<template>
  <div class="check_more_department">
    <yxtbiz-dept-tree
      version="v1"
      :openSelectSiblingNode="true"
      :targetOrgId='targetOrgId'
      :disabled="disabled"
      :visibleOrgSelector='visibleOrgSelector'
      :visibleOrgIds="visibleOrgIds"
      :enableGroupCorp="enableGroupCorp"
      @org-change='handleOrgChange'
      @close="closeTag"
      @check="handleCheck"
      @clearNode="handleCheck"
      @nodeClick="handleNodeClick"
      @selectSiblingChange="selectSiblingChange"
      filterable
      size="small"
      :placeholder="$t('biz_udp_enter_deptmentname')"
      class="department_tree"
      ref="deptTree"
      :check-strictly="true"
      show-checkbox
      @inited="init"
      :multiple="multiple"
      :children-included="2"
      @includeChanged="handleTypeChange"
      :functions="!!childrenIncluded && functions"
      :functionCode="functionCode"
      :dataPermissionCode="dataPermissionCode"
      :count="true"
      :checkedLinkage="'1'"
      :teamId="teamId"
      core="team"
      nodeKey="id"
      ></yxtbiz-dept-tree>
  </div>
</template>

<script>
import authMixin from '../../mixins/auth';
import commonMixin from '../../mixins/common';
import { i18n } from 'yxt-biz-pc/packages/common-util/changeLangs';
/**
 * @description 嵌套对象打平(虚拟部门专用)
 * @param {object} obj
 * @param {name} obj的属性名
 */
function formatObj(node, name, arr = {}) {
  formatObjLoop(node, name, arr);
  return Object.values(arr);
}

/**
 * @description 嵌套对象打平(虚拟部门专用)
 * @param {object} obj
 * @param {name} obj的属性名
 */
function formatObjLoop(node, name, arr = {}) {
  if (!node.visible) {
    return arr;
  }
  if (node.data.id) {
    arr[node.data.id] = node.data;
  }
  if (node.hasOwnProperty(name) && Array.isArray(node[name])) {
    node[name].forEach(childNode => {
      if (!childNode.visible) {
        return arr;
      }
      arr[childNode.data.id] = childNode.data;
      formatObjLoop(childNode, name, arr);
    });
  }
  return arr;
}

/**
 * @description 递归改变对象属性
 * @param {object} tag
 */
function resetAttr(tag) {
  tag.disabled = false;
  tag.includeAll = 0;
  if (tag.children && tag.children.length > 0) {
    tag.children.forEach(item => {
      item.includeAll = 0;
      item.disabled = false;
      resetAttr(item);
    });
  };
}

let departmentsRecord = [];
export default {
  name: 'CheckTeamDepartment',
  mixins: [authMixin, commonMixin],
  props: {
    childrenIncluded: {
      type: [Number, Boolean],
      default: 2
    },
    functions: {
      type: Boolean,
      default: true
    },
    checkStrictly: {
      type: Boolean,
      default: true
    },
    openSelectSiblingNode: {
      // 是否开启选同级部门
      type: Boolean,
      default: false
    },
    userCount: {
      type: Boolean,
      default: true
    },
    teamId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      listType: [
        {id: 1, label: i18n.t('biz_udp_all')},
        {id: 2, label: i18n.t('biz_udp_subsequent_all')}
      ]
    };
  },
  created() {
  },
  methods: {
    handleOrgChange(data) {
      this._targetOrgId = data.orgId;
      this.targetOrgName = data.orgName;
      this.$emit('org-change');
    },
    init() {
      this.update(() => {
        if (!this.checkStrictly) {
          this.handleCheck();
        }
      });
    },
    // 手动更新
    update(cb) {
      const { department } = this.selectData;
      let newDepartment = [...department];
      if (this.enableGroupCorp) {
        departmentsRecord = newDepartment;
        newDepartment = newDepartment.filter(item => item.orgId === this._targetOrgId);
      }
      if (this.multiple) {
        this.$refs['deptTree'].clear();
        this.$nextTick(() => {
          this.$refs['deptTree'].filter();
          this.$refs['deptTree'].setCheckedDeptIds(newDepartment.map(item => item.id));
          this.$refs['deptTree'].setChildrenIncludeByDeptIdV2(newDepartment.filter(item => item.includeAll));
          // department.map(item => {
          //   if (item.includeAll) {
          //     this.$refs['deptTree'].setChildrenIncludeByDeptId(item.id);
          //   }
          // });
          if (cb) {
            cb();
          }
        });
      } else {
        // 说明是单选
        this.$refs['deptTree'].setSingleCheckedDeptId(newDepartment[0]);
      }
    },
    selectSiblingChange(data) {
      // 选择同级触发
      console.log('selectSiblingChange');
      const { department } = this.selectData;
      let newDepartment = [...department];
      const departmentIds = new Map();
      newDepartment.forEach(item => departmentIds.set(item.id, item));
      data.forEach(item => {
        const dept = departmentIds.get(item.id);
        if (!dept) {
          const deptNode = { ...item };
          if (this.enableGroupCorp) {
            deptNode.orgId = this._targetOrgId;
            deptNode.orgName = this.targetOrgName;
          }
          newDepartment.push(deptNode);
        }
      });
      this.$emit('change', newDepartment, 'department');
    },
    handleTypeChange(node, data) {
      // 当前全部0, 后续全部1
      let idsObj = formatObj(node, 'childNodes');
      idsObj = idsObj.map(item => {
        let obj = { ...item };
        if (this.enableGroupCorp) {
          obj.orgId = this._targetOrgId;
          obj.orgName = this.targetOrgName;
        }
        obj['_type'] = 0;
        Object.seal(obj);
        return obj;
      });
      if (!data.hasAllDeptPermission) {
        idsObj = idsObj.filter(obj => obj.id !== data.id);
      }
      console.log(idsObj);
      this.updateDepartmentByType({
        data: idsObj,
        isCurrent: data.includeAll || 0
      });
    },
    updateDepartmentByType({data, isCurrent}) {
      const { department } = this.selectData;
      let newDepartment = [...department];
      const departmentIds = new Map();
      newDepartment.forEach(item => departmentIds.set(item.id, item));
      if (isCurrent === 0) {
        data.forEach(item => {
          const dept = departmentIds.get(item.id);
          if (!dept) {
            newDepartment.push(item);
          } else {
            // 之前选过的，为了防止不破坏列表展示tag顺序
            dept.includeAll = 0;
          }
        });
      } else {
        const excludeIds = new Set();
        data.forEach((item, index) => {
          // 后续全部展示区域只保存第一个
          if (index === 0) {
            const dept = departmentIds.get(item.id);
            if (!dept) {
              newDepartment.push(item);
            } else {
              // 之前选过的，为了防止不破坏列表展示tag顺序
              dept.includeAll = 1;
            }
          } else if (index > 0) {
            excludeIds.add(item.id);
          }
        });
        newDepartment = newDepartment.filter(item => !excludeIds.has(item.id));
      }
      console.log('12312312313--------12321312--3-12-312--312-', newDepartment);
      this.$emit('change', newDepartment, 'department');
    },
    formatCheckNodes(checkNodes) {
      let folllowAll = [];
      let currentAll = [];
      const folllowAllIds = new Set();
      checkNodes.map((item, index) => {
        if (item.includeAll) {
          item.ORDER = index;
          folllowAll.push(item);
          folllowAllIds.add(item.id);
        } else {
          item.ORDER = index;
          currentAll.push(item);
        };
      });
      folllowAll = folllowAll.filter(item => !folllowAllIds.has(item.parentId));
      return [...folllowAll, ...currentAll].sort((a, b) => a.ORDER - b.ORDER);
    },
    // 点击复选框
    handleCheck(a, b, c) {
      let checkNodes = this.$refs.deptTree.getCheckedDeptNodes();
      // 由于选中的节点可能包含后续全部的子节点，剔除后续全部子节点
      checkNodes = this.formatCheckNodes(checkNodes);
      checkNodes = checkNodes.map(item => {
        let obj = { ...item };
        obj['_type'] = 0;
        obj.orgId = this._targetOrgId;
        obj.orgName = this.targetOrgName;
        Object.seal(obj);
        return obj;
      });
      if (this.enableGroupCorp) {
        departmentsRecord = departmentsRecord.filter(item => item.orgId !== this._targetOrgId);
        departmentsRecord = departmentsRecord.concat(checkNodes);
        departmentsRecord.forEach(item => {
          item.combinationName = `${item.orgName}-${item.name}`;
        });
        this.$emit('change', departmentsRecord, 'department');
      } else {
        this.$emit('change', checkNodes, 'department');
      }
    },
    handleNodeClick(node) {
      if (!this.multiple) this.$emit('change', [node], 'department');
    },
    // 关闭单个标签
    closeTag({ tag, index }) {
      if (tag.includeAll) {
        resetAttr(tag);
      }
      if (!this.checkStrictly) {
        // 如果是父子节点关联的
        this.$refs['deptTree'].setChecked(tag.distinctId || tag.id, false, true);
        this.handleCheck();
      } else {
        this.update();
      }
    }
  }
};
</script>
