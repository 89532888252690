var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "standard-size-14 yxtbiz-ai-robot__coure" },
    [
      _vm.dataList.length === 0
        ? _c("EmptyData")
        : [
            _c(
              "div",
              { staticClass: "search-enroll-list" },
              [
                _c(
                  "div",
                  {
                    staticClass: "search-enroll-list-tips-info",
                    class: { "font-size-16": _vm.size === "large" },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm
                            .$t("pc_biz_search_enroll_content")
                            .d("为您找到以下报名：")
                        ) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._l(_vm.dataList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "search-enroll-list-item",
                      on: {
                        click: function ($event) {
                          return _vm.toDetail(item)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "left-img" }, [
                        item.imgUrl
                          ? _c("img", { attrs: { src: item.imgUrl, alt: "" } })
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "right-count" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "search-enroll-list-item-header ellipsis-2",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "search-enroll-list-item-header-status",
                                class: {
                                  status_1:
                                    _vm.transformStatus(item).status === 1,
                                },
                              },
                              [_vm._v(_vm._s(_vm.transformStatus(item).name))]
                            ),
                            _c("div", {
                              staticClass:
                                "search-enroll-list-item-header-title",
                              domProps: { innerHTML: _vm._s(item.title) },
                            }),
                          ]
                        ),
                        _vm.size === "small"
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "search-enroll-list-item-footer-small",
                              },
                              [
                                _c("div", { staticClass: "label" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm
                                        .$t("pc_biz_survey_lbl_type")
                                        .d("类型") +
                                        ": " +
                                        _vm.transformTag(item.tag)
                                    )
                                  ),
                                ]),
                                _c("div", { staticClass: "time" }, [
                                  _vm._v(_vm._s(_vm.transformTime(item))),
                                ]),
                              ]
                            )
                          : _c(
                              "div",
                              { staticClass: "search-enroll-list-item-footer" },
                              [
                                _c("div", { staticClass: "label" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm
                                        .$t("pc_biz_survey_lbl_type")
                                        .d("类型") +
                                        ": " +
                                        _vm.transformTag(item.tag)
                                    )
                                  ),
                                ]),
                                _c("div", { staticClass: "divider" }),
                                _c("div", { staticClass: "time" }, [
                                  _vm._v(_vm._s(_vm.transformTime(item))),
                                ]),
                              ]
                            ),
                      ]),
                    ]
                  )
                }),
              ],
              2
            ),
            _vm.needMore
              ? _c(
                  "div",
                  {
                    staticClass: "yxtbiz-ai-robot__coure-more color-gray-8",
                    on: { click: _vm.toMore },
                  },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$t("pc_biz_lbl_common_viewmore")) +
                        "\n      "
                    ),
                    _c("yxt-svg", {
                      attrs: {
                        "remote-url": `${_vm.$staticBaseUrl}ufd/55a3e0`,
                        width: "16px",
                        height: "16px",
                        "icon-class": "arrow_right",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }