var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yxt-biz-relationDynamicUserGroup" },
    [
      _c(
        "yxt-row",
        {
          staticClass: "yxt-user-selector-container yxt-user-multi-container",
          attrs: { type: "flex" },
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-bottom": "20px" } },
            [
              _c("yxt-alert", {
                attrs: {
                  title: _vm.relationTips,
                  type: "info",
                  "show-icon": "",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "yxtbiz-user-search-wrap" },
            [
              _vm.enableGroupCorp
                ? _c("yxtbiz-group-org-select", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.visibleOrgSelector,
                        expression: "visibleOrgSelector",
                      },
                    ],
                    attrs: {
                      functionCode: _vm.functionCode,
                      disabled: _vm.disabled,
                      setCurrentOrg: true,
                      targetOrgId: _vm.targetOrgId,
                      dataPermissionCode: _vm.dataPermissionCode,
                      selectFirst: true,
                      clearable: false,
                      size: "small",
                      visibleOrgIds: _vm.visibleOrgIds,
                    },
                    on: { change: _vm.handleOrgChange },
                  })
                : _vm._e(),
              _c(
                "select-popover",
                {
                  ref: "selectPopover",
                  staticClass: "ml12",
                  attrs: { size: "small", selectOpition: _vm.selectOpition },
                },
                [
                  _c(
                    "div",
                    { staticClass: "table_tree" },
                    [
                      (_vm.enableGroupCorp && _vm.postData.targetOrgId) ||
                      !_vm.enableGroupCorp
                        ? _c("user-group-tree", {
                            ref: "userGroupTree",
                            attrs: {
                              targetOrgId: _vm.postData.targetOrgId,
                              enableGroupCorp: _vm.enableGroupCorp,
                              functionCode: _vm.functionCode,
                              dataPermissionCode: _vm.dataPermissionCode,
                              filterable: true,
                            },
                            on: { nodeClick: _vm.selectGroupCatalog },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _c("yxt-input", {
                staticClass: "ml12",
                staticStyle: { width: "240px" },
                attrs: {
                  size: "small",
                  placeholder: _vm.$t("pc_biz_udp_tip_onlysearchGroup"),
                  searchable: "",
                },
                on: { search: _vm.searchGroup },
                model: {
                  value: _vm.groupValue,
                  callback: function ($$v) {
                    _vm.groupValue = $$v
                  },
                  expression: "groupValue",
                },
              }),
            ],
            1
          ),
          _c(
            "yxt-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: _vm.refTable,
              staticClass: "mt16 yxt-user-table",
              attrs: { height: _vm.tableHeight, data: _vm.usergroups },
            },
            [
              _c("template", { slot: "empty" }, [
                _c(
                  "div",
                  { staticStyle: { "line-height": "normal" } },
                  [_c("yxt-empty")],
                  1
                ),
              ]),
              _c("yxt-table-column", {
                attrs: { "show-overflow-tooltip": false, width: "40" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("yxt-radio", {
                          attrs: { label: scope.row.id },
                          on: {
                            change: function ($event) {
                              return _vm.handleSlect(scope.row.id, scope.row)
                            },
                          },
                          model: {
                            value: _vm.singleSelect,
                            callback: function ($$v) {
                              _vm.singleSelect = $$v
                            },
                            expression: "singleSelect",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("yxt-table-column", {
                attrs: {
                  "show-overflow-tooltip": true,
                  label: _vm.$t("biz_udp_usergroup"),
                  prop: "name",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.name))]),
                        scope.row.userGroupType === 1
                          ? _c(
                              "yxt-tag",
                              {
                                staticClass: "ml8",
                                attrs: { effect: "dark", size: "mini" },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t("pc_biz_ls_group").d("集团")
                                    ) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("yxt-table-column", {
                attrs: {
                  label: _vm.$t("pc_biz_udp_lbl_description"),
                  "show-overflow-tooltip": true,
                  prop: "remark",
                  formatter: _vm.formatRemark,
                },
              }),
            ],
            2
          ),
          _c(
            "yxt-row",
            {
              staticClass: "yxtbiz-user-selector-mask mt16",
              attrs: { type: "flex", align: "middle" },
            },
            [
              _c("yxt-row", {
                staticClass: "col-flex-1",
                attrs: { type: "flex", align: "middle" },
              }),
              _c("yxt-pagination", {
                attrs: {
                  "current-page": _vm.page,
                  "page-size": _vm.postData.limit,
                  "simple-total": "",
                  layout: "total, prev, pager, next",
                  total: _vm.count,
                  "pager-count": 5,
                },
                on: {
                  "size-change": _vm.sizeChange,
                  "current-change": _vm.pageChange,
                  "update:currentPage": function ($event) {
                    _vm.page = $event
                  },
                  "update:current-page": function ($event) {
                    _vm.page = $event
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }