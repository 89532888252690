<template>
  <div class="yxtbiz-ai-robot-collect-kng width-percent-100 pointer" @click="toDetail(data.id)">
    <div v-if="data.coverUrl !== ''" class="yxtbiz-ai-robot-collect-kng__face">
      <yxt-image :src="data.coverUrl" alt fit="cover" />
    </div>
    <div
      class="pull-left yxtbiz-ai-robot-collect-kng__maincontent"
      :class="{ 'yxtbiz-ai-robot-collect-kng__haveface': data.coverUrl !== '' }"
    >
      <div
        :class="[
          size === 'small'
            ? 'ellipsis-2 font-size-14 lh22'
            : 'ellipsis font-size-16  lh24',
          'color-gray-10'
        ]"
      >
        {{ data.title }}
      </div>
      <div
        :class="[
          size === 'small' ? 'mt4' : 'mt8',
          'yxtbiz-ai-robot-collect-kng__bottom lh20 color-gray-7 font-size-12'
        ]"
      >
        <span v-if="data.studyScore"
          >{{ $t('pc_biz_collect_studyScore') }}&nbsp;<span
            style="color: #f66704"
            >{{ data.studyScore }}</span
          ></span
        >
        <yxt-divider v-if="data.studyScore" direction="vertical" />
        <div>
          {{ $t('pc_biz_collect_studyCount', { num: data.studyCount }) }}
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import favoriteMixin from './favorite-mixin.js';

export default {
  name: 'kngItem',
  mixins: [favoriteMixin],
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  created() {},
  methods: {
    toDetail(id) {
      let url = `${window.location.protocol}//${window.location.host}/kng/#/scan?kngId=${id}`;
      window.open(url);
    }
  }
};
</script>
  <style scoped lang="scss">
.yxtbiz-ai-robot-collect-kng {
  display: flex;
  align-items: center;
  .yxtbiz-ai-robot-collect-kng__maincontent {
    display: flex;
    flex-direction: column;
  }

  .yxtbiz-ai-robot-collect-kng__face {
    position: relative;
    width: 112px;
    height: 64px;
    overflow: hidden;
    border-radius: 4px;

    ::v-deep .yxt-image {
      width: 112px;
      height: 64px;
    }

    ::v-deep img {
      width: 100% !important;
      height: 100%;
    }
  }

  .yxtbiz-ai-robot-collect-kng__haveface {
    width: calc(100% - 112px);
    padding-left: 12px;
  }

  .yxtbiz-ai-robot-collect-kng__bottom {
    display: flex;
    align-items: center;

    ::v-deep .yxt-divider--vertical {
      height: 10px;
    }
  }
}
</style>
  