<template>
    <div v-show="show" :class="classlist" :style="style" style="bottom: 50px;" class="yxt-video-art">{{ text }}</div>
</template>

<script>
import api from '../service';
export default {
  props: {
    url: {
      type: [String, Array],
      default: ''
    },
    opacity: {
      default: 60
    },
    size: {
      default: 'medium'
    },
    lan: {
      default: 'zh'
    },
    enabled: {
      default: true,
      type: Boolean
    }
  },
  data() {
    return {
      style: {},
      textList: [],
      show: false,
      text: '',
      textIndex: null,
      time: null,
      savedPostion: null // 记录拖动后的字幕标准位置
    };
  },
  computed: {
    classlist() {
      return `yxt-video-srt__${this.size}`;
    }
  },
  methods: {
    async setSrt(url) {
      try {
        const res = await api.getSrt(url);
        if (res.data) {
          this.transferSrt(res.data);
        }
      } catch (e) {
        console.error(e);
      }
      if (this.textList.length === 0 && typeof this.url !== 'object') {
        // 无字幕， 触发事件
        this.$emit('hide-setting');
      } else {
        this.$emit('show-setting');
      }
    },
    transferSrt(data) {
      const textList = data.split(/\n\n|\r\r|\r\n\r\n/)
        .filter(item => item !== '')
        .map((item, index) => {
          const textItem = item.split(/\n/);
          const reg = /^(\d{2}):(\d{2}):(\d{2})[\.,](\d{1,3}) --\> (\d{2}):(\d{2}):(\d{2})[\.,](\d{1,3})/g;
          const res = reg.exec(textItem[1]);
          const timeLine = res[0].split(' --> ');
          return {
            index,
            sort: textItem[0],
            text: textItem[2],
            startTime: this.toSeconds(timeLine[0]),
            endTime: this.toSeconds(timeLine[1])
          };
        });

      this.textList = textList;
    },
    toSeconds(time) {
      const arr = time.split(/\,|\./);
      let sec = 0;
      const a = arr[0].split(':');
      sec = Number(a[0]) * 60 * 60 + Number(a[1]) * 60 + Number(a[2]) + arr[1] / 1000;
      return sec;
    },
    setSrtPosition() {
      // 等待display变为可见时进行尺寸计算，避免闪烁
      this.$el.style.visibility = 'hidden';
      setTimeout(() => {
        if (!this.$el || !this.$el.offsetParent) return;
        const offsetWidth = this.$el.offsetWidth;
        const containerWidth = this.$el.offsetParent.offsetWidth;
        this.$nextTick(() => {
          if (!this.savedPostion) {
            this.style.left = `${(containerWidth - offsetWidth) / 2}px`;
            this.$forceUpdate();
          } else {
            let left, top;
            const { centerX, savedTop } = this.savedPostion;
            left = centerX - offsetWidth / 2;
            top = savedTop;

            if (left < 0) {
              left = 0;
            } else if (left + offsetWidth > containerWidth) {
              left = containerWidth - offsetWidth;
            }

            if (top < 0) {
              top = 0;
            }

            this.style.left = `${left}px`;
            this.style.top = `${top}px`;
            this.$forceUpdate();
          }
          this.$el.style.visibility = '';
        });
      }, 0);
    },
    timeChange(time) {
      this.time = time;
      if (!this.enabled) return;
      const textItem = this.textList.find(item => {
        return item.startTime <= time && time <= item.endTime;
      });
      if (textItem) {
        this.show = true;
        this.text = textItem.text;
        if (this.textIndex !== textItem.index) {
          this.textIndex = textItem.index;
          this.setSrtPosition();
        }
      } else {
        this.show = false;
        this.textIndex = null;
        this.text = '';
      }
    },
    moveChange(option) {
      this.savedPostion = option;
    }
  },
  watch: {
    url: {
      immediate: true,
      handler(value) {
        if (value) {
          if (Array.isArray(value)) {
            let srt = value.find(item => item.lan === this.lan) || value[0];
            if (!srt) {
              return;
            }
            this.setSrt(srt.url);
          } else {
            this.setSrt(value);
          }
        }
      }
    },
    opacity: {
      immediate: true,
      handler(v) {
        const opacity = v / 100;
        this.style.background = `rgba(0,0,0, ${opacity})`;
        this.$forceUpdate();
      }
    },
    size() {
      this.setSrtPosition();
    },
    lan(v) {
      if (Array.isArray(this.url)) {
        let srt = this.url.find(item => item.lan === v) || this.url[0];
        if (!srt) {
          return;
        }
        this.setSrt(srt.url);
      }
    },
    enabled(v) {
      this.show = v;
    }
  }
};
</script>
