import Captcha from './src/main';

Captcha.setConfig = (config) => {
  console.log(config);
};

/* istanbul ignore next */
Captcha.install = function(Vue, config) {
  Vue.component(Captcha.name, Captcha);
};

export default Captcha;
