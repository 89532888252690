var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "yxt-form-item",
    {
      staticClass: "mb16",
      attrs: { label: _vm.config.labelName, prop: _vm.config.name },
    },
    [
      _c("yxt-input", {
        attrs: {
          maxlength: _vm.maxlength,
          "show-word-limit": "",
          placeholder: _vm.$t("pc_biz_core_tip_placeholder"),
          size: "small",
          disabled: _vm.disabled,
        },
        model: {
          value: _vm.value_,
          callback: function ($$v) {
            _vm.value_ = $$v
          },
          expression: "value_",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }