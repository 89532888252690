var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yxtbiz-i18n-custom-template-wrap" },
    [
      _vm.$attrs.visible
        ? _c(
            "custom-template",
            _vm._g(
              _vm._b({}, "custom-template", _vm.$attrs, false),
              _vm.$listeners
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }