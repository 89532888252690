<template>
  <yxt-select
    slot="reference"
    v-model="selected"
    @change="change"
    collapse-tags
    allow-create
    class="wd100p"
    popper-class="yxtbiz-select-text-wrap"
    clearable
    multiple>
    <div class="select-text-wrap" slot="empty">
      <div v-for="(item, index) in selected" :key="index" class="tag-item">
        <span class="ellipsis">{{ item }}</span>
        <i class="yxt-icon-delete hover" @click="deleteTag(item)"></i>
      </div>
    </div>
    <div slot="fixed-footer">
      <div class="tag-options">
        <yxt-input
          v-model="tagValue"
          size="small"
          maxlength="50"
          :placeholder="$t('pc_biz_core_tip_placeholder').d('请输入')"
          @keyup.enter.native="addText"
        />
        <yxt-button style="margin-left: 5px;" type="text" @click="addText"
          >{{ $t('pc_biz_btn_add').d('添加') }}</yxt-button
        >
        <yxt-button v-if="maxLength" type="text"
          >{{ selected.length }}/{{ maxLength }}</yxt-button
        >
      </div>
      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
    </div>
  </yxt-select>
</template>
<script>
import { i18n } from '../../../common-util/changeLangs';
export default {
  model: {
    event: 'change'
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    maxLength: {
      type: [String, Number],
      default: 0
    }
  },
  watch: {
    value: {
      handler(v) {
        this.selected = v;
      }
    }
  },
  data() {
    return {
      tagValue: '',
      errorMessage: '',
      selected: []
    };
  },
  created() {
    if (Array.isArray(this.value)) {
      this.selected = this.value;
    }
  },
  methods: {
    addText() {
      const maxLength = Number(this.maxLength);
      if (maxLength && this.selected.length >= maxLength) {
        this.errorMessage = i18n.t('pc_biz_select_text_err01').d('选项超出上限');
        return;
      }
      if (!this.tagValue) {
        this.errorMessage = i18n.t('pc_biz_select_text_err02').d('选项不能为空');
        return;
      }
      if (this.selected.includes(this.tagValue)) {
        this.errorMessage = i18n.t('pc_biz_select_text_err03').d('选项已存在');
        return;
      }
      this.selected.push(this.tagValue);
      this.tagValue = '';
      this.errorMessage = '';
      this.$emit('change', this.selected);
    },
    change() {
      this.$emit('change', [...this.selected]);
    },
    deleteTag(tag) {
      this.selected = this.selected.filter(text => text !== tag);
      this.$emit('change', this.selected);
    }
  }
};
</script>
<style lang="scss">
.yxtbiz-select-text-wrap {
  .yxt-select-dropdown__suffix {
    height: auto;
    line-height: normal;
  }
  .error-message {
    color: red;
    line-height: 18px;
    font-size: 12px;
  }
}
</style>
<style lang="scss" scoped>
.select-text-wrap {
  max-height: 200px;
  padding: 0 10px;
  overflow: auto;
  .tag-item {
    display: flex;
    align-items: center;
    height: 32px;
    justify-content: space-between;
    &:hover {
      color: var(--color-primary-6);
    }
    .ellipsis {
      flex: 1;
      width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .hover {
      &:hover {
        color: red;
        cursor: pointer;
      }
    }
  }
}
.tag-options {
  display: flex;
}
</style>
