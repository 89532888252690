<template>
  <div class="checked_list">
    <div class="checked_list_box">
      <yxt-scrollbar :fit-height="true" v-if="!virtualRender">
        <div style="margin-bottom: 17px;height: calc(100% - 17px);">
          <template v-for="(source, index) in dataList">
            <yxt-tag
            v-if="index === 500"
            disable-transitions
            size="small"
            type="info"
            key="other_list"
            >{{source.name}}</yxt-tag>
            <check-list-tag
              v-else
              :key="source.id"
              :source="source"
              :index="index"
              @close="handleClose(source, index)"
              class="mr8"
              style="margin-bottom: 8px;"
          ></check-list-tag>
          </template>
        </div>
      </yxt-scrollbar>
      <div style="margin-bottom: 17px;height: calc(100% - 17px);" v-else>
        <yxtbiz-virtual-list is-scrollbar style="height: 100%;overflow: hidden;" :data-sources="dataList" data-key="id" :estimate-size="32" :keeps="50" item-tag="div">
          <template slot-scope="{source, index}">
            <yxt-tag
            v-if="index === 500"
            disable-transitions
            size="small"
            type="info"
            >{{source.name}}</yxt-tag>
            <check-list-tag
              v-else
              :source="source"
              :index="index"
              @close="handleClose(source, index)"
              class="mr8"
              style="margin-bottom: 8px;"
            ></check-list-tag>
          </template>
        </yxtbiz-virtual-list>
      </div>

    </div>
    <span class="check-list__placeholder" v-if="list.length === 0">{{$t('biz_udp_please_select')}}...</span>
    <div class="operation">
      <yxt-button
        @click="clear"
        class="font-size-12 color-gray-9 hover-primary-6"
        type='text'
        :disabled="list.length === 0"
      >{{$t('biz_udp_clear_all')}}</yxt-button>
    </div>
  </div>
</template>

<script>
import Virtual from '../../../virtual-list';
import { Scrollbar } from 'yxt-pc';
import CheckListTag from './CheckListTag';
export default {
  components: {
    [Virtual.name]: Virtual,
    YxtScrollbar: Scrollbar,
    CheckListTag
  },
  name: 'CheckedList',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    virtualRender: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    list: {
      handler(value) {
        if (value.length <= 500) {
          this.dataList = value;
        } else {
          const list = value.slice(0, 500);
          list.push({
            name: `+${value.length - 500}`,
            id: 'other_list'
          });
          this.dataList = list;
        }
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    console.log('checkedList:', this.list);
  },
  data() {
    return {
      imgs: [
        'dept',
        'position',
        'usergroup',
        'role',
        'pos-grade',
        'person-center'
      ],
      dataList: []
    };
  },
  methods: {
    handleClose(tag, index) {
      this.$emit('close', { tag, index });
    },
    clear() {
      this.$emit('clear');
    }
  },
  computed: {
  }
};
</script>

