<!-- 创建时间2024/01/04 10:25:18 -->
<!-- 创建人：Zhao bing bing -->
<!-- 组件描述：创建知识空间 -->
<template>
  <KngRelated type="createSpace" v-bind="$attrs" v-on="$listeners"/>
</template>

<script>
import KngRelated from '../common/kng/kng-releated.vue';
export default {
  name: 'KngLibCreate',
  components: {
    KngRelated
  }
};
</script>