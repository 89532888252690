var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yxtbiz-ai-robot__search-base" },
    [
      _vm.searchList.length === 0
        ? _c("EmptyData")
        : [
            _c(
              "div",
              {
                staticClass: "pb12 ph12 color-gray-7",
                class: _vm.isSmall ? "font-size-14 lh22" : "font-size-16 lh24",
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$t("pc_biz_ote_lbl_search_content", [
                        _vm.$t(_vm.searchName),
                      ])
                    ) +
                    "\n    "
                ),
              ]
            ),
            _vm._l(_vm.searchList, function (list) {
              return _c(
                "div",
                {
                  key: list[_vm.listId],
                  on: {
                    click: function ($event) {
                      return _vm.$emit("to-list", list)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "yxtbiz-ai-robot__base-list" }, [
                    _c(
                      "div",
                      {
                        class: _vm.isSmall
                          ? "ellipsis-2 font-size-14 lh20"
                          : "ellipsis font-size-16 lh24",
                      },
                      [
                        _vm.tagType ||
                        (_vm.showDisabled && _vm.quesType(list) === 0)
                          ? _c(
                              "yxtf-tag",
                              {
                                staticClass: "v-mid",
                                attrs: {
                                  type: _vm.tagLists[_vm.getStatus(list)].type,
                                  size: "mini",
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t(
                                        _vm.tagLists[_vm.getStatus(list)].label
                                      )
                                    ) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c("span", { staticClass: "v-mid color-gray-10" }, [
                          _vm._v(_vm._s(list[_vm.listName])),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "font-size-12 lh20 color-gray-7",
                        class: _vm.isSmall ? "mt4" : "mt8",
                      },
                      [_vm._t("default", null, { list: list })],
                      2
                    ),
                  ]),
                ]
              )
            }),
            _vm.count > 3
              ? _c(
                  "div",
                  {
                    staticClass: "color-gray-8 hand yxtbiz-ai-robot__base-more",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("to-more")
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "lh22 v-mid font-size-14 d-in-block" },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("pc_biz_ote_lbl_viewmore" /* 查看更多 */)
                          )
                        ),
                      ]
                    ),
                    _c("yxt-svg", {
                      staticClass: "ml4 v-mid",
                      attrs: {
                        width: "16px",
                        height: "16px",
                        "icon-class": "arrow_right",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }