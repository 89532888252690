<template>
    <div class="yxtbiz-personal-center-nav font-size-14 color-gray-10 bg-white">
        <ul>
            <li v-for="(item, index) in navList" :key="item.name"
                class="tab-item hand hover-bg-primary-1 hover-primary-6"
                :class="{'color-primary-6 bg-primary-1 yxtf-weight-6': pageCode === item.pageCode}"
                @click="changeTab(index,item)">
                <yxtf-row type="flex" align="middle">
                    <yxt-svg :remote-url='mediaUrl'
                             class='hover-primary-6 mr16'
                             :class="[pageCode === item.pageCode ? 'color-primary-6' : 'default-color']" width="22px"
                             height="22px" :icon-class="item.icon"/>
                    <span class="tab-label">{{item.name}}</span>
                </yxtf-row>
                <span class="tab-count" v-if="item.count">{{item.count}}</span>
            </li>
        </ul>
    </div>
</template>

<script>
import { FrontRow } from 'yxt-pc';

const baseCommon = (typeof window !== 'undefined' && window.feConfig && window.feConfig.common);
export default {
  name: 'YxtbizPersonalCenterNav',
  components: {
    YxtfRow: FrontRow
  },
  props: {
    pageCode: {
      type: String,
      required: true
    },
    mediaUrl: {
      type: String,
      default: `${(baseCommon && window.feConfig.common.staticBaseUrl) || 'https://stc.yxt.com/' }ufd/2338a3/core/pc/svg`
    }
  },
  data() {
    return {
      currentIndex: '',
      navList: [
        {
          'name': '个人资料',
          'icon': 'personal-information',
          'url': '/#/userinfo',
          'pageCode': 'user_info'
        },
        {
          'name': '账号与安全',
          'icon': 'account-security',
          'url': '/#/user/accountsafe',
          'pageCode': 'account_safe'
        }
      ]
    };
  },
  methods: {
    changeTab(index, { url }) {
      if (!url) return;
      window.location.href = url;
    }
  }
};
</script>
