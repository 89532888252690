var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "article",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.initLoading,
          expression: "initLoading",
        },
      ],
      class: [
        "yxtbiz-ai-robot-instruction-prompt",
        _vm.size === "small" ? "font-size-14 lh24" : "font-size-16 lh28",
      ],
      style: { width: _vm.size === "small" ? "432px" : "900px" },
      attrs: { "yxt-loading-size": "small" },
    },
    [
      _c("header", { staticClass: "color-gray-10" }, [
        _vm._v(
          _vm._s((_vm.msg.contentDetail && _vm.msg.contentDetail.content) || "")
        ),
      ]),
      _vm.examples.length > 0
        ? _c("main", { staticClass: "mt12" }, [
            _c(
              "div",
              { staticClass: "yxtbiz-ai-robot-instruction-prompt__try" },
              [
                _c("div", [
                  _vm._v(
                    _vm._s(_vm.$t("pc_biz_have_a_try" /* 你可以试试： */))
                  ),
                ]),
                _c(
                  "yxt-button",
                  {
                    attrs: { loading: _vm.btnLoading, type: "text2" },
                    on: { click: _vm.haveAChange },
                  },
                  [
                    !_vm.btnLoading
                      ? _c("yxt-svg", {
                          staticClass: "v-mid mr8 mb4",
                          attrs: {
                            width: "16px",
                            height: "16px",
                            "icon-class": "right-rotate",
                          },
                        })
                      : _vm._e(),
                    _vm._v(
                      _vm._s(_vm.$t("pc_biz_ai_change_promot" /* 换一换 */))
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "ul",
              { staticClass: "yxtbiz-ai-robot-instruction-prompt__examples" },
              _vm._l(_vm.examples, function (example) {
                return _c(
                  "li",
                  {
                    key: example,
                    class: [
                      "yxtbiz-ai-robot-instruction-prompt__examples-item",
                      `yxtbiz-ai-robot-instruction-prompt__examples-item--${_vm.size}`,
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.handleExampleClick(example)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "ellipsis-2" }, [
                      _vm._v(
                        "\n            " + _vm._s(example) + "\n          "
                      ),
                    ]),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }