var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.finished
    ? _c(
        "import-base",
        {
          staticClass: "yxtbiz-import-proc",
          attrs: { "template-url": _vm.templateUrl },
          on: {
            ready: _vm.ready,
            reset: _vm.resetImport,
            procEmits: _vm.procEmits,
            onProcess: _vm.onProcess,
            onTemplateUrl: _vm.onTemplateUrl,
          },
        },
        [
          _c("div", { attrs: { slot: "upload" }, slot: "upload" }, [
            _c(
              "div",
              { staticClass: "yxt-upload-container pr" },
              [
                _c(
                  "yxtbiz-upload",
                  {
                    ref: "bizUpload",
                    attrs: {
                      source: _vm.uploaderConfig.source,
                      "config-key": _vm.uploaderConfig.configKey,
                      "module-name": _vm.uploaderConfig.moduleName,
                      "function-name": _vm.uploaderConfig.functionName,
                      "app-code": _vm.uploaderConfig.appCode,
                      "is-v1": _vm.uploaderConfig.isV1,
                      "before-upload": _vm.beforeUpload,
                      "on-progress": _vm.onProgress,
                      "on-uploaded": _vm.onUploaded,
                      "on-error": _vm.uploaderConfig.onError,
                      drag: true,
                      filters: _vm.uploaderConfig.filters,
                      "files-filter": _vm.uploaderConfig.filesFilter,
                    },
                  },
                  [
                    _c("div", { attrs: { slot: "tip" }, slot: "tip" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t(
                              "pc_biz_import_tip_supportedextend" /* 支持扩展名： */
                            )
                          ) +
                          _vm._s(
                            _vm.uploaderConfig.filters.replace(/,/g, " ")
                          ) +
                          "\n        "
                      ),
                    ]),
                  ]
                ),
                _vm.file
                  ? _c(
                      "div",
                      { staticClass: "yxtbiz-import-proc__progress" },
                      [
                        _c("yxt-progress", {
                          attrs: { percentage: _vm.file.percentage },
                        }),
                        _c("i", {
                          staticClass:
                            "yxt-icon-close hand standard-size-12 color-gray-8 hover-primary-6",
                          on: { click: _vm.reset },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm.$slots.uploaded
            ? _c("template", { slot: "uploaded" }, [_vm._t("uploaded")], 2)
            : _vm._e(),
          _vm.$slots.success
            ? _c("template", { slot: "success" }, [_vm._t("success")], 2)
            : _vm._e(),
          _vm.$slots.error
            ? _c("template", { slot: "error" }, [_vm._t("error")], 2)
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }