<template>
  <div class="yxtbiz-ai-robot__search-base">
    <EmptyData v-if="searchList.length === 0" />
    <template v-else>
      <div class="pb12 ph12 color-gray-7" :class="isSmall ? 'font-size-14 lh22' : 'font-size-16 lh24'">
        {{ $t('pc_biz_ote_lbl_search_content', [$t(searchName)]) }}
      </div>
  
      <div v-for="list in searchList"
           :key="list[listId]"
           @click="$emit('to-list', list)">
        <div class="yxtbiz-ai-robot__base-list">
          <div :class="isSmall ? 'ellipsis-2 font-size-14 lh20' : 'ellipsis font-size-16 lh24'">
            <yxtf-tag v-if="tagType || (showDisabled && quesType(list) === 0)"
                      class="v-mid"
                      :type="tagLists[getStatus(list)].type"
                      size="mini">
              {{ $t(tagLists[getStatus(list)].label) }}
            </yxtf-tag>
            <span class="v-mid color-gray-10">{{ list[listName] }}</span>
          </div>
  
          <div class="font-size-12 lh20 color-gray-7"
               :class="isSmall ? 'mt4' : 'mt8'">
            <slot :list="list"></slot>
          </div>
        </div>
      </div>
  
      <div v-if="count > 3"
           class="color-gray-8 hand yxtbiz-ai-robot__base-more"
           @click="$emit('to-more')">
        <span class="lh22 v-mid font-size-14 d-in-block">{{ $t('pc_biz_ote_lbl_viewmore' /* 查看更多 */) }}</span>
        <yxt-svg width="16px"
                 height="16px"
                 icon-class="arrow_right"
                 class="ml4 v-mid" />
      </div>
    </template>
  </div>
</template>

<script>
import EmptyData from '../common/empty-data.vue';
import { getDateTime } from './utils';

export default {
  name: 'BaseList',
  props: {
    searchName: {
      type: String,
      default: 'pc_biz_o2o_lbl_exam'
    },
    dataList: {
      type: Object,
      default: () => {}
    },
    size: String,
    tagLists: {
      type: Object,
      default: () => {}
    },
    tagType: {
      type: [String, Number],
      default: ''
    },
    listId: {
      type: String,
      default: 'id'
    },
    listName: {
      type: String,
      default: 'name'
    },
    curtTime: {
      type: [String, Number]
    }
  },
  components: {
    EmptyData
  },
  computed: {
    isSmall() {
      return this.size === 'small';
    },

    count() {
      if (this.dataList.paging) {
        return this.dataList.paging.count;
      }

      return 0;
    },

    searchList() {
      if (this.dataList && this.dataList.datas && this.dataList.datas.length) {
        return this.dataList.datas.slice(0, 3);
      }
      return [];
    },

    showDisabled() {
      return this.listId === 'quesId';
    },

    listType() {
      const typeMap = {
        'projectId': 'surveyType',
        'praId': 'practiceType',
        'arrId': 'examType',
        'quesId': 'quesType',
        'paperId': 'publicType',
        'trackPraId': 'publicType',
        'surveyId': 'publicType'
      };

      return typeMap[this.listId] || '';
    }
  },

  methods: {
    getStatus(list) {
      return this.listType ? this[this.listType](list) : 0;
    },

    // 调研状态处理
    surveyType({ answerStatus, status, startTime, endTime }) {
      const now = this.curtTime;

      if (answerStatus === 1) {
        return 2;
      } else if (status === 2) {
        if (startTime && getDateTime(startTime) > now) {
          return 0; // 未开始
        } else if (endTime && now < getDateTime(endTime)) {
          return 1; // 进行中
        } else if (endTime && now > getDateTime(endTime)) {
          return 3; // 已结束
        }
        return 1; // 进行中
      } else if (status === 3) {
        return 3;
      }
    },

    // 练习状态处理，type对应 search-practice的 tagLists
    practiceType({ dimensionType, praStatus, upmStatus, repeated, lastAttendStatus }) {
      let type = 2;

      if (dimensionType === 1) {
        if (praStatus >= 3 || upmStatus >= 3) {
          type = 1;
        } else {
          type = 2;
        }
      } else {
        // praStatus 练习安排状态 0：未开始；1：发布中；2：进行中(已发布)；3：已结束；4：归档中；5：已归档
        // upmStatus 用户练习状态 0：未开始；1：练习中；2：已提交；3：已完成；4：已归档；
        // lastAttendStatus 最近一次用户参与状态：0：未开始；1：进行中；2：已完成；

        // 练习(结束 & 归档) || 练习进行中(用户已完成练习 & 不可重复练习)
        if (praStatus >= 3 || (praStatus < 3 && upmStatus >= 3 && !repeated)) {
          type = 1;
        } else {
          const continuePra = praStatus === 2 && lastAttendStatus === 1;
          if (upmStatus === 1 && continuePra) {
            type = 2;
          }

          if (upmStatus >= 3) {
            type = continuePra ? 2 : 3;
          }
        }
      }
      return type;
    },

    // 考试状态处理，type对应 search-exam的 tagLists
    examType({ userStatus, arrStatus, expired }) {
      if (userStatus === 4) {
        return 3;
      }

      // 已提交、批阅中 都是批阅中
      if ([2, 3].includes(userStatus)) {
        return 2;
      }

      if ([3, 4, 5].includes(arrStatus) || expired === 1) {
        return 4;
      }

      return userStatus;
    },

    // 题库禁用/启用状态处理
    quesType({ disabled }) {
      return ~~disabled ? 0 : 1;
    },

    // 试卷、练习的状态处理
    publicType(list) {
      if ('status' in list) return list.status;
      if ('praStatus' in list) return list.praStatus;
      return 0;
    }
  }
};
</script>

<style lang="scss">
.yxtbiz-ai-robot__search-base {
  .lh28 {
    line-height: 28px;
  }

  .yxtbiz-ai-robot__base-list {
    padding: 12px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background-color: #f4f5f6;
    }

    .lh20 {
      line-height: 20px;
    }
  }

  .yxtbiz-ai-robot__base-more {
    padding: 8px 0;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 22px;

    &:hover {
      background-color: #f4f5f6;
    }
  }
}
</style>
