<template>
    <yxt-form-item :label="config.labelName" v-if="showPlatForm" :prop="config.name">
    <yxt-select class="width-percent-100" v-model="value_" filterable size="small" :disabled="disabled">
      <yxt-option 
            v-for="item in list" 
            :key="item.id"
            :label="item.label"
            :value="item.id">
        </yxt-option>
    </yxt-select>
    </yxt-form-item>
</template>
<script>
export default {
  name: 'decoratPlatformSelector',
  props: {
    value: {
      type: [Number, String],
      default: ''
    },
    config: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default: () => ({})
    },
    ruleForm: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      list: [
        {
          label: '电脑端',
          id: 1
        },
        {
          label: '手机端',
          id: 2
        }
      ]
    };
  },
  computed: {
    value_: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
        this.change(val);
      }
    },
    showPlatForm() {
      return this.ruleForm.renovationType && this.ruleForm.renovationType !== 2;
    }
  },
  methods: {
    change(val) {
      const obj = this.list.find(item => item.id === val) || {};
      const {id, label} = obj;
      this.$emit('change', {value: id, labelName: label});
    }
  }
};
</script>