import Video from './src/main.vue';

/* istanbul ignore next */
Video.install = function(Vue) {
  Vue.component(Video.name, Video);
};

Video.setConfig = (config) => {
  Video.env = config.env || 'dev';
};

export default Video;
