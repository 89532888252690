import { udpApi, qidaApi } from 'yxt-biz-pc/packages/api';

// 获取岗位列表
export const getPositionList = (data) => {
  return udpApi.get('positions', {params: data});
};

// 获取用户组列表
export const getUserGroupList = (data, dynamicFlag) => {
  return udpApi.get(`usergroups?dynamicFlag=${dynamicFlag || ''}`, {params: data});
};

// 获取列表
export const getRoleList = (data) => {
  return qidaApi.get('nav/roles/search', {params: data});
};

// 获取职级
export const getLevel = (data) => {
  return udpApi.get('postiongrades', {params: data});
};

// 获取用户列表
export const getUserList = (data) => {
  return udpApi.post(`users/search?targetOrgId=${data.targetOrgId}`, data);
};
