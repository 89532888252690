var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yxtbiz-i18n-input" },
    [
      _c(
        "yxt-input",
        _vm._b(
          {
            on: {
              input: function ($event) {
                return _vm.inputChange($event)
              },
              focus: function ($event) {
                return _vm.focus($event)
              },
            },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          },
          "yxt-input",
          _vm.$attrs,
          false
        ),
        [
          _vm.$attrs.type == "text"
            ? _c(
                "template",
                { slot: "suffix" },
                [
                  _c("yxt-svg", {
                    staticClass:
                      "yxt-input-suffix-svg-btn hover-primary-6 yxtbiz-i18n-input__icon-hover",
                    attrs: {
                      width: "16px",
                      height: "16px",
                      "icon-class": "language",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.handleClick.apply(null, arguments)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.$attrs.type == "textarea"
            ? _c("i", {
                staticClass: "yxt-icon-edit yxt-input__icon",
                attrs: { slot: "suffix" },
                slot: "suffix",
              })
            : _vm._e(),
        ],
        2
      ),
      _vm.$attrs.type == "textarea"
        ? _c(
            "div",
            {
              staticClass:
                "yxtbiz-i18n-input__icon-btn yxtbiz-i18n-input__icon-hover",
              on: { click: _vm.handleClick },
            },
            [
              _c("yxt-svg", {
                staticClass: "yxt-input-suffix-svg-btn hover-primary-6",
                attrs: {
                  width: "16px",
                  height: "16px",
                  "icon-class": "language",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }