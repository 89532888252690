<template>
  <yxt-select
    v-model="selected"
    value-key="value"
    prop-label="label"
    @change="change"
  >
    <yxt-option
      :key="index"
      :value="item"
      v-for="(item, index) in sex"
      :label="item.label"
    ></yxt-option>
  </yxt-select>
</template>

<script>
import { Select, Option } from 'yxt-pc';
import { i18n } from 'yxt-biz-pc/packages/common-util/changeLangs';
export default {
  components: {
    YxtSelect: Select,
    YxtOption: Option
  },
  model: {
    event: 'change'
  },
  props: {
    value: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      selected: [],
      sex: [
        {
          label: i18n.t('pc_biz_udp_lbl_unlimited'),
          value: 0
        },
        {
          label: i18n.t('pc_biz_udp_lbl_male'),
          value: 1
        },
        {
          label: i18n.t('pc_biz_udp_lbl_female'),
          value: 2
        }
      ]
    };
  },
  created() {
    this.setSelected();
  },
  methods: {
    setSelected() {
      this.selected = this.sex.find(s => s.value === this.value);
    },
    change(v) {
      this.$emit('change', v.value);
    }
  },
  watch: {
    value(v) {
      this.setSelected();
    }
  }
};
</script>

<style lang="scss" scoped></style>
