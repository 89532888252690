<template>
  <div class="more_position">
    <div class="table_wrapper">
      <yxt-input
        class="keyword"
        @keyup.enter.native="getPositionData(true)"
        v-model="params.keyWords"
        :placeholder="$t('biz_udp_enter_rolename')"
        style="width: 240px;"
        searchable
        @search='getPositionData(true)'
      >
      </yxt-input>
      <yxt-table
        :height="tableHeight"
        ref="multipleTable"
        class="person-table"
        @select="handleSingleSeletct"
        @select-all="handleSelectAll"
        row-key="id"
        v-loading="loading"
        :data="tableData"
        style="width:100%;"
      >
        <yxt-table-column
          class-name="table-selection-odd"
          type="selection"
          :show-overflow-tooltip='false'
          width="40"
        >
        </yxt-table-column>
        <yxt-table-column
          prop="name"
          show-overflow-tooltip
          :label="$t('biz_udp_role')"
          min-width="180"
        >
        </yxt-table-column>
        <yxt-table-column
          show-overflow-tooltip
          prop="remark"
          :label="$t('pc_biz_udp_lbl_description')"
          min-width="180"
        >
        </yxt-table-column>
        <yxt-table-column
          prop="userCount"
          :label="$t('pc_biz_udp_lbl_userCount')"
          align='right'
        >
        </yxt-table-column>
        <template slot="empty">
          <div style="line-height:normal">
            <yxt-empty />
          </div>
        </template>
      </yxt-table>
      <div class="yxtbiz-page__content">
        <div class="yxtbiz-page__content-right">
          <yxt-button
            type="text"
            class="lh32"
            :class="loadingAll?'color-primary-4 disabled':''"
            @click="loadAll"
            :disabled="!tableData.length"
            >{{$t('biz_udp_all_filter_results')}}</yxt-button>
            <div class="yxtbiz-loading__all" v-loading="loadingAll"></div>
        </div>
        <yxt-pagination
          small
          class="pages"
          :page-size="params.limit"
          :page-sizes="[5, 10, 20, 30, 50, 100]"
          @size-change="handleSizeChange"
          :current-page.sync="currentPage"
          layout="total, prev, pager, next"
          @current-change="handleCurrentChange"
          :total="total"
          simple-total
        >
        </yxt-pagination>
      </div>
    </div>
    <!-- <div class="list">
      <checked-list @clear="clear" @close="closeTag" :list="tempCheckedPositons" />
    </!-->
  </div>
</template>

<script>
import { getRoleList } from '../service';
import echoMixin from '../mixin/echo';
import resizeTable from '../../../user-selector/mixins/resizeTable';
import authMixin from '../mixin/auth';
import { Table, TableColumn, Pagination, Empty } from 'yxt-pc';

export default {
  name: 'Role',
  components: {
    YxtTable: Table,
    YxtTableColumn: TableColumn,
    YxtPagination: Pagination,
    YxtEmpty: Empty
  },
  mixins: [echoMixin, resizeTable, authMixin],
  data() {
    return {
      loadingAll: false,
      tableHeight: 0,
      refTable: 'multipleTable',
      currentPage: 1,
      total: 0,
      loading: true,
      params: {
        keyWords: '',
        limit: 20,
        offset: 0
      },
      tableData: [],
      defaultOptions: {
        isBoss: 0
      }
    };
  },
  mounted() {
    this.getPositionData();
  },
  computed: {
    optionsComputed() {
      if (!this.options) {
        return this.defaultOptions;
      } else {
        return Object.assign(this.defaultOptions, this.options);
      }
    }
  },
  methods: {
    loadAll() {
      this.loadingAll = true;
      const params = Object.assign({}, this.params, {
        limit: this.total,
        offset: 0
      });
      params.isBoss = this.optionsComputed.isBoss;
      params.navCode = this.functionCode;
      params.dataPermissionCode = this.dataPermissionCode;
      getRoleList(params).then(res => {
        if (res) {
          let data = res.datas.map(item => {
            item['_type'] = 3;
            return item;
          });
          this.$refs['multipleTable'].clearSelection();
          this.$refs['multipleTable'].toggleAllSelection();
          this.selectTotal(data);
        }
      }).catch(e => {
        console.error(e);
      }).then(() => {
        this.loadingAll = false;
      });
    },
    getPositionData(reset = false) {
      this.loading = true;
      if (reset) {
        this.params.offset = 0;
        this.currentPage = 1;
      }
      this.params.isBoss = this.optionsComputed.isBoss;
      this.params.navCode = this.functionCode;
      this.params.dataPermissionCode = this.dataPermissionCode;
      getRoleList(this.params).then(res => {
        if (res) {
          this.tableData = res.datas.map(item =>{
            item['_type'] = 3;
            return item;
          });
          this.total = res.paging.count;
          this.update();
        }
      }).catch(e => {
        console.error(e);
      }).then(() => {
        this.loading = false;
      });

    },
    handleCurrentChange(val) {
      this.params.offset = (val - 1) * this.params.limit;
      this.getPositionData();
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.params.limit = val;
      this.getPositionData();
    }
  }
};
</script>
