const baseCommon = (typeof window !== 'undefined' && window.feConfig && window.feConfig.common);
let staticBaseUrl = (baseCommon && window.feConfig.common.staticBaseUrl) || 'https://stc.yxt.com/';

export const loadScript = (version, content, callback) => {
  if (typeof version === 'function') {
    callback = version;
    version = '';
  }
  let script = document.createElement('script');
  script.src = `${staticBaseUrl}ufd/55a3e0/kng/pc/other/${version}${content}`;
  document.getElementsByTagName('head')[0].appendChild(script);
  callback = callback ? callback : () => {};
  // 加载完成后要执行的方法
  if (script.readyState) {
    script.onreadystatechange = () => {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null;
        callback();
      }
    }
  } else {
    script.onload = function () {
      callback();
    }
  }
}

export const loadVideoScript = (version, content, callback) => {
  if (typeof version === 'function') {
    callback = version;
    version = '';
  }
  let script = document.createElement('script');
  script.src = `${staticBaseUrl}ufd/3f5568/kng/pc/other/${version}${content}`;
  document.getElementsByTagName('head')[0].appendChild(script);
  callback = callback ? callback : () => {};
  // 加载完成后要执行的方法
  if (script.readyState) {
    script.onreadystatechange = () => {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null;
        callback();
      }
    }
  } else {
    script.onload = function () {
      callback();
    }
  }
}

export const firstLetterUpper = (str) => {
  let reg = /\b(\w)|\s(\w)/g; //  \b判断边界\s判断空格
  return str.replace(reg, (m) => {
    return m.toUpperCase();
  });
}

// 拼接URL参数
export const linkSubString = (url, data) => {
  if (url === null || url === '') {
    return url;
  }
  let queryString = '';
  if (typeof data === 'object') {
    for (var i in data) {
      queryString += i + '=' + data[i] + '&';
    }
  }
  if (url.indexOf('?') > url.indexOf('/')) {
    url += '&';
  } else {
    url += '?';
  }
  if (queryString !== '') {
    queryString = queryString.substr(0, queryString.length - 1);
  }
  url += queryString;
  return url;
}

export const handleVideoLabel = (v) => {
  let label = '';
  let sortIndex = 1;
  switch (v) {
    case '360p':
      label = 'biz_video_lbl_360p';
      sortIndex = 1;
      break;
    case '480p':
      label = 'biz_video_lbl_480p';
      sortIndex = 2;
      break;
    case '720p':
      label = 'biz_video_lbl_720p';
      sortIndex = 3;
      break;
    case '1080p':
      label = 'biz_video_lbl_1080p';
      sortIndex = 4;
      break;
    case '2k':
      label = '2k';
      sortIndex = 5;
      break;
    case '4k':
      label = '4k';
      sortIndex = 6;
      break;
    default:
      label = false;
      sortIndex = 0;
      break;
  };
  return [label, sortIndex];
}


// 立即执行函数
(function () {
  let getBrowser = () => {
    let sys = {};
    let ua = navigator.userAgent.toLowerCase();
    let s;
    (s = ua.match(/edge\/([\d.]+)/)) ? sys.edge = s[1] :
      (s = ua.match(/rv:([\d.]+)\) like gecko/)) ? sys.ie = s[1] :
      (s = ua.match(/msie ([\d.]+)/)) ? sys.ie = s[1] :
      (s = ua.match(/firefox\/([\d.]+)/)) ? sys.firefox = s[1] :
      (s = ua.match(/chrome\/([\d.]+)/)) ? sys.chrome = s[1] :
      (s = ua.match(/opera.([\d.]+)/)) ? sys.opera = s[1] :
      (s = ua.match(/version\/([\d.]+).*safari/)) ? sys.safari = s[1] : 0;
    if (sys.edge) return { browser: 'Edge', version: sys.edge };
    if (sys.ie) return { browser: 'IE', version: sys.ie };
    if (sys.firefox) return { browser: 'Firefox', version: sys.firefox };
    if (sys.chrome) return { browser: 'Chrome', version: sys.chrome };
    if (sys.opera) return { browser: 'Opera', version: sys.opera };
    if (sys.safari) return { browser: 'Safari', version: sys.safari };

    return { browser: '', version: '0' };
  }

  let isSupportVideoJs = (b, v) => {
    return (b === 'Edge' ||
        (b === 'Chrome' && v >= 50) ||
        (b === 'Firefox' && v >= 44) ||
        (b === 'Safari' && v >= 8) ||
        (b === 'Opera' && v >= 15)) ||
      /MicroMessenger/i.test(navigator.userAgent);
  }

  let isMobile = function () {
    let ua = navigator.userAgent.toLowerCase()
    let isIOS = /iP(hone|ad|od)/i.test(ua)
    let isAndroid = /Android/i.test(ua)
    return isIOS || isAndroid
  }

  let b = getBrowser();
  let v = parseInt(b.version.substr(0, b.version.indexOf('.')));

  let browserHelper = {
    browser: b.browser, // 浏览器名称
    version: b.version, // 浏览器版本
    bigVersion: v, // 浏览器大版本
    isMobile: isMobile(), // 是否移动端浏览器
    isSupportVideoJs: isSupportVideoJs(b.browser, v) // 是否支持html5 video播放
  }

  window.browserHelper = browserHelper
})();
