import { render, staticRenderFns } from "./customUserSelector.vue?vue&type=template&id=5ccf81ca&scoped=true"
import script from "./customUserSelector.vue?vue&type=script&lang=js"
export * from "./customUserSelector.vue?vue&type=script&lang=js"
import style0 from "./customUserSelector.vue?vue&type=style&index=0&id=5ccf81ca&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ccf81ca",
  null
  
)

export default component.exports