<!-- 顶部工作台导航 -->
<template>
  <div :class="['yxtbiz-nav-top-workbench']">
      <i class="yxt-icon-back hand" style="width:20px;height:20px;" @click="backTo"></i>
      <yxt-divider direction="vertical"></yxt-divider>
      <span class="yxtbiz-nav-top__workbench_title">
        {{currentWorkBenchNav?currentWorkBenchNav.name:''}}
      </span>
  </div>
</template>

<script>
export default {
  name: 'YxtbizNavTopWorkbench',
  data() {
    return {
    };
  },
  computed: {
    // 点击进入该工作台导航的路径
    backPath() {
      return window.localStorage.getItem('workbenchEntry');
    },
    currentWorkBenchNav() {
      return JSON.parse(window.localStorage.getItem('nav_datas_5') || '[]').filter(i=>i.parentId === localStorage.getItem('orgId') && i.code === this.$store.state.navManageStore.pageCodes[0])[0];
    }
  },
  watch: {
    '$store.state.navManageStore.pageCodes'(v) {
    }
  },
  methods: {
    backTo() {
      if (this.backPath) {
        window.location.href = this.backPath;
        window.localStorage.removeItem('workbenchEntry');
        return;
      }
      let judgeLen = 1;
      const isSafari = /safari/i.test(navigator.userAgent) && !(/chrome/i.test(navigator.userAgent));
      if (isSafari) judgeLen = 2; // Safari 浏览器下历史记录是从2开始的
      if (history.length > judgeLen) {
        history.back(-1);
        return;
      }
      window.location.href = '/';
    }
  }
};
</script>

<style lang="scss" scoped>
.yxtbiz-nav-top-workbench {
  text-align: left;
  background-color: #ffffff;
  width: 100%;
  height: 56px;
  padding: 16px 24px;
  font-size: 16px;
  color: #262626;
  line-height: 24px;
  font-weight: 500;
  box-sizing: border-box;

  .yxt-divider yxt-divider--vertical {
    margin: 0 16px;
  }
}
</style>
