<script>
import { debounce } from 'throttle-debounce';
import { parseComponent } from './utils/sfcParser/parser';
import { genStyleInjectionCode } from './utils/sfcParser/styleInjection';
import { isEmpty, extend, generateId } from './utils/util';
import { addStylesClient } from './utils/style-loader/addStylesClient';
import vue from 'vue';
export default {
  name: 'CodeViewer',
  props: {
    source: { type: String },
    errorHandler: { type: Function },
    debounceDelay: {
      type: Number,
      default: 300
    }
  },
  data() {
    return {
      code: '',
      className: ['vue-code-viewer', 'vue-app'], // page className
      dynamicComponent: {
        component: {
          template: ''
        }
      },
      hasError: false,
      errorMessage: null
    };
  },
  created() {
    this.viewId = `vcv-${generateId()}`;
    this.debounceErrorHandler = debounce(this.debounceDelay, this.errorHandler);
    this.stylesUpdateHandler = addStylesClient(this.viewId, {});
  },
  mounted() {
    this._initialize();
  },
  methods: {
    // 初始化
    _initialize() {
      // 传入初始值赋值  prop.source=>code
      this.handleCodeChange(this.source);
    },

    genComponent() {
      const { template, script, styles } = this.sfcDescriptor;
      const templateCode = template ? template.content.trim() : '';
      let scriptCode = script ? script.content.trim() : '';
      const { styleArray } = genStyleInjectionCode(styles);

      // 构建组件
      const demoComponent = {};

      // 组件 script
      if (!isEmpty(scriptCode)) {
        const componentScript = {};
        scriptCode = scriptCode.replace(
          /export\s+default/,
          'componentScript ='
        );
        // eslint-disable-next-line no-eval
        eval(scriptCode);
        extend(demoComponent, componentScript);
      }

      // 组件 template
      demoComponent.template = `
            <section class="component-wrapper" >
              ${templateCode}
            </section>
        `;

      // 组件 style
      this.stylesUpdateHandler(styleArray);
      extend(this.dynamicComponent, {
        component: demoComponent
      });
      console.log('this.dynamicComponent', this.dynamicComponent);
    },
    // 更新 code 内容
    handleCodeChange(val) {
      this.code = val;
    },

    renderPreview() {
      const { hasError, errorMessage } = this;
      if (hasError) {
        return <pre class="code-view-error">{errorMessage}</pre>;
      }
      vue.component('renderComponent', vue.extend(this.dynamicComponent.component));
      return (
        <div class="code-view">
          <renderComponent></renderComponent>
        </div>
      );
    },
    // 代码检查
    codeLint() {
      // 校验代码是否为空
      this.hasError = this.isCodeEmpty;
      this.errorMessage = this.isCodeEmpty ? '' : null;
      // 代码为空 跳出检查
      if (this.isCodeEmpty) return;

      // 校验代码是否存在<template>
      const { template } = this.sfcDescriptor;
      const templateCode =
        template && template.content ? template.content.trim() : '';
      const isTemplateEmpty = isEmpty(templateCode);

      this.hasError = isTemplateEmpty;
      this.errorMessage = isTemplateEmpty
        ? '代码格式错误，不存在 <template> ！'
        : null;
    }
  },
  computed: {
    // SFC Descriptor Object
    sfcDescriptor: function() {
      return parseComponent(this.code);
    },
    // 代码是否为空
    isCodeEmpty: function() {
      return !(this.code && !isEmpty(this.code.trim()));
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    code(newSource, oldSource) {
      this.codeLint();
      // 错误事件处理
      this.hasError &&
        this.errorHandler &&
        this.debounceErrorHandler(this.errorMessage);

      if (!this.hasError) this.genComponent();
    }
  },

  render() {
    const { className } = this;
    return (
      <div class={className} ref="codeViewer">
        <div class="code-view-wrapper">{this.renderPreview()}</div>
      </div>
    );
  }
};
</script>

<style lang="scss">
.code-view-wrapper {
  padding: 0;
  .code-view-error {
    max-height: 200px;
    padding: 18px;
    color: red;
  }
}
</style>
