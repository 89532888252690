var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("yxt-select", {
    staticClass: "yxtbiz-user-tree-select",
    attrs: {
      slot: "reference",
      multiple: "",
      "prop-label": "name",
      "hidden-option": "",
      placeholder: _vm.placeholder,
      "value-key": _vm.prop,
      "collapse-tags": true,
      size: "small",
      clearable: true,
      "popper-append-to-body": false,
    },
    on: { clear: _vm.clear, "remove-tag": _vm.removeTag },
    slot: "reference",
    scopedSlots: _vm._u(
      [
        {
          key: "tag",
          fn: function ({ data }) {
            return _vm.isOpenData
              ? [_c("yxtbiz-dept-name", { attrs: { name: data.currentLabel } })]
              : undefined
          },
        },
      ],
      null,
      true
    ),
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v
      },
      expression: "selected",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }