<template>
  <div
    class="yxtbiz-ai-robot"
    :class="{ 'is-fullscreen': bFullscreen, 'is-max': max }"
  >
    <draggable-container
      v-loading="!scriptLoaded"
      yxt-loading-background="rgba(255, 255, 255, 0)"
      class="yxtbiz-ai-robot--container"
      :bFullscreen="bFullscreen"
      @resize="() => $refs.msgDialog.scrollToEnd()"
    >
    <template v-if="scriptLoaded">
      <broadside
        :visible.sync="chatListVisible"
        @selectChat="selectChat"
        :waitAnswer="waitAnswer"
        :bFullscreen="bFullscreen"
        :updateMessage="updateMessage"
        :title="title"
        :small-client="!bFullscreen"
      ></broadside>
      <msg-dialog
        v-show="!chatListVisible"
        ref="msgDialog"
        @getChatSetting="getChatSetting"
        :waitAnswer.sync="waitAnswer"
        :clientType="getClientType"
        :title="title"
        :max="max"
        :bFullscreen="bFullscreen"
        @update-message="handleUpdateMessage"
        @fullscreen="v => bFullscreen = v"
        @feedback="feedbackDialogVisible = true"
        @close="closeAI"
        @showChatList="showChatList"
      ></msg-dialog>
    </template>
    </draggable-container>
    <feedback-dialog
      :visible.sync="feedbackDialogVisible"
      @success="feedbackSuccess"
    ></feedback-dialog>
  </div>
</template>

<script>
import { getChatSetting, getStaticBaseUrl } from './service';
import FeedbackDialog from './components/feedbackDialog.vue';
import MsgDialog from './components/msgDialog.vue';
import DraggableContainer from './components/draggableContainer.vue';
import broadside from './components/broadside.vue';
import { loadScript } from 'yxt-biz-pc/src/utils/util';
import { getGlobalLangTrans } from './service';
import utils from 'yxt-biz-pc/packages/common-util/index.js';

export default {
  name: 'YxtbizAiRobot',
  components: {
    broadside,
    MsgDialog,
    FeedbackDialog,
    DraggableContainer
  },
  props: {
    mode: {
      type: String,
      default: 'manage',
      validator: val => ['manage', 'student'].includes(val)
    },
    isShare: {
      type: Boolean,
      default: false
    },
    max: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default() {
        return this.$t('pc_biz_ai_title').d('企业AI助手');
      }
    }
  },
  computed: {
    getClientType() {
      // 0-学员端，1-管理端
      return this.mode === 'manage' ? 1 : 0;
    }
  },
  provide() {
    return {
      settings: this.settings,
      clientType: this.mode,
      textLang: this.textLang
    };
  },
  data() {
    return {
      scriptLoaded: false,
      feedbackDialogVisible: false,
      textLang: {
        compulsoryText: '',
        electiveText: ''
      },
      bFullscreen: false,
      settings: {
        answerInteract: '', // 未知
        publicResourceEnabled: false, // 是否开通互联网
        factorOpenFlag: false, // 是否开通
        assistEnabled: false, // 是否开启
        accessEnabled: false, // 是否有权限
        assistHeadImg: `${getStaticBaseUrl()}ufd/407a24/assistent/pc/img/avatar.jpg`,
        assistWele: '', // 助手欢迎语
        cheatTips: '',
        hasShortcutInstruction: null, // ("是否包含快捷指令，1-有，0-无")
        fastFeedbackList: [] // 反馈提示词
      },
      updateMessage: {},
      waitAnswer: false, // 是否等待回答
      chatListVisible: false // 多窗口列表显隐
    };
  },
  created() {
    this.bFullscreen = !!this.max;
    this.loadScript();
    // TODO: 配置接口联调
    this.getChatSetting();
    this.getDefinedLang();
  },
  methods: {
    getDefinedLang() {
      const LANGKEY = {
        O2O_COMPULSORY: 'keys.org.ssp.custom.global.compulsory', // 必修文案
        O2O_OPTIONAL: 'keys.org.ssp.custom.global.optional' // 选修文案
      };
      getGlobalLangTrans(LANGKEY.O2O_OPTIONAL).then(data => {
        this.textLang.compulsoryText = data && data[utils.getLanguage()] || this.$t('pc_o2o_lbl_elective');
      }).catch(() => {
      });

      getGlobalLangTrans(LANGKEY.O2O_COMPULSORY).then(data => {
        this.textLang.electiveText = data && data[utils.getLanguage()] || this.$t('pc_o2o_lbl_obligatory');
      }).catch(() => {
      });
    },
    loadScript() {
      this.scriptLoaded = false;
      const url = `${this.$staticBaseUrl}ufd/55a3e0/js/marked.min.js`;
      loadScript(url, true)
        .finally(() => {
          this.scriptLoaded = true;
        });
    },
    handleUpdateMessage(data) {
      this.updateMessage = data;
    },
    clearHistory() {
      this.$refs.msgDialog.clearHistory();
    },
    // 点击窗口回调
    selectChat(item, isNew) {
      // 没有chatSessionId则为新窗口
      const id = item.chatSessionId || '';
      this.chatListVisible = false;
      this.$refs.msgDialog.changeUpdateAI(id, isNew);
    },
    // 展示多窗口组件
    showChatList() {
      this.chatListVisible = true;
    },
    // 获取AI助手配置
    getChatSetting() {
      getChatSetting().then(res => {
        if (res) {
          this.settings.answerInteract = !!res.answerInteract;
          this.settings.assistEnabled = !!res.assistEnabled;
          this.settings.factorOpenFlag = !!res.factorOpenFlag;
          this.settings.accessEnabled = !!res.accessEnabled;
          this.settings.publicResourceEnabled = !!res.publicResourceEnabled;
          this.settings.assistHeadImg = res.assistHeadImg || `${getStaticBaseUrl()}ufd/407a24/assistent/pc/img/avatar.jpg`;
          this.settings.assistWele = res.assistWele;
          this.settings.cheatTips = res.cheatTips;
          this.settings.fastFeedbackList = res.fastFeedbackList || [];
          this.settings.hasShortcutInstruction = res.hasShortcutInstruction;
        }
      });
    },
    closeAI() {
      this.$emit('close');
    },
    feedbackSuccess(content) {
      // 更新子消息反馈状态
      this.$refs.msgDialog.updateFeedback(content);
      this.feedbackDialogVisible = false;
    }
  }
};
</script>
