<template>
  <article class="bg-white width-percent-100 yxtbiz-ai-robot-search-archive">
    <EmptyData v-if="dataList.length === 0" />
    <template v-else>
      <header
        class="ph12"
        :class="[
          'color-gray-7',
          size === 'small' ? 'font-size-14 lh24' : 'font-size-16 lh28'
        ]"
      >
        {{
          $t('yxtbiz_ai_lbl_searchallarchibe' /* 为您找到以下个人档案： */)
        }}
      </header>
      <main class="ph12">
        <yxt-row :gutter="12" class="yxtbiz-ai-robot-search-archive__flex">
          <yxt-col
            v-if="archiveType === 0 || archiveType.includes(1) || archiveType.includes(0)"
            class=" mt12 yxtbiz-ai-robot-search-archive__hauto"
            :span="size === 'small' ? 24 : 12"
          >
            <div
              class="yxtbiz-ai-robot-search-archive__item hand"
              :class="size === 'small' ? '' : 'yxtbiz-ai-robot-search-archive__height100'"
              @click="linkJump(1)"
            >
              <div class="pl20">
                <div
                  class="mt16 color-gray-10 yxtbiz-ai-robot-search-archive__weight500"
                  :class="size === 'small' ? 'standard-size-14' : 'standard-size-16'"
                >
                  {{ $t('yxtbiz_ai_lbl_o2oarchive' /* 项目培训 */) }}
                </div>
                <div
                  class="mt4"
                  :class="size === 'small' ? 'standard-size-12' : 'standard-size-14'"
                >
                  {{ $t('yxtbiz_ai_lbl_o2oallcount' /* 总共学习项目 */) }}
                  <span class="ml12">
                    {{ o2oDetail.studyProjectTotalCount ? o2oDetail.studyProjectTotalCount : 0 }}
                  </span>
                </div>
                <div
                  class="mt16 mb16 color-gray-7"
                  :class="size === 'small' ? 'standard-size-12' : 'standard-size-14'"
                >
                  <div>
                    <span class="yxtbiz-ai-robot-search-archive__point yxtbiz-ai-robot-search-archive__point-corlor43" />
                    <span :class="size === 'small' ? 'ml8' : 'ml12'">{{ $t('pc_biz_study_lbl_complete' /* 已完成 */) }}</span>
                    <span class="ml12">{{ o2oDetail.completedCount ? o2oDetail.completedCount : 0 }}</span>
                  </div>
                  <div class="mt8">
                    <span class="yxtbiz-ai-robot-search-archive__point yxtbiz-ai-robot-search-archive__point-corlor52" />
                    <span :class="size === 'small' ? 'ml8' : 'ml12'">{{ $t('pc_biz_ai_lbl_studying' /* 学习中 */) }}</span>
                    <span class="ml12">{{ o2oDetail.studyingCount || 0 }}</span>
                  </div>
                  <div class="mt8">
                    <span class="yxtbiz-ai-robot-search-archive__point yxtbiz-ai-robot-search-archive__point-corlorbf" />
                    <span :class="size === 'small' ? 'ml8' : 'ml12'">{{ $t('pc_biz_ai_lbl_uncompleted' /* 未完成 */) }}</span>
                    <span class="ml12">{{ o2oDetail.incompleteCount || 0 }}</span>
                  </div>
                </div>
              </div>
              <div class="yxtbiz-ai-robot-search-archive__rate">
                <yxtf-progress
                  :percentage="o2oDetail.completeRate || 0"
                  :width='108'
                  color="#436bff"
                  outColor="#f0f0f0"
                  :stroke-width='6'
                  type='circle'>
                  <div slot="content" class="archive-detail-progress">
                    <div class="standard-size-20 color-gray-10">
                      <span v-if="o2oDetail.studyProjectTotalCount">{{ o2oDetail.completeRate && o2oDetail.completeRate.toFixed(0) }}%</span>
                      <span v-else>--</span>
                    </div>
                    <div class="standard-size-14 color-gray-7 mr2 ml2">{{ $t('yxtbiz_ai_lbl_completerate' /* 完成率 */) }}</div>
                  </div>
                </yxtf-progress>
              </div>
            </div>
          </yxt-col>
          <yxt-col
            v-if="archiveType === 0 || archiveType.includes(2) || archiveType.includes(0)"
            class=" mt12 yxtbiz-ai-robot-search-archive__hauto"
            :span="size === 'small' ? 24 : 12">
            <div
              class="yxtbiz-ai-robot-search-archive__item hand"
              :class="size === 'small' ? '' : 'yxtbiz-ai-robot-search-archive__height100'"
              @click="linkJump(2)"
            >
              <div class="pl20">
                <div
                  class="mt16 color-gray-10 yxtbiz-ai-robot-search-archive__weight500"
                  :class="size === 'small' ? 'standard-size-14' : 'standard-size-16'"
                >
                  {{ $t('yxtbiz_ai_lbl_kngarchive' /* 自主学习 */) }}
                </div>
                <div
                  class="mt4"
                  :class="size === 'small' ? 'standard-size-12' : 'standard-size-14'"
                >
                  {{ $t('yxtbiz_ai_lbl_courseallcount' /* 总共学习课程 */) }}
                  <span class="ml12">{{ kngDetail.totalCount || 0 }}</span>
                </div>
                <div
                  class="mt16 mb16 color-gray-7"
                  :class="size === 'small' ? 'standard-size-12' : 'standard-size-14'"
                >
                  <div>
                    <span class="yxtbiz-ai-robot-search-archive__point yxtbiz-ai-robot-search-archive__point-corlor43" />
                    <span :class="size === 'small' ? 'ml8' : 'ml12'">{{ $t('pc_biz_study_lbl_complete' /* 已完成 */) }}</span>
                    <span class="ml12">{{ kngDetail.completeCount || 0 }}</span>
                  </div>
                  <div class="mt8">
                    <span class="yxtbiz-ai-robot-search-archive__point yxtbiz-ai-robot-search-archive__point-corlor52" />
                    <span :class="size === 'small' ? 'ml8' : 'ml12'">{{ $t('pc_biz_ai_lbl_studying' /* 学习中 */) }}</span>
                    <span class="ml12">{{ kngDetail.learningCount || 0 }}</span>
                  </div>
                </div>
              </div>
              <div class="yxtbiz-ai-robot-search-archive__rate">
                <yxtf-progress
                  :percentage="kngDetail.rate || 0"
                  :width='108'
                  color="#436bff"
                  outColor="#f0f0f0"
                  :stroke-width='6'
                  type='circle'>
                  <div slot="content" class="archive-detail-progress">
                    <div class="standard-size-20 color-gray-10">
                      <span v-if="kngDetail.totalCount">{{ kngDetail.rate && kngDetail.rate.toFixed(0) }}%</span>
                      <span v-else>--</span>
                    </div>
                    <div class="standard-size-14 color-gray-7 mr2 ml2">{{ $t('yxtbiz_ai_lbl_completerate' /* 完成率 */) }}</div>
                  </div>
                </yxtf-progress>
              </div>
            </div>
          </yxt-col>
          <yxt-col
            v-if="archiveType === 0 || archiveType.includes(3) || archiveType.includes(0)"
            class=" mt12 yxtbiz-ai-robot-search-archive__hauto"
            :span="size === 'small' ? 24 : 12">
            <div
              class="yxtbiz-ai-robot-search-archive__item hand"
              :class="size === 'small' ? '' : 'yxtbiz-ai-robot-search-archive__height100'"
              @click="linkJump(3)"
            >
              <div class="pl20">
                <div
                  class="mt16 color-gray-10 yxtbiz-ai-robot-search-archive__weight500"
                  :class="size === 'small' ? 'standard-size-14' : 'standard-size-16'"
                >
                  {{ $t('yxtbiz_ai_lbl_livearchive' /* 学习直播 */) }}
                </div>
                <div
                  class="mt4"
                  :class="size === 'small' ? 'standard-size-12' : 'standard-size-14'"
                >
                  {{ $t('yxtbiz_ai_lbl_liveallcount' /* 总共观看直播 */) }}
                  <span class="ml12">{{ liveDetail.total || 0 }}</span>
                </div>
                <div
                  class="mt16 mb16 color-gray-7"
                  :class="size === 'small' ? 'standard-size-12' : 'standard-size-14'"
                >
                  <div>
                    <span class="yxtbiz-ai-robot-search-archive__point yxtbiz-ai-robot-search-archive__point-corlor43" />
                    <span :class="size === 'small' ? 'ml8' : 'ml12'">{{ $t('pc_biz_ai_lbl_viewed' /* 已观看/被指派 */) }}</span>
                    <span class="ml12">{{ liveDetail.joinedAppointSize || 0 }}/{{ liveDetail.totalAppointSize || 0 }}</span>
                  </div>
                  <div class="mt8">
                    <span class="yxtbiz-ai-robot-search-archive__point yxtbiz-ai-robot-search-archive__point-corlor52" />
                    <span :class="size === 'small' ? 'ml8' : 'ml12'">{{ $t('pc_biz_ai_lbl_activeviewed' /* 主动观看 */) }}</span>
                    <span class="ml12">{{ liveDetail.joinedSize || 0 }}</span>
                  </div>
                </div>
              </div>
              <div class="yxtbiz-ai-robot-search-archive__rate">
                <yxtf-progress
                  :percentage="liveDetail.rate || 0"
                  :width='108'
                  color="#436bff"
                  outColor="#f0f0f0"
                  :stroke-width='6'
                  type='circle'>
                  <div slot="content" class="archive-detail-progress">
                    <div class="standard-size-20 color-gray-10">
                      <span v-if="liveDetail.total">{{ liveDetail.rate && liveDetail.rate.toFixed(0) }}%</span>
                      <span v-else>--</span>
                    </div>
                    <div class="standard-size-14 color-gray-7 mr2 ml2">{{ $t('yxtbiz_ai_lbl_attendance' /* 出勤率 */) }}</div>
                  </div>
                </yxtf-progress>
              </div>
            </div>
          </yxt-col>
          <yxt-col
            v-if="archiveType === 0 || archiveType.includes(4) || archiveType.includes(0)"
            class=" mt12 yxtbiz-ai-robot-search-archive__hauto"
            :span="size === 'small' ? 24 : 12">
            <div
              class="yxtbiz-ai-robot-search-archive__item hand"
              :class="size === 'small' ? '' : 'yxtbiz-ai-robot-search-archive__height100'"
              @click="linkJump(4)"
            >
              <div class="pl20">
                <div
                  class="mt16 color-gray-10 yxtbiz-ai-robot-search-archive__weight500"
                  :class="size === 'small' ? 'standard-size-14' : 'standard-size-16'"
                >
                  {{ $t('yxtbiz_ai_lbl_examarchive' /* 学员考试 */) }}
                </div>
                <div
                  class="mt4"
                  :class="size === 'small' ? 'standard-size-12' : 'standard-size-14'"
                >
                  {{ $t('pc_biz_ai_lbl_exam' /* 考试 */) }}
                  <span class="ml12">{{ examDetail.total || 0 }}</span>
                </div>
                <div
                  class="mt16 mb16 color-gray-7"
                  :class="size === 'small' ? 'standard-size-12' : 'standard-size-14'"
                >
                  <div>
                    <span class="yxtbiz-ai-robot-search-archive__point yxtbiz-ai-robot-search-archive__point-corlor43" />
                    <span :class="size === 'small' ? 'ml8' : 'ml12'">{{ $t('pc_biz_study_lbl_complete' /* 已完成 */) }}</span>
                    <span class="ml12">{{ examDetail.completeArrangeCount || 0 }}</span>
                  </div>
                  <div class="mt8">
                    <span class="yxtbiz-ai-robot-search-archive__point yxtbiz-ai-robot-search-archive__point-corlor52" />
                    <span :class="size === 'small' ? 'ml8' : 'ml12'">{{ $t('pc_biz_ai_lbl_marking' /* 批阅中 */) }}</span>
                    <span class="ml12">{{ examDetail.reviewArrangeCount || 0 }}</span>
                  </div>
                  <div class="mt8">
                    <span class="yxtbiz-ai-robot-search-archive__point yxtbiz-ai-robot-search-archive__point-corlorbf" />
                    <span :class="size === 'small' ? 'ml8' : 'ml12'">{{ $t('pc_biz_ai_lbl_pendingexam' /* 待考试 */) }}</span>
                    <span class="ml12">{{ examDetail.waitArrangeCount || 0 }}</span>
                  </div>
                  <div class="mt8">
                    <span class="yxtbiz-ai-robot-search-archive__point yxtbiz-ai-robot-search-archive__point-corlorbf" />
                    <span :class="size === 'small' ? 'ml8' : 'ml12'">{{ $t('pc_biz_ai_lbl_uncompleted' /* 未完成 */) }}</span>
                    <span class="ml12">{{ examDetail.uncompleteArrangeCount || 0 }}</span>
                  </div>
                </div>
              </div>
              <div class="yxtbiz-ai-robot-search-archive__rate">
                <yxtf-progress
                  :percentage="examDetail.rate || 0"
                  :width='108'
                  color="#436bff"
                  outColor="#f0f0f0"
                  :stroke-width='6'
                  type='circle'>
                  <div slot="content" class="archive-detail-progress">
                    <div class="standard-size-20 color-gray-10">
                      <span v-if="examDetail.total">{{ examDetail.rate && examDetail.rate.toFixed(0) }}%</span>
                      <span v-else>--</span>
                    </div>
                    <div class="standard-size-14 color-gray-7 mr2 ml2">{{ $t('yxtbiz_ai_lbl_completerate' /* 完成率 */) }}</div>
                  </div>
                </yxtf-progress>
              </div>
            </div>
          </yxt-col>
          <yxt-col
            v-if="archiveType === 0 || archiveType.includes(5) || archiveType.includes(0)"
            class=" mt12 yxtbiz-ai-robot-search-archive__hauto"
            :span="size === 'small' ? 24 : 12">
            <div
              class="yxtbiz-ai-robot-search-archive__item hand"
              :class="size === 'small' ? '' : 'yxtbiz-ai-robot-search-archive__height100'"
              @click="linkJump(5)"
            >
              <div class="pl20">
                <div
                  class="mt16 color-gray-10 yxtbiz-ai-robot-search-archive__weight500"
                  :class="size === 'small' ? 'standard-size-14' : 'standard-size-16'"
                >
                  {{ $t('yxtbiz_ai_lbl_pararchive' /* 学员练习 */) }}
                </div>
                <div
                  class="mt4"
                  :class="size === 'small' ? 'standard-size-12' : 'standard-size-14'"
                >
                  {{ $t('pc_biz_ai_lbl_practice' /* 练习 */) }}
                  <span class="ml12">{{ practiceDetail.total || 0 }}</span>
                </div>
                <div
                  class="mt16 mb16 color-gray-7"
                  :class="size === 'small' ? 'standard-size-12' : 'standard-size-14'"
                >
                  <div>
                    <span class="yxtbiz-ai-robot-search-archive__point yxtbiz-ai-robot-search-archive__point-corlor52" />
                    <span :class="size === 'small' ? 'ml8' : 'ml12'">{{ $t('pc_biz_ai_lbl_processing' /* 进行中 */) }}</span>
                    <span class="ml12">{{ practiceDetail.progressPraCount || 0 }}</span>
                  </div>
                  <div class="mt8">
                    <span class="yxtbiz-ai-robot-search-archive__point yxtbiz-ai-robot-search-archive__point-corlorbf" />
                    <span :class="size === 'small' ? 'ml8' : 'ml12'">{{ $t('pc_biz_ai_ended' /* 已结束 */) }}</span>
                    <span class="ml12">{{ practiceDetail.endedPraCount || 0 }}</span>
                  </div>
                </div>
              </div>
              <div class="yxtbiz-ai-robot-search-archive__rate">
                <yxtf-progress
                  :percentage="practiceDetail.rate || 0"
                  :width='108'
                  color="#436bff"
                  outColor="#f0f0f0"
                  :stroke-width='6'
                  type='circle'>
                  <div slot="content" class="archive-detail-progress">
                    <div class="standard-size-20 color-gray-10">
                      <span v-if="practiceDetail.total">{{ practiceDetail.rate && practiceDetail.rate.toFixed(0) }}%</span>
                      <span v-else>--</span>
                    </div>
                    <div class="standard-size-14 color-gray-7 mr2 ml2">{{ $t('yxtbiz_ai_lbl_participationrate' /* 参与率 */) }}</div>
                  </div>
                </yxtf-progress>
              </div>
            </div>
          </yxt-col>
          <yxt-col
            v-if="archiveType === 0 || archiveType.includes(6) || archiveType.includes(0)"
            class=" mt12 yxtbiz-ai-robot-search-archive__hauto"
            :span="size === 'small' ? 24 : 12">
            <div
              class="yxtbiz-ai-robot-search-archive__item hand"
              :class="size === 'small' ? '' : 'yxtbiz-ai-robot-search-archive__height100'"
              @click="linkJump(6)"
            >
              <div class="pl20">
                <div
                  class="mt16 color-gray-10 yxtbiz-ai-robot-search-archive__weight500"
                  :class="size === 'small' ? 'standard-size-14' : 'standard-size-16'"
                >
                  {{ $t('yxtbiz_ai_lbl_surveyarchive' /* 学员调查 */) }}
                </div>
                <div
                  class="mt4"
                  :class="size === 'small' ? 'standard-size-12' : 'standard-size-14'"
                >
                  {{ $t('pc_biz_ai_lbl_survey' /* 调查 */) }}
                  <span class="ml12">{{ surveyDetail.total || 0 }}</span>
                </div>
                <div
                  class="mt16 mb16 color-gray-7"
                  :class="size === 'small' ? 'standard-size-12' : 'standard-size-14'"
                >
                  <div>
                    <span class="yxtbiz-ai-robot-search-archive__point yxtbiz-ai-robot-search-archive__point-corlor43" />
                    <span :class="size === 'small' ? 'ml8' : 'ml12'">{{ $t('pc_biz_study_lbl_complete' /* 已完成 */) }}</span>
                    <span class="ml12">{{ surveyDetail.finishedNum || 0 }}</span>
                  </div>
                  <div class="mt8">
                    <span class="yxtbiz-ai-robot-search-archive__point yxtbiz-ai-robot-search-archive__point-corlorbf" />
                    <span :class="size === 'small' ? 'ml8' : 'ml12'">{{ $t('pc_biz_ai_lbl_uncompleted' /* 未完成 */) }}</span>
                    <span class="ml12">{{ surveyDetail.unfinishedNum || 0 }}</span>
                  </div>
                </div>
              </div>
              <div class="yxtbiz-ai-robot-search-archive__rate">
                <!-- <yxtf-progress :percentage="50" type="circle" color="#436bff"></yxtf-progress> -->
                <yxtf-progress
                  :percentage="surveyDetail.rate || 0"
                  :width='108'
                  color="#436bff"
                  outColor="#f0f0f0"
                  :stroke-width='6'
                  type='circle'>
                  <div slot="content" class="archive-detail-progress">
                    <div class="standard-size-20 color-gray-10">
                      <span v-if="surveyDetail.total">{{ surveyDetail.rate && surveyDetail.rate.toFixed(0) }}%</span>
                      <span v-else>--</span>
                    </div>
                    <div class="standard-size-14 color-gray-7 mr2 ml2">{{ $t('yxtbiz_ai_lbl_completerate' /* 完成率 */) }}</div>
                  </div>
                </yxtf-progress>
              </div>
            </div>
          </yxt-col>
        </yxt-row>
      </main>
    </template>
  </article>
</template>

<script>
import propsMixin from '../common/props-mixin.js';
export default {
  name: 'SearchArchive',
  mixins: [propsMixin],
  data() {
    return {
    };
  },
  computed: {
    archiveType() {
      if (typeof this.params.fileType === 'number' && !isNaN(this.params.fileType) && this.params.fileType !== 0) {
        return String(this.params.fileType);
      } else {
        return this.params.fileType || 0;
      }
      // return this.params.fileType || 0;
    },
    count() {
      const paging = this.datas.paging || {};
      return paging.count;
    },
    dataList() {
      return this.datas || {};
    },
    o2oDetail() {
      return this.datas.o2oDetail || {};
    },
    kngDetail() {
      return this.datas.kngDetail || {};
    },
    liveDetail() {
      return this.datas.liveDetail || {};
    },
    examDetail() {
      return this.datas.examDetail || {};
    },
    practiceDetail() {
      return this.datas.practiceDetail || {};
    },
    surveyDetail() {
      return this.datas.surveyDetail || {};
    }
  },
  // mounted () {
  //   // const ttt = this.params
  //   // debugger
  // },
  methods: {
    linkJump(type) {
      const userId = window.localStorage.getItem('userId');
      let url = '';
      switch (type) {
        case 1: // o2o
          url = `${window.location.origin}/archive/#/project?id=${userId}`;
          break;
        case 2: // 学习
          url = `${window.location.origin}/archive/#/courseamview?id=${userId}`;
          break;
        case 3: // 直播
          url = `${window.location.origin}/archive/#/live?id=${userId}`;
          break;
        case 4: // 考试
          url = `${window.location.origin}/archive/#/exam?id=${userId}`;
          break;
        case 5: // 练习
          url = `${window.location.origin}/archive/#/practice?id=${userId}`;
          break;
        case 6: // 调研
          url = `${window.location.origin}/archive/#/survey?id=${userId}`;
          break;
      }
      window.open(url, '_blank');
    },
    filterTypeRateName(type) {
      const aMap = {
        0: this.$t('yxtbiz_ai_lbl_completerate' /* 完成率 */), // 项目培训
        1: this.$t('yxtbiz_ai_lbl_completerate' /* 完成率 */), // 自主学习
        2: this.$t('yxtbiz_ai_lbl_attendance' /* 出勤率 */), // 学习直播
        3: this.$t('yxtbiz_ai_lbl_completerate' /* 完成率 */), // 学员考试
        4: this.$t('yxtbiz_ai_lbl_participationrate' /* 参与率 */), // 学员练习
        5: this.$t('yxtbiz_ai_lbl_completerate' /* 完成率 */) // 学员调查
      };
      return aMap[type];
    }
  }
};
</script>

<style lang="scss" scoped>
.yxtbiz-ai-robot-search-archive { 
  // border-radius: 0px 12px 12px 12px;
  &__flex {
    display: flex;
    flex-wrap: wrap;
  }
  &__hauto {
    height: auto;
  }
  &__item {
    display: flex;
    justify-content: space-between;
    background: #fafafa;
    border-radius: 8px;
    // min-height: 180px;
  }
  &__minheight {
    min-height: 180px;
  }
  &__height100 {
    height: 100%;
  }
  &__bgfa{
    background: #fafafa;
  }
  &__weight500 {
    font-weight: 500;
  }
  &__rate {
    display: flex;
    align-items: center;
    padding-right: 44px;
  }
  &__point{
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
  &__point-corlor43 {
    background: #436BFF;
  }
  &__point-corlor52 {
    background: #52c41a;
  }
  &__point-corlorbf {
    background: #bfbfbf;
  }
}
</style>
