<!-- 创建时间2024/01/04 10:24:16 -->
<!-- 创建人：Zhao bing bing -->
<!-- 组件描述：笔记-->
<template>
  <KngRelated type="searchNote" v-bind="$attrs" v-on="$listeners"/>
</template>

<script>
import KngRelated from '../common/kng/kng-releated.vue';
export default {
  name: 'KngNote',
  components: {
    KngRelated
  }
};
</script>