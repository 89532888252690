<template>
  <div class="yxtbiz-ai-robot__search-teas">
    <EmptyData v-if="dataList.length === 0" />
    <template v-else>
      <div :class="size === 'small'? 'font-size-14 lh22': 'font-size-16 lh24'" class="pb12 ph20 color-gray-7">
        {{ $t('pc_biz_ote_lbl_search_content', [$t('pc_audit_tit_audit').d('审核')]) }}
      </div>
      <div v-for="list in dataList.slice(0, 3)" :key="list.execId" class="ph8" @click="toDetail(list)">
        <div class="yxtbiz-ai-robot__teas-list">
          <div :class="size === 'small'? 'font-size-14 lh22': 'font-size-16 lh24'" class="ellipsis">
            <yxtf-tag class="v-mid" :type="auditTagStatus[list.myStatus][1]" size="mini">
              {{ $t(auditTagStatus[list.myStatus][0]) }}
            </yxtf-tag>
            <span class="v-mid color-gray-10">{{ list.tmplName }}</span>
          </div>
          <div v-if="size !== 'small'" class="font-size-12 lh20 color-gray-7 mt8">
            <span style="margin-right: 20px;">{{ $t('pc_biz_survey_lbl_type' /* 名称 */) }}：{{ list.tmplName }}</span><span>{{ list.initUser }}</span>
            <yxt-divider direction="vertical"></yxt-divider>
            <span>{{ list.initTime + ' ' + $t('pc_setup') }}</span>
          </div>
          <div v-else class="font-size-12 lh20 color-gray-7 mt4">
            <span style="margin-right: 20px;">{{ $t('pc_biz_survey_lbl_type' /* 名称 */) }}：{{ list.tmplName }}</span>
            <div class="mt4 search-audit-small">
              <span class="ellipsis">{{ list.initUser }}</span>
              <yxt-divider direction="vertical"></yxt-divider>
              <span class="search-audit-small-nowrap">{{ list.initTime + ' ' + $t('pc_setup') }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="datas.paging.count > 3" class="text-center color-gray-8 hand yxtbiz-ai-robot__teas-more"
        @click="toMore">
        <span class="lh22 v-mid">{{ $t('pc_biz_ote_lbl_viewmore' /* 查看更多 */) }}</span>
        <yxt-svg width="16px" height="16px" icon-class="arrow_right" class="v-mid" />
      </div>
    </template>
  </div>
</template>
  
<script>
import EmptyData from '../common/empty-data.vue';
const auditTagStatus = {
  0: ['pc_biz_te_status_checking', 'warning'], // 审核中
  1: ['pc_biz_lbl_passed', 'info'], // 已通过
  2: ['pc_biz_te_status_rejected', 'danger'], // 已拒绝
  3: ['pc_audit_global_lbl_undone', 'info'], // 已撤销
  4: ['pc_auto_pass', 'info'], // 自动通过
  5: ['pc_audit_global_lbl_no_start', 'info'], // 未启动
  6: ['pc_audit_global_lbl_not_use', 'danger'] // 账号不可用
};
export default {
  name: 'PendingAuditList',
  components: {
    EmptyData
  },
  data() {
    return {
      auditTagStatus
    };
  },
  props: {
    datas: {
      type: Object,
      default: ()=>{
        return {datas: []};
      }
    },
    params: {
      type: Object,
      default: ()=>{}
    },
    size: {
      type: String,
      default: 'small',
      validator: val => ['small', 'large'].includes(val)
    }
  },
  computed: {
    dataList() {
      return this.datas.datas;
    }
  },
  methods: {
    toMore() {
      window.open('/audit-center/#/my-audit');
    },
    toDetail(item) {
      window.open(`/audit-center/#/audit-center-detail?execId=${item.execId}&formType=${item.formType}`);
    }
  }
};
</script>
  
<style lang="scss">
.search-audit-small{
  display: flex;
  .search-audit-small-nowrap{
    white-space: nowrap;
  }
}
.yxtbiz-ai-robot__search-teas {
  border-radius: 0 12px 12px 12px;
  .lh28 {
    line-height: 28px;
  }
  .yxtbiz-ai-robot__teas-list {
    padding: 12px;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      background-color: #f4f5f6;
    }
    .lh20 {
      line-height: 20px;
    }
  }
  .yxtbiz-ai-robot__teas-more {
    margin: 0 8px;
    padding: 8px 0;
    border-radius: 8px;
    font-size: 14px;
    &:hover {
      background-color: #f4f5f6;
    }
  }
}
</style>
  