const nonWhitespaceRE = /\S+/;

export function genStyleInjectionCode(styles) {
  let styleArray = [];
  let styleCodes = [];

  // 不支持 css link src为空，且 <style>标签内容不为空
  const isNotEmptyStyle = (style) =>
    !style.src && nonWhitespaceRE.test(style.content);

  // eslint-disable-next-line no-unused-vars
  styles.forEach((style, i) => {
    if (isNotEmptyStyle(style)) {
      styleCodes.push(style.content.trim());
      style.css = style.content.trim();
      styleArray.push(style);
    } else {
      console.log(`the ${style.lang} not support !`);
    }
  });

  return { styleArray, styleCode: styleCodes.join('\n') };
}
