<template>
  <base-list
    :dataList="datas"
    :size="size"
    :tagLists="tagLists"
    searchName="pc_biz_ote_lbl_exam"
    tagType="status"
    listId="paperId"
    @to-list="toList"
    @to-more="toListMore">
    <!-- slot插槽处理 -->
    <template slot-scope="{ list }">
      <!-- 出卷方式：{0} -->
      <span>{{ $t('pc_biz_ote_lbl_test_method', [list.buildType === 0 ? $t('pc_biz_ote_lbl_exampapertype0' /* 固定出卷 */) : $t('pc_biz_ote_lbl_exampapertype1' /* 随机出卷 */)]) }}</span>
      <!-- 引用次数：{0} -->
      <span class="ml20">{{ $t('pc_biz_ote_lbl_quotetimes', [list.usedQty]) }}</span>
      <!-- 创建人 -->
      <span class="ml20">{{ list.createFullname || '--' }}</span>
      <yxt-divider direction="vertical"></yxt-divider>
      <!-- {0} 更新 -->
      <span>{{ $t('pc_kng_common_lbl_update_time', [list.updateTime]) }}</span>
    </template>
  </base-list>
</template>

<script>
import CommonMixin from '../common/props-mixin';
import utils from 'yxt-biz-pc/packages/common-util/utils.js';
import BaseList from '../search-exam/base-list.vue';

export default {
  name: 'SearchPaper',
  mixins: [CommonMixin],
  components: {
    BaseList
  },

  data() {
    const tagLists = {
      0: {
        label: 'pc_biz_ote_lbl_saved', // 已保存
        type: 'info'
      },
      1: {
        label: 'pc_biz_o2o_lbl_submitted', // 已提交
        type: ''
      },
      2: {
        label: 'pc_biz_ote_lbl_audited', // 已审核
        type: ''
      },
      3: {
        label: 'pc_biz_ote_lbl_status1', // 发布中
        type: ''
      },
      4: {
        label: 'pc_biz_kng_lbl_published', // 已发布
        type: 'success'
      },
      5: {
        label: 'pc_biz_te_status_notpassed', // 未通过
        type: 'danger'
      }
    };

    return {
      tagLists
    };
  },

  methods: {
    getDateTimeFormatted: utils.getDateTimeFormatted,
    toList(list) {
      window.open(`${window.location.origin}/ote/#/examEditQues?id=${list.id}`, '_blank');
    },
    toListMore() {
      localStorage['ai-search-paper'] = JSON.stringify(this.params);
      console.log(this.params);
      window.open(`${window.location.origin}/ote/#/exam/mgmt`, '_blank');
    }
  }
};
</script>
