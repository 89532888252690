<template>
  <import-base
    v-if="finished"
    class="yxtbiz-import-proc"
    :template-url="templateUrl"
    @ready="ready"
    @reset="resetImport"
    @procEmits="procEmits"
    @onProcess="onProcess"
    @onTemplateUrl="onTemplateUrl"
  >
    <div slot="upload">
      <div class="yxt-upload-container pr">
        <yxtbiz-upload
          ref="bizUpload"
          :source="uploaderConfig.source"
          :config-key="uploaderConfig.configKey"
          :module-name="uploaderConfig.moduleName"
          :function-name="uploaderConfig.functionName"
          :app-code="uploaderConfig.appCode"
          :is-v1="uploaderConfig.isV1"
          :before-upload="beforeUpload"
          :on-progress="onProgress"
          :on-uploaded="onUploaded"
          :on-error="uploaderConfig.onError"
          :drag="true"
          :filters="uploaderConfig.filters"
          :files-filter="uploaderConfig.filesFilter"
        >
          <div slot="tip">
            {{$t('pc_biz_import_tip_supportedextend'/* 支持扩展名： */)}}{{uploaderConfig.filters.replace(/,/g,' ')}}
          </div>
        </yxtbiz-upload>
        <div class="yxtbiz-import-proc__progress" v-if="file">
          <yxt-progress :percentage="file.percentage" />
          <i class="yxt-icon-close hand standard-size-12 color-gray-8 hover-primary-6" @click="reset" />
        </div>
      </div>
    </div>
    <template
      v-if="$slots.uploaded"
      slot="uploaded"
    >
      <slot name="uploaded" />
    </template>

    <template
      v-if="$slots.success"
      slot="success"
    >
      <slot name="success" />
    </template>
    <template
      v-if="$slots.error"
      slot="error"
    >
      <slot name="error" />
    </template>
  </import-base>
</template>
<script>
import ImportBase from './importBase';
import yxtbizUpload from 'yxt-biz-pc/packages/upload';
export default {
  name: 'YxtbizImportProc',
  components: {
    ImportBase,
    yxtbizUpload
  },
  props: {
    templateUrl: {
      type: String,
      default: ''
    },
    appCode: {
      type: String,
      default: ''
    },
    // 用法同上传组件source、configKey、moduleName、functionName、appCode、isV1
    // 其中 appCode必传，其他都有默认值
    uploadConfig: {
      type: Object,
      default: ()=>{
        return {};
      }
    }
  },
  data() {
    return {
      importer: null,
      finished: false,
      // 流程进行中
      processing: false,
      file: null
    };
  },
  computed: {
    showUploadedSlot() {
      return Boolean(this.$slots.uploader);
    },
    uploaderConfig() {
      return {
        appCode: this.appCode || this.uploadConfig.appCode, // 必传，
        source: this.uploadConfig.source,
        configKey: this.uploadConfig.configKey || 'AttachConfigKey',
        moduleName: this.uploadConfig.moduleName || 'importproc',
        functionName: this.uploadConfig.functionName || 'file',
        filters: this.uploadConfig.filters || '.xlsx,.xls',
        filesFilter: this.uploadConfig.filesFilter || this.filesFilter, // 设置filters时此项必须设置
        onError: this.uploadConfig.onError || this.onError, // 自定义上传错误后的处理
        isV1: !!this.uploadConfig.isV1
      };
    }
  },
  created() {
    if (!this.appCode && (!this.uploadConfig || !this.uploadConfig.appCode)) {
      console.error(new Error('appCode is require. please set props: "appCode" or "uploadConfig.appCode"'));
    // } else if (this.uploadConfig && this.uploadConfig.filters && typeof this.uploadConfig.filesFilter !== 'function') {
    //   console.error(new Error('When "uploadConfig.filters" exists, "uploadConfig.filesFilter" must exist '));
    } else {
      this.finished = true;
    }

  },
  methods: {
    ready(data) {
      this.importer = data;
      this.$emit('ready', data);
    },
    procEmits(type, next, data) {
      this.$emit(type, next, data);
    },
    onError(e) {
      this.errorMessage(e);
    },
    onUploaded(file) { // 上传文件成功事件
      let fileName = this.sliceFileName(file.name, 13);
      this.importer.uploaded({...file, name: fileName, origName: file.name});
    },
    sliceFileName(fileName, size) {
      let name = fileName.substr(0, fileName.lastIndexOf('.'));
      let suffix = fileName.substr(fileName.lastIndexOf('.'));
      if (name.length > size) {
        name = name.slice(0, size) + '...';
      }
      return name + suffix;
    },
    onProgress(file, progress, event) {
      // 上传进度事件，回调参数（file：上传的文件，progress：已上传比例，event：进度事件参数）
      const f = this.getFile(file.uuid);
      if (f) {
        f.percentage = Math.floor(progress * 100);
      }
    },
    getFile(uuid) {
      if (this.file.uuid === uuid) {
        return this.file;
      }
    },
    beforeUpload(file) {
      if (this.fileFilter(file.name)) {
        this.file = {
          uuid: file.uuid,
          file: file,
          percentage: 0
        };
      } else {
        this.errorMessage('forbidden');
        return false;
      }
      this.importer.uploading();
    },
    fileFilter(fileName) {
      return this.uploaderConfig.filters.split(',').some(d=> fileName.endsWith(d));
    },
    filesFilter(files) {
      if (files[0] && this.uploaderConfig && this.uploaderConfig.filters && !this.fileFilter(files[0].name)) {
        this.errorMessage('forbidden');
        return [];
      }
      return files;
    },
    errorMessage(errorType) {
      let msg = '';
      if (errorType === 'forbidden') {
        msg = this.$t('pc_biz_import_msg_extenderror'/* 格式错误，请上传{0}格式文件 */, { 0: this.uploaderConfig.filters.replace(/\./g, '') });
      } else if (errorType === 'oversize') {
        msg = this.$t('pc_biz_import_msg_contentsize'/* 您选择的内容大小已超过允许的大小，请确认 */);
      } else if (errorType === 'overlength') {
        msg = this.$t('pc_biz_import_msg_filenamesize'/* 文件名不得超过{0}个字符，请重新上传 */, { 0: 200});
      } else {
        msg = this.$t('pc_biz_import_msg_uploaderror'/* 文件上传失败，请稍后再试 */);
      }
      this.$message.warning(msg);
    },
    // 重新上传
    reset() {
      this.file && this.file.file && this.file.file.abort && this.file.file.abort();
      this.importer.reset();
    },
    resetImport() {
      this.file = null;
      this.$emit('reset');
    },
    // 流程状态变化回调
    onProcess(processing) {
      this.processing = processing;
      this.$emit('onProcess', processing);
    },
    onTemplateUrl() {
      this.$emit('onTemplateUrl');
    }
  },
  beforeDestroy() {
    this.reset();
    this.onProcess(false);
  }
};
</script>
