var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "yxtbiz-area-code-select",
      class: {
        "yxtbiz-area-code-select-48": _vm.height48,
        "yxtbiz-area-code-select-isforbidden": _vm.disable,
      },
    },
    [
      _c(
        "yxt-form",
        [
          _c(
            "yxt-input",
            {
              staticClass: "input-with-drop",
              attrs: {
                size: _vm.size,
                placeholder: _vm.isXuanxing
                  ? _vm.$t("common_i18n_country_insertPhone").d("请输入手机号")
                  : "请输入手机号",
                clearable: "",
                disabled: _vm.disable,
                autocomplete: "off",
              },
              on: {
                input: function ($event) {
                  return _vm.inputChange("")
                },
              },
              model: {
                value: _vm.phoneCode.phone,
                callback: function ($$v) {
                  _vm.$set(_vm.phoneCode, "phone", $$v)
                },
                expression: "phoneCode.phone",
              },
            },
            [
              _c(
                "yxt-dropdown",
                {
                  staticClass: "dropdownFull",
                  attrs: {
                    slot: "prepend",
                    "custom-elm": "",
                    placement: "bottom-start",
                  },
                  on: {
                    "visible-change": _vm.change,
                    command: _vm.inputChange,
                  },
                  slot: "prepend",
                },
                [
                  _c(
                    "div",
                    { class: _vm.disable ? "isNotallow" : "ispointer" },
                    [
                      _c(
                        "div",
                        { staticClass: "yxtbiz-area-code-select_codeItem" },
                        [
                          _vm._v(
                            "+" +
                              _vm._s(Number(_vm.phoneCode.areaCode || 86)) +
                              " "
                          ),
                        ]
                      ),
                      !_vm.disable
                        ? [
                            _c("yxt-svg", {
                              staticClass: "svgIcon",
                              attrs: {
                                width: "12px",
                                height: "12px",
                                "icon-class": "arrow-down",
                              },
                            }),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  !_vm.disable
                    ? _c(
                        "yxt-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        _vm._l(_vm.areaCodeList, function (item, index) {
                          return _vm.areaCodeList.length > 0
                            ? _c(
                                "yxt-dropdown-item",
                                {
                                  key: index.id,
                                  staticClass:
                                    "yxtbiz-area-code-select_chooseItem yxtbiz-area-code-select_chooseListBox",
                                  attrs: { command: item },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "yxtbiz-area-code-select_areaCode",
                                    },
                                    [_vm._v("+" + _vm._s(item.areaCode))]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      attrs: {
                                        title: _vm.isXuanxing
                                          ? _vm.$t(item.countryKey)
                                          : item.countryName,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.isXuanxing
                                            ? _vm.$t(item.countryKey)
                                            : item.countryName
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "yxtbiz-area-code-select__nodata",
                                },
                                [_c("yxt-empty")],
                                1
                              )
                        }),
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }