import { udpApi, miscApi, orgInit, qidaApi, dmpApi, downApi } from 'yxt-biz-pc/packages/api';

/**
 * 获取用户列表
 * @param query
 * @return {*}
 */
export const getUserList = (data) => {
  data = data === undefined ? {} : data;
  return udpApi.post(`users/search?targetOrgId=${data.targetOrgId || ''}`, data);
};

export const getGroupUserList = (data) => {
  return udpApi.post(`users/list?targetOrgId=${data.targetOrgId || ''}`, data);
};

/**
 * @param  dynamicFlag 是否是动态用户组
 */
export const getUserGroupList = (params, dynamicFlag) => {
  return udpApi.get(`usergroups?dynamicFlag=${dynamicFlag || ''}`, { params });
};

export const getPositionList = (params) => {
  return udpApi.get('positions', { params });
};

/**
 * 新增筛选器
 * @param {*} data
 */
export const createFilter = (data) => {
  return udpApi.post('advancefilters', data);
};

export const getFilterList = () => {
  return udpApi.get('advancefilters/recentlist');
};

/**
 * 获取职级
 * @param {Object} params
 */
export const getGradeList = (params) => {
  return udpApi.get('postiongrades', { params });
};

/**
 * 删除筛选器
 * @param {*} id
 */
export const deleteFilter = id => {
  return udpApi.delete(`advancefilters/${id}`);
};

/**
 * 编辑筛选器
 * @param {*} id
 * @param {*} data
 */
export const editFilter = (id, data) => {
  return udpApi.put(`advancefilters/${id}`, data);
};

export const getFilterDetail = (id) => {
  return udpApi.get(`advancefilters/${id}`);
};

/**
 * 导入用户（供选人组件使用）
 * @param {*} id
 * @param {*} data
 */
export const importUser = (data) => udpApi.post('users/importsearch', data);

// 部门信息查询
export const depMessageQuery = () => {
  return miscApi.get('security/dept/config');
};

// 获取机构扩展字段 高级选人用
export const getOrgSparsByUser = () => {
  return udpApi.get('orgs/usermgr/spares?spareType=1');
};
// 群信息查询
export const getDDGroupList = (data) => {
  return orgInit.post('dingding/sceneGroups/members/batchQuery', data);
};

// 获取列表
export const getRoleList = (data) => {
  return qidaApi.get('nav/roles/search', {params: data});
};

// 获取职级
export const getLevel = (data) => {
  return udpApi.get('postiongrades', {params: data});
};

// 获取团队、直属、部门经理下拉选项
export const getTeamSelect = (data) => {
  return dmpApi.post('team/component/team/list', data);
};

// 获取团队用户
export const getTeamUsers = (data) => {
  return dmpApi.post('team/component/user/page', data);
};

// 获取文件 安全下载地址
export const getSafeDownLoad = () => {
  return downApi.get('/nfs/basepc?fileName=userExport.xls');
};

// 获取导入模版
export const getUserSelectionDownload = () => {
  return udpApi.get(`users/selection/template/download`);
};