<!-- 审核中心入口 -->
<template>
  <yxt-badge :value="auditCount" :hidden="auditCount === 0" :max="99"
             class="audit-center-enter"
             :class="[{'is-stu':isStu}]" @click.native="goAuditCenter">
    <yxt-svg
      :width="size"
      :height="size"
      :remote-url="mediaPath"
      icon-class="audit-center-new02"
      class="hover-primary-6-i color-gray-9"
    />
  </yxt-badge>
</template>

<script>
import { getAuditCount } from './service';
import { CoreSvgPath } from './const';

export default {
  data() {
    return {
      auditCount: 0, // 审核数量
      mediaPath: CoreSvgPath
    };
  },
  props: {
    isStu: {
      type: Boolean,
      default: false
    }
  },
  components: {},

  computed: {
    size() {
      return this.isStu ? '24px' : '18px';
    }
  },

  mounted() {
    this.getAuditCountByMe();
  },

  methods: {
    // 获取审核中心数量
    getAuditCountByMe() {
      getAuditCount().then(res => {
        this.auditCount = res.count;
      });
    },
    goAuditCenter() {
      if (this.auditCount) {
        window.location = `${window.location.origin}/audit-center/#/my-audit`;
      } else {
        window.location = `${window.location.origin}/audit-center/#/my-apply`;
      }
    }
  }
};

</script>
<style lang="scss" scoped>
.audit-center-enter {
  cursor: pointer;
  width: 18px;
  height: 18px;


}

.is-stu {
  width: 24px;
  height: 24px;

}
</style>

