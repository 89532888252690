<template>
  <div class="is-content" v-if="isShow">
    {{ (msg.contentDetail && msg.contentDetail.content) || settings.assistWele || $t('pc_biz_ai_question_default_tips').d('你好，我是AI助手，很高兴为你服务！') }}
    <div class="promot-content">
        <div :class="{'promot-header': true, 'fullscreen-promot-header': fullscreen}">
            <div class="mt8">
                {{ $t('pc_biz_ai_promot_tips').d('你可以试着这样问我：') }}
            </div>
            <div @click="changeList" class="changeList" v-if="changeBtn">
                <yxtf-svg class="icon-copy" width="20px" height="20px" icon-class="turn-right" />
                <span>{{ $t('pc_biz_ai_change_promot').d('换一换') }}</span>
            </div>
        </div>
        <div class="promot-list" :class="{'promot-space':!fullscreen, 'fullscreen-promot': fullscreen}">
            <div class="promot-list-item" v-for="item in promots" :key="item" @click="promptClick(item)">
                <div class="list-item">
                    <span class="point"></span>{{ item }}
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
  getWeekHot
} from '../../service';
export default {
  name: 'questionPromot',
  props: {
    fullscreen: {
      type: Boolean,
      default: false
    },
    msg: { // 回答的消息体
      type: Object,
      default: () => {}
    }
  },
  inject: ['settings'],
  data() {
    return {
      startIndex: 0,
      promots: [],
      promotList: [],
      isShow: false
    };
  },
  mounted() {
    this.getWeekHot();
  },
  computed: {
    showPromotNums() {
      if (this.fullscreen) {
        return 6;
      } else {
        return 3;
      }
    },
    changeBtn() {
      return this.promotList.length > this.showPromotNums;
    }
  },
  watch: {
    fullscreen: function() {
      this.changeList();
    }
  },
  methods: {
    getWeekHot() { // 获取提问热词
      getWeekHot().then(res => {
        console.log('getWeekHot', res);
        if (res.length) {
          this.isShow = true;
          let words = res.map(item => item.word).sort(() => Math.random() - 0.5);
          // 根据热词数量插入热词
          this.promotList.unshift(...words);
        } else {
          this.isShow = false;
          this.$emit('promotEmpty');
        }
        this.changeList();
        this.$emit('scrollToEnd');
      });
    },
    changeList() {
      let data = [];
      const len = this.promotList.length;
      if (len <= this.showPromotNums) {
        this.promots = this.promotList;
        return;
      }

      while (data.length < this.showPromotNums) {
        data.push(this.promotList[this.startIndex]);
        this.startIndex = (this.startIndex + 1) % len;
      }
      this.promots = data;
    },
    promptClick(item) {
      this.$emit('PromotClick', item);
    }
  }
};
</script>

<style lang="scss" scoped>
.promot-content {
    .promot-header {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #595959;
        line-height: 28px;

        .changeList {
            cursor: pointer;
            display: flex;
            align-items: center;

            &:hover {
                color: var(--color-primary-6);
            }
        }
    }
    .fullscreen-promot-header {
      font-size: 16px;
    }

    .promot-list {
        .promot-list-item {
            margin-top: 8px;
            background: #F4F5F6;
            border-radius: 0px 12px 12px 12px;
            box-sizing: border-box;
            font-size: 14px;
            font-weight: 400;
            color: #262626;
            padding: 8px 16px;
            line-height: 28px;
            cursor: pointer;

            .list-item {
                overflow: hidden;
                box-sizing: border-box;
                text-overflow: ellipsis;

                .point {
                    line-height: 28px;
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    margin-right: 10px;
                    border: 1px solid #262626;
                    background: #fff;
                    border-radius: 50%;
                }
            }
        }

        &.fullscreen-promot {
            display: flex;
            flex-wrap: wrap;

            .promot-list-item {
                width: calc(33% - 8px);
                padding-top: 8px;
                margin-right: 16px;
                font-size: 16px;
                &:nth-child(3n) {
                    margin-right: 0px;
                }

                .list-item {
                    word-break: break-all;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    /* 这里是超出几行省略 */
                    overflow: hidden;
                }
            }
        }

        &.promot-space {
            .promot-list-item {
                .list-item {
                    white-space: nowrap;
                }
            }
        }
    }
}
</style>
