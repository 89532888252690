<template>
  <div class="yxtbiz-group-source-selector">
    <yxt-tabs v-model="sourceType" :drawer-top='true'>
      <yxt-tab-pane v-for="comp in components" :key="comp.sourceType" :label="$t(comp.nameKey).d(comp.name)" :name="comp.sourceType"></yxt-tab-pane>
    </yxt-tabs>
    <div class="yxtbiz-group-source-selector-body">
      <source-select ref="source" :sourceType="sourceType" :value.sync="list" :disabledIds="disabledIds"></source-select>
      <div class="yxtbiz-group-source-selector-body-selected pull-right">
        <yxt-scrollbar :fit-height="true">
          <div class="yxtbiz-group-source-selector-body-selected-tags">
          <yxt-tag :closable="!disabledIds.includes(item.id)"  @close="remove(item)" type="info" class="mr5 mt5 ellipsis" v-for="item in list" :key="item.id">
            <span class="close-tag">
              <yxt-tooltip  class="item" effect="dark" :content="item.name || item.fullname" placement="top">
                    <span class="close-tag_width_tail">{{item.name || item.fullname}}</span>
              </yxt-tooltip>
            </span>
          </yxt-tag>
          </div>
        </yxt-scrollbar>
        <yxt-button 
          type='text'
          class="mt5 yxtbiz-group-source-selector-body-selected-clear-btn"
          @click="clear"
          :disabled="Object.values(this.list).length === 0"
        >
        {{$t('pz_biz_share_btn_clearall').d('清除所有')}} 
        </yxt-button>
      </div>
    </div>
  </div>
</template>

<script>
import sourceSelect from './components/sourceSelector';
import { components } from './config';
export default {
  name: 'YxtbizGroupSourceSelector',
  components: { sourceSelect },
  data() {
    return {
      sourceType: '0',
      list: [],
      components
    };
  },
  props: {
    selected: {
      type: Array,
      default: []
    },
    disabledIds: {
      type: Array,
      default: []
    }
  },
  created() {
    this.list = this.selected.map(i=>{
      return {
        id: i.srcRsrcId,
        name: i.srcRsrcName,
        sourceType: i.rsrcType.toString(),
        shareState: i.shareState
      };
    });;

  },
  methods: {
    // 全部清除
    clear() {
      this.list = this.list.filter(i=>this.disabledIds.includes(i.id));
    },
    // 清除单个
    remove(item) {
      // this.$refs.source.toggleRowSelection(item);
      this.list = this.list.filter(i=>i.id !== item.id);
    },
    getSelected(type) {
      if (type !== undefined && type !== null) {
        type = type.toString();
        return this.list.filter(i=>{
          return i.sourceType === type;
        }).map(i=>{
          return {
            srcKngType: i.type,
            rsrcType: parseFloat(i.sourceType),
            srcRsrcId: i.id,
            srcRsrcName: i.name || i.fullname,
            shareState: i.shareState
          };
        });
      } else {
        return this.list.map(i=>{
          return {
            srcKngType: i.type,
            rsrcType: parseFloat(i.sourceType),
            srcRsrcId: i.id,
            srcRsrcName: i.name || i.fullname,
            shareState: i.shareState
          };
        });
      }
    }
  }
};
</script>
