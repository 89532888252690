<template>
  <div class="yxtbiz-check-person-range">
    <!--<span class="left_border" v-if="tabs.length !== 1"></span>-->
    <!-- <span class="right_border" v-if="tabs.length !== 1"></span> -->
    <yxt-tabs v-model="activeName" v-if="setTabs.length > 1" :drawer-top="true" :before-leave="tabBeforeLeave">
      <yxt-tab-pane
        v-for="(item, index) in setTabs"
        :label="item.label"
        :name="item.name"
        :key="item.name"
      >
        <template v-if="activeName===item.name">
          <slot
            v-if="$slots[item.name]"
            :ref="item.name"
            :name="item.name"
            @update="updateList"
          />
          <component
            v-else
            :identify="index"
            :ref="item.name"
            :is="item.component"
            :options="options[item.name]"
            @update="updateList"
            @updateDepartmentByType="updateDepartmentByType"
            :list="personRange"
            :functionCode="functionCode"
            :dataPermissionCode="dataPermissionCode"
            @onProcess="onProcess"
            @closeTag="closeTag"
            :enableGroupCorp='enableGroupCorp'
            :strongManageMode="strongManageMode"
            :targetOrgId="targetOrgId"
            :disabled="disabledOrgSelect"
            @org-change='handleOrgChange'
            :visibleOrgIds="visibleOrgIds"
            :visibleOrgSelector='visibleOrgSelector'
            :isShowDeptment="isShowDeptment"
          ></component>
        </template>
      </yxt-tab-pane>
    </yxt-tabs>
    <template v-else>
      <component
        v-for="(item) in setTabs"
        :class="{'single-table': !hiddenCheckList}"
        :identify="item.index"
        :key="item.name"
        :ref="item.name"
        :is="item.component"
        :options="options[item.name]"
        @update="updateList"
        @updateDepartmentByType="updateDepartmentByType"
        :list="personRange"
        :functionCode="functionCode"
        :dataPermissionCode="dataPermissionCode"
        @onProcess="onProcess"
        @closeTag="closeTag"
        :disabled="disabledOrgSelect"
        :enableGroupCorp='enableGroupCorp'
        :strongManageMode="strongManageMode"
        @org-change='handleOrgChange'
        :targetOrgId="targetOrgId"
        :visibleOrgIds="visibleOrgIds"
        :visibleOrgSelector='visibleOrgSelector'
        :isShowDeptment="isShowDeptment"
      ></component>
    </template>
    <div class="yxt-list__wrapper" :class="setTabs.length<=1?'yxt-list__wrapper-single':''" v-if="!hiddenCheckList">
      <checked-list
        @clear="clear"
        @close="closeTag"
        :list="personRange"
        :virtualRender="virtualRender"
      />
    </div>
  </div>

</template>

<script>
import CheckMorePosition from './components/CheckMorePosition';
import CheckMoreDepartment from './components/CheckMoreDepartment';
import CheckedList from './components/CheckedList';
import CheckManage from './components/CheckManage';
import UserGroup from './components/UserGroup';
import Role from './components/Role';
import Level from './components/Level';
import CheckPerson from './components/CheckPerson';
import Import from './components/import'; // 暂时复用组件
import { Tabs, TabPane } from 'yxt-pc';
import Vue from 'vue';
import { i18n } from 'yxt-biz-pc/packages/common-util/changeLangs';
import { MessageBox } from 'yxt-pc';

export default {
  name: 'YxtbizCheckPersonRange',
  components: {
    CheckMorePosition,
    CheckMoreDepartment,
    CheckedList,
    CheckManage,
    UserGroup,
    Role,
    Level,
    CheckPerson,
    Import,
    YxtTabs: Tabs,
    YxtTabPane: TabPane
  },
  props: {
    personRange: {
      type: Array,
      default: () => []
    },
    activeTab: {
      type: Number,
      default: 0
    },
    // string Or Object
    // Object:{label, name}  (name:slotName)
    tabs: {
      type: Array,
      default: () => [
        'department',
        'position',
        'usergroup',
        'role',
        'level',
        'person'
      ]
    },
    options: {
      type: Object,
      default: () => {
        return {
        };
      }
    },
    isShowDeptment: {
      type: Boolean,
      default: true
    },
    // 导航功能权限
    functionCode: {
      type: String,
      default: ''
    },
    // 数据权限code
    dataPermissionCode: {
      type: String,
      default: ''
    },
    virtualRender: {
      type: Boolean,
      default: false
    },
    hiddenCheckList: {
      type: Boolean,
      default: false
    },
    enableGroupCorp: {
      type: Boolean,
      default: false
    },
    targetOrgId: {
      type: String,
      default: ''
    },
    visibleOrgIds: {
      type: Array,
      default: []
    },
    visibleOrgSelector: {
      type: Boolean,
      default: true
    },
    disabledOrgSelect: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    list(list) {
      let renderList = [];
      list.forEach((item, index) => {
        const page = Math.floor(index / this.pageSize);
        if (!renderList[page]) {
          renderList[page] = {
            // id:
          };
        }
        renderList[page].push();
      });
    },
    activeTab: {
      handler(val) {
        let item = this.tabs[val];
        this.activeName = typeof item === 'string' ? item : item.name;
      },
      immediate: true
    },
    activeName: {
      handler(val) {
        this.$nextTick(() => {
          if (this.activeName !== 'department' && this.activeName !== 'import') {
            this.$refs[this.activeName] && this.$refs[this.activeName][0] && this.$refs[this.activeName][0].update && this.$refs[this.activeName][0].update();
          }
        });
      }
    },
    targetOrgId(val) {
      console.log(val);
    }
  },
  data() {
    // 记录组件初始化的原始数据key值
    this.oldObjKeys = [];
    return {
      tempArr: [],
      activeName: '',
      tabsOptioin: [
        { index: 0, name: 'department', label: i18n.t('biz_udp_deptment'), component: 'CheckMoreDepartment' },
        { index: 1, name: 'position', label: i18n.t('biz_udp_position'), component: 'CheckMorePosition' },
        { index: 2, name: 'usergroup', label: i18n.t('biz_udp_usergroup'), component: 'UserGroup' },
        { index: 3, name: 'role', label: i18n.t('biz_udp_role'), component: 'Role' },
        { index: 4, name: 'level', label: i18n.t('biz_udp_occupation_level'), component: 'Level' },
        { index: 5, name: 'person', label: i18n.t('biz_udp_person'), component: 'CheckPerson' },
        { index: 6, name: 'import', label: i18n.t('pc_biz_udp_lbl_importUser'), component: 'Import' },
        { index: 7, name: 'manage', label: i18n.t('biz_udp_direct'), component: 'CheckManage' }
      ],
      pageSize: 20,
      // 子组件状态，是否在处理内部数据（用于标记是否在导入用户流程中）
      processing: false,
      // 用于父组件调用，标记当前选人组件在抽屉中是否需要关闭提示，true：需要提示，false：不需要提示
      showTip: false
    };
  },
  created() {
    this.oldObjKeys = this.personRange.map(d=>d.id);
    if (this.enableGroupCorp) {
      this.personRange.forEach(item => {
        item.combinationName = item.orgName ? `${item.orgName}-${item.name}` : item.name;
      });
    }
    console.log(this.personRange, 'check-person-range');
  },
  mounted() {
  },
  computed: {
    setTabs() {
      if (this.tabs.length === 0) {
        return this.tabsOptioin;
      } else {
        return this.tabs
          .map(tab => {
            if (typeof tab === 'string') {
              return this.tabsOptioin.find(item => item.name === tab);
            } else if (typeof tab === 'object' && tab.label && tab.name) {
              return { ...tab };
            } else {
              return null;
            }
          })
          .filter(item => {
            return item !== null;
          });
      }
    }
  },
  methods: {
    handleOrgChange() {
      // this.$emit('update:personRange', []);
    },
    update() {
      this.$refs[this.activeName] && this.$refs[this.activeName][0] && this.$refs[this.activeName][0].update && this.$refs[this.activeName][0].update();
    },
    updateDepartmentByType({data, isCurrent}) {
      let arr = [...this.personRange];
      const personRangeIds = this.personRange.map(item => item.id);
      if (isCurrent === 0) {
        data.map(item => {
          if (!personRangeIds.includes(item.id)) {
            arr.push(item);
          } else {
            // 之前选过的，为了防止不破坏列表展示tag顺序
            const index = arr.findIndex(item2 => item2.id === item.id);
            arr[index].includeAll = 0;
            Vue.set(arr, index, {...arr[index]});
          }
        });
      } else {
        data.map((item, index) => {
          // 后续全部展示区域只保存第一个
          if (index === 0) {
            if (!personRangeIds.includes(item.id)) {
              arr.push(item);
            } else {
              // 之前选过的，为了防止不破坏列表展示tag顺序
              const index = arr.findIndex(item2 => item2.id === item.id);
              arr[index].includeAll = 1;
              Vue.set(arr, index, {...arr[index]});
            }
          }
          // else {
          //   // 之前选过的，为了防止不破坏列表展示tag顺序
          //   const index = arr.findIndex(item2 => item2.id === item.id);
          //   arr[index].includeAll = 1;
          // }
          if (index > 0) {
            arr = arr.filter(item2 => {
              return item2.id !== item.id;
            });
          }
        });
      }
      this.$emit('update:personRange', arr);
      this.showTipHandler();
    },
    updateList(val) {
      console.log(val);
      if (this.activeName === this.tabsOptioin[0].name) {
        val = this.handleDepartment(val);
      }
      this.$emit('update:personRange', val);
      this.showTipHandler();
    },
    handleDepartment(val) {
      const ids = val.map(item => item.id);
      let arr = [...this.personRange];
      // 删除不在val中的部门
      arr = arr.filter((item, index) => {
        // 判断是否来自部门
        if (item['_type'] === 0 && !ids.includes(item.id)) {
          return false;
        }
        return true;
      });
      console.log(arr);
      const personRangeIds = arr.map(item => item.id);
      // 添加不在列表中的部门
      val.map(item => {
        if (!personRangeIds.includes(item.id)) {
          arr.push(item);
        }
      });
      return arr;
    },
    closeTag({ tag, index }) {
      this.personRange.splice(index, 1);
      const tagName = tag['_type'] === 0 ? 'department' : this.activeName;
      this.$refs[tagName] && this.$refs[tagName][0] && this.$refs[tagName][0].closeTag && this.$refs[tagName][0].closeTag({ tag, index });
      this.showTipHandler();
    },
    clear() {
      this.tempArr = [...this.personRange];
      this.$emit('update:personRange', []);
      if (this.activeName === 'department') {
        this.$nextTick(() => {
          this.$refs[this.activeName][0].update(this.tempArr);
        });
      } else {
        this.$refs[this.activeName] && this.$refs[this.activeName][0] &&
        this.$refs[this.activeName][0].$refs['multipleTable'] &&
        this.$refs[this.activeName][0].$refs['multipleTable'].clearSelection &&
        this.$refs[this.activeName][0].$refs['multipleTable'].clearSelection();
      }
      this.showTipHandler();
    },
    tabBeforeLeave() {
      if (this.processing) {
        return MessageBox.confirm(this.$t('pc_biz_udp_msg_closeMsg'), this.$t('pc_biz_udp_lbl_closeTitle'), {
          confirmButtonText: this.$t('pc_biz_udp_btn_confirm'),
          cancelButtonText: this.$t('pc_biz_udp_btn_cancel'),
          type: 'warning'
        });
      } else {
        return true;
      }
    },
    // 子组件处理状态变更
    onProcess(processing) {
      this.processing = processing;
      this.showTipHandler();
    },
    showTipHandler() {
      // 以下情况需要关闭提示：数据发生了变化或者子组件正在处理数据
      this.$nextTick(()=>{
        this.showTip = this.processing || JSON.stringify(this.oldObjKeys) !== JSON.stringify(this.personRange.map(d=>d.id));
      });
    }
  }
};
</script>
<style lang="scss" scoped></style>
