<template>
  <div class="yxtbiz-nav-global-notice-opts yxtbiz-nav-global-notice-opts-small">
    <div class="yxtbiz-nav-global-notice-opts__title">
      <div class="yxtbiz-nav-global-notice-opts__title-ctx">{{ content.title }}</div>
      <div class="yxtbiz-nav-global-notice-opts__title-btn">
        <yxt-button type="text" @click="closeNotice">
          <i class="yxt-icon-close" width="16px" height="16px"></i>
        </yxt-button>
      </div>
    </div>
    <div class="yxtbiz-nav-global-notice-opts-small__wrapper" v-html="filterImg(content.content)"></div>
    <div class="yxtbiz-nav-global-notice-opts__footer yxtbiz-nav-global-notice-opts-small__footer">
      <!-- buttonDesc不为空则按钮名字不改变，否则按钮名字为buttonDesc字段的名字 -->
      <yxt-button type="text" @click="checkNotice">{{content.buttonDesc?content.buttonDesc:$t('pc_biz_notice_btn_check'/* 查看 */) }}<i class="yxt-icon-arrow-right"></i></yxt-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    content: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    filterImg(content) {
      return content.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, '');
    },
    filterStr(content) {
      var dd = content.replace(/<\/?.+?>/g, '');
      return dd.replace(/ /g, '');
    },
    closeNotice() {
      this.$emit('update:visible', false);
      this.$emit('closeNotice', this.content);
    },
    checkNotice() {
      this.$emit('check', this.content);
    }
  }
};
</script>
