<template>
  <div class="yxtbiz-biz-ai-my-study-history">
    <div class="yxtbiz-biz-ai-my-study-history-description" :class="{'font-size-16': size === 'large'}">
      {{ $t('pc_biz_study_lbl_search_history_for_you').d('为您找到以下学习历史：') }}
    </div>
    <div class="yxtbiz-biz-ai-my-study-history-content">
      <div class="yxtbiz-biz-ai-my-study-history-item" v-for="item in datas" :key="item.id" @click="openDetail(item.pcUrl)">
        <div class="my-study-history-item-left">
          <img class="my-study-history-item-img" :src="item.coverUrl || getDefaultImg(item.parentTargetType)">
        </div>
        <div class="my-study-history-item-right">
          <div class="my-study-history-item-title">
            <yxtf-tag type="info" size="small">{{ $t(tagFormat(item)) }}</yxtf-tag>
            <span class="my-study-history-item-title-text" :class="{'font-size-16': size === 'large'}">{{ item.parentTargetName}}</span>
          </div>
          <div class="my-study-history-item-description">
            <span class="my-study-history-item-date">{{ $t('pc_biz_cer_lbl_time') }}：{{dateFormat(item) || '--'}}</span>
            <yxtf-divider direction="vertical"  v-if="size === 'large'" ></yxtf-divider>
            <span class="my-study-history-item-dec" v-if="size === 'large'" v-html="getTipHtml(item)"></span>
          </div>
          <div v-if="size === 'small'" class="my-study-history-item-info" v-html="getTipHtml(item)">
          </div>
        </div>
      </div>
    </div>
    <div class="yxtbiz-biz-ai-my-study-footer"  @click="toMore">
      <span>{{ $t('pc_biz_ote_lbl_viewmore').d('查看更多') }}<i class="yxt-icon-arrow-right"></i></span>
    </div>
  </div>
</template>
<script>
import CommonMixin from '../common/props-mixin';
// 项目类型 0-所有类型 10-调研(11问卷调查、12评价表单、13投票评选) 20-考试 30-直播 40-培训 50-课程 60-练习 70-人才培养 71-岗位学习地图 72-测训项目
export const PROJECT_TYPE_MAP = {
  ALL: 0,
  SURVE: 10,
  SURVE2: 11,
  SURVE3: 12,
  SURVE4: 13,
  OTE: 20,
  LIVE: 30,
  O2O: 40,
  KNG: 50,
  PRACTICE: 60,
  PERSONTRAINING: 70,
  GWNL: 71,
  TESTTRAINING: 72
};

const PROJECT_LIST = {
  [PROJECT_TYPE_MAP.ALL]: {
    key: 'h5_core_msg_allo',
    cardClassBg: '',
    defaultImg: ''
  },
  [PROJECT_TYPE_MAP.SURVE]: {
    key: 'pc_biz_ai_lbl_survey',
    defaultImg: '324b29aa/c49b7e7c/SURVE.png'
  },
  [PROJECT_TYPE_MAP.SURVE2]: {
    key: 'pc_biz_ai_lbl_survey',
    defaultImg: '324b29aa/c49b7e7c/SURVE.png'
  },
  [PROJECT_TYPE_MAP.SURVE3]: {
    key: 'pc_biz_ai_lbl_survey',
    defaultImg: '324b29aa/c49b7e7c/SURVE.png'
  },
  [PROJECT_TYPE_MAP.SURVE4]: {
    key: 'pc_biz_ai_lbl_survey',
    defaultImg: '324b29aa/c49b7e7c/SURVE.png'
  },
  [PROJECT_TYPE_MAP.O2O]: {
    key: 'pc_biz_nav_tip_train',
    defaultImg: '324b29aa/c49b7e7c/O2O.png'
  },
  [PROJECT_TYPE_MAP.OTE]: {
    key: 'pc_biz_ai_lbl_exam',
    defaultImg: '324b29aa/c49b7e7c/OTE.png'
  },
  [PROJECT_TYPE_MAP.LIVE]: {
    key: 'pc_biz_o2o_lbl_live',
    defaultImg: '324b29aa/c49b7e7c/LIVE.png'
  },
  [PROJECT_TYPE_MAP.KNG]: {
    key: 'pc_biz_o2o_lbl_course',
    defaultImg: '324b29aa/c49b7e7c/KNG.png'
  },
  [PROJECT_TYPE_MAP.PRACTICE]: {
    key: 'pc_biz_o2o_lbl_practice',
    defaultImg: '324b29aa/c49b7e7c/OTE.png'
  }
};
export default {
  name: 'MyStudyHistory',
  mixins: [CommonMixin],
  props: {
    size: {
      type: String,
      default: 'large'
    }
  },
  data() {
    return {
    };
  },
  mounted() {
    console.log('this.params', this.params);
  },
  methods: {
  // 获取默认图片
    getDefaultImg(type) {
      return this.$staticBaseUrl + PROJECT_LIST[type].defaultImg;
    },
    openDetail(url) {
      if (!url) return;
      window.open(url);
    },
    tagFormat(item) {
      return PROJECT_LIST[item.parentTargetType].key;
    },
    dateFormat(item) {
      if (item.visitDate) {
        return item.visitDate.split(' ')[0];
      }
      return false;
    },
    toMore() {
      const studyTypeMap = {
        1: 40, // 培训
        2: 20, // 考试
        3: 50, // 课程
        4: 30, // 直播
        5: 10, // 调研
        6: 60 // 练习
      };
      const studyType = studyTypeMap[this.params.studyType] || 0;
      const startDate = this.params.startTime || '';
      const endDate = this.params.endTime || '';
      window.open(`/study/#/history?studyType=${studyType}&startDate=${startDate}&endDate=${endDate}`); // 学习历史
    },
    getTipHtml(item) {
      let { studyTime, schedule, taskStatus } = item;
      studyTime = (studyTime ? (studyTime / 60).toFixed(0) : 0) + this.$t('pc_biz_study_lbl_min');
      // 考试
      const MAPOTE = {
        '-1': '',
        1: this.$t('pc_biz_study_lbl_pendingexam'/* 待考试 */),
        2: this.$t('pc_biz_study_lbl_evaluating'/* 考试中 */),
        3: this.$t('pc_biz_study_lbl_complete'/* 已完成 */),
        4: this.$t('pc_biz_study_lbl_tobereview'/* 待批阅 */)
      };
      // 直播
      const MAPLIVE = {
        '-1': '',
        1: this.$t('pc_biz_study_lbl_notyetgegun'), // 未开始
        2: this.$t('pc_biz_study_lbl_processing'), // 进行中
        3: this.$t('pc_biz_study_lbl_projectover') // 已结束
      };

      const MAPOTHER = {
        '-1': '',
        1: this.$t('pc_biz_study_lbl_notyetgegun'), // 未开始
        2: this.$t('pc_biz_study_lbl_uncompleted'), // 未完成
        3: this.$t('pc_biz_study_lbl_complete') // 已完成
      };
      const STATUSLIST = {
        [PROJECT_TYPE_MAP.O2O]: `<span style="margin-right: 8px">${this.$t('pc_biz_study_lbl_usetime' /* 用时 */)} ${studyTime}</span>` + (schedule >= 0 ? `${this.$t('pc_biz_study_lbl_haslearn' /* 已学 */)}<span class="color-26 ml2">${schedule || 0}%</span>` : ''),
        [PROJECT_TYPE_MAP.OTE]: `<span style="margin-right: 8px">${this.$t('pc_biz_study_lbl_usetime' /* 用时 */)} ${studyTime}</span><span>${MAPOTE[taskStatus]}</span>`,
        [PROJECT_TYPE_MAP.KNG]: `<span style="margin-right: 8px">${this.$t('pc_biz_study_lbl_usetime' /* 用时 */)} ${studyTime}</span>` + (schedule >= 0 ? `${this.$t('pc_biz_study_lbl_haslearn' /* 已学 */)}<span class="color-26 ml2">${schedule || 0}%</span>` : ''),
        [PROJECT_TYPE_MAP.SURVE]: `<span>${MAPOTHER[taskStatus]}</span>`,
        [PROJECT_TYPE_MAP.LIVE]: `<span>${MAPLIVE[taskStatus]}</span>`,
        [PROJECT_TYPE_MAP.PRACTICE]: `<span>${MAPOTHER[taskStatus]}</span>`
      };

      return STATUSLIST[item.parentTargetType];
    }
  }
};
</script>
<style lang="scss" scoped>
.yxtbiz-biz-ai-my-study-history {
background-color: #fff;
border-radius: 4px;

.yxtbiz-biz-ai-my-study-history-description {
  font-size: 14px;
  font-weight: 400;
  padding-left: 12px;
  color: #8c8c8c;
  line-height: 28px;
}

.yxtbiz-biz-ai-my-study-history-content {
  .yxtbiz-biz-ai-my-study-history-item {
    display: flex;
    padding: 12px;
    cursor: pointer;
    border-radius: 8px;
    align-items: center;

    &:hover {
      background: #f4f5f6;
    }

    .my-study-history-item-left {
      width: 113px;
      height: 64px;
      margin-right: 12px;

      .my-study-history-item-img {
        object-fit: cover;
        width: 113px;
        height: 64px;
        border-radius: 4px;
      }
    }

    .my-study-history-item-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .my-study-history-item-title {
        font-size: 16px;
        font-weight: 400;
        color: #262626;
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;

        .my-study-history-item-title-text {
          line-height: 22px;
          font-size: 14px;
          max-height: 44px;
        }
      }

      .my-study-history-item-description {
        margin-top: 4px;
        font-size: 12px;
        color: #8c8c8c;
        line-height: 20px;
      }

      .my-study-history-item-info {
        font-size: 12px;
        color: #8c8c8c;
        line-height: 20px;
      }
    }
  }
}

.yxtbiz-biz-ai-my-study-footer {
  cursor: pointer;
  margin-top: 8px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #757575;
  line-height: 22px;

  &:hover {
    color: var(--color-primary-5);
  }
}
}
</style>
