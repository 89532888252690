<template>
  <div class="standard-size-14 yxtbiz-ai-robot__coure">
    <EmptyData v-if="dataList.length === 0" />
    <template v-else>
      <div :class="size === 'small'? 'font-size-14 lh22': 'font-size-16 lh24'" class="color-gray-7 yxtbiz-ai-robot__coure-title">
        {{ $t('pc_biz_ote_lbl_search_content', [$t('pc_biz_kng_lbl_course')]) }}
      </div>
      <div class="yxtbiz-ai-robot__coure-cell" v-for="item in dataList" :key="item.id" @click="toDetail(item)">
        <yxt-image class="yxtbiz-ai-robot__course-cover" v-if="item.imgUrl" :src="item.imgUrl" fit="cover" />
        <div class="ml12 yxtbiz-ai-robot__course-contain">
          <div :class="size === 'small'? 'font-size-14 lh22': 'font-size-16 lh24'" class="ellipsis-2 mb4" v-html="item.title"></div>
          <div v-if="item.summary && size !== 'small'" class="summary mb4" v-html="item.summary"></div>
          <div class="coure-flex-center">
            <div class="standard-size-12 color-gray-7 ellipsis-1" >
              <span v-html="item._author"></span>
              <yxt-divider direction="vertical" v-if="item._author && item.catalogName && size !== 'small'"></yxt-divider>
              {{(item.catalogName && size !== 'small') ? $t('pc_kng_course_lbl_exercise_label') + ' ' + item.catalogName : '' }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="datas.paging.count > 3" class="yxtbiz-ai-robot__course-more color-gray-8" @click="toMore">
        {{ $t('pc_biz_lbl_common_viewmore') }}
        <yxt-svg :remote-url="`${$staticBaseUrl}ufd/55a3e0`" width="16px" height="16px" icon-class="arrow_right" />
      </div>
    </template>
  </div>
</template>

<script>
import EmptyData from '../common/empty-data.vue';
export default {
  name: 'SearchKngCourse',
  components: {
    EmptyData
  },
  props: {
    datas: {
      type: Object,
      default: ()=>{
        return {datas: []};
      }
    },
    params: {
      type: Object,
      default: ()=>{}
    },
    size: String
  },
  data() {
    return {};
  },
  computed: {
    dataList() {
      return this.datas.datas.map(item => {
        item._author = item.lecturer ? this.$t('pc_biz_ai_lecturer') + '：' + item.lecturer : item.contributorsName ? this.$t('pc_biz_ai_contributor') + '：' + item.contributorsName : '';
        return item;
      });
    }
  },
  methods: {
    toDetail(item) {
      window.open(`/kng/#/scan?kngId=${item.id}`);
    },
    toMore() {
      window.open(`/search/#/gs/knowledge?keyword=${this.params.searchKey}`);
    }
  }
};
</script>
<style scoped lang="scss">
.yxtbiz-ai-robot__coure{
  width: 100%;
}
.yxtbiz-ai-robot__coure-title{
  padding: 0px 12px 12px;
}
.summary {
  flex: 1;
  min-width: 0;
  font-size: 12px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #757575;
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.yxtbiz-ai-robot__course-contain{
  flex-grow: 1;
  overflow: hidden;
}
.yxtbiz-ai-robot__coure-cell {
  display: flex;
  overflow: hidden;
  align-items: center;
  border-radius: 8px;
  padding: 12px;
  cursor: pointer;
  &:hover {
    background-color: #f4f5f6;
  }
}
.yxt-divider {
  background-color: #d8d8d8;
}
.yxt-progress__text {
  font-size: 12px !important;
  line-height: 20px;

  &>span {
    font-size: 12px !important;
  }
}
.coure-flex-center {
  display: flex;
  align-items: center;
}
.yxtbiz-ai-robot__course-cover {
  width: 112px;
  height: 63px;
  border-radius: 4px;
  flex-shrink: 0;
  flex-grow: 0;
}
.yxtbiz-ai-robot__course-more {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: #f4f5f6;
  }
}
</style>
