var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "yxtf-dialog",
    {
      attrs: {
        title: _vm.$t("pc_biz_ai_feedback_title").d("我要反馈"),
        visible: _vm.dialogVisible,
        "append-to-body": true,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _vm.settings.fastFeedbackList && _vm.settings.fastFeedbackList.length
        ? _c(
            "div",
            { staticClass: "mb16" },
            _vm._l(_vm.settings.fastFeedbackList, function (tag, index) {
              return _c(
                "yxtf-tag",
                {
                  key: index,
                  staticClass: "hand",
                  attrs: { type: "info" },
                  on: {
                    click: function ($event) {
                      return _vm.tagClicked(tag)
                    },
                  },
                },
                [_vm._v(_vm._s(tag))]
              )
            }),
            1
          )
        : _vm._e(),
      _c("yxtf-input", {
        attrs: {
          type: "textarea",
          placeholder: _vm
            .$t("pc_biz_ai_input_feedback_conent")
            .d("请输入反馈内容"),
          rows: "4",
          resize: "none",
          autofocus: "",
          maxlength: 200,
          "show-word-limit": "",
        },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("yxtf-button", { on: { click: _vm.handleClose } }, [
            _vm._v(_vm._s(_vm.$t("pc_biz_ai_btn_cancel").d("取消"))),
          ]),
          _c(
            "yxtf-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v(_vm._s(_vm.$t("pc_biz_ai_btn_submit").d("提交")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }