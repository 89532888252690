var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "yxtbiz-biz-ai-my-study-history" }, [
    _c(
      "div",
      {
        staticClass: "yxtbiz-biz-ai-my-study-history-description",
        class: { "font-size-16": _vm.size === "large" },
      },
      [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm
                .$t("pc_biz_study_lbl_search_history_for_you")
                .d("为您找到以下学习历史：")
            ) +
            "\n  "
        ),
      ]
    ),
    _c(
      "div",
      { staticClass: "yxtbiz-biz-ai-my-study-history-content" },
      _vm._l(_vm.datas, function (item) {
        return _c(
          "div",
          {
            key: item.id,
            staticClass: "yxtbiz-biz-ai-my-study-history-item",
            on: {
              click: function ($event) {
                return _vm.openDetail(item.pcUrl)
              },
            },
          },
          [
            _c("div", { staticClass: "my-study-history-item-left" }, [
              _c("img", {
                staticClass: "my-study-history-item-img",
                attrs: {
                  src:
                    item.coverUrl || _vm.getDefaultImg(item.parentTargetType),
                },
              }),
            ]),
            _c("div", { staticClass: "my-study-history-item-right" }, [
              _c(
                "div",
                { staticClass: "my-study-history-item-title" },
                [
                  _c("yxtf-tag", { attrs: { type: "info", size: "small" } }, [
                    _vm._v(_vm._s(_vm.$t(_vm.tagFormat(item)))),
                  ]),
                  _c(
                    "span",
                    {
                      staticClass: "my-study-history-item-title-text",
                      class: { "font-size-16": _vm.size === "large" },
                    },
                    [_vm._v(_vm._s(item.parentTargetName))]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "my-study-history-item-description" },
                [
                  _c("span", { staticClass: "my-study-history-item-date" }, [
                    _vm._v(
                      _vm._s(_vm.$t("pc_biz_cer_lbl_time")) +
                        "：" +
                        _vm._s(_vm.dateFormat(item) || "--")
                    ),
                  ]),
                  _vm.size === "large"
                    ? _c("yxtf-divider", { attrs: { direction: "vertical" } })
                    : _vm._e(),
                  _vm.size === "large"
                    ? _c("span", {
                        staticClass: "my-study-history-item-dec",
                        domProps: { innerHTML: _vm._s(_vm.getTipHtml(item)) },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm.size === "small"
                ? _c("div", {
                    staticClass: "my-study-history-item-info",
                    domProps: { innerHTML: _vm._s(_vm.getTipHtml(item)) },
                  })
                : _vm._e(),
            ]),
          ]
        )
      }),
      0
    ),
    _c(
      "div",
      {
        staticClass: "yxtbiz-biz-ai-my-study-footer",
        on: { click: _vm.toMore },
      },
      [
        _c("span", [
          _vm._v(_vm._s(_vm.$t("pc_biz_ote_lbl_viewmore").d("查看更多"))),
          _c("i", { staticClass: "yxt-icon-arrow-right" }),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }