var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "study-center-nav font-size-14 color-gray-10 bg-white" },
    [
      _c(
        "ul",
        _vm._l(_vm.navList, function (item, index) {
          return _c(
            "li",
            {
              key: item.name,
              staticClass: "tab-item hand hover-bg-primary-1 hover-primary-6",
              class: {
                "color-primary-6 bg-primary-1": _vm.pageCode === item.pageCode,
              },
              on: {
                click: function ($event) {
                  return _vm.changeTab(index, item)
                },
              },
            },
            [
              _c(
                "div",
                [
                  _c("yxtf-svg", {
                    staticClass: "hover-primary-6 tab-icon",
                    class: [
                      _vm.pageCode === item.pageCode
                        ? "color-primary-6"
                        : "default-color",
                    ],
                    attrs: {
                      "remote-url": _vm.mediaUrl,
                      width: "22px",
                      height: "22px",
                      "icon-class": item.icon,
                    },
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "tab-label yxt-weight-4",
                      class: { "yxt-weight-6": _vm.pageCode === item.pageCode },
                    },
                    [_vm._v(_vm._s(item.name))]
                  ),
                ],
                1
              ),
              item.count
                ? _c(
                    "span",
                    {
                      staticClass: "tab-count standard-size-16",
                      class: [
                        _vm.pageCode === item.pageCode
                          ? "bg-white color-primary-6"
                          : "bg-gray-2 color-gray-8 ",
                      ],
                    },
                    [_vm._v(_vm._s(_vm._f("max99")(item.count)))]
                  )
                : _vm._e(),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }