var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("import", {
    attrs: {
      functionCode: _vm.functionCode,
      dataPermissionCode: _vm.dataPermissionCode,
      value: _vm.list,
    },
    on: { input: _vm.input, onProcess: _vm.onProcess },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }