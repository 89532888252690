<template>
  <div
    :id="navId"
    class="yxtbiz-nav-main clearfix"
    :class="navDisplayType === 0 ? 'yxtbiz-nav-main--front' : 'yxtbiz-nav-main--manager'"
    v-loading="!isLoadedNav"
    :style="`visibility: ${isLoadedNav? 'visible':'hidden' }`">
    <!-- 管理端 默认导航 -->
    <template v-if="navDisplayType === 1">
      <yxt-layout :auto-fit="isShowLeftNav || autoFit" root>
        <yxt-layout-header v-if="isShowTopNav" :style="{ 'z-index': topNavZindex }">
          <!--头部导航-->
          <nav-top-workbench v-if="isWorkBenchNav"></nav-top-workbench>
          <yxtbiz-nav-top
            v-else
            :isRedirectFirstPage="isRedirectFirstPage"
            :firstPageCode="topPageCode"
            :mainPathname="mainPathname"
            :navDatas="navDatas"
            :mainPageCode="functionCode"
            :tinyNav="tinyNav"
            :lang-list="langList"
            :isOpenI18n="enableI18n"
            :allPath="allPath"
          />
          <!-- <out-service-msg @show="(s) => osmShow = s" v-if="OSMEnable === 1"/> -->
            <stop-notice  @show="(s) => osmShow = s" :isManager="true"  v-if="OSMEnable === 1" ></stop-notice>
        </yxt-layout-header>
        <yxt-layout-main>
          <yxt-layout-side
            inner-class='yxtbiz-nav-main__side-inner'
            :class="{
              'yxtbiz-nav-main__side--hover': hovered && collapse,
              'yxtbiz-nav-main__side--loading': !leftLoaded
            }"
            @mouseenter.native="leftEnter"
            @mouseleave.native="leftLeave"
            class="yxtbiz-nav-main__side" :width="collapse?56:184" v-if="isShowLeftNav" main-nav>
            <!--左侧导航-->
            <yxtbiz-nav-left
              :hovered="hovered"
              @enterBottom="enterBottom"
              @leaveBottom="leaveBottom"
              @collapseLeft="collapseLeftInner"
              :mainPathname="mainPathname"
              :mainPageCode="functionCode"
              :class="[isShowTopNav? '': 'yxtbiz-nav-main__left-notop']"
              :appNavLeft="appNavLeft"
              @collapseOperated="collapseOperated = true"
             >
              <template :slot="'leftbottom'" slot-scope="scope" >
                <slot name="leftbottom" :collapse="scope.collapse" :width="scope.collapse?56:184"></slot>
              </template>
              <!-- <template v-slot:leftbottom="collapse"><slot name="leftbottom" :collapse="collapse" /></template> -->
            </yxtbiz-nav-left>
          </yxt-layout-side>
          <slot v-if="isLoadedNav"></slot>
        </yxt-layout-main>
      </yxt-layout>
    </template>
    <!-- 管理端 应用导航 -->
    <template v-else-if="navDisplayType === 11">
      <div class="yxtbiz-nav-main__body">
        <yxtbiz-nav-immersive
          :isRedirectFirstPage="isRedirectFirstPage"
          :firstPageCode="topPageCode"
          :mainPathname="mainPathname"
          :navDatas="navDatas"
          :mainPageCode="functionCode"
          :isShowTopNav="isShowTopNav"
          :allPath="allPath"
        >
          <slot v-if="isLoadedNav"></slot>
        </yxtbiz-nav-immersive>
      </div>
    </template>
    <!-- 学员端导航 -->
    <template v-else>
      <div class="yxtbiz-nav-main__stu-container" :style="{ 'min-width': minWidth + 'px' }">
        <nav-top-workbench v-if="isShowTopNav&&isWorkBenchNav"></nav-top-workbench>
        <!-- 头部导航 -->
        <yxtbiz-nav-top-stu
          v-else-if="isShowTopNav"
          :isRedirectFirstPage="isRedirectFirstPage"
          :firstPageCode="topPageCode"
          :mainPathname="mainPathname"
          :navDatas="navDatas"
          :mainPageCode="functionCode"
          :decorateModule="decorateModule"
          :isKngNav="isKngNav"
          :OSMEnable="OSMEnable"
          :lang-list="langList"
          :isOpenI18n="enableI18n"
          :previewMode="previewMode"
          :allPath="allPath"
          :showIm="showIm"
        >
          <template :slot="'imbell'">
            <slot name="imbell"></slot>
          </template>
          <template :slot="'announcement'">
            <slot name="announcement"></slot>
          </template>
        </yxtbiz-nav-top-stu>
        <!-- 全局公告 TODO -->
        <!-- 主体内容 -->
        <div :class="['yxtbiz-nav-main__stu yxtbiz-nav-main__stu--buildin',
          isShowTopNav && isContentPt ? '': 'yxtbiz-nav-main__stu--notop',
          isShowLeftNav ? '': ''
        ]">
          <template v-if="!isShowLeftNav">
            <!-- 通栏 -->
            <slot v-if="fullWidth"></slot>
            <!-- 宽度限制的内容 -->
            <div v-else class="yxtbiz-nav-main__stu-content">
              <slot></slot>
            </div>
          </template>
          <div v-else class="yxtbiz-nav-main__container yxtbiz-nav-main__container--buildin">
            <!--左导航-->
            <yxtbiz-nav-left-stu :class="showBreadcrumb ? 'mt45':''"
              :mainPathname="mainPathname"
              :functionCode="functionCode"
              @toPage="toPage"
            ></yxtbiz-nav-left-stu>
            <div class="yxtbiz-nav-main__stu-right" :class="showBreadcrumb? 'show-breadcrumb' :''">
              <div class="yxtbiz-nav-main__stu-breadcrumb" v-if="showBreadcrumb">
                <yxtf-breadcrumb separator-class="yxtf-icon-arrow-right">
                  <yxtf-breadcrumb-item v-for="(item,index) in stuBreadcrumb" :key="index" v-bind="item">{{item.name}}</yxtf-breadcrumb-item>
                </yxtf-breadcrumb>
              </div>
              <!--网页主体-->
              <slot></slot>
            </div>
          </div>
        </div>
        <!--网页页脚-->
        <yxtbiz-nav-footer v-if="isShowFooter && !isWorkBenchNav" />
      </div>
    </template>
    <!-- 帮助中心 -->
    <yxtbiz-support-sidebar
      v-if="isShowHelpCenter && type === 2 && isZh"
      :isExistTopNav="isShowTopNav && type === 2 && displayType === 1"
      :functionCode="functionCode"
      :tinyNav="tinyNav">
    </yxtbiz-support-sidebar>
    <global-notice v-if="OSMEnable === 1" :isManager="true" @show="(s) => osmShow = s"></global-notice>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import commonUtil from 'yxt-biz-pc/packages/common-util';
import { addResizeListener, removeResizeListener } from 'yxt-biz-pc/src/utils/resize-event';
import GlobalNotice from './global-notice.vue';
import StopNotice from './stop-notice.vue';
import NavTopWorkbench from './nav-top-workbench';
import { Globalize, goNavPage, trackError } from './common';
import DownloadGlobalToast from 'yxt-biz-pc/packages/download-global-toast';
// import { productionUtil } from '../../../common-util/productionUtil';
import { defaultPath, defaultProductAllPath} from './const';
import { getOrgParams } from './service.js';
export default {
  name: 'YxtbizNavMain',
  components: {
    // OutServiceMsg,
    GlobalNotice,
    StopNotice,
    NavTopWorkbench
  },
  data() {
    return {
      navId: 'yxtBizNavMain',
      oldNavStamp: 0,
      noPerTimeout: null,
      hovered: false,
      collapseOperated: false,
      leftLoaded: false,
      hoveredLeft: false,
      allPath: '',
      // 机构参控制 是否在繁体下 展示帮助中心
      showHelpCenterInHa: false
    };
  },
  props: {
    // 是否导向初始页
    isRedirectFirstPage: {
      type: Boolean,
      default: false
    },
    // 一级导航的code，导初始页时会寻找第一个此导航下有权限的页面
    topPageCode: {
      type: String,
      default: ''
    },
    // 当前访问页面功能编号，用于判断是否有权限以及导航的选中
    functionCode: {
      type: String,
      default: ''
    },
    // 微服务code
    appName: {
      type: String,
      default: ''
    },
    // 是否显示左侧导航
    isShowLeftNav: {
      type: Boolean,
      default: true
    },
    // 是否显示头导航
    isShowTopNav: {
      type: Boolean,
      default: true
    },
    // 是否显示面包屑 m2 废弃
    isShowBreadcrumb: {
      type: Boolean,
      default: false
    },
    // 是否显示帮助中心
    isShowHelpCenter: {
      type: Boolean,
      default: true
    },
    // 面包屑追加的菜单 m2 废弃
    appendMenus: {
      type: Array,
      default: () => { return []; }
    },
    // 是否显示页脚
    isShowFooter: {
      type: Boolean,
      default: true
    },
    // pc学员端内容区域是否横向铺满浏览器
    fullWidth: {
      type: Boolean
    },
    // pc学员端内容区域最小宽度，默认1280
    minWidth: {
      type: Number,
      default: 1280
    },
    // 静态的导航数据，传入时不再读取数据库的导航信息
    navDatas: {
      type: Object,
      default: null
    },
    // 类型 1：pc学员端，2pc管理员，3移动端导航 4boss平台
    type: {
      type: Number,
      default: 2
    },
    // 展示类型 1：普通  2：沉浸式（应用模式）
    displayType: {
      type: Number,
      default: 1
    },
    // 自适应窗口宽度 用于后台导航内布局
    autoFit: {
      type: Boolean,
      default: false
    },
    // 是否默认收起左侧导航。0或者null等为默认状态不会处理，1时会直接收起，-1时会直接展开
    collapseLeft: {
      type: Number,
      default: 0
    },
    // 装修模块信息，用于在装修时临时呈现 m1时使用
    decorateModule: {
      type: Object,
      default: null
    },
    // 是否知识库导航 m2 废弃
    isKngNav: {
      type: Boolean
    },
    // 内容区域是否内置顶边距
    isContentPt: {
      type: Boolean,
      default: true
    },
    // 是否设置页面标题
    // isSetTitle: {
    //   type: Boolean,
    //   default: false
    // },
    // 是否校验权限
    isCheckPermission: {
      type: Boolean,
      default: true
    },
    // 是否显示头部小型导航
    tinyNav: {
      type: Boolean,
      default: false
    },
    // 是否显示app模式的左导航
    appNavLeft: {
      type: Boolean,
      default: false
    },
    // 停服公告的启用控制，-1为默认值（默认前台导航开启，后台导航关闭）  0： 强制关闭  1：开启
    OSMEnable: {
      type: Number,
      default: -1
    },
    stuBreadcrumb: { // {to,url,name}
      type: Array,
      default: () => []
    },
    // 预览模式
    previewMode: {
      type: Boolean,
      default: false
    },
    // 是否展示学员端im小铃铛
    showIm: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    showBreadcrumb() {
      return this.stuBreadcrumb && this.stuBreadcrumb.length;
    },
    isZh() {
      return commonUtil.getLanguage() === 'zh' || (commonUtil.getLanguage() === 'ha' && this.showHelpCenterInHa);
    },
    isLoadedNav() {
      return (this.$store.state.navManageStore.lastUpdateNavStamp && this.$store.state.navManageStore.lastUpdateNavStamp !== this.oldNavStamp) || this.navDatas;
    },
    mainPathname() {
      return `/${this.appName ? this.appName + '/' : ''}`;
    },
    pagePermissionAndCode() {
      // 同时监听code和permission ,处理连续没权限的问题
      return this.$store.state.navManageStore.pageCode + this.$store.state.navManageStore.pagePermission;
    },
    ...mapState({
      collapse: state => state.navManageStore.collapse
    }),
    nav() {
      return this.$store.state.navManageStore.nav;
    },
    cnavs() {
      return this.$store.state.navManageStore.cnavs;
    },
    topNavZindex() {
      return this.$store.state.navManageStore.topNavZindex || 100;
    },
    navDisplayType() {
      switch (this.type) {
        case 2:
        case 4:
          // 管理端导航样式
          return this.displayType === 1 || this.tinyNav ? 1 : 11;
        case 1:
          // 学员端导航样式
          return 0;
        default:
          return 2;
      }
    },
    langList() {
      return commonUtil.getLangList();
    },
    enableI18n() {
      return window.localStorage.getItem('enableI18n') === '1' ? 1 : 0;
    },
    isWorkBenchNav() {
      try {
        const workNavList = JSON.parse(window.localStorage.getItem(`${defaultPath}nav_datas_5`) || '[]');
        const nav = workNavList.filter(i=>i.code === this.functionCode);
        return nav && nav.length > 0;
      } catch (e) {
        return false;
      }
    }
  },
  watch: {
    type(val, valold) {
      if (val !== valold) {
        this.oldNavStamp = this.$store.state.navManageStore.lastUpdateNavStamp;
        this.initNavs();
      }
    },
    functionCode() {
      this.$store.dispatch(
        'navManageStore/computePageCodes',
        this.functionCode
      );
      this.dealCollapseLeft();
    },
    pagePermissionAndCode() {
      if (!this.$store.state.navManageStore.pagePermission) {
        if (this.isRedirectFirstPage && !this.functionCode) {
          return false;
        }

        if (this.isCheckPermission) {
          this.$emit('noPermission');
          trackError({
            tag: 'permission_error',
            pageCode: this.functionCode,
            orgId: localStorage.orgId
          });
          window.location.replace(`${window.location.origin}/default-pages/403.html`); // 不在白名单，跳转到白名单页面
        }

        this.$emit('noNavPermission', {
          pageCode: this.functionCode,
          store: this.$store.state.navManageStore,
          orgId: localStorage.orgId
        });
      }
    },
    isShowLeftNav(v) {
      if (!v) {
        this.leftLoaded = false;
      } else {
        this.$nextTick(() => {
          this.leftLoaded = true;
        });
      }
    },
    collapseLeft(val) {
      this.dealCollapseLeft();
    }
    // nav() {
    //   if (this.isSetTitle && this.nav) {
    //     // 设置title
    //     document.title = Globalize(this.nav, 'name');
    //   }
    // }
    // isSetTitle() {
    //   if (this.isSetTitle && this.nav) {
    //     // 设置title
    //     document.title = Globalize(this.nav, 'name');
    //   }
    // }
  },
  created() {
    const downloadGlobalToast = new DownloadGlobalToast(this);
    console.log(downloadGlobalToast);
    this.dealCollapseLeft();
    this.initNavs();
    this.resizeToSetCollapseLeft();
    this.$store.dispatch('navManageStore/setGroupCorpInfo').catch(e => {
      // null
    }); // 获取集团化信息
    // 获取当前产品跳转路径
    // const info = productionUtil.getProductionInfo()
    this.allPath = defaultProductAllPath;
  },
  mounted() {
    const navDom = document.getElementById(this.navId);
    navDom && addResizeListener(navDom, this.resizeToSetCollapseLeft);
    this.$nextTick(() => {
      this.leftLoaded = true;
    });
    this.$store.dispatch('navManageStore/getOrgLogoutSetting');
    // 机构参请求 判断是否展示帮助中心
    this.getHelperCenterStatus();
  },
  methods: {
    ...mapActions('navManageStore', ['setCollapse']),
    toPage(page) {
      let goPage = page;
      if (!page.pageUrl) {
        if (page.subMenuInfoList && page.subMenuInfoList.length > 0) {
          goPage = page.subMenuInfoList[0];
        } else {
          return;
        }
      }
      goNavPage(goPage, this.mainPathname, this);
    },
    initNavs() {
      // 获取导航数据
      this.$store.dispatch('navManageStore/getNavs', [
        this.functionCode,
        this.navDatas,
        this.type
      ]);
    },
    dealCollapseLeft() {
      if (this.collapseLeft) {
        const isCollapse = this.collapseLeft === 1;
        if (this.collapse !== isCollapse) {
          this.setCollapse();
        }
      }
    },
    resizeToSetCollapseLeft() {
      const widthThreshold = 1280;
      if (this.collapseLeft) {
        return;
      }
      let Oldwidth = this.windowWidth;
      this.windowWidth = window.innerWidth || document.documentElement.clientWidth || window.document.body.clientWidth;
      if ((Oldwidth >= widthThreshold && this.windowWidth < widthThreshold) || (Oldwidth <= widthThreshold && this.windowWidth > widthThreshold) || !Oldwidth) {
        let newCollapse = this.windowWidth <= widthThreshold;
        if (newCollapse !== this.collapse) {
          this.setCollapse(newCollapse);
        }
      }
    },
    leftEnter(e) {
      if (this.hoverLock) {
        return;
      }
      this.hovered = true;
      this.hoveredLeft = true;
    },
    leftLeave(e) {
      this.hovered = false;
      this.hoveredLeft = false;
    },
    enterBottom() {
      this.hovered = false;
    },
    leaveBottom() {
      if (this.hoverLock) {
        return;
      }
      this.hovered = this.hoveredLeft;
    },
    collapseLeftInner() {
      this.hovered = false;
      this.hoverLock = true;
      this.lockSto && clearTimeout(this.lockSto);
      this.lockSto = setTimeout(()=> {
        this.hoverLock = false;
      }, 200);
    },
    getHelperCenterStatus() {
      getOrgParams('show_helper_center_status', localStorage.orgId).then(res =>{
        this.showHelpCenterInHa = res.value === '1';
      });
    }
  },
  filters: {
    Globalize(data, key) {
      return Globalize(data, key);
    }
  },
  beforeDestroy() {
    const navDom = document.getElementById(this.navId);
    removeResizeListener(navDom, this.resizeToSetCollapseLeft);
  }
};
</script>
<style lang="scss">
  .download-toast-footer-box {
    margin-top: 17px;
    display: flex;
    justify-content: flex-end;
  }
</style>
