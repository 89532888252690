import { supportApi, apiBaseUrl, qidaApi } from 'yxt-biz-pc/packages/api';

/**
 * 获取侧边栏数据
 * @param {*} functionCode 模块编码
 */
export const getSidebarMenu = functionCode => {
  return supportApi.get(`/xx-mgt/sidebar-menus?functionCode=${functionCode}`);
};

/**
 * 获取视频播放地址
 * @param {*} contentId 内容编码
 */
export const getVideoUrl = contentId => {
  return supportApi.get(`/opt-back/contents/${contentId}/play-url`);
};

/**
 * @description 获取助手配置信息
 * @param {*} data
 * @return {*}
 */
export const getChatSetting = data => {
  return apiBaseUrl.get('/aiassist/stu/chat/org_setting', data);
};

// 获取机构信息
export const getOrgInfo = orgId => {
  return qidaApi.get('org/orgname?orgId=' + orgId);
};
