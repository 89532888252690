<template>
  <div class="yxtbiz-support-sidebar">
    <div v-show="!drawer" class="sidebar-entry sidebar-entry__show">
      <div v-if="showAi" class="sidebar-ai hand" @click="openAi">
        <yxt-svg
          class="sidebar-ai-icon"
          :remote-url="`${$staticBaseUrl}ufd/407a24/assistent/pc/svg`"
          width="16"
          height="16"
          icon-class="ai-log1"
        ></yxt-svg>
      </div>
      <span class="center" v-if="showSupport" @click="handleShowDrawer"
        >帮助中心<i class="yxt-icon-arrow-left"></i
      ></span>
      <span
        v-if="showCustomService && onlineServiceShow"
        class="custom support-customerService"
      >
        <i class="custom-icon"></i>客服</span
      >
      <div v-if="isShowHotline" class="support-hotline">
        <div class="support-hotline__inner standard-size-12">
          <yxt-svg class="color-gray-9" icon-class="hotline" />
          <div class="color-gray-10 yxt-weight-5" v-if="isTieFinance">
            400-900-2217
          </div>
          <div class="color-gray-10 yxt-weight-5" v-else>400-8397-880</div>
          <div class="color-gray-7">9:00～18:30</div>
        </div>
      </div>
    </div>
    <yxt-drawer
      ref="sidebar"
      title="帮助中心"
      :visible.sync="drawer"
      :before-close="handleCloseDrawer"
      direction="rtl"
      size="280px"
      :modal="false"
      class="yxtbiz-sidebar-drawer"
      :style="
        isExistTopNav ? `padding-top: ${offsetTop}px` : `padding-top: 0px`
      "
      @open="handleOpen"
    >
      <yxt-scrollbar :fit-height="true">
        <div class="yxtbiz-supportslidbar__wrap">
          <div class="sidebar-packup">
            <span class="packup" @click="handleCloseDrawer"
              >收起<i class="yxt-icon-arrow-right"></i
            ></span>
          </div>
          <div
            class="module"
            v-if="showNav && navigations && navigations.length > 0"
            key="navigations"
          >
            <header><i class="nav-icon"></i>功能导航</header>
            <navigation :data="navigations"></navigation>
          </div>
          <div
            class="module"
            v-if="showNav && hotIssues && hotIssues.length > 0"
            key="hotIssues"
          >
            <header><i class="hot-qa-icon"></i>热门问题</header>
            <contents
              :active="active"
              :data="hotIssues"
              type="hotIssues"
              @select="handleSelect"
            ></contents>
          </div>
          <div
            class="module"
            v-if="!showNav && tutorials && tutorials.length > 0"
            key="tutorials"
          >
            <header><i class="course-icon"></i>使用教程</header>
            <contents
              :active="active"
              :data="tutorials"
              @select="handleSelect"
            ></contents>
          </div>
          <div
            class="module"
            v-if="!showNav && issues && issues.length > 0"
            key="issues"
          >
            <header><i class="hot-qa-icon"></i>常见问题</header>
            <contents
              :active="active"
              :data="issues"
              @select="handleSelect"
            ></contents>
          </div>
        </div>
      </yxt-scrollbar>

      <div slot="footer" class="footer-container">
        <button type="button" class="support-center" @click="handleShowCenter">
          <i class="center-icon"></i>帮助中心
        </button>
        <span v-if="showCustomService" class="split-line"></span>
        <button
          v-if="showCustomService"
          type="button"
          class="support-customerService"
        >
          <i class="custom-icon"></i>联系客服
        </button>
      </div>
    </yxt-drawer>
    <!-- ai助手 -->
    <yxtbiz-ai-robot v-if="showAiDialog" @close="showAiDialog = false" />
  </div>
</template>

<script>
import Navigation from './components/navigation';
import Contents from './components/contents';
import { getSidebarMenu, getChatSetting, getOrgInfo } from './service';
import { enmuFunctions } from '../../function-points';
import { checkTimeOutFnc } from '../../common-util/getFactor';
import commonUtil from 'yxt-biz-pc/packages/common-util';

export default {
  name: 'YxtbizSupportSidebar',
  components: {
    Navigation,
    Contents
  },
  props: {
    functionCode: String,
    isExistTopNav: {
      default: false,
      type: Boolean
    },
    // 是否显示小型导航
    tinyNav: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showCustomService: true,
      drawer: false,
      showNav: false,
      hotIssues: [],
      issues: [],
      tutorials: [],
      navigations: [],
      active: '',
      offsetTop: 56,
      scrollStyle: {
        width: '280px',
        height: 0
      },
      isShowHotline: window.localStorage.sourceCode !== '101', // 是否显示服务热线，中欧渠道不显示
      isTieFinance: window.localStorage.sourceCode === '105', // 是否是领带金融渠道
      showAi: false, // ai展示
      showAiDialog: false // ai对话框展示
    };
  },
  computed: {
    // 在线客服入口满足上架+购买+未过期就是显示，其余的就隐藏
    onlineServiceShow() {
      return checkTimeOutFnc(enmuFunctions.ONLINE_SERVICE) === 2;
    },
    showSupport() {
      return checkTimeOutFnc(enmuFunctions.SUPPORT_CENTER_SWITCH) === 2;
    }
  },
  mounted() {
    this.initCustomerService();
    // 初始化ai助手
    this.initAiRobot();
  },
  methods: {
    // 侧边栏打开时处理的业务
    handleOpen() {
      // 增加滚动监听
      if (this.isExistTopNav) {
        this.calcOffsetTop();
        window.addEventListener('scroll', this.calcOffsetTop);
      }
      getSidebarMenu(this.functionCode).then(res => {
        const { hotIssues, issues, tutorials, navigations } = res;
        if (!issues && !tutorials) {
          this.showNav = true;
          this.active = '';
        } else {
          this.showNav = false;
          if (tutorials.length > 0) {
            this.active = tutorials[0].id;
          } else if (issues.length > 0) {
            this.active = issues[0].id;
          }
        }
        this.hotIssues = hotIssues;
        this.issues = issues;
        this.tutorials = tutorials;
        this.navigations = navigations;
      });
    },
    // 显示侧边栏
    handleShowDrawer() {
      this.drawer = true;
    },
    // 关闭侧边栏
    handleCloseDrawer() {
      this.drawer = false;
      // 取消监听
      if (this.isExistTopNav) {
        window.removeEventListener('scroll', this.calcOffsetTop);
      }
    },
    // 初始化客服
    initCustomerService() {
      const operateLevel = window.localStorage.getItem('operateLevel') || ''; // 客户档案运营分级(CSM),S/A/B/C
      const batchId = window.localStorage.getItem('batchId') || ''; // 批次id,v5.1~v5.2期间临时使用: 取值参考: a001,a002
      if (!operateLevel && !batchId) {
        getOrgInfo(localStorage.getItem('orgId'))
          .then(res => {
            res.operateLevel &&
              localStorage.setItem('operateLevel', res.operateLevel);
            res.batchId && localStorage.setItem('batchId', res.batchId);
            this.initZC(res.operateLevel, res.batchId);
          })
          .catch(() => {
            this.initZC(operateLevel, batchId);
          });
      } else {
        this.initZC(operateLevel, batchId);
      }
    },
    initZC(operateLevel, batchId) {
      (function(w, d, e, x) {
        w[e] = function() {
          w.cbk = w.cbk || [];
          w.cbk.push(arguments);
        };
        x = d.createElement('script');
        x.async = true;
        x.id = 'zhichiScript';
        x.className = 'support-customerService'; // 该class绑定到自定义客服入口按钮上 第一步
        x.src =
          'https://ten.sobot.com/chat/frame/v2/entrance.js?sysnum=511c0a484f704e5591b7338d069b3bc5&channelid=2';
        d.body.appendChild(x);
      })(window, document, 'zc');

      window.zc('config', {
        uname: window.localStorage.getItem('fullname') || '帮助中心用户', // 当前用户昵称
        realname: window.localStorage.getItem('username') || 'admin', // 当前用户登录名
        remark: '帮助中心', // 自定义备注
        source: 0,
        title_flag: 2,
        custom_title: '帮助中心客服平台', // 页面title
        custom: true, // 设置自定义生效 第二步
        partnerid: window.localStorage.getItem('userId'),
        params: JSON.stringify({
          level: operateLevel || '',
          p: batchId || '',
          orgid: window.localStorage.getItem('orgId') || ''
        })
      });
    },
    // 显示帮助中心
    handleShowCenter() {
      window.open(`${window.location.origin}/support/#/center`, '_blank');
    },
    // 获取选中对象
    handleSelect(val) {
      this.active = val;
    },
    calcOffsetTop() {
      let topMax = this.tinyNav ? 36 : 56;
      let scrollTop =
        window.pageXOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop <= topMax) {
        this.offsetTop = topMax - scrollTop;
      } else {
        this.offsetTop = 0;
      }
    },
    // 初始化ai助手
    async initAiRobot() {
      try {
        await commonUtil.preCheckFunctions(['ai_bob']);
        const isOpenAI = commonUtil.checkTimeOutFnc('ai_bob') === 2;
        if (isOpenAI) {
          const res = await getChatSetting();
          const { accessEnabled, assistEnabled, factorOpenFlag } = res;
          this.showAi = accessEnabled && assistEnabled && factorOpenFlag;
        }
      } catch (e) {}
    },
    // 打开ai界面
    openAi() {
      this.showAiDialog = true;
    }
  }
};
</script>
<style lang="scss" scoped>
.sidebar-ai {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 0;
  background: #ffffff;
  box-shadow: -6px 0px 8px -4px rgba(0, 0, 0, 0.12);
  border-radius: 4px 0px 0px 0px;
  &-icon {
    width: 16px;
    height: 16px;
  }
}
</style>
