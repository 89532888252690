var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "yxtf-popover",
    { attrs: { placement: "top", trigger: "hover", effect: "" } },
    [
      _c("div", [_vm._v(_vm._s(_vm.popText))]),
      _vm.iconName === "share"
        ? _c(
            "span",
            {
              attrs: { slot: "reference" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.$emit("click")
                },
              },
              slot: "reference",
            },
            [
              _c("yxt-svg", {
                staticClass: "color-gray-7 hover-primary-6 hand",
                attrs: {
                  "remote-url": _vm.svgUrl,
                  width: _vm.iconSize,
                  height: _vm.iconSize,
                  "icon-class": _vm.iconName,
                },
              }),
            ],
            1
          )
        : _vm.iconName === "close"
        ? _c(
            "span",
            {
              attrs: { slot: "reference" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.$emit("click")
                },
              },
              slot: "reference",
            },
            [
              _c("yxt-svg", {
                staticClass: "color-gray-7 hover-primary-6 hand",
                attrs: {
                  "remote-url":
                    "https://stc.yxt.com/ufd/407a24/assistent/pc/svg",
                  width: _vm.iconSize,
                  height: _vm.iconSize,
                  "icon-class": _vm.iconName,
                },
              }),
            ],
            1
          )
        : _vm.iconName === "fullscreen" || _vm.iconName === "window"
        ? _c(
            "span",
            {
              attrs: { slot: "reference" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.$emit("click")
                },
              },
              slot: "reference",
            },
            [
              _c("yxt-svg", {
                staticClass: "color-gray-7 hover-primary-6 hand",
                attrs: {
                  "remote-url":
                    "https://stc.yxt.com/ufd/407a24/assistent/h5/svg",
                  width: _vm.iconSize,
                  height: _vm.iconSize,
                  "icon-class": _vm.iconName,
                },
              }),
            ],
            1
          )
        : _c(
            "span",
            {
              attrs: { slot: "reference" },
              on: {
                click: function ($event) {
                  return _vm.$emit("click")
                },
              },
              slot: "reference",
            },
            [
              _c("yxt-svg", {
                staticClass: "color-gray-7 hover-primary-6 hand",
                attrs: {
                  "remote-url": _vm.svgUrl,
                  width: _vm.iconSize,
                  height: _vm.iconSize,
                  "icon-class": _vm.iconName,
                },
              }),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }