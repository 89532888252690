// 数据授权

export default {
  props: {
    // 导航功能权限
    functionCode: {
      type: String,
      default: ''
    },
    // 数据权限code
    dataPermissionCode: {
      type: String,
      default: ''
    },
    enableGroupCorp: {
      type: Boolean,
      default: false
    },
    visibleOrgIds: {
      type: Array,
      default: []
    },
    visibleOrgSelector: {
      type: Boolean,
      default: true
    },
    targetOrgId: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    };
  },
  methods: {
  }
};
