var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "article",
    {
      class: [
        "yxtbiz-ai-robot-guess-prompt",
        _vm.size === "small" ? "font-size-12" : "font-size-14",
      ],
      style: { width: _vm.size === "small" ? "100%" : "900px" },
    },
    [
      _c("simpleAnswer", { attrs: { msg: _vm.getMsg } }),
      !_vm.msg.isHistory && _vm.isShow
        ? [
            _c("header", { staticClass: "mt20" }, [
              _vm._v(
                _vm._s(
                  _vm.$t("pc_biz_lbl_guess_want_ask").d("猜测你可能想问：")
                )
              ),
            ]),
            _c("main", [
              _c(
                "ul",
                { staticClass: "yxtbiz-ai-robot-guess-prompt__examples" },
                _vm._l(_vm.getContent, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      class: [
                        "yxtbiz-ai-robot-guess-prompt__examples-item",
                        `yxtbiz-ai-robot-guess-prompt__examples-item--${_vm.size}`,
                      ],
                      on: {
                        click: function ($event) {
                          return _vm.handleExampleClick(item)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "ellipsis-2" }, [
                        _vm._v(
                          "\n            " + _vm._s(item) + "\n          "
                        ),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }