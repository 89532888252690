var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "yxtbiz-note__note-item-view color-gray-9",
      class: { "yxtbiz-note__note-item-view--light": _vm.effect === "light" },
    },
    [
      _vm._l(_vm.dataList, function (item, index) {
        return [
          item.type === 0
            ? _c("div", { key: `text-${index}` }, [
                _vm._v("\n      " + _vm._s(item.content) + "\n    "),
              ])
            : _c("div", { key: `mark-${index}` }, [
                _c(
                  "div",
                  {
                    staticClass: "yxtbiz-note__note-mark hover-bg-primary-6-i",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.toJump(item)
                      },
                    },
                  },
                  [
                    _c("yxtf-svg", {
                      staticClass: "color-gray-6 yxtbiz-flex-shrink-0",
                      attrs: {
                        "remote-url": `${_vm.$staticBaseUrl}ufd/407a24/kng/pc/svg`,
                        width: "16px",
                        height: "16px",
                        "icon-class": _vm.getIcon(item),
                      },
                    }),
                    _vm.getPositionText(item)
                      ? _c(
                          "yxt-popover",
                          {
                            attrs: {
                              trigger: "hover",
                              content: _vm.$t("pc_kng_note_tip_marker_jump"),
                              placement: "top",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "ml7 yxtbiz-flex-center",
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.getPositionText(item)) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.isInCourse
                      ? _c("div", { staticClass: "ellipsis ml8" }, [
                          _vm._v(_vm._s(item.kngName)),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }