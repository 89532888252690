var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yxtbiz-msg-editor-v2", class: { inline: _vm.formInline } },
    [
      _c("yxt-scrollbar", { staticStyle: { height: "100%" } }, [
        _c(
          "div",
          { staticClass: "yxtbiz-msg-editor-v2__body" },
          [
            _c(
              "div",
              {
                staticClass:
                  "yxtbiz-msg-editor-v2__title color-gray-10 text-center",
              },
              [_vm._v(_vm._s(_vm.title) + "\n      ")]
            ),
            _c(
              "yxt-form",
              {
                ref: "ruleForm",
                staticClass: "yxtbiz-msg-editor-v2__form",
                attrs: {
                  inline: _vm.formInline,
                  rules: _vm.rules,
                  model: _vm.ruleForm,
                  "label-position": "top",
                },
              },
              [
                _vm.checkStatus("fullname")
                  ? _c(
                      "yxt-form-item",
                      {
                        class: _vm.isInlineRight("fullname"),
                        attrs: {
                          prop: "fullname",
                          label: _vm.$t("pc_biz_core_lbl_name"),
                        },
                      },
                      [
                        _c("yxt-input", {
                          attrs: {
                            maxLength: "50",
                            placeholder: _vm.$t("pc_biz_core_tip_enterName"),
                          },
                          model: {
                            value: _vm.ruleForm.fullname,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "fullname", $$v)
                            },
                            expression: "ruleForm.fullname",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.checkStatus("mobile")
                  ? _c(
                      "yxt-form-item",
                      {
                        class: _vm.isInlineRight("mobile"),
                        attrs: {
                          prop: "mobile",
                          label: _vm.$t("pc_biz_core_lbl_phoneNumber"),
                        },
                      },
                      [
                        _c("yxt-input", {
                          attrs: {
                            maxLength: "11",
                            disabled: _vm.mobileDisabled,
                            placeholder: _vm.$t(
                              "pc_biz_core_tip_enterPhoneNumber"
                            ),
                          },
                          model: {
                            value: _vm.ruleForm.mobile,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "mobile", $$v)
                            },
                            expression: "ruleForm.mobile",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.checkStatus("city")
                  ? _c(
                      "yxt-form-item",
                      {
                        class: _vm.isInlineRight("city"),
                        attrs: {
                          prop: "city",
                          label: _vm.$t("pc_biz_core_lbl_city"),
                        },
                      },
                      [
                        _c("yxtbiz-area-select", {
                          ref: "city",
                          attrs: {
                            placeholder: _vm.$t("pc_biz_core_lbl_city"),
                            value: _vm.defaultCity,
                          },
                          on: { change: _vm.changeCity },
                          model: {
                            value: _vm.ruleForm.city,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "city", $$v)
                            },
                            expression: "ruleForm.city",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.checkStatus("company")
                  ? _c(
                      "yxt-form-item",
                      {
                        class: _vm.isInlineRight("company"),
                        attrs: {
                          prop: "company",
                          label: _vm.$t("pc_biz_core_lbl_company"),
                        },
                      },
                      [
                        _c("yxt-input", {
                          attrs: {
                            maxLength: "50",
                            placeholder: _vm.$t("pc_biz_core_tip_enterCompany"),
                          },
                          model: {
                            value: _vm.ruleForm.company,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "company", $$v)
                            },
                            expression: "ruleForm.company",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.checkStatus("department")
                  ? _c(
                      "yxt-form-item",
                      {
                        class: _vm.isInlineRight("department"),
                        attrs: {
                          prop: "department",
                          label: _vm.$t("pc_biz_core_lbl_department"),
                        },
                      },
                      [
                        _c("yxt-input", {
                          attrs: {
                            maxLength: "50",
                            placeholder: _vm.$t(
                              "pc_biz_core_tip_enterDepartment"
                            ),
                          },
                          model: {
                            value: _vm.ruleForm.department,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "department", $$v)
                            },
                            expression: "ruleForm.department",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.checkStatus("position")
                  ? _c(
                      "yxt-form-item",
                      {
                        class: _vm.isInlineRight("position"),
                        attrs: {
                          prop: "position",
                          label: _vm.$t("pc_biz_core_lbl_position"),
                        },
                      },
                      [
                        _c("yxt-input", {
                          attrs: {
                            maxLength: "50",
                            placeholder: _vm.$t(
                              "pc_biz_core_tip_enterPosition"
                            ),
                          },
                          model: {
                            value: _vm.ruleForm.position,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "position", $$v)
                            },
                            expression: "ruleForm.position",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.checkStatus("jobNo")
                  ? _c(
                      "yxt-form-item",
                      {
                        class: _vm.isInlineRight("jobNo"),
                        attrs: {
                          prop: "jobNo",
                          label: _vm.$t("pc_biz_core_lbl_userNo"),
                        },
                      },
                      [
                        _c("yxt-input", {
                          attrs: {
                            maxLength: "50",
                            placeholder: _vm.$t("pc_biz_core_tip_enterUserNo"),
                          },
                          model: {
                            value: _vm.ruleForm.jobNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "jobNo", $$v)
                            },
                            expression: "ruleForm.jobNo",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.checkStatus("email")
                  ? _c(
                      "yxt-form-item",
                      {
                        class: _vm.isInlineRight("email"),
                        attrs: {
                          prop: "email",
                          label: _vm.$t("pc_biz_core_lbl_mail"),
                        },
                      },
                      [
                        _c("yxt-input", {
                          attrs: {
                            maxLength: "50",
                            placeholder: _vm.$t("pc_biz_core_tip_enterMail"),
                          },
                          model: {
                            value: _vm.ruleForm.email,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "email", $$v)
                            },
                            expression: "ruleForm.email",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.checkStatus("idNo")
                  ? _c(
                      "yxt-form-item",
                      {
                        class: _vm.isInlineRight("idNo"),
                        attrs: {
                          prop: "idNo",
                          label: _vm.$t("pc_biz_core_lbl_identitycard"),
                        },
                      },
                      [
                        _c("yxt-input", {
                          attrs: {
                            maxLength: "50",
                            placeholder: _vm.$t(
                              "pc_biz_core_tip_enterIdentitycard"
                            ),
                          },
                          model: {
                            value: _vm.ruleForm.idNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "idNo", $$v)
                            },
                            expression: "ruleForm.idNo",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.checkStatus("sex")
                  ? _c(
                      "yxt-form-item",
                      {
                        class: _vm.isInlineRight("sex"),
                        attrs: {
                          prop: "sex",
                          label: _vm.$t("pc_biz_core_lbl_gender"),
                        },
                      },
                      [
                        _c(
                          "yxt-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: _vm.$t("pc_biz_core_tip_entersex"),
                            },
                            model: {
                              value: _vm.ruleForm.sex,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "sex", $$v)
                              },
                              expression: "ruleForm.sex",
                            },
                          },
                          [
                            _c("yxt-option", {
                              attrs: {
                                label: _vm.$t("pc_biz_core_lbl_female"),
                                value: "2",
                              },
                            }),
                            _c("yxt-option", {
                              attrs: {
                                label: _vm.$t("pc_biz_core_lbl_male"),
                                value: "1",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "yxt-form-item",
                  { staticStyle: { "margin-top": "40px", width: "100%" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "text-center",
                        staticStyle: { "line-height": "normal" },
                      },
                      [
                        _c(
                          "yxt-button",
                          {
                            attrs: { type: "primary", size: "large" },
                            on: { click: _vm.confirm },
                          },
                          [_vm._v(_vm._s(_vm.$t("pc_biz_core_btn_determine")))]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }