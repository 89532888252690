<!-- 追踪项目 -->
<template>
  <article class="yxtbiz-ai-robot-track-project">
    <EmptyData v-if="projectList.length === 0" />
    <template v-else>
      <header :class="['color-gray-7', size === 'small' ? 'font-size-14 lh24' : 'font-size-16 lh28']">{{ $t('pc_biz_found_projects' /* 为您找到以下项目：*/)}}</header>
      <main>
        <ul>
          <li v-for="project in projectList" :key="project.projectId"  @click="handleProjectClick(project)" class="yxtbiz-ai-robot-track-project__item">
            <yxt-image
            class="yxtbiz-ai-robot-track-project__cover"
            :src="project.imageUrl || `${$staticBaseUrl}ufd/55a3e0/o2o/pc/other/project.png`"
            ></yxt-image>
            <div class="ml12 over-hidden">
              <div :class="[size === 'small' ? 'ellipsis-2 font-size-14 lh22' : 'ellipsis font-size-16  lh24', 'color-gray-10' ]"><yxt-tag :type="project.status === 2 ? '' : 'info'" class="mr8 yxtbiz-ai-robot-track-project__item-tag" size="mini">{{ filterProjectStatus(project.status) }}</yxt-tag>{{ project.name }}</div>
              <div :class="[size === 'small' ? 'mt4' : 'mt8', 'yxtbiz-flex-center lh20 color-gray-7 font-size-12']" >
                <div>{{$t('pc_biz_learner_num' /* 学员人数：{0} */, [project.stuCount])}}</div>
                <template v-if="size === 'large'">
                  <yxt-divider direction="vertical"></yxt-divider>
                  <div>{{$t('pc_biz_time' /* 时间：{0} */, [getDateTimeFormatted({startTime: project.startTime, endTime: project.endTime})])}}</div> 
                </template>
              </div>
              <div v-if="size === 'small'" class="mt2 lh20 color-gray-7 font-size-12">{{$t('pc_biz_time' /* 时间：{0} */, [getDateTimeFormatted({startTime: project.startTime, endTime: project.endTime})])}}</div>
            </div>
          </li>
        </ul>
        <ViewMoreButton v-if="count > 3" @click="handleViewMoreClick" />
      </main>
    </template>
  </article>
</template>

<script>
import ViewMoreButton from '../common/view-more-button';
import EmptyData from '../common/empty-data.vue';
import utils from 'yxt-biz-pc/packages/common-util/utils.js';
import propsMixin from '../common/props-mixin.js';
import qs from 'qs';

export default {
  name: 'TrackProject',
  components: {
    ViewMoreButton,
    EmptyData
  },
  mixins: [propsMixin],
  computed: {
    count() {
      const paging = this.datas.paging || {};
      return paging.count;
    },
    projectList() {
      return this.datas.datas || [];
    }
  },
  methods: {
    filterProjectStatus(status) {
      const aMap = {
        0: this.$t('pc_o2o_lbl_unpublished' /* 未发布 */),
        1: this.$t('pc_o2o_lbl_unpublished' /* 未发布 */),
        7: this.$t('pc_o2o_lbl_unpublished' /* 未发布 */),
        2: this.$t('pc_o2o_lbl_ongoing' /* 进行中 */),
        3: this.$t('pc_o2o_lbl_projectover' /* 已结束 */),
        4: this.$t('pc_o2o_lbl_hasfiled' /* 已归档 */)
      };
      return aMap[status];
    },
    getDateTimeFormatted: utils.getDateTimeFormatted,
    handleProjectClick(project) {
      const projectUrl = `${window.location.origin}/o2o/#/projectconsole?${qs.stringify({id: project.projectId})}`; // 项目详情地址
      window.open(projectUrl, '_blank');
    },
    handleViewMoreClick() {
      const aMapForProjectRange = {
        1: 2, // 我创建的
        2: 1, // 我负责的
        3: 3 // 我管辖的
      };

      const {projectName, projectRange} = this.params;
      const projectListUrl = `${window.location.origin}/o2o/#/traincenter/responsible?${qs.stringify({
        keyword: projectName,
        projectRange: aMapForProjectRange[projectRange]
      })}`;
      window.open(projectListUrl, '_blank');
    }
  }
};
</script>

<style lang="scss" scoped>
.yxtbiz-ai-robot-track-project {

  &__cover {
    width: 112px;
    height: 64px;
    border-radius: 4px;
    flex-shrink: 0;
  }

  > header {
    padding-left: 12px;
    margin-bottom: 12px;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 12px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background: #F4F5F6;
    }
  }

  &__item-tag {
    vertical-align: text-top; 
  }
}

</style>