<template>
  <div>
    <yxt-row type='flex' class="yxt-user-selector-container yxt-user-multi-container">
      <div class="yxtbiz-user-search-wrap">
        <yxtbiz-group-org-select 
          :functionCode="functionCode"
          :disabled="disabled"
          :setCurrentOrg="true"
          size="small"
          class='mr12'
          v-show="visibleOrgSelector"
          :targetOrgId="targetOrgId"
          :dataPermissionCode="dataPermissionCode"
             @change="handleOrgChange" :selectFirst='true' :clearable="false" v-if='enableGroupCorp' :visibleOrgIds="visibleOrgIds"></yxtbiz-group-org-select>
        <select-popover size="small" class='mr12' ref="selectPopover" :selectOpition="selectOpition" >
          <div class="table_tree" >
            <user-group-tree
              v-if='(enableGroupCorp && postData.targetOrgId) || !enableGroupCorp'
              :targetOrgId='postData.targetOrgId'
              :enableGroupCorp='enableGroupCorp'
            :functionCode="functionCode"
            :dataPermissionCode="dataPermissionCode"
            @nodeClick="selectGroupCatalog"
            :filterable="true"
            ref="userGroupTree"
            ></user-group-tree>
          </div>
        </select-popover>
        <yxt-input
          :placeholder="$t('pc_biz_udp_tip_searchGroup')"
          v-model="groupValue"
          style="width:240px"
          size="small"
          searchable
          @search="searchGroup"
        >
        </yxt-input>
      </div>
        <yxt-table
          :ref="refTable"
          @select-all="selectAllGroups"
          @select="selectGroup"
          v-loading="loading"
          :height="tableHeight"
          class="mt16 yxt-user-table"
          :row-key="(row) => row.id"
          :data="userGroup"
        >
          <template slot="empty">
            <div style="line-height:normal">
              <yxt-empty />
            </div>
          </template>
          <!-- 表格单多选 -->
          <yxt-table-column
            v-if="isSelectPerson"
            :show-overflow-tooltip="false"
            class-name="yxtbiz-table-selection"
            type="selection"
            width="42"
            reserve-selection
            clear-padding="right"
          ></yxt-table-column>
          <yxt-table-column
            v-else
            :show-overflow-tooltip="false"
            width="40"
          >
            <template slot-scope="scope">
              <yxt-radio v-model="singleSelect" @change="selectGroup(scope.row.id, scope.row)" :label="scope.row.id"></yxt-radio>
            </template>
          </yxt-table-column>
          <yxt-table-column
            width="18"
            align="right"
            clear-padding="left-right"
            v-if="multiple"
            >
            <template slot="header">
              <yxt-dropdown placement="bottom-start" @command="tableHeaderCommand">
                  <span> </span>
                  <yxt-dropdown-menu slot="dropdown">
                    <yxt-dropdown-item command="selectCurrent">{{$t('pc_biz_enroll_select_current_page').d('选择本页')}}</yxt-dropdown-item>
                    <yxt-dropdown-item command="selectAll" >{{$t('pc_biz_enroll_select_all').d('选择全部')}}</yxt-dropdown-item>
                    <yxt-dropdown-item command="clearSelect" >{{$t('pc_biz_enroll_clear_all').d('清空所选')}}</yxt-dropdown-item>
                  </yxt-dropdown-menu>
              </yxt-dropdown>
            </template>
          </yxt-table-column>
          <yxt-table-column :show-overflow-tooltip="true" :label="$t('biz_udp_usergroup')" prop="name">
            <template slot-scope="scope">
              <span>{{scope.row.name}}</span>
              <yxt-tag
                v-if="scope.row.userGroupType === 1"
                class="ml8"
                effect="dark"
                size="mini">
                {{$t('pc_biz_ls_group').d('集团')}}
              </yxt-tag>
            </template>
          </yxt-table-column>
          <yxt-table-column
            :label="$t('pc_biz_udp_lbl_description')"
            :show-overflow-tooltip="true"
            prop="remark"
            :formatter="formatRemark"
          ></yxt-table-column>
        </yxt-table>
        <yxt-row type='flex' align='middle' class="yxtbiz-user-selector-mask mt16">
          <yxt-row class="col-flex-1" type='flex' align='middle'>
          </yxt-row>
          <yxt-pagination
            @size-change="sizeChange"
            @current-change="pageChange"
            :current-page.sync="page"
            :page-size="postData.limit"
            simple-total
            layout="total, prev, pager, next"
            :total="count"
            :pager-count="5"
          ></yxt-pagination>
        </yxt-row>
    </yxt-row>
  </div>
</template>

<script>
import { getUserGroupList } from '../service';
import userGroupTree from '../../../user-group-tree';
import resizeTable from '../../mixins/resizeTable';
import SelectPopover from '../components/SelectPopover';
import { Table, TableColumn, Pagination, Empty } from 'yxt-pc';
import { i18n } from 'yxt-biz-pc/packages/common-util/changeLangs';
import commonMixin from '../../mixins/common';
import SelectorPropsMixin from '../../mixins/selector-props';
import { enmuFunctions } from '../../../function-points';
import { getFunStatus } from '../../../common-util/factorUtils';
export default {
  components: {
    userGroupTree,
    SelectPopover,
    YxtTable: Table,
    YxtTableColumn: TableColumn,
    YxtPagination: Pagination,
    YxtEmpty: Empty
  },
  mixins: [resizeTable, commonMixin, SelectorPropsMixin],
  props: {
    type: {
      type: String,
      default: 'userGroup'
    },
    scope: {
      type: Number
    },
    isPerson: {
      type: Boolean,
      default: false
    },
    filterDynamicData: {
      // 是否过滤掉动态用户组数据
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      refTable: 'groupTable',
      isSearchAll: false,
      postData: {
        catalogId: '',
        scope: this.scope === -1 ? 2 : 0,
        name: '',
        limit: 20,
        offset: 0
      },
      selectOpition: {
        placeholder: i18n.t('biz_udp_usergroup_select'),
        checkWord: ''
      },
      count: 0,
      tableHeight: 0,
      groupValue: '',
      selectedGroups: {},
      userGroup: [],
      loading: true,
      totalPage: 0,
      page: 1,
      formatRemark: row => {
        return row.remark || '--';
      }
    };
  },
  watch: {
    selectData: {
      deep: true,
      handler() {
        this.performanceUpateSelected('userGroup');
        this.userGroup = [...this.userGroup];
      }
    }
  },
  created() {
    if (!this.enableGroupCorp || this.targetOrgId) {
      if (this.targetOrgId) this.postData.targetOrgId = this.targetOrgId;
      this.getData();
    }
  },
  computed: {
    showRelation() {
      // 是否显示动态用户组
      return getFunStatus(enmuFunctions.UDP_DYNAMIC_USERGROUP).enabled;
    },
    isSelectPerson() {
      // 是否是按用户组选人，是的话，只有多选没有单选
      if (this.isPerson) {
        return true;
      }
      return this.multiple;
    }
  },
  methods: {
    tableHeaderCommand(row) {
      this[row]();
    },
    selectCurrent() {
      // 选择本页
      this.selectCurrData('userGroup');
    },
    selectAll() {
      // 选择所有
      this.loadAll();
    },
    clearSelect() {
      // 清空所选
      this.$refs[this.refTable].clearSelection();
      this.$emit('change', [], 'userGroup');
    },
    handleOrgChange(data) {
      this.targetOrgName = data.orgName;
      this.postData.targetOrgId = data.orgId;
      this.postData.catalogId = '';
      this.selectOpition.checkWord = '';
      this.groupValue = '';
      this.$emit('org-change', data);
      this.getData();
    },
    formatGroups(groups) {
      this.selectedGroups = {};
      groups.forEach(group => {
        this.selectedGroups[group.id] = group;
      });
    },
    getData() {
      this.loading = true;
      let type = this.showRelation ? -1 : 0; // -1 查询普通与动态用户组，0 只查询普通用户组
      if (this.filterDynamicData) type = 0;
      if (this.isPerson) {
        this.postData.dataPermissionCode = this.dataPermissionCode;
        this.postData.navCode = this.functionCode;
      }
      getUserGroupList(this.postData, type)
        .then(result => {
          this.userGroup = result.datas;
          this.userGroup.forEach(group => {
            group.nodeType = this.type;
            group.orgId = this.postData.targetOrgId;
            group.orgName = this.targetOrgName;
          });
          this.totalPage = result.paging.pages;
          this.count = result.paging.count;
          this.loading = false;
          this.performanceUpateSelected('userGroup');
        })
        .catch(e => {
          this.userGroup = [];
          this.loading = false;
        });
    },
    searchGroup() {
      this.postData.name = this.groupValue;
      this.postData.offset = 0;
      this.page = 1;
      this.getData();
    },
    selectGroup(items, row) {
      // 选中数据
      if (Array.isArray(items)) {
        // 多选
        this.performanceSelectData(items, 'userGroup');
      } else {
        this.$emit('change', [row], 'userGroup');
      }
    },
    selectAllGroups(items) {
      this.performanceSelectData(items, 'userGroup');
    },
    async loadAll() {
      this.loading = true;
      const limit = 500;
      const reqCount = Math.ceil(this.count / limit); // 循环次数
      const reqArrs = []; // 请求数组
      let type = this.showRelation ? -1 : 0; // -1 查询普通与动态用户组，0 只查询普通用户组
      if (this.filterDynamicData) type = 0;
      for (let i = 0; i < reqCount; i++) {
        const params = Object.assign({}, this.postData, {
          limit: limit,
          offset: limit * i
        });
        if (this.isPerson) {
          params.dataPermissionCode = this.dataPermissionCode;
          params.navCode = this.functionCode;
        }
        reqArrs.push(params);
      }
      try {
        const results = await Promise.all(
          reqArrs.map(params => getUserGroupList(params, type))
        );
        const dataSource = [];
        results.forEach(res => {
          dataSource.push(...res.datas);
        });
        let data = dataSource.map(item => {
          item['_type'] = 2;
          item.orgId = this.postData.targetOrgId;
          item.orgName = this.targetOrgName;
          return item;
        });
        this.selectAllData(data, 'userGroup');
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    sizeChange(size) {
      this.postData.limit = size;
      this.page = 1;
      this.postData.offset = 0;
      this.getData();
    },
    pageChange(page) {
      this.page = page;
      this.postData.offset = (page - 1) * this.postData.limit;
      this.getData();
    },
    selectGroupCatalog(item) {
      this.postData.catalogId = item.id;
      this.selectOpition.checkWord = item.id ? item.name : '';
      this.$refs['selectPopover'].cancel();
      this.searchGroup();
    }
  }
};
</script>

<style lang="scss" scoped></style>
