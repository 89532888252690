var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "yxtbiz-ai-robot-demo__dialog" }, [
    _c("div", { staticClass: "yxtbiz-ai-robot-demo__dialog--header" }, [
      _c(
        "div",
        { staticClass: "yxtbiz-ai-robot-demo__dialog--header-left" },
        [
          !_vm.bFullscreen && _vm.settings.assistHeadImg
            ? _c("yxtf-portrait", {
                staticClass: "mr12",
                attrs: {
                  size: "small",
                  username: "AI",
                  imgUrl: _vm.settings.assistHeadImg,
                },
              })
            : _vm._e(),
          _c("span", [_vm._v(_vm._s(_vm.title))]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "yxtbiz-ai-robot-demo__dialog--header-right" },
        [
          _c(
            "yxtf-popconfirm",
            {
              attrs: {
                title: _vm
                  .$t("pc_biz_ai_log_clear_confirm")
                  .d("确定清空此对话消息记录？"),
                "hide-icon": "",
              },
              on: { confirm: _vm.clearHistory },
            },
            [
              _c("header-icon", {
                attrs: {
                  slot: "reference",
                  size: _vm.iconSize,
                  "icon-name": "clear",
                  "pop-text": _vm.$t("pc_biz_ai_clear_log").d("清空记录"),
                  remote: "",
                },
                slot: "reference",
              }),
            ],
            1
          ),
          _c("header-icon", {
            attrs: {
              size: _vm.iconSize,
              "icon-name": "share",
              "pop-text": _vm.$t("pc_biz_ai_share").d("分享"),
              remote: "",
            },
            on: { click: _vm.share },
          }),
          _vm.showShare
            ? _c("Share", { attrs: { bFullscreen: _vm.bFullscreen } })
            : _vm._e(),
          !_vm.max
            ? [
                _c("header-icon", {
                  attrs: {
                    remote: "",
                    size: _vm.iconSize,
                    "icon-name": _vm.bFullscreen ? "downsize" : "fullscreen",
                    "pop-text": _vm.bFullscreen
                      ? _vm.$t("pc_biz_ai_small_window").d("小窗口")
                      : _vm.$t("pc_biz_ai_full_screen").d("全屏"),
                  },
                  on: { click: _vm.changeFullSize },
                }),
                _c("header-icon", {
                  attrs: {
                    remote: "",
                    size: _vm.iconSize + 2,
                    "icon-name": "close",
                    "pop-text": _vm.$t("pc_biz_ai_close").d("关闭"),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]),
    _c(
      "div",
      {
        staticClass: "yxtbiz-ai-robot-demo__dialog--body",
        style: _vm.aiBodyStyle,
      },
      [
        _c(
          "yxtf-scrollbar",
          { ref: "scrollbar", attrs: { "fit-height": true } },
          [
            _c(
              "ul",
              { ref: "dialogBody", staticClass: "is-msg-list" },
              [
                _c("li", [
                  _c(
                    "div",
                    {
                      staticClass:
                        "yxtbiz-ai-robot-demo__dialog--msg is-answer",
                      class: { fullscreen: _vm.bFullscreen },
                    },
                    [
                      _vm.bFullscreen
                        ? _c(
                            "div",
                            { staticClass: "mr12" },
                            [
                              _c("yxtf-portrait", {
                                attrs: {
                                  size: "28px",
                                  "img-url":
                                    "https://stc.yxt.com/ufd/407a24/assistent/pc/img/avatar.jpg",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("div", { staticClass: "is-content" }, [
                        _c("div", { staticClass: "font-size-16" }, [
                          _vm._v("你好，我是Talent Ai, 很高兴为你服务~"),
                        ]),
                        _c("div", { staticClass: "mt12 font-size-16" }, [
                          _vm._v("你可以试着这样问我："),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "mt8",
                            class: { "welcome-group": _vm.bFullscreen },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "welcome-item hand",
                                on: {
                                  click: function ($event) {
                                    return _vm.quickSend(0)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "○ 销售总监岗位中达到高准备度的继任员工占比"
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "welcome-item hand",
                                on: {
                                  click: function ($event) {
                                    return _vm.quickSend(1)
                                  },
                                },
                              },
                              [_vm._v("○ 销售管理部的继任情况")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "welcome-item hand",
                                on: {
                                  click: function ($event) {
                                    return _vm.quickSend(2)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "○ 拥有集团及事业部复合经验标签的员工有哪些"
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]),
                _vm._l(_vm.msgList, function (msg, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      class: {
                        "yxtbiz-ai-robot-demo__dialog--msg-question": msg.type,
                      },
                    },
                    [
                      _c("Message", {
                        attrs: {
                          msg: msg,
                          isWaitAnswer: _vm.isWaitAnswer,
                          fullscreen: _vm.bFullscreen,
                        },
                        on: {
                          feedback: function ($event) {
                            return _vm.showFeedbackDialog(index)
                          },
                          resend: (val) => _vm.resend(msg, val, index),
                        },
                      }),
                    ],
                    1
                  )
                }),
              ],
              2
            ),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { ref: "aiFooter", staticClass: "yxtbiz-ai-robot-demo__dialog--footer" },
      [
        _c("dropLine", { on: { drag: _vm.onDrag, start: _vm.startDrag } }),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.question,
              expression: "question",
            },
          ],
          ref: "textarea",
          style: _vm.textareaStyle,
          attrs: {
            maxlength: "2000",
            placeholder: _vm
              .$t("pc_biz_ai_input_problem")
              .d("请输入您要咨询的问题"),
          },
          domProps: { value: _vm.question },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.question = $event.target.value
              },
              _vm.resizeTextarea,
            ],
            keydown: _vm.keydown,
          },
        }),
        _c("div", { staticClass: "yxtbiz-ai-robot-demo__dialog--footer-btn" }, [
          _vm.question
            ? _c(
                "div",
                {
                  class: {
                    "ai-robot-btn-send": true,
                    "disbale-send": _vm.isWaitAnswer,
                  },
                  on: { click: _vm.sendMsg },
                },
                [
                  _c("img", {
                    attrs: {
                      src: "https://stc.yxt.com/ufd/407a24/assistent/pc/svg/send.svg",
                    },
                  }),
                ]
              )
            : _vm._e(),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }