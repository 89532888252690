<template>
  <div class="yxt-biz-relationDynamicUserGroup">
    <yxt-row
      type="flex"
      class="yxt-user-selector-container yxt-user-multi-container"
    >
      <div style="margin-bottom: 20px;"><yxt-alert :title="relationTips" type="info" show-icon /></div>
      <div class="yxtbiz-user-search-wrap">
        <yxtbiz-group-org-select
          :functionCode="functionCode"
          :disabled="disabled"
          :setCurrentOrg="true"
          :targetOrgId="targetOrgId"
          :dataPermissionCode="dataPermissionCode"
          @change="handleOrgChange"
          :selectFirst="true"
          :clearable="false"
          size="small"
          v-if="enableGroupCorp"
          v-show="visibleOrgSelector"
          :visibleOrgIds="visibleOrgIds"
        ></yxtbiz-group-org-select>
        <select-popover
          class="ml12"
          ref="selectPopover"
          size="small"
          :selectOpition="selectOpition"
        >
          <div class="table_tree">
            <user-group-tree
              v-if="
                (enableGroupCorp && postData.targetOrgId) || !enableGroupCorp
              "
              :targetOrgId="postData.targetOrgId"
              :enableGroupCorp="enableGroupCorp"
              :functionCode="functionCode"
              :dataPermissionCode="dataPermissionCode"
              @nodeClick="selectGroupCatalog"
              :filterable="true"
              ref="userGroupTree"
            ></user-group-tree>
          </div>
        </select-popover>
        <yxt-input
          size="small"
          :placeholder="$t('pc_biz_udp_tip_onlysearchGroup')"
          v-model="groupValue"
          style="width:240px"
          class="ml12"
          searchable
          @search="searchGroup"
        >
        </yxt-input>
      </div>
      <yxt-table
        :ref="refTable"
        v-loading="loading"
        :height="tableHeight"
        class="mt16 yxt-user-table"
        :data="usergroups"
      >
        <template slot="empty">
          <div style="line-height:normal">
            <yxt-empty />
          </div>
        </template>
        <yxt-table-column
          :show-overflow-tooltip="false"
          width="40"
        >
          <template slot-scope="scope">
            <yxt-radio v-model="singleSelect" @change="handleSlect(scope.row.id, scope.row)" :label="scope.row.id"></yxt-radio>
          </template>
        </yxt-table-column>
        <yxt-table-column
          :show-overflow-tooltip="true"
          :label="$t('biz_udp_usergroup')"
          prop="name"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
            <yxt-tag
              v-if="scope.row.userGroupType === 1"
              class="ml8"
              effect="dark"
              size="mini"
            >
              {{ $t('pc_biz_ls_group').d('集团') }}
            </yxt-tag>
          </template>
        </yxt-table-column>
        <yxt-table-column
          :label="$t('pc_biz_udp_lbl_description')"
          :show-overflow-tooltip="true"
          prop="remark"
          :formatter="formatRemark"
        ></yxt-table-column>
        <!-- <yxt-table-column
          :label="$t('pc_biz_ote_btn_operate').d('操作')"
          width="100"
        >
          <template slot-scope="scope">
            <yxt-button type="text" @click="handleSlect(scope.row)">{{
              currentSelect.id === scope.row.id ? '取消选择' : '选择'
            }}</yxt-button>
          </template>
        </yxt-table-column> -->
      </yxt-table>
      <yxt-row
        type="flex"
        align="middle"
        class="yxtbiz-user-selector-mask mt16"
      >
        <yxt-row class="col-flex-1" type='flex' align='middle'></yxt-row>
        <yxt-pagination
          @size-change="sizeChange"
          @current-change="pageChange"
          :current-page.sync="page"
          :page-size="postData.limit"
          simple-total
          layout="total, prev, pager, next"
          :total="count"
          :pager-count="5"
        ></yxt-pagination>
      </yxt-row>
    </yxt-row>
  </div>
</template>

<script>
import { getUserGroupList } from '../service';
import userGroupTree from '../../../user-group-tree';
import resizeTable from '../../mixins/resizeTable';
import SelectPopover from '../components/SelectPopover';
import { Table, TableColumn, Pagination, Empty } from 'yxt-pc';
import { i18n } from 'yxt-biz-pc/packages/common-util/changeLangs';
import groupMixin from '../../mixins/group';
import commonMixin from '../../mixins/common';
export default {
  components: {
    userGroupTree,
    SelectPopover,
    YxtTable: Table,
    YxtTableColumn: TableColumn,
    YxtPagination: Pagination,
    YxtEmpty: Empty
  },
  mixins: [resizeTable, groupMixin, commonMixin],
  props: {
    value: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'relationDynamicUserGroup'
    },
    scope: {
      type: Number
    },
    relationTips: {
      type: String,
      default: ''
    },
    functionCode: {
      type: String,
      default: ''
    },
    dataPermissionCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentSelect: {}, // 当前选中的动态用户组信息
      refTable: 'groupTable',
      isSearchAll: false,
      postData: {
        navCode: this.functionCode,
        dataPermissionCode: this.dataPermissionCode,
        catalogId: '',
        scope: this.scope === -1 ? 2 : 0,
        name: '',
        limit: 20,
        offset: 0
      },
      selectOpition: {
        placeholder: i18n.t('biz_udp_usergroup_select'),
        checkWord: ''
      },
      count: 0,
      tableHeight: 0,
      groupValue: '',
      selectedGroups: {},
      usergroups: [],
      loading: true,
      totalPage: 0,
      page: 1,
      formatRemark: row => {
        return row.remark || '--';
      }
    };
  },
  created() {
    if (!this.relationTips) {
      // 处理 relationTips 没传时的问题
      throw new Error('关联用户组组件属性relationTips为必填参数，请填写！');
    }
    if (!this.enableGroupCorp || this.targetOrgId) {
      if (this.targetOrgId) this.postData.targetOrgId = this.targetOrgId;
      this.getData();
    }
    this.updateSelected(this.selectData.relationDynamicUserGroup);
  },
  watch: {
    selectData: {
      deep: true,
      handler(result) {
        this.$refs[this.refTable].clearSelection();
        console.log(result, 'selected-selectedselected-selected');
        const relationDynamicUserGroup = [...result.relationDynamicUserGroup];
        if (!relationDynamicUserGroup.length) {
          this.singleSelect = '';
        } else {
          this.updateSelected(result.relationDynamicUserGroup);
        };
      }
    }
  },
  methods: {
    updateSelected(selected) {
      if (selected.length) {
        this.singleSelect = selected[0].id;
      }
    },
    handleSlect(id, row) {
      this.$emit('change', [row], 'relationDynamicUserGroup');
    },
    handleOrgChange(data) {
      this.targetOrgName = data.orgName;
      this.postData.targetOrgId = data.orgId;
      this.postData.catalogId = '';
      this.selectOpition.checkWord = '';
      this.groupValue = '';
      this.$emit('org-change', data);
      this.getData();
    },
    getData() {
      this.loading = true;
      getUserGroupList(this.postData, 1)
        .then(result => {
          this.usergroups = result.datas;
          this.usergroups.forEach(group => {
            group.nodeType = this.type;
          });
          this.totalPage = result.paging.pages;
          this.count = result.paging.count;
          this.loading = false;
        })
        .catch(e => {
          this.usergroups = [];
          this.loading = false;
        });
    },
    searchAll() {
      let postData = Object.assign({}, this.postData, {
        limit: this.count,
        offset: 0
      });
      this.isSearchAll = true;
      getUserGroupList(postData, this.enableGroupCorp)
        .then(result => {
          this.isSearchAll = false;
          const list = result.datas;
          list.forEach(group => {
            group.nodeType = this.type;
            group.orgId = this.postData.targetOrgId;
            group.orgName = this.targetOrgName;
            this.selectedGroups[group.id] = group;
          });
        })
        .catch(e => {
          this.isSearchAll = false;
          this.$message({
            type: 'error',
            message: e
          });
        });
    },
    searchGroup() {
      this.postData.name = this.groupValue;
      this.postData.offset = 0;
      this.page = 1;
      this.getData();
    },
    sizeChange(size) {
      this.postData.limit = size;
      this.page = 1;
      this.postData.offset = 0;
      this.getData();
    },
    pageChange(page) {
      this.page = page;
      this.postData.offset = (page - 1) * this.postData.limit;
      this.getData();
    },
    selectGroupCatalog(item) {
      this.postData.catalogId = item.id;
      this.postData.offset = 0;
      this.page = 1;
      this.selectOpition.checkWord = item.id ? item.name : '';
      this.$refs['selectPopover'].cancel();
      this.getData();
    }
  }
};
</script>

<style lang="scss">
  .yxt-biz-relationDynamicUserGroup {
    .yxt-alert--info.is-light {
      background-color: #F0F6FF;
      color: #262626;
    }
    .yxt-alert__content {
      padding: 0 16px 0 24px;
    }
  }
</style>
