var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yxtbiz-dept-manager-tree" },
    [
      _c(
        "yxt-tree",
        _vm._g(
          _vm._b(
            {
              ref: "tree",
              attrs: {
                data: _vm.data,
                "node-key": "id",
                "wrap-width": "100%",
                "show-checkbox": _vm.showCheckbox,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ data, node }) {
                    return [
                      _c(
                        "span",
                        [
                          _c("yxtbiz-dept-name", {
                            attrs: { name: data.name },
                          }),
                          _vm.deptCountAvailable && data.parentId
                            ? _c("span", [
                                _vm._v("(" + _vm._s(data.userCount) + ")"),
                              ])
                            : _vm._e(),
                          [
                            data.manager === 1
                              ? _c(
                                  "yxt-tag",
                                  {
                                    staticClass: "ml12",
                                    attrs: { size: "mini" },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("biz_tag_director")))]
                                )
                              : _vm._e(),
                            data.manager === 2
                              ? _c(
                                  "yxt-tag",
                                  {
                                    staticClass: "ml12",
                                    attrs: { size: "mini", type: "info" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("biz_tag_parttime_manage"))
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                        ],
                        2
                      ),
                    ]
                  },
                },
              ]),
            },
            "yxt-tree",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }