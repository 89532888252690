export default {
  props: {
    datas: {
      type: [Object, Array],
      default: () => ({})
    }, // 对应接口返回字段，字段名称为对应业务的字段名称,页面渲染
    params: {
      type: Object,
      default: () => ({})
    }, // 用于跳转等功能带参数使用
    size: {
      type: String,
      default: 'small',
      validator: val => ['small', 'large'].includes(val)
    },
    mode: { // manage: 管理端 student: 学员端
      type: String,
      default: 'manage',
      validator: val => ['manage', 'student'].includes(val)
    }
  }
};
