<template>
  <div class="yxtbiz-sponsor-choose">
    <yxt-drawer
      :title="$t('pc_biz_enroll_tit_selectaudit').d('选择审核人')"
      size="480px"
      :visible.sync="isShowModal"
      v-bind="modal.options"
      :before-close="closeDrawer"
    >
      <div class="modal-content">
        <div class="color-gray-10 modal-content-title">{{ $t('pc_biz_common_tit_all_flow').d('审核全流程') }}</div>
        <div class="modal-content-flow">
          <!--发起节点-->
          <div class="flow-item">
            <div class="left">
              <div class="icon"></div>
              <div class="line"></div>
            </div>
            <div class="right">
              <div class="right-content">
                <span class="color-gray-10 flow-name">{{ $t('pc_biz_common_tip_start').d('发起') }}</span>
              </div>
            </div>
          </div>
          <!--部门经理-->
          <div v-for="(item,index) in nodeList" :key="index">
            <div v-if="getAuditStatus(item)" class="flow-item"
            >
              <div class="left">
                <div class="icon"></div>
                <div class="line"></div>
              </div>
              <div class="right ">
                <div class="right-content">
                  <div class="flow-name color-gray-7">{{ $t('pc_biz_common_tit_audit').d('审核') }}</div>
                  <div class="flow-person color-gray-9">{{ getAuditName(item) }}</div>
                </div>
              </div>
            </div>
            <!--选择成员-->
            <div v-if="item.auditPersType===4" class="flow-item">
              <div class="left">
                <div class="icon-choose">
                  <div class="icon-inner"></div>
                </div>
                <div class="line"></div>
              </div>
              <div class="right">
                <div class="right-content right-content-choose">
                  <div class="right-content-choose-inner bg-gray-1">
                    <div class="flow-name color-gray-7 flow-name-choose">{{ $t('pc_biz_common_tit_audit').d('审核') }}
                    </div>
                    <yxt-button v-if="!item.selectuser || !item.selectuser.length" type="second"
                                @click="openUserDialog(item)">{{ $t('pc_biz_common__select_user').d('选择人员') }}
                    </yxt-button>
                    <yxt-button v-if="item.selectuser && item.selectuser.length" type="second"
                                @click="openUserDialog(item)">{{ $t('pc_biz_common_re_select_user').d('重新选择') }}
                    </yxt-button>
                    <div
                      class="flow-select-user"
                    >
                      <yxt-tag
                        class="flow-select-user-item mb8"
                        v-for="innerItem in item.selectuser"
                        :key="innerItem.id"
                        closable
                        type="info"
                        @close="closeSelectItem(item,innerItem.id)"
                      >
                        <yxtbiz-user-name v-if="isOpenData" :name="innerItem.fullname" />
                        <span
                          v-else
                        >{{ innerItem.fullname }}</span>
                      </yxt-tag>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--已经选有人员-->
            <div v-if="item.auditPersType===3" class="flow-item" :key="item.id">
              <div class="left">
                <div class="icon">
                  <div class="icon-inner"></div>
                </div>
                <div class="line"></div>
              </div>
              <div class="right">
                <div class="right-content ">
                  <div class="flow-name color-gray-7 flow-name-choose">{{ $t('pc_biz_common_tit_audit').d('审核') }}</div>
                  <div class="person-list">
                    <template v-for="innerItem in item.appters">
                      <div class="person-list-item" :key="innerItem.userId">
                        <div>
                          <!--<img :src="innerItem.pic">-->
                          <!-- <yxt-avatar
                            class="user-icon"
                            size="small"
                            background-color="#436bff"
                            color="#fff"
                            :username="innerItem.userName"
                          ></yxt-avatar> -->
                           <yxtf-portrait  class="user-icon" size="small" :username="innerItem.userName"
                              :imgUrl="innerItem.pic"
                              ></yxtf-portrait>
                        </div>
                        <yxtbiz-user-name class="user-name color-gray-9" v-if="isOpenData" :name="innerItem.fullName" />
                        <span
                          v-else
                        >{{ innerItem.fullName }}</span>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--结束节点-->
          <div class="flow-item">
            <div class="left">
              <div class="icon"></div>
            </div>
            <div class="right">
              <div class="right-content">
                <span class="color-gray-10 flow-name">{{ $t('pc_biz_common_tip_end').d('结束') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
          <yxtf-button @click="closeDialog">{{ $t('pc_biz_common_cancel_mini').d('取消') }}</yxtf-button>
          <yxtf-button type="primary" @click="confirmModal">{{ $t('pc_biz_common_btn_sure').d('确定') }}</yxtf-button>
        </span>
    </yxt-drawer>


    <!--选人抽屉-->
    <yxt-drawer
      title=""
      :visible.sync="isShowUserDialog"
      size="960px"
    >
      <custom-user-selector v-if="isShowUserDialog" ref="selector" :selected="tableUsers" :customNodeId="customNodeId"
                            :multiple="multiple"></custom-user-selector>
      <!-- <yxtbiz-user-selector
        v-if="isShowUserDialog"
        ref="selector"
        :data.sync="users"
        :limit="20"
        :tabs="customTabLabel"
        function-code="audit_process_management"
        data-permission-code="audit_designated_members"
        @change="changeUser"
      >
        <custom-person-list slot="customTab" :value.sync="tableUsers" :customNodeId="customNodeId" :multiple="multiple"
                            @selectedChange="updateChange"></custom-person-list>
      </yxtbiz-user-selector> -->
      <div slot="footer">
        <yxt-button @click="closeUserDialog" plain>{{ $t('pc_biz_common_cancel_mini').d('取消') }}</yxt-button>
        <yxt-button type="primary" @click="getUsers">{{ $t('pc_biz_common_btn_sure').d('确定') }}</yxt-button>
      </div>
    </yxt-drawer>
  </div>
</template>

<script>
import 'yxt-biz-pc/packages/common-util/changeLangs';
import { getmplbaseinfo, selectUser, getprojmplbaseinfo, selectProjUser } from './service';
import CustomUserSelector from './components/customUserSelector';
import openDataMixin from 'yxt-biz-pc/packages/user-selector/mixins/openData';

export default {
  name: 'YxtbizSponsorChoose',
  data() {
    return {
      modal: {},
      isShowModal: false,
      tabs: ['persons'],
      customTabLabel: [{ label: this.$t('pc_biz_common__select_user').d('选择人员'), value: 'customTab' }],
      users: [],
      tableUsers: [],
      isShowUserDialog: false,
      nodeList: [],
      currentSelect: '',
      functionCode: '',
      dataPermissionCode: '',
      appters: [],
      customTab: false,
      currentSelectId: null,
      code: '', // 模板code
      customNodeId: '', // 打开选人组件传入当前节点id
      multiple: 0,
      projId: '', // 项目id
      isConfirmButton: false // 是否是点击确认按钮
    };
  },
  mixins: [openDataMixin],
  components: {
    CustomUserSelector
  },
  methods: {
    changeUser(val) {
      console.log(val);
      if (this.isChange) return (this.isChange = false);
      this.isChange = false;
      this.tableUsers = val;
    },
    updateChange(list) {
      this.isChange = true;
      this.tableUsers = list;
      this.users = list;
    },
    init(modal) {
      this.modal = Object.assign({}, modal);
      this.code = this.modal.props.code || '';
      this.projId = this.modal.props.projId || '';
      this.projId ? this.getprojmplbaseinfo() : this.getmplbaseinfo();
    },
    // 判断组件需不需要打开发起人自选
    getmplbaseinfo() {
      getmplbaseinfo(this.code).then(res => {
        if (res && res.selfSelect === 1) {
          this.selectUser();
        } else {
          this.modal.onSuccess([]);
        }
      }).catch((res) => {
        this.modal.onError(res);
      });
    },

    getprojmplbaseinfo() {
      getprojmplbaseinfo(this.code, this.projId).then(res => {
        if (res && res.selfSelect === 1) {
          this.selectProjUser();
        } else {
          this.modal.onSuccess([]);
        }
      }).catch((res) => {
        this.modal.onError(res);
      });
    },

    // 发起人自选数据
    selectUser() {
      selectUser(this.code).then(res => {
        this.nodeList = res.nodeList;
        this.open();
      }).catch((res) => {
        this.modal.onError(res);
      });
    },
    selectProjUser() {
      selectProjUser(this.code, this.projId).then(res => {
        this.nodeList = res.nodeList;
        this.open();
      }).catch((res) => {
        this.modal.onError(res);
      });
    },
    open() {
      this.isShowModal = true;
    },
    // 选人组件确认
    confirmModal() {
      // 未选择人员不允许提交
      let newNodeList = this.nodeList.filter(item => item.auditPersType === 4);
      if (newNodeList.find(i => !i.selectuser || !i.selectuser.length)) {
        return this.$message.warning(this.$t('pc_audit_tip_place_select_user').d('请选择人员'));
      }
      let arr = [];
      this.nodeList.map(item => {
        if (item.selectuser) {
          let ids = item.selectuser.map(item => item.id);
          arr.push({
            userIds: ids,
            nodeId: item.id
          });
        }
      });
      this.isShowModal = false;
      this.isConfirmButton = true;
      this.modal.onSuccess(arr);
    },
    openUserDialog(item) {
      this.currentSelect = item.id;
      this.customNodeId = item.id;
      this.tableUsers = item.selectuser || [];
      this.multiple = item.selType;	// 选择方式 0单选 1多选
      this.isShowUserDialog = true;
    },
    closeUserDialog() {
      this.isShowUserDialog = false;
    },
    // 获取选人
    getUsers() {
      const list = this.$refs.selector.getSelected();
      if (!list.length) return this.$message.warning(this.$t('pc_audit_tip_place_select_user').d('请选择人员'));
      if (list.length > 20) return this.$message.warning(this.$t('pc_audit_msg_usermax20').d('最多选择20个人员'));
      this.nodeList.map(item => {
        if (item.id === this.currentSelect) {
          this.$set(item, 'selectuser', list);
        }
      });
      this.isShowUserDialog = false;
    },
    getAuditStatus(item) {
      return item.auditPersType === 0 || item.auditPersType === 1 || item.auditPersType === 2;
    },
    getAuditName(item) {
      let nameMap = {
        0: this.$t('pc_biz_common__type_0').d('直属经理'),
        1: this.$t('pc_biz_common_type_1').d('部门经理'),
        2: this.$t('pc_biz_common_type_2').d('审核角色'),
        5: this.$t('pc_biz_common_tip_no_user').d('无需审核人')
      };
      return nameMap[item.auditPersType];
    },
    closeSelectItem(item, id) {
      for (let i in item.selectuser) {
        if (item.selectuser[i].id === id) {
          item.selectuser.splice(i, 1);
        }
      }
    },
    closeDialog() {
      this.isShowModal = false;
      this.modal.onCancel && this.modal.onCancel();
    },
    closeDrawer(done) {
      let findNode = this.nodeList.filter(item => item.auditPersType === 4).find(item=>item.selectuser && item.selectuser.length !== 0);
      if (findNode) {
        done(true);
        if (!this.isConfirmButton) {
          this.modal.onCancel && this.modal.onCancel();
        }
      } else {
        done(false);
        this.modal.onCancel && this.modal.onCancel();
      }
    }
  }
};
</script>
