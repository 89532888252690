var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "clickOutside",
          rawName: "v-clickOutside",
          value: _vm.cancel,
          expression: "cancel",
        },
      ],
      staticClass: "yxtbiz-select__wrapper",
    },
    [
      _c(
        "yxt-popover",
        {
          attrs: {
            "popper-class": "select-popper",
            "append-to-body": false,
            width: "648px",
            placement: "bottom-start",
            "visible-arrow": false,
            trigger: "manual",
          },
          model: {
            value: _vm.showPopper,
            callback: function ($$v) {
              _vm.showPopper = $$v
            },
            expression: "showPopper",
          },
        },
        [
          _c("div", [
            _vm.showPopper
              ? _c(
                  "div",
                  { staticClass: "tree-wrapper" },
                  [_vm._t("default")],
                  2
                )
              : _vm._e(),
          ]),
          _vm.showCustom
            ? _c(
                "div",
                {
                  staticClass:
                    "search-input yxt-input yxt-input--suffix yxt-popover__reference",
                  class: { "yxt-input--small": _vm.size === "small" },
                  attrs: { slot: "reference" },
                  on: { click: _vm.show },
                  slot: "reference",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "yxt-input__inner yxtbiz-popover-dept",
                      class: {
                        "yxtbiz-popover-placeholder":
                          !_vm.$attrs.selectOpition.checkWord,
                      },
                    },
                    [
                      _vm.$attrs.selectOpition.checkWord
                        ? _c("yxtbiz-dept-name", {
                            attrs: { name: _vm.$attrs.selectOpition.checkWord },
                          })
                        : _c("span", [
                            _vm._v(_vm._s(_vm.$t("biz_udp_select_dept"))),
                          ]),
                    ],
                    1
                  ),
                  _c("span", { staticClass: "yxt-input__suffix" }, [
                    _c("span", { staticClass: "yxt-input__suffix-inner" }, [
                      _c("i", {
                        staticClass: "yxt-input__icon",
                        class: {
                          "yxt-icon-arrow-up": _vm.showPopper,
                          "yxt-icon-arrow-down": !_vm.showPopper,
                        },
                      }),
                    ]),
                  ]),
                ]
              )
            : _c(
                "yxt-input",
                _vm._b(
                  {
                    staticClass: "search-input",
                    attrs: {
                      slot: "reference",
                      size: _vm.size,
                      "suffix-icon": _vm.showPopper
                        ? "yxt-icon-arrow-up"
                        : "yxt-icon-arrow-down",
                      readonly: "",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.show.apply(null, arguments)
                      },
                    },
                    slot: "reference",
                    model: {
                      value: _vm.$attrs.selectOpition.checkWord,
                      callback: function ($$v) {
                        _vm.$set(_vm.$attrs.selectOpition, "checkWord", $$v)
                      },
                      expression: "$attrs.selectOpition.checkWord",
                    },
                  },
                  "yxt-input",
                  _vm.$attrs.selectOpition,
                  false
                ),
                [_c("template", { slot: "suffix" })],
                2
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }