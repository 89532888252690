var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showElement,
          expression: "showElement",
        },
      ],
      staticClass: "jw-icon jw-icon-tooltip jw-button-color jw-reset jw-ai",
    },
    [
      _c(
        "span",
        {
          staticClass: "jw-icon-ai",
          class: { "jw-ai-disabled": !_vm.enabled },
          on: { mouseleave: _vm.hideHandler, mouseenter: _vm.hoverHandler },
        },
        [
          _vm._v(
            "\n      " + _vm._s(_vm.translation("pc_biz_video_lbl_subtitles"))
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showPopover,
              expression: "showPopover",
            },
          ],
          ref: "popover",
          staticClass:
            "yxt-popover yxt-popper yxt-poper-arrow-flag jw-ai-popover is-dark",
        },
        [
          _c(
            "div",
            {
              on: { mouseenter: _vm.hoverHandler, mouseleave: _vm.hideHandler },
            },
            [
              _c(
                "div",
                { staticClass: "jw-ai-enabled" },
                [
                  _c("yxt-switch", {
                    staticClass: "mr8",
                    on: {
                      change: function ($event) {
                        return _vm.changeHandler("enableChange", arguments)
                      },
                    },
                    model: {
                      value: _vm.aiEnabled,
                      callback: function ($$v) {
                        _vm.aiEnabled = $$v
                      },
                      expression: "aiEnabled",
                    },
                  }),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.translation("pc_biz_video_lbl_subtitles")) +
                      "\n      "
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "jw-ai-setting" },
                [
                  _vm.showLan
                    ? _c("div", { staticClass: "jw-ai-set-title" }, [
                        _vm._v(
                          _vm._s(
                            _vm.translation("pc_biz_video_lbl_subtitles_lan")
                          )
                        ),
                      ])
                    : _vm._e(),
                  _vm.showLan
                    ? _c(
                        "yxt-radio-group",
                        {
                          on: {
                            change: function ($event) {
                              return _vm.changeHandler(
                                "ai-lan-change",
                                arguments
                              )
                            },
                          },
                          model: {
                            value: _vm.textLan,
                            callback: function ($$v) {
                              _vm.textLan = $$v
                            },
                            expression: "textLan",
                          },
                        },
                        _vm._l(_vm.lanList, function (item) {
                          return _c(
                            "yxt-radio",
                            { key: item.value, attrs: { label: item.value } },
                            [_vm._v(_vm._s(item.label))]
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "jw-ai-set-title" }, [
                    _vm._v(
                      _vm._s(_vm.translation("pc_biz_video_lbl_subtitles_size"))
                    ),
                  ]),
                  _c(
                    "yxt-radio-group",
                    {
                      on: {
                        change: function ($event) {
                          return _vm.changeHandler("ai-text-size", arguments)
                        },
                      },
                      model: {
                        value: _vm.textSize,
                        callback: function ($$v) {
                          _vm.textSize = $$v
                        },
                        expression: "textSize",
                      },
                    },
                    _vm._l(_vm.textSizeList, function (item) {
                      return _c(
                        "yxt-radio",
                        { key: item.value, attrs: { label: item.value } },
                        [_vm._v(_vm._s(item.label))]
                      )
                    }),
                    1
                  ),
                  _c("div", { staticClass: "jw-ai-set-title" }, [
                    _vm._v(
                      _vm._s(_vm.translation("pc_biz_video_lbl_bg_opacity"))
                    ),
                  ]),
                  _c("yxt-slider", {
                    attrs: { "input-size": "mini" },
                    on: {
                      change: function ($event) {
                        return _vm.changeHandler("ai-text-opacity", arguments)
                      },
                    },
                    model: {
                      value: _vm.bgOpacity,
                      callback: function ($$v) {
                        _vm.bgOpacity = $$v
                      },
                      expression: "bgOpacity",
                    },
                  }),
                  _c("div", { staticClass: "jw-ai-set-title" }, [
                    _vm._v(_vm._s(_vm.translation("pc_biz_video_lbl_drag"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "jw-set-row-flex mt8" },
                    [
                      _c("yxt-switch", {
                        staticClass: "mr8",
                        on: {
                          change: function ($event) {
                            return _vm.changeHandler(
                              "drag-enable-change",
                              arguments
                            )
                          },
                        },
                        model: {
                          value: _vm.enableDrag,
                          callback: function ($$v) {
                            _vm.enableDrag = $$v
                          },
                          expression: "enableDrag",
                        },
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.translation("pc_biz_video_lbl_drag_setting")
                          ) +
                          "\n        "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }