var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "yxt-drawer",
    {
      attrs: {
        title: _vm.title || _vm.$t("pc_biz_core_tit_msgCollector"),
        "has-scroll": true,
        visible: _vm.showDrawer,
        size: "480px",
      },
      on: {
        close: _vm.close,
        "update:visible": function ($event) {
          _vm.showDrawer = $event
        },
      },
    },
    [
      _c("div", [
        _c(
          "dl",
          { staticClass: "yxt-biz-msgselector-item" },
          [
            _c("dt", { staticClass: "color-gray-10" }, [
              _vm._v(_vm._s(_vm.$t("pc_biz_core_lbl_requiredInfo"))),
            ]),
            _vm._l(_vm.required, function (item, index) {
              return _c("dd", { key: index }, [
                _c("span", [
                  _vm._v("\n          " + _vm._s(item.label) + "\n        "),
                ]),
                !_vm.unRemoveSet.has(item.value)
                  ? _c(
                      "span",
                      {
                        staticClass: "yxt-biz-color-danger hand",
                        on: {
                          click: function ($event) {
                            return _vm.remove(item, 1)
                          },
                        },
                      },
                      [
                        _c("yxt-svg", {
                          staticClass: "v-mid",
                          attrs: {
                            width: "18px",
                            height: "18px",
                            "icon-class": "minus-circle-o",
                          },
                        }),
                        _c("span", { staticClass: "ml5 v-mid" }, [
                          _vm._v(_vm._s(_vm.$t("pc_biz_core_lbl_remove"))),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            }),
          ],
          2
        ),
        _c(
          "dl",
          { staticClass: "yxt-biz-msgselector-item" },
          [
            _c("dt", { staticClass: "color-gray-10" }, [
              _vm._v(_vm._s(_vm.$t("pc_biz_core_lbl_optionalInfo"))),
            ]),
            _vm._l(_vm.optional, function (item, index) {
              return _c("dd", { key: index }, [
                _c("span", [
                  _vm._v("\n          " + _vm._s(item.label) + "\n        "),
                ]),
                _c(
                  "span",
                  {
                    staticClass: "yxt-biz-color-danger hand",
                    on: {
                      click: function ($event) {
                        return _vm.remove(item, 2)
                      },
                    },
                  },
                  [
                    _c("yxt-svg", {
                      staticClass: "v-mid",
                      attrs: {
                        width: "18px",
                        height: "18px",
                        "icon-class": "minus-circle-o",
                      },
                    }),
                    _c("span", { staticClass: "ml5 v-mid" }, [
                      _vm._v(_vm._s(_vm.$t("pc_biz_core_lbl_remove"))),
                    ]),
                  ],
                  1
                ),
              ])
            }),
          ],
          2
        ),
        _c(
          "dl",
          { staticClass: "yxt-biz-msgselector-item" },
          [
            _c("dt", { staticClass: "color-gray-10" }, [
              _vm._v(_vm._s(_vm.$t("pc_biz_core_lbl_notAddedInfo"))),
            ]),
            _vm._l(_vm.unselectlist, function (item, index) {
              return _c("dd", { key: index }, [
                _c("span", [
                  _vm._v("\n          " + _vm._s(item.label) + "\n        "),
                ]),
                _c("span", { staticClass: "color-primary-6 hand" }, [
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.add(item, 1)
                        },
                      },
                    },
                    [
                      _c("yxt-svg", {
                        staticClass: "v-mid",
                        attrs: {
                          width: "18px",
                          height: "18px",
                          "icon-class": "plus-circle-o",
                        },
                      }),
                      _c("span", { staticClass: "ml5 v-mid" }, [
                        _vm._v(_vm._s(_vm.$t("pc_biz_core_lbl_requiredItem"))),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "ml24",
                      on: {
                        click: function ($event) {
                          return _vm.add(item, 2)
                        },
                      },
                    },
                    [
                      _c("yxt-svg", {
                        staticClass: "v-mid",
                        attrs: {
                          width: "18px",
                          height: "18px",
                          "icon-class": "plus-circle-o",
                        },
                      }),
                      _c("span", { staticClass: "ml5 v-mid" }, [
                        _vm._v(_vm._s(_vm.$t("pc_biz_core_lbl_optionalItem"))),
                      ]),
                    ],
                    1
                  ),
                ]),
              ])
            }),
          ],
          2
        ),
      ]),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("yxt-button", { attrs: { plain: "" }, on: { click: _vm.close } }, [
            _vm._v(_vm._s(_vm.$t("pc_biz_utility_btn_cancel"))),
          ]),
          _c(
            "yxt-button",
            { attrs: { type: "primary" }, on: { click: _vm.select } },
            [_vm._v(_vm._s(_vm.$t("pc_biz_core_btn_confirm")))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }