var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "yxt-row",
        {
          staticClass: "yxt-user-selector-container yxt-user-multi-container",
          attrs: { type: "flex" },
        },
        [
          _c(
            "div",
            { staticClass: "yxtbiz-user-search-wrap mb16" },
            [
              _vm.isShowTeamSelect
                ? _c(
                    "yxt-select",
                    {
                      staticClass: "mr12",
                      attrs: { size: "small" },
                      on: { change: _vm.handleTeamType },
                      model: {
                        value: _vm.currSelectTeamId,
                        callback: function ($$v) {
                          _vm.currSelectTeamId = $$v
                        },
                        expression: "currSelectTeamId",
                      },
                    },
                    _vm._l(_vm.teamSelectOptions, function (item, index) {
                      return _c("yxt-option", {
                        key: index,
                        attrs: { label: item.teamName, value: item.teamId },
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _c(
                "yxt-popover",
                {
                  attrs: {
                    "append-to-body": false,
                    "popper-class": "yxtbiz-user-selector-popover",
                    trigger: "manual",
                    width: "338",
                    placement: "bottom-start",
                    "visible-arrow": false,
                  },
                  model: {
                    value: _vm.showMoreFilter,
                    callback: function ($$v) {
                      _vm.showMoreFilter = $$v
                    },
                    expression: "showMoreFilter",
                  },
                },
                [
                  _c(
                    "yxt-button",
                    {
                      staticClass: "yxtbiz-filter-btn mr12 pull-left",
                      staticStyle: { height: "32px" },
                      attrs: { slot: "reference", plain: "", size: "small" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.showFilterBox.apply(null, arguments)
                        },
                      },
                      slot: "reference",
                    },
                    [
                      _c("yxt-svg", {
                        staticClass: "v-top",
                        attrs: {
                          width: "20px",
                          height: "20px",
                          "icon-class": "filter",
                        },
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "ml8 v-top",
                          staticStyle: {
                            "font-size": "14px",
                            color: "#595959",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("pc_biz_udp_lbl_moreFilter")))]
                      ),
                      _c("i", {
                        staticClass: "ml8",
                        class: _vm.showMoreFilter
                          ? "yxt-icon-arrow-up"
                          : "yxt-icon-arrow-down",
                      }),
                    ],
                    1
                  ),
                  _c(
                    "yxt-form",
                    {
                      attrs: {
                        "label-position": "top",
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "yxt-form-item",
                        {
                          attrs: {
                            label: _vm.$t("pc_biz_rank_dept").d("部门"),
                            prop: "department",
                            size: "small",
                          },
                        },
                        [
                          _c("tree-select", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: _vm
                                .$t("pc_biz_lbl_selecttip")
                                .d("请选择"),
                            },
                            on: { "remove-tag": _vm.removeDept },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.showTree("department")
                              },
                            },
                            model: {
                              value: _vm.depts,
                              callback: function ($$v) {
                                _vm.depts = $$v
                              },
                              expression: "depts",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "yxt-form-item",
                        {
                          attrs: {
                            label: _vm.$t("biz_udp_position").d("岗位"),
                            prop: "position",
                            size: "small",
                          },
                        },
                        [
                          _c("tree-select", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: _vm
                                .$t("pc_biz_lbl_selecttip")
                                .d("请选择"),
                            },
                            on: { "remove-tag": _vm.removePosition },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.showTree("position")
                              },
                            },
                            model: {
                              value: _vm.positions,
                              callback: function ($$v) {
                                _vm.positions = $$v
                              },
                              expression: "positions",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "yxt-form-item",
                        {
                          attrs: {
                            label: _vm
                              .$t("pc_biz_range_select_001")
                              .d("入职时间"),
                            prop: "hireTime",
                            size: "small",
                          },
                        },
                        [
                          _c("yxt-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              size: "small",
                              "value-format": "yyyy-MM-dd",
                              type: "daterange",
                              placeholder: _vm
                                .$t("pc_biz_rule_select_date")
                                .d("选择日期"),
                            },
                            model: {
                              value: _vm.hireTime,
                              callback: function ($$v) {
                                _vm.hireTime = $$v
                              },
                              expression: "hireTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "yxt-form-item",
                        { staticClass: "btn-item", attrs: { size: "small" } },
                        [
                          _c(
                            "yxt-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.filterUsers },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("pc_biz_udp_btn_confirm").d("确定")
                                )
                              ),
                            ]
                          ),
                          _c(
                            "yxt-button",
                            {
                              staticStyle: { "margin-left": "12px" },
                              attrs: { plain: "" },
                              on: { click: _vm.reset },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("pc_comp_table_resetFilter").d("重置")
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("yxtbiz-type-search", {
                staticClass: "mr12",
                attrs: {
                  size: "small",
                  maxlength: "20",
                  kwType: _vm.userData.kwType,
                  keywords: _vm.userValue,
                  options: [1, 2],
                  selectCache: true,
                  searchable: "",
                },
                on: {
                  "update:kwType": function ($event) {
                    return _vm.$set(_vm.userData, "kwType", $event)
                  },
                  "update:kw-type": function ($event) {
                    return _vm.$set(_vm.userData, "kwType", $event)
                  },
                  "update:keywords": function ($event) {
                    _vm.userValue = $event
                  },
                  search: _vm.searchUser,
                },
              }),
            ],
            1
          ),
          _c(
            "yxt-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              key: "persons" + _vm.timeStamp,
              ref: _vm.refTable,
              staticClass: "yxt-user-table",
              attrs: {
                height: _vm.tableHeight,
                data: _vm.persons,
                "row-key": (row) => row.id,
              },
              on: { "select-all": _vm.selectAllUser, select: _vm.selectUser },
            },
            [
              _c("template", { slot: "empty" }, [
                _c(
                  "div",
                  { staticStyle: { "line-height": "normal" } },
                  [_c("yxt-empty")],
                  1
                ),
              ]),
              _vm.multiple
                ? _c("yxt-table-column", {
                    attrs: {
                      "show-overflow-tooltip": false,
                      "class-name": "yxtbiz-table-selection",
                      type: "selection",
                      width: "42",
                      selectable: (row) => !row.disabled,
                      "reserve-selection": "",
                      "clear-padding": "right",
                    },
                  })
                : _c("yxt-table-column", {
                    attrs: { "show-overflow-tooltip": false, width: "40" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("yxt-radio", {
                              attrs: { label: scope.row.id },
                              on: {
                                change: function ($event) {
                                  return _vm.selectUser(scope.row.id, scope.row)
                                },
                              },
                              model: {
                                value: _vm.singleSelect,
                                callback: function ($$v) {
                                  _vm.singleSelect = $$v
                                },
                                expression: "singleSelect",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
              _vm.multiple
                ? _c(
                    "yxt-table-column",
                    {
                      attrs: {
                        width: "18",
                        align: "right",
                        "clear-padding": "left-right",
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "header" },
                        [
                          _c(
                            "yxt-dropdown",
                            {
                              attrs: { placement: "bottom-start" },
                              on: { command: _vm.tableHeaderCommand },
                            },
                            [
                              _c("span"),
                              _c(
                                "yxt-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                [
                                  _c(
                                    "yxt-dropdown-item",
                                    { attrs: { command: "selectCurrent" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm
                                            .$t(
                                              "pc_biz_enroll_select_current_page"
                                            )
                                            .d("选择本页")
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "yxt-dropdown-item",
                                    { attrs: { command: "selectAll" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm
                                            .$t("pc_biz_enroll_select_all")
                                            .d("选择全部")
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "yxt-dropdown-item",
                                    { attrs: { command: "clearSelect" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm
                                            .$t("pc_biz_enroll_clear_all")
                                            .d("清空所选")
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  )
                : _vm._e(),
              _c("yxt-table-column", {
                attrs: {
                  label: _vm.$t("pc_biz_core_lbl_name"),
                  "show-overflow-tooltip": true,
                  "popover-html": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.isOpenData
                          ? _c("yxtbiz-user-name", {
                              attrs: { name: scope.row.fullname },
                            })
                          : _c("span", [_vm._v(_vm._s(scope.row.fullname))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("yxt-table-column", {
                attrs: {
                  label: _vm.$t("pc_biz_core_lbl_accnum"),
                  prop: "username",
                  "show-overflow-tooltip": true,
                  "popover-html": true,
                },
              }),
              _c("yxt-table-column", {
                attrs: {
                  formatter: _vm.formatUserNo,
                  label: _vm.$t("pc_biz_udp_lbl_userNo"),
                  "show-overflow-tooltip": true,
                  prop: "userNo",
                },
              }),
              _c("yxt-table-column", {
                attrs: {
                  label: _vm.$t("pc_biz_core_lbl_department"),
                  "show-overflow-tooltip": true,
                  "popover-html": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        !scope.row.deptName
                          ? _c("span", [_vm._v("--")])
                          : _vm.isOpenData
                          ? _c("yxtbiz-dept-name", {
                              attrs: { name: scope.row.deptName },
                            })
                          : _c("span", [_vm._v(_vm._s(scope.row.deptName))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("yxt-table-column", {
                attrs: {
                  label: _vm.$t("biz_udp_position"),
                  "show-overflow-tooltip": true,
                  formatter: _vm.formatPosition,
                },
              }),
            ],
            2
          ),
          _c(
            "yxt-row",
            {
              staticClass: "yxtbiz-user-selector-mask mt16",
              attrs: { align: "middle", type: "flex" },
            },
            [
              _c("yxt-row", {
                staticClass: "col-flex-1",
                attrs: { type: "flex", align: "middle" },
              }),
              _c("yxt-pagination", {
                attrs: {
                  "current-page": _vm.page,
                  "page-size": _vm.userData.limit,
                  "simple-total": "",
                  layout: "total, prev, pager, next",
                  total: _vm.count,
                  "pager-count": 5,
                },
                on: {
                  "size-change": _vm.sizeChange,
                  "current-change": _vm.pageChange,
                  "update:currentPage": function ($event) {
                    _vm.page = $event
                  },
                  "update:current-page": function ($event) {
                    _vm.page = $event
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "yxt-drawer",
        {
          attrs: {
            title: _vm.$t("biz_udp_select_dept"),
            "append-to-body": true,
            "modal-append-to-body": true,
            "destroy-on-close": true,
            size: "480px",
            visible: _vm.drawerDeptVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawerDeptVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "yxtbiz-dept-tree-full-height" },
            [
              _c("yxtbiz-dept-tree", {
                ref: "deptTree",
                staticClass: "department_tree",
                attrs: {
                  size: "small",
                  version: "v1",
                  "show-checkbox": "",
                  filterable: "",
                  openSelectSiblingNode: true,
                  count: true,
                  "check-strictly": true,
                  functions: "",
                  "wrap-width": "100%",
                  childrenIncluded: 2,
                  "default-checked-keys": _vm.defaultDeptCheckeds,
                  checkedLinkage: 1,
                  teamId: _vm.currSelectTeamId,
                  core: "team",
                  nodeKey: "id",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "yxt-button",
                { attrs: { plain: "" }, on: { click: _vm.cancelTree } },
                [_vm._v(_vm._s(_vm.$t("pc_biz_udp_btn_cancel")))]
              ),
              _c(
                "yxt-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirmTree } },
                [_vm._v(_vm._s(_vm.$t("pc_biz_udp_btn_confirm")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "yxt-drawer",
        {
          attrs: {
            title: _vm.$t("pc_biz_udp_tit_selectPotision"),
            visible: _vm.drawerPosVisible,
            "destroy-on-close": true,
            size: "960px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawerPosVisible = $event
            },
          },
        },
        [
          _c("yxtbiz-range-selector", {
            ref: "position",
            attrs: { model: "array", tabs: ["position"] },
            model: {
              value: _vm.selectedPositions,
              callback: function ($$v) {
                _vm.selectedPositions = $$v
              },
              expression: "selectedPositions",
            },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "yxt-button",
                {
                  attrs: { plain: "" },
                  on: {
                    click: function ($event) {
                      _vm.drawerPosVisible = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("pc_biz_udp_btn_cancel")))]
              ),
              _c(
                "yxt-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirmPos } },
                [_vm._v(_vm._s(_vm.$t("pc_biz_udp_btn_confirm")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }