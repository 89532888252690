<template>
  <div class="check_more_department">
    <!-- <yxtbiz-dept-tree

      showCheckbox
      ref="deptTree"

    ></yxtbiz-dept-tree> -->
    <yxtbiz-dept-tree
      :targetOrgId='targetOrgId'
      :disabled="disabled"
      :visibleOrgSelector='visibleOrgSelector'
      :visibleOrgIds="visibleOrgIds"
      :enableGroupCorp="enableGroupCorp"
      @org-change='handleOrgChange'
      @close="closeTag"
      @check="handleCheck"
      @clearNode="handleCheck"
      filterable
      size=""
      :placeholder="$t('biz_udp_enter_deptmentname')"
      class="department_tree"
      ref="deptTree"
      :check-strictly="!!optionsComputed.checkStrictly"
      show-checkbox
      @inited="init"
      :children-included="optionsComputed.childrenIncluded"
      @includeChanged="handleTypeChange"
      :functions="!!optionsComputed.childrenIncluded"
      :functionCode="functionCode"
      :dataPermissionCode="dataPermissionCode"
      :count="showDeptCount"
      :checkedLinkage="'1'"
      :list="list"
      ></yxtbiz-dept-tree>
    <!-- <div class="setting">
      <i style="color: gray;" class="yxt-icon-setting"></i>
      <ul class="setting_box">
        <li v-for="item in listType" @click="handleType(item)" :key="item.id">
          {{ item.label }}
        </li>
      </ul>
    </div> -->
  </div>
</template>

<script>
import authMixin from '../mixin/auth';
import { i18n } from 'yxt-biz-pc/packages/common-util/changeLangs';
/**
 * @description 嵌套对象打平(虚拟部门专用)
 * @param {object} obj
 * @param {name} obj的属性名
 */
function formatObj(node, name, arr = {}) {
  formatObjLoop(node, name, arr);
  return Object.values(arr);
  // if (!node.visible) {
  //   return Object.values(arr);
  // }
  // if (node.data.id) {
  //   arr[node.data.id] = node.data;
  // }
  // if (node.hasOwnProperty(name) && Array.isArray(node[name])) {
  //   node[name].forEach(childNode => {
  //     if (!childNode.visible) {
  //       return Object.values(arr);
  //     }
  //     arr[childNode.data.id] = childNode.data;
  //     formatObj(childNode, name, arr);
  //   });
  // }
  // return Object.values(arr);
}

/**
 * @description 嵌套对象打平(虚拟部门专用)
 * @param {object} obj
 * @param {name} obj的属性名
 */
function formatObjLoop(node, name, arr = {}) {
  if (!node.visible) {
    return arr;
  }
  if (node.data.id) {
    arr[node.data.id] = node.data;
  }
  if (node.hasOwnProperty(name) && Array.isArray(node[name])) {
    node[name].forEach(childNode => {
      if (!childNode.visible) {
        return arr;
      }
      arr[childNode.data.id] = childNode.data;
      formatObjLoop(childNode, name, arr);
    });
  }
  return arr;
}

/**
 * @description 递归改变对象属性
 * @param {object} tag
 */
function resetAttr(tag) {
  tag.disabled = false;
  tag.includeAll = 0;
  if (tag.children && tag.children.length > 0) {
    tag.children.forEach(item => {
      item.includeAll = 0;
      item.disabled = false;
      resetAttr(item);
    });
  };
}

let departmentsRecord = [];
export default {
  name: 'CheckMoreDepartment',
  mixins: [authMixin],
  props: {
    list: {
      type: Array,
      default: () => []
    },
    options: {}
  },
  data() {
    return {
      listType: [
        {id: 1, label: i18n.t('biz_udp_all')},
        {id: 2, label: i18n.t('biz_udp_subsequent_all')}
      ],
      defaultOptions: {
        childrenIncluded: 2,
        checkStrictly: true
      }
    };
  },
  computed: {
    optionsComputed() {
      if (!this.options) {
        return this.defaultOptions;
      } else {
        return Object.assign(this.defaultOptions, this.options);
      }
    },
    showDeptCount() {
      if (this.options && this.options.count === false) {
        return false;
      }
      return true;
    }
  },
  created() {
    console.log(this.disabled, this.targetOrgId);
  },
  methods: {
    handleOrgChange(data) {
      this._targetOrgId = data.orgId;
      this.targetOrgName = data.orgName;
      this.$emit('org-change');
    },
    init() {
      this.update(() => {
        if (!this.optionsComputed.checkStrictly) {
          this.handleCheck();
        }
      });
    },
    // 手动更新
    update(cb) {
      if (this.enableGroupCorp) {
        departmentsRecord = this.list;
      }
      this.$refs['deptTree'].clear();
      this.$nextTick(() => {
        // let distinctIds = this.$refs['deptTree'].transIdsToKeys(this.list.map(item => item.id)) || [];
        // // console.log(distinctIds, this.list);
        this.$refs['deptTree'].filter();
        this.$refs['deptTree'].setCheckedDeptIds(this.list.map(item => item.id));
        this.$refs['deptTree'].setChildrenIncludeByDeptIdV2(this.list.filter(item => item.includeAll));
        // this.list.map(item => {
        //   if (item.includeAll) {
        //     this.$refs['deptTree'].setChildrenIncludeByDeptId(item.id);
        //   }
        // });
        if (cb) {
          cb();
        }
        // if (this.list.length === 0) {
        //   this.$refs['deptTree'].refresh();
        // }
      });
    },
    handleTypeChange(node, data) {
      // 当前全部0, 后续全部1
      let idsObj = formatObj(node, 'childNodes');
      idsObj = idsObj.map(item => {
        item['_type'] = 0;
        return item;
      });
      if (!data.hasAllDeptPermission) {
        idsObj = idsObj.filter(obj => obj.id !== data.id);
      }
      this.$emit('updateDepartmentByType', {
        data: idsObj,
        isCurrent: data.includeAll
      });
    },
    formatCheckNodes(checkNodes) {
      let folllowAll = [];
      let currentAll = [];
      checkNodes.map((item, index) => {
        if (item.includeAll) {
          item.ORDER = index;
          folllowAll.push(item);
        } else {
          item.ORDER = index;
          currentAll.push(item);
        };
      });
      const folllowAllIds = folllowAll.map(item => item.id);
      folllowAll = folllowAll.filter(item => {
        return !folllowAllIds.includes(item.parentId);
      });
      return [...folllowAll, ...currentAll].sort((a, b) => a.ORDER - b.ORDER);
    },
    // 点击复选框
    handleCheck(a, b, c) {
      let checkNodes = JSON.parse(JSON.stringify(this.$refs.deptTree.getCheckedDeptNodes()));
      // 由于选中的节点可能包含后续全部的子节点，剔除后续全部子节点
      checkNodes = this.formatCheckNodes(checkNodes);
      checkNodes = checkNodes.map(item => {
        item['_type'] = 0;
        item.orgId = this._targetOrgId;
        item.orgName = this.targetOrgName;
        return item;
      });
      if (this.enableGroupCorp) {
        departmentsRecord = departmentsRecord.filter(item => item.orgId !== this._targetOrgId);
        departmentsRecord = departmentsRecord.concat(checkNodes);
        departmentsRecord.forEach(item => {
          item.combinationName = `${item.orgName}-${item.name}`;
        });
        console.log(departmentsRecord);
        this.$emit('update', departmentsRecord);
      } else {
        this.$emit('update', checkNodes);
      }
    },
    // 关闭单个标签
    closeTag({ tag, index }) {
      if (tag.includeAll) {
        resetAttr(tag);
      }
      if (!this.optionsComputed.checkStrictly) {
        // 如果是父子节点关联的
        this.$refs['deptTree'].setChecked(tag.distinctId || tag.id, false, true);
        this.handleCheck();
      } else {
        this.update();
      }
      // this.$refs['deptTree'].setCheckedNodes(this.list);
      // this.$refs['deptTree'].setCheckedNodes([]);
      // setTimeout(() => {
      // console.log(this.list);
      //   this.update()
      // }, 2000);
      // this.$nextTick(() => {
      //   this.update();
      // })
    }
  }
};
</script>
