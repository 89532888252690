<template>
  <yxt-dropdown-item v-if="enableGroupCorp && orgList.length > 1" style="padding-right:8px" v-bind="$attrs">
      <yxt-dropdown  placement="left-start"  :append-to-body="false" >
        <span type="text"  class="ell">
          {{orgName}}
        </span>
        <yxt-dropdown-menu
          slot="dropdown"
          :append-to-body="false"
          :popperOptions="{
            modifiers:[ 'shift', 'offset', 'keepTogether', 'arrow', 'flip', 'applyStyle'],
            gpuAcceleration: false
          }"
        >
          <yxt-dropdown-item
            v-for="item in orgList"
            :key="item.orgId"
            :command="item.orgId"
            :class="['yxtbiz-nav-lang__default-lang', {'yxtbiz-nav-lang__active-langs': activeId == item.orgId}]"
            @click.native="handleChange(item)"
          >
            {{ item.orgName }}
            <i v-if="activeId == item.orgId" class="yxt-icon-check"></i>
          </yxt-dropdown-item>
        </yxt-dropdown-menu>
      </yxt-dropdown>
  </yxt-dropdown-item>
</template>

<script>
import NavOrgListMixin from './nav-org-list-mixin.vue';
export default {
  name: 'YxtbizNavOrgList',
  props: ['className'],
  mixins: [NavOrgListMixin]
};
</script>

<style lang="scss" scoped>
.ell {
  overflow:hidden;  
  display: inline-block;
  white-space:nowrap;
	text-overflow:ellipsis;
  max-width: 112px;
  color:#000000;
  vertical-align: bottom;
  padding-right: 0;
}
.ell:hover {
  color: var(--color-primary-6);
}
</style>
