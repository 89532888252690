var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "yxtbiz-nav-breadcrumb",
      class: { "yxtbiz-nav-breadcrumb__containner": _vm.padding },
    },
    [
      _vm.type === 1
        ? _c(
            "yxtf-breadcrumb",
            { attrs: { "separator-class": "yxt-icon-arrow-right" } },
            _vm._l(_vm.cnavs, function (cnav, index) {
              return _c(
                "yxtf-breadcrumb-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: index != 0 || _vm.topNav,
                      expression: "index != 0 || topNav",
                    },
                  ],
                  key: index,
                  staticClass: "hand",
                  attrs: { isLink: "" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.toPage(cnav)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm._f("Globalize")(cnav, "name")))]
              )
            }),
            1
          )
        : _vm._e(),
      _vm.type === 2
        ? _c(
            "yxt-breadcrumb",
            { attrs: { "separator-class": "yxt-icon-arrow-right" } },
            _vm._l(_vm.cnavs, function (cnav, index) {
              return _c(
                "yxt-breadcrumb-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: index != 0 || _vm.topNav,
                      expression: "index != 0 || topNav",
                    },
                  ],
                  key: index,
                  staticClass: "hand",
                  attrs: { isLink: "" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.toPage(cnav)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm._f("Globalize")(cnav, "name")))]
              )
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }