<template>
  <div class="yxtbiz-ai-robot-collect-item width-percent-100 over-hidden" @click="toDetail(data)">
    <!--      文章封面，帖子，问题第一张图-->
    <div v-if="coverUrl(data)" class="yxtbiz-ai-robot-collect-community__img">
      <yxt-image
        :src="coverUrl(data)"
        alt
        class="yxtbiz-ai-robot-bbs__cover mr12"
        fit="cover"
      />
    </div>
    <div class="over-hidden">
      <!--        文章，问题的标题,-->
      <div v-if="data.postType === 2 || data.postType === 9">
        <div
          :class="[
            size === 'small'
              ? 'ellipsis-2 font-size-14 lh22'
              : 'ellipsis font-size-16  lh24',
            'color-gray-10'
          ]"
        >
          <yxtf-tag
            type="warning"
            size="mini"
            class="mr8 vertical-align__middle"
          >
            {{
              data.postType === 9
                ? $t('pc_biz_collect_lbl_answer')
                : $t('pc_biz_collect_lbl_discuss')
            }}
          </yxtf-tag>
          <span v-html="data.title"></span>
        </div>
      </div>
      <!--  帖子内容-->
      <div
        v-if="data.postType === 1"
        :class="[
          size === 'small'
            ? 'ellipsis-2 font-size-14 lh22'
            : 'ellipsis font-size-16  lh24',
          'color-gray-10'
        ]"
      >
        <yxtf-tag type="warning" size="mini" class="mr8">
          {{ $t('pc_biz_collect_lbl_discuss') }}
        </yxtf-tag>
        <span v-html="data.title"></span>
      </div>
      <div
        :class="[
          size === 'small' ? 'mt4' : 'mt8',
          'yxtbiz-flex-center lh20 color-gray-7 font-size-12'
        ]"
      >
        <span v-if="!data.anonymous" class="ellipsis maxwidth200"
          ><yxtbiz-user-name :name="data.createUsername"
        /></span>
        <span v-else class="ellipsis maxwidth200">
          {{ $t('pc_biz_collect_anonymousUser') }}</span
        >
        <yxt-divider direction="vertical"></yxt-divider>
        <span class="ellipsis mr20">{{ data.createTime | formatTime }}</span>
        <span v-if="size !== 'small'" class="ellipsis maxwidth300">
          {{ data.boardName }}</span
        >
      </div>
      <div
        v-if="size === 'small'"
        class="ellipsis mt4 lh20 color-gray-7 font-size-12"
      >
        {{ data.boardName }}
      </div>
    </div>
  </div>
</template>
  
  <script>
import favoriteMixin from './favorite-mixin.js';
import dayjs from 'dayjs';

export default {
  name: 'discussItem',
  filters: {
    formatTime(time) {
      return dayjs(time).format('YYYY-MM-DD HH:mm');
    }
  },
  mixins: [favoriteMixin],
  props: {
    isTrain: Boolean // 是否是培训项目
  },
  data() {
    return {
      loading: false,
      list: []
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    coverUrl(imgs) {
      // <!--      文章封面，帖子，问题第一张图-->
      if (imgs.postType === 2) {
        return imgs.logoUrl;
      } else if (imgs.images) {
        return imgs.images.split(';')[0];
      } else return null;
    },
    toDetail(item) {
      // 2021年10月09日16:47:44 社区改为独立的应用，不再外kng跳转，而是跳往独立的应用
      let url = `${window.location.protocol}//${window.location.host}/bbs/#/bbs/bbsdetail?boardId=${item.boardId}&targetId=${item.id}`;
      window.open(url);
    }
  }
};
</script>
  
  <style scoped lang="scss">
.maxwidth200 {
  max-width: 200px;
}

.maxwidth300 {
  max-width: 300px;
}
.yxtbiz-ai-robot-collect-item {
  display: flex;
  align-items: center;
}

.yxtbiz-ai-robot-bbs__cover {
  display: block;
  width: 112px;
  height: 68px;
  border-radius: 4px;

  ::v-deep img {
    width: 100% !important;
    height: 100%;
  }
}
</style>
  