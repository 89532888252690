var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "container", staticClass: "yxtf-infinite-list-loading" },
    [
      _c("span", { staticClass: "yxt-infinite-loading-circle" }, [
        _c("svg", { attrs: { viewBox: "0 0 16 16" } }, [
          _c("circle", {
            staticClass: "path",
            attrs: { cx: "8", cy: "8", r: "7", fill: "none" },
          }),
        ]),
      ]),
      _c("span", { staticClass: "color-gray-7 font-size-14" }, [
        _vm._v(_vm._s(_vm.$t("pc_biz_ai_loadding").d("加载中"))),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }