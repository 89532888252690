// WModal.js
import Vue from 'vue';
import { i18n } from 'yxt-biz-pc/packages/common-util/changeLangs';
// 具体的组件
import WModal from './main.vue';

WModal.newInstance = properties => {
  const props = properties || {};
  // 实例化一个组件，然后挂载到 body 上
  const Instance = new Vue({
    data: props,
    i18n,
    render(h) {
      return h(WModal, {
        props: props
      });
    }
  });
  const component = Instance.$mount();
  document.body.appendChild(component.$el);
  // 通过闭包维护 modal 组件的引用
  const modal = Instance.$children[0];
  return {
    // modal 组件对外暴露的的方法
    init(modalProps) {
      modal.init(modalProps);
    }
  };
};

// 提示单例
let messageInstance;

function getMessageInstance() {
  messageInstance = messageInstance || WModal.newInstance();
  return messageInstance;
}

function open({ props, options, onSuccess, onError, onCancel}) {
  // 等待接口调用的时候再实例化组件，避免进入页面就直接挂载到 body 上
  let instance = getMessageInstance();
  instance.init({
    props,
    options,
    onSuccess,
    onError,
    onCancel
  });
}

// 对外暴露的方法
let SponsorChoose = {
  name: 'YxtbizSponsorChoose',
  open(params) {
    return open(params);
  }
};
export default SponsorChoose;
