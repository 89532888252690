<template>
  <yxt-row type='flex' class="yxt-user-selector-container yxt-user-multi-container">
    <div class="yxtbiz-user-search-wrap">
      <yxtbiz-group-org-select
        class="mr12"
        :functionCode="functionCode"
        :dataPermissionCode="dataPermissionCode"
        v-if="enableGroupCorp"
        v-show="visibleOrgSelector"
        @change="handleOrgChange"
        :allgroup="true"
        size="small"
        :disabled="disabled"
        :setCurrentOrg="true"
        :targetOrgId="targetOrgId || (strongManageMode ? 'all' : '')"
        :selectFirst="true"
        :clearable="false"
        :visibleOrgIds="visibleOrgIds"
      >
      </yxtbiz-group-org-select>
      <select-popover ref="selectPopover" size="small" :selectOpition="selectOpition">
        <div class="table_tree">
          <yxtbiz-pos-tree
            v-if="(enableGroupCorp && currentTargetOrgId) || !enableGroupCorp"
            :targetOrgId="currentTargetOrgId"
            :enableGroupCorp="enableGroupCorp"
            :placeholder="$t('biz_udp_pos_category')"
            filterable
            size="small"
            ref="posTree"
            @nodeClick="nodeClick"
            :functionCode="functionCode"
            :dataPermissionCode="dataPermissionCode"
          >
          </yxtbiz-pos-tree>
        </div>
      </select-popover>
      <yxt-input
        size="small"
        class="keyword ml12"
        @keyup.enter.native="getPositionData(true)"
        v-model="params.name"
        :placeholder="$t('biz_udp_enter_pos_name')"
        style="width: 240px;"
        searchable
        @search="getPositionData(true)"
      >
      </yxt-input>
    </div>
    <yxt-table
      :height="tableHeight"
      :ref="refTable"
      class="mt16 yxt-user-table"
      @select="selectPosition"
      @select-all="selectAllPosition"
      row-key="id"
      v-loading="loading"
      :data="position"
      style="width:100%;"
    >
      <!-- 表格单多选 -->
      <yxt-table-column
        v-if="multiple"
        :show-overflow-tooltip="false"
        class-name="yxtbiz-table-selection"
        type="selection"
        width="42"
        reserve-selection
        clear-padding="right"
      ></yxt-table-column>
      <yxt-table-column
        v-else
        :show-overflow-tooltip="false"
        width="40"
      >
        <template slot-scope="scope">
          <yxt-radio v-model="singleSelect" @change="selectPosition(scope.row.id, scope.row)" :label="scope.row.id"></yxt-radio>
        </template>
      </yxt-table-column>
      <yxt-table-column
        width="18"
        align="right"
        clear-padding="left-right"
        v-if="multiple"
        >
        <template slot="header">
          <yxt-dropdown placement="bottom-start" @command="tableHeaderCommand">
              <span> </span>
              <yxt-dropdown-menu slot="dropdown">
                <yxt-dropdown-item command="selectCurrent">{{$t('pc_biz_enroll_select_current_page').d('选择本页')}}</yxt-dropdown-item>
                <yxt-dropdown-item command="selectAll" >{{$t('pc_biz_enroll_select_all').d('选择全部')}}</yxt-dropdown-item>
                <yxt-dropdown-item command="clearSelect" >{{$t('pc_biz_enroll_clear_all').d('清空所选')}}</yxt-dropdown-item>
              </yxt-dropdown-menu>
          </yxt-dropdown>
        </template>
      </yxt-table-column>
      <yxt-table-column
        prop="name"
        show-overflow-tooltip
        :label="$t('biz_udp_pos_name')"
        min-width="180"
      >
      </yxt-table-column>
      <yxt-table-column
        prop="gradeList"
        show-overflow-tooltip
        :label="$t('biz_udp_occupation_level')"
        min-width="180"
      >
        <template slot-scope="scope">
          <span>{{
            (scope.row.gradeList || []).map(item => item.name).join() || '--'
          }}</span>
        </template>
      </yxt-table-column>
      <yxt-table-column
        show-overflow-tooltip
        prop="catalogName"
        :label="$t('biz_udp_pos_category')"
        min-width="180"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.catalogName || $t('biz_udp_pos_system') }}</span>
        </template>
      </yxt-table-column>
      <template slot="empty">
        <div style="line-height:normal">
          <yxt-empty />
        </div>
      </template>
    </yxt-table>
    <yxt-row type='flex' align='middle' class="yxtbiz-user-selector-mask mt16">
      <yxt-row class="col-flex-1" type='flex' align='middle'>
      </yxt-row>
      <yxt-pagination
        :page-size="params.limit"
        :page-sizes="[5, 10, 20, 30, 50, 100]"
        @size-change="handleSizeChange"
        :current-page.sync="currentPage"
        layout="total, prev, pager, next"
        simple-total
        @current-change="handleCurrentChange"
        :total="total"
      >
      </yxt-pagination>
    </yxt-row>
  </yxt-row>
</template>

<script>
import resizeTable from '../../mixins/resizeTable';
import { getPositionList } from '../service';
import { getGroupPositions } from '../../../group-org-select/src/service';
import echoMixin from '../../mixins/echo';
import authMixin from '../../mixins/auth';
import commonMixin from '../../mixins/common';
import SelectPopover from '../components/SelectPopover';
import { Table, TableColumn, Pagination, Empty } from 'yxt-pc';
import { i18n } from 'yxt-biz-pc/packages/common-util/changeLangs';

export default {
  name: 'CheckPosition',
  mixins: [echoMixin, resizeTable, authMixin, commonMixin],
  components: {
    SelectPopover,
    YxtTable: Table,
    YxtTableColumn: TableColumn,
    YxtPagination: Pagination,
    YxtEmpty: Empty
  },
  props: {
    visibleOrgSelector: {
      type: Boolean,
      default: true
    },
    targetOrgId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      refTable: 'positionTable',
      tableHeight: 0,
      currentPage: 1,
      total: 0,
      loading: true,
      params: {
        name: '',
        catalogId: '',
        // fields: ['catalogId', 'id', 'name'],
        limit: 20,
        offset: 0
      },

      position: [],
      selectOpition: {
        placeholder: i18n.t('biz_udp_pos_select_category'),
        checkWord: ''
      },
      currentTargetOrgId: ''
    };
  },
  mounted() {
    if (this.targetOrgId) {
      this.currentTargetOrgId = this.params.targetOrgId = this.targetOrgId;
    }
    if (!this.enableGroupCorp) this.getPositionData();
  },
  computed: {
    masterOrgId(state) {
      return this.$store.state.navManageStore.groupCorpInfo.masterOrgId;
    }
  },
  watch: {
    selectData: {
      deep: true,
      handler() {
        this.performanceUpateSelected('position');
        this.position = [...this.position];
      }
    }
  },
  methods: {
    tableHeaderCommand(row) {
      this[row]();
    },
    async selectCurrent() {
      // 选择本页
      // 全集团下因为要调接口换数据，因此不调公共方法
      // this.selectCurrData('position');
      const type = 'position';
      const selectObj = {};
      const selecteds = [...this.selectData[type]];
      selecteds.forEach(item => {
        selectObj[item.id] = true;
      });
      this[type].forEach(row => {
        if (!selectObj[row.id]) {
          this.$refs[this.refTable].toggleRowSelection(row, true);
          selecteds.push(row);
        }
      });
      const result = await this.getAllGroupPos(selecteds);
      this.$emit('change', result, type);
    },
    selectAll() {
      // 选择所有
      this.loadAll();
    },
    clearSelect() {
      // 清空所选
      this.$refs[this.refTable].clearSelection();
      this.$emit('change', [], 'position');
    },
    async selectAllPosition(items) {
      const selected = await this.getAllGroupPos(items);
      this.performanceSelectData(selected, 'position');
    },
    async selectPosition(items, row) {
      // 选中数据
      if (Array.isArray(items)) {
        // 多选
        const selected = await this.getAllGroupPos(items);
        this.performanceSelectData(selected, 'position');
      } else {
        const selected = await this.getAllGroupPos([row]);
        this.$emit('change', selected, 'position');
      }
    },
    async getAllGroupPos(selection) {
      if (this.currentTargetOrgId !== 'all') {
        return selection;
      }
      const ids = selection.map(v => v.id);
      try {
        const { datas } = await getGroupPositions(ids);
        datas.forEach(item => {
          item.combinationName = item.orgName + '-' + item.name;
          item._type = 1;
        });
        return datas;
      } catch ({ error }) {
        console.log(error);
        this.$message.error(error.message);
        return [];
      }
    },
    handleOrgChange(data) {
      this.params.allgroup = data.allgroup;
      this.currentTargetOrgId = data.orgId;
      this.currentTargetOrgName = data.orgName;
      this.selectOpition.checkWord = '';
      if (data.orgId !== 'all') {
        this.params.targetOrgId = data.orgId;
        this.$delete(this.params, 'allgroup');
      } else {
        this.params.targetOrgId = '';
        this.params.allgroup = 1;
      }
      this.params.name = '';
      this.params.catalogId = '';
      this.getPositionData();
    },
    async loadAll() {
      this.loading = true;
      const limit = 500;
      const reqCount = Math.ceil(this.total / limit); // 循环次数
      const reqArrs = []; // 请求数组
      for (let i = 0; i < reqCount; i++) {
        const params = Object.assign({}, this.params, {
          limit: limit,
          offset: limit * i
        });
        reqArrs.push(params);
      }
      try {
        const results = await Promise.all(
          reqArrs.map(params => getPositionList(params, this.enableGroupCorp))
        );
        const dataSource = [];
        results.forEach(res => {
          dataSource.push(...res.datas);
        });
        let data = dataSource.map(item => {
          item['_type'] = 1;
          if (this.enableGroupCorp && this.visibleOrgSelector) {
            item.combinationName = this.currentTargetOrgName + '-' + item.name;
            item.orgName = this.currentTargetOrgName;
            item.orgId = this.currentTargetOrgId;
          }
          return item;
        });
        const selected = await this.getAllGroupPos(data);
        this.selectAllData(selected, 'position');
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    nodeClick(a) {
      this.params.catalogId = a.id;
      this.selectOpition.checkWord = a.id ? a.name : '';
      this.$refs['selectPopover'].cancel();
      this.getPositionData(true);
    },
    getPositionData(reset = false) {
      this.loading = true;
      if (reset) {
        this.params.offset = 0;
        this.currentPage = 1;
      }
      this.params.dataPermissionCode = this.dataPermissionCode;
      this.params.navCode = this.functionCode;
      getPositionList(this.params, this.enableGroupCorp)
        .then(res => {
          if (res) {
            this.position = res.datas.map(item => {
              item['_type'] = 1;
              if (this.enableGroupCorp && this.visibleOrgSelector) {
                item.combinationName = this.currentTargetOrgName + '-' + item.name;
                item.orgName = this.currentTargetOrgName;
                item.orgId = this.currentTargetOrgId;
              }
              return item;
            });
            this.total = res.paging.count;
            this.performanceUpateSelected('position');
          }
        })
        .catch(e => {
          console.error(e);
        })
        .then(() => {
          this.loading = false;
        });
    },
    handleCurrentChange(val) {
      this.params.offset = (val - 1) * this.params.limit;
      this.getPositionData();
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.params.limit = val;
      this.getPositionData();
    }
  }
};
</script>
