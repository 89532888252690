var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.workList.length,
          expression: "workList.length",
        },
      ],
      staticClass: "yxtbiz-nav-work-bench",
      class: [{ "is-stu": _vm.isStu, tiny: _vm.tiny }],
    },
    [
      _c("yxt-divider", { attrs: { direction: "vertical" } }),
      _vm.isStu
        ? _c(
            "yxtf-dropdown",
            {
              staticClass: "pd10",
              class: {
                "yxtbiz-nav-work-bench__guard-svg": _vm.workBenchGuardVisible,
              },
              attrs: {
                trigger: "hover",
                "custom-elm": "",
                placement: "bottom",
              },
            },
            [
              _c(
                "span",
                { staticClass: "hover-primary-6-i color-gray-9" },
                [
                  _c(
                    "yxtf-badge",
                    {
                      staticClass: "audit-center-enter",
                      class: [{ "is-stu": _vm.isStu, tiny: _vm.tiny }],
                      attrs: {
                        "is-dot": !!_vm.toDoSum && !_vm.workBenchGuardVisible,
                      },
                    },
                    [
                      _c("yxtf-svg", {
                        staticClass: "color-primary-6",
                        attrs: {
                          width: _vm.size,
                          height: _vm.size,
                          "remote-url": _vm.workbenchIconUrl,
                          "icon-class": "nav-workbench--new-10-11-4",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "ml8 lbl hand",
                      style: `font-size:${_vm.fontSizeLbl};`,
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("biz_workbench_nav_title").d("工作台"))
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "yxtf-dropdown-menu",
                {
                  staticClass: "nav-workbench-dropdown",
                  class: [{ "is-stu": _vm.isStu, tiny: _vm.tiny }],
                  attrs: { slot: "dropdown" },
                  slot: "dropdown",
                },
                _vm._l(_vm.workList, function (item) {
                  return _c(
                    "yxtf-dropdown-item",
                    { key: item.code, style: _vm.padding },
                    [
                      _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.openPage(item)
                            },
                          },
                        },
                        [
                          _c(
                            "a-link",
                            {
                              staticClass: "nav-workbench-dropdown_name",
                              style: `font-size:${_vm.fontSize};`,
                              attrs: {
                                "page-node": _vm.transformPageNode(item),
                                url: item.pageUrl,
                              },
                            },
                            [_vm._v(_vm._s(item.name))]
                          ),
                          _vm.isAuditCenter(item) && _vm.toDoSum
                            ? _c("yxt-badge", {
                                staticClass: "ml10",
                                attrs: {
                                  max: 99,
                                  value: _vm.toDoSum,
                                  hidden: !_vm.toDoSum,
                                  size: "mini",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          )
        : _c(
            "yxt-dropdown",
            {
              staticClass: "pd10",
              class: {
                "yxtbiz-nav-work-bench__guard-svg": _vm.workBenchGuardVisible,
              },
              attrs: {
                trigger: "hover",
                "custom-elm": "",
                placement: "bottom",
              },
            },
            [
              _c(
                "span",
                { staticClass: "hover-primary-6-i color-gray-9" },
                [
                  _c(
                    "yxt-badge",
                    {
                      staticClass: "audit-center-enter",
                      class: [{ "is-stu": _vm.isStu, tiny: _vm.tiny }],
                      attrs: {
                        "is-dot": !!_vm.toDoSum && !_vm.workBenchGuardVisible,
                      },
                    },
                    [
                      _c("yxt-svg", {
                        staticClass: "color-primary-6",
                        attrs: {
                          width: _vm.size,
                          height: _vm.size,
                          "remote-url": _vm.workbenchIconUrl,
                          "icon-class": "nav-workbench--new-10-11-4",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "ml8 lbl hand",
                      style: `font-size:${_vm.fontSizeLbl};`,
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("biz_workbench_nav_title").d("工作台"))
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "yxt-dropdown-menu",
                {
                  staticClass: "nav-workbench-dropdown",
                  class: [{ "is-stu": _vm.isStu, tiny: _vm.tiny }],
                  attrs: { slot: "dropdown" },
                  slot: "dropdown",
                },
                _vm._l(_vm.workList, function (item) {
                  return _c(
                    "yxt-dropdown-item",
                    { key: item.code, staticStyle: { padding: "0" } },
                    [
                      _c(
                        "div",
                        {
                          style: _vm.padding,
                          on: {
                            click: function ($event) {
                              return _vm.openPage(item)
                            },
                          },
                        },
                        [
                          _c(
                            "a-link",
                            {
                              staticClass: "nav-workbench-dropdown_name",
                              style: `font-size:${_vm.fontSize};`,
                              attrs: {
                                "page-node": _vm.transformPageNode(item),
                                url: item.pageUrl,
                              },
                            },
                            [_vm._v(_vm._s(item.name))]
                          ),
                          _vm.isAuditCenter(item) && _vm.toDoSum
                            ? _c("yxt-badge", {
                                staticClass: "ml10",
                                attrs: {
                                  max: 99,
                                  value: _vm.toDoSum,
                                  hidden: !_vm.toDoSum,
                                  size: "mini",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }