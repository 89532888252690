import axios from 'axios';
import { fileApi } from 'yxt-biz-pc/packages/api';
import * as util from './utils/utils';

export default {
  // 获取知识库目录树
  getWatermark() {
    return fileApi.get('/watermark/config');
  },
  getBceValid(linkParams, ticket) {
    return fileApi.get(util.linkSubString('/auth/bce/valid', linkParams), {}, {
      headers: {
        ticket
      }
    });
  },
  async getSrt(url) {
    return axios.get(url);
  },
  // 获取播放列表
  getResourceList(params, groupId) {
    return fileApi.get('/play/detailAndWatermark', params, {
      headers: {
        serviceGroup: groupId,
        token: window.localStorage && window.localStorage.getItem('token') || ''
      }
    });
  },
  // 获取机构的服务器组
  getGroupList() {
    return fileApi.get(`local/serviceGroupUploadConfigByOrgid?orgid=${window.localStorage && window.localStorage.getItem('orgId') || ''}`);
  },
  // 获取服务器组的主服务器
  getMainServerList(groupId) {
    return fileApi.get(`local/serviceConfigById?orgid=${window.localStorage && window.localStorage.getItem('orgId') || ''}&groupId=${groupId}`);
  },
  // 获取机构混部的基础信息，机构是否只开启了只允许上传内网课件
  getMixingInfo() {
    return fileApi.get(`/local/baseConfigByIdNew?orgid=${window.localStorage && window.localStorage.getItem('orgId') || ''}`);
  },
  getFileDetail(fileId) {
    return fileApi.get(`/fileInfo/detail?id=${fileId}`);
  }
};
