var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.ShowAi
    ? _c("div", { staticClass: "warp" }, [
        _c(
          "div",
          {
            staticClass: "icon-warp",
            class: _vm.isStu ? "icon-warp-stu" : "",
            on: {
              click: function ($event) {
                _vm.show = true
              },
            },
          },
          [
            !_vm.isStu
              ? _c(
                  "yxt-tooltip",
                  {
                    attrs: {
                      content: _vm.$t("pc_biz_ai_helper_tip").d("企业AI助手"),
                    },
                  },
                  [
                    _c("yxt-image", {
                      staticClass: "ai-icon",
                      class: _vm.tiny ? "is-tiny" : "is-manage",
                      attrs: {
                        src: `${_vm.staticBaseUrl}assets/c3cb0554/36c358cd/aip.png`,
                      },
                    }),
                  ],
                  1
                )
              : _c(
                  "yxtf-tooltip",
                  {
                    attrs: {
                      content: _vm.$t("pc_biz_ai_helper_tip").d("企业AI助手"),
                    },
                  },
                  [
                    _c("yxt-image", {
                      staticClass: "is-stu ai-icon",
                      attrs: {
                        src: `${_vm.staticBaseUrl}assets/c3cb0554/36c358cd/aip.png`,
                      },
                    }),
                  ],
                  1
                ),
          ],
          1
        ),
        _c(
          "div",
          [
            _vm.show
              ? _c("yxtbiz-ai-robot", {
                  attrs: { mode: _vm.isStu ? "student" : "manage" },
                  on: {
                    close: function ($event) {
                      _vm.show = false
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }