import { enrollApi, surveyApi, kngApi, oteApi, o2oApi, bbsApi, utilityApi, cerApi } from 'yxt-biz-pc/packages/api';

// 获取报名项目数
export const getEnrollCount = (params) => {
  return enrollApi.get('my/project/count', {
    params
  });
};

// 项目中心数量
export const getO2oCount = () => {
  return o2oApi.get('/study/project/statistics');
};

// 我的项目数
export const getProjectCount = (params) => {
  return surveyApi.post('projects/my/count', {
    params
  });
};

export const getKngPanel = () => {
  return kngApi.get('/knowledge/panel');
};

// 获取考试数量
export const getUemCount = () => {
  return oteApi.get('/uem/count', {
    type: 1 // 待考试
  });
};

// 调研
export const getSurveyCount = () => {
  return surveyApi.post('/projects/my/count', {});
};

// 获取帖子数量
export const getBbsCount = () => {
  return bbsApi.get('/posts/mylist?limit=1&offset=0');
};
// 收藏数
export const getCollectCount = () => {
  return utilityApi.get('/favorites/mycount', {
    params: {
      targetType: 2 // 知识
    }
  });
};

export const getCerCount = () => {
  return cerApi.get('/issue/my/count');
};
