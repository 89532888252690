<!-- 创建时间2023/11/17 13:39:26 -->
<!-- 创建人：Zhao bing bing -->
<!-- 组件描述：ai-创建课程 -->
<template>
  <KngRelated type="createCourse" v-bind="$attrs" v-on="$listeners"/>
</template>

<script>
import KngRelated from '../common/kng/kng-releated.vue';
export default {
  name: 'KngCreateCourse',
  components: {
    KngRelated
  }
};
</script>