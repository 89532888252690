var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "hover-btn-tools" },
    [
      _c(
        "yxtf-tooltip",
        {
          staticClass: "item",
          attrs: {
            content: _vm.$t("pc_biz_ai_copy").d("复制"),
            placement: "top",
          },
        },
        [
          _c(
            "span",
            {
              on: {
                click: function ($event) {
                  return _vm.$emit("toolClick", "copy")
                },
              },
            },
            [
              _c("yxt-svg", {
                attrs: {
                  "remote-url": _vm.svgUrl,
                  width: 20,
                  height: 20,
                  "icon-class": "copy",
                },
              }),
            ],
            1
          ),
        ]
      ),
      !_vm.isanswer
        ? [
            _c(
              "yxtf-tooltip",
              {
                staticClass: "item",
                attrs: {
                  content: _vm.$t("pc_biz_ai_search").d("搜一搜"),
                  placement: "top",
                },
              },
              [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.$emit("toolClick", "search")
                      },
                    },
                  },
                  [
                    _c("yxt-svg", {
                      attrs: {
                        "remote-url": _vm.svgUrl,
                        width: 20,
                        height: 20,
                        "icon-class": "search",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }