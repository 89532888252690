<template>
  <div class="draggable-container" :style="containerStyle">
    <div class="draggable-mask" v-if="isDragging || isMoveing"></div>
    <!-- <div v-if="!bFullscreen" class="draggable-scale" @mousedown="startDrag"></div> -->
    <div v-if="!bFullscreen" class="draggable-move" @mousedown.stop="startMove"></div>
    <slot></slot>
  </div>
</template>

<script>
const throttle = (fn, time) => {
  let timer = null;
  return (...args) => {
    if (!timer) {
      timer = setTimeout(() => {
        timer = null;
        fn.apply(this, args);
      }, time);
    }
  };
};
export default {
  name: 'DraggableContainer',
  props: {
    bFullscreen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isDragging: false,
      isMoveing: false,
      startX: 0,
      startY: 0,
      right: 0,
      bottom: 0,
      defaultRight: 20,
      defaultBottom: 20,
      containerWidth: 480,
      containerHeight: window.document.body.clientHeight || 1080,
      bodyWidth: window.document.body.clientWidth || 1920, // body宽度
      bodyHeight: window.document.body.clientHeight || 1080 // body高度
    };
  },
  computed: {
    containerStyle() {
      return this.bFullscreen
        ? { width: '100%', height: '100%' }
        : { width: this.containerWidth + 'px', height: '100%', right: this.right + 'px', bottom: this.bottom + 'px' };
    }
  },
  methods: {
    startDrag(event) {
      if (this.bFullscreen) return;
      this.isDragging = true;
      this.startX = event.clientX;
      this.startY = event.clientY;
      document.addEventListener('mousemove', throttle(this.drag));
      document.addEventListener('mouseup', this.stopDrag);
    },
    drag(event) {
      if (this.isDragging) {
        console.log('drag', event);
        const deltaX = this.startX - event.clientX;
        const deltaY = this.startY - event.clientY;
        let w = this.containerWidth + deltaX;
        let h = this.containerHeight + deltaY;
        if (event.offsetY < 50) {
          h = this.containerWidth - deltaY;
        }
        if (event.offsetX < 100) {
          w = this.containerWidth - deltaX;
        }
        this.containerWidth = w < 480 ? 480 : (w > 900 ? 900 : w); // 限制最大宽度
        this.containerHeight = h < 620 ? 620 : (h > 780 ? 780 : h); // 限制最大高度
        this.startX = event.clientX;
        this.startY = event.clientY;
      }
    },
    stopDrag(e) {
      this.isDragging = false;
      this.$emit('resize');
      document.removeEventListener('mousemove', this.drag);
      document.removeEventListener('mouseup', this.stopDrag);
    },
    // 移动相关逻辑
    startMove(event) {
      if (this.bFullscreen) return;
      this.isMoveing = true;
      this.startX = event.pageX;
      this.startY = event.pageY;
      document.addEventListener('mousemove', throttle(this.move, 5));
      document.addEventListener('mouseleave', this.stopMove);
      document.addEventListener('mouseup', this.stopMove);
    },
    move(event) {
      event.stopPropagation();
      if (this.isMoveing) {
        let offsetX = this.startX - event.pageX + this.defaultRight;
        let offsetY = this.startY - event.pageY + this.defaultBottom;
        if (offsetX > 20 && offsetX < (this.bodyWidth - 540)) {
          this.right = offsetX;
        }
        if (offsetY > 20 && offsetY < (this.bodyHeight - 640)) {
          this.bottom = offsetY;
        }
      }
    },
    stopMove() {
      this.defaultBottom = this.bottom;
      this.defaultRight = this.right;
      this.isMoveing = false;
      this.$emit('resize');
      document.removeEventListener('mousemove', this.move);
      document.removeEventListener('mouseleave', this.stopMove);
      document.removeEventListener('mouseup', this.stopMove);
    }
  },
  beforeDestroy() {
    document.removeEventListener('mousemove', this.move);
    document.removeEventListener('mouseleave', this.stopMove);
    document.removeEventListener('mouseup', this.stopMove);
  }
};
</script>
