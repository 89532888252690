<template>
  <div class="yxtbiz-select-cers">
    <div class="clearfix font-size-14">
      <yxt-select
        class="pull-left mr12"
        clearable
        v-model="datasSearch.catalogId"
        :placeholder="
          $t('pc_biz_lbl_selectcatalogforsearch', [$t('pc_biz_cer_lbl_cer')])
        "
        @change="getCerList(true)"
      >
        <yxt-option
          v-for="item in catalogs"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></yxt-option>
      </yxt-select>
      <yxt-input
        style="width: 240px"
        class="pull-left"
        :placeholder="
          $t('pc_biz_lbl_inputnameforsearch', [$t('pc_biz_cer_lbl_cername')])
        "
        v-model="datasSearch.name"
        searchable
        @search="cerNameSearch"
        maxlength="50"
      >
      </yxt-input>
    </div>
    <div class="yxtbiz-select-cers__main mt16">
      <yxt-table
        v-loading="loading"
        :data="datasList"
        @select-all="selectAll"
        @select="select"
        :ref="refTable"
        :height="tableHeight"
        class="yxtbiz-select-cers__table"
        :default-sort="{ prop: 'updateTime', order: 'desc' }"
        @sort-change="sortChange"
      >
        <yxt-table-column
          :selectable="selectable"
          type="selection"
          :show-overflow-tooltip="false"
          width="38"
          align="right"
          clear-padding="left-right"
        ></yxt-table-column>
        <yxt-table-column
          :label="$t('pc_biz_cer_lbl_cername')"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.name }}
          </template>
        </yxt-table-column>
        <yxt-table-column
          :key="1"
          :label="$t('pc_biz_cer_lbl_valid_month')"
          prop="validMonth"
          width="200"
        ></yxt-table-column>
      </yxt-table>
    </div>
    <div class="mt16 pr clearfix">
      <yxt-pagination
        class="pull-right"
        :page-size="datasSearch.limit"
        :total="count"
        :current-page="page"
        @size-change="sizeChange"
        @current-change="pageChange"
        layout="total, prev, pager, next"
        :page-count="totalPage"
      ></yxt-pagination>
    </div>
  </div>
</template>

<script>
import { getCerListOG, getCatalogListOG } from './service';
import resizeTable from '../mixins/resizeTable';
import CheckList from './components/checkList';
import { Table, TableColumn, Pagination, Tooltip, Tree } from 'yxt-pc';

export default {
  name: 'YxtbizSelectCertificates',
  components: {
    CheckList,
    YxtTable: Table,
    YxtTableColumn: TableColumn,
    YxtPagination: Pagination,
    YxtTooltip: Tooltip,
    YxtTree: Tree
  },
  mixins: [resizeTable],
  props: {
    value: {
      type: Array,
      default: []
    },
    selectable: {
      type: Function,
      default: () => true
    }
  },
  data() {
    return {
      refTable: 'stb',
      tableHeight: 0,
      page: 1,
      datasSearch: {
        catalogId: '',
        name: '',
        limit: 20,
        offset: 0,
        orderby: 'updateTime',
        direction: 'DESC'
      },
      loading: true,
      totalPage: 0,
      count: 0,
      datasList: [],
      selected: {},
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    };
  },
  watch: {
    value(v) {
      this.formatModel(v);
    },
    'datasSearch.catalogId'() {
      this.getCerList(true);
    }
  },
  created() {
    this.formatModel(this.value);
    this.getCerList();
    this.getCatalogList();
  },
  methods: {
    formatModel(v) {
      this.selected = {};
      if (v) {
        v.forEach(obj => {
          this.selected[obj.id] = obj;
        });
        this.setChecked();
      }
    },
    setChecked() {
      this.datasList.forEach(obj => {
        if (this.selected.hasOwnProperty(obj.id)) {
          this.$refs[this.refTable].toggleRowSelection(obj, true);
        } else {
          this.$refs[this.refTable].toggleRowSelection(obj, false);
        }
      });
    },
    filterNode(value, data) {
      return !value || data.label.indexOf(value) !== -1;
    },
    getCerList(isReset) {
      if (isReset) {
        this.page = 1;
        this.datasSearch.offset = 0;
      }
      this.datasSearch.name = this.datasSearch.name.trim();
      this.loading = true;
      getCerListOG(this.datasSearch)
        .then(result => {
          this.datasList = result.datas;
          this.datasList.forEach(data => {
            delete data.sourceType;
          });
          this.totalPage = result.paging.pages;
          this.count = result.paging.count;
          this.loading = false;
          this.$nextTick(() => {
            this.setChecked();
          });
        })
        .catch(e => {
          console.log(e);
        });
    },
    getCatalogList() {
      getCatalogListOG().then(result => {
        this.catalogs = result;
      });
    },
    cerNameSearch() {
      this.getCerList(true);
    },
    sortChange(sort) {
      this.datasSearch.direction = sort.order;
      this.datasSearch.orderby = sort.prop;
      this.getCerList();
      return false;
    },
    sizeChange(size) {
      this.datasSearch.limit = size;
      this.getCerList(true);
    },
    pageChange(page) {
      this.page = page;
      this.datasSearch.offset = (page - 1) * this.datasSearch.limit;
      this.getCerList();
    },
    // 选择某个
    select(item, row) {
      let isAdd = item.some(v => {
        return v.id === row.id;
      });
      if (!isAdd) {
        delete this.selected[row.id];
      } else {
        this.selected[row.id] = row;
      }
      this.outPut();
    },
    // 全选
    selectAll(items) {
      let isAdd = items.filter((item) => {
        return this.selectable(item);
      }).length !== 0;
      this.datasList.forEach(obj => {
        if (this.selectable(obj)) {
          if (!isAdd) {
            delete this.selected[obj.id];
          } else {
            this.selected[obj.id] = obj;
          }
        }
      });
      this.outPut();
    },
    outPut() {
      const selArray = Object.values(this.selected);
      this.$emit('input', selArray);
      this.$emit('select', selArray);
    }
  }
};
</script>
