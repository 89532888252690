<template>
  <yxtf-popover
    class="ai-header__icon"
    placement="top"
    trigger="hover"
    effect
  >
    <div>{{ popText }}</div>

    <span
      v-if="iconName === 'share'"
      @click.stop="$emit('click')"
      slot="reference"
    >
      <yxt-svg
        :remote-url='svgUrl'
        class="color-gray-7 hover-primary-6 hand"
        :width="iconSize"
        :height="iconSize"
        :icon-class="iconName"
      /></span>
    <span
      v-else-if="iconName === 'ai-closed'"
      @click.stop="$emit('click')"
      slot="reference"
    >
      <yxt-svg
        :remote-url='pcURL'
        class="color-gray-7 hover-primary-6 hand"
        :width="iconSize"
        :height="iconSize"
        :icon-class="iconName"
      /></span>
    <span
      v-else-if="iconName === 'ai-screen' || iconName ==='ai-window'"
      @click.stop="$emit('click')"
      slot="reference"
    >
      <yxt-svg
        :remote-url='pcURL'
        class="color-gray-7 hover-primary-6 hand"
        :width="iconSize"
        :height="iconSize"
        :icon-class="iconName"
      /></span>
    <span
      v-else
      @click="$emit('click')"
      slot="reference"
    >
      <yxt-svg
        :remote-url='svgUrl'
        class="color-gray-7 hover-primary-6 hand"
        :width="iconSize"
        :height="iconSize"
        :icon-class="iconName"
      />
    </span>
  </yxtf-popover>
</template>

<script>
import { getStaticBaseUrl } from '../service';
export default {
  name: 'HeaderIcon',
  props: {
    remote: {
      type: Boolean,
      default: false
    },
    popText: {
      type: String,
      default: ''
    },
    iconName: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 20
    }
  },
  computed: {
    iconSize() {
      return this.size + 'px';
    }
  },
  data() {
    return {
      pcURL: `${getStaticBaseUrl()}ufd/55a3e0/assistent/pc/svg`,
      h5URL: `${getStaticBaseUrl()}ufd/55a3e0/assistent/h5/svg`,
      svgUrl: this.remote ? `${getStaticBaseUrl()}assets/14a8350a/9a77b0e7/` : ''
    };
  }
};
</script>

<style lang="scss">
.ai-header__icon {
  svg {
    g,
    path {
      fill: currentColor;
    }
  }
}
</style>
