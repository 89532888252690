var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "yxtbiz-i18n-text" }, [
    _c(
      "div",
      [
        _c(
          "yxt-input",
          _vm._b(
            {
              attrs: {
                type: _vm.dataObj.type,
                placeholder: _vm.getTranslate("pc_biz_core_tip_placeholder"),
                maxlength: _vm.getMaxlength(),
                "show-word-limit":
                  _vm.dataObj.rule && _vm.dataObj.rule.showWordLimit,
              },
              on: { focus: _vm.openDrawer },
              model: {
                value: _vm.transObj.trans,
                callback: function ($$v) {
                  _vm.$set(_vm.transObj, "trans", $$v)
                },
                expression: "transObj.trans",
              },
            },
            "yxt-input",
            _vm.transObj.rule,
            false
          ),
          [
            _vm.dataObj.type == "text"
              ? _c(
                  "template",
                  { slot: "suffix" },
                  [
                    _c("yxt-svg", {
                      staticClass:
                        "yxt-input-suffix-svg-btn hover-primary-6 yxtbiz-i18n-text__icon-hover",
                      attrs: {
                        width: "16px",
                        height: "16px",
                        "icon-class": "language",
                      },
                      nativeOn: {
                        click: function ($event) {
                          _vm.drawer = true
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.dataObj.type == "textarea"
              ? _c("i", {
                  staticClass: "yxt-icon-edit yxt-input__icon",
                  attrs: { slot: "suffix" },
                  slot: "suffix",
                })
              : _vm._e(),
          ],
          2
        ),
        _vm.dataObj.type == "textarea"
          ? _c(
              "div",
              {
                staticClass:
                  "yxtbiz-i18n-text__icon-btn yxtbiz-i18n-text__icon-hover",
                on: {
                  click: function ($event) {
                    _vm.drawer = true
                  },
                },
              },
              [
                _c("yxt-svg", {
                  staticClass: "yxt-input-suffix-svg-btn hover-primary-6",
                  attrs: {
                    width: "16px",
                    height: "16px",
                    "icon-class": "language",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _vm.drawer
      ? _c(
          "div",
          [
            _c("yxtbiz-i18n-lang", {
              attrs: { visible: _vm.drawer, "data-list": _vm.dataObj },
              on: {
                "update:visible": function ($event) {
                  _vm.drawer = $event
                },
                confirm: _vm.getResult,
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }