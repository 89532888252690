var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yxtbiz-ai-robot__treasure-body-wrap" },
    [
      !_vm.list || _vm.list.length === 0
        ? _c("yxtf-empty", {
            staticClass: "pos-center",
            attrs: {
              size: "medium",
              "empty-text": _vm.$t("pc_biz_lbl_empty_app").d("暂无应用"),
            },
          })
        : _vm._l(_vm.list, function (item, index) {
            return _c("div", { key: index }, [
              _c(
                "div",
                { staticClass: "yxtbiz-ai-robot__treasure-body--title" },
                [_vm._v(_vm._s(item.categoryName))]
              ),
              _c(
                "div",
                { staticClass: "yxtbiz-ai-robot__treasure-body--list" },
                _vm._l(item.appBeanList, function (sItem) {
                  return _c(
                    "div",
                    {
                      key: sItem.id,
                      staticClass: "yxtbiz-ai-robot__treasure-body--item hand",
                      on: {
                        click: function ($event) {
                          return _vm.handleUseBtn(sItem)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "yxtbiz-ai-robot__treasure-body--head" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "yxtbiz-ai-robot__treasure-body--description",
                            },
                            [
                              _c("yxt-image", {
                                staticClass:
                                  "yxtbiz-ai-robot__treasure-body--img",
                                attrs: {
                                  src:
                                    sItem.icon ||
                                    _vm.$staticBaseUrl +
                                      "ufd/55a3e0/ai-select.png",
                                },
                              }),
                              _c("span", { staticClass: "ellipsis" }, [
                                _vm._v(_vm._s(sItem.appName)),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "yxtf-tooltip",
                            {
                              attrs: {
                                disabled: !(
                                  !_vm.getCollectDisabled &&
                                  sItem.subscribe === 1
                                ),
                                content: _vm
                                  .$t("pc_biz_msg_set_max_10_app")
                                  .d("最多设置10个常用应用"),
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "yxtbiz-ai-robot__treasure-body--collect",
                                  class: {
                                    "yxtbiz-ai-robot__treasure-body--ncollect":
                                      sItem.subscribe === 0,
                                    "yxtbiz-ai-robot__treasure-body--subscribe":
                                      sItem.subscribe === 1,
                                    "no-allow":
                                      !_vm.getCollectDisabled &&
                                      sItem.subscribe === 1,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.handleCollect(item, sItem)
                                    },
                                  },
                                },
                                [
                                  _c("yxt-svg", {
                                    staticClass:
                                      "yxtbiz-ai-robot__treasure-body--collect-svg",
                                    attrs: {
                                      "remote-url":
                                        _vm.$staticBaseUrl + "ufd/55a3e0",
                                      width: 28,
                                      height: 28,
                                      "icon-class":
                                        sItem.subscribe === 0
                                          ? "collect"
                                          : "ncollect",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "yxtbiz-ai-robot__treasure-body--content ellipsis-2",
                        },
                        [
                          _vm._v(
                            "\n          " + _vm._s(sItem.guide) + "\n        "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "yxtbiz-ai-robot__treasure-body--footer",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "yxtbiz-ai-robot__treasure-body--footer-chat",
                            },
                            [
                              _c("yxt-svg", {
                                staticClass: "mt6",
                                attrs: {
                                  "remote-url":
                                    _vm.$staticBaseUrl + "ufd/55a3e0",
                                  width: 20,
                                  height: 20,
                                  "icon-class": "chat-ai",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "yxtf-tooltip",
                            {
                              attrs: {
                                disabled: sItem.permission,
                                content: _vm
                                  .$t("pc_biz_msg_permison_cancel_common")
                                  .d("已无当前知识助手使用权限，可取消常用"),
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "yxt-button",
                                {
                                  staticClass:
                                    "yxtbiz-ai-robot__treasure-body--footer-operation",
                                  attrs: {
                                    disabled: !sItem.permission,
                                    type: "primary",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t("pc_biz_lbl_use_btn").d("使用")
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                0
              ),
            ])
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }