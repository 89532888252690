<template>
  <div class="navigation">
    <ul>
      <li v-for="(d,index) in data" :key="index" @click="handleShowNav(d)">{{d.name}}</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    data: Array
  },
  data() {
    return {

    };
  },
  methods: {
    // 进入web端查看导航内容
    handleShowNav(item) {
      if (!item) return;
      window.open(`${window.location.origin}/support/#/question?directoryId=${item.directoryId}`, '_blank');
    }
  }
};
</script>
