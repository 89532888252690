var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "hand flex-1 width-percent-100 yxtbiz-ai-robot-knglib__cell-content",
      on: {
        click: function ($event) {
          return _vm.toDetail(_vm.data.id)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "pull-left" },
        [
          _c("yxtf-svg", {
            attrs: {
              width: "52px",
              height: "52px",
              "icon-class": _vm.getSvgName(_vm.data.fileType),
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "ml64" }, [
        _c(
          "div",
          {
            class: [
              _vm.size === "small" ? "font-size-14 lh22" : "font-size-16  lh24",
              "yxtbiz-ai-robot-knglib__title color-gray-10",
            ],
          },
          [
            _c("div", { staticClass: "ellipsis" }, [
              _vm._v("\n        " + _vm._s(_vm.data.title) + "\n      "),
            ]),
            _c("div", { staticClass: "yxtbiz-ai-robot-knglib__title-suffix" }, [
              _vm._v("\n        " + _vm._s(_vm.data.fileExt) + "\n      "),
            ]),
          ]
        ),
        _c(
          "span",
          {
            staticClass:
              "ellipsis color-gray-7 standard-size-12 d-in-block mt8 yxtbiz-ai-robot-knglib__item-from",
          },
          [_vm._v(_vm._s(_vm.data.kngLibName))]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }