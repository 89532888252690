<template>
  <div class="yxtbiz-msg-editor-v2" :class="{inline:formInline}">
    <yxt-scrollbar style="height: 100%" >
      <div class="yxtbiz-msg-editor-v2__body">
        <div class=" yxtbiz-msg-editor-v2__title color-gray-10 text-center">{{ title }}
        </div>
        <yxt-form
          :inline="formInline"
          :rules="rules"
          ref="ruleForm"
          :model="ruleForm"
          class="yxtbiz-msg-editor-v2__form"
          label-position="top"
        >
        <!-- 姓名 -->
          <yxt-form-item prop="fullname" :label="$t('pc_biz_core_lbl_name')" 
          :class="isInlineRight('fullname')" 
          v-if="checkStatus('fullname')">
            <yxt-input
              maxLength="50"
              v-model="ruleForm.fullname"
              :placeholder="$t('pc_biz_core_tip_enterName')"
            ></yxt-input>
          </yxt-form-item>
          <!-- 手机号 -->
          <yxt-form-item
            :class="isInlineRight('mobile')" 
            v-if="checkStatus('mobile')"
            prop="mobile"
            :label="$t('pc_biz_core_lbl_phoneNumber')"
          >
            <yxt-input
              maxLength="11"
              v-model="ruleForm.mobile"
              :disabled="mobileDisabled"
              :placeholder="$t('pc_biz_core_tip_enterPhoneNumber')"
            ></yxt-input>
          </yxt-form-item>
          <!-- 城市 -->
          <yxt-form-item
            prop="city"
            :class="isInlineRight('city')" 
            v-if="checkStatus('city')"
            :label="$t('pc_biz_core_lbl_city')"
          >
            <yxtbiz-area-select
              ref="city"
              :placeholder="$t('pc_biz_core_lbl_city')"
              v-model="ruleForm.city"
              :value="defaultCity"
              @change="changeCity"
            ></yxtbiz-area-select>
          </yxt-form-item>
          <!-- 公司 -->
          <yxt-form-item
            prop="company"
            :class="isInlineRight('company')" 
            v-if="checkStatus('company')"
            :label="$t('pc_biz_core_lbl_company')"
          >
            <yxt-input
              maxLength="50"
              v-model="ruleForm.company"
              :placeholder="$t('pc_biz_core_tip_enterCompany')"
            ></yxt-input>
          </yxt-form-item>
          <!-- 部门 -->
          <yxt-form-item
            prop="department"
            :class="isInlineRight('department')" 
            v-if="checkStatus('department')"
            :label="$t('pc_biz_core_lbl_department')"
          >
            <yxt-input
              maxLength="50"
              v-model="ruleForm.department"
              :placeholder="$t('pc_biz_core_tip_enterDepartment')"
            ></yxt-input>
          </yxt-form-item>
          <!-- 岗位 -->
          <yxt-form-item
            prop="position"
            :class="isInlineRight('position')" 
            v-if="checkStatus('position')"
            :label="$t('pc_biz_core_lbl_position')"
          >
            <yxt-input
              maxLength="50"
              v-model="ruleForm.position"
              :placeholder="$t('pc_biz_core_tip_enterPosition')"
            ></yxt-input>
          </yxt-form-item>
          <!-- 工号 -->
          <yxt-form-item
            prop="jobNo"
            :class="isInlineRight('jobNo')" 
            v-if="checkStatus('jobNo')"
            :label="$t('pc_biz_core_lbl_userNo')"
          >
            <yxt-input
              maxLength="50"
              v-model="ruleForm.jobNo"
              :placeholder="$t('pc_biz_core_tip_enterUserNo')"
            ></yxt-input>
          </yxt-form-item>
          <!-- 邮箱 -->
          <yxt-form-item
            prop="email"
            :class="isInlineRight('email')" 
            v-if="checkStatus('email')"
            :label="$t('pc_biz_core_lbl_mail')"
          >
            <yxt-input
              maxLength="50"
              v-model="ruleForm.email"
              :placeholder="$t('pc_biz_core_tip_enterMail')"
            ></yxt-input>
          </yxt-form-item>
          <!-- 身份证 -->
          <yxt-form-item
            prop="idNo"
            :class="isInlineRight('idNo')" 
            v-if="checkStatus('idNo')"
            :label="$t('pc_biz_core_lbl_identitycard')"
          >
            <yxt-input
              maxLength="50"
              v-model="ruleForm.idNo"
              :placeholder="$t('pc_biz_core_tip_enterIdentitycard')"
            ></yxt-input>
          </yxt-form-item>
          <!-- 性别 -->
          <yxt-form-item
            prop="sex"
            :class="isInlineRight('sex')" 
            v-if="checkStatus('sex')"
            :label="$t('pc_biz_core_lbl_gender')"
          >
            <yxt-select v-model="ruleForm.sex" :placeholder="$t('pc_biz_core_tip_entersex')" style="width:100%;">
               <yxt-option
                :label="$t('pc_biz_core_lbl_female')"
                value="2"
              >
              </yxt-option>
              <yxt-option
                :label="$t('pc_biz_core_lbl_male')"
                value="1"
              >
              </yxt-option>
            </yxt-select>
          </yxt-form-item>
          <yxt-form-item style="margin-top: 40px;width: 100%;">
            <div class="text-center" style="line-height: normal;">
              <yxt-button type="primary" @click="confirm"  size="large">{{
                $t('pc_biz_core_btn_determine')
              }}</yxt-button>
            </div>
          </yxt-form-item>
        </yxt-form>
      </div>
    </yxt-scrollbar>
  </div>
</template>

<script>
import { saveVisitorExtInfo, getVisitorInfo } from './service';
import YxtbizAreaSelect from 'yxt-biz-pc/packages/area-select';
import { Scrollbar, Form, FormItem, RadioGroup, Radio, Link } from 'yxt-pc';
const fromItemList = ['fullname', 'mobile', 'city', 'company', 'department', 'position', 'jobNo', 'email', 'idNo', 'sex'];
export default {
  name: 'YxtbizMsgEditorV2',
  components: {
    YxtbizAreaSelect,
    YxtScrollbar: Scrollbar,
    YxtForm: Form,
    YxtFormItem: FormItem,
    YxtRadioGroup: RadioGroup,
    YxtRadio: Radio,
    YxtLink: Link
  },
  props: {
    title: {
      default: '',
      type: String
    },
    orgId: {
      required: false
    },
    data: {
      type: Object,
      default() {
        return {
          required: [],
          optional: []
        };
      }
    }
  },
  data() {
    return {
      list: [],
      ruleForm: {
        fullname: '', // 姓名
        mobile: '', // 手机号
        jobNo: '', // 工号
        sex: '', // 性别 1-男，2女
        email: '', // 邮箱
        company: '', // 公司
        department: '', // 部门
        position: '', // 岗位
        idNo: '', // 身份证号
        city: []// 城市
      },
      mobileDisabled: false,
      defaultCity: ['120000', '120100', '120101'],
      cityObj: {},
      rules: {
        fullname: {
          required: false,
          message: this.$t('pc_biz_core_msg_enterName'),
          trigger: 'blur'
        },
        mobile: [
          {
            required: false,
            message: this.$t('pc_biz_core_msg_enterPhoneNumber'),
            trigger: 'blur'
          },
          {
            pattern: /^1([3-9])[0-9]{9}$/,
            message: this.$t('pc_biz_msg_phone_enter_error'),
            trigger: 'blur'
          }
        ],
        city: {
          required: false,
          message: this.$t('pc_biz_core_lbl_city'),
          trigger: 'blur'
        },
        company: {
          required: false,
          message: this.$t('pc_biz_core_tip_enterCompany'),
          trigger: 'blur'
        },
        department: {
          required: false,
          message: this.$t('pc_biz_core_tip_enterDepartment'),
          trigger: 'blur'
        },
        position: {
          required: false,
          message: this.$t('pc_biz_core_tip_enterPosition'),
          trigger: 'blur'
        },
        jobNo: {
          required: false,
          message: this.$t('pc_biz_core_tip_enterUserNo'),
          trigger: 'blur'
        },
        email: [{
          required: false,
          message: this.$t('pc_biz_core_tip_enterMail'),
          trigger: 'blur'
        },
        {
          pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
          message: this.$t('pc_biz_msg_email_enter_error'),
          trigger: 'blur'
        }],
        idNo: [{
          required: false,
          message: this.$t('pc_biz_core_tip_enterIdentitycard'),
          trigger: 'blur'
        },
        {
          pattern: /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/,
          message: this.$t('pc_biz_msg_credict_enter_error'),
          trigger: 'blur'
        }],
        sex: {
          required: false,
          trigger: 'blur',
          message: this.$t('pc_biz_core_tip_entersex')
        }
      },
      formInline: false
    };
  },
  computed: {
    // 所有需要展示的项
    allList() {
      return fromItemList.filter(v=>{
        return (this.data.required || []).includes(v) || (this.data.optional || []).includes(v);
      });
    }
  },
  created() {
    this.initData();
  },
  methods: {
    // 初始化表单配置
    isInlineRight(str) {
      let i = this.allList.findIndex(s=>{
        return s === str;
      });
      return {
        'inline-right': i % 2 === 1
      };
    },
    // 初始化数据
    initData() {
      getVisitorInfo({
        orgId: this.orgId,
        token: window.localStorage.getItem('token')
      }).then(res=>{
        this.ruleForm.mobile = res.phone || window.localStorage.getItem('vMobile') || '';
        this.ruleForm.email = res.email || window.localStorage.getItem('vEmail') || '';
        this.ruleForm.fullname = res.fullname || window.localStorage.getItem('vFullname') || '';
        this.mobileDisabled = !!(this.mobile && this.mobile.length);

        if (res.extInfo) {
          const extInfo = JSON.parse(res.extInfo);
          for (const key in extInfo) {
            if (extInfo[key]) {
              if (key === 'mobile') {
                this.ruleForm[key] = extInfo[key];
                this.mobileDisabled = !!(extInfo[key] && extInfo[key].length);
              } else if (key === 'city') {
                let cityObj = extInfo[key];
                if (typeof cityObj === 'string') {
                  cityObj = JSON.parse(extInfo[key]) || null;
                }
                if (cityObj) {
                  this.ruleForm[key] = cityObj;
                  this.cityObj = cityObj;
                  this.defaultCity = cityObj.ids || [];
                  setTimeout(() => {
                    this.$refs.city && (this.$refs.city.$refs.areaSelect.inputValue = cityObj.names ? cityObj.names.join('') : '');
                  }, 1000);
                }
              } else {
                this.ruleForm[key] = extInfo[key];
              }
            }
          }
          // console.log(this.ruleForm);
        }
      }).catch(e=>{
      });
    },
    confirm() {
      let result = {};
      this.allList.forEach(key => {
        if (key === 'city') {
          result[key] = JSON.stringify(this.cityObj);
        } else {
          result[key] = this.ruleForm[key];
        }
      });

      this.$refs['ruleForm'].validate().then(res => {
        this.save(result);
      });
    },
    save(data) {
      saveVisitorExtInfo({
        token: window.localStorage.getItem('token'),
        extInfo: JSON.stringify(data),
        orgId: this.orgId
      }).then(res=>{
        if (res.success) {
          this.$emit('getData', data);
        }
      }).catch(e=>{
        if (e.error) {
          e = e.error;
        }
        this.$message.error(this.$t(e.key));
      });
    },
    change() {
      // 刷新视图
      this.$forceUpdate();
    },
    changeCity(city) {
      this.$refs['ruleForm'].validateField('city');
      this.cityObj = city;
    },
    checkStatus(label) {
      return this.allList.includes(label);

    }
  },
  watch: {
    ruleForm: {
      deep: true,
      handler() {}
    },
    data: {
      immediate: true,
      handler(v) {
        if (this.data.required) {
          this.data.required.forEach(key => {
            if (this.rules[key]) {
              const rules = this.rules[key];
              if (!Array.isArray(rules)) {
                rules.required = true;
              } else {
                const r = rules.find(r=>{
                  return r.required !== undefined;
                });
                r && (r.required = true);
              }
            }
          });
        }
        this.formInline = this.allList.length > 6 ;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
