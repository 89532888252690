var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "article",
    { staticClass: "yxtbiz-ai-robot-my-favorite" },
    [
      _vm.dataList.length === 0
        ? _c("EmptyData")
        : [
            _c(
              "header",
              {
                class: [
                  "color-gray-7",
                  _vm.size === "small"
                    ? "font-size-14 lh24"
                    : "font-size-16 lh28",
                ],
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$t(
                        "yxtbiz_ai_lbl_followingfavorites" /* 为您找到以下{0}收藏 */,
                        [_vm.filterTitle]
                      )
                    ) +
                    "\n    "
                ),
              ]
            ),
            _c(
              "main",
              [
                _c(
                  "ul",
                  _vm._l(_vm.dataList, function (item) {
                    return _c(
                      "li",
                      {
                        key: item.id,
                        staticClass: "yxtbiz-ai-robot-my-favorite__item",
                      },
                      [
                        _c(_vm.componentName, {
                          ref: _vm.componentName,
                          refInFor: true,
                          tag: "components",
                          attrs: {
                            params: _vm.params,
                            size: _vm.size,
                            data: item,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
                _vm.count > 3
                  ? _c("ViewMoreButton", {
                      on: { click: _vm.handleViewMoreClick },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }