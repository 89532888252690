var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ClickToJump", {
    attrs: {
      message: _vm.$t(
        "pc_biz_ote_click_to_exam" /* 好的,正在为您唤起创建考试页面 */
      ),
      "target-url": _vm.targetUrl,
      datas: _vm.datas,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }