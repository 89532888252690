<template>
    <div class="yxtbiz-ai-robot__confirm width-percent-100">
      <simpleAnswer :msg="getTitleMsg" class="mb8" />
        <div class="yxtbiz-ai-robot__form" v-show="isShow">
            <yxt-form :model="ruleForm" label-position="top" ref="ruleForm" :rules="rules" size="small">
              <template v-for="(form, index) in formData">
                <yxt-form-item v-if="form.componentType === 'input'" class="mb16" :label="form.labelName" :prop="form.name"  :key="index">
                  <yxt-input v-model="form.value" @input="(v)=>ruleForm[form.name] = v" maxlength="200" show-word-limit :placeholder="$t('pc_biz_core_tip_placeholder')" size="small" :disabled="disabled"></yxt-input>
                </yxt-form-item>
                <yxt-form-item v-else-if="form.componentType === 'input-number'" class="mb16" :label="form.labelName" :prop="form.name"  :key="index">
                  <yxt-input-number class="w120" size="small" :disabled="disabled" v-model="form.value" @input="(v)=>ruleForm[form.name] = v" :min="1" :max="200"></yxt-input-number>
                </yxt-form-item>
                <yxt-form-item v-else-if="form.componentType === 'select'" class="mb16" :label="form.labelName" :prop="form.name"  :key="index">
                  <yxt-select class="width-percent-100" size="small" clearable :disabled="disabled" :placeholder="$t('pc_biz_lbl_selecttip')" v-model="form.value" @change="(v)=>ruleForm[form.name] = v" >
                      <yxt-option
                        v-for="item in form.list"
                        :key="item.value"
                        :label="item.labelName"
                        :value="item.value"
                      />
                  </yxt-select>
                </yxt-form-item>
                <yxt-form-item v-else-if="form.componentType === 'date'" class="mb16" :label="form.labelName" :prop="form.name" :key="index">
                  <yxt-date-picker
                    v-model="form.value"
                    class="width-percent-100-i"
                    type="date"
                    size="small"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    :disabled="disabled"
                    @change="(v)=>ruleForm[form.name] = v"
                    :placeholder="$t('pc_biz_lbl_selecttip')" />
                </yxt-form-item>
                <template v-else>
                  <component
                    v-if="form.componentType" 
                    :is="form.componentType"
                    v-model="form.value"
                    @change="v=>handleChange(form, v)"
                    :config="form"
                    :ruleForm="ruleForm"
                    :key="index"
                    :disabled="disabled" />
                </template>
              </template>
            </yxt-form>
            <div class="btn-item">
              <yxt-button :disabled="disabled" plain @click="handleReset">{{$t('pc_comp_table_resetFilter').d('重置')}}</yxt-button>
              <yxt-button type="primary" :disabled="disabled" :loading="loading" @click="submit">{{ $t('pc_biz_common_btn_sure').d('确定') }}</yxt-button>
            </div>
        </div>
    </div>
</template>
<script>
import simpleAnswer from './simpleAnswer.vue';

const files = require.context('../../biz-form-components', true, /\.vue$/);
const components = {};

files.keys().forEach(key => {
  const name = key.replace(/^.+\/([^/]+)\/[^/]+\.vue/, '$1');
  if (['common'].includes(name)) return;
  const file = files(key).default || files(key);
  components[file.name] = file;
});

export default {
  components: {
    simpleAnswer,
    ...components
  },
  props: {
    msg: { // 回答的消息体
      type: Object,
      default: () => ({})
    },
    isWaitAnswer: Boolean
  },
  data() {
    return {
      loading: false,
      ruleForm: {},
      formData: [],
      rules: {},
      timer: null,
      disabled: false,
      isShow: true
    };
  },
  watch: {
    msg: {
      handler(data) {
        if (data) {
          this.msgTransData(data);
        }
      },
      immediate: true
    }
  },
  computed: {
    getTitleMsg() {
      return {
        ...this.msg,
        answerStatus: -1
      };
    }
  },
  methods: {
    msgTransData(data) {
      this.formData = ([...(data.bizMissParam || []), ...(data.bizOkParam || [])]).map((item)=> {
        this.ruleForm = {
          ...this.ruleForm,
          [item.name]: item.value
        };

        this.rules[item.name] = [{
          required: item.required,
          validator: (rule, value, cb)=>{
            if (rule.required) {
              let v = Array.isArray(value) ? value[0] : value;
              if (!v && v !== 0) {
                return cb(
                  new Error(this.$t(item.componentType.toLowerCase().indexOf('input') !== -1
                    ? 'pc_biz_lbl_please_enter_what' /* 请输入{0} */ : 'pc_biz_lbl_selectthings' /* 请选择{0} */
                    , [item.labelName]))
                );
              }
            }
            cb();
          },
          trigger: item.componentType === 'input' ? 'blur' : 'change'
        }];

        let bizList = item.list;

        if (item.componentType === 'select') {
          bizList = (item.list || []).map((item)=> {
            return JSON.parse(item || '{}');
          });
        }

        return { ...item, value: [null, undefined].includes(item.value) ? '' : item.value, list: bizList };
      });
    },
    submit() {
      if (this.isWaitAnswer) {
        // 等待中
        return this.$message.warning(
          this.$t('pc_biz_ai_send_wait').d('暂时不能发送，请稍后再试！')
        );
      }
      this.loading = true;
      clearTimeout(this.timer);
      this.$refs.ruleForm.validate((valid)=>{
        if (valid) {
          this.timer = setTimeout(() => {
            this.loading = false;

            const question = this.formData.reduce((pre, cur) => {
              let value = cur.labelSelectName || cur.value;

              if (cur.componentType === 'select') {
                const item = cur.list.find(item => item.value === cur.value);
                value = item ? item.labelName : '';
              }

              if (value) {
                pre += (cur.labelName + '：' + value + '；');
              }
              return pre;
            }, this.msg.bizAppName ? this.msg.bizAppName + '，' : '');

            this.disabled = true;
            this.isShow = false;

            this.$emit('submit', {
              ...this.ruleForm,
              question,
              bizAppId: this.msg.bizAppId,
              askId: this.msg.askId,
              bizOkParam: (this.msg.bizOkParam || []).map(({labelSelectName, list, ...form}) => {
                const f = (this.formData || []).find(item => item.name === form.name && item.aiName === form.aiName);
                form.value = f ? f.value : form.value;
                return form;
              }),
              bizMissParam: (this.msg.bizMissParam || []).map(({labelSelectName, list, ...form}) => {
                const f = (this.formData || []).find(item => item.name === form.name && item.aiName === form.aiName);
                form.value = f ? f.value : form.value;
                return form;
              })
            });
          }, 1000);
        } else {
          this.loading = false;
        }
      });

    },
    handleReset() {
      if (this.msg) {
        this.msgTransData(this.msg);
        this.$nextTick(this.$refs.ruleForm.clearValidate);
      }
    },
    handleChange(form, v) {
      if (({}).toString.call(v).replace(/\[object (\w+)\]/g, '$1') === 'Object') {
        this.ruleForm[form.name] = v.value;
        form['labelSelectName'] = v.labelName;
      } else {
        this.ruleForm[form.name] = v;
        form['labelSelectName'] = v;
      }
    }
  }
};
</script>
<style lang="scss">
.yxtbiz-ai-robot__dialog.fullscreen-dialog {
  .yxtbiz-ai-robot__form {
    width: 392px;
    box-sizing: content-box !important;
  }
}

.yxtbiz-ai-robot__form {
    padding: 10px 20px 16px 20px;
    background: #fff;
    border-radius: 0px 12px 12px 12px;
   
    .yxt-form-item:last-child {
      margin-bottom: 20px;
    }

    .btn-item {
      box-sizing: content-box;
      text-align: center;
      .yxt-button {
        width: 186px;
        height: 36px;
        border-radius: 18px;
      }
    }

  .w120 {
    width: 120px;
  }
  .wline {
    width: 100%;
  }
}
</style>