var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yxtbiz-group-source-selector" },
    [
      _c(
        "yxt-tabs",
        {
          attrs: { "drawer-top": true },
          model: {
            value: _vm.sourceType,
            callback: function ($$v) {
              _vm.sourceType = $$v
            },
            expression: "sourceType",
          },
        },
        _vm._l(_vm.components, function (comp) {
          return _c("yxt-tab-pane", {
            key: comp.sourceType,
            attrs: {
              label: _vm.$t(comp.nameKey).d(comp.name),
              name: comp.sourceType,
            },
          })
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "yxtbiz-group-source-selector-body" },
        [
          _c("source-select", {
            ref: "source",
            attrs: {
              sourceType: _vm.sourceType,
              value: _vm.list,
              disabledIds: _vm.disabledIds,
            },
            on: {
              "update:value": function ($event) {
                _vm.list = $event
              },
            },
          }),
          _c(
            "div",
            {
              staticClass:
                "yxtbiz-group-source-selector-body-selected pull-right",
            },
            [
              _c("yxt-scrollbar", { attrs: { "fit-height": true } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "yxtbiz-group-source-selector-body-selected-tags",
                  },
                  _vm._l(_vm.list, function (item) {
                    return _c(
                      "yxt-tag",
                      {
                        key: item.id,
                        staticClass: "mr5 mt5 ellipsis",
                        attrs: {
                          closable: !_vm.disabledIds.includes(item.id),
                          type: "info",
                        },
                        on: {
                          close: function ($event) {
                            return _vm.remove(item)
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "close-tag" },
                          [
                            _c(
                              "yxt-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: item.name || item.fullname,
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "close-tag_width_tail" },
                                  [_vm._v(_vm._s(item.name || item.fullname))]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  1
                ),
              ]),
              _c(
                "yxt-button",
                {
                  staticClass:
                    "mt5 yxtbiz-group-source-selector-body-selected-clear-btn",
                  attrs: {
                    type: "text",
                    disabled: Object.values(this.list).length === 0,
                  },
                  on: { click: _vm.clear },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.$t("pz_biz_share_btn_clearall").d("清除所有")
                      ) +
                      " \n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }