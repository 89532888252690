<template>
  <base-list
    :dataList="datas"
    :size="size"
    :tagLists="tagLists"
    searchName="pc_biz_o2o_lbl_survey"
    tagType="status"
    listId="surveyId"
    @to-list="toList"
    @to-more="toListMore">
    <!-- slot插槽处理 -->
    <template slot-scope="{ list }">
      <!-- 创建人 -->
      <span>{{ list.createFullname || '--' }}</span>
      <yxt-divider direction="vertical"></yxt-divider>
      <!-- {0} 创建 -->
      <span>{{ $t('pc_biz_ote_lbl_create_time_tips', [shortDateTime(list.createTime)]) }}</span>
    </template>
  </base-list>
</template>

<script>
import CommonMixin from '../common/props-mixin';
import BaseList from '../search-exam/base-list.vue';
import { shortDateTime } from '../search-exam/utils.js';

export default {
  name: 'TrackSurvey',
  mixins: [CommonMixin],
  components: {
    BaseList
  },
  data() {
    const tagLists = {
      0: {
        label: 'pc_biz_ote_lbl_status0', // 未发布
        type: 'info'
      },
      1: {
        label: 'pc_biz_ote_lbl_status1', // 发布中
        type: ''
      },
      2: {
        label: 'pc_biz_ote_lbl_status2', // 进行中
        type: ''
      },
      3: {
        label: 'pc_biz_ote_lbl_status3', // 已结束
        type: 'info'
      },
      4: {
        label: 'pc_biz_ote_lbl_status4', // 归档中
        type: 'info'
      },
      5: {
        label: 'pc_biz_ote_lbl_status5', // 已归档
        type: 'success'
      }
    };

    return {
      tagLists,
      curtTime: new Date().getTime()
    };
  },

  methods: {
    shortDateTime,
    toList(list) {
      window.open(`${window.location.origin}/survey/#/manage/result?pid=${list.id}`, '_blank');
    },
    toListMore() {
      localStorage['ai-track-survey'] = JSON.stringify(this.params);
      console.log(this.params);
      window.open(`${window.location.origin}/survey/#/manage`, '_blank');
    }
  }
};
</script>
