var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "yxtbiz-ai-robot-demo__dialog--msg is-answer",
      class: {
        "is-long-size": _vm.isSearch,
        "bbs-search": _vm.mode === "bbs",
        fullscreen: _vm.fullscreen,
      },
    },
    [
      _vm.showPortrait
        ? _c(
            "div",
            { class: { "head-img": _vm.isSearch, mr12: !_vm.isSearch } },
            [
              _c("yxtf-portrait", {
                class: { hand: _vm.isSearch },
                attrs: { size: "28px", imgUrl: _vm.settings.assistHeadImg },
                on: {
                  click: function ($event) {
                    _vm.isSearch && !_vm.isWaitAnswer && _vm.$emit("launch")
                  },
                },
              }),
              _vm.isSearch ? _c("span", [_vm._v("问一问")]) : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.msg.answerStatus === 0
        ? _c("div", { staticClass: "is-answer-loading" }, [
            _c("div", { staticClass: "is-answer-loading-text font-size-16" }, [
              _vm._v(_vm._s(_vm.$t("pc_biz_ai_thinking").d("正在思考"))),
            ]),
            _vm._m(0),
          ])
        : _vm.msg.answerStatus === 2
        ? _c(
            "div",
            { staticClass: "is-content font-size-16" },
            [
              _vm._v(
                _vm._s(_vm.msg.contentDetail && _vm.msg.contentDetail.content)
              ),
              _vm.settings.publicResourceEnabled
                ? _c(
                    "yxt-button",
                    {
                      staticClass: "internet-btn",
                      attrs: { type: "text" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("resend", "askInternet")
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("pc_biz_ai_question_tips").d("互联网问问")
                          )
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm.msg.answerStatus === 3
        ? _c("div", { staticClass: "is-content font-size-16" }, [
            _vm._v(
              _vm._s(
                (_vm.msg.contentDetail && _vm.msg.contentDetail.content) ||
                  _vm.settings.assistWele ||
                  _vm
                    .$t("pc_biz_ai_question_default_tips")
                    .d("你好，我是AI助手，很高兴为你服务！")
              )
            ),
          ])
        : _c("div", [
            _c(
              "div",
              {
                staticClass: "is-content is-answer-content",
                class: { "is-expanded": _vm.isExpanded },
              },
              [
                _c("msgToolBtn", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isSearch,
                      expression: "!isSearch",
                    },
                  ],
                  attrs: { like: _vm.like, msg: _vm.msg, isanswer: true },
                  on: { toolClick: _vm.toolClick },
                }),
                _c("div", {
                  ref: "content",
                  staticClass: "answer-content font-size-16",
                  domProps: { innerHTML: _vm._s(_vm.content) },
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.needShowMoreCover,
                        expression: "needShowMoreCover",
                      },
                    ],
                    staticClass: "is-more-cover",
                  },
                  [
                    _c("div"),
                    _c(
                      "div",
                      {
                        staticClass: "text-center hand",
                        on: {
                          click: function ($event) {
                            _vm.isExpanded = !_vm.isExpanded
                          },
                        },
                      },
                      [
                        _c(
                          "yxtf-link",
                          {
                            staticClass: "font-size-12",
                            attrs: { type: "primary", underline: false },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.isExpanded
                                  ? _vm.$t("pc_biz_ai_retract").d("收起")
                                  : _vm.$t("pc_biz_ai_expand_all").d("展开全部")
                              )
                            ),
                          ]
                        ),
                        _c("i", {
                          class: _vm.isExpanded
                            ? "yxt-icon-arrow-up"
                            : "yxt-icon-arrow-down",
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm.msg.contentDetail &&
                _vm.msg.contentDetail.linkList &&
                _vm.msg.contentDetail.linkList.length
                  ? _c(
                      "div",
                      { staticClass: "font-size-12 mt10" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "ai-content-source",
                            staticStyle: { "line-height": "26px" },
                          },
                          [_vm._v("内容来源：")]
                        ),
                        _vm._l(
                          _vm.msg.contentDetail.linkList,
                          function (source, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "d-content" },
                              [
                                source.goUrl
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "d-content-item",
                                        on: {
                                          click: function ($event) {
                                            return _vm.goUrl(source.goUrl)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(source.name) +
                                            "\n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          }
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "is-loading" }, [_c("div")])
  },
]
render._withStripped = true

export { render, staticRenderFns }