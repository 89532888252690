<template>
  <div v-if="loading" v-floading="loading" class="yxtbiz-nav-top-stu">
  </div>
  <code-viewer
    v-else-if="!!customCode"
    class="yxtbiz-nav-top-stu"
    :source="customCode"
  ></code-viewer>
  <div
    v-else
    class="yxtbiz-nav-top-stu"
    :class="{ 'yxtbiz-nav-top-stu--preview': previewMode }"
  >
    <yxt-sticky
      @change="headerFixChange"
      resizable
      autoSize
      :allowance="1"
    >
      <div>
        <div
          class="yxtbiz-nav-top-stu__main"
          :class="{ 'yxtbiz-nav-top-stu__main--fixed': isFixed }"
        >
          <div @click="goStuIndex">
            <a-link
              :url="`${defaultProductPath}/#/studyindex?norequest=1`"
              mode="flex"
              class="yxtbiz-nav-top-stu__logo hand"
              :style="`background-image: url(${logo || ''})`"
            >
            </a-link>
          </div>
          <div
            class="yxtbiz-nav-top-stu__menus"
            :id="navMenusAllId"
          >
            <div
              class="yxtbiz-nav-top-stu__menu-bar bg-primary-6"
              :class="{ 'yxtbiz-nav-top-stu__menu-bar--hide': !menuBarWidth }"
              :style="{
                width: `${menuBarWidth}px`,
                transform: `translateX(${menuBarLeft}px)`
              }"
            ></div>
            <template  v-for="(menu, idx) in navsOnMenu">
              <!-- 特殊处理装修首页平铺的导航数据 -->
              <div v-if="menu.isDecorate && pcPageIndexToggle === 1" 
               class="yxtbiz-nav-top-stu__menu hover-primary-6"
               :data-code="menu.code"
               :class="{
                  'yxtbiz-nav-top-stu__menu--active': menu.checked && menu.mainObj.code === pageCodes[0]
                }" @click="() => linkTo(menu, menu.mainObj)">
                <yxtf-tooltip :open-filter="true" :content="menu.name" placement="top">
                  <a-link  :url="menu.pageUrl" :page-node="menu"
                    @mouseenter="() => setMenuBarIndex(menu.code, true, true)"
                    @mouseover="() => setMenuBarIndex(menu.code, false, true)"
                    @mouseleave="() => setMenuBarIndex(pageCodes[0], false, true)" class="yxtbiz-nav-top-stu__menu-name decorate-menu-nav-name">{{ menu.name }}</a-link>
                </yxtf-tooltip>
              </div>
              <div
                v-else
                :key="menu.id"
                class="yxtbiz-nav-top-stu__menu hover-primary-6"
                v-show="menuSet.max >= idx + 1"
                :class="{
                  'yxtbiz-nav-top-stu__menu--active': menu.code === pageCodes[0]
                }"
                @click="() => toSubPage(menu)"
                :data-code="menu.code"
              >
                <yxtf-dropdown
                  v-if="menu.code === 'main_index' && pcPageIndexToggle === 0 && rangeTemplatesArr.length > 1"
                  @visible-change="isShowIndex = !isShowIndex"
                  placement="bottom-start"
                  custom-elm
                  trigger="hover"
                  class=""
                >
                <a-link mode="flex" :url="menu.pageUrl" :page-node="menu" class="yxtbiz-nav-top-stu__menu-name hover-primary-6-i">
                  {{ menu.name }}
                  <yxt-svg
                    width="12px"
                    height="12px"
                    :icon-class="!isShowIndex ? 'arrow-down' : 'arrow-up'"
                  />
                </a-link>
                  <yxtf-dropdown-menu
                    slot="dropdown"
                    class="yxtbiz-nav-top-stu__sub-menus yxtbiz-nav-top-stu__sub-menus--more yxtbiz-nav-top-stu_sub-menus--ul"
                  >
                    <yxtf-dropdown-item
                      v-for="item in rangeTemplatesArr"
                      v-show="item"
                      :key="item.id"
                      @click.stop.native="linkTo(item, menu)"
                    >
                      <a-link :url="menu.pageUrl" :class="['yxtbiz-nav-top-stu__pop-menu', item.checked === 1 ? 'yxtbiz-nav-top-stu__pop-menu--checked': '']">{{
                        item.name
                      }}</a-link>
                    </yxtf-dropdown-item>
                  </yxtf-dropdown-menu>
                </yxtf-dropdown>
                <a-link
                v-else
                mode="flex" :url="menu.pageUrl" :page-node="menu"
                @mouseenter="() => setMenuBarIndex(menu.code, true, true)"
                @mouseover="() => setMenuBarIndex(menu.code, false, true)"
                @mouseleave="() => setMenuBarIndex(pageCodes[0], false, true)"
                class="yxtbiz-nav-top-stu__menu-name"
                :class="['yxtbiz-nav-top-stu__menu-name', menu.code === 'main_index'? 'yxtbiz-nav-top-stu__menu-name--more': '' ]"
              >{{ menu | Globalize('name') }}</a-link>
            </div>
            </template>
            <div
              v-show="menuSet.more"
              class="yxtbiz-nav-top-stu__menu"
              :id="navMenuMoreId"
            >
              <yxtf-dropdown
                @visible-change="isShowMore = !isShowMore"
                placement="bottom-start"
                custom-elm
                trigger="hover"
              >
                <span class="yxtbiz-nav-top-stu__menu-name hover-primary-6-i">
                  {{ $t('pc_biz_nav_btn_more')
                  }}
                  <yxt-svg
                    width="12px"
                    height="12px"
                    :icon-class="!isShowMore ? 'arrow-down' : 'arrow-up'"
                  />
                </span>
                <yxtf-dropdown-menu
                  slot="dropdown"
                  class="yxtbiz-nav-top-stu__sub-menus yxtbiz-nav-top-stu__sub-menus--more"
                >
                  <template v-for="(sMenu, sIndex) in navsOnMenu">
                    <yxtf-dropdown-item
                      v-if="sIndex + 1 > menuSet.max"
                      v-show="sMenu.showed"
                      :key="sMenu.id"
                      @click.native="() => toSubPage(sMenu)"
                    >
                      <a-link :url="sMenu.pageUrl" :page-node="sMenu"  class="yxtbiz-nav-top-stu__pop-menu">{{
                          sMenu | Globalize('name')
                        }}</a-link>
                    </yxtf-dropdown-item>
                  </template>
                </yxtf-dropdown-menu>
              </yxtf-dropdown>
            </div>
          </div>
          <div
            class="yxtbiz-nav-top-stu__my-panel"
            v-if="!previewMode"
          >
            <nav-production-enter  :isStu="true"></nav-production-enter>
            <!-- ai助手 -->
            <nav-ai-helper  :isXxv2="isXxv2" :isStu="true"  ></nav-ai-helper>
            <!-- 搜索（全文检索） -->
            <yxtf-tooltip
              v-if="!EDITION_TOOL&&isXxv2"
              :content="$t('pc_biz_nav_tip_searchfor').d('搜索')"
            >
              <yxt-svg
                @click.native="(isSearching = true), (keyword = '')"
                width="24px"
                height="24px"
                :remote-url="strStcOssCorePcSvg"
                icon-class="nav-search"
                class="yxtbiz-nav-top-stu__my-panel-item hover-primary-6-i color-gray-9"
              />
            </yxtf-tooltip>
            <!-- 消息中心 -->
            <yxtf-tooltip
              v-if="isOpenIM&&showIm"
              :content="$t('biz_message_nav_title').d('消息中心')"
            >
              <slot
                name="imbell"
                v-if="$slots.imbell"
              ></slot>
              <yxt-im-bell class="yxtbiz-nav-top-stu__my-panel-item hover-primary-6-i color-gray-9" style="height:24px"></yxt-im-bell>
            </yxtf-tooltip>
            <!-- 用户头像，切换多语言等 -->
            <yxtf-dropdown
              trigger="hover"
              custom-elm
              class="yxtbiz-nav-top-stu__my-panel-item"
              :hide-on-click="false"
            >
              <yxtf-portrait
                class="hand yxtbiz-nav-top-stu__my-avatar"
                shape="circle"
                size="small"
                :username="userInfo.userShortName"
                :imgUrl="userInfo.headPictureUrl"
              >
                <img :src="strAvatar" />
              </yxtf-portrait>
              
              <yxtf-dropdown-menu
                slot="dropdown"
                class="yxtbiz-nav-top-stu__sub-menus yxtbiz-nav-top-stu__sub-menus--my"
              >
                <!-- 集团版切换机构 -->
                <nav-org-list-stu className="yxtbiz-nav-top-stu__pop-menu color-gray-9"></nav-org-list-stu>

                <yxtf-dropdown-item
                  v-if="!hideUsercenterBtn"
                  @click.native="goPersonCenter"
                >
                  <span class="yxtbiz-nav-top-stu__pop-menu">{{
                      $t('pc_biz_nav_btn_usercenter')
                    }}</span>
                </yxtf-dropdown-item>
                <yxtf-dropdown-item v-if="$attrs.isOpenI18n">
                  <yxtbiz-nav-lang
                    :isStu="true"
                    v-bind="$attrs"
                    trigger="hover"
                    placement="left"
                    :append-to-body="false"
                  >
                    <span class="yxtbiz-nav-top-stu__pop-menu color-gray-9">{{
                        $t('pc_biz_core_showlanguage')
                      }}</span>
                  </yxtbiz-nav-lang>
                </yxtf-dropdown-item>
                <yxtf-dropdown-item
                  @click.native="goManageCenter"
                  v-if="schedules.isManage"
                >
                  <span class="yxtbiz-nav-top-stu__pop-menu">{{
                      $t('pc_biz_nav_btn_gomgmt')
                    }}</span>
                </yxtf-dropdown-item>
                <yxtf-dropdown-item
                  @click.native="logout"
                  v-if="!isISV && !isHideLogout"
                >
                  <span class="yxtbiz-nav-top-stu__pop-menu">{{
                      $t('pc_biz_nav_btn_logout')
                    }}</span>
                </yxtf-dropdown-item>
              </yxtf-dropdown-menu>
            </yxtf-dropdown>
            <!-- 工作台 -->
            <work-bench
              :mainPathname="mainPathname"
              :isStu="true"
              class="hover-primary-6-i color-gray-9"
            ></work-bench>
          </div>
        </div>
        <template v-if="!previewMode">
          <template>
            <slot
              name="announcement"
              v-if="$slots.announcement"
            ></slot>
            <yxt-im-announcement
              v-else-if="isOpenIM"
              :showLamp="!osmShow"
            ></yxt-im-announcement>
          </template>
          <stop-notice
            @show="s => (osmShow = s)"
            :isManager="false"
            v-if="OSMEnable !== 0"
          ></stop-notice>
        </template>
      </div>
    </yxt-sticky>
    <div class="yxtbiz-nav-top-stu__searching">
      <yxt-collapse-transition>
        <div
          v-show="isSearching"
          class="yxtbiz-nav-top-stu__searching-container"
        >
          <div
            v-show="isSearching"
            class="yxtbiz-nav-top-stu__searching-content"
          >
            <yxt-svg
              @click.native="() => goSearch()"
              width="24px"
              height="24px"
              :remote-url="strStcOssCorePcSvg"
              icon-class="nav-search"
              class="hand hover-primary-6-i color-gray-9 yxtbiz-nav-top-stu__search-icon"
            />
            <yxtbiz-search
              @selected="goSearch"
              popperClass="yxtbiz-nav-top-stu__search-popper"
              class="yxtbiz-nav-top-stu__search"
              :searchable="false"
              v-model="keyword"
              ref="defaultSearch"
              hotOnly
              v-if="isSearching"
              :placeholder="searchPlaceholder"
            >
            </yxtbiz-search>
            <yxt-svg
              width="20px"
              height="20px"
              icon-class="close"
              class="color-gray-9 hover-primary-6-i yxtbiz-nav-top-stu__search-close hand"
              @click.native="isSearching = false"
            />
          </div>
        </div>
      </yxt-collapse-transition>
      <transition name="yxtbiz-nav-top-stu__searching-fade-in">
        <div
          v-show="isSearching"
          class="yxtbiz-nav-top-stu__searching-modal"
          @click="isSearching = false"
        ></div>
      </transition>
    </div>
  </div>
</template>

<script>
// import axios from 'axios';
import { mapActions, mapState, mapGetters } from 'vuex';
import CodeViewer from 'yxt-biz-pc/packages/custom/code-viewer/index';
import { common } from 'yxt-biz-pc/packages/common-util/index.common';
import { decorateApi } from 'yxt-biz-pc/packages/api';
import { productionUtil } from '../../../common-util/productionUtil';
import avt from './img/avatar.png';

import NavMain from '../../index';
import navProductionEnter from './nav-production-enter';
const { components, navCommon } = NavMain._shareToDevelopAgain;
const { Globalize, goSubNavPage, getShortName, uerLogout, platformDirect, goFirstPage, checkNavMore, EDITION_TOOL } = navCommon;
// const { getNavTemplateByCode } = navService;
import navAiHelper from './nav-ai-helper';
import ALink from './alink.vue';
import { defaultProductPath } from './const';
export default {
  name: 'YxtbizNavTopStu',
  components: {
    ALink,
    CodeViewer,
    navProductionEnter,
    navAiHelper,
    ...components
  },
  data() {
    return {
      defaultProductPath,
      pcPageIndexToggle: 0, // 1平铺，0下拉
      EDITION_TOOL,
      osmShow: false,
      menuBarCode: '',
      menuBarWidth: 0,
      menuBarLeft: 0,
      menuBarCodeHv: '',
      menuBarWidthHv: 0,
      menuBarLeftHv: 0,
      isFixed: false,
      isSearching: false,
      keyword: '',
      isShowMore: false,
      isShowIndex: false,
      defaultAvatar: avt,
      navLoaded: false,
      stickyContainer: null,
      menuSet: {
        max: 999,
        more: false
      },
      customCode: '', // 自定义导航编码
      rangeTemplatesArr: [], // 首页适合的模板
      loading: false
    };
  },
  props: {
    // 是否导初始页
    isRedirectFirstPage: {
      type: Boolean,
      default: false
    },
    // 一级导航的code，导初始页时会寻找第一个此导航下有权限的页面
    firstPageCode: {
      type: String,
      default: ''
    },
    mainPathname: {
      type: String,
      default: ''
    },
    // 静态的导航数据，传入时不再读取数据库的导航信息
    navDatas: {
      type: Object,
      default: null
    },
    // 自定义当前编号
    navCodes: {
      type: Array,
      default: null
    },
    // 预览模式
    previewMode: {
      type: Boolean,
      default: false
    },
    // 停服公告的启用控制，-1为默认值（默认前台导航开启，后台导航关闭）  0： 强制关闭  1：开启
    OSMEnable: {
      type: Number,
      default: -1
    },
    // 页面跳转路径
    allPath: {
      type: String,
      default: ''
    },
    // 是否展示学员端im小铃铛
    showIm: {
      type: Boolean,
      default: true
    }
  },
  mixins: [common.genIsMixin(), common.genStrMixin()],
  computed: {
    ...mapGetters('navManageStore', ['isISV']),
    ...mapState({
      collapse: state => state.navManageStore.collapse,
      schedules: state => state.navManageStore.schedules,
      navs: state => state.navManageStore.navs,
      isHideLogout: state => state.navManageStore.isHideLogout
    }),
    logo() {
      return window.localStorage.logoUrl;
    },
    homepage() {
      let url = '/';
      console.debug('enableAppMenus', this.navs.enableAppMenus);
      if (
        this.navs.enableAppMenus.length &&
        this.navs.enableAppMenus[0].pageUrl
      ) {
        url = this.navs.enableAppMenus[0].pageUrl;
      }
      return url;
    },
    userInfo() {
      return {
        userName: window.localStorage.username,
        userShortName: window.localStorage.fullname,
        orgName: window.localStorage.orgName,
        headPictureUrl: window.localStorage.imgUrl
      };
    },
    elpsName() {
      let n = '';
      if (this.userInfo && this.userInfo.userShortName) {
        n = getShortName(this.userInfo.userShortName);
      }
      return n;
    },
    pageCodes() {
      return this.navCodes || this.$store.state.navManageStore.pageCodes;
    },
    currentNav() {
      if (
        this.navDatas &&
        this.navDatas.enableAppMenus &&
        this.navDatas.enableAppMenus.length > 0
      ) {
        this.navLoaded = true;
        return this.navDatas;
      }
      if (
        !this.$store.state.navManageStore.lastUpdateNavStamp ||
        this.$store.state.navManageStore.navs.enableAppMenus.length === 0 ||
        this.$store.state.navManageStore.navType !== 1
      ) {
        this.navLoaded = false;
        return {};
      }
      this.navLoaded = true;
      return this.$store.state.navManageStore.navs;
    },
    // 所有显示在导航上的菜单
    navsOnMenu() {
      if (!this.currentNav || !this.currentNav.enableAppMenus) {
        return [];
      }
      let menus = this.currentNav.enableAppMenus.filter(m => {
        return m.showed && this.checkNavShow(m);
      });
      // 如果是装修多首页平铺模式，则需要拼接装修多首页数据和导航数据
      if (this.rangeTemplatesArr.length && this.pcPageIndexToggle === 1) {
        let mainIndex = 0; // 首页在导航中位置
        let mainIndexMenu = menus.find(function(menu, index) { // 找到首页导航的数据
          if (menu.code === 'main_index') {
            mainIndex = index;
          }
          return menu.code === 'main_index';
        });
        let decorateNev = this.rangeTemplatesArr.map(item => {
          return {
            isDecorate: true, // 导航数据中装修的标识, 用来对装修的导航特殊处理并不侵扰导航源数据
            code: mainIndexMenu.code + item.id,
            mainObj: {
              ...mainIndexMenu
            },
            ...item
          };
        });
        menus.splice(mainIndex, 1, ...decorateNev); // 将装修的数据插到导航首页的位置中
      }
      console.log('menus', menus);
      return menus;
    },
    msgCount() {
      return this.$store.state.navManageStore.messageCount;
    },
    searchPlaceholder() {
      let kw = [
        this.$t('pc_biz_nav_tip_course'),
        this.$t('pc_biz_nav_tip_train')
      ];
      // 101:中欧渠道    102:内容BU   这两个只能搜课程
      if (
        window.localStorage.sourceCode !== '101' &&
        window.localStorage.sourceCode !== '102'
      ) {
        kw.push(this.$t('pc_biz_nav_tip_kng'));
        kw.push(this.$t('pc_biz_nav_tip_lecturer'));
        kw.push(this.$t('pc_biz_nav_tip_post'));
      }

      return `${this.$t('pc_biz_nav_tip_searchfor')}${kw.join('、')}...`;
    },
    navMenusAllId() {
      return 'navMenusAll';
    },
    navMenuMoreId() {
      return 'navMenuMore';
    },
    isXxv2() {
      // 是否是绚星
      return productionUtil.isOneProduct('xxv2');
    }

  },
  watch: {
    navLoaded(val, oldVal) {
      if (!oldVal && val) {
        this.toFirstPage();
      }
      this.$nextTick(() => {
        this.setMoreShow();
      });
    },
    isRedirectFirstPage() {
      this.toFirstPage();
    },
    pageCodes() {
      if (this.pageCodes[0] !== this.menuBarCode) {
        this.setMenuBarIndex(this.pageCodes[0]);
      }
    },
    navsOnMenu() {
      this.$nextTick(() => {
        this.setMoreShow();
      });
    }
  },
  created() {
    this.getCustomNav();
    this.getDecoreteIndexConfig();
    this.getRangeTemplates();
    this.getIsManagement();
  },
  mounted() {
    this.setMenuBarIndex(this.pageCodes[0]);
    this.stickyContainer = document.getElementById('nav_stu');
  },
  methods: {
    ...mapActions('navManageStore', ['setCollapse', 'getIsManagement']),
    showMoreMenu() {
      this.moreMenuVisible = true;
    },
    hideMoreMenu() {
      this.moreMenuVisible = false;
    },
    setMoreShow() {
      if (this.navLoaded) {
        Object.assign(
          this.menuSet,
          checkNavMore(
            this.navMenusAllId,
            this.navMenuMoreId,
            this.navMenusAllId,
            true,
            'yxtbiz-nav-top-stu__menu'
          )
        );
        this.$nextTick(() => {
          this.setMenuBarIndex(this.pageCodes[0], true);
        });
      }
    },
    setMenuBarIndex(code, isForce, isHover) {
      // 处理装修的特殊逻辑
      if (code === 'main_index' && this.pcPageIndexToggle === 1 && this.rangeTemplatesArr.length) {
        this.rangeTemplatesArr.forEach(item => {
          if (item.checked) {
            code = 'main_index' + item.id;
          }
        });
      }
      let widthKey = 'menuBarWidth' + (isHover ? 'Hv' : '');
      let leftKey = 'menuBarLeft' + (isHover ? 'Hv' : '');
      let codeKey = 'menuBarCode' + (isHover ? 'Hv' : '');
      if (this[codeKey] === code && !isForce) {
        return;
      }
      this[widthKey] = 0;
      this[leftKey] = 0;
      if (code) {
        this[codeKey] = code;
        this[leftKey] = 0;
        let menus = window.document.getElementsByClassName(
          'yxtbiz-nav-top-stu__menu'
        );
        if (menus && menus.length > 0) {
          for (let index = 0; index < menus.length; index++) {
            const element = menus[index];
            if (element.style.display === 'none') {
              continue;
            }
            if (element.getAttribute('data-code') !== code) {
              this[leftKey] += element.clientWidth;
            } else {
              this[widthKey] = 32; // Math.max(element.clientWidth - 24 - 48, 12); 这里暂时先写死，不按宽度来。不然变来变去UI觉得丑
              this[leftKey] += (element.clientWidth - this[widthKey]) / 2;
              break;
            }
          }
        }
      }
    },
    headerFixChange(e) {
      this.isFixed = true;
    },
    redirectIndex() {
      location.href = this.homepage;
    },
    checkNavShow(menu) {
      // switch (menu.code) {
      //   case 'nav_audit':
      //     return this.schedules.auditCenter;
      //   case 'o2o_myteam':
      //     return this.schedules.myTeam;
      //   case 'o2o_teachmgmt':
      //     return this.schedules.studyMgmt;
      //   default:
      //     return true;
      // }
      return true; // 前端不处理了
    },
    toSubPage(page) {
      if (this.previewMode) {
        return;
      }
      goSubNavPage(page, this.mainPathname, this);
    },
    toFirstPage() {
      if (this.previewMode) {
        return;
      }
      if (this.isRedirectFirstPage && this.navLoaded) {
        goFirstPage(
          this.currentNav.enableAppMenus,
          this.mainPathname,
          this,
          this.firstPageCode
        );
      }
    },
    logout() {
      window.sessionStorage.removeItem('yxt_factors');
      uerLogout(2); // 退出登录
    },
    goPersonCenter() {
      window.location.href = `${this.allPath}/#/uinfo`; // 个人中心
    },
    goMyTeam() {
      window.location.href = `${this.allPath}/o2o/#/myteam/report`; // 我的团队
    },
    goManageCenter() {
      platformDirect(1);
    },
    goStuIndex() {
      platformDirect(2);
    },
    goMsgCenter() {
      window.location.href = `${this.allPath}/#/inbox`; // 站内信
    },
    goSearch(kw) {
      window.location.href =
        `${this.allPath}/search/#/gs/composite?keyword=` +
        encodeURIComponent(kw || this.keyword); // 搜索中心
      // window.open(`/search/#/gs/knowledge/${encodeURIComponent(kw || '')}`); // 搜索中心
      // 关闭遮罩
      this.isSearching = false;
    },
    // 校验自定义编码中是否直接使用业务组件
    checkCode(code) {
      return !!~code.indexOf('yxtbiz-nav-top-stu');
    },
    // 获取装修自定义导航模板
    async getCustomNav() {
      // 4.1暂时不上，现将装修逻辑还原
      // try {
      //   this.loading = true;
      //   const orgParame = await getOrgpParameter('selecttemplate', localStorage.orgId);
      //   if (orgParame && orgParame.code && orgParame.value) {
      //     const { navTemplateUrl } = await getNavTemplateByCode(orgParame.value);
      //     if (!navTemplateUrl) throw new Error('定制导航模板错误，code=2002；');
      //     const res = await axios.get(navTemplateUrl);
      //     const code = res.data || '';
      //     if (!this.checkCode(code)) {
      //       this.customCode = code;
      //       return;
      //     }
      //     throw new Error('定制导航模板错误，code=2003；');
      //   }
      // } catch (e) {
      //   this.customCode = '';
      //   this.$message.error(`${e.message}进入兜底登录页面；`);
      // } finally {
      //   this.loading = false;
      //   this.$nextTick(() => {
      //     this.addResizeWatch();
      //   });
      // }
      try {
        this.loading = true;
        const { toggle } = await decorateApi
          .get('/custom/release-codes/types/4/toggle-configs');
        this.showCustom = toggle;
        if (this.showCustom) {
          const { body } = await decorateApi.get('/custom/release-codes/types/4');
          let codeObj = JSON.parse(body);
          if (this.checkCode(codeObj.tmp)) {
            this.showCustom = false;
          } else {
            this.customCode = codeObj.tmp;
          }
        }
      } catch (error) {
        this.customCode = '';
        this.$message.error(`${error.message}进入兜底登录页面；`);
      } finally {
        this.loading = false;
        this.$nextTick(() => {
          this.addResizeWatch();
        });
      }
    },
    // 获取学院端访问模板列表
    getRangeTemplates() {
      decorateApi
        .get('/stu/range-templates', {
          pageCode: 'index'
        })
        .then(res => {
          this.rangeTemplatesArr = res;
        });
    },
    // 获取装修多首页配置数据
    getDecoreteIndexConfig() {
      decorateApi
        .get('festivals/auto-toggle', {})
        .then(res => {
          this.pcPageIndexToggle = res.pcPageIndexToggle;
        });
    },
    // 首页模板跳转首页模板跳转
    linkTo(item, menu) {
      decorateApi
        .post('/stu/templates/' + item.id + '/choose-records')
        .then(() => {
          if (location.href === location.origin + menu.pageUrl) {
            window.location.reload(true);
          }
          window.location.href = menu.pageUrl;
        });
    },
    addResizeWatch() {
      const dom = document.getElementById(this.navMenusAllId);
      if (dom) {
        common.addResizeListener(dom, this.setMoreShow);
      }
    }
  },
  filters: {
    Globalize(data, key) {
      return Globalize(data, key);
    }
  },
  beforeDestroy() {
    const dom = document.getElementById(this.navMenusAllId);
    if (dom) {
      common.removeResizeListener(dom, this.setMoreShow);
    }
  }
};
</script>
<style lang="scss" scoped>
.decorate-menu-nav-name {
    max-width: 128px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
}
.yxtbiz-nav-top-stu_sub-menus--ul {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 6px;
  }
  &::-webkit-scrollbar-corner {
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #f4f5f5;
    &:hover {
      background-color: #f4f5f5;
    }
  }
  max-height: 330px;
  overflow: auto;
}
</style>