import { apiBaseUrl } from 'yxt-biz-pc/packages/api';
import { fetchEventSource } from './ai-tools/fetch'; // 流式API
// 生成随机数
export function getUid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    let r = (Math.random() * 16) | 0;
    let v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
/**
 * @description 提问历史查询
 * @author （zengya）
 * @date 2023/07/03 15:20:52
 * @param {*} data
 * @return {*}
 */
export const questionHistory = (data) => {
  return apiBaseUrl.post('/aiassist/stu/chat/chat_history', data);
};
/**
 * @description 提问AI
 * @author （zengya）
 * @date 2023/07/03 15:20:34
 * @param {*} data
 * @return {*}
 */
export const submitQuestion = (data, SourceEvent) => {
  return fetchEventSource(`${window.feConfig.common.apiBaseUrl}aiassist/event_stream/stu/ask_question`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Token': window.localStorage.getItem('token') || '',
      'Source': 501,
      'Yxt-Orgdomain': window.location.host
    },
    body: JSON.stringify(data),
    onmessage: SourceEvent.onmessage,
    onclose: SourceEvent.onclose,
    onerror: SourceEvent.onerror
  });
};
/**
 * @description 回答纠错
 * @author （zengya）
 * @date 2023/07/03 15:20:08
 * @param {*} data
 * @return {*}
 */
export const submitQuestionFeedback = (data) => {
  return apiBaseUrl.post('/aiassist/stu/chat/chat_answer_correct', data);
};
/**
 * @description 回答很棒/回答糟糕
 * @author （zengya）
 * @date 2023/07/03 15:18:34
 * @param {*} data
 * @return {*}
 */
export const submitQuestionLike = (data) => {
  return apiBaseUrl.put(`/aiassist/stu/chat/chat_answer_like?chatContentId=${data.chatContentId}&likeAnswer=${data.likeAnswer}`, {});
};
/**
 * @description 清空提问历史
 * @author （zengya）
 * @date 2023/07/03 15:19:32
 * @return {*}
 */
export const clearHistory = () => {
  return apiBaseUrl.post('/aiassist/stu/chat/reset_chat_history');
};
/**
 * @description 获取助手配置信息
 * @author （zengya）
 * @date 2023/07/03 15:19:50
 * @param {*} data
 * @return {*}
 */
export const getChatSetting = (data) => {
  return apiBaseUrl.get('/aiassist/stu/chat/org_setting', data);
};
/**
 * @description 获取分享链接地址
 * @author （zengya）
 * @date 2023/07/03 15:19:50
 * @param {*} data
 * @return {*}
 */
export const getShareUrl = () => {
  return apiBaseUrl.get('/aiassist/stu/chat/org_chat_share_url', {});
};
