var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "yxtbiz-ai-robot-ssp-text standard-size-14 ph12",
      class: { "standard-size-16": _vm.size === "large" },
    },
    [
      _vm._v(
        "\n  " +
          _vm._s(_vm.$t("pc_biz_ssp_lbl_you").d("您")) +
          _vm._s(_vm.timeTypeMapping[_vm.datas.timeRange]) +
          _vm._s(_vm.$t("pc_biz_ssp_lbl_acheive").d("获得")) +
          "  "
      ),
      _c("span", { staticStyle: { color: "orange" } }, [
        _vm._v(_vm._s(_vm.datas.objValue)),
      ]),
      _vm._v("  " + _vm._s(_vm.objTypeMapping[_vm.datas.objType]) + "\n"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }