<template>
    <div class="drop-line" @mousedown.stop="startDrag"></div>
</template>
<script>
const throttle = (fn, time) => {
  let timer = null;
  return (...args) => {
    if (!timer) {
      timer = setTimeout(() => {
        timer = null;
        fn.apply(this, args);
      }, time);
    }
  };
};
export default {
  data() {
    return {
      isDroging: false,
      startX: 0,
      startY: 0,
      defaultRight: 0,
      defaultBottom: 0
    };
  },
  methods: {
    startDrag(event) {
      this.isDroging = true;
      this.startY = event.y;
      this.$emit('start');
      console.log('startY', this.startY);
      document.addEventListener('mousemove', throttle(this.drag, 16));
      document.addEventListener('mouseup', this.stopMove);
    },
    drag(event) {
      if (this.isDroging) {
        let offsetY = this.startY - event.y;
        this.$emit('drag', offsetY);
        console.log('差值', offsetY); // 差值
      }
    },
    stopMove() {
      this.defaultBottom = this.bottom;
      this.defaultRight = this.right;
      this.isDroging = false;
      document.removeEventListener('mousemove', this.move);
      document.removeEventListener('mouseup', this.stopMove);
    }
  },
  beforeDestroy() {
    document.removeEventListener('mousemove', this.move);
    document.removeEventListener('mouseup', this.stopMove);
  }
};
</script>