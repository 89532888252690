<!-- 创建时间2024/01/04 17:28:13 -->
<!-- 创建人：Zhao bing bing -->
<!-- 组件描述： 笔记数据转化为dom显示  -->
<template>
  <div class="yxtbiz-note__note-item-view color-gray-9" :class="{'yxtbiz-note__note-item-view--light':effect==='light'}">
    <template v-for="(item,index) in dataList">
      <div v-if="item.type === 0" :key="`text-${index}`">
        {{ item.content }}
      </div>
      <div v-else :key="`mark-${index}`">
        <div
          class="yxtbiz-note__note-mark hover-bg-primary-6-i"
          @click.stop="toJump(item)"
        >
          <yxtf-svg
            :remote-url="`${$staticBaseUrl}ufd/407a24/kng/pc/svg`"
            width="16px"
            height="16px"
            :icon-class="getIcon(item)"
            class="color-gray-6 yxtbiz-flex-shrink-0"
          />
          <yxt-popover
            v-if="getPositionText(item)"
            trigger="hover"
            :content="$t('pc_kng_note_tip_marker_jump')"
            placement="top"
          >
            <span slot="reference" class="ml7 yxtbiz-flex-center">
              {{ getPositionText(item) }}
            </span>
          </yxt-popover>
          <div v-if="isInCourse" class="ellipsis ml8">{{ item.kngName }}</div>
        </div>
      </div>

    </template>
  </div>
</template>

<script>
import kngUtils from 'yxt-biz-pc/packages/common-util/kngUtils';
import { KNG_TYPE } from 'yxt-biz-pc/packages/select-kng/src/const';
import { getMediaTimeStr } from '../utils';
export default {
  name: 'NoteItemView',
  inject: ['getDetail'],
  props: {
    note: {
      type: Object,
      default: () => ({})
    },
    effect: {
      type: String,
      default: 'night'
    },
    isInCourse: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      KNG_TYPE
    };
  },
  computed: {
    dataList() {
      try {
        const content = JSON.parse(this.note.content || '[]');
        return content;
      } catch (e) {
        return [];
      }
    }
  },
  methods: {
    getPositionText(item) {
      const position = parseInt(item.position || 0, 10);
      if (position < 0) return '';
      else if (item.kngType === KNG_TYPE.DOC) return this.$t('pc_kng_note_lbl_marke_page', [position]);
      else if (item.kngType === KNG_TYPE.VIDEO || item.kngType === KNG_TYPE.AUDIO) return getMediaTimeStr(position);
      return '';
    },
    // 播放中的视频文档记录位置，其他的不显示位置
    getIcon(item, light = false) {
      // 知识类型 0.课程、1.文档、2.视频、3.音频、4.微课、5.SCORM、6.HTML、7.压缩包、8.外链
      if (item.position < 0) return 'kng' + item.kngType;
      else if (item.kngType === KNG_TYPE.AUDIO || item.kngType === KNG_TYPE.VIDEO) return 'note-circle' + (light ? '-white' : '');
      else if (item.kngType === KNG_TYPE.DOC) return 'note-flag' + (light ? '-white' : '');
      return 'kng' + item.kngType;
    },
    toJump(obj) {
      kngUtils.noteJump(obj);
    }
  }
};
</script>
