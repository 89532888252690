<template>
  <div
    class="yxtbiz-ai-robot-collect-item width-percent-100 over-hidden"
    @click="toDetail(data.id)"
  >
    <!--      文章封面，帖子，问题第一张图-->
    <div v-if="coverUrl(data)" class="yxtbiz-ai-robot-collect-community__img">
      <yxt-image
        :src="coverUrl(data)"
        alt
        class="yxtbiz-ai-robot-bbs__cover mr12"
        fit="cover"
      />
    </div>
    <div class="over-hidden">
      <div
        :class="[
          size === 'small'
            ? 'ellipsis-2 font-size-14 lh22'
            : 'ellipsis font-size-16  lh24',
          'color-gray-10'
        ]"
      >
        <yxtf-tag
          type="warning"
          size="mini"
          class="mr8 yxtbiz-ai-robot-collect-community__post-tag vertical-align__middle"
        >
          {{ getTagName(data.type) }}
        </yxtf-tag>
        <span v-html="data.titleHtml"></span>
      </div>
      <div
        :class="[
          size === 'small' ? 'mt4' : 'mt8',
          'yxtbiz-flex-center lh20 color-gray-7 font-size-12'
        ]"
      >
        <span v-if="!data.anonymous" class="ellipsis maxwidth200"
          ><yxtbiz-user-name :name="data.createUsername"
        /></span>
        <span v-else class="ellipsis maxwidth200">
          {{ $t('pc_biz_collect_anonymousUser') }}</span
        >
        <yxt-divider direction="vertical"></yxt-divider>
        <span class="ellipsis mr20">{{ data.createTime | formatTime }}</span>
        <span v-if="size !== 'small'" class="ellipsis maxwidth300">
          {{ data.boardName }}</span
        >
      </div>
      <div
        v-if="size === 'small'"
        class="ellipsis mt4 lh20 color-gray-7 font-size-12"
      >
        {{ data.boardName }}
      </div>
    </div>
  </div>
</template>
  
  <script>
import favoriteMixin from './favorite-mixin.js';
import dayjs from 'dayjs';

export default {
  name: 'bbsItem',
  filters: {
    formatTime(time) {
      return dayjs(time).format('YYYY-MM-DD HH:mm');
    }
  },
  mixins: [favoriteMixin],
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    coverUrl(imgs) {
      // <!--      文章封面，帖子，问题第一张图-->
      if (imgs.type === 2) {
        return imgs.logoUrl;
      } else if (imgs.images) {
        return imgs.images.split(';')[0];
      } else return null;
    },
    toDetail(id) {
      let url = `${window.location.protocol}//${window.location.host}/bbs/#/postdetail/${id}`;
      window.open(url);
    },
    getTagName(type) {
      let obj = {
        1: this.$t('pc_biz_collect_lbl_post'),
        2: this.$t('pc_biz_collect_lbl_article'),
        4: this.$t('pc_biz_global_share'),
        9: this.$t('pc_biz_collect_lbl_answer'),
        11: this.$t('pc_biz_collect_lbl_comments'),
        12: this.$t('pc_biz_collect_lbl_notes')
      };
      return obj[type] || '';
    }
  }
};
</script>
  
  <style scoped lang="scss">
.maxwidth200 {
  max-width: 200px;
}

.maxwidth300 {
  max-width: 300px;
}
.yxtbiz-ai-robot-collect-item {
  display: flex;
  align-items: center;
}
.yxtbiz-ai-robot-bbs__cover {
  display: block;
  width: 112px;
  height: 68px;
  border-radius: 4px;

  ::v-deep img {
    width: 100% !important;
    height: 100%;
  }
}
</style>
  