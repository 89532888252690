import { i18n } from 'yxt-biz-pc/packages/common-util/changeLangs';
import CheckTeamDepartment from './view/CheckTeamDepartment.vue'; // 团队部门选择
import CheckTeamPersons from './view/CheckTeamPersons.vue'; // 团队人员选择
import Persons from './view/persons.vue'; // 人
import UserGroup from './view/user-group.vue'; // 用户组
import Advance from './view/advance.vue'; // 高级选人
import RelationDynamicUserGroup from './view/RelationDynamicUserGroup.vue'; // 动态用户组
import CheckMoreDepartment from './view/CheckMoreDepartment.vue'; // 部门
import CheckMorePosition from './view/CheckMorePosition.vue'; // 岗位
import CheckManage from './view/CheckManage.vue'; // 直属
import Role from './view/Role.vue'; // 角色
import Level from './view/Level.vue'; // 职级
import GroupPerson from './view/group-person.vue'; // 群成员
import Import from './view/import.vue'; // 导入用户
import CheckedList from './components/checkedlist.vue';
import SelectorPropsMixin from '../mixins/selector-props';
import { enmuFunctions } from '../../function-points';
import { getFunStatus } from '../../common-util/factorUtils';
import { MessageBox } from 'yxt-pc';

const TYPE = {
  USER: 'persons',
  GROUP: 'userGroup',
  RELATION: 'relationDynamicUserGroup'
};

// tab页所对应的数据分组
export const TYPEVALUE = {
  persons: 'persons',
  'userGroup.person': 'persons',
  userGroup: 'userGroup',
  advance: 'persons',
  manage: 'persons',
  relationDynamicUserGroup: 'relationDynamicUserGroup',
  department: 'department',
  position: 'position',
  role: 'role',
  level: 'level',
  import: 'persons',
  groupPerson: 'groupPerson',
  customTab: 'customTab',
  teamDepartment: 'department',
  teamPersons: 'persons'
};

// 此处label用函数执行，原因是在这里使用i18n可能国际化json还没有下载下来会导致不翻译，直接写成方法在render的时候执行
export const Tabs = [
  {
    label: () => i18n.t('pc_biz_common__select_user').d('选择人员'),
    value: 'teamPersons',
    component: CheckTeamPersons,
    model: TYPE.USER
  },
  {
    label: () => i18n.t('biz_udp_select_dept').d('选择部门'),
    value: 'teamDepartment',
    component: CheckTeamDepartment,
    model: TYPE.USER
  },
  {
    label: () => i18n.t('pc_biz_udp_lbl_quickSelect').d('快速选人'),
    value: 'persons',
    component: Persons,
    model: TYPE.USER
  },
  {
    label: () => i18n.t('pc_biz_udp_lbl_selectGroup').d('按用户组选人'),
    value: 'userGroup',
    component: UserGroup,
    model: TYPE.GROUP
  },
  {
    label: () => i18n.t('pc_biz_udp_lbl_advanceSelect').d('高级选人'),
    value: 'advance',
    component: Advance,
    model: TYPE.USER
  },
  {
    label: () => i18n.t('biz_udp_direct').d('直属'),
    value: 'manage',
    component: CheckManage,
    model: TYPE.USER
  },
  {
    label: () => i18n.t('pc_biz_udp_lbl_relation_selectGroup').d('关联动态用户组'),
    value: 'relationDynamicUserGroup',
    component: RelationDynamicUserGroup,
    model: TYPE.RELATION
  },
  {
    label: () => i18n.t('biz_udp_deptment').d('部门'),
    value: 'department',
    component: CheckMoreDepartment,
    model: TYPE.USER
  },
  {
    label: () => i18n.t('biz_udp_position').d('岗位'),
    value: 'position',
    component: CheckMorePosition,
    model: TYPE.USER
  },
  {
    label: () => i18n.t('biz_udp_role').d('角色'),
    value: 'role',
    component: Role,
    model: TYPE.USER
  },
  {
    label: () => i18n.t('biz_udp_occupation_level').d('职级'),
    value: 'level',
    component: Level,
    model: TYPE.USER
  },
  {
    label: () => i18n.t('pc_biz_udp_lbl_groupPerson').d('群成员'),
    value: 'groupPerson',
    component: GroupPerson,
    model: TYPE.USER
  },
  {
    label: () => i18n.t('pc_biz_udp_lbl_importUser').d('导入用户'),
    value: 'import',
    component: Import,
    model: TYPE.USER
  }
];

export default {
  components: {
    CheckedList
  },
  mixins: [SelectorPropsMixin],
  data() {
    return {
      activeTabName: 'persons',
      selectData: {
        persons: [], // 人员
        userGroup: [], // 用户组
        relationDynamicUserGroup: [], // 动态用户组
        department: [], // 部门
        position: [], // 岗位
        role: [], // 角色
        level: [], // 职级
        groupPerson: [], // 群成员
        customTab: [] // 自定义tab的数据
      },
      selectDataStatus: {
        outside: false,
        inside: false
      }, // 是否是外部改变，这个参数用于判断是不是直接改变了v-model的变量还是通过内部change触发改变的
      processing: false
    };
  },
  props: {
    selected: [Object, Array],
    isShowDeptment: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    showRelation() {
      // 是否显示动态用户组tab
      return getFunStatus(enmuFunctions.UDP_DYNAMIC_USERGROUP).enabled;
    },
    tab0Type() {
      if (typeof this.tabs[0] === 'string') {
        return TYPEVALUE[this.tabs[0]];
      } else {
        return TYPEVALUE[this.tabs[0].type];
      }
    },
    showTabComp() {
      return this.tabs.length > 1;
    }
  },
  provide() {
    return {
      selectData: this.selectData
    };
  },
  methods: {
    labelName(label) {
      return typeof label === 'function' ? label() : label;
    },
    tabBeforeLeave() {
      if (this.processing) {
        return MessageBox.confirm(
          this.$t('pc_biz_udp_msg_closeMsg'),
          this.$t('pc_biz_udp_lbl_closeTitle'),
          {
            confirmButtonText: this.$t('pc_biz_udp_btn_confirm'),
            cancelButtonText: this.$t('pc_biz_udp_btn_cancel'),
            type: 'warning'
          }
        );
      } else {
        return true;
      }
    },
    clearSelectData() {
      for (const type in this.selectData) {
        this.selectData[type] = [];
      }
    },
    selectChange(value, type) {
      value.forEach(d => Object.seal(d));
      // 选中时触发
      this.selectData[type] = value;
      this.selectedChange({});
    },
    selectedChange({ type = '', deleteItem = null }) {
      // 选中区进行清空、取消操作时触发
      console.log(deleteItem, 'selectedChange');
      this.selectDataStatus.inside = true;
      const copyData = this.selectData;
      this.$emit('change', copyData);
      if (type === 'department' || type === 'teamDepartment' || type === 'all') {
        // 因部门选择器老代码与新代码更新机制不一样，会产生死循环，故使用手动更新
        if (this.activeTabName === 'department' || this.activeTabName === 'teamDepartment') {
          this.$refs[this.activeTabName].update(null, deleteItem);
        }
      }
    },
    upDatedSelectData(selected) {
      console.log('selectDataStatus', this.selectDataStatus.outside, this.selectDataStatus.inside);
      // 建立一个当前传入tabs所对应的分组组成的map对象，用户数据回显时不在当前传入tab分组内的，直接过滤
      const tabTypesMap = {};
      this.tabs.forEach(item => {
        const type = typeof item === 'string' ? item : item.type;
        tabTypesMap[TYPEVALUE[type]] = true;
      });
      if (this.selectDataStatus.outside && this.selectDataStatus.inside) {
        this.selectDataStatus = { outside: false, inside: false };
        return;
      }

      if (!selected) {
        this.clearSelectData();
        return;
      }

      Array.isArray(selected) ? selected.forEach(d => Object.seal(d)) : Object.seal(selected);
      // 回显处理
      if (Array.isArray(selected)) {
        selected.forEach(d => Object.seal(d));
        // 代表是个数组
        if (this.tabs.length === 1) {
          // 只有一个tab时，直接把数据挂载到对应的位置上去
          this.selectData[this.tab0Type] = [...selected];
          this.transformShowPersonSelectAlone();
          return;
        }
        // 还有一种情况就是传入的各tab都是同一个数据分组的
        const tabType = this.getSameType(this.tabs);
        if (tabType) {
          this.selectData[tabType] = [...selected];
          this.transformShowPersonSelectAlone();
          return;
        }

        // 先进行分组
        const selectedMap = {};
        this.clearSelectData(); // 先清空数据
        selected.forEach(item => {
          // 只有这个数据所对应的tab存在时，才回显上去
          if (item.nodeType && this.selectData[item.nodeType] && tabTypesMap[item.nodeType]) {
            if (selectedMap[item.nodeType]) {
              selectedMap[item.nodeType].push(item);
            } else {
              selectedMap[item.nodeType] = [item];
            }
          }
        });
        for (const type in selectedMap) {
          const elementData = selectedMap[type];
          this.selectData[type] = elementData;
        }
        this.transformShowPersonSelectAlone();
        return;
      }
      if (!this.multiple && this.tabs.length === 1 || this.tabs.length === 1 && this.tab0Type === 'RelationDynamicUserGroup') {
        // 只存在一个tab页并且是单选
        this.selectData[this.tab0Type] = Array.isArray(selected) ? selected : selected.id ? [selected] : []; // 对传入的对象做兼容，没有id直接不回显
        this.transformShowPersonSelectAlone();
        return;
      }

      for (const type in selected) {
        // 只对存在相关tab的数据进行回显
        if (!tabTypesMap[type]) continue;
        const elementData = selected[type];
        if (elementData && elementData.length && this.selectData[type]) {
          this.selectData[type] = elementData;
        }
      }
      this.transformShowPersonSelectAlone();
    },
    transformShowPersonSelectAlone() {
      // 集团版独立选择人员的模式下，对参数特殊处理下
      if (this.enableGroupCorp && this.isOrgSelectAlone) {
        this.selectData.persons = this.selectData.persons.map(item => ({
          ...item,
          oldId: item.id,
          id: `${item.id}&&${item.orgId}`
        }));
      }
    },
    getSameType(tabs = []) {
      let tabValue = '';
      for (let i = 0; i < tabs.length; i++) {
        const tab = tabs[i];
        let type = '';
        if (typeof tab === 'string') {
          type = TYPEVALUE[tab];
        } else {
          if (tab.type === 'userGroup' && tab.isPerson) {
            // 因为用户组选人返回的结果也是人，因此在回显的时候直接当persons分组处理
            type = TYPEVALUE['userGroup.person'];
          } else {
            type = TYPEVALUE[tab.type];
          }
        }
        if (!tabValue) {
          tabValue = type;
        } else {
          if (tabValue !== type) {
            return null;
          }
        }
      }
      return tabValue;
    },
    visibleOrgSelectorFormat() {
      const visibleOrgSelector = this.visibleOrgSelector;
      if (visibleOrgSelector === 'hidden') {
        return {
          visibleOrgSelector: false,
          disabled: false
        };
      } else if (visibleOrgSelector === 'disabled') {
        return {
          visibleOrgSelector: true,
          disabled: true
        };
      } else if (visibleOrgSelector === 'show') {
        return {
          visibleOrgSelector: true,
          disabled: false
        };
      } else {
        return {
          visibleOrgSelector,
          disabled: false
        };
      }
    },
    // 导入流程阶段状态变化回调
    onProcess(processing) {
      this.processing = processing;
      this.$emit('onProcess', processing);
    }
  },
  watch: {
    selected: {
      deep: true,
      handler(result) {
        this.selectDataStatus.outside = true;
        this.upDatedSelectData(result);
      }
    }
  },
  created() {
    this.upDatedSelectData(this.selected);
    this.activeTabName = typeof this.tabs[0] === 'string' ? this.tabs[0] : this.tabs[0].type;
  },
  render() {
    const TabComponents = [];
    this.tabs.forEach(tab => {
      if (typeof tab === 'string') {
        const tabComp = Tabs.find(tabComp => tabComp.value === tab);
        if (tabComp) {
          if (tabComp.value === 'userGroup') {
            tabComp.label = i18n.t('biz_udp_usergroup').d('用户组');
          }
          if (!this.showRelation && tabComp.value === 'relationDynamicUserGroup' || tabComp.value === 'groupPerson') {
            // 判断2种情况，第一种是动态用户组未开启增购，不显示，第二种是群成员tab没有群id，不显示（这里因为传入的tab是个字符串未配置参数，直接不显示）
          } else {
            // 表示动态用户组未增购，不管业务方传没传，直接不显示该tab
            TabComponents.push({...tabComp, props: {
              isShowDeptment: this.isShowDeptment
            }});
          }
        }
      } else {
        const tabComp = Tabs.find(tabComp => tabComp.value === tab.type);
        if (tabComp) {
          if (tabComp.value === 'userGroup' && !tab.isPerson) {
            tabComp.label = i18n.t('biz_udp_usergroup').d('用户组');
          }
          if (!this.showRelation && tabComp.value === 'relationDynamicUserGroup' || tabComp.value === 'groupPerson' && !tab.projectGroupId) {
            // 判断2种情况，第一种是动态用户组未开启增购，不显示，第二种是群成员tab没有群id，不显示
          } else {
            TabComponents.push({...tabComp, props: {
              isShowDeptment: this.isShowDeptment,
              ...tab
            }});
          }
        } else {
          if (this.$parent.$slots[tab.type]) {
            TabComponents.push({
              label: tab.label,
              value: tab.type
            });
          }
        }
      }
    });
    return (
      <div class="yxtbiz-range-selector">
        { this.showTabComp ? (
          <yxt-tabs value={this.activeTabName}
            on-tab-click={(e) => {
              this.activeTabName = e.name;
            }}
            drawer-top={true}
            type="ant"
            before-leave={this.tabBeforeLeave}
          >
            {TabComponents.map((item, index) => (
              <yxt-tab-pane label={this.labelName(item.label)} name={item.value} key={index} />
            ))}
          </yxt-tabs>
        ) : (
          this.showTab ? <div class="yxtbiz-range-selector-custom-title">{this.labelName(TabComponents[0].label)}</div> : ''
        ) }
        <div class="yxtbiz-range-selector-main" style={{ height: this.showTabComp ? '' : '100%' }}>
          <div class="yxtbiz-range-selector-main-content">
            {TabComponents.map((item, index) => {
              if (this.$slots[item.value]) {
                return item.value === this.activeTabName ? this.$slots[item.value] : '';
              } else {
                return (
                  item.value === this.activeTabName
                    ? <item.component
                      ref={item.value}
                      multiple={this.multiple}
                      functionCode={this.functionCode}
                      dataPermissionCode={this.dataPermissionCode}
                      disabledOrgIds={this.disabledOrgIds}
                      disabled={this.visibleOrgSelectorFormat().disabled}
                      visibleOrgIds={this.visibleOrgIds}
                      targetOrgId={this.targetOrgId}
                      enableGroupCorp={this.enableGroupCorp}
                      isOrgSelectAlone={this.isOrgSelectAlone}
                      limit={this.limit}
                      visibleOrgSelector={this.visibleOrgSelectorFormat().visibleOrgSelector}
                      selectData={this.selectData}
                      {...{ attrs: {...item.props}}}
                      key={index}
                      onProcess={this.onProcess}
                      onChange={this.selectChange}
                    /> : '');
              }
            })}
          </div>
          <div class="yxtbiz-range-selector-main-checkedlist">
            <CheckedList limit={this.limit} checkedData={this.selectData} tabs={this.tabs} enableGroupCorp={this.enableGroupCorp} onChange={this.selectedChange} />
          </div>
        </div>
      </div>
    );
  }
};
