var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "yxtbiz-personal-center-nav font-size-14 color-gray-10 bg-white",
    },
    [
      _c(
        "ul",
        _vm._l(_vm.navList, function (item, index) {
          return _c(
            "li",
            {
              key: item.name,
              staticClass: "tab-item hand hover-bg-primary-1 hover-primary-6",
              class: {
                "color-primary-6 bg-primary-1 yxtf-weight-6":
                  _vm.pageCode === item.pageCode,
              },
              on: {
                click: function ($event) {
                  return _vm.changeTab(index, item)
                },
              },
            },
            [
              _c(
                "yxtf-row",
                { attrs: { type: "flex", align: "middle" } },
                [
                  _c("yxt-svg", {
                    staticClass: "hover-primary-6 mr16",
                    class: [
                      _vm.pageCode === item.pageCode
                        ? "color-primary-6"
                        : "default-color",
                    ],
                    attrs: {
                      "remote-url": _vm.mediaUrl,
                      width: "22px",
                      height: "22px",
                      "icon-class": item.icon,
                    },
                  }),
                  _c("span", { staticClass: "tab-label" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                ],
                1
              ),
              item.count
                ? _c("span", { staticClass: "tab-count" }, [
                    _vm._v(_vm._s(item.count)),
                  ])
                : _vm._e(),
            ],
            1
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }