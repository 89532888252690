<!-- 员工扩展字段form-item -->
<template>
  <yxt-col :span='12'>
    <yxt-form-item
    style='width: 100%'
    label-width='100px'
    :label="name"
    >
      <select-checkbox
        v-if="type==0"
        v-model="itemValue"
        class="wd100p"
        :options="list"
        :placeholder="$t('pc_biz_lbl_selecttip')"
        clearable
        @change="changeHanlder"
      />
      <yxt-date-picker
        v-else-if="type==1"
        v-model="dateValue"
        type="daterange"
        range-separator="-"
        :start-placeholder="$t('pc_biz_ls_start').d('开始日期')"
        :end-placeholder="$t('pc_biz_ls_end').d('结束日期')"
        format="yyyy-MM-dd"
        value-format="timestamp"
        style="width: 100%;"
        :placeholder="$t('udp_orgoumgmt_lbl_selectdate')"
        @change="changeHanlder"
      />
      <TextSelect v-else-if="type === 3" v-model="textValue" maxLength="100" @change="changeHanlder"></TextSelect>
    </yxt-form-item>
  </yxt-col>
</template>

<script>
import SelectCheckbox from './select-checkbox';
import TextSelect from '../components/text-select';
/*
option:{
    type:'text',text-文本，date-日期，number-数值，droplist-下拉框
    spareName:'',
    maxLength:20,
    optionList:['北京','上海','苏州'],
    spareKey:'spare1'
}
*/
export default {
  props: {
    type: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: ''
    },
    value: {
      type: [String, Array],
      required: true
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  components: {
    SelectCheckbox,
    TextSelect
  },
  data() {
    return {
      textValue: [],
      itemValue: [],
      dateValue: [], // 本地时区的时间戳
      timeOffset: new Date().getTimezoneOffset() * 60 * 1000 + 8 * 60 * 60 * 1000
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(v) {
        if (this.itemValue === v) return;
        v && (this.itemValue = v);
        if (v && this.type === 1) {
          this.dateValue = v.map(i=> Number(i) + this.timeOffset);
        }
        if (v && this.type === 3) {
          this.textValue = v;
        }
      }
    }
  },

  methods: {
    changeHanlder(val) {
      val = val || [];
      if (this.type === 0) {
        this.$emit('update:value', val);
      } else if (this.type === 1) {
        // 日期转为时间戳保存
        this.$emit('update:value', val.map(i=>{
          if (i) return Number(i) - this.timeOffset;
          return i;
        }));
      } else if (this.type === 3) {
        this.$emit('update:value', val);
      } else {}
    },
    getChecked(item) {
      let index = this.itemValue.findIndex((node) => {
        return node.id === item.id;
      });
      return index > -1;
    }
  }
};

</script>
