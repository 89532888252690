var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "yxt-badge",
    {
      staticClass: "audit-center-enter",
      class: [{ "is-stu": _vm.isStu }],
      attrs: { value: _vm.auditCount, hidden: _vm.auditCount === 0, max: 99 },
      nativeOn: {
        click: function ($event) {
          return _vm.goAuditCenter.apply(null, arguments)
        },
      },
    },
    [
      _c("yxt-svg", {
        staticClass: "hover-primary-6-i color-gray-9",
        attrs: {
          width: _vm.size,
          height: _vm.size,
          "remote-url": _vm.mediaPath,
          "icon-class": "audit-center-new02",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }