var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rate" },
    [
      _c("yxtf-rate", {
        staticClass: "comment-title-content-old",
        attrs: {
          value: Math.round(_vm.score),
          disabled: true,
          "icon-size": "small",
          "text-color": "#FA8C16",
          "void-icon-class": "yxt-icon-star-on",
          "void-color": "#d9d9d9",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }