var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "yxt-row",
    {
      staticClass: "yxt-user-selector-container yxt-user-multi-container",
      attrs: { type: "flex" },
    },
    [
      _c(
        "div",
        { staticClass: "yxtbiz-user-search-wrap" },
        [
          _vm.enableGroupCorp
            ? _c("yxtbiz-group-org-select", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.visibleOrgSelector,
                    expression: "visibleOrgSelector",
                  },
                ],
                staticClass: "mr12",
                attrs: {
                  functionCode: _vm.functionCode,
                  dataPermissionCode: _vm.dataPermissionCode,
                  allgroup: true,
                  size: "small",
                  disabled: _vm.disabled,
                  setCurrentOrg: true,
                  targetOrgId:
                    _vm.targetOrgId || (_vm.strongManageMode ? "all" : ""),
                  selectFirst: true,
                  clearable: false,
                  visibleOrgIds: _vm.visibleOrgIds,
                },
                on: { change: _vm.handleOrgChange },
              })
            : _vm._e(),
          _c(
            "select-popover",
            {
              ref: "selectPopover",
              attrs: { size: "small", selectOpition: _vm.selectOpition },
            },
            [
              _c(
                "div",
                { staticClass: "table_tree" },
                [
                  (_vm.enableGroupCorp && _vm.currentTargetOrgId) ||
                  !_vm.enableGroupCorp
                    ? _c("yxtbiz-pos-tree", {
                        ref: "posTree",
                        attrs: {
                          targetOrgId: _vm.currentTargetOrgId,
                          enableGroupCorp: _vm.enableGroupCorp,
                          placeholder: _vm.$t("biz_udp_pos_category"),
                          filterable: "",
                          size: "small",
                          functionCode: _vm.functionCode,
                          dataPermissionCode: _vm.dataPermissionCode,
                        },
                        on: { nodeClick: _vm.nodeClick },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _c("yxt-input", {
            staticClass: "keyword ml12",
            staticStyle: { width: "240px" },
            attrs: {
              size: "small",
              placeholder: _vm.$t("biz_udp_enter_pos_name"),
              searchable: "",
            },
            on: {
              search: function ($event) {
                return _vm.getPositionData(true)
              },
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.getPositionData(true)
              },
            },
            model: {
              value: _vm.params.name,
              callback: function ($$v) {
                _vm.$set(_vm.params, "name", $$v)
              },
              expression: "params.name",
            },
          }),
        ],
        1
      ),
      _c(
        "yxt-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: _vm.refTable,
          staticClass: "mt16 yxt-user-table",
          staticStyle: { width: "100%" },
          attrs: {
            height: _vm.tableHeight,
            "row-key": "id",
            data: _vm.position,
          },
          on: {
            select: _vm.selectPosition,
            "select-all": _vm.selectAllPosition,
          },
        },
        [
          _vm.multiple
            ? _c("yxt-table-column", {
                attrs: {
                  "show-overflow-tooltip": false,
                  "class-name": "yxtbiz-table-selection",
                  type: "selection",
                  width: "42",
                  "reserve-selection": "",
                  "clear-padding": "right",
                },
              })
            : _c("yxt-table-column", {
                attrs: { "show-overflow-tooltip": false, width: "40" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("yxt-radio", {
                          attrs: { label: scope.row.id },
                          on: {
                            change: function ($event) {
                              return _vm.selectPosition(scope.row.id, scope.row)
                            },
                          },
                          model: {
                            value: _vm.singleSelect,
                            callback: function ($$v) {
                              _vm.singleSelect = $$v
                            },
                            expression: "singleSelect",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
          _vm.multiple
            ? _c(
                "yxt-table-column",
                {
                  attrs: {
                    width: "18",
                    align: "right",
                    "clear-padding": "left-right",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _c(
                        "yxt-dropdown",
                        {
                          attrs: { placement: "bottom-start" },
                          on: { command: _vm.tableHeaderCommand },
                        },
                        [
                          _c("span"),
                          _c(
                            "yxt-dropdown-menu",
                            { attrs: { slot: "dropdown" }, slot: "dropdown" },
                            [
                              _c(
                                "yxt-dropdown-item",
                                { attrs: { command: "selectCurrent" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm
                                        .$t("pc_biz_enroll_select_current_page")
                                        .d("选择本页")
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "yxt-dropdown-item",
                                { attrs: { command: "selectAll" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm
                                        .$t("pc_biz_enroll_select_all")
                                        .d("选择全部")
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "yxt-dropdown-item",
                                { attrs: { command: "clearSelect" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm
                                        .$t("pc_biz_enroll_clear_all")
                                        .d("清空所选")
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
          _c("yxt-table-column", {
            attrs: {
              prop: "name",
              "show-overflow-tooltip": "",
              label: _vm.$t("biz_udp_pos_name"),
              "min-width": "180",
            },
          }),
          _c("yxt-table-column", {
            attrs: {
              prop: "gradeList",
              "show-overflow-tooltip": "",
              label: _vm.$t("biz_udp_occupation_level"),
              "min-width": "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          (scope.row.gradeList || [])
                            .map((item) => item.name)
                            .join() || "--"
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("yxt-table-column", {
            attrs: {
              "show-overflow-tooltip": "",
              prop: "catalogName",
              label: _vm.$t("biz_udp_pos_category"),
              "min-width": "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.catalogName || _vm.$t("biz_udp_pos_system")
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("template", { slot: "empty" }, [
            _c(
              "div",
              { staticStyle: { "line-height": "normal" } },
              [_c("yxt-empty")],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "yxt-row",
        {
          staticClass: "yxtbiz-user-selector-mask mt16",
          attrs: { type: "flex", align: "middle" },
        },
        [
          _c("yxt-row", {
            staticClass: "col-flex-1",
            attrs: { type: "flex", align: "middle" },
          }),
          _c("yxt-pagination", {
            attrs: {
              "page-size": _vm.params.limit,
              "page-sizes": [5, 10, 20, 30, 50, 100],
              "current-page": _vm.currentPage,
              layout: "total, prev, pager, next",
              "simple-total": "",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "update:currentPage": function ($event) {
                _vm.currentPage = $event
              },
              "update:current-page": function ($event) {
                _vm.currentPage = $event
              },
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }