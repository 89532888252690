<template>
  <div
    v-if="navsFirst && navsFirst.length > 0"
    :class="['yxtbiz-nav-immersive', {
      'yxtbiz-nav-immersive--notop': !isShowTopNav,
      'yxtbiz-nav-immersive--visible': menusVisible
      }]"
  >
    <yxt-layout root>
      <yxt-layout-main class="yxtbiz-nav-immersive__top" v-if="isShowTopNav">
        <yxt-layout-header class="yxtbiz-nav-immersive__header">
          <!-- 顶部导航 -->
          <div class="yxtbiz-nav-immersive__menu-btn" ref="menusBtn" v-if="cnavs && cnavs.length >= 2">
            <!-- 左上角按钮区域 -->
            <div class="yxtbiz-nav-immersive__menu-btn-icon"
              @mouseenter="openMenus()"
              @mouseleave="hideMenus()"
              >
              <yxt-svg
                icon-class="all"
                width="20px"
                height="20px"
                class
                :style="{transform: collapse ? 'rotate(180deg)' : ''}"
              />
            </div>
            <div class="yxtbiz-nav-immersive__menu-btn-text">
              {{cnavs[1]|Globalize('name')}}
            </div>
            <!-- 弹出菜单 -->
            <transition name="yxt-zoom-in-top">
              <div class="yxtbiz-nav-immersive__panel-pop"
                :style="{
                  'top': popmenuTop + 'px',
                  'height': 'calc(100vh - ' + popmenuTop + 'px)'
                }"
                ref="menus" v-show="menusVisible"
                @mouseenter="openMenus()"
                @mouseleave="hideMenus()"
                @mousemove="menuHoving = true">
                <div class="yxtbiz-nav-immersive__dialog-arrow">
                </div>
                <div class="yxtbiz-nav-immersive__logo">
                  <img v-if="logo" @click="goMainIndex()" :src="logo" alt/>
                </div>
                <div class="yxtbiz-nav-immersive__menu-wrap">
                  <!-- 一级菜单 -->
                  <div class="yxtbiz-nav-immersive__menus yxtbiz-nav-immersive__menus--first">
                    <yxt-scrollbar :fit-height="true">
                      <ul>
                        <li
                          class="yxtbiz-nav-immersive__menu"
                          :class="{
                            'color-primary-6-i bg-primary_x-1-i': hoverId === menu.id || (pageCodes.length > 0 && pageCodes[0] === menu.code)
                          }"
                          v-for="(menu) in navsFirst"
                          :key="menu.id"
                          @click="toSubPage(menu)"
                          @mouseover="hoverPage(menu)"
                        > 
                          <span class="ellipsis yxtbiz-nav-immersive__menu-name">
                            <template v-if="menu.id === '_app_all'">{{$t('pc_biz_nav_btn_allapplication')}}</template>
                            <template v-else>{{menu | Globalize("name")}}</template>
                          </span>
                          <yxt-svg
                            v-if="menu.subMenuInfoList && menu.subMenuInfoList.length > 0"
                            class="color-gray-6 yxtbiz-nav-immersive__arrow"
                            width="16px"
                            height="16px"
                            :class="{'color-primary-6-i': hoverId === menu.id || (pageCodes.length > 0 && pageCodes[0] === menu.code)}"
                            :icon-class="'arrow-right'"
                          />
                        </li>
                      </ul>
                      <ul class="yxtbiz-nav-immersive__sub-menus">
                        <li  v-if="!SOURCE_100_WXISV && !EDITION_TOOL"
                          @mouseover="hoverPage()"
                          @click="goSetting()"
                          class="yxtbiz-nav-immersive__menu hover-primary-6 hover-bg-primary_x-1-i"
                        >
                          <span class="ellipsis yxtbiz-nav-immersive__menu-name">{{ $t('pc_biz_nav_btn_usercenter') }}</span>
                        </li>
                        <li
                          @mouseover="hoverPage()"
                          @click="logout()"
                          class="yxtbiz-nav-immersive__menu hover-primary-6 hover-bg-primary_x-1-i"
                        >
                          <span class="ellipsis yxtbiz-nav-immersive__menu-name">{{ $t('pc_biz_nav_btn_logout') }}</span>
                        </li>
                      </ul>
                    </yxt-scrollbar>
                  </div>
                  <!-- 二级菜单 -->
                  <div class="yxtbiz-nav-immersive__menus yxtbiz-nav-immersive__menus--second"
                    :class="{'yxtbiz-nav-immersive__menus--hide': navsSecond.length === 0}"
                  >
                    <yxt-scrollbar :fit-height="true">
                      <ul v-if="navsSecond.length > 0">
                        <li
                          class="yxtbiz-nav-immersive__menu hover-primary-6-i hover-bg-primary_x-1-i"
                          :class="{
                            'color-primary-6-i bg-primary_x-1-i': (pageCodes.length > 1 && pageCodes[1] === menu.code)
                          }"
                          v-for="(menu) in navsSecond"
                          :key="menu.id"
                          @click="toSubPage(menu)"
                        >
                          <span class="ellipsis yxtbiz-nav-immersive__menu-name">{{menu | Globalize("name")}}</span>
                        </li>
                      </ul>
                    </yxt-scrollbar>
                  </div>
                </div>
                <div class="yxtbiz-nav-immersive__dialog-bottom">
                </div>
              </div>
            </transition>
          </div>
          <!-- 右上角三级菜单 -->
          <ul  class="yxtbiz-nav-immersive__menus-top" v-if="cnavs && cnavs.length >= 3">
            <li :data-code="menu.code"
              class="yxtbiz-nav-immersive__menu-top"
              v-for="(menu) in cnavs[1].subMenuInfoList"
              :class="{'yxtbiz-nav-immersive__menu-top--active': cnavs[2].id === menu.id }"
              :key="menu.id"
              @click="openPage(menu)"
            >
              {{menu | Globalize("name")}}
            </li>
          </ul>
        </yxt-layout-header>
      </yxt-layout-main>
      <!-- 网页主体 -->
      <yxt-layout-main class="yxtbiz-nav-immersive__body">
        <yxt-layout class="yxtbiz-nav-immersive__content">
          <slot></slot>
        </yxt-layout>
      </yxt-layout-main>
    </yxt-layout>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { Globalize, getScrollTop, goSubNavPage, uerLogout, SOURCE_100_WXISV, EDITION_TOOL} from './common';
export default {
  name: 'YxtbizNavImmersive',
  data() {
    return {
      EDITION_TOOL,
      SOURCE_100_WXISV,
      currentPageCode: '',
      navLoaded: false,
      menusVisible: false,
      hoverId: '',
      popmenuTop: 66,
      hoverMenus: {
        first: null,
        second: null
      },
      menuStamp: 0,
      menuHoving: false,
      menuCloseTimeout: null
    };
  },
  props: {
    // 是否导初始页
    isRedirectFirstPage: {
      type: Boolean,
      default: false
    },
    // 是否显示头导航
    isShowTopNav: {
      type: Boolean,
      default: true
    },
    // 一级导航的code，导初始页时会寻找第一个此导航下有权限的页面
    firstPageCode: {
      type: String,
      default: ''
    },
    mainPageCode: {
      type: String,
      default: ''
    },
    mainPathname: {
      type: String,
      default: ''
    },
    hash: {
      type: String,
      default: ''
    },
    // 静态的导航数据，传入时不再读取数据库的导航信息
    navDatas: {
      type: Object,
      default: null
    },
    top: {
      type: Number,
      default: 80
    },
    // 页面跳转路径
    allPath: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState({
      collapse: state => state.navManageStore.collapse
    }),
    logo() {
      return window.localStorage.logoUrl;
    },
    homepage() {
      let url = '/';
      if (this.$store.state.navManageStore.navs.enableAppMenus.length &&
        this.$store.state.navManageStore.navs.enableAppMenus[0].pageUrl) {
        url = this.$store.state.navManageStore.navs.enableAppMenus[0].pageUrl;
      }
      return url;
    },
    pageCodes() {
      // return this.$store.state.navManageStore.pageCodes;
      return ['', '', ''];
    },
    cnavs() {
      return this.$store.state.navManageStore.cnavs;
    },
    currentNav() {
      if (!this.$store.state.navManageStore.lastUpdateNavStamp || this.$store.state.navManageStore.navs.enableAppMenus.length === 0) {
        return {};
      }
      this.navLoaded = true;
      return this.$store.state.navManageStore.navs;
    },
    // 所有显示在导航上的一级菜单
    navsFirst() {
      if (!this.currentNav || !this.currentNav.enableAppMenus) {
        return [];
      }
      return this.currentNav.enableAppMenus.filter((m)=> {
        return m.showed;
      });
    },
    // 所有显示在导航上的二级菜单
    navsSecond() {
      if (this.hoverMenus.first) {
        return this.hoverMenus.first.subMenuInfoList;
      }
      // if (this.cnavs && this.cnavs.length > 1) {
      //   return this.cnavs[0].subMenuInfoList;
      // }
      return [];
    }
  },
  watch: {
    'navLoaded'(val, oldVal) {
      if (!oldVal && val) {
        this.toFirstPage();
      }
    },
    'isRedirectFirstPage'() {
      this.toFirstPage();
    }
  },
  created() {
    // document.addEventListener('click', this.bindClick);
  },
  mounted() {
  },
  methods: {
    ...mapActions('navManageStore', ['setCollapse']),
    // bindClick(e) {
    //   if (this.$refs.menus && this.$refs.menus.contains(e.target)) {
    //     return;
    //   } if (this.$refs.menusBtn && this.$refs.menusBtn.contains(e.target)) {
    //     this.menusVisible = !this.menusVisible;
    //   } else {
    //     this.menusVisible = false;
    //   }
    // },
    openMenus() {
      this.menuStamp = Date.now();
      // console.log(this.menuStamp);
      if (this.menusVisible) {
        return;
      }
      let top = getScrollTop(this.$refs.menusBtn);
      this.popmenuTop = 66 - top;
      if (this.popmenuTop < 0) {
        this.popmenuTop = 0;
      }
      this.menusVisible = true;
    },
    hideMenus() {
      this.menuHoving = false;
      this.menuStamp = Date.now();
      // console.log(this.menuStamp);
      let oldMenuStamp = this.menuStamp;
      let vm = this;
      if (this.menuCloseTimeout) {
        window.clearTimeout(this.menuCloseTimeout);
      }
      this.menuCloseTimeout = setTimeout(() => {
        if (oldMenuStamp !== vm.menuStamp || this.menuHoving) {
          return;
        }
        vm.hoverId = '';
        vm.hoverMenus.first = null;
        vm.menusVisible = false;
      }, 300);
    },
    hoverPage(menu) {
      if (menu) {
        this.hoverId = menu.id;
        this.hoverMenus.first = menu;
      } else {
        this.hoverId = '';
        this.hoverMenus.first = null;
      }
    },
    showMoreMenu() {
      this.moreMenuVisible = true;
    },
    hideMoreMenu() {
      this.moreMenuVisible = false;
    },
    redirectIndex() {
      location.href = this.homepage;
    },
    toSubPage(page) {
      goSubNavPage(page, this.mainPathname, this);
    },
    openPage(page) {
      // goNavPage(page, this.mainPathname, this);
      goSubNavPage(page, this.mainPathname, this);
    },
    toFirstPage() {
      let navs = this.currentNav.enableAppMenus;
      let isFind = false;
      if (this.isRedirectFirstPage) {
        if (!navs || navs.length === 0) {
          window.location.href = `${this.allPath}/#/login`; // 前往登录
        }
        // 导初始页时会寻找第一个此导航下有权限的页面
        navs.forEach(navFirst => {
          if (isFind) {
            return;
          }
          if (navFirst.showed && (navFirst.code === this.firstPageCode || !this.firstPageCode)) {
            isFind = true;
            let pageTo = navFirst; // 一级
            if (pageTo.subMenuInfoList && pageTo.subMenuInfoList.length > 0) {
              pageTo = pageTo.subMenuInfoList[0]; // 二级
              if (pageTo.subMenuInfoList && pageTo.subMenuInfoList.length > 0) {
                pageTo = pageTo.subMenuInfoList[0]; // 三级
              }
            }
            if (pageTo.pageUrl.indexOf(this.mainPathname) >= 0) {
              let urlTo = pageTo.pageUrl.replace(this.mainPathname + '#', '');
              this.$router.replace({
                path: urlTo
              });
            } else {
              window.location.replace(pageTo.pageUrl);
            }
          }
        });
      }
    },
    logout() {
      uerLogout(1); // 退出登录
    },
    goMsgCenter() {
      window.location.href = `${this.allPath}/#/inbox`; // 站内信
    },
    goSetting() {
      window.location.href = `${this.allPath}/#/userinfo`; // 用户主页
    }
  },
  beforeDestroy() {
    // document.removeEventListener('click', this.bindClick);
  },
  filters: {
    Globalize(data, key) {
      return Globalize(data, key);
    }
  }
};
</script>
