var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "block" },
    [
      _c("span", { staticClass: "demonstration" }),
      _c("yxt-cascader", {
        ref: "areaSelect",
        attrs: {
          options: _vm.options,
          props: {
            expandTrigger: _vm.expandTrigger,
            value: "id",
            label: "name",
          },
          separator: _vm.separator,
          clearable: _vm.clearable,
        },
        on: { change: _vm.handleChange },
        model: {
          value: _vm.nativeValue,
          callback: function ($$v) {
            _vm.nativeValue = $$v
          },
          expression: "nativeValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }