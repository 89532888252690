<template>
  <ClickToJump :message="urlTitle" :target-url="targetUrl" :datas="datas" />
</template>
 
<script>
import ClickToJump from '../common/click-to-jump.vue';
import CommonMixin from '../common/props-mixin';

export default {
  name: 'CreateDecorate',
  components: {
    ClickToJump
  },
  mixins: [CommonMixin],
  mounted() {
    console.log('datas', this.datas);
    console.log('params', this.params);
    console.log('mode', this.mode);
  },
  computed: {
    urlTitle() {
      if (this.params.renovationType) {
        switch (this.params.renovationType) {
          case 1:
            if (this.params.renovationEnd === 1) {
              return this.$t('pc_biz_decorate_web_index_title').d('装修电脑端首页');
            } else {
              return this.$t('pc_biz_decorate_mob_index_title').d('装修移动端首页');
            }
          case 2:
            return this.$t('pc_biz_decorate_study_index_title').d('装修我的学习');
          case 3:
            if (this.params.renovationEnd === 1) {
              return this.$t('pc_biz_decorate_login_index_title').d('装修电脑端登录页');
            } else {
              return this.$t('pc_biz_decorate_login_mob_index_title').d('装修移动端登录页');
            }
          default:
            return this.$t('pc_biz_decorate_web_index_title').d('装修电脑端首页');
        }
      } else {
        return this.$t('pc_biz_decorate_web_index_title').d('装修电脑端首页');
      }
    },
    targetUrl() {
      if (this.params.renovationType) {
        switch (this.params.renovationType) {
          case 1:
            if (this.params.renovationEnd === 1) {
              return `${window.location.origin}/main/#/homepage/web`;
            } else {
              return `${window.location.origin}/main/#/homepage/mobile`;
            }
          case 2:
            return `${window.location.origin}/main/#/myLearning/web`;
          case 3:
            if (this.params.renovationEnd === 1) {
              return `${window.location.origin}/main/#/login/web`;
            } else {
              return `${window.location.origin}/main/#/login/mobile`;
            }
          default:
            return `${window.location.origin}/main/#/homepage/web`;
        }
      } else {
        return `${window.location.origin}/main/#/homepage/web`;
      }
    }
  },
  data() {
    return {
    };
  }
};
 </script>