<!-- 创建时间2023/11/17 14:19:39 -->
<!-- 创建人：Zhao bing bing -->
<!-- 组件描述：自学课程-->
<template>
  <KngRelated type="selfCourse" v-bind="$attrs" v-on="$listeners"/>
</template>

<script>
import KngRelated from '../common/kng/kng-releated.vue';
export default {
  name: 'KngSelfStudyCourse',
  components: {
    KngRelated
  }
};
</script>