var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ClickToJump", {
    attrs: {
      message: _vm.urlTitle,
      "target-url": _vm.targetUrl,
      datas: _vm.datas,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }