var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "article",
    {
      staticClass: "bg-white width-percent-100 yxtbiz-ai-robot-search-archive",
    },
    [
      _vm.dataList.length === 0
        ? _c("EmptyData")
        : [
            _c(
              "header",
              {
                staticClass: "ph12",
                class: [
                  "color-gray-7",
                  _vm.size === "small"
                    ? "font-size-14 lh24"
                    : "font-size-16 lh28",
                ],
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$t(
                        "yxtbiz_ai_lbl_searchallarchibe" /* 为您找到以下个人档案： */
                      )
                    ) +
                    "\n    "
                ),
              ]
            ),
            _c(
              "main",
              { staticClass: "ph12" },
              [
                _c(
                  "yxt-row",
                  {
                    staticClass: "yxtbiz-ai-robot-search-archive__flex",
                    attrs: { gutter: 12 },
                  },
                  [
                    _vm.archiveType === 0 ||
                    _vm.archiveType.includes(1) ||
                    _vm.archiveType.includes(0)
                      ? _c(
                          "yxt-col",
                          {
                            staticClass:
                              "mt12 yxtbiz-ai-robot-search-archive__hauto",
                            attrs: { span: _vm.size === "small" ? 24 : 12 },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "yxtbiz-ai-robot-search-archive__item hand",
                                class:
                                  _vm.size === "small"
                                    ? ""
                                    : "yxtbiz-ai-robot-search-archive__height100",
                                on: {
                                  click: function ($event) {
                                    return _vm.linkJump(1)
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "pl20" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mt16 color-gray-10 yxtbiz-ai-robot-search-archive__weight500",
                                      class:
                                        _vm.size === "small"
                                          ? "standard-size-14"
                                          : "standard-size-16",
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "yxtbiz_ai_lbl_o2oarchive" /* 项目培训 */
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mt4",
                                      class:
                                        _vm.size === "small"
                                          ? "standard-size-12"
                                          : "standard-size-14",
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "yxtbiz_ai_lbl_o2oallcount" /* 总共学习项目 */
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                      _c("span", { staticClass: "ml12" }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.o2oDetail
                                                .studyProjectTotalCount
                                                ? _vm.o2oDetail
                                                    .studyProjectTotalCount
                                                : 0
                                            ) +
                                            "\n                "
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mt16 mb16 color-gray-7",
                                      class:
                                        _vm.size === "small"
                                          ? "standard-size-12"
                                          : "standard-size-14",
                                    },
                                    [
                                      _c("div", [
                                        _c("span", {
                                          staticClass:
                                            "yxtbiz-ai-robot-search-archive__point yxtbiz-ai-robot-search-archive__point-corlor43",
                                        }),
                                        _c(
                                          "span",
                                          {
                                            class:
                                              _vm.size === "small"
                                                ? "ml8"
                                                : "ml12",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "pc_biz_study_lbl_complete" /* 已完成 */
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("span", { staticClass: "ml12" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.o2oDetail.completedCount
                                                ? _vm.o2oDetail.completedCount
                                                : 0
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c("div", { staticClass: "mt8" }, [
                                        _c("span", {
                                          staticClass:
                                            "yxtbiz-ai-robot-search-archive__point yxtbiz-ai-robot-search-archive__point-corlor52",
                                        }),
                                        _c(
                                          "span",
                                          {
                                            class:
                                              _vm.size === "small"
                                                ? "ml8"
                                                : "ml12",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "pc_biz_ai_lbl_studying" /* 学习中 */
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("span", { staticClass: "ml12" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.o2oDetail.studyingCount || 0
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c("div", { staticClass: "mt8" }, [
                                        _c("span", {
                                          staticClass:
                                            "yxtbiz-ai-robot-search-archive__point yxtbiz-ai-robot-search-archive__point-corlorbf",
                                        }),
                                        _c(
                                          "span",
                                          {
                                            class:
                                              _vm.size === "small"
                                                ? "ml8"
                                                : "ml12",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "pc_biz_ai_lbl_uncompleted" /* 未完成 */
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("span", { staticClass: "ml12" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.o2oDetail.incompleteCount || 0
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "yxtbiz-ai-robot-search-archive__rate",
                                  },
                                  [
                                    _c(
                                      "yxtf-progress",
                                      {
                                        attrs: {
                                          percentage:
                                            _vm.o2oDetail.completeRate || 0,
                                          width: 108,
                                          color: "#436bff",
                                          outColor: "#f0f0f0",
                                          "stroke-width": 6,
                                          type: "circle",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "archive-detail-progress",
                                            attrs: { slot: "content" },
                                            slot: "content",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "standard-size-20 color-gray-10",
                                              },
                                              [
                                                _vm.o2oDetail
                                                  .studyProjectTotalCount
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.o2oDetail
                                                            .completeRate &&
                                                            _vm.o2oDetail.completeRate.toFixed(
                                                              0
                                                            )
                                                        ) + "%"
                                                      ),
                                                    ])
                                                  : _c("span", [_vm._v("--")]),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "standard-size-14 color-gray-7 mr2 ml2",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "yxtbiz_ai_lbl_completerate" /* 完成率 */
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.archiveType === 0 ||
                    _vm.archiveType.includes(2) ||
                    _vm.archiveType.includes(0)
                      ? _c(
                          "yxt-col",
                          {
                            staticClass:
                              "mt12 yxtbiz-ai-robot-search-archive__hauto",
                            attrs: { span: _vm.size === "small" ? 24 : 12 },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "yxtbiz-ai-robot-search-archive__item hand",
                                class:
                                  _vm.size === "small"
                                    ? ""
                                    : "yxtbiz-ai-robot-search-archive__height100",
                                on: {
                                  click: function ($event) {
                                    return _vm.linkJump(2)
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "pl20" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mt16 color-gray-10 yxtbiz-ai-robot-search-archive__weight500",
                                      class:
                                        _vm.size === "small"
                                          ? "standard-size-14"
                                          : "standard-size-16",
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "yxtbiz_ai_lbl_kngarchive" /* 自主学习 */
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mt4",
                                      class:
                                        _vm.size === "small"
                                          ? "standard-size-12"
                                          : "standard-size-14",
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "yxtbiz_ai_lbl_courseallcount" /* 总共学习课程 */
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                      _c("span", { staticClass: "ml12" }, [
                                        _vm._v(
                                          _vm._s(_vm.kngDetail.totalCount || 0)
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mt16 mb16 color-gray-7",
                                      class:
                                        _vm.size === "small"
                                          ? "standard-size-12"
                                          : "standard-size-14",
                                    },
                                    [
                                      _c("div", [
                                        _c("span", {
                                          staticClass:
                                            "yxtbiz-ai-robot-search-archive__point yxtbiz-ai-robot-search-archive__point-corlor43",
                                        }),
                                        _c(
                                          "span",
                                          {
                                            class:
                                              _vm.size === "small"
                                                ? "ml8"
                                                : "ml12",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "pc_biz_study_lbl_complete" /* 已完成 */
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("span", { staticClass: "ml12" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.kngDetail.completeCount || 0
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c("div", { staticClass: "mt8" }, [
                                        _c("span", {
                                          staticClass:
                                            "yxtbiz-ai-robot-search-archive__point yxtbiz-ai-robot-search-archive__point-corlor52",
                                        }),
                                        _c(
                                          "span",
                                          {
                                            class:
                                              _vm.size === "small"
                                                ? "ml8"
                                                : "ml12",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "pc_biz_ai_lbl_studying" /* 学习中 */
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("span", { staticClass: "ml12" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.kngDetail.learningCount || 0
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "yxtbiz-ai-robot-search-archive__rate",
                                  },
                                  [
                                    _c(
                                      "yxtf-progress",
                                      {
                                        attrs: {
                                          percentage: _vm.kngDetail.rate || 0,
                                          width: 108,
                                          color: "#436bff",
                                          outColor: "#f0f0f0",
                                          "stroke-width": 6,
                                          type: "circle",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "archive-detail-progress",
                                            attrs: { slot: "content" },
                                            slot: "content",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "standard-size-20 color-gray-10",
                                              },
                                              [
                                                _vm.kngDetail.totalCount
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.kngDetail.rate &&
                                                            _vm.kngDetail.rate.toFixed(
                                                              0
                                                            )
                                                        ) + "%"
                                                      ),
                                                    ])
                                                  : _c("span", [_vm._v("--")]),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "standard-size-14 color-gray-7 mr2 ml2",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "yxtbiz_ai_lbl_completerate" /* 完成率 */
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.archiveType === 0 ||
                    _vm.archiveType.includes(3) ||
                    _vm.archiveType.includes(0)
                      ? _c(
                          "yxt-col",
                          {
                            staticClass:
                              "mt12 yxtbiz-ai-robot-search-archive__hauto",
                            attrs: { span: _vm.size === "small" ? 24 : 12 },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "yxtbiz-ai-robot-search-archive__item hand",
                                class:
                                  _vm.size === "small"
                                    ? ""
                                    : "yxtbiz-ai-robot-search-archive__height100",
                                on: {
                                  click: function ($event) {
                                    return _vm.linkJump(3)
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "pl20" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mt16 color-gray-10 yxtbiz-ai-robot-search-archive__weight500",
                                      class:
                                        _vm.size === "small"
                                          ? "standard-size-14"
                                          : "standard-size-16",
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "yxtbiz_ai_lbl_livearchive" /* 学习直播 */
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mt4",
                                      class:
                                        _vm.size === "small"
                                          ? "standard-size-12"
                                          : "standard-size-14",
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "yxtbiz_ai_lbl_liveallcount" /* 总共观看直播 */
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                      _c("span", { staticClass: "ml12" }, [
                                        _vm._v(
                                          _vm._s(_vm.liveDetail.total || 0)
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mt16 mb16 color-gray-7",
                                      class:
                                        _vm.size === "small"
                                          ? "standard-size-12"
                                          : "standard-size-14",
                                    },
                                    [
                                      _c("div", [
                                        _c("span", {
                                          staticClass:
                                            "yxtbiz-ai-robot-search-archive__point yxtbiz-ai-robot-search-archive__point-corlor43",
                                        }),
                                        _c(
                                          "span",
                                          {
                                            class:
                                              _vm.size === "small"
                                                ? "ml8"
                                                : "ml12",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "pc_biz_ai_lbl_viewed" /* 已观看/被指派 */
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("span", { staticClass: "ml12" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.liveDetail
                                                .joinedAppointSize || 0
                                            ) +
                                              "/" +
                                              _vm._s(
                                                _vm.liveDetail
                                                  .totalAppointSize || 0
                                              )
                                          ),
                                        ]),
                                      ]),
                                      _c("div", { staticClass: "mt8" }, [
                                        _c("span", {
                                          staticClass:
                                            "yxtbiz-ai-robot-search-archive__point yxtbiz-ai-robot-search-archive__point-corlor52",
                                        }),
                                        _c(
                                          "span",
                                          {
                                            class:
                                              _vm.size === "small"
                                                ? "ml8"
                                                : "ml12",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "pc_biz_ai_lbl_activeviewed" /* 主动观看 */
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("span", { staticClass: "ml12" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.liveDetail.joinedSize || 0
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "yxtbiz-ai-robot-search-archive__rate",
                                  },
                                  [
                                    _c(
                                      "yxtf-progress",
                                      {
                                        attrs: {
                                          percentage: _vm.liveDetail.rate || 0,
                                          width: 108,
                                          color: "#436bff",
                                          outColor: "#f0f0f0",
                                          "stroke-width": 6,
                                          type: "circle",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "archive-detail-progress",
                                            attrs: { slot: "content" },
                                            slot: "content",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "standard-size-20 color-gray-10",
                                              },
                                              [
                                                _vm.liveDetail.total
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.liveDetail.rate &&
                                                            _vm.liveDetail.rate.toFixed(
                                                              0
                                                            )
                                                        ) + "%"
                                                      ),
                                                    ])
                                                  : _c("span", [_vm._v("--")]),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "standard-size-14 color-gray-7 mr2 ml2",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "yxtbiz_ai_lbl_attendance" /* 出勤率 */
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.archiveType === 0 ||
                    _vm.archiveType.includes(4) ||
                    _vm.archiveType.includes(0)
                      ? _c(
                          "yxt-col",
                          {
                            staticClass:
                              "mt12 yxtbiz-ai-robot-search-archive__hauto",
                            attrs: { span: _vm.size === "small" ? 24 : 12 },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "yxtbiz-ai-robot-search-archive__item hand",
                                class:
                                  _vm.size === "small"
                                    ? ""
                                    : "yxtbiz-ai-robot-search-archive__height100",
                                on: {
                                  click: function ($event) {
                                    return _vm.linkJump(4)
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "pl20" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mt16 color-gray-10 yxtbiz-ai-robot-search-archive__weight500",
                                      class:
                                        _vm.size === "small"
                                          ? "standard-size-14"
                                          : "standard-size-16",
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "yxtbiz_ai_lbl_examarchive" /* 学员考试 */
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mt4",
                                      class:
                                        _vm.size === "small"
                                          ? "standard-size-12"
                                          : "standard-size-14",
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "pc_biz_ai_lbl_exam" /* 考试 */
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                      _c("span", { staticClass: "ml12" }, [
                                        _vm._v(
                                          _vm._s(_vm.examDetail.total || 0)
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mt16 mb16 color-gray-7",
                                      class:
                                        _vm.size === "small"
                                          ? "standard-size-12"
                                          : "standard-size-14",
                                    },
                                    [
                                      _c("div", [
                                        _c("span", {
                                          staticClass:
                                            "yxtbiz-ai-robot-search-archive__point yxtbiz-ai-robot-search-archive__point-corlor43",
                                        }),
                                        _c(
                                          "span",
                                          {
                                            class:
                                              _vm.size === "small"
                                                ? "ml8"
                                                : "ml12",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "pc_biz_study_lbl_complete" /* 已完成 */
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("span", { staticClass: "ml12" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.examDetail
                                                .completeArrangeCount || 0
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c("div", { staticClass: "mt8" }, [
                                        _c("span", {
                                          staticClass:
                                            "yxtbiz-ai-robot-search-archive__point yxtbiz-ai-robot-search-archive__point-corlor52",
                                        }),
                                        _c(
                                          "span",
                                          {
                                            class:
                                              _vm.size === "small"
                                                ? "ml8"
                                                : "ml12",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "pc_biz_ai_lbl_marking" /* 批阅中 */
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("span", { staticClass: "ml12" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.examDetail
                                                .reviewArrangeCount || 0
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c("div", { staticClass: "mt8" }, [
                                        _c("span", {
                                          staticClass:
                                            "yxtbiz-ai-robot-search-archive__point yxtbiz-ai-robot-search-archive__point-corlorbf",
                                        }),
                                        _c(
                                          "span",
                                          {
                                            class:
                                              _vm.size === "small"
                                                ? "ml8"
                                                : "ml12",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "pc_biz_ai_lbl_pendingexam" /* 待考试 */
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("span", { staticClass: "ml12" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.examDetail.waitArrangeCount ||
                                                0
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c("div", { staticClass: "mt8" }, [
                                        _c("span", {
                                          staticClass:
                                            "yxtbiz-ai-robot-search-archive__point yxtbiz-ai-robot-search-archive__point-corlorbf",
                                        }),
                                        _c(
                                          "span",
                                          {
                                            class:
                                              _vm.size === "small"
                                                ? "ml8"
                                                : "ml12",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "pc_biz_ai_lbl_uncompleted" /* 未完成 */
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("span", { staticClass: "ml12" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.examDetail
                                                .uncompleteArrangeCount || 0
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "yxtbiz-ai-robot-search-archive__rate",
                                  },
                                  [
                                    _c(
                                      "yxtf-progress",
                                      {
                                        attrs: {
                                          percentage: _vm.examDetail.rate || 0,
                                          width: 108,
                                          color: "#436bff",
                                          outColor: "#f0f0f0",
                                          "stroke-width": 6,
                                          type: "circle",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "archive-detail-progress",
                                            attrs: { slot: "content" },
                                            slot: "content",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "standard-size-20 color-gray-10",
                                              },
                                              [
                                                _vm.examDetail.total
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.examDetail.rate &&
                                                            _vm.examDetail.rate.toFixed(
                                                              0
                                                            )
                                                        ) + "%"
                                                      ),
                                                    ])
                                                  : _c("span", [_vm._v("--")]),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "standard-size-14 color-gray-7 mr2 ml2",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "yxtbiz_ai_lbl_completerate" /* 完成率 */
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.archiveType === 0 ||
                    _vm.archiveType.includes(5) ||
                    _vm.archiveType.includes(0)
                      ? _c(
                          "yxt-col",
                          {
                            staticClass:
                              "mt12 yxtbiz-ai-robot-search-archive__hauto",
                            attrs: { span: _vm.size === "small" ? 24 : 12 },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "yxtbiz-ai-robot-search-archive__item hand",
                                class:
                                  _vm.size === "small"
                                    ? ""
                                    : "yxtbiz-ai-robot-search-archive__height100",
                                on: {
                                  click: function ($event) {
                                    return _vm.linkJump(5)
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "pl20" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mt16 color-gray-10 yxtbiz-ai-robot-search-archive__weight500",
                                      class:
                                        _vm.size === "small"
                                          ? "standard-size-14"
                                          : "standard-size-16",
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "yxtbiz_ai_lbl_pararchive" /* 学员练习 */
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mt4",
                                      class:
                                        _vm.size === "small"
                                          ? "standard-size-12"
                                          : "standard-size-14",
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "pc_biz_ai_lbl_practice" /* 练习 */
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                      _c("span", { staticClass: "ml12" }, [
                                        _vm._v(
                                          _vm._s(_vm.practiceDetail.total || 0)
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mt16 mb16 color-gray-7",
                                      class:
                                        _vm.size === "small"
                                          ? "standard-size-12"
                                          : "standard-size-14",
                                    },
                                    [
                                      _c("div", [
                                        _c("span", {
                                          staticClass:
                                            "yxtbiz-ai-robot-search-archive__point yxtbiz-ai-robot-search-archive__point-corlor52",
                                        }),
                                        _c(
                                          "span",
                                          {
                                            class:
                                              _vm.size === "small"
                                                ? "ml8"
                                                : "ml12",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "pc_biz_ai_lbl_processing" /* 进行中 */
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("span", { staticClass: "ml12" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.practiceDetail
                                                .progressPraCount || 0
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c("div", { staticClass: "mt8" }, [
                                        _c("span", {
                                          staticClass:
                                            "yxtbiz-ai-robot-search-archive__point yxtbiz-ai-robot-search-archive__point-corlorbf",
                                        }),
                                        _c(
                                          "span",
                                          {
                                            class:
                                              _vm.size === "small"
                                                ? "ml8"
                                                : "ml12",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "pc_biz_ai_ended" /* 已结束 */
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("span", { staticClass: "ml12" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.practiceDetail
                                                .endedPraCount || 0
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "yxtbiz-ai-robot-search-archive__rate",
                                  },
                                  [
                                    _c(
                                      "yxtf-progress",
                                      {
                                        attrs: {
                                          percentage:
                                            _vm.practiceDetail.rate || 0,
                                          width: 108,
                                          color: "#436bff",
                                          outColor: "#f0f0f0",
                                          "stroke-width": 6,
                                          type: "circle",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "archive-detail-progress",
                                            attrs: { slot: "content" },
                                            slot: "content",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "standard-size-20 color-gray-10",
                                              },
                                              [
                                                _vm.practiceDetail.total
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.practiceDetail
                                                            .rate &&
                                                            _vm.practiceDetail.rate.toFixed(
                                                              0
                                                            )
                                                        ) + "%"
                                                      ),
                                                    ])
                                                  : _c("span", [_vm._v("--")]),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "standard-size-14 color-gray-7 mr2 ml2",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "yxtbiz_ai_lbl_participationrate" /* 参与率 */
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.archiveType === 0 ||
                    _vm.archiveType.includes(6) ||
                    _vm.archiveType.includes(0)
                      ? _c(
                          "yxt-col",
                          {
                            staticClass:
                              "mt12 yxtbiz-ai-robot-search-archive__hauto",
                            attrs: { span: _vm.size === "small" ? 24 : 12 },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "yxtbiz-ai-robot-search-archive__item hand",
                                class:
                                  _vm.size === "small"
                                    ? ""
                                    : "yxtbiz-ai-robot-search-archive__height100",
                                on: {
                                  click: function ($event) {
                                    return _vm.linkJump(6)
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "pl20" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mt16 color-gray-10 yxtbiz-ai-robot-search-archive__weight500",
                                      class:
                                        _vm.size === "small"
                                          ? "standard-size-14"
                                          : "standard-size-16",
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "yxtbiz_ai_lbl_surveyarchive" /* 学员调查 */
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mt4",
                                      class:
                                        _vm.size === "small"
                                          ? "standard-size-12"
                                          : "standard-size-14",
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "pc_biz_ai_lbl_survey" /* 调查 */
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                      _c("span", { staticClass: "ml12" }, [
                                        _vm._v(
                                          _vm._s(_vm.surveyDetail.total || 0)
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mt16 mb16 color-gray-7",
                                      class:
                                        _vm.size === "small"
                                          ? "standard-size-12"
                                          : "standard-size-14",
                                    },
                                    [
                                      _c("div", [
                                        _c("span", {
                                          staticClass:
                                            "yxtbiz-ai-robot-search-archive__point yxtbiz-ai-robot-search-archive__point-corlor43",
                                        }),
                                        _c(
                                          "span",
                                          {
                                            class:
                                              _vm.size === "small"
                                                ? "ml8"
                                                : "ml12",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "pc_biz_study_lbl_complete" /* 已完成 */
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("span", { staticClass: "ml12" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.surveyDetail.finishedNum || 0
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c("div", { staticClass: "mt8" }, [
                                        _c("span", {
                                          staticClass:
                                            "yxtbiz-ai-robot-search-archive__point yxtbiz-ai-robot-search-archive__point-corlorbf",
                                        }),
                                        _c(
                                          "span",
                                          {
                                            class:
                                              _vm.size === "small"
                                                ? "ml8"
                                                : "ml12",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "pc_biz_ai_lbl_uncompleted" /* 未完成 */
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("span", { staticClass: "ml12" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.surveyDetail.unfinishedNum ||
                                                0
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "yxtbiz-ai-robot-search-archive__rate",
                                  },
                                  [
                                    _c(
                                      "yxtf-progress",
                                      {
                                        attrs: {
                                          percentage:
                                            _vm.surveyDetail.rate || 0,
                                          width: 108,
                                          color: "#436bff",
                                          outColor: "#f0f0f0",
                                          "stroke-width": 6,
                                          type: "circle",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "archive-detail-progress",
                                            attrs: { slot: "content" },
                                            slot: "content",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "standard-size-20 color-gray-10",
                                              },
                                              [
                                                _vm.surveyDetail.total
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.surveyDetail
                                                            .rate &&
                                                            _vm.surveyDetail.rate.toFixed(
                                                              0
                                                            )
                                                        ) + "%"
                                                      ),
                                                    ])
                                                  : _c("span", [_vm._v("--")]),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "standard-size-14 color-gray-7 mr2 ml2",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "yxtbiz_ai_lbl_completerate" /* 完成率 */
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }