<!-- 创建时间2024/01/04 14:50:11 -->
<!-- 创建人：Zhao bing bing -->
<!-- 组件描述：空间选择 -->
<template>
  <yxt-form-item :label="config.labelName" :prop="config.name">
    <yxt-select class="width-percent-100" v-model="value_" filterable size="small" :disabled="disabled || loading">
      <yxt-option 
        v-for="item in list" 
        :key="item.id"
        :label="item.label"
        :value="item.id">
      </yxt-option>
  </yxt-select>
  </yxt-form-item>
</template>

<script>
import { knglibApi } from 'yxt-biz-pc/packages/api';
export default {
  name: 'KngLibSelector',
  props: {
    value: {
      type: [Number, String],
      default: ''
    },
    config: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      list: [],
      loading: false
    };
  },
  computed: {
    value_: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
        this.change(val);
      }
    }
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      knglibApi.post('kngLib/optionalLibs', {})
        .then(res => {
          this.list = res && res.kngLibList || [];
          if (this.value_ && this.list.some(item => (item.id === this.value_ || item.label === this.value_))) {
            const obj = this.list.find(item => item.id === this.value_ || item.label === this.value_);
            this.value_ = obj.id;
          } else if (this.list.length > 0) {
            this.value_ = this.list[0].id;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    change(val) {
      const obj = this.list.find(item => item.id === val) || {};
      const {id, label} = obj;
      this.$emit('change', {value: id, labelName: label});
    }
  }
};
</script>