/* Automatically generated by 'build-entry-base.js' */
import AreaCodeSelect from '../../packages/area-code-select';
import AreaSelect from '../../packages/area-select';
import AsyncImportList from '../../packages/async-import-list';
import CheckPersonRange from '../../packages/check-person-range';
import CheckSinglePosition from '../../packages/check-single-position';
import CommonUtil from '../../packages/common-util';
import DeptManagerTree from '../../packages/dept-manager-tree';
import DeptTree from '../../packages/dept-tree';
import DeptTreeV2 from '../../packages/dept-tree-v2';
import FunsTree from '../../packages/funs-tree';
import GroupOrgSelect from '../../packages/group-org-select';
import I18nCustomTemplate from '../../packages/i18n-custom-template';
import I18nInput from '../../packages/i18n-input';
import I18nLang from '../../packages/i18n-lang';
import I18nText from '../../packages/i18n-text';
import ImportProc from '../../packages/import-proc';
import MsgCollector from '../../packages/msg-collector';
import MsgCollectorV2 from '../../packages/msg-collector-v2';
import MsgEditor from '../../packages/msg-editor';
import MsgEditorV2 from '../../packages/msg-editor-v2';
import NavBreadcrumb from '../../packages/nav-breadcrumb';
import NavFooter from '../../packages/nav-footer';
import NavImmersive from '../../packages/nav-immersive';
import NavLang from '../../packages/nav-lang';
import NavLeft from '../../packages/nav-left';
import NavLeftStu from '../../packages/nav-left-stu';
import NavMain from '../../packages/nav-main';
import NavManageStore from '../../packages/nav-manage-store';
import NavTab from '../../packages/nav-tab';
import NavTop from '../../packages/nav-top';
import NavTopStu from '../../packages/nav-top-stu';
import PersonRangeSelector from '../../packages/person-range-selector';
import PersonSelector from '../../packages/person-selector';
import PersonalCenterNav from '../../packages/personal-center-nav';
import PosTree from '../../packages/pos-tree';
import Search from '../../packages/search';
import SingleUser from '../../packages/single-user';
import StudyCenterNav from '../../packages/study-center-nav';
import SupportSidebar from '../../packages/support-sidebar';
import Upload from '../../packages/upload';
import UploadImage from '../../packages/upload-image';
import UserCenterNav from '../../packages/user-center-nav';
import UserGroupTree from '../../packages/user-group-tree';
import UserSelector from '../../packages/user-selector';
import GroupSourceSelector from '../../packages/group-source-selector';
import SponsorChoose from '../../packages/sponsor-choose';
import VirtualList from '../../packages/virtual-list';
import Captcha from '../../packages/captcha';
import Qrcode from '../../packages/qrcode';
import Video from '../../packages/video';
import RangeSelector from '../../packages/range-selector';
import DeptName from '../../packages/dept-name';
import UserName from '../../packages/user-name';
import LanguageSlot from '../../packages/language-slot';
import Personalitytemplate from '../../packages/PersonalityTemplate';
import TypeSearch from '../../packages/type-search';
import AiRobot from '../../packages/ai-robot';
import AiRobotSearch from '../../packages/ai-robot-search';
import AiRobotDemo from '../../packages/ai-robot-demo';
import ExtendFieldSelector from '../../packages/extend-field-selector';
import yxtOpenData from 'yxt-open-data/es';
import yxtI18n from 'yxt-i18n/es';
import yxtFactor from 'yxt-factor/es';
// TODO
import EcoEs from '../../packages/eco';
import navManageStore from '../../packages/nav-manage-store';
import commonUtil from '../../packages/common-util';
import TitleUtil from '../../packages/title-util';

import * as ApiInstanceAll from '../../packages/api';
import { transformApiParams } from 'yxt-biz-pc/packages/api/configDomain';
import { isIE } from 'yxt-biz-pc/packages/common-util/utils.js';

const timezone = yxtI18n.timezone;
const Api = ApiInstanceAll.default;
const otherApi = {};
for (var key in ApiInstanceAll) {
  if (key === "default") continue;
  otherApi[key] = ApiInstanceAll[key];
}

timezone.init();

const components = [
  AreaCodeSelect,
  AreaSelect,
  AsyncImportList,
  CheckPersonRange,
  CheckSinglePosition,
  CommonUtil,
  DeptManagerTree,
  DeptTree,
  DeptTreeV2,
  FunsTree,
  GroupOrgSelect,
  I18nCustomTemplate,
  I18nInput,
  I18nLang,
  I18nText,
  ImportProc,
  MsgCollector,
  MsgCollectorV2,
  MsgEditor,
  MsgEditorV2,
  NavBreadcrumb,
  NavFooter,
  NavImmersive,
  NavLang,
  NavLeft,
  NavLeftStu,
  NavMain,
  NavManageStore,
  NavTab,
  NavTop,
  NavTopStu,
  PersonRangeSelector,
  PersonSelector,
  PersonalCenterNav,
  PosTree,
  Search,
  SingleUser,
  StudyCenterNav,
  SupportSidebar,
  Upload,
  UploadImage,
  UserCenterNav,
  UserGroupTree,
  UserSelector,
  GroupSourceSelector,
  SponsorChoose,
  VirtualList,
  Captcha,
  Qrcode,
  Video,
  RangeSelector,
  DeptName,
  UserName,
  LanguageSlot,
  Personalitytemplate,
  TypeSearch,
  AiRobot,
  AiRobotSearch,
  AiRobotDemo,
  ExtendFieldSelector
];

components.forEach(Component => {
  Component.mixins = Component.mixins || [];
  Component.mixins.push({
    created() {
      // 业务组件埋点统计
      // from：组件名
      // aspect：事件发生描述
      // version：组件库版本
      window.YxtFeLog && window.YxtFeLog.track('e_component', {
        properties: {
          from: Component.name,
          aspect: 'load',
          version: '1.17.14'
        }
      });
    }
  });
});

const setStaticCdnUrl = function(Vue) {
  try {
    if (Vue) {
      Vue.prototype.$imagesBaseUrl = (window.feConfig && window.feConfig.common.imagesBaseUrl) || 'https://images.yxt.com/';
      Vue.prototype.$staticBaseUrl = (window.feConfig && window.feConfig.common.staticBaseUrl) || 'https://stc.yxt.com/';
      Vue.prototype.$isChinese = ['zh', 'ha'].includes(commonUtil.getLanguage());
    }
  } catch (e) {
    console.log(e);
  }
};

const logVersion = function() {
  const { log } = console;
  log('%cYXT-BIZ-PC 1.17.14', 'color: #fff; border-radius: 3px; padding: 3px 7px;background: linear-gradient(315deg, #fc5c7d 0%, #6a82fb 74%)');
};

var installed = false;
const install = function(Vue, config = {}) {
  Api.setConfig(config);
  yxtOpenData.install(Vue, config);

  const options = transformApiParams(config);
  yxtI18n.Api.setConfig(options, options.source, options.domain);
  EcoEs.Api.setConfig({ ...options });
  yxtFactor.install(options);

  if (installed) return;
  installed = true;
  !isIE() && logVersion();
  setStaticCdnUrl(Vue);
  components.forEach(component => {
    component.name && Vue.component(component.name, component);
  });
  window.addEventListener('ready',() => {})

  document.body ? commonUtil.bindImgPreviewer(document.body) : document.addEventListener('DOMContentLoaded', function() {
    commonUtil.bindImgPreviewer(document.body);
  });
};

/* istanbul ignore if */
if (typeof window !== 'undefined' && window.Vue) {
  window.__hack_yxtbiz_OpenData = yxtOpenData
  window.__hack_yxtbiz_I18n = yxtI18n
  window.__hack_yxtbiz_Factor = yxtFactor
  install(window.Vue);
}

// export {
//   timezone
// };
// hack 
// 如果新增字段保持大写开头
export default {
  version: '1.17.14',
  install,
  navManageStore,
  yxtOpenData,
  yxtI18n,
  timezone,
  commonUtil,
  Api: Object.assign({}, Api, otherApi),
  ...otherApi,
  TitleUtil,
  AreaCodeSelect,
  AreaSelect,
  AsyncImportList,
  CheckPersonRange,
  CheckSinglePosition,
  CommonUtil,
  DeptManagerTree,
  DeptTree,
  DeptTreeV2,
  FunsTree,
  GroupOrgSelect,
  I18nCustomTemplate,
  I18nInput,
  I18nLang,
  I18nText,
  ImportProc,
  MsgCollector,
  MsgCollectorV2,
  MsgEditor,
  MsgEditorV2,
  NavBreadcrumb,
  NavFooter,
  NavImmersive,
  NavLang,
  NavLeft,
  NavLeftStu,
  NavMain,
  NavManageStore,
  NavTab,
  NavTop,
  NavTopStu,
  PersonRangeSelector,
  PersonSelector,
  PersonalCenterNav,
  PosTree,
  Search,
  SingleUser,
  StudyCenterNav,
  SupportSidebar,
  Upload,
  UploadImage,
  UserCenterNav,
  UserGroupTree,
  UserSelector,
  GroupSourceSelector,
  SponsorChoose,
  VirtualList,
  Captcha,
  Qrcode,
  Video,
  RangeSelector,
  DeptName,
  UserName,
  LanguageSlot,
  Personalitytemplate,
  TypeSearch,
  AiRobot,
  AiRobotSearch,
  AiRobotDemo,
  ExtendFieldSelector
};
