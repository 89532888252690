<template>
  <base-list
    :dataList="datas"
    :size="size"
    :tagLists="tagLists"
    searchName="pc_biz_o2o_lbl_survey"
    tagType="status"
    listId="projectId"
    :curtTime="curtTime"
    @to-list="toList"
    @to-more="toListMore">
    <!-- slot插槽处理 -->
    <template slot-scope="{ list }">
      <span>{{ $t('pc_biz_survey_lbl_type' /* 类型 */) }}：{{ list.cateName }}</span>
      <yxt-divider direction="vertical"></yxt-divider>
      <span>{{ $t('pc_biz_ote_lbl_minutes' /* 时间 */) }}：{{ getDateTimeFormatted({startTime: list.startTime, endTime: list.endTime}) }}</span>
    </template>
  </base-list>
</template>

<script>
import CommonMixin from '../common/props-mixin';
import utils from 'yxt-biz-pc/packages/common-util/utils.js';
import BaseList from '../search-exam/base-list.vue';
import { getSystemTime } from './service';

export default {
  name: 'SearchSurvey',
  mixins: [CommonMixin],
  components: {
    BaseList
  },
  data() {
    const tagLists = {
      0: {
        label: 'pc_biz_ls_notStart', // 未开始
        type: 'info'
      },
      1: {
        label: 'pc_biz_ote_lbl_status2', // 进行中
        type: ''
      },
      2: {
        label: 'pc_biz_shoptour_lbl_finished', // 已完成
        type: 'info'
      },
      3: {
        label: 'pc_biz_ote_lbl_status3', // 已结束
        type: 'info'
      }
    };

    return {
      tagLists,
      curtTime: new Date().getTime()
    };
  },

  created() {
    getSystemTime().then(res => {
      this.curtTime = res.data;
    });
  },

  methods: {
    getDateTimeFormatted: utils.getDateTimeFormatted,
    toList(list) {
      window.open(`${window.location.origin}/survey/#/preview?id=${list.projectId}&uamId=${list.uamId}`, '_blank');
    },
    toListMore() {
      localStorage['ai-search-survey'] = JSON.stringify(this.params);
      console.log(this.params);
      window.open(`${window.location.origin}/survey/#/`, '_blank');
    }
  }
};
</script>
