var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "yxtbiz-ai-robot-demo",
      class: { "is-fullscreen": _vm.bFullscreen, "is-max": _vm.max },
    },
    [
      _c(
        "draggable-container",
        {
          attrs: { bFullscreen: _vm.bFullscreen },
          on: { resize: () => _vm.$refs.msgDialog.scrollToEnd() },
        },
        [
          _vm.bFullscreen
            ? _c("broadside", {
                attrs: { bFullscreen: _vm.bFullscreen, title: _vm.title },
                on: { clearHistory: _vm.clearHistory },
              })
            : _vm._e(),
          _c("msg-dialog", {
            ref: "msgDialog",
            attrs: {
              title: _vm.title,
              sid: _vm.sessionId,
              max: _vm.max,
              bFullscreen: _vm.bFullscreen,
            },
            on: {
              fullscreen: (v) => (_vm.bFullscreen = v),
              feedback: function ($event) {
                _vm.feedbackDialogVisible = true
              },
              close: _vm.closeAI,
            },
          }),
        ],
        1
      ),
      _c("feedback-dialog", {
        attrs: { visible: _vm.feedbackDialogVisible },
        on: {
          "update:visible": function ($event) {
            _vm.feedbackDialogVisible = $event
          },
          success: _vm.feedbackSuccess,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }