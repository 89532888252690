var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "yxtbiz-nav-global-notice-opts yxtbiz-nav-global-notice-opts-small",
    },
    [
      _c("div", { staticClass: "yxtbiz-nav-global-notice-opts__title" }, [
        _c("div", { staticClass: "yxtbiz-nav-global-notice-opts__title-ctx" }, [
          _vm._v(_vm._s(_vm.content.title)),
        ]),
        _c(
          "div",
          { staticClass: "yxtbiz-nav-global-notice-opts__title-btn" },
          [
            _c(
              "yxt-button",
              { attrs: { type: "text" }, on: { click: _vm.closeNotice } },
              [
                _c("i", {
                  staticClass: "yxt-icon-close",
                  attrs: { width: "16px", height: "16px" },
                }),
              ]
            ),
          ],
          1
        ),
      ]),
      _c("div", {
        staticClass: "yxtbiz-nav-global-notice-opts-small__wrapper",
        domProps: { innerHTML: _vm._s(_vm.filterImg(_vm.content.content)) },
      }),
      _c(
        "div",
        {
          staticClass:
            "yxtbiz-nav-global-notice-opts__footer yxtbiz-nav-global-notice-opts-small__footer",
        },
        [
          _c(
            "yxt-button",
            { attrs: { type: "text" }, on: { click: _vm.checkNotice } },
            [
              _vm._v(
                _vm._s(
                  _vm.content.buttonDesc
                    ? _vm.content.buttonDesc
                    : _vm.$t("pc_biz_notice_btn_check" /* 查看 */)
                )
              ),
              _c("i", { staticClass: "yxt-icon-arrow-right" }),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }