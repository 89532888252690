<template>
  <!-- 回答 -->
  <answer v-if="msg.chatType === 1"
   :lastMsg="lastMsg" 
   :isWaitAnswer="isWaitAnswer" 
   :show-portrait="fullscreen" 
   @promot="(...info) => $emit('promot', ...info)" 
   @resend="val => $emit('resend', val)" 
   :fullscreen="fullscreen" 
   :msg="msg"  
   @feedback="$emit('feedback')"  
   @promotEmpty="$emit('promotEmpty')"
   @submit="(data)=>$emit('submit', data)"
   @scrollToEnd="$emit('scrollToEnd')"></answer>
  <!-- 提问 -->
  <question v-else-if="msg.chatType === 0" :show-portrait="fullscreen" :fullscreen="fullscreen" :msg="msg"></question>
  <div v-else class="system-msg">{{ msg.content }}</div>
</template>

<script>
import Answer from './answerContent.vue';
import Question from './question.vue';
export default {
  name: 'AiRobotMessage',
  components: {
    Answer,
    Question
  },
  props: {
    isWaitAnswer: {
      type: Boolean,
      default: false
    },
    msg: {
      type: Object,
      default: () => {}
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    lastMsg: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  }
};
</script>
