export const components = [
  {
    compName: 'select-ques-lib',
    sourceType: '0',
    name: '题库',
    nameKey: 'pc_biz_share_lbl_tk'
  },
  {
    compName: 'select-exams',
    sourceType: '1',
    nameKey: 'pc_biz_share_lbl_sj',
    name: '试卷'

  },
  {
    compName: 'yxtbiz-select-kng-source',
    sourceType: '2',
    name: '课件',
    nameKey: 'pc_biz_share_lbl_kj',
    params: {
      kngSelectType: 999
    }
  },
  {
    name: '课程',
    compName: 'yxtbiz-select-kng-source',
    sourceType: '3',
    nameKey: 'pc_biz_share_lbl_kc',
    params: {
      kngSelectType: 0
    }
  },
  {
    name: '课程分类',
    compName: 'yxtbiz-select-kng-catelog-source',
    nameKey: 'pc_biz_share_lbl_kcfl',
    sourceType: '4'
  },
  {
    name: '讲师',
    compName: 'yxtbiz-teacher-selector',
    sourceType: '5',
    nameKey: 'pc_biz_share_lbl_js',
    params: {
      shareSource: true,
      prepared: true // 集团版讲师添加预备讲师
    }
  },
  {
    name: '证书',
    nameKey: 'pc_biz_share_lbl_zs',
    compName: 'select-cers',
    sourceType: '6'
  }
];
