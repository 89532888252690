<template>
  <div class="yxtbiz-nav-tab" v-if="navs && navs.length > 0" :class="{'yxtbiz-nav-tab--app': type === 2}">
    <yxt-tabs v-model="activeName" @tab-click="handleClick" v-if="type === 1">
      <yxt-tab-pane show-tooltip :data="menu" :name="menu.code" v-for="menu in navs" :key="menu.id" :label="menu|Globalize('name')">
      </yxt-tab-pane>
    </yxt-tabs>
    <yxt-tabs v-model="activeName" @tab-click="handleClick" v-else-if="type === 2">
      <yxt-tab-pane show-tooltip :data="menu" :name="menu.code" v-for="menu in navs" :key="menu.id" :label="menu|Globalize('name')">
      </yxt-tab-pane>
    </yxt-tabs>
    <yxtbiz-group-org-select  class='mr24 yxtbiz-nav-tab--org_select'
                             :selectFirst='selectFirst'
                             :clearable='clearable'
                             :placeholder='placeholder'
                             @change='change'
                             :style="{width: selectorWidth}"
                             :setCurrentOrg='setCurrentOrg'
                             v-if='_visibleOrgSelector'
                            :functionCode="functionCode"
                            :dataPermissionCode="dataPermissionCode"
    />
  </div>
</template>

<script>
import { Globalize, goNavPage } from './common';
import {
  Tabs,
  TabPane
} from 'yxt-pc';
export default {
  name: 'YxtbizNavTab',
  components: {
    YxtTabs: Tabs,
    YxtTabPane: TabPane
  },
  props: {
    /*
    类型
     1 普通管理端的三级导航tab
     2 应用的二级导航tab
    */
    type: {
      type: Number,
      default: 1
    },
    level: {
      type: Number,
      default: -1
    },
    mainPathname: {
      type: String,
      default: ''
    },
    visibleOrgSelector: { // 是否在tab 导航上展示机构选择组件
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    clearable: {
      type: Boolean,
      default: false
    },
    selectFirst: {
      type: Boolean,
      default: true // 是否默认选中第一个
    },
    selectorWidth: {
      default: '290px'
    },
    setCurrentOrg: { // 是否默认选中当前登录机构,优先级高于defaultId
      type: Boolean,
      default: false
    },
    functionCode: {
      type: String,
      default: ''
    },
    dataPermissionCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      activeName: ''
    };
  },
  computed: {
    _visibleOrgSelector() {
      const { enableGroupCorp, manageMode } = this.$store.state.navManageStore.groupCorpInfo;
      return this.visibleOrgSelector && enableGroupCorp && manageMode === 1; // 弱管理
    },
    navs() {
      let navsAll = this.$store.state.navManageStore.cnavs[this.cnavLevel - 2];
      if (navsAll) {
        return navsAll.subMenuInfoList;
      }
      return [];
    },
    cnav() {
      return this.$store.state.navManageStore.cnavs[this.cnavLevel - 1];
    },
    // 当前导航数组
    cnavLevel() {
      let navLevel = 3;
      switch (this.type) {
        case 1:
          navLevel = 3;
          break;
        case 2:
          navLevel = 4;
          break;
        default:
          break;
      }
      if (this.level !== -1) {
        navLevel = this.level;
      }
      return navLevel;
    }
  },
  watch: {
    cnav(val, valold) {
      if (val !== valold && val) {
        this.activeName = val.code;
      }
    }
  },
  methods: {
    change(orgInfo) {
      this.$store.commit('navManageStore/setTabOrgInfoByGroup', orgInfo);
      this.$emit('orgInfoChange', orgInfo);
    },
    handleClick(e) {
      this.$emit('change', e.$attrs.data);
      this.toPage(e.$attrs.data);
    },
    toPage(page) {
      goNavPage(page, this.mainPathname, this);
    }
  },
  created() {
    this.activeName = this.cnav ? this.cnav.code : '';
    if (this.visibleOrgSelector) {
      this.$store.dispatch('navManageStore/setGroupCorpInfo');
    }
  },
  filters: {
    Globalize(data, key) {
      return Globalize(data, key);
    }
  }
};
</script>
