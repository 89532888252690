var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "pr yxt-biz-video-container",
      class: [_vm.isPaused ? "audio--paused" : ""],
      style: "width:" + _vm.comWidth + ";height:" + _vm.comHeight,
    },
    [
      _c("div", { style: _vm.playerStyle, attrs: { id: _vm.playerType } }),
      _c(
        "yxt-popover",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showCuesGuide && _vm.$slots.mark,
              expression: "showCuesGuide && $slots.mark",
            },
          ],
          ref: "markGuidePopover",
          attrs: {
            "append-to-body": false,
            popperOptions: {
              modifiers: [
                "shift",
                "offset",
                "keepTogether",
                "arrow",
                "flip",
                "applyStyle",
              ],
              gpuAcceleration: false,
            },
            value: !!_vm.$slots.mark,
            "max-width": 300,
            "max-height": 200,
            placement: _vm.markGuideDirection ? "top-end" : "top-start",
            "popper-class": "yxt-biz-video-markguide__popper",
          },
          on: {
            show: function ($event) {
              return _vm.setCuesGuide(true)
            },
            "after-leave": function ($event) {
              return _vm.setCuesGuide(false)
            },
            hide: function ($event) {
              return _vm.setCuesGuide(false)
            },
          },
        },
        [_vm._t("mark")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }