<template>
  <div>
    <div
      class='clearfix yxt-user-advance-wrap pr20'
    >
      <yxt-row onsubmit='return false' class="mb16" :type="enableGroupCorp ? '': 'flex'">
        <yxt-form
          style='flex:1'
          :inline='true'
        >
          <div class='d-in-flex'>
            <yxt-popover
              :append-to-body='false'
              popper-class='yxtbiz-user-selector-popover'
              trigger='manual'
              placement='bottom-start'
              :visible-arrow='false'
              v-model='showMoreFilter'
            >
              <yxt-button
                slot='reference'
                plain
                size="small"
                @click.stop='showFilterBox'
                style="height: 32px;"
                class='yxtbiz-filter-btn mr12 pull-left'
              >
                <yxt-svg
                  width='20px'
                  height='20px'
                  icon-class='filter'
                  class='v-top'
                />
                <span class='ml8 v-top'>{{$t('pc_biz_udp_lbl_moreFilter')}}</span>
                <i
                  class='ml8'
                  :class='showMoreFilter ? "yxt-icon-arrow-up": "yxt-icon-arrow-down"'
                ></i>
              </yxt-button>
              <yxt-form
                :inline='true'
                class='yxtbiz-user-selector-filter'
                label-position="top"
              >
                <div style="width:950px;max-height: 600px;overflow: auto;">
                  <div
                    class='yxtbiz-user-selector-filter-box clearfix'
                    style='width:950px;'
                  >
                    <div class='pl20 yxtbiz-user-selector-filter-right yxtbiz-user-selector-filter-block'>
                      <yxt-row :gutter='20'>
                        <yxt-col v-if="isShowDeptment" :span='12'>
                          <yxt-form-item
                            style='width: 100%'
                            label-width='100px'
                            :label="$t('biz_udp_deptment')"
                          >
                          <tree-select
                            :placeholder='$t("pc_biz_udp_lbl_unlimitedPosition")'
                            style='width: 220px;'
                            @click.native='showTree("dept-tree")'
                            v-model='depts'
                            @remove-tag='removeDept'
                          ></tree-select>
                          </yxt-form-item>
                        </yxt-col>
                        <yxt-col :span='12'>
                          <yxt-form-item
                            style='width: 100%'
                            label-width='100px'
                            :label="$t('biz_udp_position')"
                          >
                            <tree-select
                              :placeholder="$t('biz_udp_no_limit_pos')"
                              @click.native='showPosition'
                              v-model='positions'
                              @remove-tag='removePostion'
                            ></tree-select>
                          </yxt-form-item>
                        </yxt-col>
                        <yxt-col
                          :span='12'
                          v-for='(item, index) in selectedParams'
                          :key='index'
                        >
                          <yxt-form-item
                            style='width: 100%'
                            :label='item.label'
                            label-width='100px'
                            class='d-in-block'
                          >
                            <div>
                              <component
                                :enableGroupCorp='enableGroupCorp'
                                v-bind='item.props'
                                :is='item.component'
                                v-model='item.data'
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                                @input='itemChange($event)'
                              />
                            </div>
                          </yxt-form-item>
                        </yxt-col>
                        <!-- 高级选人筛选字段支持扩展字段 -->
                        <spare-item
                          v-for="(item,key) in spares"
                          :key="'spareitem_'+key"
                          :name="item.name"
                          :type="item.patternType"
                          :list="item.list"
                          :value.sync="item.patternValues"
                        />
                        <yxt-col :span='24'>
                          <yxt-form-item label=' ' label-width='100px' class='d-in-block'>
                            <yxt-button
                                @click='filterSearch'
                                type='primary'
                              >{{$t('pc_biz_udp_btn_determine')}}</yxt-button>
                              <yxt-button
                                @click='closeFilterBox'
                                plain
                                class="mr8"
                                type='default'
                              >{{$t('pc_biz_udp_btn_cancel')}}</yxt-button>
                              <yxt-button
                                :disabled='saveDisabled'
                                type='text'
                                @click='showFilter'
                              >{{$t('pc_biz_udp_btn_saveToCommon')}}</yxt-button>
                          </yxt-form-item>
                        </yxt-col>
                      </yxt-row>
                    </div>
                  </div>
                </div>
              </yxt-form>
            </yxt-popover>
            <yxtbiz-group-org-select 
              @change='handleOrgChange'
              :functionCode="functionCode"
              :dataPermissionCode="dataPermissionCode"
              @orgInvalid="orgInvalidHandler"
              v-if='enableGroupCorp'  
              :selectFirst="true"
              :clearable="false"
              size="small"
              :disabled="disabled"
              :setCurrentOrg="true"
              :targetOrgId="targetOrgId"
              :visibleOrgIds="visibleOrgIds"
              :disabledOrgIds="disabledOrgIds">
            </yxtbiz-group-org-select>
            <yxtbiz-type-search
              class="ml12"
              size="small"
              maxlength="20"
              :kwType.sync="userData.kwType"
              :keywords.sync="searchValue"
              :options="[1,2]"
              :selectCache="true"
              searchable
              @search="search"
            ></yxtbiz-type-search>
            <!-- <yxt-input
              size="small"
              :placeholder="$t('biz_udp_enter_account')"
              v-model='searchValue'
              class='ml12'
              style='width: 180px;'
              searchable
              @search='search'
            ></yxt-input> -->
          </div>
        </yxt-form>
        <div class='lh32'>
          <yxt-dropdown
            :modal-append-to-body="false"
            trigger='click'
            @command='selectFilter'
          >
            <span
              @click='closeFilterBox'
              class='yxt-dropdown-link yxt-link yxt-link--primary'
            >{{$t('pc_biz_udp_lbl_commonFilters')}}</span>
            <yxt-dropdown-menu
              slot='dropdown'
              style='overflow:hidden'
            >
              <yxt-dropdown-item
                disabled
                v-if='filterList.length === 0'
              >{{$t('pc_biz_udp_tip_noFilters')}}</yxt-dropdown-item>
              <yxt-dropdown-item
                :key='item.id'
                :command='item'
                v-for='item in filterList'
              >
                <yxt-row
                  type='flex'
                  align='middle'
                  class='yxt-selector-filter-row'
                  style='width: 100%'
                >
                  <div class='yxt-selector-filter-name'>
                    <span>{{ item.name }}</span>
                  </div>
                  <span
                    class='yxt-icon-close'
                    @click.stop='deleteFilter(item)'
                  ></span>
                </yxt-row>
              </yxt-dropdown-item>
            </yxt-dropdown-menu>
          </yxt-dropdown>
        </div>
      </yxt-row>
      <yxt-table
        :ref="refTable"
        @select-all='selectAllUser'
        @select='selectUser'
        :height='tableHeight'
        v-loading='loading'
        class='yxt-user-table'
        row-key="id"
        :data='persons'
      >
        <template slot='empty'>
          <div style='line-height:normal'>
            <yxt-empty />
          </div>
        </template>
        <!-- 表格单多选 -->
        <yxt-table-column
          v-if="customMultiple"
          :show-overflow-tooltip="false"
          class-name="yxtbiz-table-selection"
          type="selection"
          width="42"
          reserve-selection
          clear-padding="right"
        ></yxt-table-column>
        <yxt-table-column
          v-else
          :show-overflow-tooltip="false"
          width="40"
        >
          <template slot-scope="scope">
            <yxt-radio v-model="singleSelect" @change="selectUser(scope.row.id, scope.row)" :label="scope.row.id"></yxt-radio>
          </template>
        </yxt-table-column>
        <yxt-table-column
          width="18"
          align="right"
          clear-padding="left-right"
          v-if="multiple"
          >
          <template slot="header">
            <yxt-dropdown placement="bottom-start" @command="tableHeaderCommand">
                <span> </span>
                <yxt-dropdown-menu slot="dropdown">
                  <yxt-dropdown-item command="selectCurrent">{{$t('pc_biz_enroll_select_current_page').d('选择本页')}}</yxt-dropdown-item>
                  <yxt-dropdown-item command="selectAll" >{{$t('pc_biz_enroll_select_all').d('选择全部')}}</yxt-dropdown-item>
                  <yxt-dropdown-item command="clearSelect" >{{$t('pc_biz_enroll_clear_all').d('清空所选')}}</yxt-dropdown-item>
                </yxt-dropdown-menu>
            </yxt-dropdown>
          </template>
        </yxt-table-column>
        <yxt-table-column :label="$t('pc_biz_core_lbl_name')" :show-overflow-tooltip="true" popover-html>
          <template slot-scope='scope'>
            <yxtbiz-user-name v-if='isOpenData' :name='scope.row.fullname' />
            <span v-else>{{ scope.row.fullname }}</span>
          </template>
        </yxt-table-column>
        <yxt-table-column :label="$t('pc_biz_core_lbl_accnum')" prop="username" :show-overflow-tooltip="true" :popover-html="true">
          </yxt-table-column>
        <yxt-table-column
          :show-overflow-tooltip="true"
          :formatter='formatUserNo'
          :label='$t("pc_biz_core_lbl_userNo")'
          prop='userNo'
        ></yxt-table-column>
        <yxt-table-column :label="$t('pc_biz_core_lbl_department')" :show-overflow-tooltip="true" :popover-html="true">
          <template slot-scope="scope">
            <span v-if="!scope.row.deptName">--</span>
            <yxtbiz-dept-name v-else-if='isOpenData' :name='scope.row.deptName' />
            <span v-else>{{scope.row.deptName}}</span>
          </template>
        </yxt-table-column>
        <yxt-table-column
          :label='$t("biz_udp_position")'
          :show-overflow-tooltip="true"
          :formatter='formatPostion'
        ></yxt-table-column>
      </yxt-table>
      <yxt-row type='flex' align='middle' class='yxtbiz-user-selector-mask mt16'>
        <yxt-row class="col-flex-1" type='flex' align='middle'></yxt-row>
        <yxt-pagination
          class='pull-right'
          @size-change='sizeChange'
          @current-change='pageChange'
          :current-page.sync='page'
          :page-size='userData.limit'
          simple-total
          layout='total, prev, pager, next'
          :total='count'
        ></yxt-pagination>
      </yxt-row>
    </div>
    <yxt-drawer
      :title='$t("pc_biz_udp_tit_saveFilter")'
      :modal='false'
      :visible.sync='dialogVisible'
      size='480px'
    >
      <yxt-form>
        <yxt-form-item
          label-width='80px'
          :label='$t("pc_biz_udp_lbl_filterName")'
        >
          <yxt-input
            maxlength='100'
            v-model='filerName'
          ></yxt-input>
        </yxt-form-item>
      </yxt-form>
      <div slot='footer'>
        <yxt-button
          plain
          @click='dialogVisible = false'
        >{{$t('pc_biz_udp_btn_cancel')}}</yxt-button>
        <yxt-button
          type='primary'
          @click='saveFilter'
        >{{$t('pc_biz_udp_btn_confirm')}}</yxt-button>
      </div>
    </yxt-drawer>
    <yxt-drawer
      :title='$t("biz_udp_select_dept")'
      :append-to-body="true"
      :modal-append-to-body="true"
      :destroy-on-close='true'
      size='480px'
      :visible.sync='drawerVisible'
    >
      <div class="yxtbiz-dept-tree-full-height">
        <component
          :enableGroupCorp='true'
          :targetOrgId="userData.targetOrgId"
          :visibleOrgSelector='false'
          ref='tree'
          v-bind='treeProps'
          :is='component'
        ></component>
      </div>
      <div slot='footer'>
        <yxt-button
          plain
          @click='cancelTree'
        >{{$t('pc_biz_udp_btn_cancel')}}</yxt-button>
        <yxt-button
          type='primary'
          @click='confirmTree'
        >{{$t('pc_biz_udp_btn_confirm')}}</yxt-button>
      </div>
    </yxt-drawer>
    <yxt-drawer
      :title='$t("pc_biz_udp_tit_selectPotision")'
      :visible.sync='positionVisible'
      :destroy-on-close='true'
      :append-to-body="true"
      :modal-append-to-body="true"
      size='960px'
    >
      <yxtbiz-range-selector
        v-if="enableGroupCorp && userData.targetOrgId"
        :targetOrgId="userData.targetOrgId"
        :enableGroupCorp='true'
        :visibleOrgSelector='false'
        model="array"
        v-model='selectedPositions'
        :functionCode='functionCode'
        :dataPermissionCode='dataPermissionCode'
        :tabs="['position']"
      ></yxtbiz-range-selector>
       <yxtbiz-range-selector
        v-if="!enableGroupCorp"
        model="array"
        v-model='selectedPositions'
        :functionCode='functionCode'
        :dataPermissionCode='dataPermissionCode'
        :tabs="['position']"
      ></yxtbiz-range-selector>
      <div
        slot='footer'
        class='dialog-footer'
      >
        <yxt-button
          plain
          @click='positionVisible = false'
        >{{$t('pc_biz_udp_btn_cancel')}}</yxt-button>
        <yxt-button
          type='primary'
          @click='closePosition'
        >{{$t('pc_biz_udp_btn_confirm')}}</yxt-button>
      </div>
    </yxt-drawer>
  </div>
</template>

<script>
import {
  createFilter,
  getFilterList,
  deleteFilter,
  editFilter,
  getUserList,
  getFilterDetail,
  getOrgSparsByUser
} from '../service';
import deptTree from '../../../dept-tree';
import posTree from '../../../pos-tree';
import treeSelect from '../components/tree-select';
import DateRange from '../components/dateRange';
import Sex from '../components/sex';
import Grade from '../components/grade';
import resizeTable from '../../mixins/resizeTable';
import { Form, FormItem, Drawer, DatePicker, Table, TableColumn, Empty, Pagination, Popover } from 'yxt-pc';
import openDataMixin from '../../mixins/openData';
import { i18n } from 'yxt-biz-pc/packages/common-util/changeLangs';
import commonMixin from '../../mixins/common';
import SpareItem from '../components/spareItem';

export default {
  components: {
    deptTree,
    treeSelect,
    posTree,
    Sex,
    DateRange,
    Grade,
    YxtForm: Form,
    YxtFormItem: FormItem,
    YxtDrawer: Drawer,
    YxtDatePicker: DatePicker,
    YxtTable: Table,
    YxtTableColumn: TableColumn,
    YxtEmpty: Empty,
    YxtPagination: Pagination,
    YxtPopover: Popover,
    SpareItem
  },
  mixins: [resizeTable, openDataMixin, commonMixin],
  props: {
    type: {
      type: String,
      default: 'persons'
    },
    scope: {
      type: Number
    },
    functionCode: {
      type: String,
      default: ''
    },
    showAll: {
      type: Boolean,
      default: false
    },
    dataPermissionCode: {
      type: String,
      default: ''
    },
    isShowDeptment: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      customMultiple: true, // 开启多选，高级选人暂无单选，先直接写死
      selectedParams: [],
      selectedPositions: [],
      isSearchAll: false,
      orgInvalid: false, // 是否全部禁用，如果机构全部禁用了，则不能搜索
      refTable: 'userTable',
      showMoreFilter: false,
      loading: false,
      component: '',
      treeProps: {},
      drawerVisible: false,
      persons: [],
      count: 0,
      searchValue: '',
      filterId: '',
      dialogVisible: false,
      depts: [],
      filterList: [],
      positions: [],
      deptObj: {},
      positionObj: [],
      filerName: '', // 筛选器名称
      positionVisible: false,
      isCancelSelect: false,
      showFilterAll: false, // 是否显示筛选所有
      params: [
        {
          label: i18n.t('biz_udp_occupation_level'),
          type: 'treeSelect',
          db: 'gradeIds',
          data: [],
          component: 'Grade'
        },
        // {
        //   label: '工号',
        //   type: 'text',
        //   db: 'userNo',
        //   data: '',
        //   component: 'yxtInput'
        // },
        // {
        //   label: '手机号',
        //   type: 'text',
        //   component: 'yxtInput',
        //   db: 'mobile',
        //   data: ''
        // },
        // {
        //   label: '邮箱',
        //   type: 'text',
        //   component: 'yxtInput',
        //   db: 'email',
        //   data: ''
        // },
        {
          label: i18n.t('pc_biz_enroll_lbl_entrydate'),
          db: 'hireDate',
          data: {
            start: '',
            end: ''
          },
          component: 'YxtDatePicker',
          props: { type: 'daterange' },
          type: 'dateRange'
        },
        {
          label: i18n.t('biz_udp_lbl_dateofbirth'),
          type: 'dateRange',
          data: {
            start: '',
            end: ''
          },
          component: 'YxtDatePicker',
          props: { type: 'daterange' },
          db: 'birthday'
        },
        {
          label: i18n.t('pc_biz_core_lbl_gender'),
          db: 'sex',
          type: 'sex',
          data: 0,
          component: 'Sex'
        }
      ],
      totalPage: 0,
      tableHeight: 0,
      page: 1,
      userData: {
        kwType: 1,
        keywords: '',
        scope: this.scope,
        showAll: this.showAll ? 1 : 0,
        navCode: this.functionCode,
        dataPermissionCode: this.dataPermissionCode,
        searchKey: '',
        type: 3,
        limit: 20,
        offset: 0
      },
      selectedPersons: {},
      formatPostion: row => {
        if (row.positionName === undefined) {
          return (
            row.positionSet
              .map(item => {
                return item.name;
              })
              .join(';') || '--'
          );
        } else {
          const parttimePosition = row.parttimePositionSet ? Array.from(row.parttimePositionSet) : [];
          const posList = parttimePosition.map(item => item.name);
          posList.push(row.positionName || '');
          return posList.filter(item => item !== '').join(';') || '--';
        }
      },
      formatUserNo: row => {
        return row.userNo || '--';
      },
      spares: [],
      spareForm: {
      }
    };
  },
  created() {
    this.selectedParams = this.formatSelectedFromData(this.params);
    this.getOrgSpares();
    this.getFilterList();
    if (!this.enableGroupCorp || this.targetOrgId) {
      if (this.targetOrgId) this.userData.targetOrgId = this.targetOrgId;
      this.getUserData();
    }
  },
  computed: {
    postData() {
      let result = {};
      this.selectedParams.forEach(item => {
        if (item.type === 'sex') {
          result.sex = item.data;
        } else if (item.type === 'dateRange') {
          if (item.data) {
            result[item.db + 'Start'] = item.data[0];
            result[item.db + 'End'] = item.data[1];
          } else {
            result[item.db + 'Start'] = '';
            result[item.db + 'End'] = '';
          }

        } else if (item.db === 'gradeIds') {
          result.gradeIds = item.data;
        } else {
          result[item.db] = item.data;
        }
      });
      /* eslint-disable no-undef */
      result.deptIds = Array.from(new Set(this.depts.map(dept => dept.id).filter(id => id !== '')));
      // result.deptSearchList = result.deptIds.map(id => {
      //   return {
      //     deptId: id,
      //     includeSubDept: 0 // 高级选人不包含下级部门
      //   };
      // });
      result.includeSubDept = 0; // 不包含子部门
      result.positionIds = this.positions
        .map(position => position.id)
        .filter(id => id !== '');
      result.name = this.filerName;
      return result;
    },
    saveDisabled() {
      const spareHasValue = this.spares.some(i=>!!i.patternValues.length);
      const keys = ['birthdayEnd', 'birthdayStart', 'deptIds', 'gradeIds', 'hireDateEnd', 'hireDateStart', 'positionIds', 'sex'];
      return !spareHasValue && !keys.some(key => {
        return (this.postData[key] && !(this.postData[key] instanceof Array)) || (this.postData[key] instanceof Array && this.postData[key].length > 0) || (key === 'sex' && this.postData[key] !== 0);
      });
    }
  },
  methods: {
    tableHeaderCommand(row) {
      this[row]();
    },
    selectCurrent() {
      // 选择本页
      this.selectCurrData('persons');
    },
    selectAll() {
      // 选择所有
      this.loadAll();
    },
    clearSelect() {
      // 清空所选
      this.$refs[this.refTable].clearSelection();
      this.$emit('change', [], 'persons');
    },
    // 无可用机构-数据置空
    orgInvalidHandler() {
      this.orgInvalid = true;
      this.persons = [];
      this.totalPage = 0;
      this.count = 0;
    },
    handleOrgChange(data) {
      this.orgInvalid = false;
      this.userData.targetOrgId = data.orgId;
      this.userData.targetOrgName = data.orgName;
      this.selectedParams[0].data = [];
      this.depts = [];
      this.positions = [];
      this.search();
    },
    showPosition() {
      this.selectedPositions = this.positions.map(i => {
        i._type = 1;
        return i;
      });
      this.positionVisible = true;
    },
    closePosition() {
      this.positions = this.selectedPositions.slice();
      this.positionVisible = false;
    },
    showTree(component) {
      this.treeProps = {
        filterable: true,
        'check-strictly': true,
        'show-checkbox': true,
        'functions': true,
        'wrap-width': '100%',
        checkedLinkage: 1,
        'functionCode': this.functionCode,
        'dataPermissionCode': this.dataPermissionCode,
        'childrenIncluded': 2
      };

      if (component === 'pos-tree') {
        this.treeProps['catalog-only'] = false;
        this.treeProps['default-checked-keys'] = this.postData.positionIds;
      } else {
        this.treeProps['default-checked-keys'] = this.postData.deptIds;
      }
      this.component = component;
      this.drawerVisible = true;
    },
    confirmTree() {
      switch (this.component) {
        case 'dept-tree':
          this.depts = this.$refs.tree.getCheckedNodes();
          break;
        case 'pos-tree':
          this.positions = this.$refs.tree.getCheckedNodes();
          break;
        case 'Grade':
          break;
        default:
          break;
      }
      this.drawerVisible = false;
    },
    cancelTree() {
      this.drawerVisible = false;
      this.component = '';
      this.treeProps = {};
      this.drawerVisible = false;
    },
    showFilterBox() {
      this.showMoreFilter = !this.showMoreFilter;
    },
    closeFilterBox() {
      this.showMoreFilter = false;
    },
    filterSearch() {
      this.showMoreFilter = false;
      this.search();
    },
    setSelectedDepts(depts) {
      const ids = depts.forEach(dept => dept.id);
      this.$refs.tree.setCheckedKeys(ids);
    },
    setSelectedPositions(positions) {
      const ids = positions.map(pos => pos.id);
      this.$refs.tree.setCheckedKeys(ids);
      this.positions = this.$refs.tree.getCheckedNodes();
    },
    getFilterList() {
      getFilterList(this.enableGroupCorp).then(result => {
        this.filterList = result.datas;
      });
    },
    removeDept(dept) {
      this.depts = this.depts.filter(item => item.id !== dept.id);
    },
    removePostion(position) {
      this.positions = this.positions.filter(item => item.id !== position.id);
    },
    isParamsEmpty(userData) {
      const arrarykeys = ['positionIds', 'gradeIds', 'deptIds'];
      const strKeys = ['birthdayEnd', 'birthdayStart', 'hireDateEnd', 'hireDateStart', 'searchKey', 'sex'];
      let isEmpty = true;
      for (let i = 0; i < arrarykeys.length; i++) {
        if (userData[arrarykeys[i]].length !== 0) {
          isEmpty = false;
          break;
        }
      }

      if (!isEmpty) {
        for (let i = 0; i < strKeys.length; i++) {
          if (userData[strKeys[i]]) {
            isEmpty = false;
            break;
          }
        }
      }
      return isEmpty;
    },
    getUserData() {
      if (this.orgInvalid) return;
      const userData = Object.assign({}, this.userData, this.postData, {dynamicSearch: JSON.parse(JSON.stringify(this.spares))});
      // 把 dynamicSearch 数组中的list字段去除掉
      userData.dynamicSearch.forEach(item => delete item.list);
      delete userData.name; // 查找人不需要筛选器名字
      this.userData = userData;
      this.loading = true;
      this.isCancelSelect = false;
      getUserList(userData, this.enableGroupCorp).then(result => {
        if (this.orgInvalid) return;
        if (result.datas.length !== 0) {
          // 搜索条件不为空，展示筛选全部按钮
          this.showFilterAll = true;
        } else {
          this.showFilterAll = false;
        }
        this.loading = false;
        this.persons = result.datas;
        this.persons.forEach(user => {
          user.nodeType = this.type;
          if (this.enableGroupCorp) {
            user.orgId = this.userData.targetOrgId;
            user.orgName = this.userData.targetOrgName;
            user.combinationName = `${this.userData.targetOrgName}-${user.fullname}`;
          }
          if (this.enableGroupCorp && this.isOrgSelectAlone) {
            // 集团版环境下，选人组件可设置成独立选人，及各个orgId下的相同一个人可以独立选择，并重置每一项的数据的org为当前选择的org
            user.oldId = user.id;
            user.id = `${user.id}&&${this.userData.targetOrgId}`;
          }
        });
        this.totalPage = result.paging.pages;
        this.count = result.paging.count;
        this.loading = false;
        this.performanceUpateSelected('persons');
      }).catch(() => {
        this.persons = [];
        this.loading = false;
      });
    },
    // cancelSelectAll() {
    //   getUserList(Object.assign({}, this.userData, {offset: 0, limit: this.count}))
    //     .then(res => {
    //       this.isCancelSelect = false;
    //       const list = res.datas;
    //       list.forEach(user => {
    //         if (this.enableGroupCorp) {
    //           user.orgId = this.userData.targetOrgId;
    //           user.orgName = this.userData.targetOrgName;
    //           user.combinationName = `${this.userData.targetOrgName}-${user.fullname}`;
    //         }
    //         if (this.enableGroupCorp && this.isOrgSelectAlone) {
    //           // 集团版环境下，选人组件可设置成独立选人，及各个orgId下的相同一个人可以独立选择，并重置每一项的数据的org为当前选择的org
    //           user.oldId = user.id;
    //           user.id = `${user.id}&&${this.userData.targetOrgId}`;
    //         }
    //         delete this.selectedPersons[user.id];
    //       });
    //       this.updateUsers(list, false);
    //     });
    // },
    async loadAll() {
      // if (this.isCancelSelect) {
      //   return this.cancelSelectAll();
      // }
      this.loading = true;
      const limitCount = 5000;
      // let count = 0;
      const len = Math.ceil(this.count / limitCount);
      // if (len > 0) {
      //   this.isSearchAll = true;
      // }
      const reqArrs = []; // 请求数组
      for (let i = 0; i < len; i++) {
        const params = Object.assign({}, this.userData, {
          limit: limitCount,
          offset: limitCount * i
        });
        reqArrs.push(params);
      }

      try {
        const results = await Promise.all(
          reqArrs.map(params => getUserList(params))
        );
        const dataSource = [];
        results.forEach(res => {
          dataSource.push(...res.datas);
        });
        let data = dataSource.map(user => {
          user.nodeType = this.type;
          if (this.enableGroupCorp) {
            user.orgId = this.userData.targetOrgId;
            user.orgName = this.userData.targetOrgName;
            user.combinationName = `${this.userData.targetOrgName}-${user.fullname}`;
          }
          if (this.enableGroupCorp && this.isOrgSelectAlone) {
            // 集团版环境下，选人组件可设置成独立选人，及各个orgId下的相同一个人可以独立选择，并重置每一项的数据的org为当前选择的org
            user.oldId = user.id;
            user.id = `${user.id}&&${this.userData.targetOrgId}`;
          }
          return user;
        });
        this.selectAllData(data, 'persons');
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
      // let postData = Object.assign({}, this.userData, { limit: limitCount });
      // for (let i = 0; i < len; i++) {
      //   getUserList(Object.assign({}, postData, { offset: limitCount * i }))
      //     .then(result => {
      //       const list = result.datas;
      //       count++;
      //       if (count >= len) {
      //         this.isCancelSelect = true;
      //         this.isSearchAll = false;
      //       }
      //       list.forEach(user => {
      //         user.nodeType = this.type;
      //         if (this.enableGroupCorp) {
      //           user.orgId = this.userData.targetOrgId;
      //           user.orgName = this.userData.targetOrgName;
      //           user.combinationName = `${this.userData.targetOrgName}-${user.fullname}`;
      //         }
      //         if (this.enableGroupCorp && this.isOrgSelectAlone) {
      //           // 集团版环境下，选人组件可设置成独立选人，及各个orgId下的相同一个人可以独立选择，并重置每一项的数据的org为当前选择的org
      //           user.oldId = user.id;
      //           user.id = `${user.id}&&${this.userData.targetOrgId}`;
      //         }
      //         this.selectedPersons[user.id] = user;
      //       });

      //       this.updateUsers(list, true);
      //     })
      //     .catch(e => {
      //       console.log(e);
      //     });
      // }
    },
    search() {
      this.userData.searchKey = this.searchValue;
      this.userData.offset = 0;
      this.page = 1;
      this.showMoreFilter = false;
      this.getUserData();
    },
    itemChange() {
      this.$forceUpdate();
    },
    paramDataChange(type, data) {
      this.selectedParams.forEach(item => {
        if (item.db === type) {
          item.data = data;
        }
      });
    },
    showFilter() {
      this.showMoreFilter = false;
      this.dialogVisible = true;
    },
    saveFilter() {
      if (this.filerName.trim() === '') {
        this.$message({
          type: 'error',
          message: this.$t('pc_biz_udp_msg_filterNameEmpty')
        });
      }
      this.loading = true;
      this.spares.forEach(i=>{
        this.postData[i.nameKey] = {
          ...i,
          list: null
        };
      });
      let fn = this.filterId
        ? editFilter.bind(this, this.filterId, this.postData, this.enableGroupCorp)
        : createFilter.bind(this, this.postData, this.enableGroupCorp);
      fn()
        .then(() => {
          this.loading = false;
          this.dialogVisible = false;
          if (!this.filterId) {
            this.filerName = '';
          }
          this.getFilterList();
        })
        .catch(e => {
          this.loading = false;
          if (e.error && e.error.key === 'apis.udp.filter.validation.name.alreadyExist') {
            this.$message({
              type: 'error',
              message: this.$t('pc_biz_udp_msg_filterNameSame')
            });
          } else {
            console.log(e);
          }

        });
    },
    selectFilter(filter) {
      this.filerName = filter.name;
      this.filterId = filter.id;
      this.loading = true;
      getFilterDetail(filter.id, this.enableGroupCorp)
        .then(item => {
          this.loading = false;
          this.depts = item.deptList;
          this.positions = item.positionList;
          let result = this.params
            .map(param => {
              if (param.db === 'hireDate' || param.db === 'birthday') {
                return Object.assign({}, param, {
                  // 处理部分date格式化部分没有的问题
                  data: [
                    item[param.db + 'Start'] || '',
                    item[param.db + 'End'] || ''
                  ]
                });
              } else if (param.db === 'gradeIds') {
                let gradeList = item.gradeList;
                return Object.assign({}, param, {
                  data: gradeList.map(grade => grade.id)
                });
              } else {
                return Object.assign({}, param, { data: item[param.db] });
              }
            });
          this.getFilterSpares(item);
          this.selectedParams = result;
          this.search();
          this.$forceUpdate();
        })
        .catch(e => {
          this.$message({
            type: 'error',
            message: e.error.key
          });
        });
    },
    deleteFilter(item) {
      deleteFilter(item.id, this.enableGroupCorp)
        .then(() => {
          this.getFilterList();
        })
        .catch(e => {
          this.$message({
            type: 'error',
            message: e.error.key
          });
        });
    },
    selectUser(items, row) {
      // 选中数据
      if (Array.isArray(items)) {
        // 多选
        this.performanceSelectData(items, 'persons');
      } else {
        this.$emit('change', [row], 'persons');
      }
    },
    selectAllUser(items) {
      // 全选
      this.performanceSelectData(items, 'persons');
    },
    sizeChange(size) {
      this.userData.limit = size;
      this.page = 1;
      this.userData.offset = 0;
      this.getUserData();
    },
    updateUsers(items, isAdd) {
      this.$emit('input', Object.values(this.selectedPersons));
      this.$emit('update', this.type, items, isAdd);
    },
    pageChange(page) {
      this.page = page;
      this.userData.offset = (page - 1) * this.userData.limit;
      this.getUserData();
    },
    formatSelectedFromData(datas) {
      return [].concat(datas).map((data) => {
        if (data.db === 'hireDate' || data.db === 'birthday') {
          return {
            ...data,
            data: [data.start || '', data.end || '']
          };
        }
        return data;
      });
    },
    async getOrgSpares() {
      try {
        const { datas } = await getOrgSparsByUser();
        this.spares = datas.map(i=>{
          if (i.type === 'date') {
            return {
              basicFlag: 1,
              id: i.id,
              nameKey: i.spareKey, // 扩展字key
              patternRelation: 3, // 日期介于
              patternType: 1, // 0 选项 1日期 2数值
              patternValues: [],
              name: i.name
            };
          } else if (i.type === 'text') {
            // 文本
            return {
              basicFlag: 1,
              id: i.id,
              nameKey: i.spareKey, // 扩展字key
              patternRelation: 8, // 文本包含
              patternType: 3, // 0 选项 1日期 2数值 3文本
              patternValues: [],
              name: i.name
            };
          } else {
            // 选项
            return {
              basicFlag: 1,
              id: i.id,
              nameKey: i.spareKey, // 扩展字key
              patternRelation: 1, // 选项属于
              patternType: 0, // 0 选项 1日期 2数值
              list: i.optionValue ? i.optionValue.split(',') : [],
              patternValues: [],
              name: i.name
            };
          }
        });
      } catch (e) {
        this.spares = [];
      }
    },
    getFilterSpares(filter) {
      this.spares.forEach(i=>{
        if (!filter[i.nameKey]) return; // 跳过本次循环
        const filterSpare = filter[i.nameKey];
        if (i.patternType === 0) i.patternValues = i.list.filter(v=>filterSpare.patternValues.includes(v));
        else if (i.patternType === 1 || i.patternType === 3) i.patternValues = filterSpare.patternValues;
      });
      console.log(this.spares);
    }
  },
  watch: {
    selectData: {
      deep: true,
      handler() {
        this.performanceUpateSelected('persons');
        this.persons = [...this.persons];
      }
    },
    depts(depts) {
      this.deptObj = {};
      depts.forEach(dept => {
        this.deptObj[dept.id] = dept;
      });
    },
    positions(positions) {
      this.positionObj = {};
      positions.forEach(position => {
        this.positionObj[position.id] = position;
      });
    },
    isShowDeptment(v) {}
    // searchValue(value) {
    //   if (value.length > 20) {
    //     this.searchValue = value.substring(0, 20);
    //   }
    // }
  }
};
</script>

<style lang="scss">
.yxtbiz-user-selector-filter-block .yxt-form-item__label{
  display: block !important;
}
</style>
<style lang="scss">
.yxtbiz-user-selector-filter-right .yxt-form-clamp {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  word-break: break-all;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
}
</style>