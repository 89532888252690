<template>
  <div>
    <yxt-select
      v-model="dateString"
      value-key="id"
      prop-label="value"
      multiple
      size="small"
      @remove-tag="removeTag"
    >
      <yxt-option
        :value="dateString"
        label="value"
        disabled
        style="height:auto;margin:0 -20px;"
      >
        <div
          class="bg-white"
          style="padding: 10px 15px"
        >
          <yxt-date-picker
            v-model="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="-"
            :start-placeholder="$t('pc_biz_udp_tip_startPicker')"
            :end-placeholder="$t('pc_biz_udp_tip_endPicker')"
            @change="dateChange"
          ></yxt-date-picker>
        </div>
      </yxt-option>
    </yxt-select>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => Object.create({ start: '', end: '' })
    }
  },
  data() {
    return {
      date: [],
      dateString: []
    };
  },
  methods: {
    dateChange(v) {
      this.dateString = [{
        id: 'date',
        value: this.date.join('~')
      }];
      this.$emit('input', { start: v[0], end: v[1] });
    },
    removeTag() {
      this.date = ['', ''];
      this.$emit('input', { start: '', end: '' });
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(v) {
        this.date = [v.start, v.end];

        if (v.start || v.end) {
          this.dateString = [{
            id: 'date',
            value: this.date.join('~')
          }];
        }

      }

    }
  }
};
</script>

<style lang="scss" scoped>
</style>
