var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "standard-size-14 yxtbiz-ai-robot__coure" },
    [
      _vm.dataList.length === 0
        ? _c("EmptyData")
        : [
            _c(
              "div",
              {
                staticClass: "color-gray-7 yxtbiz-ai-robot__coure-title",
                class:
                  _vm.size === "small"
                    ? "font-size-14 lh22"
                    : "font-size-16 lh24",
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$t("pc_biz_ote_lbl_search_content", [
                        _vm.$t("pc_biz_o2o_lbl_weike"),
                      ])
                    ) +
                    "\n    "
                ),
              ]
            ),
            _vm._l(_vm.dataList, function (item) {
              return _c(
                "div",
                {
                  key: item.id,
                  on: {
                    click: function ($event) {
                      return _vm.toDetail(item)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "yxtbiz-ai-robot__coure-cell" },
                    [
                      item.imgUrl
                        ? _c("yxt-image", {
                            staticClass: "yxtbiz-ai-robot__course-cover",
                            attrs: { src: item.imgUrl, fit: "cover" },
                          })
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "ml12 yxtbiz-ai-robot__course-contain" },
                        [
                          _c("div", {
                            staticClass: "ellipsis-2 mb4",
                            class:
                              _vm.size === "small"
                                ? "font-size-14 lh22"
                                : "font-size-16 lh24",
                            domProps: { innerHTML: _vm._s(item.title) },
                          }),
                          _vm.size !== "small"
                            ? _c(
                                "div",
                                { staticClass: "coure-flex-center mt4" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "standard-size-12 color-gray-7 mt4 ellipsis-1",
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            item.lecturer
                                              ? _vm
                                                  .$t("pc_biz_ai_lecturer")
                                                  .d("讲师") +
                                                  ": " +
                                                  item.lecturer
                                              : ""
                                          ) +
                                          "\n              "
                                      ),
                                      item.lecturer
                                        ? _c("yxt-divider", {
                                            attrs: { direction: "vertical" },
                                          })
                                        : _vm._e(),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm
                                              .$t("pc_biz_cer_lbl_time")
                                              .d("时间") +
                                              "：" +
                                              (
                                                item.createTime ||
                                                item.publishTime
                                              ).replace(/\.\d+/, "")
                                          ) +
                                          "\n            "
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _c("div", { staticClass: "mt4" }, [
                                item.lecturer
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "standard-size-12 color-gray-7 mt4 ellipsis-1",
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm
                                                .$t("pc_biz_ai_lecturer")
                                                .d("讲师") +
                                                ": " +
                                                item.lecturer
                                            ) +
                                            "\n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "standard-size-12 color-gray-7 mt4 ellipsis-1",
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm
                                            .$t("pc_biz_cer_lbl_time")
                                            .d("时间") +
                                            "：" +
                                            (
                                              item.createTime ||
                                              item.publishTime
                                            ).replace(/\.\d+/, "")
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  item.courseContents && item.courseContents.length
                    ? [
                        _vm.size === "small"
                          ? _c(
                              "yxtf-row",
                              {
                                staticClass: "yxtbiz-ai-robot__microlecture",
                                attrs: { gutter: 8 },
                              },
                              [
                                _vm._l(
                                  item.courseContents.slice(0, 3),
                                  function (item1, index) {
                                    return _c(
                                      "yxtf-col",
                                      {
                                        key: index,
                                        staticClass: "mt8 hand",
                                        attrs: { span: 24 },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "search-sub-item-small p12",
                                            on: {
                                              click: function ($event) {
                                                return _vm.goDetail(
                                                  item.id,
                                                  item1.startTime,
                                                  item.searchKey
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "font-size-12 text-26 font-bold",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("getHMS")(
                                                      item1.startTime
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("span", {
                                              staticClass:
                                                "search-sub-item-small-text flex-1 ml12 text-59",
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  item1.highlightText
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                item.courseContents.length > 3
                                  ? _c(
                                      "yxtf-col",
                                      {
                                        staticClass: "mt8 text-8c",
                                        attrs: { span: 24 },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "search-sub-item p12 search-sub-item-small-empty text-center hand hover-primary-6",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "v-mid",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.toDetail(item)
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "pc_core_graphicmsg_checkdetail"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("yxtf-svg", {
                                              staticClass: "ml4 v-mid",
                                              attrs: {
                                                width: "16px",
                                                height: "16px",
                                                "icon-class": "right",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _c(
                              "yxtf-row",
                              {
                                staticClass: "yxtbiz-ai-robot__microlecture",
                                attrs: { gutter: 8 },
                              },
                              [
                                _vm._l(
                                  item.courseContents.slice(0, 3),
                                  function (item1, index) {
                                    return _c(
                                      "yxtf-col",
                                      {
                                        key: index,
                                        staticClass: "mt8 hand",
                                        attrs: { span: 6 },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "search-sub-item p12",
                                            on: {
                                              click: function ($event) {
                                                return _vm.goDetail(
                                                  item.id,
                                                  item1.startTime,
                                                  item.searchKey
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "font-size-12 text-26 font-bold",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("getHMS")(
                                                      item1.startTime
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("span", {
                                              staticClass:
                                                "search-sub-item-text flex-1 ml12 text-59",
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  item1.highlightText
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                item.courseContents.length > 3
                                  ? _c(
                                      "yxtf-col",
                                      {
                                        staticClass: "mt8 text-8c",
                                        attrs: { span: 6 },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "search-sub-item p12 search-sub-item-empty text-center hand hover-primary-6",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "v-mid",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.toDetail(item)
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "pc_core_graphicmsg_checkdetail"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("yxtf-svg", {
                                              staticClass: "ml4 v-mid",
                                              attrs: {
                                                width: "16px",
                                                height: "16px",
                                                "icon-class": "right",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              2
                            ),
                      ]
                    : _vm._e(),
                ],
                2
              )
            }),
            _vm.datas.paging.count > 3
              ? _c(
                  "div",
                  {
                    staticClass: "yxtbiz-ai-robot__course-more color-gray-8",
                    on: { click: _vm.toMore },
                  },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$t("pc_biz_lbl_common_viewmore")) +
                        "\n      "
                    ),
                    _c("yxt-svg", {
                      attrs: {
                        "remote-url": `${_vm.$staticBaseUrl}ufd/55a3e0`,
                        width: "16px",
                        height: "16px",
                        "icon-class": "arrow_right",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }