<template>
  <div :class="{'yxtbiz-ai-robot__dialog--msg': true, 'is-question': true, 'fullscreen': fullscreen} ">
    <div class="is-content">
      <div class="question-msg" ref="question">{{ msg.contentDetail && msg.contentDetail.content }}</div>
      <msgToolBtn  @toolClick="toolClick"/>
    </div>
    <yxtf-portrait v-if="showPortrait" class="mt4 ml12 yxtbiz-ai-robot__dialog--portrait" size="28px" :username="username" :imgUrl="userImgUrl"></yxtf-portrait>
  </div>
</template>

<script>
import msgToolBtn from './msgToolBtn.vue';
export default {
  name: 'Question',
  components: {
    msgToolBtn
  },
  props: {
    showPortrait: {
      type: Boolean,
      default: false
    },
    msg: {
      type: Object,
      default: () => {
        return {};
      }
    },
    fullscreen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      username: window.localStorage.getItem('username') || '',
      userImgUrl: window.localStorage.getItem('imgUrl') || '',
      menuVisible: false,
      rightMenuVisible: true
    };
  },
  methods: {
    toolClick(type) {
      if (type === 'search') {
        this.search();
      } else {
        this.copyText(this.$refs.question);
      }
    },
    copyText(copyDom) {
      // 创建text area
      const textArea = document.createElement('textarea');
      textArea.value = copyDom.innerText;
      textArea.style.position = 'absolute';
      textArea.style.opacity = '0';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select(); // 选中文本
      document.execCommand('Copy'); // 执行浏览器复制命令
      this.$message.success(this.$t('pc_biz_copy_success').d('复制成功！'));
    },
    search() {
      const { contentDetail } = this.msg;
      let pageUrl = `${window.location.origin}/search/#/gs/composite?keyword=${decodeURIComponent(contentDetail.content || '')}`;
      window.open(pageUrl, '_blank');
    }
  }
};
</script>
