<template>
  <div
    class="pr yxt-biz-video-container"
    :class="[
      isPaused ? 'audio--paused' : ''
    ]"
    :style="'width:' + comWidth + ';height:' + comHeight"
  >
    <div :id="playerType" :style="playerStyle">
    </div>
    <yxt-popover
      v-show="showCuesGuide && $slots.mark"
      :append-to-body="false"
      :popperOptions="{
        modifiers: [
          'shift',
          'offset',
          'keepTogether',
          'arrow',
          'flip',
          'applyStyle'
        ],
        gpuAcceleration: false
      }"
      :value="!!$slots.mark"
      :max-width="300"
      :max-height="200"
      :placement="markGuideDirection ? 'top-end' : 'top-start'"
      ref="markGuidePopover"
      popper-class="yxt-biz-video-markguide__popper"
      @show="setCuesGuide(true)"
      @after-leave="setCuesGuide(false)"
      @hide="setCuesGuide(false)"
      ><slot name="mark"></slot
    ></yxt-popover>
  </div>
</template>

<script>
import * as utils from './utils/utils';
import { bindPlayerCustom } from './utils/palyerUtils';
import WaterMark from '../../watermark';
import api from './service';
import AiTextSetting from './components/aiTextSetting.vue';
import AiSrt from './components/aiSrt.vue';
import { dragMove, setDragEnable } from './utils/dragmove';
import Vue from 'vue';
// import { loadScript as loadScriptWhole } from 'yxt-biz-pc/src/utils/util';

const u = navigator.userAgent.toLowerCase();

const d = /firefox/.test(u); // isFirefox
const r = /opera|opr\/[\d]+/.test(u); // isOpera
const k = !r && /(msie|trident)/.test(u); // isIE
const e = /edge\/(\d+)/.test(u); // isEdge
const o = !r && !e && !chrome && /safari/.test(u); // isSafari
const chrome = !r && !e && /chrome/.test(u) && /webkit/.test(u); // isChrome
const isNoHandlerSeek = k || e || o;

// mac下企业微信测试
const isQYWX = /wxwork/.test(u) && /macintosh|mac os x/i.test(u);
export default {
  name: 'YxtbizVideo',
  props: {
    isNewSdk: { // 是否使用新版本百度播放器版本
      type: Boolean,
      default: false
    },
    version: { // 水印新增属性
      type: String,
      default: 'v1'
    },
    appCode: { // 水印新增属性
      type: String,
      default: ''
    },
    isV1: { type: Boolean, default: false }, // 是否是1.0 1.0 不判断是否公有云
    clientType: { type: Number, default: 0 }, // 0 普通机构 // 1 h5 // 2 混部机构
    preview: { type: Number, default: 0 },
    fileId: { type: String, default: '' },
    type: { type: String, default: 'video' },
    width: { type: String, default: '800' },
    height: { type: String, default: '600' },
    repeat: { type: Boolean, default: false },
    autoStart: { type: Boolean, default: true },
    srtUrl: { type: [String, Array], default: ''}, // 字幕链接
    primary: {
      type: String,
      default: () => {
        return isQYWX ? 'videojs' : 'html5';
      }
    },
    volume: {
      type: Number,
      default: -1
    },
    starttime: { type: Number, default: 0 },
    logo: { type: Object, default: () => ({}) },
    minBufferLength: { type: Number, default: 100 },
    bceDecrypt: { type: Boolean, default: true },
    playRate: { type: Boolean, default: false }, // 是否开启倍速播放
    controlbarDrag: { type: Boolean, default: true },
    options: { type: Array },
    rightclick: {
      type: Array,
      default: () => [
        {
          title: '关于云学堂',
          link: 'https://www.yxt.com/'
        }
      ]
    },
    isShowWatermark: { type: Boolean, default: false },
    watermarkObj: {
      type: Object,
      default: () => ({})
    },
    isNeedToken: { type: Boolean, default: false },
    ticket: { type: String, default: '' }, // 后端生成的上传用票据，类似token
    isOpenMutiple: { type: Boolean, default: false },
    // cover: { type: String, default: '' },
    marks: { type: Array },
    // 是否启用打点自定义跳转，true: 点击打点不做处理，抛出markJump事件，false: 点击打点自动跳转
    enableMarkJump: { type: Boolean, default: true },
    // markGuide: { type: String }
    cover: { type: String, default: '' },
    enableAi: { // 是否可以开启ai字幕
      type: Boolean, default: false
    },
    lan: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isLocalApi: false,
      isLocalVideo: true, // 是否私有云
      fileIdForVideo: '',
      orgList: [],
      videoVersion: '3.7.0', // 定制百度播放器版本
      commonVideoVersion: '4.3.1.7', // 最新通用百度播放器版本
      videoHelperVersion: '3.7.1', // videojs版本
      playerType: this.type === 'video' ? 'videocontainer' : 'audiocontainer',
      playerStyle: {
        width: this.width,
        height: this.height
      },
      aiTextEnabled: true, // 开启ai字幕
      status: 'loading', // 加载js状态
      newOptions: this.options, // 需要内置m3u8时的数组
      callbackList: [], // 需要初始化的组件列表
      playRateConfig: null, // 播放速率
      isPaused: false,
      audioNum: 1,
      markOptions: {}, // 水印的参数
      playerStartTime: 0,
      player: null,
      markGuideDirection: false, // 打点引导的方向，false:默认向右，true:溢出向左
      showCuesGuide: false, // 首次渲染时不显示
      srtInstance: null,
      waterMarkText: '',
      currentTextLan: this.lan, // 当前字幕语言
      aiTextNode: null, // ai字幕设置组件实例
      textDragEnable: false // 是否允许拖拽字幕
    };
  },
  computed: {
    useCustomVersion() {
      return this.isLocalVideo || (this.type !== 'video') || this.isIE || !this.isNewSdk;
    },
    isIE() {
      return window.browserHelper.browser === 'IE';
    },
    comWidth() {
      return this.width.includes('%') ? this.width : this.width + 'px';
    },
    comHeight() {
      return this.height.includes('%') ? this.height : this.height + 'px';
    },
    isUseToken() {
      return this.type === 'video' && this.isNeedToken;
    },
    isHavaFileId() {
      return this.newOptions.every(item => item.fileId);
    },
    isHavaResolution() {
      return this.newOptions.every(item => item.resolution);
    },
    isColorRGBA() {
      return this.markOptions.color && this.markOptions.color.includes('rgba');
    },
    initVolume() {
      if (this.volume === -1) {
        // 老版本sdk静音时baiducyberplayer.volume的值不改变，新版sdk会设置为0
        // 新版本sdk销毁播放器时baiducyberplayer.mute默认设置为true
        let localVolume = parseInt(localStorage.getItem('baiducyberplayer.volume') || 100, 10);
        localVolume = (localVolume >= 0 && localVolume <= 100) ? localVolume : 100;
        if (this.useCustomVersion) { // 旧版sdk
          return localStorage.getItem('baiducyberplayer.mute') === 'true' ? 0 : localVolume;
        } else {
          return localVolume;
        }
      } else {
        return this.volume;
      }
    }
  },
  created() {
    this.playerStartTime = this.starttime;
    // this.getBceValid();

    if (this.isOpenMutiple) {
      this.playerType = this.playerType + parseInt(Math.random() * 100000, 10);
    }
  },
  async mounted() {
    // 获取当前机构是否公有云
    this.isV1 ? this.isLocalApi = false : this.isLocalApi = await this.getUploadBucketType();
    // this.isLocalApi = await this.getUploadBucketType();
    this.fileIdForVideo = this.fileId || (this.options && this.options.length && this.options[0].fileId);
    await this.getFileDetail(this.fileIdForVideo);
    this.getNewListAgain();
    // loadScriptWhole(`${this.$staticBaseUrl}ufd/b0174a/common/pc/js/videoconfiglist.js?t=${new Date().getTime()}`).then(() => {
    //   this.orgList = window.videoOrgList;
    //   if (this.isNewOrgId()) {
    //     // 业务传入播放列表 第一次获取播放列表 未传入直接组件内获取播放列表
    //     this.fileIdForVideo = this.fileId || (this.options && this.options.length && this.options[0].fileId);
    //     if (this.options && this.options.length) {
    //       this.getBceValid();
    //     } else {
    //       this.getPlayList();
    //     }
    //   } else {
    //     this.getBceValid();
    //   }
    // });
  },
  methods: {
    getNewListAgain() {
      if (this.options && this.options.length) {
        this.getBceValid();
      } else {
        this.getPlayList();
      }
    },
    getMarkOption() {
      if (Object.keys(this.watermarkObj).length) {
        let color = this.watermarkObj.color;
        if (this.isHex(color) && !color.startsWith('#')) {
          color = '#' + color;
        }

        this.markOptions = {
          enabled: typeof this.watermarkObj.enabled === 'undefined' ? 1 : this.watermarkObj.enabled,
          color: color,
          fontSize: this.watermarkObj.fontSize,
          type: this.watermarkObj.type,
          text: this.watermarkObj.text,
          opacity: this.watermarkObj.opacity
        };
      } else {
        this.markOptions = {};
      }
    },
    formatConfig(config) {
      if (!config) return {};
      let color = config.otherColor;
      if (this.isHex(color) && !color.startsWith('#')) {
        color = '#' + color;
      }

      return {
        ...config,
        fontSize: config.otherFontSize,
        color,
        opacity: config.otherAlpha,
        text: config.watermarkContent
      };
    },
    setLan(lan) {
      this.currentTextLan = lan;
      // 设置字幕语言
      if (this.aiTextNode) {
        this.aiTextNode.setLan(lan);
      }
    },
    // 重新获取token
    getNewToken() {
      this.newOptions.forEach(item => {
        let linkParams = {
          fileId: item.fileId,
          resolution: item.resolution
        };
        api.getBceValid(linkParams, this.ticket).then(res => {
          item.tokenText = res.token;
          item.loading = true;
        });
      });
    },
    getBceValid() {
      // 设置码率、清晰度
      if (this.isHavaResolution) {
        this.newOptions = this.newOptions
          .map(item => {
            const videoLabel = utils.handleVideoLabel(item.resolution);
            if (videoLabel[0]) {
              item.label = this.$t(videoLabel[0]);
              item.sortIndex = videoLabel[1];
            }
            return item;
          })
          .sort((a, b) => {
            return b.sortIndex - a.sortIndex;
          });
      }
      // 如果是m3u8视频需要解码时，传入token
      if (this.isUseToken && this.isHavaFileId) {
        this.getNewToken();

        this.getRec = setInterval(() => {
          const loadComplete = this.newOptions.every(item => item.loading);
          if (loadComplete) {
            this.getRec && window.clearInterval(this.getRec);
            this.getResource();
          }
        }, 500);
      } else {
        this.getResource();
      }
    },

    getResource() {
      if (this.type === 'audio' || !this.isShowWatermark) {
        this.loaded();
        return;
      }

      if (Object.keys(this.watermarkObj).length) {
        this.getMarkOption();
        this.loaded();
      } else {
        api
          .getWatermark()
          .then(async res => {
            const data = this.formatConfig(res);
            this.markOptions = data;
            await this.loaded();
          })
          .catch(err => {
            console.log(err);
          });
      }

    },

    // 加载播放器资源
    loadVideoResource() {
      if (!window.browserHelper.isSupportVideoJs) {
        // win10 IE11 使用 video播放
        window.browserHelper.isSupportVideoJs =
          // navigator.userAgent.toLowerCase().indexOf('windows nt 10') > -1 &&
          window.browserHelper.browser === 'IE' &&
          window.browserHelper.bigVersion === 11;
      }

      if (window.browserHelper.isSupportVideoJs) {
        // 特定机构走新播放器 其他机构走老播放器
        // if (this.isNewOrgId()) {
        utils.loadVideoScript(this.videoHelperVersion, '/videojs/video.min.js', () => {
          utils.loadVideoScript(this.videoHelperVersion, '/videojs/videojs-contrib-hls.min.js', () => {
            utils.loadVideoScript(this.videoHelperVersion, '/videojs/videojs-contrib-quality-levels.min.js', () => {
              if (this.useCustomVersion) {
                this.loadCyberplayer(this.videoVersion);
              } else {
                this.loadCyberplayer(this.commonVideoVersion, true);
              }
            });
          });
        });
      } else {
        if (this.useCustomVersion) {
          this.loadCyberplayer(this.videoVersion);
        } else {
          this.loadCyberplayer(this.commonVideoVersion, true);
        }
      }
    },
    // 配置字幕
    setSrt() {
      const AiSrtConstructor = Vue.extend(AiSrt);
      if (!this.srtInstance) {
        this.srtInstance = new AiSrtConstructor();
        this.srtInstance.enabled = this.enableAi && this.aiTextEnabled;
        this.player && this.player.getContainer().querySelector('.jw-controls').appendChild(this.srtInstance.$mount().$el);
        this.srtInstance.$on('hide-setting', () => {
          // 字幕解析失败或者文件中无内容，隐藏设置面板
          this.aiTextNode.hasContent = false;
        });
        this.srtInstance.$on('show-setting', () => {
          this.aiTextNode.hasContent = true;
        });
        dragMove(this.player.getContainer().querySelector('.jw-controls'), this.srtInstance.$el, this.emitCenterChange);
      }

      this.srtInstance.url = this.srtUrl;
      if (this.currentTextLan) {
        this.srtInstance.lan = this.currentTextLan;
      }
      this.aiTextNode.showLan = Array.isArray(this.srtUrl) && this.srtUrl.length > 1;
    },

    emitCenterChange(option) {
      this.srtInstance && this.srtInstance.moveChange(option);
    },
    // 加载百度播放器
    loadCyberplayer(version, isCommon = false) {
    //   const baseCommon = (typeof window !== 'undefined' && window.feConfig && window.feConfig.common);
    //   let staticBaseUrl = (baseCommon && window.feConfig.common.staticBaseUrl) || 'https://stc.yxt.com/';
      const loadFunc = isCommon ? utils.loadVideoScript : utils.loadScript;
      loadFunc(version, '/cyberplayer.js', () => {
        if (window.cyberplayer) {
          this.status = 'loaded';
        } else {
          this.status = 'loading';
        }
      });
    },

    // 检查空数据
    checkNullData() {
      this.playRateConfig = this.playRate
        ? this.playRateConfig || [
          { label: '×1' },
          { label: '×1.25' },
          { label: '×1.5' },
          { label: '×1.75' },
          { label: '×2' }
        ]
        : null;
    },

    // 加载基础数据
    loaded() {
      this.loadVideoResource();

      // 检查空数据
      this.checkNullData();

      // 事件注册
      this.events = [
        'setupError',
        'playlist',
        'playlistItem',
        'playlistComplete',
        'bufferChange',
        'play',
        'pause',
        'buffer',
        'idle',
        'error',
        'seek',
        'seeked',
        'time',
        'mute',
        'volume',
        'fullscreen',
        'resize',
        'levels',
        'levelsChanged',
        'captionsList',
        'captionsChange',
        'controls',
        'displayClick',
        'meta'
      ];
      this.eventCaller = (funcName, parameter) => {
        let fixedName = 'on' + utils.firstLetterUpper(funcName);
        if (this && this[fixedName] && typeof this[fixedName] === 'function') {
          this[fixedName](parameter);
        }
      };
    },

    // 开始播放
    setupEncrypt() {
      try {
        if (this.newOptions.length > 0) {
          let sources = this.newOptions.map(item => {
            let params = {
              file: item.fileFullUrl,
              width: item.width,
              height: item.height,
              label: item.label,
              tokenText: item.tokenText
            };
            if (item.type) {
              params.type = item.type;
            }
            return params;
          });
          let playlist = [{ sources, image: this.cover }];
          this.tempVolume = this.initVolume;
          //   let playMarkColor = '';
          //   if (this.type === 'video' && this.isShowWatermark) {
          //     playMarkColor = this.isRGBA();
          //   }

          // 播放器的初始化
          let playerOptions = {
            hevc: false,
            native: false,
            width: this.width,
            height: this.height,
            startParam: 'start',
            backcolor: '#FFFFFF',
            stretching: 'uniform',
            autostart:
              this.type === 'audio'
                ? this.autoStart
                : d
                  ? false
                  : this.autoStart,
            repeat: this.repeat,
            primary: this.primary,
            volume: this.initVolume,
            starttime: 0,
            playRate: this.playRate,
            playRateConfig: this.playRateConfig,
            minBufferLength: this.minBufferLength,
            control: 'over',
            controlbar: {
              barLogo: false,
              canDrag: this.controlbarDrag
            },
            logo: this.logo,
            playlist: playlist,
            rightclick: this.rightclick,
            // marquee: {
            // // 跑马灯设置（IE9及以下不支持）
            //   show: this.isOpenMarquee, // 显示与否
            //   text: this.markOptions.text, // 文案
            //   fontSize: this.markOptions.color, // 字体大小
            //   color: playMarkColor // 字体颜色
            // },
            // keyRequestMode: 'once',
            ak: 'b57e71d512c64ac39543b585ea3f32b1'
          };
          // if (this.isNewOrgId()) {
          playerOptions.hevc = false;
          playerOptions.native = false;
          playerOptions.keyRequestMode = 'once';
          // }
          let player = window.cyberplayer(this.playerType).setup(playerOptions);

          this.player = player;
          player.onMeta(event => {
            bindPlayerCustom(player);
            // 设置打点和缩略图
            this.setCuesAndThumbnails(player);
          });

          player.onBeforePlay(event => {
          // 断点续播
            if (
              this.playerStartTime > 0 &&
              player.getPlaylistItem().title !== '广告' &&
              !isNoHandlerSeek
            ) {
              player.seek(this.playerStartTime);
              this.playerStartTime = 0;
            }
            if (!isNoHandlerSeek) {
              this.handlerSeek(player);
            }
            if (this.type === 'audio' && this.audioNum === 1) {
              this.insertAudioIcon();
              this.audioNum = 2;
            }

            if (this.useCustomVersion) {
              setTimeout(() => {
                let sourcesToken = sources.filter(source => {
                  return (
                    source.tokenText === undefined ||
                    source.tokenText === '' ||
                    source.tokenText === null
                  );
                });
                if (sourcesToken.length === 0) {
                  player.setToken(sources);
                }
              }, 200);
            }
            this.eventCaller('beforePlay', event);
            // 播放前事件
            this.$emit('onBeforePlay', event);
          });

          player.onReady(event => {
            this.playReady();
            this.initAiText();
            this.setSrt();

            if (!this.useCustomVersion) {
              setTimeout(() => {
                let sourcesToken = sources.filter(source => {
                  return (
                    source.tokenText === undefined ||
                  source.tokenText === '' ||
                  source.tokenText === null
                  );
                });
                if (sourcesToken.length === 0) {
                  player.setToken(sources);
                }
              }, 200);
            }
            this.eventCaller('ready', event);

            if (this.type === 'video') {
            // 在ready里绘制水印
              if (
                this.markOptions.enabled && // && this.markOptions.type === 1
                this.isShowWatermark
              ) {
                this.refreshWatermark();
              }
            }
            this.$emit('onReady', event);
          });
          player.onResize(() => {
            this.srtInstance && this.srtInstance.setSrtPosition();
          });
          player.onBuffer(event => {
            this.eventCaller('buffer', event);
            this.$emit('onBuffer', event);
          });

          player.onPlay(event => {
            if (!this.useCustomVersion && this.tempVolume) {
              this.$nextTick(()=>{
                player.setVolume(this.tempVolume);
                this.tempVolume = undefined;
              });
            }
            this.isPaused = false;
            if (isNoHandlerSeek) {
              this.handlerSeek(player);
            }

            this.eventCaller('play', event);
            this.$emit('onPlay', event);
          });

          player.onPause(event => {
            this.isPaused = true;
            this.eventCaller('pause', event);
            this.$emit('onPause', event);
          });

          player.onFullscreen(event => {
            this.eventCaller('fullscreen', event);
            this.$emit('onFullscreen', event);
          });

          // 播放完成事件
          player.onComplete(event => {
            this.getNewToken();
            this.isPaused = true;
            this.eventCaller('complete', event);
            this.$emit('onComplete', event);
          // this.getPlayList();
          });

          // 切换清晰度事件
          player.onQualityChange(event => {
          // this.getPlayList();
            this.getNewToken();
            // this.isLocalApi && this.getPlayList();
            this.eventCaller('qualityChange', event);
            this.$emit('onQualityChange', event);
          });

          // 播放过程中事件
          player.onTime(event => {
          // window.sessionStorage && window.sessionStorage.setItem('playedTime', event.position);
            this.timeHandler(event);
            this.eventCaller('time', event);
            this.$emit('onTime', event);
          });

          player.onSeek(event => {
          // let playedTime = window.sessionStorage && window.sessionStorage.getItem('playedTime');
          // if (playedTime) {
          //   if (event.offset !== event.position && event.offset > playedTime) {
          //     setTimeout(() => {
          //       // player2.seek(playedTime);
          //       this.eventCaller('seek', event, playedTime);
          //       this.$emit('onSeek', event, playedTime);
          //     });
          //   }
          // } else {
          //   this.eventCaller('seek', event);
          //   this.$emit('onSeek', event);
          // }
            this.eventCaller('seek', event);
            this.$emit('onSeek', event);
          });

          player.onError(event => {
            this.isPaused = true;
            this.eventCaller('error', event);
            this.$emit('onError', event);
          });

          player.onPlaybackRate(event => {
            this.eventCaller('playbackRate', event);
            this.$emit('onPlaybackRate', event);
          });

          if (window.browserHelper.isSupportVideoJs) {
            player.on('levelsChanged', event => {
              let videoArr = [
                {
                  file: this.newOptions[event.currentQuality].fileFullUrl,
                  tokenText: this.newOptions[event.currentQuality].tokenText
                }
              ];
              setTimeout(() => {
                player.setToken(videoArr);
              }, 200);
            });
          }

          this.bdPlayer = player;
        }
      } catch (e) {
        console.log(e.message);
      }
    },

    timeHandler(e) {
      const { position } = e;
      this.srtInstance && this.srtInstance.timeChange(position);
    },

    async getFileDetail(fileId) {
      try {
        const res = await api.getFileDetail(fileId);
        this.isLocalVideo = res.platform === 'local';
      } catch (_) {}
    },

    async getUploadBucketType() {
      const mixinRes = await api.getMixingInfo();
      let isLocalApi = false;
      let { localSwith, uploadToCloud } = mixinRes;
      if (!uploadToCloud) {
        uploadToCloud = '0';
      }
      if (!localSwith) {
        localSwith = 0;
      }
      if (localSwith === 1 && uploadToCloud === '1') {
        // 私有云
        isLocalApi = true;
      } else {
        // 公有云
        isLocalApi = false;
      }
      return isLocalApi;
    },

    initAiText() {
      if (!this.player) return;
      const container = this.player.getContainer();
      if (container) {
        const iconContainer = container.querySelector('.jw-controlbar-right-group');
        const AiTextConstructor = Vue.extend(AiTextSetting);
        const aiTextNode = new AiTextConstructor();
        aiTextNode.enabled = this.enableAi;
        aiTextNode.aiEnabled = this.aiTextEnabled;
        aiTextNode.textDragEnable = this.textDragEnable;
        aiTextNode.player = this.player;
        setDragEnable(this.textDragEnable);
        if (this.currentTextLan) {
          aiTextNode.setLan(this.currentTextLan);
        }

        // ai字幕开启设置变化
        aiTextNode.$on('enableChange', (v) => {
          this.aiTextEnabled = v;
          this.srtInstance && (this.srtInstance.enabled = v && this.enableAi);
        });
        // ai字幕语言配置
        aiTextNode.$on('ai-lan-change', (v) => {
          this.currentTextLan = v;
          this.srtInstance && (this.srtInstance.lan = v);
          this.$emit('ai-lan-change', v);
        });
        // ai字幕字体大小配置
        aiTextNode.$on('ai-text-size', v => {
          this.srtInstance && (this.srtInstance.size = v);
        });

        // 字幕背景透明度修改
        aiTextNode.$on('ai-text-opacity', v => {
          this.srtInstance && (this.srtInstance.opacity = v);
        });

        aiTextNode.$on('drag-enable-change', v => {
          setDragEnable(v);
        });

        this.aiTextNode = aiTextNode;

        // 将aiTextNode插入iconContainer最前面
        iconContainer.insertBefore(aiTextNode.$mount().$el, iconContainer.firstChild);
      }
    },

    async getPlayList() {
      // 请求接口 让ticket生效
      let groupListRes = await api.getGroupList();
      let groupId = groupListRes.serviceGroupList && groupListRes.serviceGroupList.length && groupListRes.serviceGroupList[0].id;
      let params = {
        appCode: 'kng',
        fileId: this.fileIdForVideo,
        fullname: window.localStorage && window.localStorage.getItem('fullname') || '', // 用户名
        orgId: window.localStorage && window.localStorage.getItem('orgId') || '',
        username: window.localStorage && window.localStorage.getItem('username') || '', // 英文
        clientType: this.isLocalApi ? 2 : 0, // 0 普通机构 // 1 h5 // 2 混部机构
        preview: this.preview || 0, // 默认0 业务传进来
        upgred: 'upgg' // 固定值
      };
      // header serviceGroup 业务传过来或者接口里拿
      api.getResourceList(params, groupId).then(res => {
        let newOptions = [];
        res.playDetails && res.playDetails.length && res.playDetails.forEach(item => {
          newOptions.push({
            fileFullUrl: item.url,
            resolution: item.desc,
            fileId: this.fileIdForVideo
          });
        });
        this.newOptions = [...newOptions];
        this.status = 'loading';
        this.getBceValid();
      });
    },

    // 控制是否可拖动播放进度条
    playReady() {
      if (!this.controlbarDrag) {
        this.$el.querySelector('.jw-slider-time').style.pointerEvents = 'none';
      }
    },

    // 16进制转rgba
    hexToRgb(hex) {
      let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        }
        : null;
    },

    isRGBA() {
      if (this.isColorRGBA) {
        return this.markOptions.color;
      }
      let originColor = this.markOptions.color;
      if (this.markOptions.color && this.markOptions.color.startsWith('#')) {
        originColor = this.markOptions.color.slice(1);
      }
      let color = this.hexToRgb('#' + originColor);
      let alpha = this.markOptions.opacity ? this.markOptions.opacityparseInt(this.markOptions.opacity, 10) / 100 : 1;
      return `rgba(${color.r}, ${color.g}, ${color.b}, ${alpha})`;
    },

    isHex(color) {
      if (!color) return false;
      return !color.startsWith('hsl') && !color.startsWith('rgb');
    },

    refreshWatermark() {
      // 单个水印
      let container = document.getElementById(this.playerType);
      if (container !== null && container !== undefined) {
        const WaterConstructor = Vue.extend(WaterMark);
        const instance = new WaterConstructor({
          propsData: {
            version: this.version,
            option: this.markOptions,
            appCode: this.appCode,
            text: this.markOptions.text
          }
        });
        container.appendChild(instance.$mount().$el);
      } else {
        this.refreshWatermark();
      }
    },

    handlerSeek(player) {
      if (
        this.playerStartTime > 0 &&
        player.getPlaylistItem().title !== '广告'
      ) {
        player.seek(this.playerStartTime);
        this.playerStartTime = 0;
      }
    },

    // 插入音频图标
    insertAudioIcon() {
      let container = document.getElementById(this.playerType);
      if (container !== null && container !== undefined) {
        let audio = document.createElement('div');
        audio.setAttribute('class', 'audio-icon');
        let audioHtml =
          '<i class="d-in-block audio-disk-icon disk-paused"></i><i class="d-in-block audio-lever-icon lever-outside"></i>';
        audio.innerHTML = audioHtml;
        container.appendChild(audio);
      }
    },

    // 暂停播放器
    pause() {
      this.bdPlayer.pause();
    },

    // 继续播放
    play() {
      this.bdPlayer.play();
    },

    // 控制播放进度s
    seek(time) {
      try {
        this.bdPlayer.seek(time);
      } catch (e) {
        console.log(e);
      }
    },

    // 停止视频
    stop() {
      this.bdPlayer.stop();
    },

    // 销毁播放器
    dispose() {
      if (this.bdPlayer) {
        this.bdPlayer.stop();
        this.bdPlayer.remove();
        this.bdPlayer = null;
      }
      if (document.getElementById(this.type + 'Player')) {
        document.getElementById(this.type + 'Player').innerHTML = '';
        document.getElementById('audio') &&
          (document.getElementById('audio').innerHTML = '');
      }
    },

    getPlayer() {
      return this.bdPlayer;
    },
    // 设置打点和缩略图
    setCuesAndThumbnails(player) {
      // 清空所有打点信息
      player.yxtCustom.setCustomCues([]);
      // 重新加载打点信息
      player.yxtCustom.setCustomCues(
        this.marks.map(mark => ({
          time: mark.markTime,
          text: mark.markDesc,
          url: mark.markUrl,
          onClick: mark => {
            // 启用自定义mark jump时，返回 true，用于告知内部不执行seek
            if (!this.controlbarDrag || this.enableMarkJump) {
              this.$emit('markJump', mark.time);
              return true;
            }
            return false;
          }
        }))
      );
      this.initCuesGuide();
    },
    initCuesGuide() {
      this.showCuesGuide = true;
      const markGuidePopover = this.$refs.markGuidePopover;
      if (!markGuidePopover) return;
      markGuidePopover.doClose();
      this.$nextTick(() => {
        this.setCuesGuide(true);
      });
    },
    // 设置打点引导popover，visible: 设置完成后是否显示popover
    setCuesGuide(visible) {
      const markGuidePopover = this.$refs.markGuidePopover;
      if (!markGuidePopover || !this.player) return;
      const cue =
        this.player && this.player.yxtCustom && this.player.yxtCustom.time && this.player.yxtCustom.time.cues && this.player.yxtCustom.time.cues[0];
      if (cue) {
        this.player
          .getContainer()
          .querySelector('.jw-controlbar')
          .append(markGuidePopover.$el);
        markGuidePopover.referenceElm = cue.el;
        this.excCuesGuidePopover(cue);
        visible && markGuidePopover.handleMouseEnter();
      }
    },
    // 计算CuesGuidePopover 的位置，禁用自动溢出处理后，防止可能的边界溢出
    excCuesGuidePopover(cue) {
      const markGuidePopover = this.$refs.markGuidePopover;
      if (!markGuidePopover) return;
      const containerWidth = this.player.yxtCustom.timeContainerEl.offsetWidth;
      // cue offset rigth 点位到右侧结束距离
      const cueOffsetRight = containerWidth * (1 - cue.progress / 100);
      this.markGuideDirection = cue.progress > 60 && cueOffsetRight < 230;
    }
  },
  watch: {
    status(v) {
      if (v === 'loaded') {
        this.setupEncrypt();
      }
    },
    enableAi(v) {
      this.srtInstance.enabled = v && this.aiTextEnabled;
    },
    marks() {
      this.player && this.setCuesAndThumbnails(this.player);
    },
    lan: {
      immediate: true,
      handler(v) {
        if (v) {
          this.setLan(v);
        }
      }
    },
    srtUrl: {
      handler() {
        this.setSrt();
      }
    }
  },
  beforeDestroy() {
    this.dispose();
    this.getRec && window.clearInterval(this.getRec);
  }
};
</script>
