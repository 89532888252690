var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "broadside-content" }, [
    _c(
      "div",
      { staticClass: "ai-robot-info" },
      [
        _vm.settings.assistHeadImg
          ? _c("yxtf-portrait", {
              staticClass: "mr12",
              attrs: {
                size: "medium",
                username: "AI",
                imgUrl: _vm.settings.assistHeadImg,
              },
            })
          : _vm._e(),
        _c("span", { staticClass: "mt12 ai-title" }, [
          _vm._v(_vm._s(_vm.title)),
        ]),
      ],
      1
    ),
    _c("div", { staticClass: "action-btns" }, [
      _c(
        "div",
        { staticClass: "action-btns-item" },
        [
          _c(
            "yxtf-popconfirm",
            {
              attrs: {
                title: _vm
                  .$t("pc_biz_ai_log_clear_confirm")
                  .d("确定清空此对话消息记录？"),
                "hide-icon": "",
              },
              on: { confirm: _vm.clearHistory },
            },
            [
              _c(
                "div",
                {
                  staticClass: "item-section",
                  attrs: { slot: "reference" },
                  slot: "reference",
                },
                [
                  _c(
                    "span",
                    { staticClass: "mr5" },
                    [
                      _c("yxt-svg", {
                        staticClass: "color-gray-7 hover-primary-6 hand",
                        attrs: {
                          "remote-url": _vm.svgUrl,
                          width: 22,
                          height: 22,
                          "icon-class": "clear",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("pc_biz_ai_clear_log").d("清空记录"))),
                  ]),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "action-btns-item" },
        [
          _c(
            "div",
            {
              staticClass: "item-section",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.share.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "span",
                { staticClass: "mr5" },
                [
                  _c("yxt-svg", {
                    staticClass: "color-gray-7 hover-primary-6 hand",
                    attrs: {
                      "remote-url": _vm.svgUrl,
                      width: 22,
                      height: 22,
                      "icon-class": "share",
                    },
                  }),
                ],
                1
              ),
              _c("span", [
                _vm._v(_vm._s(_vm.$t("pc_biz_ai_share_friends").d("分享好友"))),
              ]),
            ]
          ),
          _vm.showShare
            ? _c("Share", { attrs: { bFullscreen: _vm.bFullscreen } })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }