<template>
  <div class="rate">
    <yxtf-rate
      :value="Math.round(score)"
      class="comment-title-content-old"
      :disabled="true"
      icon-size="small"
      text-color="#FA8C16"
      void-icon-class="yxt-icon-star-on"
      void-color="#d9d9d9"
    ></yxtf-rate>
  </div>
</template>
<script>
export default {
  props: {
    score: {
      type: Number
    }
  },
  data() {
    return {
      userScore: 3.2
    };
  }
};
</script>
<style scoped lang="scss">
::v-deep.yxt-rate__item {
  margin-right: 4px;
}
.comment-title-content-old {
  display: flex;
  align-items: center;
}
</style>
