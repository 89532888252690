var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "yxtbiz-ai-robot-collect-kng width-percent-100 pointer",
      on: {
        click: function ($event) {
          return _vm.toDetail(_vm.data.id)
        },
      },
    },
    [
      _vm.data.coverUrl !== ""
        ? _c(
            "div",
            { staticClass: "yxtbiz-ai-robot-collect-kng__face" },
            [
              _c("yxt-image", {
                attrs: { src: _vm.data.coverUrl, alt: "", fit: "cover" },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "pull-left yxtbiz-ai-robot-collect-kng__maincontent",
          class: {
            "yxtbiz-ai-robot-collect-kng__haveface": _vm.data.coverUrl !== "",
          },
        },
        [
          _c(
            "div",
            {
              class: [
                _vm.size === "small"
                  ? "ellipsis-2 font-size-14 lh22"
                  : "ellipsis font-size-16  lh24",
                "color-gray-10",
              ],
            },
            [_vm._v("\n      " + _vm._s(_vm.data.title) + "\n    ")]
          ),
          _c(
            "div",
            {
              class: [
                _vm.size === "small" ? "mt4" : "mt8",
                "yxtbiz-ai-robot-collect-kng__bottom lh20 color-gray-7 font-size-12",
              ],
            },
            [
              _vm.data.studyScore
                ? _c("span", [
                    _vm._v(_vm._s(_vm.$t("pc_biz_collect_studyScore")) + " "),
                    _c("span", { staticStyle: { color: "#f66704" } }, [
                      _vm._v(_vm._s(_vm.data.studyScore)),
                    ]),
                  ])
                : _vm._e(),
              _vm.data.studyScore
                ? _c("yxt-divider", { attrs: { direction: "vertical" } })
                : _vm._e(),
              _c("div", [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t("pc_biz_collect_studyCount", {
                        num: _vm.data.studyCount,
                      })
                    ) +
                    "\n      "
                ),
              ]),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }