<template>
<div class="yxtbiz-ai-robot__biz-answer">
  <div class="is-content is-answer-content yxtbiz-ai-robot__biz-answer--content">
  <div v-if="incorrectText" class="ph12 mb12">{{incorrectText}}</div>
    <div ref="content" class="answer-content">
      <slot></slot>
    </div>
</div>
<div class="answer-footer" v-if="msg.answerStatus === 1 && !msg.isFunction && !isWaitAnswer">
    <span>
        <span class="re-answer" v-if="lastMsg" @click="toolClick">
            <yxtf-svg class="icon-copy" width="20px" height="20px" icon-class="turn-right" />
            <span> {{ $t('pc_biz_ai_reanswer').d('重新回答') }}</span>
        </span>
    </span>
</div>
</div>
</template>

<script>
export default {
  name: 'bizAnswer',
  props: {
    isWaitAnswer: { // 是否回答等待中
      type: Boolean,
      default: false
    },
    msg: { // 回答的消息体
      type: Object,
      default: () => ({})
    },
    incorrectText: {
      type: String,
      default: ''
    },
    lastMsg: { // 当前是否是最后一条回答消息
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  methods: {
    toolClick() {
      this.$emit('resend', 'onceAgin');
    }
  }
};
</script>
