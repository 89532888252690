<template>
  <div v-if="ShowAi" class="warp">
    <div class="icon-warp" :class="isStu ? 'icon-warp-stu':''"  @click="show = true">
      <yxt-tooltip v-if="!isStu" :content="$t('pc_biz_ai_helper_tip').d('企业AI助手')">
        <yxt-image :class="tiny ? 'is-tiny' : 'is-manage'" class="ai-icon"
          :src="`${staticBaseUrl}assets/c3cb0554/36c358cd/aip.png`"></yxt-image>
      </yxt-tooltip>
      <yxtf-tooltip v-else :content="$t('pc_biz_ai_helper_tip').d('企业AI助手')">
        <yxt-image class="is-stu ai-icon"
          :src="`${staticBaseUrl}assets/c3cb0554/36c358cd/aip.png`"></yxt-image>
      </yxtf-tooltip>

    </div>
    <div>
      <yxtbiz-ai-robot v-if="show"  @close="show = false" :mode="isStu ? 'student' : 'manage'"></yxtbiz-ai-robot>
    </div>
  </div>
</template>

<script>
import commonUtil from 'yxt-biz-pc/packages/common-util';
import { getAiIconStatus } from './service';
export default {
  props: {
    isStu: {
      type: Boolean,
      default: false
    },
    tiny: {
      type: Boolean,
      default: false
    },
    isXxv2: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      show: false,
      staticBaseUrl: window.feConfig.common.staticBaseUrl,
      openAi: false
    };
  },
  async created() {
    try {
      await commonUtil.preCheckFunctions(['ai_bob']);
      const isOpenAI = commonUtil.checkTimeOutFnc('ai_bob') === 2;
      if (isOpenAI) {
        getAiIconStatus().then(res => {
          this.openAi = res.access;
        });
      }
    } catch (e) {}
  },
  computed: {
    ShowAi() {
      return this.openAi;
    }
  }
};
</script>

<style lang="scss" scoped>
.warp {
  display: flex;
  align-items: center;

  .icon-warp {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    .ai-svg{
      position: absolute;
      top:-7px;
      right: 2px;
    }
    .ai-icon {
      margin: 0 16px;
      display: inline-block;
    }

    .is-stu {
      width: 23px;
      height: 23px;
    }

    .is-manage {
      width: 16px;
      height: 16px;
      display: inline-block;
      margin-top: 2px;
    }

    .is-tiny {
      width: 16px;
      height: 16px;
      display: inline-block;
      margin-top: 2px;
    }
  }

  .icon-warp-stu{
    height: 56px;
    width: 56px;
  }

}
</style>
