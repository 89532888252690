<template>
  <div class="yxtbiz-ai-robot-click-jump color-gray-10">
    <span class="yxtbiz-ai-robot-click-jump__text">{{ message }}</span>
    <span v-if="showCountdown" class="ml8 yxtbiz-ai-robot-click-jump__text">{{timeInSecond}}s</span>
    <yxt-button v-if="showCountdown" @click="handleCancelTimerClick" class="ml8 yxtbiz-ai-robot-click-jump__button" type="text">{{$t('pc_biz_ai_btn_cancel' /* 取消 */)}}</yxt-button>
    <yxt-button v-else @click="goToTargetPage" class="ml8 yxtbiz-ai-robot-click-jump__button" type="text">{{$t('pc_biz_click_to_jump' /* 点击跳转 */)}}</yxt-button>
  </div> 
</template>

<script>
export default {
  props: {
    targetUrl: { // 目标页面地址
      type: String,
      required: true
    },
    message: { // 提示文案
      type: String,
      required: true
    },
    datas: {
      type: Object,
      default: () => ({isHistory: false}) // 是否为历史记录
    }
  },
  data() {
    this.timer = null; // 定时器
    return {
      timeInSecond: 5 // 倒计时初始时间 5s
    };
  },
  mounted() {
    !this.datas.isHistory && this.countdown();
  },
  computed: {
    showCountdown: ({datas, timeInSecond}) => !datas.isHistory && timeInSecond > 0
  },
  methods: {
    handleCancelTimerClick() {
      this.timeInSecond = -1;
      this.clearTimer();
    },
    countdown() {
      this.clearTimer();
      this.timer = setInterval(() => {
        this.timeInSecond--;
        if (this.timeInSecond === 0) {
          this.clearTimer();
          this.goToTargetPage();
        }
      }, 1000);
    },
    goToTargetPage() {
      this.$emit('open');
      window.open(this.targetUrl, '_blank');
    },
    clearTimer() {
      this.timer && clearInterval(this.timer);
      this.timer = null;
    }
  },
  beforeDestroy() {
    this.clearTimer();
  }
};
</script>

<style lang="scss" scoped>
.yxtbiz-ai-robot-click-jump {
  display: flex;
  align-items: center;
  height: 28px;
  padding: 0 12px;
}
</style>