<template>
  <yxt-select
    popper-class="yxtbiz-grade-select"
    v-model="selected"
    value-key="id"
    prop-label="name"
    :clearable="true"
    @clear="clear"
    :collapse-tags="true"
    multiple
    @change="change"
    @remove-tag="removeTag"
  >
    <div class="lh44" slot='fixed-header'>
      <yxt-checkbox v-model="isAll" @change='changeAll'>{{$t('pc_biz_udp_lbl_selectAll')}}</yxt-checkbox>
    </div>
    <yxt-option
      :key="index"
      :value="item"
      v-for="(item, index) in grades"
      :label="item.name"
      style="height:auto;max-height:200px;overflow:auto;"
    >
      <yxt-checkbox
        :change-text-color="true"
        @change="changeGrade($event, item)"
        :value="testChecked(item)"
      >
        {{ item.name }}
      </yxt-checkbox>
    </yxt-option>
  </yxt-select>
</template>

<script>
import { getGradeList } from '../service';
import { Select, Option } from 'yxt-pc';
export default {
  components: {
    YxtSelect: Select,
    YxtOption: Option
  },
  created() {
    getGradeList({ limit: 10000, offset: 0 }, this.enableGroupCorp).then(result => {
      this.grades = result.datas;
      this.setSelected();
    });
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    enableGroupCorp: {
      defalut: false
    }
  },
  data() {
    return {
      selected: [],
      grades: [],
      isAll: false
    };
  },
  methods: {
    setSelected() {
      let selected = [];
      if (this.value.length !== 0) {
        this.isAll = this.grades.every(item => {
          return this.value.find(id => item.id === id);
        });
        this.grades.forEach(item => {
          if (this.value.indexOf(item.id) !== -1) {
            selected.push(item);
          }
        });
      } else {
        this.isAll = false;
      }
      this.selected = selected;
    },
    change(items) {
      this.isAll = this.grades.every(item => {
        return items.find(node => item.id === node.id);
      });
      this.$emit(
        'input',
        items.map(item => item.id)
      );
    },
    changeAll(v) {
      this.isAll = v;
      this.checkAllStatus();
    },
    checkAllStatus() {
      if (this.isAll) {
        this.selected = [].concat(this.grades);
      } else {
        this.selected = [];
      }
      this.$emit(
        'input',
        this.selected.map(item => item.id)
      );
    },
    changeGrade(checked, item) {
      if (this.testChecked(item)) {
        this.selected = this.selected.filter(node => node.id !== item.id);
      } else {
        this.selected.push(item);
      }
      this.change(this.selected);

    },
    testChecked(item) {
      let index = this.selected.findIndex(node => {
        return node.id === item.id;
      });
      return index > -1;
    },
    removeTag(tag) {
      /* eslint-disable no-undef */
      let s = new Set(this.selected.map(item => item.id));
      s.delete(tag.id);
      this.$emit('input', Array.from(s));
    },
    clear() {
      this.$emit('input', []);
    }
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.setSelected();
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
