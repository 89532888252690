<template>
  <div class="more_position">
    <div class="table_wrapper">
      <div class="table_wrapper_search">
        <yxtbiz-group-org-select
          class="mr12"
          :functionCode="functionCode"
          :dataPermissionCode="dataPermissionCode"
          v-if="enableGroupCorp"
          v-show="visibleOrgSelector"
          @change="handleOrgChange"
          :allgroup="true"
          :disabled="disabled"
          :setCurrentOrg="true"
          :targetOrgId="targetOrgId || (strongManageMode ? 'all' : '')"
          :selectFirst="true"
          :clearable="false"
          :visibleOrgIds="visibleOrgIds"
        >
        </yxtbiz-group-org-select>
        <select-popover ref="selectPopover" :selectOpition="selectOpition">
          <div class="table_tree">
            <yxtbiz-pos-tree
              v-if="(enableGroupCorp && currentTargetOrgId) || !enableGroupCorp"
              :targetOrgId="currentTargetOrgId"
              :enableGroupCorp="enableGroupCorp"
              :placeholder="$t('biz_udp_pos_category')"
              size=""
              filterable
              ref="posTree"
              @nodeClick="nodeClick"
              :functionCode="functionCode"
              :dataPermissionCode="dataPermissionCode"
            >
            </yxtbiz-pos-tree>
          </div>
        </select-popover>
        <yxt-input
          class="keyword ml12"
          @keyup.enter.native="getPositionData(true)"
          v-model="params.name"
          :placeholder="$t('biz_udp_enter_pos_name')"
          style="width: 240px;"
          searchable
          @search="getPositionData(true)"
        >
        </yxt-input>
      </div>
      <yxt-table
        :height="tableHeight"
        ref="multipleTable"
        class="person-table"
        @select="handleSingleSeletctMiddle"
        @select-all="handleSelectAllMiddle"
        row-key="id"
        v-loading="loading"
        :data="tableData"
        style="width:100%;"
      >
        <yxt-table-column
          :show-overflow-tooltip="false"
          class-name="table-selection-odd"
          type="selection"
          width="40"
        >
        </yxt-table-column>
        <yxt-table-column
          prop="name"
          show-overflow-tooltip
          :label="$t('biz_udp_pos_name')"
          min-width="180"
        >
        </yxt-table-column>
        <yxt-table-column
          prop="gradeList"
          show-overflow-tooltip
          :label="$t('biz_udp_occupation_level')"
          min-width="180"
        >
          <template slot-scope="scope">
            <span>{{
              (scope.row.gradeList || []).map(item => item.name).join() || '--'
            }}</span>
          </template>
        </yxt-table-column>
        <yxt-table-column
          show-overflow-tooltip
          prop="catalogName"
          :label="$t('biz_udp_pos_category')"
          min-width="180"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.catalogName || $t('biz_udp_pos_system') }}</span>
          </template>
        </yxt-table-column>
        <template slot="empty">
          <div style="line-height:normal">
            <yxt-empty />
          </div>
        </template>
      </yxt-table>
      <div class="yxtbiz-page__content">
        <div class="yxtbiz-page__content-right">
          <yxt-button
            type="text"
            class="lh32"
            :class="loadingAll ? 'color-primary-4 disabled' : ''"
            @click="loadAll"
            :disabled="!tableData.length"
            >{{ $t('biz_udp_all_filter_results') }}</yxt-button
          >
          <div class="yxtbiz-loading__all" v-loading="loadingAll"></div>
        </div>
        <yxt-pagination
          small
          class="pages"
          :page-size="params.limit"
          :page-sizes="[5, 10, 20, 30, 50, 100]"
          @size-change="handleSizeChange"
          :current-page.sync="currentPage"
          layout="total, prev, pager, next"
          simple-total
          @current-change="handleCurrentChange"
          :total="total"
        >
        </yxt-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import resizeTable from '../../../user-selector/mixins/resizeTable';
import { getPositionList } from '../service';
import { getGroupPositions } from '../../../group-org-select/src/service';
import echoMixin from '../mixin/echo';
import authMixin from '../mixin/auth';
import SelectPopover from './SelectPopover';
import { Table, TableColumn, Pagination, Empty } from 'yxt-pc';
import { i18n } from 'yxt-biz-pc/packages/common-util/changeLangs';

export default {
  name: 'CheckPosition',
  mixins: [echoMixin, resizeTable, authMixin],
  components: {
    SelectPopover,
    YxtTable: Table,
    YxtTableColumn: TableColumn,
    YxtPagination: Pagination,
    YxtEmpty: Empty
  },
  props: {
    visibleOrgSelector: {
      type: Boolean,
      default: true
    },
    targetOrgId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loadingAll: false,
      refTable: 'multipleTable',
      tableHeight: 0,
      currentPage: 1,
      total: 0,
      loading: true,
      params: {
        name: '',
        catalogId: '',
        // fields: ['catalogId', 'id', 'name'],
        limit: 20,
        offset: 0
      },

      tableData: [],
      selectOpition: {
        placeholder: i18n.t('biz_udp_pos_select_category'),
        checkWord: ''
      },
      currentTargetOrgId: ''
    };
  },
  mounted() {
    if (this.targetOrgId) {
      this.currentTargetOrgId = this.params.targetOrgId = this.targetOrgId;
    }
    if (!this.enableGroupCorp) this.getPositionData();
  },
  computed: {
    masterOrgId(state) {
      return this.$store.state.navManageStore.groupCorpInfo.masterOrgId;
    },
    strongManageMode() {
      return this.$store.state.navManageStore.groupCorpInfo.manageMode === 2;
    }
  },
  methods: {
    handleSelectAllMiddle(...rest) {
      this.enableGroupCorp
        ? this.handleSelectAllGroup(...rest)
        : this.handleSelectAll(...rest);
    },
    handleSingleSeletctMiddle(...rest) {
      this.enableGroupCorp
        ? this.handleSingleSeletctGroup(...rest)
        : this.handleSingleSeletct(...rest);
    },
    async getAllGroupPos(selection) {
      if (this.currentTargetOrgId !== 'all') {
        const res = JSON.parse(JSON.stringify(selection));
        console.log(res);
        res.forEach(item => {
          if (this.enableGroupCorp && this.visibleOrgSelector) {
            item.combinationName = this.currentTargetOrgName + '-' + item.name;
            item.orgName = this.currentTargetOrgName;
            item.orgId = this.currentTargetOrgId;
          }
        });
        return res;
      }
      const ids = Array.isArray(selection)
        ? selection.map(v => v.id)
        : [selection.id];
      try {
        const { datas } = await getGroupPositions(ids);
        datas.forEach(item => {
          item.combinationName = item.orgName + '-' + item.name;
          item._type = 1;
        });
        return datas;
      } catch ({ error }) {
        console.log(error);
        this.$message.error(error.message);
        return [];
      }
    },
    // 全选与全不选
    async handleSelectAllGroup(selection) {
      let res = await this.getAllGroupPos(selection);
      let ids = this.list.map(item => item.id);
      if (selection.length) {
        res.map(item => {
          if (!ids.includes(item.id)) {
            this.list.push(item);
          }
        });
      } else {
        let selectedItems = JSON.parse(JSON.stringify(this.list));
        if (this.currentTargetOrgId !== 'all') {
          this.tableData.map(item => {
            if (ids.includes(item.id)) {
              selectedItems = selectedItems.filter(
                item2 => item2.id !== item.id
              );
            }
          });
        } else {
          res.map(item => {
            selectedItems = selectedItems.filter(item2 => item2.id !== item.id);
          });
        }
        this.$emit('update', selectedItems);
      }
    },
    async handleSingleSeletctGroup(selection, row) {
      console.log(selection, row, 'check-position-selection');
      let res = await this.getAllGroupPos([row]);
      let ids = this.list.map(item => item.id);
      let selectedItems = JSON.parse(JSON.stringify(this.list)); // 已经选中的元素
      console.log(this.list, '已选中元素');
      if (selection.map(ele => ele.id).includes(row.id)) {
        res.map(item => {
          if (!ids.includes(item.id)) {
            // 不存在就添加
            selectedItems.push(item);
          }
        });
      } else {
        res.map(item => {
          if (ids.includes(item.id)) {
            // 已经存在就删除掉该项
            selectedItems = selectedItems.filter(item2 => item2.id !== item.id);
          }
        });
      };
      console.log(selectedItems, 'CheckMorePosition');
      this.$emit('update', selectedItems);
    },
    handleOrgChange(data) {
      this.params.allgroup = data.allgroup;
      this.currentTargetOrgId = data.orgId;
      this.currentTargetOrgName = data.orgName;
      this.selectOpition.checkWord = '';
      if (data.orgId !== 'all') {
        this.params.targetOrgId = data.orgId;
        this.$delete(this.params, 'allgroup');
      } else {
        this.params.targetOrgId = '';
        this.params.allgroup = 1;
      }
      this.params.name = '';
      this.params.catalogId = '';
      this.getPositionData();
    },
    async loadAll() {
      this.loadingAll = true;
      const limit = 500;
      const reqCount = Math.ceil(this.total / limit); // 循环次数
      const reqArrs = []; // 请求数组
      for (let i = 0; i < reqCount; i++) {
        const params = Object.assign({}, this.params, {
          limit: limit,
          offset: limit * i
        });
        reqArrs.push(params);
      }
      try {
        const results = await Promise.all(
          reqArrs.map(params => getPositionList(params, this.enableGroupCorp))
        );
        const dataSource = [];
        results.forEach(res => {
          dataSource.push(...res.datas);
        });
        let data = dataSource.map(item => {
          item['_type'] = 1;
          if (this.enableGroupCorp && this.visibleOrgSelector) {
            item.combinationName = this.currentTargetOrgName + '-' + item.name;
            item.orgName = this.currentTargetOrgName;
            item.orgId = this.currentTargetOrgId;
          }
          return item;
        });
        this.$refs['multipleTable'].clearSelection();
        this.$refs['multipleTable'].toggleAllSelection();
        // 在全集团下拉选项下，点击全选时，不走此逻辑
        if (this.currentTargetOrgId !== 'all') this.selectTotal(data);
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingAll = false;
      }
    },
    nodeClick(a) {
      this.params.catalogId = a.id;
      this.selectOpition.checkWord = a.id ? a.name : '';
      this.$refs['selectPopover'].cancel();
      this.getPositionData(true);
    },
    getPositionData(reset = false) {
      this.loading = true;
      if (reset) {
        this.params.offset = 0;
        this.currentPage = 1;
      }
      this.params.dataPermissionCode = this.dataPermissionCode;
      this.params.navCode = this.functionCode;
      getPositionList(this.params, this.enableGroupCorp)
        .then(res => {
          if (res) {
            this.tableData = res.datas.map(item => {
              item['_type'] = 1;
              return item;
            });
            this.total = res.paging.count;
            this.update();
          }
        })
        .catch(e => {
          console.error(e);
        })
        .then(() => {
          this.loading = false;
        });
    },
    handleCurrentChange(val) {
      this.params.offset = (val - 1) * this.params.limit;
      this.getPositionData();
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.params.limit = val;
      this.getPositionData();
    }
  }
};
</script>
