<template>
  <div @click="evt => $emit('click',evt)" class="yxtbiz-ai-robot-view-more">
    <div class="yxtbiz-ai-robot-view-more__text">{{$t('pc_biz_ote_lbl_viewmore' /* 查看更多 */)}}</div>
    <yxt-svg width="16px" height="16px" icon-class="arrow_right" />
  </div> 
</template>
<script>
  export default {};
</script>
<style lang="scss" scoped>
.yxtbiz-ai-robot-view-more {
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: #757575;
  cursor: pointer;
  user-select: none;

  &:hover {
    background: #F4F5F6;
  }
  
  &__text {
    font-size: 14px;
    line-height: 22px;
    margin-right: 4px;
  }
}
</style>