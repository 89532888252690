<template>
  <div class="yxtbiz-nav-main__stu-left" v-if="currentNav && currentNav.subMenuInfoList.length > 0">
    <div class="yxtbiz-nav-left-stu">
      <div class="yxtbiz-nav-left-stu__title" :title="cnavs[0]|Globalize('name')">
        <h1>{{cnavs[0]|Globalize('name')}}</h1>
      </div>
      <yxtf-divider></yxtf-divider>
      <ul class="yxtbiz-nav-left-stu__navs">
        <li class="hover-primary-6-i" :class="{ 'color-primary-6-i': isMenuSelected(menu) }" @click="toPage(menu)"
          v-for="menu in currentNav.subMenuInfoList"
          :name="menu.id"
          :index="menu.code"
          :key="menu.id"
          v-show="menu.showed">
          <a-link :url="menu.pageUrl" :page-node="menu">{{menu|Globalize('name')}}</a-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { Globalize, goSubNavPage} from './common';
import { FrontDivider } from 'yxt-pc';
import ALink from './alink.vue';
export default {
  name: 'YxtbizNavLeftStu',
  components: {
    YxtfFrontDivider: FrontDivider,
    ALink
  },
  data() {
    return {
      menusShow: [],
      menuHover: {}
    };
  },
  props: {
    mainPathname: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState({
      collapse: state => state.navManageStore.collapse
    }),
    // 获取二级导航列表
    currentNav() {
      let menusInfo = this.$store.state.navManageStore.navs.enableAppMenus.filter(menu => {
        return menu.code === this.pageCodes[0];
      })[0];
      if (!this.located) {
        this.$nextTick(() => {
          // 初次定位导航
          if (this.cnavs && this.cnavs[1] && this.$refs[this.cnavs[1].code]) {
            this.$refs[this.cnavs[1].code][0].scrollIntoView();
            this.menusShow.push(this.cnavs[1].id);
            this.located = true;
          }
        });
      }
      return menusInfo;
    },
    // 当前导航数组
    cnavs() {
      return this.$store.state.navManageStore.cnavs;
    },
    // 当前层及编号数组
    pageCodes() {
      return this.$store.state.navManageStore.pageCodes;
    },
    // 当前导航信息
    cnav() {
      return this.$store.state.navManageStore.nav;
    }
  },
  watch: {
    cnavs(val, valold) {
      if (val !== valold && val.length >= 1 && val[1] && this.menusShow.indexOf(val[1].id) < 0) {
        this.menusShow.push(val[1].id);
      }
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    ...mapActions('navManageStore', ['setCollapse']),
    toPage(page) {
      this.drawer = false;
      // goNavPage(page, this.mainPathname, this);
      goSubNavPage(page, this.mainPathname, this);
    },
    isMenuSelected(menu, index = 1) {
      return this.pageCodes[index] && this.pageCodes[index] === menu.code;
    }
  },
  beforeDestroy() {
  },
  filters: {
    Globalize(data, key) {
      return Globalize(data, key);
    }
  }
};
</script>