var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.enableGroupCorp && _vm.orgList.length > 1
    ? _c(
        "yxtf-dropdown-item",
        { staticStyle: { "padding-right": "8px" } },
        [
          _c(
            "yxtf-dropdown",
            _vm._b(
              { attrs: { placement: "left-start", "append-to-body": false } },
              "yxtf-dropdown",
              _vm.$attrs,
              false
            ),
            [
              _c("span", { staticClass: "ell", attrs: { type: "text" } }, [
                _vm._v("\n        " + _vm._s(_vm.orgName) + "\n      "),
              ]),
              _c(
                "yxtf-dropdown-menu",
                {
                  attrs: {
                    slot: "dropdown",
                    "append-to-body": false,
                    popperOptions: {
                      modifiers: [
                        "shift",
                        "offset",
                        "keepTogether",
                        "arrow",
                        "flip",
                        "applyStyle",
                      ],
                      gpuAcceleration: false,
                    },
                  },
                  slot: "dropdown",
                },
                _vm._l(_vm.orgList, function (item) {
                  return _c(
                    "yxtf-dropdown-item",
                    {
                      key: item.orgId,
                      class: [
                        "yxtbiz-nav-lang__default-lang",
                        {
                          "yxtbiz-nav-lang__active-langs":
                            _vm.activeId == item.orgId,
                        },
                      ],
                      attrs: { command: item.orgId },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.handleChange(item)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(item.orgName) + "\n          "
                      ),
                      _vm.activeId == item.orgId
                        ? _c("i", { staticClass: "yxtf-icon-check" })
                        : _vm._e(),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }