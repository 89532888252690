var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.pageUrl
    ? _c(
        "a",
        {
          staticClass: "init-a-style",
          style: { display: _vm.mode },
          attrs: { href: _vm.pageUrl },
          on: { click: (e) => e.preventDefault() },
        },
        [_vm._t("default")],
        2
      )
    : _c("span", [_vm._t("default")], 2)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }