var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "standard-size-14 yxtbiz-ai-robot__coure" },
    [
      _vm.dataList.length === 0
        ? _c("EmptyData")
        : [
            _c(
              "div",
              { staticClass: "search-community-list" },
              [
                _c(
                  "div",
                  {
                    staticClass: "search-community-list-tips-info",
                    class: { "font-size-16": _vm.size === "large" },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm
                            .$t("pc_biz_search_community_content")
                            .d("为您找到以下社区内容：")
                        ) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._l(_vm.dataList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "search-community-list-item",
                      on: {
                        click: function ($event) {
                          return _vm.toDetail(item)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "search-community-list-item-title ellipsis-2",
                          class: {
                            "community-large-title": _vm.size === "large",
                          },
                        },
                        [_vm._v(_vm._s(item.title))]
                      ),
                      _c("div", {
                        staticClass:
                          "search-community-list-item-content ellipsis",
                        domProps: { innerHTML: _vm._s(item.content) },
                      }),
                      _c(
                        "div",
                        { staticClass: "search-community-list-item-footer" },
                        [
                          _c("div", { staticClass: "label ellipsis" }, [
                            _vm._v(
                              _vm._s(
                                `${
                                  item.boardName
                                    ? _vm
                                        .$t("pc_biz_publish_lbl_board")
                                        .d("版块") +
                                      ": " +
                                      item.boardName
                                    : ""
                                }`
                              )
                            ),
                          ]),
                          item.boardName
                            ? _c("div", { staticClass: "divider" })
                            : _vm._e(),
                          _c("div", { staticClass: "like" }, [
                            _vm._v(
                              _vm._s(item.praiseCount) +
                                " " +
                                _vm._s(_vm.$t("pc_biz_lbl_praiseCount").d("赞"))
                            ),
                          ]),
                          _c("div", { staticClass: "reply" }, [
                            _vm._v(
                              _vm._s(item.replyCount) +
                                " " +
                                _vm._s(_vm.$t("pc_biz_lbl_reply").d("回复"))
                            ),
                          ]),
                        ]
                      ),
                    ]
                  )
                }),
              ],
              2
            ),
            _vm.needMore
              ? _c(
                  "div",
                  {
                    staticClass: "yxtbiz-ai-robot__coure-more color-gray-8",
                    on: { click: _vm.toMore },
                  },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$t("pc_biz_lbl_common_viewmore")) +
                        "\n      "
                    ),
                    _c("yxt-svg", {
                      attrs: {
                        "remote-url": `${_vm.$staticBaseUrl}ufd/55a3e0`,
                        width: "16px",
                        height: "16px",
                        "icon-class": "arrow_right",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }