<template>
  <base-list
    :dataList="datas"
    :size="size"
    :tagLists="tagLists"
    searchName="pc_biz_ote_lbl_quesbank"
    listId="quesId"
    @to-list="toList"
    @to-more="toListMore">
    <!-- slot插槽处理 -->
    <template slot-scope="{ list }">
      <!-- 试题数：{0} -->
      <span>{{ $t('pc_biz_ote_lbl_ques_number', [list.totalQuesQty]) }}</span>
      <!-- 创建人 -->
      <span class="ml20">{{ list.createFullname || '--' }}</span>
      <yxt-divider direction="vertical"></yxt-divider>
      <!-- {0} 更新 -->
      <span>{{ $t('pc_kng_common_lbl_update_time', [list.updateTime]) }}</span>
    </template>
  </base-list>
</template>

<script>
import CommonMixin from '../common/props-mixin';
import utils from 'yxt-biz-pc/packages/common-util/utils.js';
import BaseList from '../search-exam/base-list.vue';

export default {
  name: 'SearchLib',
  mixins: [CommonMixin],
  components: {
    BaseList
  },

  data() {
    const tagLists = {
      0: {
        label: 'pc_biz_cer_disable', // 禁用
        type: 'danger'
      }
    };

    return {
      tagLists
    };
  },

  methods: {
    getDateTimeFormatted: utils.getDateTimeFormatted,
    toList(list) {
      window.open(`${window.location.origin}/ote/#/queslib/quesmgmt/detail?id=${list.id}`, '_blank');
    },
    toListMore() {
      localStorage['ai-search-ques'] = JSON.stringify(this.params);
      console.log(this.params);
      window.open(`${window.location.origin}/ote/#/queslib/mgmt`, '_blank');
    }
  }
};
</script>
