var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yxtbiz-extend-field-selector-wrap" },
    [
      _c(
        "yxt-row",
        {
          staticClass: "yxt-user-selector-container yxt-user-multi-container",
          attrs: { type: "flex" },
        },
        [
          _c(
            "div",
            { staticClass: "yxtbiz-user-search-wrap mb16" },
            [
              _c(
                "yxt-select",
                {
                  attrs: { size: "small" },
                  on: { change: _vm.spareChange },
                  model: {
                    value: _vm.selectSpare,
                    callback: function ($$v) {
                      _vm.selectSpare = $$v
                    },
                    expression: "selectSpare",
                  },
                },
                _vm._l(_vm.spares, function (item) {
                  return _c("yxt-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
              _c(
                "div",
                { staticStyle: { width: "200px", "margin-left": "20px" } },
                [
                  _c("yxt-input", {
                    attrs: {
                      size: "small",
                      clearable: "",
                      searchable: "",
                      placeholder: _vm
                        .$t("pc_biz_placehoder_optionname")
                        .d("输入选项名称"),
                    },
                    on: { search: _vm.search },
                    model: {
                      value: _vm.keyword,
                      callback: function ($$v) {
                        _vm.keyword = $$v
                      },
                      expression: "keyword",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "yxt-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              key: `spares-${_vm.selectSpare}`,
              ref: _vm.refTable,
              staticClass: "yxt-user-table",
              attrs: {
                height: _vm.tableHeight,
                data: _vm.sparesOptions,
                "row-key": "id",
              },
              on: {
                "select-all": _vm.selectAllOption,
                select: _vm.selectOption,
              },
            },
            [
              _c("template", { slot: "empty" }, [
                _c(
                  "div",
                  { staticStyle: { "line-height": "normal" } },
                  [_c("yxt-empty")],
                  1
                ),
              ]),
              _vm.multiple
                ? _c("yxt-table-column", {
                    attrs: {
                      "show-overflow-tooltip": false,
                      "class-name": "yxtbiz-table-selection",
                      type: "selection",
                      width: "42",
                      "reserve-selection": "",
                      "clear-padding": "right",
                    },
                  })
                : _c("yxt-table-column", {
                    attrs: { "show-overflow-tooltip": false, width: "40" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("yxt-radio", {
                              attrs: { label: scope.row.id },
                              on: {
                                change: function ($event) {
                                  return _vm.selectOption(
                                    [scope.row],
                                    scope.row
                                  )
                                },
                              },
                              model: {
                                value: _vm.selectSparesingleOption,
                                callback: function ($$v) {
                                  _vm.selectSparesingleOption = $$v
                                },
                                expression: "selectSparesingleOption",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
              _c("yxt-table-column", {
                attrs: {
                  prop: "name",
                  label: _vm.$t("pc_biz_optionname").d("选项名称"),
                  "show-overflow-tooltip": true,
                },
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }