<template>
  <base-list
    :dataList="datas"
    :size="size"
    :tagLists="tagLists"
    tagType="userStatus"
    listId="arrId"
    listName="arrName"
    @to-list="toList"
    @to-more="toListMore">
    <!-- slot插槽处理 -->
    <template slot-scope="{ list }">
      <span>{{ $t('pc_biz_ote_lbl_minutes' /* 时间 */) }}：{{ getDateTimeFormatted({startTime: list.entryStartTime, endTime: list.entryEndTime}) }}</span>
    </template>
  </base-list>
</template>

<script>
import CommonMixin from '../common/props-mixin';
import utils from 'yxt-biz-pc/packages/common-util/utils.js';
import BaseList from './base-list';

export default {
  name: 'SearchExam',
  mixins: [CommonMixin],
  components: {
    BaseList
  },
  data() {
    const tagLists = {
      0: {
        label: 'pc_biz_ote_lbl_pendingexam', // 待考试
        type: 'info'
      },
      1: {
        label: 'pc_biz_ote_lbl_testing', // 考试中
        type: ''
      },
      2: {
        label: 'pc_biz_ote_lbl_marking', // 批阅中
        type: 'warning'
      },
      3: {
        label: 'pc_biz_shoptour_lbl_finished', // 已完成
        type: 'success'
      },
      4: {
        label: 'pc_biz_cer_lbl_expired', // 已过期
        type: 'info'
      }
    };

    return {
      tagLists
    };
  },

  methods: {
    getDateTimeFormatted: utils.getDateTimeFormatted,
    toList(list) {
      window.open(`${window.location.origin}/ote/#/stu/exampreview?arrangeId=${list.arrId}`, '_blank');
    },
    toListMore() {
      localStorage['ai-search-exam'] = JSON.stringify(this.params);
      console.log(this.params);
      window.open(`${window.location.origin}/ote/#/stu/myexam`, '_blank');
    }
  }
};
</script>
