<template>
  <div>
    <yxt-row type='flex' class="yxt-user-selector-container yxt-user-multi-container">
      <div class="yxtbiz-user-search-wrap">
        <yxtbiz-group-org-select 
          :functionCode="functionCode"
          :disabled="disabled"
          :setCurrentOrg="true"
          :targetOrgId="targetOrgId"
          :dataPermissionCode="dataPermissionCode"
             @change="handleOrgChange" :selectFirst='true' :clearable="false" v-if='enableGroupCorp' :visibleOrgIds="visibleOrgIds"></yxtbiz-group-org-select>
          <select-popover class='ml12' ref="selectPopover" :selectOpition="selectOpition" >
          <div class="table_tree" >
            <user-group-tree
              v-if='(enableGroupCorp && postData.targetOrgId) || !enableGroupCorp'
              :targetOrgId='postData.targetOrgId'
              :enableGroupCorp='enableGroupCorp'
            :functionCode="functionCode"
            :dataPermissionCode="dataPermissionCode"
            @nodeClick="selectGroupCatalog"
            :filterable="true"
            ref="userGroupTree"
            ></user-group-tree>
          </div>
        </select-popover>
        <yxt-input
          :placeholder="$t('pc_biz_udp_tip_searchGroup')"
          v-model="groupValue"
          style="width:240px"
          class="ml12"
          searchable
          @search="searchGroup"
        >
        </yxt-input>
      </div>
        <yxt-table
          ref="groupTable"
          @select-all="selectAllGroups"
          @select="selectGroup"
          v-loading="loading"
          :height="tableHeight"
          class="mt16 yxt-user-table"
          :data="usergroups"
        >
          <template slot="empty">
            <div style="line-height:normal">
              <yxt-empty />
            </div>
          </template>
          <yxt-table-column
            :show-overflow-tooltip="false"
            class-name="yxtbiz-table-selection"
            type="selection"
            width=40
          ></yxt-table-column>
          <yxt-table-column :show-overflow-tooltip="true" :label="$t('biz_udp_usergroup')" prop="name">
            <template slot-scope="scope">
              <span>{{scope.row.name}}</span>
              <yxt-tag
                v-if="scope.row.userGroupType === 1"
                class="ml8"
                effect="dark"
                size="mini">
                {{$t('pc_biz_ls_group').d('集团')}}
              </yxt-tag>
            </template>
          </yxt-table-column>
          <yxt-table-column
            :label="$t('pc_biz_udp_lbl_description')"
            :show-overflow-tooltip="true"
            prop="remark"
            :formatter="formatRemark"
          ></yxt-table-column>
          <!-- <yxt-table-column
            :label="$t('pc_biz_udp_lbl_userCount')"
            :show-overflow-tooltip="true"
            align="right"
            prop="userCount"
          ></yxt-table-column> -->
        </yxt-table>
        <yxt-row type='flex' align='middle' class="yxtbiz-user-selector-mask mt16">
          <yxt-row class="col-flex-1" type='flex' align='middle'>
            <yxt-button
              type="text"
              class="lh32 yxtbiz-btn-search-all"
              :class="isSearchAll ? 'color-primary-4 disabled' : ''"
              :disabled="usergroups.length === 0"
              @click="searchAll"
              >{{$t('biz_udp_all_filter_results')}}</yxt-button
            ><div class="yxtbiz-loading__all" v-loading="isSearchAll"></div>
          </yxt-row>
          <yxt-pagination
            @size-change="sizeChange"
            @current-change="pageChange"
            :current-page.sync="page"
            :page-size="postData.limit"
            simple-total
            layout="total, prev, pager, next"
            :total="count"
            :pager-count="5"
          ></yxt-pagination>
        </yxt-row>
    </yxt-row>
  </div>
</template>

<script>
import { getUserGroupList } from '../service';
import userGroupTree from '../../../user-group-tree';
import resizeTable from '../../mixins/resizeTable';
import SelectPopover from '../../../check-person-range/src/components/SelectPopover';
import { Table, TableColumn, Pagination, Empty } from 'yxt-pc';
import { i18n } from 'yxt-biz-pc/packages/common-util/changeLangs';
import groupMixin from '../../mixins/group';
import { enmuFunctions } from '../../../function-points';
import { getFunStatus } from '../../../common-util/factorUtils';
export default {
  components: {
    userGroupTree,
    SelectPopover,
    YxtTable: Table,
    YxtTableColumn: TableColumn,
    YxtPagination: Pagination,
    YxtEmpty: Empty
  },
  mixins: [resizeTable, groupMixin],
  props: {
    value: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'userGroup'
    },
    scope: {
      type: Number
    },
    functionCode: {
      type: String,
      default: ''
    },
    dataPermissionCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      refTable: 'groupTable',
      isSearchAll: false,
      postData: {
        catalogId: '',
        scope: this.scope === -1 ? 2 : 0,
        name: '',
        limit: 20,
        offset: 0
      },
      selectOpition: {
        placeholder: i18n.t('biz_udp_usergroup_select'),
        checkWord: ''
      },
      count: 0,
      tableHeight: 0,
      groupValue: '',
      selectedGroups: {},
      usergroups: [],
      loading: true,
      totalPage: 0,
      page: 1,
      formatRemark: row => {
        return row.remark || '--';
      }
    };
  },
  created() {
    if (!this.enableGroupCorp || this.targetOrgId) {
      if (this.targetOrgId) this.postData.targetOrgId = this.targetOrgId;
      this.getData();
    }
    this.formatGroups(this.value);
  },
  computed: {
    showRelation() {
      // 是否显示动态用户组
      return getFunStatus(enmuFunctions.UDP_DYNAMIC_USERGROUP).enabled;
    }
  },
  methods: {
    handleOrgChange(data) {
      this.targetOrgName = data.orgName;
      this.postData.targetOrgId = data.orgId;
      this.postData.catalogId = '';
      this.selectOpition.checkWord = '';
      this.groupValue = '';
      this.$emit('org-change', data);
      this.getData();
    },
    formatGroups(groups) {
      this.selectedGroups = {};
      groups.forEach(group => {
        this.selectedGroups[group.id] = group;
      });
    },
    getData() {
      this.loading = true;
      const type = this.showRelation ? -1 : 0; // -1 查询普通与动态用户组，0 只查询普通用户组
      getUserGroupList(this.postData, type)
        .then(result => {
          this.usergroups = result.datas;
          this.usergroups.forEach(group => {
            group.nodeType = this.type;
          });
          this.totalPage = result.paging.pages;
          this.count = result.paging.count;
          this.loading = false;
          this.$nextTick(() => {
            this.checkStatus();
          });
        })
        .catch(e => {
          this.usergroups = [];
          this.loading = false;
        });
    },
    searchAll() {
      let postData = Object.assign({}, this.postData, {
        limit: this.count,
        offset: 0
      });
      this.isSearchAll = true;
      const type = this.showRelation ? -1 : 0; // -1 查询普通与动态用户组，0 只查询普通用户组
      getUserGroupList(postData, type)
        .then(result => {
          this.isSearchAll = false;
          const list = result.datas;
          list.forEach(group => {
            group.nodeType = this.type;
            group.orgId = this.postData.targetOrgId;
            group.orgName = this.targetOrgName;
            this.selectedGroups[group.id] = group;
          });

          this.updateGroups(list, true);
        })
        .catch(e => {
          this.isSearchAll = false;
          this.$message({
            type: 'error',
            message: e
          });
        });
    },
    searchGroup() {
      this.postData.name = this.groupValue;
      this.postData.offset = 0;
      this.page = 1;
      this.getData();
    },
    selectGroup(item, row) {
      let isAdd = item.some(v => {
        return v.id === row.id;
      });
      if (item.length === 0) {
        isAdd = false;
        delete this.selectedGroups[row.id];
      } else {
        this.selectedGroups[row.id] = row;
      }
      row.orgName = this.targetOrgName;
      row.orgId = this.postData.targetOrgId;
      this.updateGroups(row, isAdd);
    },
    selectAllGroups(items) {
      let isAdd = items.length !== 0;
      this.usergroups.forEach(group => {
        if (!isAdd) {
          delete this.selectedGroups[group.id];
        } else {
          this.selectedGroups[group.id] = group;
        }
        group.orgName = this.targetOrgName;
        group.orgId = this.postData.targetOrgId;
      });

      this.updateGroups(this.usergroups, isAdd);
    },
    updateGroups(items, isAdd) {
      this.$emit('input', Object.values(this.selectedGroups));
      this.$emit('update', this.type, items, isAdd);
    },
    sizeChange(size) {
      this.postData.limit = size;
      this.page = 1;
      this.postData.offset = 0;
      this.getData();
    },
    pageChange(page) {
      this.page = page;
      this.postData.offset = (page - 1) * this.postData.limit;
      this.getData();
    },
    checkStatus() {
      this.usergroups.forEach(group => {
        if (this.selectedGroups.hasOwnProperty(group.id)) {
          this.$refs['groupTable'].toggleRowSelection(group, true);
        } else {
          this.$refs['groupTable'].toggleRowSelection(group, false);
        }
      });
    },
    selectGroupCatalog(item) {
      this.postData.catalogId = item.id;
      this.postData.offset = 0;
      this.page = 1;
      this.selectOpition.checkWord = item.id ? item.name : '';
      this.$refs['selectPopover'].cancel();
      this.getData();
    }
  },
  watch: {
    value(v) {
      this.formatGroups(v);
      this.checkStatus();
    }
  }
};
</script>

<style lang="scss" scoped></style>
