<template>
    <div class="yxtbiz-ai-robot-ssp-text standard-size-14 ph12" :class="{'standard-size-16': size === 'large'}">
      {{ $t('pc_biz_ssp_lbl_you').d('您') }}{{ timeTypeMapping[datas.timeRange] }}{{ $t('pc_biz_ssp_lbl_acheive').d('获得') }}  <span style="color: orange">{{ datas.objValue }}</span>  {{ objTypeMapping[datas.objType] }}
    </div>
</template>


<script>
import CommonMixin from '../common/props-mixin';
export default {
  name: 'SspText',
  mixins: [CommonMixin],
  data() {
    return {
      timeTypeMapping: {
        '0': this.$t('pc_biz_ssp_lbl_total').d('总共'),
        '1': this.$t('pc_biz_ssp_lbl_today').d('今天'),
        '2': this.$t('pc_biz_ssp_lbl_this_week').d('本周'),
        '3': this.$t('pc_biz_ssp_lbl_this_month').d('本月'),
        '4': this.$t('pc_biz_ssp_lbl_this_quarter').d('本季度'),
        '5': this.$t('pc_biz_ssp_lbl_this_year').d('本年')
      },
      objTypeMapping: {
        '0': this.$t('pc_biz_ssp_lbl_study_time').d('学时'),
        '1': this.$t('pc_biz_ssp_lbl_study_score').d('学分'),
        '2': this.$t('pc_biz_ssp_lbl_study_point').d('积分')
      }
    };
  }
};
</script>

<style lang="scss" scoped>

</style>