<template>
  <div class="yxtbiz-msg-editor">
    <yxt-scrollbar style="height: 100%">
      <div class="yxtbiz-msg-editor__body">
        <div class=" yxtbiz-msg-editor__title color-gray-10 text-center">
          {{ title }}
        </div>
        <yxt-form
          :rules="rules"
          ref="ruleForm"
          :model="ruleForm"
          class="yxtbiz-msg-editor__form"
          label-width="162px"
        >
          <yxt-form-item prop="name" :label="$t('pc_biz_core_lbl_name')">
            <yxt-input
              maxLength="50"
              v-model="ruleForm.name"
              :placeholder="$t('pc_biz_core_tip_enterName')"
            ></yxt-input>
          </yxt-form-item>
          <yxt-form-item
            prop="mobile"
            :label="$t('pc_biz_core_lbl_phoneNumber')"
          >
            <yxt-input
              maxLength="11"
              v-model="ruleForm.mobile"
              :placeholder="$t('pc_biz_core_tip_enterPhoneNumber')"
            ></yxt-input>
          </yxt-form-item>
          <yxt-form-item prop="captch" :label="$t('pc_biz_core_lbl_captch')">
            <yxt-input
              maxLength="10"
              v-model="ruleForm.captch"
              :placeholder="$t('pc_biz_core_tip_enterCaptch')"
            >
              <span slot="suffix" class="font-size-16  mr10">
                <yxtbiz-captcha
                  @open="openCaptch"
                  @success="toSuccess"
                  v-if="!reget && hasMobile"
                  class="hand"
                >
                  <yxt-link
                    :underline="false"
                    class="font-size-14"
                    type="primary"
                    >{{ $t('pc_biz_core_btn_getCaptch') }}</yxt-link
                  >
                </yxtbiz-captcha>
                <span
                  v-if="!reget && !hasMobile"
                  class="color-gray-6 font-size-14"
                  >{{ $t('pc_biz_core_btn_getCaptch') }}</span
                >
                <span v-if="reget" class="color-gray-6 font-size-14">
                  {{ timeCountText }}s
                </span>
              </span>
            </yxt-input>
          </yxt-form-item>
          <yxt-form-item
            prop="city"
            v-if="checkStatus('city')"
            :label="$t('pc_biz_core_lbl_city')"
          >
            <yxtbiz-area-select
              :placeholder="$t('pc_biz_core_lbl_city')"
              v-model="ruleForm.city"
              @change="changeCity"
            ></yxtbiz-area-select>
          </yxt-form-item>
          <yxt-form-item
            prop="company"
            v-if="checkStatus('company')"
            :label="$t('pc_biz_core_lbl_company')"
          >
            <yxt-input
              maxLength="50"
              v-model="ruleForm.company"
              :placeholder="$t('pc_biz_core_tip_enterCompany')"
            ></yxt-input>
          </yxt-form-item>
          <yxt-form-item
            prop="department"
            v-if="checkStatus('department')"
            :label="$t('pc_biz_core_lbl_department')"
          >
            <yxt-input
              maxLength="50"
              v-model="ruleForm.department"
              :placeholder="$t('pc_biz_core_tip_enterDepartment')"
            ></yxt-input>
          </yxt-form-item>
          <yxt-form-item
            prop="position"
            v-if="checkStatus('position')"
            :label="$t('pc_biz_core_lbl_position')"
          >
            <yxt-input
              maxLength="50"
              v-model="ruleForm.position"
              :placeholder="$t('pc_biz_core_tip_enterPosition')"
            ></yxt-input>
          </yxt-form-item>
          <yxt-form-item
            prop="userNo"
            v-if="checkStatus('userNo')"
            :label="$t('pc_biz_core_lbl_userNo')"
          >
            <yxt-input
              maxLength="50"
              v-model="ruleForm.userNo"
              placeholder="$t('pc_biz_core_tip_enterUserNo')"
            ></yxt-input>
          </yxt-form-item>
          <yxt-form-item
            prop="email"
            v-if="checkStatus('email')"
            :label="$t('pc_biz_core_lbl_mail')"
          >
            <yxt-input
              maxLength="50"
              v-model="ruleForm.email"
              :placeholder="$t('pc_biz_core_tip_enterMail')"
            ></yxt-input>
          </yxt-form-item>
          <yxt-form-item
            prop="identitycard"
            v-if="checkStatus('identitycard')"
            :label="$t('pc_biz_core_lbl_identitycard')"
          >
            <yxt-input
              maxLength="50"
              v-model="ruleForm.identitycard"
              :placeholder="$t('pc_biz_core_tip_enterIdentitycard')"
            ></yxt-input>
          </yxt-form-item>
          <yxt-form-item
            prop="sex"
            v-if="checkStatus('sex')"
            :label="$t('pc_biz_core_lbl_gender')"
          >
            <yxt-radio-group v-model="ruleForm.sex">
              <yxt-radio label="2">{{
                $t('pc_biz_core_lbl_female')
              }}</yxt-radio>
              <yxt-radio label="1">{{ $t('pc_biz_core_lbl_male') }}</yxt-radio>
            </yxt-radio-group>
          </yxt-form-item>
          <yxt-form-item
            prop="leavingmsg"
            v-if="checkStatus('leavingmsg')"
            :label="$t('pc_biz_core_lbl_leavingmsg')"
          >
            <yxt-input
              maxLength="200"
              v-model="ruleForm.leavingmsg"
              type="textarea"
              :placeholder="$t('pc_biz_core_tip_enterLeavingmsg')"
            >
            </yxt-input>
          </yxt-form-item>
          <yxt-form-item
            prop="exam"
            v-if="checkStatus('exam')"
            :label="$t('pc_biz_core_lbl_examPwd')"
          >
            <yxt-input
              maxLength="10"
              v-model="ruleForm.exam"
              :placeholder="$t('pc_biz_core_tip_enterExamPwd')"
            >
            </yxt-input>
          </yxt-form-item>
          <yxt-form-item style="margin-top: 40px;">
            <div class="text-center" style="line-height: normal;">
              <yxt-button type="primary" @click="confirm">{{
                $t('pc_biz_core_btn_determine')
              }}</yxt-button>
            </div>
          </yxt-form-item>
        </yxt-form>
      </div>
    </yxt-scrollbar>
  </div>
</template>

<script>
import { getMobileCaptch, postAnonyLogin } from './service';
import YxtbizCaptcha from 'yxt-biz-pc/packages/captcha';
import YxtbizAreaSelect from 'yxt-biz-pc/packages/area-select';
import { Scrollbar, Form, FormItem, RadioGroup, Radio, Link } from 'yxt-pc';
const timeCount = 60; // 倒数计时
export default {
  name: 'YxtbizMsgEditor',
  components: {
    YxtbizAreaSelect,
    YxtbizCaptcha,
    YxtScrollbar: Scrollbar,
    YxtForm: Form,
    YxtFormItem: FormItem,
    YxtRadioGroup: RadioGroup,
    YxtRadio: Radio,
    YxtLink: Link
  },
  props: {
    title: {
      default: '',
      type: String
    },
    orgId: {
      required: true
    },
    domain: {
      required: true
    },
    data: {
      type: Object,
      default() {
        return {
          required: ['name', 'mobile', 'captch']
        };
      }
    }
  },
  data() {
    return {
      list: [],
      ticket: '',
      randstr: '',
      timeCount: timeCount, // 倒计时
      reget: false, // 是否显示重新获取验证码
      ruleForm: {
        name: '',
        mobile: '',
        captch: '',
        userNo: '',
        sex: '2',
        email: '',
        company: '',
        department: '',
        position: '',
        identitycard: '',
        leavingmsg: '',
        city: [],
        cityObj: {},
        exam: ''
      },
      rules: {
        name: [
          {
            required: true,
            message: this.$t('pc_biz_core_msg_enterName'),
            trigger: 'blur'
          }
        ],
        mobile: [
          {
            required: true,
            message: this.$t('pc_biz_core_msg_enterPhoneNumber'),
            trigger: 'blur'
          }
        ],
        captch: [
          {
            required: true,
            message: this.$t('pc_biz_core_msg_enterCaptch'),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  computed: {
    requiredList() {
      let list = this.data.required || [];
      /* eslint-disable no-undef */
      list = new Set(list)
        .add('name')
        .add('mobile')
        .add('captch');
      return Array.from(list);
    },
    optionalList() {
      return this.data.optional ? this.data.optional : [];
    },
    hasMobile() {
      return /^\d{11}$/.test(this.ruleForm.mobile);
    },
    timeCountText() {
      return this.$t('pc_biz_core_tip_regetTime', [this.timeCount]);
    }
  },
  methods: {
    confirm() {
      let result = {};
      [].concat(this.requiredList, this.optionalList).forEach(key => {
        if (key === 'city') {
          result[key] = this.cityObj;
        } else {
          result[key] = this.ruleForm[key];
        }
      });

      result.ticket = this.ticket;
      result.randstr = this.randstr;
      this.$refs['ruleForm']
        .validate()
        .then(() => {
          return postAnonyLogin({
            code: result['captch'],
            domain: this.domain,
            orgId: this.orgId,
            phone: result.mobile
          });
        })
        .then(res => {
          result.token = res.token;
          window.localStorage.setItem('visitorToken', res.token);
          this.$emit('getData', result);
        })
        .catch(e => {
          if (e) {
            if (e.error && e.error.key === 'apis.core.login.sms.error') {
              this.$message(this.$t('pc_biz_core_msg_captchError'));
            } else if (
              e.error &&
              e.error.key === 'apis.core.login.sms.timeout'
            ) {
              this.$message(this.$t('pc_biz_core_msg_captchExpired'));
            } else {
              this.$message(this.$t('pc_biz_core_msg_submitError'));
            }
          } else {
            this.$message.error(this.$t('pc_biz_core_msg_formInvalid'));
          }
        });
    },
    setTime() {
      if (this.timeCount > 0) {
        window.setTimeout(() => {
          this.timeCount--;
          this.setTime();
        }, 1000);
      } else {
        this.reget = false;
        this.timeCount = timeCount;
      }
    },
    openCaptch() {
      // 重置验证码字段
      this.ticket = '';
      this.randstr = '';
    },
    toSuccess(res) {
      this.reget = true;
      if (!/^1[23456789]\d{9}$/.test(this.ruleForm.mobile)) {
        this.$message.error(this.$t('pc_biz_core_msg_phoneInvalid'));
        this.reget = false;
      } else {
        this.ticket = res.ticket;
        this.randstr = res.randstr;
        this.setTime();
        getMobileCaptch({
          countryCode: '+86', // 目前写死国家区号
          orgId: this.orgId,
          phone: this.ruleForm.mobile,
          randstr: this.randstr,
          ticket: this.ticket
        })
          .then(result => {})
          .catch(e => {
            this.$message.error(e);
          });
      }
    },
    change() {
      // 刷新视图
      this.$forceUpdate();
    },
    changeCity(city) {
      this.$refs['ruleForm'].validate();
      this.cityObj = city;
    },
    checkStatus(label) {
      return (
        this.optionalList.includes(label) || this.requiredList.includes(label)
      );
    }
  },
  watch: {
    ruleForm: {
      deep: true,
      handler() {}
    },
    'ruleForm.mobile'(value, oldValue) {
      if (!/^\d{0,11}$/.test(String(value))) {
        this.ruleForm.mobile = oldValue || '';
      }
    },
    data: {
      immediate: true,
      handler(v) {
        let rules = {};
        if (this.data.required) {
          this.data.required.forEach(key => {
            if (!['name', 'mobile'].includes(key)) {
              if (key === 'city') {
                this.ruleForm[key] = [];
              } else if (key !== 'sex') {
                this.ruleForm[key] = '';
              }
              rules[key] = [
                {
                  required: true,
                  message: this.$t('pc_biz_core_msg_contentEmptyError'),
                  trigger: 'blur'
                }
              ];
            }
          });
        }

        if (this.data.optional) {
          this.data.optional.forEach(key => {
            this.ruleForm[key] = '';
          });
        }

        this.rules = Object.assign({}, this.rules, rules);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
