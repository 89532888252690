<!-- 创建培训项目 -->
<template>
 <ClickToJump :message="$t('pc_biz_to_create_project' /* 好的,正在为您唤起创建培训页面 */)" :target-url="targetUrl" :datas="datas"/>
</template>

<script>
import ClickToJump from '../common/click-to-jump.vue';
import propsMixin from '../common/props-mixin.js';

export default {
  name: 'CreateProject',
  components: {
    ClickToJump
  },
  mixins: [propsMixin],
  data() {
    return {
      targetUrl: `${window.location.origin}/o2o/#/createproject`
    };
  }
};
</script>