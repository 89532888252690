<template>
  <code-viewer
    v-if="showCustom && customCode"
    class="yxtbiz-nav-footer"
    :source="customCode"
  ></code-viewer>
  <div v-else class="yxtbiz-nav-footer">
    <div class="yxtbiz-nav-footer__links" v-if="showLinks">
      <span class="yxtbiz-nav-footer__link"
        ><a :href="urlMap.userhome" class="hover-primary-6">{{
          $t('pc_biz_nav_btn_websitindex')
        }}</a></span
      >
      <span v-if="!hideUsercenterBtn" class="yxtbiz-nav-footer__link"
        ><a :href="urlMap.uinfo" class="hover-primary-6">{{
          $t('pc_biz_nav_btn_usercenter')
        }}</a></span
      >
      <span class="yxtbiz-nav-footer__link" v-if="isSecretprotocol"
        ><a :href="urlMap.ua" class="hover-primary-6">{{
          $t('pc_biz_nav_btn_confidentialityagreement')
        }}</a></span
      >
      <span class="yxtbiz-nav-footer__link" v-if="showFeedback"
        ><a :href="urlMap.feedback" class="hover-primary-6">{{
          $t('pc_biz_nav_btn_feedback')
        }}</a></span
      >
    </div>
    <div
      class="yxtbiz-nav-footer__copyright"
      v-if="!isCeibsonline && isShowCopyright"
    >
      <span
        >© {{ new Date().getFullYear() }} {{ $t('pc_biz_nav_lbl_copyright1') }}
        <a
          href="https://beian.miit.gov.cn/"
          target="_blank"
          class="hover-primary-6"
          >{{ $t('pc_biz_nav_lbl_copyright2') }}</a
        ></span
      >
      <span
        ><a
          href="https://www.yxt.com/"
          class="hover-primary-6"
          target="_blank"
          >{{ $t('pc_biz_nav_lbl_websitname') }}</a
        >
        {{ $t('pc_biz_nav_lbl_tecsupport') }}
        {{ $t('pc_biz_nav_lbl_permitnumber') }}</span
      >
    </div>
    <div class="yxtbiz-nav-footer__copyright" v-if="isCeibsonline">
      <span
        >{{ $t('pc_biz_nav_lbl_zhongoucopyright1') }}
        <a
          href="https://beian.miit.gov.cn/"
          target="_blank"
          class="hover-primary-6"
          >{{ $t('pc_biz_nav_lbl_zhongoucopyright2') }}</a
        ></span
      >
    </div>
  </div>
</template>

<script>
import CodeViewer from 'yxt-biz-pc/packages/custom/code-viewer/index';
import { checkTimeOutFnc, common, productionUtil } from 'yxt-biz-pc/packages/common-util/index.common';
import { decorateApi } from 'yxt-biz-pc/packages/api';
import { GetIsSecretprotocolNew, getOrgInfoByDomain } from './service';
import { enmuFunctions } from '../../../function-points';

export default {
  name: 'YxtbizNavFooter',
  components: {
    CodeViewer
  },
  props: {
    // 是否显示站内连接
    showLinks: {
      type: Boolean,
      default: true
    }
  },
  mixins: [common.genIsMixin()],
  data: () => {
    return {
      path: '',
      urlMap: {
        userhome: '/main/#/index',
        uinfo: '/#/uinfo',
        ua: '/#/ua',
        feedback: '/support/#/feedback/main'
      },
      isSecretprotocol: false,
      isShowCopyright: true,
      isCeibsonline: window.localStorage.sourceCode === '101', // 是否中欧渠道，中欧渠道页脚版权信息需要特殊处理
      showCustom: false, // 是否使用自定义导航
      customCode: '' // 自定义导航编码
    };
  },
  created() {
    this.path = productionUtil.getProductionInfo().path;
    this.getCustomNav();
    if (window.localStorage.orgId) {
      GetIsSecretprotocolNew(window.localStorage.orgId).then(res => {
        this.isSecretprotocol = res.secretSwitch;
      });
    }
    this.loadStuNav();
    this.resetUrlByProduction();
  },
  computed: {
    // 建议和反馈如果和帮助中心用一个可插拔的要素，控制其在私有化不显示入口
    showFeedback() {
      return checkTimeOutFnc(enmuFunctions.SUPPORT_CENTER_SWITCH) === 2 && !this.path;
    }
  },
  methods: {
    resetUrlByProduction() {
      if (this.path) {
        this.urlMap.userhome = `/${this.path}/#/studyindex`;
        this.urlMap.uinfo = `/${this.path}/#/uinfo`;
        this.urlMap.ua = `/${this.path}/#/ua`;
        this.urlMap.feedback = `/${this.path}/support/#/feedback/main`;
      }
    },
    loadStuNav() {
      this.setFooterConfig();
    },
    async setFooterConfig() {
      // 判断机构参是否开启，1：不展示页脚 0：展示页脚
      try {
        // let params={
        //   code:'isShowNavFooter',
        //   orgId:localStorage.orgId,
        //   domain:localStorage.domain
        // }
        // const res = await getOrgParamsNoToken(params);
        // if (res && res.code && Number(res.value)) this.isShowCopyright = false;
        let isShowNavFooter = localStorage.getItem('isShowNavFooter');
        if (isShowNavFooter === null) {
          let params = {
            orgId: localStorage.getItem('orgId')
          };
          if (!params.orgId) {
            params.domain = this.getDomain();
          }
          const res = await getOrgInfoByDomain(params);
          isShowNavFooter = res.isShowNavFooter;
          localStorage.setItem('isShowNavFooter', isShowNavFooter);
        }
        isShowNavFooter === '1' && (this.isShowCopyright = false);
      } catch (error) {
        console.log(error);
        this.isShowCopyright = true;
      }
    },
    // 校验自定义编码中是否直接使用业务组件
    checkCode(code) {
      return !!~code.indexOf('yxtbiz-nav-footer');
    },
    // 获取装修自定义页脚模板
    getCustomNav() {
      const orgId = window.localStorage.orgId || '';
      const domain = window.location.host;
      decorateApi
        .get(`/custom/release-codes/types/3/toggle-configs?orgId=${orgId}&domainName=${domain}`)
        .then(res => {
          const { toggle } = res;
          this.showCustom = toggle;
          if (this.showCustom) {
            const langKey = window.localStorage.getItem('yxtLang');
            const requestUrl = `/custom/release-codes/types/3?orgId=${orgId}&domainName=${domain}` + (langKey ? `&langKey=${langKey}` : '');
            decorateApi.get(requestUrl).then(res => {
              const { body } = res;
              let codeObj = JSON.parse(body);
              if (this.checkCode(codeObj.tmp)) {
                this.showCustom = false;
              } else {
                this.customCode = codeObj.tmp;
              }
            });
          }
        });
    },
    getDomain() {
      if (window.location.host.indexOf('localhost') > -1) {
        return window.localStorage.getItem('testDomain');
      }
      // 存在配置中心配置时，如果当前域名为非机构域名则跳过
      if (
        window.feConfig &&
        window.feConfig.common &&
        [
          window.feConfig.common.commonDomainH5, // 移动端通用域名
          window.feConfig.common.dingDomainPc, // 钉钉ISV域名
          window.feConfig.common.qw // 企业微信ISV域名
        ].filter((url) => url.indexOf('//' + window.location.host) > -1).length
      ) { return ''; }
      return window.location.host;
    }
  }
};
</script>
