// 选择组件全局公共属性
import SelectorPropsMixin from './selector-props';
export default {
  mixins: [SelectorPropsMixin],
  inject: ['selectData'],
  data() {
    return {
      singleSelect: '' // 单选的值
    };
  },
  computed: {
    strongManageMode() {
      // 是否是强管理集团
      return this.$store.state.navManageStore.groupCorpInfo.manageMode === 2;
    }
  },
  methods: {
    selectCurrData(type) {
      // 表格选中当前页数据逻辑
      // 先得到已选中的向，把未选中的加入进去
      const selectObj = {};
      const selecteds = [...this.selectData[type]];
      selecteds.forEach(item => {
        selectObj[item.id] = true;
      });
      this[type].forEach(row => {
        if (!selectObj[row.id] && !row.disabled) {
          this.$refs[this.refTable].toggleRowSelection(row, true);
          selecteds.push(row);
        }
      });
      this.$emit('change', selecteds, type);
    },
    selectAllData(selectAllData, type) {
      // 全选操作
      const selectObj = {};
      const selecteds = [...this.selectData[type]];
      selecteds.forEach(item => {
        selectObj[item.id] = true;
      });
      selectAllData.forEach(row => {
        if (!selectObj[row.id] && !row.disabled) {
          selecteds.push(row);
        }
      });
      this.$emit('change', selecteds, type);
    },
    performanceUpateSelected(type) {
      // 性能优化，当前数据量大于500条时，执行该方法
      const selected = this.selectData[type];
      if (!this.multiple) {
        // 单选模式，直接赋值就行
        this.singleSelect = selected[0] ? selected[0].id : '';
        return;
      }
      this.$refs[this.refTable].clearSelection();
      if (selected.length > 500) {
        const tableData = this[type];
        tableData.forEach((item) => {
          // if (item.disabled) return; // 禁用下的数据就不做回显处理
          if (selected.find(select => select.id === item.id)) {
            this.$refs[this.refTable].toggleRowSelection(item, true);
          }
        });
      } else {
        selected.forEach(row => {
          // if (row.disabled) return; // 禁用下的数据就不做回显处理
          this.$refs[this.refTable].toggleRowSelection(row, true);
        });
      }
    },
    performanceSelectData(currSelected, type) {
      // 在大数据量下单页/单选选中时做优化处理
      const selected = this.selectData[type];
      const tableData = this[type];
      const newSelectData = [];
      const waitSelectData = [[], []];
      if (selected.length > 500) {
        tableData.forEach(item => {
          if (currSelected.find(el => el.id === item.id)) {
            waitSelectData[0].push(item);
          } else {
            waitSelectData[1].push(item);
          }
        });
        const [adds, dels] = waitSelectData;
        selected.forEach(select => {
          if (dels.find(item => item.id === select.id)) {
          } else {
            newSelectData.push(select);
          }
        });
        adds.forEach(add => {
          if (selected.find(item => item.id === add.id)) {
          } else {
            newSelectData.push(add);
          }
        });
        this.$emit('change', newSelectData, type);
      } else {
        this.$emit('change', currSelected, type);
      }
    }
  }
};
