<template>
  <div>
    <yxt-row type='flex' class="yxt-user-selector-container yxt-user-multi-container">
        <div class="yxtbiz-user-search-wrap mb16">
          <yxt-input
            :placeholder="$t('biz_udp_enter_account')"
            v-model="userValue"
            class="ml12"
            size="small"
            maxlength="20"
            style="width:240px"
            searchable
            @search="searchUser"
          >
          </yxt-input>
        </div>
        <yxt-table
          :ref="refTable"
          @select-all="selectAllUser"
          @select="selectUser"
          v-loading="loading"
          :height="tableHeight"
          class="yxt-user-table"
          :data="persons"
          :row-key="(row) => row.id"
          :key="'persons'+timeStamp"
        >
          <template slot="empty">
            <div style="line-height:normal">
              <yxt-empty />
            </div>
          </template>
          <!-- 表格单多选 -->
          <yxt-table-column
            v-if="multiple"
            :show-overflow-tooltip="false"
            class-name="yxtbiz-table-selection"
            type="selection"
            width="42"
            reserve-selection
            clear-padding="right"
          ></yxt-table-column>
          <yxt-table-column
            v-else
            :show-overflow-tooltip="false"
            width="40"
          >
            <template slot-scope="scope">
              <yxt-radio v-model="singleSelect" @change="selectUser(scope.row.id, scope.row)" :label="scope.row.id"></yxt-radio>
            </template>
          </yxt-table-column>
          <yxt-table-column
            width="18"
            align="right"
            clear-padding="left-right"
            v-if="multiple"
            >
            <template slot="header">
              <yxt-dropdown placement="bottom-start" @command="tableHeaderCommand">
                  <span> </span>
                  <yxt-dropdown-menu slot="dropdown">
                    <yxt-dropdown-item command="selectCurrent">{{$t('pc_biz_enroll_select_current_page').d('选择本页')}}</yxt-dropdown-item>
                    <yxt-dropdown-item command="selectAll" >{{$t('pc_biz_enroll_select_all').d('选择全部')}}</yxt-dropdown-item>
                    <yxt-dropdown-item command="clearSelect" >{{$t('pc_biz_enroll_clear_all').d('清空所选')}}</yxt-dropdown-item>
                  </yxt-dropdown-menu>
              </yxt-dropdown>
            </template>
          </yxt-table-column>
          <yxt-table-column :label="$t('pc_biz_core_lbl_name')" :show-overflow-tooltip="true" :popover-html="true">
            <template slot-scope="scope">
              <yxtbiz-user-name v-if='isOpenData' :name='scope.row.fullname' />
              <span v-else>{{scope.row.fullname}}</span>
            </template>
          </yxt-table-column>
          <yxt-table-column :label="$t('pc_biz_core_lbl_accnum')" prop="username" :show-overflow-tooltip="true" :popover-html="true">
          </yxt-table-column>
          <yxt-table-column
            :label="$t('biz_udp_position')"
            :show-overflow-tooltip="true"
            :formatter="formatPostion"
          ></yxt-table-column>
        </yxt-table>
        <yxt-row align='middle' class="yxtbiz-user-selector-mask mt16" type='flex'>
          <yxt-row type='flex' align='middle' class="col-flex-1"></yxt-row>
          <yxt-pagination
            @size-change="sizeChange"
            @current-change="pageChange"
            :current-page.sync="page"
            :page-size="userData.limit"
            simple-total
            layout="total, prev, pager, next"
            :total="count"
            :pager-count="5"
          ></yxt-pagination>
        </yxt-row>
    </yxt-row>
  </div>
</template>

<script>
import deptTree from '../../../dept-tree';
import SelectPopover from '../components/SelectPopover';
import resizeTable from '../../mixins/resizeTable';
import openDataMixin from '../../mixins/openData';
import commonMixin from '../../mixins/common';
import { getUserList } from '../service';

export default {
  components: {
    deptTree,
    SelectPopover
  },
  mixins: [resizeTable, openDataMixin, commonMixin],
  props: {
    value: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'persons'
    },
    showAll: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      refTable: 'userTable',
      isSearchAll: false,
      userValue: '',
      page: 1,
      count: 0,
      tableHeight: 0,
      userData: {
        scope: 0,
        deptId: '',
        searchKey: '',
        showAll: this.showAll ? 1 : 0,
        limit: 20,
        offset: 0
      },
      loading: false,
      totalPage: 0,
      persons: [], // table数据
      formatPostion: row => {
        if (row.positionName === undefined) {
          return (
            row.positionSet
              .map(item => {
                return item.name;
              })
              .join(';') || '--'
          );
        } else {
          const parttimePosition = row.parttimePositionSet ? Array.from(row.parttimePositionSet) : [];
          const posList = parttimePosition.map(item => item.name);
          posList.push(row.positionName || '');
          return posList.filter(item => item !== '').join(';') || '--';
        }
      },
      timeStamp: ''
    };
  },
  watch: {
    selectData: {
      deep: true,
      handler() {
        console.log('监听器也触发啦');
        this.performanceUpateSelected('persons');
        // 基础组件 toggleRowSelection 有个坑，传入的row必须是当前table所使用的那个数组中的row
        // 否则不管用，列如 persons[0] 与 row哪怕长得一样，persons[0]可以生效而row不生效，故这里
        // 将把获取到的数据进行重新处理
        this.persons = [...this.persons]; // 一种解决方案，直接把当前table数据重新赋值，即可解决
      }
    }
  },
  async created() {
    await this.getUserData();
  },
  methods: {
    tableHeaderCommand(row) {
      this[row]();
    },
    selectCurrent() {
      // 选择本页
      this.selectCurrData('persons');
    },
    selectAll() {
      // 选择所有
      this.loadAll();
    },
    clearSelect() {
      // 清空所选
      this.$refs[this.refTable].clearSelection();
      this.$emit('change', [], 'persons');
    },
    async getUserData() {
      if (this.orgInvalid) return;
      this.loading = true;
      this.isCancelSelect = false;
      try {
        this.userData.searchKey = this.userValue;
        this.userData.managerId = window.localStorage.getItem('userId');
        const result = await getUserList(this.userData, this.enableGroupCorp);
        if (this.orgInvalid) return;
        if (result.datas.length !== 0 && (this.userData.searchKey.trim() !== '' || this.userData.deptId !== '')) {
          // 搜索条件不为空，展示筛选全部按钮
          this.showFilterAll = true;
        } else {
          this.showFilterAll = false;
        }
        this.persons = result.datas;
        this.persons.forEach(user => {
          user.nodeType = this.type;
        });
        this.totalPage = result.paging.pages;
        this.count = result.paging.count;
        this.loading = false;
        this.performanceUpateSelected('persons');
        console.log('997876');
      } catch (e) {
        this.persons = [];
        this.loading = false;
        this.$message({
          type: 'error',
          message: e.error.message
        });
      }
    },
    searchUser() {
      this.userData.offset = 0;
      this.page = 1;
      this.getUserData();
    },
    async loadAll() {
      this.loading = true;
      const limit = 5000;
      const reqCount = Math.ceil(this.count / limit); // 循环次数
      const reqArrs = []; // 请求数组
      for (let i = 0; i < reqCount; i++) {
        const params = Object.assign({}, this.userData, {
          managerId: window.localStorage.getItem('userId'),
          limit: limit,
          offset: limit * i
        });
        reqArrs.push(params);
      }
      try {
        const results = await Promise.all(
          reqArrs.map(params => getUserList(params, this.enableGroupCorp))
        );
        const dataSource = [];
        results.forEach(res => {
          dataSource.push(...res.datas);
        });
        let data = dataSource.map(user => {
          user.nodeType = this.type;
          return user;
        });
        this.selectAllData(data, 'persons');
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    sizeChange(size) {
      this.userData.limit = size;
      this.page = 1;
      this.userData.offset = 0;
      this.getUserData();
    },
    pageChange(page) {
      this.page = page;
      this.userData.offset = (page - 1) * this.userData.limit;
      this.getUserData();
    },
    selectUser(items, row) {
      // 选中数据
      if (Array.isArray(items)) {
        // 多选
        this.performanceSelectData(items, 'persons');
      } else {
        this.$emit('change', [row], 'persons');
      }
    },
    selectAllUser(items) {
      // 全选
      this.performanceSelectData(items, 'persons');
    }
  }
};
</script>

<style lang="scss" scoped></style>
