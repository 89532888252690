<template>
  <searchTodo v-bind="$attrs" v-on="$listeners" />
</template>
  
<script>
import searchTodo from '../search-todo/index.vue';
export default {
  name: 'StudyTodo',
  components: {
    searchTodo
  }
};
</script>