var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "yxt-row",
        {
          staticClass: "yxt-user-selector-container yxt-user-multi-container",
          attrs: { type: "flex" },
        },
        [
          _c(
            "div",
            { staticClass: "yxtbiz-user-search-wrap mb16" },
            [
              _vm.enableGroupCorp
                ? _c("yxtbiz-group-org-select", {
                    attrs: {
                      setCurrentOrg: true,
                      selectFirst: true,
                      clearable: false,
                      visibleOrgIds: _vm.visibleOrgIds,
                      disabledOrgIds: _vm.disabledOrgIds,
                      targetOrgId: _vm.targetOrgId,
                      disabled: _vm.disabled,
                      functionCode: _vm.functionCode,
                      dataPermissionCode: _vm.dataPermissionCode,
                    },
                    on: {
                      change: _vm.handleOrgChange,
                      orgInvalid: _vm.orgInvalidHandler,
                    },
                  })
                : _vm._e(),
              _vm.isShowDeptment
                ? _c(
                    "select-popover",
                    {
                      ref: "selectPopover",
                      staticClass: "ml12",
                      attrs: {
                        "is-dept": "",
                        selectOpition: _vm.selectOpition,
                      },
                    },
                    [
                      _c(
                        "div",
                        [
                          (_vm.enableGroupCorp && _vm.userData.targetOrgId) ||
                          !_vm.enableGroupCorp
                            ? _c("dept-tree", {
                                ref: "deptTree",
                                attrs: {
                                  targetOrgId: _vm.userData.targetOrgId,
                                  visibleOrgSelector: false,
                                  enableGroupCorp: _vm.enableGroupCorp,
                                  functionCode: _vm.functionCode,
                                  dataPermissionCode: _vm.dataPermissionCode,
                                  filterable: true,
                                },
                                on: { nodeClick: _vm.selectDept },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _c("yxt-input", {
                staticClass: "ml12",
                staticStyle: { width: "240px" },
                attrs: {
                  placeholder: _vm.$t("biz_udp_enter_account"),
                  maxlength: "20",
                  searchable: "",
                },
                on: { search: _vm.searchUser },
                model: {
                  value: _vm.userValue,
                  callback: function ($$v) {
                    _vm.userValue = $$v
                  },
                  expression: "userValue",
                },
              }),
            ],
            1
          ),
          _c(
            "yxt-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              key: "persons" + _vm.timeStamp,
              ref: "userTable",
              staticClass: "yxt-user-table",
              attrs: { height: _vm.tableHeight, data: _vm.users },
              on: { "select-all": _vm.selectAllUser, select: _vm.selectUser },
            },
            [
              _c("template", { slot: "empty" }, [
                _c(
                  "div",
                  { staticStyle: { "line-height": "normal" } },
                  [_c("yxt-empty")],
                  1
                ),
              ]),
              _c("yxt-table-column", {
                attrs: {
                  "show-overflow-tooltip": false,
                  "class-name": "yxtbiz-table-selection",
                  type: "selection",
                  width: "40",
                },
              }),
              _c("yxt-table-column", {
                attrs: {
                  label: _vm.$t("pc_biz_core_lbl_name"),
                  "show-overflow-tooltip": true,
                  "popover-html": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.isOpenData
                          ? _c("yxtbiz-user-name", {
                              attrs: { name: scope.row.fullname },
                            })
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.fullname +
                                    "(" +
                                    scope.row.username +
                                    ")"
                                )
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("yxt-table-column", {
                attrs: {
                  formatter: _vm.formatUserNo,
                  label: _vm.$t("pc_biz_udp_lbl_userNo"),
                  "show-overflow-tooltip": true,
                  prop: "userNo",
                },
              }),
              _c("yxt-table-column", {
                attrs: {
                  label: _vm.$t("pc_biz_core_lbl_department"),
                  "show-overflow-tooltip": true,
                  "popover-html": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        !scope.row.deptName
                          ? _c("span", [_vm._v("--")])
                          : _vm.isOpenData
                          ? _c("yxtbiz-dept-name", {
                              attrs: { name: scope.row.deptName },
                            })
                          : _c("span", [_vm._v(_vm._s(scope.row.deptName))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("yxt-table-column", {
                attrs: {
                  label: _vm.$t("biz_udp_position"),
                  "show-overflow-tooltip": true,
                  formatter: _vm.formatPostion,
                },
              }),
            ],
            2
          ),
          _c(
            "yxt-row",
            {
              staticClass: "yxtbiz-user-selector-mask mt16",
              attrs: { align: "middle", type: "flex" },
            },
            [
              _c(
                "yxt-row",
                {
                  staticClass: "col-flex-1",
                  attrs: { type: "flex", align: "middle" },
                },
                [
                  _vm.showFilterAll
                    ? _c(
                        "yxt-button",
                        {
                          staticClass: "lh32 yxtbiz-btn-search-all",
                          class: _vm.isSearchAll
                            ? "color-primary-4 disabled"
                            : "",
                          attrs: { type: "text" },
                          on: { click: _vm.searchAll },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.isCancelSelect
                                ? _vm.$t("pc_biz_udp_btn_cancelFilter")
                                : _vm.$t("biz_udp_all_filter_results")
                            )
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c("div", {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.isSearchAll,
                        expression: "isSearchAll",
                      },
                    ],
                    staticClass: "yxtbiz-loading__all",
                  }),
                ],
                1
              ),
              _c("yxt-pagination", {
                attrs: {
                  "current-page": _vm.page,
                  "page-size": _vm.userData.limit,
                  "simple-total": "",
                  layout: "total, prev, pager, next",
                  total: _vm.count,
                  "pager-count": 5,
                },
                on: {
                  "size-change": _vm.sizeChange,
                  "current-change": _vm.pageChange,
                  "update:currentPage": function ($event) {
                    _vm.page = $event
                  },
                  "update:current-page": function ($event) {
                    _vm.page = $event
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }