<template>
  <div v-if="isOpenI18n" class="yxtbiz-nav-lang" :class="{ 'is-stu': isStu }">
    <!-- 学员端 -->
    <yxtf-dropdown v-if="isStu" v-bind="$attrs">
      <div v-if="!$slots.default" class="color-gray-9 yxtbiz-nav-lang__content">
        <img :src="imgSrc" class="yxtbiz-nav-lang__img" />
        <span class="yxtbiz-nav-lang__name">{{ activeName }}</span>
      </div>
      <slot v-else></slot>
      <yxtf-dropdown-menu
        :scrollable="false"
        slot="dropdown"
        :append-to-body="appendToBody"
        :popperOptions="{
          modifiers: [
            'shift',
            'offset',
            'keepTogether',
            'arrow',
            'flip',
            'applyStyle'
          ],
          gpuAcceleration: false
        }"
      >
        <yxtf-dropdown-item
          v-for="lang in langList"
          :key="lang.langTag"
          :command="lang.langTag"
          :class="[
            'yxtbiz-nav-lang__default-lang',
            { 'yxtbiz-nav-lang__active-langs': activeLang == lang.langTag }
          ]"
          @click.native="handleLang(lang)"
        >
          {{ lang.langName }}
          <!-- <i v-if="activeLang == lang.langTag" class="yxt-icon-check"></i> -->
        </yxtf-dropdown-item>
      </yxtf-dropdown-menu>
    </yxtf-dropdown>
    <!-- 管理端 -->
    <yxt-dropdown v-else v-bind="$attrs">
      <div v-if="!$slots.default" class="color-gray-9 yxtbiz-nav-lang__content">
        <img :src="imgSrc" class="yxtbiz-nav-lang__img" />
        <span class="yxtbiz-nav-lang__name">{{ activeName }}</span>
      </div>
      <slot v-else></slot>
      <yxt-dropdown-menu
        :scrollable="false"
        slot="dropdown"
        :append-to-body="appendToBody"
        :popperOptions="{
          modifiers: [
            'shift',
            'offset',
            'keepTogether',
            'arrow',
            'flip',
            'applyStyle'
          ],
          gpuAcceleration: false
        }"
      >
        <yxt-dropdown-item
          v-for="lang in langList"
          :key="lang.langTag"
          :command="lang.langTag"
          :class="[
            'yxtbiz-nav-lang__default-lang',
            { 'yxtbiz-nav-lang__active-langs': activeLang == lang.langTag }
          ]"
          @click.native="handleLang(lang)"
        >
          {{ lang.langName }}
          <!-- <i v-if="activeLang == lang.langTag" class="yxt-icon-check"></i> -->
        </yxt-dropdown-item>
      </yxt-dropdown-menu>
    </yxt-dropdown>
  </div>
</template>

<script>
import { Dropdown, DropdownMenu, DropdownItem } from 'yxt-pc';
import commonUtils from 'yxt-biz-pc/packages/common-util/index';
import { setLocale } from './service';
import { CorePath } from './const';
// import { navCatchKeyStu, navCatchKeyManagement } from './userNavs';
import { productionUtil } from '../../../common-util/productionUtil';
export default {
  name: 'YxtbizNavLang',
  components: {
    YxtDropDown: Dropdown,
    YxtDropdownMenu: DropdownMenu,
    YxtDropdownItem: DropdownItem
  },
  props: {
    isOpenI18n: {
      type: Number,
      default: 0
    },
    appendToBody: {
      // 是否需要加载body中
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    isIcon: {
      type: Boolean,
      default: false
    },
    langList: {
      type: Array,
      default: []
    },
    isStu: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      imgSrc: CorePath + '/lang/earth.png',
      isI18n: '',
      activeLang: commonUtils.getLanguage()
    };
  },
  created() {
    localStorage.setItem('lang4Get', JSON.stringify(this.langList));
  },
  computed: {
    activeName() {
      if (this.title) {
        return this.title;
      } else {
        return this.langList.find(item => item.langTag === this.activeLang)
          .langName;
      }
    }
  },
  methods: {
    handleLang(lang) {
      // 切换语种后所有产品下导航都清楚缓存，防止语种不一致
      const codes = localStorage.getItem('productCodes') || 'xxv2';
      const productCodes = codes.includes('[') ? JSON.parse(codes) : codes.split(',');
      productCodes.forEach(item => {
        const info = productionUtil.getProductionInfo(item);
        if (info) {
          const navCatchKeyManagement = info.path ? `${info.path}_nav_datas_2` : 'nav_datas_2';
          const navCatchKeyStu = info.path ? `${info.path}_nav_datas_1` : 'nav_datas_1';
          localStorage.removeItem(navCatchKeyManagement);
          localStorage.removeItem(navCatchKeyStu);
        }
      });

      let oldLang = commonUtils.getLanguage();
      if (lang.langTag === oldLang) return;
      if (!window.localStorage.getItem('token')) {
        this.activeLang = lang.langTag;
        localStorage.setItem('yxtLang', lang.langTag);
        return window.location.reload();
      }
      setLocale({
        locale: lang.langTag
      })
        .then((res) => {
          if (res && res.fullname) {
            localStorage.setItem('fullname', res.fullname);
          }
          this.activeLang = lang.langTag;
          localStorage.setItem('yxtLang', lang.langTag);
          window.location.reload();
        })
        .catch(() => {
          window.location.reload();
        });
    }
  }
};
</script>

<style lang="scss" scoped></style>
