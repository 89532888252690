<template>
  <div class="yxtbiz-i18n-input">
    <yxt-input
      v-model="value"
      v-bind="$attrs"
      @input="inputChange($event)"
      @focus="focus($event)"
    >
      <template v-if="$attrs.type == 'text'" slot="suffix">
        <yxt-svg class="yxt-input-suffix-svg-btn hover-primary-6 yxtbiz-i18n-input__icon-hover" width="16px" height="16px" icon-class="language" @click.native="handleClick"/>
      </template>
      <i
        v-if="$attrs.type == 'textarea'"
        slot="suffix"
        class="yxt-icon-edit yxt-input__icon"
      >
      </i>
    </yxt-input>
    <div v-if="$attrs.type == 'textarea'" class="yxtbiz-i18n-input__icon-btn yxtbiz-i18n-input__icon-hover" @click="handleClick">
      <yxt-svg class="yxt-input-suffix-svg-btn hover-primary-6" width="16px" height="16px" icon-class="language" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'YxtbizI18nInput',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  methods: {
    inputChange(val) {
      this.$emit('input', val);
    },
    focus(val) {
      this.$emit('focus', val);
    },
    handleClick() {
      this.$emit('click');
    }
  }
};
</script>


