<!-- 创建培训计划 -->
<template>
 <ClickToJump :message="$t('pc_biz_to_create_plan' /* 好的，正在为您唤起创建培训计划页面 */)" :target-url="targetUrl" :datas="datas"/>
</template>

<script>
import ClickToJump from '../common/click-to-jump.vue';
import propsMixin from '../common/props-mixin.js';

export default {
  name: 'CreatePlan',
  components: {
    ClickToJump
  },
  mixins: [propsMixin],
  data() {
    return {
      targetUrl: `${window.location.origin}/o2o/#/createPlan?isEdit=create`
    };
  }
};
</script>