var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "standard-size-14 yxtbiz-ai-robot__coure" },
    [
      _vm.dataList.length === 0
        ? _c("EmptyData")
        : [
            _c(
              "div",
              {
                staticClass: "color-gray-7 yxtbiz-ai-robot__coure-title",
                class:
                  _vm.size === "small"
                    ? "font-size-14 lh22"
                    : "font-size-16 lh24",
              },
              [
                _c(
                  "yxtbiz-language-slot",
                  { attrs: { inline: "", "lang-key": "pc_biz_search_title" } },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: { color: "#436BFF", padding: "0 6px" },
                        attrs: { slot: "0" },
                        slot: "0",
                      },
                      [_vm._v(_vm._s(_vm.totalCount))]
                    ),
                    _vm.techtodo.length && _vm.stutodo.length
                      ? _c("template", { slot: "1" }, [
                          _vm._v(_vm._s(_vm.$t("pc_biz_ai_todo"))),
                        ])
                      : _vm.techtodo.length
                      ? _c("template", { slot: "1" }, [
                          _vm._v(_vm._s(_vm.$t("pc_biz_ai_study_todo"))),
                        ])
                      : _vm.stutodo.length
                      ? _c("template", { slot: "1" }, [
                          _vm._v(_vm._s(_vm.$t("pc_biz_ai_teach_todo"))),
                        ])
                      : _c("template", { slot: "1" }, [
                          _vm._v(_vm._s(_vm.$t("pc_biz_ai_todo"))),
                        ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _vm.techtodo.length && _vm.stutodo.length
              ? _c("div", { staticClass: "tag-box" }, [
                  _c(
                    "span",
                    {
                      class: _vm.sceneTarget === 1 ? "active" : "",
                      on: {
                        click: function ($event) {
                          return _vm.changeSceneTarget(1)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("pc_biz_ai_study_todo")))]
                  ),
                  _c(
                    "span",
                    {
                      class: _vm.sceneTarget === 0 ? "active" : "",
                      on: {
                        click: function ($event) {
                          return _vm.changeSceneTarget(0)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("pc_biz_ai_teach_todo")))]
                  ),
                ])
              : _vm._e(),
            _vm._l(_vm.dataList, function (item) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "yxtbiz-ai-robot__coure-cell",
                  on: {
                    click: function ($event) {
                      return _vm.toDetail(item)
                    },
                  },
                },
                [
                  item.catIcon
                    ? _c("yxtf-portrait", {
                        staticClass: "yxtbiz-ai-robot__course-cover",
                        attrs: { size: "48px", imgUrl: item.catIcon },
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "ml12 yxtbiz-ai-robot__course-contain" },
                    [
                      _c("div", {
                        staticClass: "ellipsis-2 mb4",
                        class:
                          _vm.size === "small"
                            ? "font-size-14 lh22"
                            : "font-size-16 lh24",
                        domProps: { innerHTML: _vm._s(item.title) },
                      }),
                      _vm.size !== "small"
                        ? _c("div", { staticClass: "mt4" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "standard-size-12 color-gray-7 mt4 ellipsis-1 lh20",
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t("pc_biz_cer_type").d("类型") +
                                        ": " +
                                        item.tag
                                    ) +
                                    "\n            "
                                ),
                                _c("yxtf-divider", {
                                  attrs: { direction: "vertical" },
                                }),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t("pc_biz_cer_lbl_time").d("时间") +
                                        ": " +
                                        _vm.timeStr(item)
                                    ) +
                                    "\n          "
                                ),
                              ],
                              1
                            ),
                          ])
                        : _c("div", { staticClass: "mt4" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "standard-size-12 color-gray-7 mt4 ellipsis-1 lh20",
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t("pc_biz_cer_type").d("类型") +
                                        ": " +
                                        item.tag
                                    ) +
                                    "\n          "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "standard-size-12 color-gray-7 mt4 ellipsis-1 lh20",
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t("pc_biz_cer_lbl_time").d("时间") +
                                        ": " +
                                        _vm.timeStr(item)
                                    ) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ]),
                    ]
                  ),
                ],
                1
              )
            }),
            _vm.needMore
              ? _c(
                  "div",
                  {
                    ref: "toMoreButton",
                    staticClass: "yxtbiz-ai-robot__coure-more color-gray-8",
                    on: { click: _vm.toMore },
                  },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$t("pc_biz_lbl_common_viewmore")) +
                        "\n      "
                    ),
                    _c("yxt-svg", {
                      attrs: {
                        width: "16px",
                        height: "16px",
                        "icon-class": "arrow-down",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }