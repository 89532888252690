<template>
  <div
    v-if="currentNav && currentNav.subMenuInfoList.length > 0"
    class="yxtbiz-nav-left"
    :class="{
      'yxtbiz-nav-left--collapse' : collapse,
      'yxtbiz-nav-left--hover' : hovered
    }"
  >
    <div :class="['yxtbiz-nav-left__inner', !isOperated ? 'yxtbiz-nav-left__inner--notransition': '']">
      <div 
        v-if="appNavLeft" 
        :class="['yxtbiz-nav-left__appname ellipsis', collapse ? 'text-center': '']"
      >
        <span v-if="!collapse">
          <yxt-tooltip 
            :content="currentNav.name" 
            placement="right"
          >
            <span>{{currentNav.name}}</span>
          </yxt-tooltip>
        </span>
        <yxt-svg
          v-if="currentNav.functionCode === 'core_user_center'"
          v-show="collapse"
          :remote-url='iconPath'
          :icon-class="currentNav.navIcon"
          :query="iconUpdateParam"
          :width="'32px'"
          :height="'32px'"
        />
        <img v-else v-show="collapse" :src="currentNav.icon" />
      </div>
      <yxt-collapse v-model="menusShow">
        <template v-for="menu in currentNav.subMenuInfoList">
          <yxt-collapse-item
            :name="menu.id"
            :index="menu.code"
            :key="menu.id"
            :show-item-icon="false"
            v-if="menu.showed"
          >
            <!-- 左导航一级菜单区域,M5V1去除了折叠后的移入popover效果。改为了移入展开左侧菜单 -->
            <template slot="title">
              <div class="yxtbiz-nav-left__menu-wrap">
                <!-- 左导航一级菜单 -->
                <div
                  :ref="menu.code"
                  :class="[
                    'yxtbiz-nav-left__menu yxtbiz-nav-left__menu--main hover-primary-6-i',
                    isMenuSelected(menu, pageCodeStartIndex, collapse, false, true) ? 'yxtbiz-nav-left__menu--actived color-primary-6-i':'',
                    isMenuSelected(menu, pageCodeStartIndex, false, false, true) ? 'bg-primary-1-i':'',
                    collapse ? 'hover-primary-6-i' : '',
                    menuHover.id === menu.id ? 'yxtbiz-nav-left__menu--hover' : ''
                  ]"
                  @click="toggleShow(menu)"
                  @mouseenter="setMenuHover(menu)"
                  @mouseleave="setMenuHover({})"
                >
                  <div :class="['yxtbiz-nav-left__menu-icon']">

                    <yxt-svg
                      v-if="getIconPath(menu)"
                      :remote-url='getIconPath(menu).remoteUrl'
                      :icon-class="getIconPath(menu).name"
                      :query="iconUpdateParam"
                      :width="'16px'"
                      :height="'16px'"
                      :class="[
                        isMenuSelected(menu, pageCodeStartIndex, true)? 'color-primary-6-i' : ''
                      ]"
                    />
                    <yxt-svg
                      v-else-if="iconPath&&menu.code"
                      :remote-url='iconPath'
                      :icon-class="menu.code"
                      :query="iconUpdateParam"
                      :width="'16px'"
                      :height="'16px'"
                      :class="[
                        isMenuSelected(menu, pageCodeStartIndex, true)? 'color-primary-6-i' : ''
                      ]"
                    />
                    <i v-else 
                      class="yxt-icon-picture-outline" 
                      style="width:16px;height:16px"
                      :class="[
                        isMenuSelected(menu, pageCodeStartIndex, true)? 'color-primary-6-i' : ''
                      ]"></i>
                  </div>
                  <div 
                    class="yxtbiz-nav-left__menu-name"
                    :class="{
                      'color-primary-6-i': isMenuSelected(menu, pageCodeStartIndex, true),
                      'yxtbiz-nav-left__menu-name--full': !isDisplaySubMenus(menu)
                    }"
                  >
                    <yxt-tooltip class="yxtbiz-nav-left__menu-tip ellipsis" :content="menu.name" placement="right" :open-filter="true">
                      <span>
                        <a-link :url="menu.pageUrl">{{menu.name}}</a-link>
                      </span>
                    </yxt-tooltip>
                  </div>
                  <div class="yxtbiz-nav-left__arrow"
                  v-if="isDisplaySubMenus(menu)"
                  :class="[menusShow.indexOf(menu.id) >=0 ? 'yxtbiz-nav-left__arrow--active' : '']"
                  >
                    <div class="yxtbiz-nav-left__arrow-l hover-border-primary-6-i"
                          :class="[isMenuSelected(menu, pageCodeStartIndex, true) ? 'border-primary-6-i' : '' ]"
                    ></div>
                    <div class="yxtbiz-nav-left__arrow-r hover-border-primary-6-i"
                          :class="[isMenuSelected(menu, pageCodeStartIndex, true) ? 'border-primary-6-i' : '' ]"
                    ></div>
                  </div>
                  <div
                    v-if="isMenuSelected(menu, pageCodeStartIndex, false, false, true)"
                    class="yxtbiz-nav-left__menu-leftbar bg-primary-6"
                  ></div>
                </div>
              </div>
            </template>
            <!-- 左导航二级菜单 -->
            <div v-if="isDisplaySubMenus(menu)">
              <div
                v-for="submenu in menu.subMenuInfoList"
                :key="submenu.id"
                :ref="submenu.code"
                :index="submenu.code"
                class="yxtbiz-nav-left__menu yxtbiz-nav-left__menu--sub"
                :class="['hover-primary-6-i hand', isMenuSelected(submenu) ? 'yxtbiz-nav-left__menu--actived color-primary-6-i bg-primary-1-i':'']"
                @click="toPage(submenu)"
                v-show="submenu.showed && !collapse"
              >
                <yxt-tooltip 
                  class="ellipsis yxtbiz-nav-left__menu-name color-gray-9 hover-primary-6-i"
                  :class="[isMenuSelected(submenu) ? 'color-primary-6-i yxt-weight-5':'']"
                  :content="submenu|Globalize('name')" placement="right" :open-filter="true">
                  <div>
                    <a-link :url="submenu.pageUrl" :page-node="submenu">{{submenu|Globalize('name')}}</a-link>
                  </div>
                </yxt-tooltip>
                <div
                  v-if="isMenuSelected(submenu)"
                  class="yxtbiz-nav-left__menu-leftbar bg-primary-6"
                ></div>
              </div>
            </div>
          </yxt-collapse-item>
        </template>
        
      </yxt-collapse>
    </div>
    <slot name="leftbottom" :collapse="collapse" />
    <!-- 底部的展开收起 -->
    <div class="yxtbiz-nav-left__aside-bottom hover-primary-6-i color-gray-10 hand"
        @click="setBottomCollapse"
        @mouseleave="leaveBottom"
        @mouseenter="enterBottom">
      <yxt-svg
        icon-class="collapse"
        :remote-url='remoteUrl'
        width="18px"
        height="18px"
        class
        :style="{transform: collapse ? 'rotate(180deg)' : ''}"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { Globalize, goSubNavPage } from './common';
import { getNavIcoPath } from 'yxt-biz-pc/packages/api';
import ALink from './alink.vue';
import {
  Collapse,
  CollapseItem,
  Popover,
  CollapseTransition
} from 'yxt-pc';

export default {
  name: 'YxtbizNavLeft',
  components: {
    YxtCollapse: Collapse,
    YxtCollapseItem: CollapseItem,
    YxtPopover: Popover,
    YxtCollapseTransition: CollapseTransition,
    ALink
  },
  data() {
    return {
      menusShow: [],
      pageCodeStartIndex: 1,
      isOperated: false,
      popID: '',
      remoteUrl: `${this.$staticBaseUrl}assets/e57f23c2/f7b885e3`,
      menuHover: {}
    };
  },
  props: {
    mainPathname: {
      type: String,
      default: ''
    },
    appNavLeft: {
      type: Boolean,
      default: false
    },
    hovered: {
      type: Boolean,
      default: false
    },
    customDatas: {
      type: Object,
      default: () => null
    },
    customPageCodes: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState({
      collapseFix: state => state.navManageStore.collapse
    }),
    collapse() {
      return this.collapseFix && !this.hovered;
    },
    iconPath() {
      return getNavIcoPath().navIcoPath + 'left';
    },
    iconUpdateParam() {
      return getNavIcoPath().navIcoUpdateTime ? { v: getNavIcoPath().navIcoUpdateTime + '' } : undefined;
    },
    // 获取二级导航列表
    currentNav() {
      let menusInfo = this.$store.state.navManageStore.navs.enableAppMenus.filter(menu => {
        return menu.code === this.pageCodes[0];
      })[0];
      if (menusInfo && menusInfo.code === '_app_all_code') {
        // 应用模式的mini导航
        this.pageCodeStartIndex = 2;
        menusInfo = menusInfo.subMenuInfoList.filter(menu => {
          return menu.code === this.pageCodes[1];
        })[0];
      }
      if (!this.located) {
        this.$nextTick(() => {
          const navNow = this.cnavs && this.cnavs[this.pageCodeStartIndex];
          // 初次定位导航
          if (navNow && navNow.code) {
            this.$refs[navNow.code] && this.$refs[navNow.code][0] && this.$refs[navNow.code][0].scrollIntoView();
            this.menusShow = [navNow.id];
            this.located = true;
            this.$nextTick(() => {
              this.isOperated = true;
            });
          }
        });
      }
      let subMenuInfoList = (menusInfo && menusInfo.subMenuInfoList) || [];
      this.$store.commit('navManageStore/SET_NAV_LEFT_LIST', subMenuInfoList);
      console.debug('menusInfo', menusInfo);
      return menusInfo;
    },
    // 当前导航数组
    cnavs() {
      return this.$store.state.navManageStore.cnavs;
    },
    // 当前层及编号数组
    pageCodes() {
      return this.$store.state.navManageStore.pageCodes;
    },
    // 当前导航信息
    cnav() {
      return this.$store.state.navManageStore.nav;
    }
  },
  watch: {
    cnavs(val, valold) {
      if (val !== valold &&
        val.length >= this.pageCodeStartIndex &&
        val[this.pageCodeStartIndex] &&
        this.menusShow.indexOf(val[this.pageCodeStartIndex].id) < 0) {
        this.menusShow = [val[this.pageCodeStartIndex].id]; // this.menusShow.push(val[1].id);

        this.$nextTick(() => {
          this.isOperated = true;
        });
      }
    }
  },
  methods: {
    ...mapActions('navManageStore', ['setCollapse']),
    showSubmenu(menu) {
      this.$set(menu, 'submenuHidden', !menu.submenuHidden);
    },
    toPage(page) {
      this.drawer = false;
      // goNavPage(page, this.mainPathname, this);
      goSubNavPage(page, this.mainPathname, this);
    },
    toSubPage(page) {
      goSubNavPage(page, this.mainPathname, this);
    },
    toggleShow(menu) {
      this.isOperated = true;
      if (!this.isDisplaySubMenus(menu)) {
        this.toPage(menu); // 没有子导航直接跳转
      }
    },
    setMenuHover(menu) {
      // 这里只在收起时修改hover元素，防止影响左导航手风琴的动画
      if (this.collapse) {
        this.menuHover = menu;
      }
    },
    isMenuSelected(menu, index, hover, pop, isMain) {
      const secondMenuCodeIndex = this.pageCodeStartIndex + 1;
      if (!index) {
        index = secondMenuCodeIndex;
      }
      if (index === secondMenuCodeIndex) {
        return (this.pageCodes[secondMenuCodeIndex] && this.pageCodes[secondMenuCodeIndex] === menu.code && (this.menusShow.indexOf(menu.parentId) >= 0 || this.collapse));
      } else if (index === this.pageCodeStartIndex) {
        /**
         * 1.hover选中效果
         * 2.在当前导航层级里，一级菜单选中
         *  a.折叠2级菜单时选中1级菜单
         *  a.选中的就是1级菜单，没有二级菜单时 。 子菜单选中且展开时父一级菜单也需要有Hover状态的效果
         *  a.左导航收起时
         *  a.二级菜单不显示在左导航时
         */
        return (hover && this.menuHover.id === menu.id) ||
              (
                ((!this.collapse && (!isMain || (this.menusShow.indexOf(menu.id) < 0 && (!menu.subMenuInfoList || menu.subMenuInfoList.length === 0)))) ||
                  !this.pageCodes[secondMenuCodeIndex] ||
                  (this.collapse && !pop && hover) ||
                  !this.isDisplaySubMenus(menu)) &&
                this.pageCodes.length >= 1 && this.pageCodes[this.pageCodeStartIndex] === menu.code
              );
      }
      return false;
    },
    isDisplaySubMenus(menu) {
      // 判断子导航是否展示在导航上
      return menu && !menu.isHasTabNav && menu.subMenuInfoList && menu.subMenuInfoList.length > 0;
    },
    enterBottom() {
      this.$emit('enterBottom');
    },
    leaveBottom() {
      this.$emit('leaveBottom');
    },
    setBottomCollapse() {
      if (!this.collapse) {
        this.$emit('collapseLeft');
      }
      this.setCollapse();
      this.$emit('collapseOperated');
    },
    // 获取导航图标
    getIconPath(menu) {
      if (!menu.navIcon) {
        return;
      }
      const navIconUrl = menu.navIcon;
      let urlSplitArr = navIconUrl.split('/');
      let svgDetail = {};
      svgDetail.name = urlSplitArr.pop().split('.')[0];
      svgDetail.remoteUrl = urlSplitArr.join('/');
      if (!svgDetail.name || !svgDetail.remoteUrl) {
        return false;
      }
      return svgDetail;
    }
  },
  beforeDestroy() {
  },
  filters: {
    Globalize(data, key) {
      return Globalize(data, key);
    }
  }
};
</script>
