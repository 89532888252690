<template>
  <div class="block">
    <span class="demonstration"></span>
    <yxt-cascader ref="areaSelect"
                  :options="options"
                  :props="{expandTrigger, value:'id',label:'name'}"
                  :separator="separator"
                  :clearable="clearable"
                  v-model="nativeValue"
                  @change="handleChange">
    </yxt-cascader>
  </div>
</template>

<script>
import { getAreas } from './service';
import { Cascader } from 'yxt-pc';

export default {
  name: 'YxtbizAreaSelect',
  components: {
    YxtCascader: Cascader
  },
  props: {
    value: {
      default: [],
      type: Array
    },
    separator: {
      default: '',
      type: String
    },
    expandTrigger: {
      default: 'click',
      type: String
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      options: [],
      nativeValue: this.value
    };
  },
  watch: {
    value(val) {
      this.nativeValue = this.value;
    }
  },
  methods: {
    init() {
      getAreas().then(res => {
        res && (this.options = res.datas);
      });
    },
    handleChange(val) {
      let values = [];
      if (val.length) {
        let item = this.$refs.areaSelect.getCheckedNodes();
        const getNodeLabel = node => {
          values.unshift(node.label);
          if (node.parent) {
            getNodeLabel(node.parent);
          }
        };
        getNodeLabel(item[0]);
      }
      this.$emit('input', val);
      this.$emit('change', { ids: val, names: values });
    }
  },
  created() {
    this.init();
  }
};
</script>
