import { qidaApi, udpApi } from 'yxt-biz-pc/packages/api';

// 获取授权数据
export const getAuthSelectData = (params) => {
  return qidaApi.get('data/auth/user/select', {
    params
  });
};

// 获取管辖部门id
export const getAuthDept = (params) => {
  return qidaApi.get('data/auth/user', {
    params
  });
};

// 根据id批量查询部门具体信息
export const getDepartments = (params) => {
  return udpApi.post('depts/deptnames', params);
};

export const getDeptTree = (query) => {
  return udpApi.get('depts/tree', {params: query});
};
