import manualTreeMixin from './manualTree';
export default {
  mixins: [manualTreeMixin],
  data() {
    return {
      data: [],
      originData: []
    };
  }
};
