<template>
  <div  class="yxtbiz-group-org-select" :class="{'yxtbiz-group-org-select-single':!multiple}">
    <multiple-select v-if='multiple'
                    :functionCode='functionCode'
                    :dataPermissionCode='dataPermissionCode'
                     :selected='selected'
                     :disabled="disabled"
                     ref='multipleSelect'
                     :checkStrictly='checkStrictly'
                     :setCurrentOrg='setCurrentOrg'
                     @selectedChange='selectedChange'
                     :placeholder="getPlaceholder">
    </multiple-select>
    <single-select v-else
                  :size="size"
                  :selectFirst="selectFirst"
                  :clearable="clearable"
                   @orgInvalid='() => $emit("orgInvalid")'
                   @change='singleChange'
                   :functionCode='functionCode'
                   :dataPermissionCode='dataPermissionCode'
                   :hideOrgIds='hideOrgIds'
                   :disabledOrgIds='disabledOrgIds'
                   :setCurrentOrg='setCurrentOrg'
                   :disabled="disabled"
                   :defaultId='defaultId'
                   :targetOrgId="targetOrgId"
                   :placeholder="getPlaceholder"
                   :allgroup="allgroup"
                   :visibleOrgIds="visibleOrgIds"
    />
  </div>
</template>

<script>
import multipleSelect from './multiple-select';
import singleSelect from './single-select';
import { i18n } from '../../common-util/changeLangs';
export default {
  components: {
    multipleSelect,
    singleSelect
  },
  name: 'YxtbizGroupOrgSelect',
  props: {
    selected: {
      type: Array,
      default: () => []
    },
    functionCode: {
      type: String,
      default: ''
    },
    dataPermissionCode: {
      type: String,
      default: ''
    },
    hideOrgIds: { // 隐藏机构id
      type: Array,
      default: () => []
    },
    disabledOrgIds: { // 禁用机构id
      type: Array,
      default: () => []
    },
    setCurrentOrg: { // 是否默认选中当前登录机构,优先级高于defaultId
      type: Boolean,
      default: false
    },
    defaultId: {
      type: String, // 默认选中机构id
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    placeholder: {
      default: ''
    },
    clearable: {
      type: Boolean,
      default: true
    },
    selectFirst: {
      type: Boolean,
      default: false
    },
    checkStrictly: {
      default: true
    },
    allgroup: {
      default: false
    },
    visibleOrgIds: {
      type: Array,
      default: []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    targetOrgId: { // targetOrgId有值时最高优先级
      type: String,
      default: ''
    },
    size: { // 单选选择器尺寸 medium/small/mini
      type: String,
      default: ''
    }
  },
  data() {
    return {
    };
  },
  computed: {
    enableGroupCorp() {
      return this.$store.state.navManageStore.groupCorpInfo.enableGroupCorp;
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : i18n.t('pc_biz_kng_lbl_chooseorg').d('请选择机构');
    }
  },
  created() {
    // this.$store.dispatch('navManageStore/setGroupCorpInfo');
  },
  mounted() {},
  methods: {
    singleChange(orgInfo, orgList) {
      this.$emit('change', orgInfo, orgList);
    },
    selectedChange(nodes) {
      this.$emit('update:selected', nodes);
      this.$emit('change', nodes);
    },
    init() {
      this.$nextTick(()=>{
        this.$refs['multipleSelect'].reset();
      });
    }
  }
};
</script>
