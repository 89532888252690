var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yxtbiz-group-source-selector-body-source" },
    [
      _vm.sourceType === "4"
        ? _c("yxtbiz-select-kng-catelog-source", {
            ref: "catelogTree",
            attrs: {
              "check-strictly": true,
              checkLists: _vm.cateList,
              disabledList: _vm.disabledIds,
              needParent: "",
            },
            on: {
              "update:checkLists": function ($event) {
                _vm.cateList = $event
              },
              "update:check-lists": function ($event) {
                _vm.cateList = $event
              },
              "node-change": _vm.checkCatelog,
            },
          })
        : _c(
            _vm.comp.compName,
            _vm._b(
              {
                tag: "component",
                attrs: {
                  value: _vm.list.filter(
                    (i) => i.sourceType === _vm.sourceType
                  ),
                  selectable: _vm.selectable,
                },
                on: { select: _vm.select },
              },
              "component",
              _vm.comp.params,
              false
            )
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }