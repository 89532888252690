var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "yxt-col",
    { attrs: { span: 12 } },
    [
      _c(
        "yxt-form-item",
        {
          staticStyle: { width: "100%" },
          attrs: { "label-width": "100px", label: _vm.name },
        },
        [
          _vm.type == 0
            ? _c("select-checkbox", {
                staticClass: "wd100p",
                attrs: {
                  options: _vm.list,
                  placeholder: _vm.$t("pc_biz_lbl_selecttip"),
                  clearable: "",
                },
                on: { change: _vm.changeHanlder },
                model: {
                  value: _vm.itemValue,
                  callback: function ($$v) {
                    _vm.itemValue = $$v
                  },
                  expression: "itemValue",
                },
              })
            : _vm.type == 1
            ? _c("yxt-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  type: "daterange",
                  "range-separator": "-",
                  "start-placeholder": _vm.$t("pc_biz_ls_start").d("开始日期"),
                  "end-placeholder": _vm.$t("pc_biz_ls_end").d("结束日期"),
                  format: "yyyy-MM-dd",
                  "value-format": "timestamp",
                  placeholder: _vm.$t("udp_orgoumgmt_lbl_selectdate"),
                },
                on: { change: _vm.changeHanlder },
                model: {
                  value: _vm.dateValue,
                  callback: function ($$v) {
                    _vm.dateValue = $$v
                  },
                  expression: "dateValue",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }