<template>
  <div class="standard-size-14 yxtbiz-ai-robot__coure">
    <EmptyData v-if="dataList.length === 0" /> 
    <template v-else>
      <div class="search-community-list">
        <div class="search-community-list-tips-info" :class="{'font-size-16': size === 'large'}">
          {{ $t('pc_biz_search_community_content').d('为您找到以下社区内容：') }}
        </div>
        <div class="search-community-list-item" v-for="(item, index) in dataList" :key="index" @click="toDetail(item)">
          <div class="search-community-list-item-title ellipsis-2" :class="{'community-large-title': size === 'large'}">{{ item.title }}</div>
          <div class="search-community-list-item-content ellipsis" v-html="item.content"></div>
          <div class="search-community-list-item-footer">
            <div class="label ellipsis">{{ `${item.boardName ? ($t('pc_biz_publish_lbl_board').d('版块') + ': ' + item.boardName) : ''}` }}</div>
            <div class="divider" v-if="item.boardName"></div>
            <div class="like">{{ item.praiseCount }} {{ $t('pc_biz_lbl_praiseCount').d('赞') }}</div>
            <div class="reply">{{ item.replyCount }} {{ $t('pc_biz_lbl_reply').d('回复') }}</div>
          </div>
        </div>
      </div>
      <div v-if="needMore" class="yxtbiz-ai-robot__coure-more color-gray-8" @click="toMore">
        {{ $t('pc_biz_lbl_common_viewmore') }}
        <yxt-svg :remote-url="`${$staticBaseUrl}ufd/55a3e0`" width="16px" height="16px" icon-class="arrow_right" />
      </div>
    </template>
  </div>
</template>
<script>
import EmptyData from '../common/empty-data.vue';
export default {
  name: 'SearchBbs',
  components: {
    EmptyData
  },
  props: {
    datas: {
      type: Object,
      default: ()=>{
        return {datas: []};
      }
    },
    params: {
      type: Object,
      default: ()=>{}
    },
    size: {
      type: String,
      default: 'small',
      validator: val => ['small', 'large'].includes(val)
    }
  },
  data() {
    return {};
  },
  computed: {
    dataList() {
      return this.datas.datas.map(item => {
        return item;
      });
    },
    needMore() {
      if (this.datas.paging && this.datas.paging.count) {
        return this.datas.paging.count > 3;
      }
      return false;
    }
  },
  created() {
    console.log(this.datas, this.params, this.size, '搜素社区', this.dataList);
  },
  methods: {
    toDetail(item) {
      window.open(`/bbs/#/postdetail/${item.id}`);
    },
    toMore() {
      window.open(`/search/#/gs/community?keyword=${this.params.searchKey}`);
    }
  }
};
</script>
<style scoped lang="scss">
.yxtbiz-ai-robot__coure{
  width: 100%;
}
.search-community-list {
  &-tips-info {
    font-size: 14px;
    font-weight: 400;
    padding: 0 12px 12px;
    color: #8c8c8c;
    line-height: 28px;
  }
  &-item {
    &:hover {
      border-radius: 8px;
      background-color: #F4F5F6;
    }
    cursor: pointer;
    padding: 12px;
    .community-large-title {
      font-size: 16px;
      line-height: 28px;
    }
    &-title {
      color: #262626;
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
    }
    &-content {
      color: #757575;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }
    &-footer {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 12px;
      color: #8C8C8C;
      line-height: 20px;
      .label {
        max-width: 200px;
      }
      .divider {
        background-color: #d8d8d8;
        width: 1px;
        height: 10px;
        margin: 0 8px;
        flex-shrink: 0;
      }
      .like {
        margin-right: 10px;
      }
      .reply {

      }
    }
  }
}

.yxt-divider {
  background-color: #d8d8d8;
}


.yxtbiz-ai-robot__coure-more {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: #f4f5f6;
  }
}
</style>
