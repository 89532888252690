<template>
  <ClickToJump :message="$t('pc_biz_ote_click_to_survey' /* 好的,正在为您唤起创建调查页面 */)" :target-url="targetUrl" :datas="datas" @open="setLocalStorage" />
</template>
 
<script>
import ClickToJump from '../common/click-to-jump.vue';
import CommonMixin from '../common/props-mixin';

export default {
  name: 'CreateSurvey',
  components: {
    ClickToJump
  },
  mixins: [CommonMixin],
  data() {
    return {
      targetUrl: `${window.location.origin}/survey/#/manage`
    };
  },

  methods: {
    setLocalStorage() {
      localStorage['ai-create-survey'] = JSON.stringify(this.params);
      console.log(this.params);
    }
  }
};
 </script>
 