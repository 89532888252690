<template>
  <ClickToJump :message="$t('pc_biz_live_createJumpTip' /* 好的,正在为您唤起创建直播页面 */)" :target-url="targetUrl" :datas="datas" />
</template>
   
<script>
  import ClickToJump from '../common/click-to-jump.vue';
  import propsMixin from '../common/props-mixin.js';

  export default {
    name: 'CreateLive',
    components: {
      ClickToJump
    },
    mixins: [propsMixin],
    data() {
      return {
        targetUrl: `${window.location.origin}/tlive/#/list?isCreate=1`
      };
    }
  };
</script>