var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yxtbiz-msg-editor" },
    [
      _c("yxt-scrollbar", { staticStyle: { height: "100%" } }, [
        _c(
          "div",
          { staticClass: "yxtbiz-msg-editor__body" },
          [
            _c(
              "div",
              {
                staticClass:
                  "yxtbiz-msg-editor__title color-gray-10 text-center",
              },
              [_vm._v("\n        " + _vm._s(_vm.title) + "\n      ")]
            ),
            _c(
              "yxt-form",
              {
                ref: "ruleForm",
                staticClass: "yxtbiz-msg-editor__form",
                attrs: {
                  rules: _vm.rules,
                  model: _vm.ruleForm,
                  "label-width": "162px",
                },
              },
              [
                _c(
                  "yxt-form-item",
                  {
                    attrs: {
                      prop: "name",
                      label: _vm.$t("pc_biz_core_lbl_name"),
                    },
                  },
                  [
                    _c("yxt-input", {
                      attrs: {
                        maxLength: "50",
                        placeholder: _vm.$t("pc_biz_core_tip_enterName"),
                      },
                      model: {
                        value: _vm.ruleForm.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "name", $$v)
                        },
                        expression: "ruleForm.name",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "yxt-form-item",
                  {
                    attrs: {
                      prop: "mobile",
                      label: _vm.$t("pc_biz_core_lbl_phoneNumber"),
                    },
                  },
                  [
                    _c("yxt-input", {
                      attrs: {
                        maxLength: "11",
                        placeholder: _vm.$t("pc_biz_core_tip_enterPhoneNumber"),
                      },
                      model: {
                        value: _vm.ruleForm.mobile,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "mobile", $$v)
                        },
                        expression: "ruleForm.mobile",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "yxt-form-item",
                  {
                    attrs: {
                      prop: "captch",
                      label: _vm.$t("pc_biz_core_lbl_captch"),
                    },
                  },
                  [
                    _c(
                      "yxt-input",
                      {
                        attrs: {
                          maxLength: "10",
                          placeholder: _vm.$t("pc_biz_core_tip_enterCaptch"),
                        },
                        model: {
                          value: _vm.ruleForm.captch,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "captch", $$v)
                          },
                          expression: "ruleForm.captch",
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "font-size-16 mr10",
                            attrs: { slot: "suffix" },
                            slot: "suffix",
                          },
                          [
                            !_vm.reget && _vm.hasMobile
                              ? _c(
                                  "yxtbiz-captcha",
                                  {
                                    staticClass: "hand",
                                    on: {
                                      open: _vm.openCaptch,
                                      success: _vm.toSuccess,
                                    },
                                  },
                                  [
                                    _c(
                                      "yxt-link",
                                      {
                                        staticClass: "font-size-14",
                                        attrs: {
                                          underline: false,
                                          type: "primary",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("pc_biz_core_btn_getCaptch")
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            !_vm.reget && !_vm.hasMobile
                              ? _c(
                                  "span",
                                  { staticClass: "color-gray-6 font-size-14" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("pc_biz_core_btn_getCaptch")
                                      )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.reget
                              ? _c(
                                  "span",
                                  { staticClass: "color-gray-6 font-size-14" },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.timeCountText) +
                                        "s\n              "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm.checkStatus("city")
                  ? _c(
                      "yxt-form-item",
                      {
                        attrs: {
                          prop: "city",
                          label: _vm.$t("pc_biz_core_lbl_city"),
                        },
                      },
                      [
                        _c("yxtbiz-area-select", {
                          attrs: {
                            placeholder: _vm.$t("pc_biz_core_lbl_city"),
                          },
                          on: { change: _vm.changeCity },
                          model: {
                            value: _vm.ruleForm.city,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "city", $$v)
                            },
                            expression: "ruleForm.city",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.checkStatus("company")
                  ? _c(
                      "yxt-form-item",
                      {
                        attrs: {
                          prop: "company",
                          label: _vm.$t("pc_biz_core_lbl_company"),
                        },
                      },
                      [
                        _c("yxt-input", {
                          attrs: {
                            maxLength: "50",
                            placeholder: _vm.$t("pc_biz_core_tip_enterCompany"),
                          },
                          model: {
                            value: _vm.ruleForm.company,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "company", $$v)
                            },
                            expression: "ruleForm.company",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.checkStatus("department")
                  ? _c(
                      "yxt-form-item",
                      {
                        attrs: {
                          prop: "department",
                          label: _vm.$t("pc_biz_core_lbl_department"),
                        },
                      },
                      [
                        _c("yxt-input", {
                          attrs: {
                            maxLength: "50",
                            placeholder: _vm.$t(
                              "pc_biz_core_tip_enterDepartment"
                            ),
                          },
                          model: {
                            value: _vm.ruleForm.department,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "department", $$v)
                            },
                            expression: "ruleForm.department",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.checkStatus("position")
                  ? _c(
                      "yxt-form-item",
                      {
                        attrs: {
                          prop: "position",
                          label: _vm.$t("pc_biz_core_lbl_position"),
                        },
                      },
                      [
                        _c("yxt-input", {
                          attrs: {
                            maxLength: "50",
                            placeholder: _vm.$t(
                              "pc_biz_core_tip_enterPosition"
                            ),
                          },
                          model: {
                            value: _vm.ruleForm.position,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "position", $$v)
                            },
                            expression: "ruleForm.position",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.checkStatus("userNo")
                  ? _c(
                      "yxt-form-item",
                      {
                        attrs: {
                          prop: "userNo",
                          label: _vm.$t("pc_biz_core_lbl_userNo"),
                        },
                      },
                      [
                        _c("yxt-input", {
                          attrs: {
                            maxLength: "50",
                            placeholder: "$t('pc_biz_core_tip_enterUserNo')",
                          },
                          model: {
                            value: _vm.ruleForm.userNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "userNo", $$v)
                            },
                            expression: "ruleForm.userNo",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.checkStatus("email")
                  ? _c(
                      "yxt-form-item",
                      {
                        attrs: {
                          prop: "email",
                          label: _vm.$t("pc_biz_core_lbl_mail"),
                        },
                      },
                      [
                        _c("yxt-input", {
                          attrs: {
                            maxLength: "50",
                            placeholder: _vm.$t("pc_biz_core_tip_enterMail"),
                          },
                          model: {
                            value: _vm.ruleForm.email,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "email", $$v)
                            },
                            expression: "ruleForm.email",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.checkStatus("identitycard")
                  ? _c(
                      "yxt-form-item",
                      {
                        attrs: {
                          prop: "identitycard",
                          label: _vm.$t("pc_biz_core_lbl_identitycard"),
                        },
                      },
                      [
                        _c("yxt-input", {
                          attrs: {
                            maxLength: "50",
                            placeholder: _vm.$t(
                              "pc_biz_core_tip_enterIdentitycard"
                            ),
                          },
                          model: {
                            value: _vm.ruleForm.identitycard,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "identitycard", $$v)
                            },
                            expression: "ruleForm.identitycard",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.checkStatus("sex")
                  ? _c(
                      "yxt-form-item",
                      {
                        attrs: {
                          prop: "sex",
                          label: _vm.$t("pc_biz_core_lbl_gender"),
                        },
                      },
                      [
                        _c(
                          "yxt-radio-group",
                          {
                            model: {
                              value: _vm.ruleForm.sex,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "sex", $$v)
                              },
                              expression: "ruleForm.sex",
                            },
                          },
                          [
                            _c("yxt-radio", { attrs: { label: "2" } }, [
                              _vm._v(_vm._s(_vm.$t("pc_biz_core_lbl_female"))),
                            ]),
                            _c("yxt-radio", { attrs: { label: "1" } }, [
                              _vm._v(_vm._s(_vm.$t("pc_biz_core_lbl_male"))),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.checkStatus("leavingmsg")
                  ? _c(
                      "yxt-form-item",
                      {
                        attrs: {
                          prop: "leavingmsg",
                          label: _vm.$t("pc_biz_core_lbl_leavingmsg"),
                        },
                      },
                      [
                        _c("yxt-input", {
                          attrs: {
                            maxLength: "200",
                            type: "textarea",
                            placeholder: _vm.$t(
                              "pc_biz_core_tip_enterLeavingmsg"
                            ),
                          },
                          model: {
                            value: _vm.ruleForm.leavingmsg,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "leavingmsg", $$v)
                            },
                            expression: "ruleForm.leavingmsg",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.checkStatus("exam")
                  ? _c(
                      "yxt-form-item",
                      {
                        attrs: {
                          prop: "exam",
                          label: _vm.$t("pc_biz_core_lbl_examPwd"),
                        },
                      },
                      [
                        _c("yxt-input", {
                          attrs: {
                            maxLength: "10",
                            placeholder: _vm.$t("pc_biz_core_tip_enterExamPwd"),
                          },
                          model: {
                            value: _vm.ruleForm.exam,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "exam", $$v)
                            },
                            expression: "ruleForm.exam",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("yxt-form-item", { staticStyle: { "margin-top": "40px" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "text-center",
                      staticStyle: { "line-height": "normal" },
                    },
                    [
                      _c(
                        "yxt-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.confirm },
                        },
                        [_vm._v(_vm._s(_vm.$t("pc_biz_core_btn_determine")))]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }