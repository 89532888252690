<template>
  <div class="yxtbiz-group-source-selector">
    <yxt-tabs :drawer-top="true" v-model="activeName">
      <yxt-tab-pane :label="$t('pc_biz_common__select_user').d('选择人员')" name="1"></yxt-tab-pane>
    </yxt-tabs>
    <div class="yxtbiz-group-source-selector-body">
      <yxt-row type="flex" class="yxt-user-selector-container yxt-user-multi-container">
        <yxt-row class="mb16">
          <yxt-input
            :placeholder="$t('biz_udp_enter_name')"
            v-model="params.key"
            class="ml12"
            style="width:240px"
            searchable
            @search="searchUser"
          >
          </yxt-input>
        </yxt-row>
        <yxt-table
          v-loading="loading"
          ref="userTable"
          @select-all="selectAllUser"
          @select="selectUser"
          :height="tableHeight"
          class="yxt-user-table"
          :data="tableData"
          :key="'persons'+timeStamp"
        >
          <yxt-table-column
            :show-overflow-tooltip="false"
            class-name="yxtbiz-table-selection"
            type="selection"
            width="40"
          ></yxt-table-column>
          <yxt-table-column :label="$t('pc_biz_core_lbl_accnum').d('账号')" :show-overflow-tooltip="true"
                            :popover-html="true">
            <template slot-scope="scope">
              <span>{{ scope.row.username }}</span>
            </template>
          </yxt-table-column>
          <yxt-table-column :label="$t('pc_biz_core_lbl_name')" :show-overflow-tooltip="true" :popover-html="true">
            <template slot-scope="scope">
              <yxtbiz-user-name v-if="isOpenData" :name="scope.row.fullname" />
              <span v-else>{{ scope.row.fullname }}</span>
            </template>
          </yxt-table-column>
          <yxt-table-column :label="$t('pc_biz_core_lbl_department')" :show-overflow-tooltip="true"
                            :popover-html="true">
            <template slot-scope="scope">
              <yxtbiz-dept-name v-if="isOpenData" :name="scope.row.deptName" />
              <span v-else>{{ scope.row.deptName }}</span>
            </template>
          </yxt-table-column>
        </yxt-table>
        <yxt-pagination
          class="mt16 align-right"
          :page-size="params.pageSize"
          :page-sizes="[5, 10, 20, 30, 50, 100]"
          @size-change="handleSizeChange"
          :current-page.sync="params.pageIndex"
          simple-total
          layout="total, prev, pager, next"
          @current-change="handleCurrentChange"
          :total="totalPage"
        >
        </yxt-pagination>
      </yxt-row>
      <div class="yxtbiz-group-source-selector-body-selected pull-right pr0">
        <yxt-scrollbar :fit-height="true">
          <div class="yxtbiz-group-source-selector-body-selected-tags">
            <yxt-tag size="small" class="tag-info mr5 mt5 ellipsis" closable @close="remove(item)" type="info"
                     v-for="item in list" :key="item.id">
            <span class="close-tag">
              <yxt-tooltip class="item" effect="dark" placement="top">
                <div slot="content">
                  <yxtbiz-user-name v-if="isOpenData" :name="item.name || item.fullname" />
                  <span v-else>{{ item.name || item.fullname }}</span>
                </div>
                 <yxtbiz-user-name v-if="isOpenData" class="close-tag_width_tail" :name="item.name || item.fullname" />
                 <span v-else class="close-tag_width_tail">{{ item.name || item.fullname }}</span>
                    <!-- <span class="close-tag_width_tail">{{ item.name || item.fullname }}</span> -->
              </yxt-tooltip>
            </span>
            </yxt-tag>
          </div>
        </yxt-scrollbar>
        <yxt-button
          type="text"
          class="user-clear font-size-12"
          @click="clearAll"
          :disabled="Object.values(this.list).length === 0"
        >
          {{ $t('pz_biz_share_btn_clearall').d('清除所有') }}
        </yxt-button>
      </div>
    </div>
  </div>
</template>

<script>
import resizeTable from 'yxt-biz-pc/packages/user-selector/mixins/resizeTable';
import openDataMixin from 'yxt-biz-pc/packages/user-selector/mixins/openData';
import SelectPopover from 'yxt-biz-pc/packages/check-person-range/src/components/SelectPopover';
import { Pagination, Table, TableColumn, Input, Empty } from 'yxt-pc';
import { getNodeUserList } from '../service';

export default {
  name: 'YxtbizGroupSourceSelector',
  data() {
    return {
      refTable: 'userTable',
      list: [], // 已勾选的数据
      tableHeight: 0,
      activeName: '1',
      totalPage: 0,
      selectedPersons: {},
      timeStamp: '',
      tableData: [],
      params: {
        pageIndex: 1,
        pageSize: 20,
        key: '',
        nodeId: this.customNodeId
      },
      loading: false
    };
  },
  components: {
    SelectPopover,
    YxtPagination: Pagination,
    YxtTable: Table,
    YxtTableColumn: TableColumn,
    YxtInput: Input,
    YxtEmpty: Empty
  },
  mixins: [resizeTable, openDataMixin],
  props: {
    selected: {
      type: Array,
      default: []
    },
    disabledIds: {
      type: Array,
      default: ()=>[]
    },
    multiple: { // shifou受否开启多选
      type: Number
    },
    customNodeId: { // 节点id
      type: String
    }
  },
  created() {
    this.list = JSON.parse(JSON.stringify(this.selected));
    this.formatUsers();
    this.getUserList();
  },
  watch: {
    selected(v) {
      this.list = v;
      this.formatUsers();
      this.checkStatus();
    },
    users() {
      this.timeStamp = new Date().valueOf();
    }
  },
  methods: {
    // 全部清除
    clearAll() {
      this.list.forEach(i => {
        delete this.selectedPersons[i.id];
      });
      this.list = [];
      this.$refs['userTable'].clearSelection();
    },
    // 清除单个
    remove(item) {
      this.list = this.list.filter(i => i.id !== item.id);
      this.$refs['userTable'].toggleRowSelection(item, false);
      delete this.selectedPersons[item.id];
    },
    getSelected(type) {
      return this.list;
    },
    handleCurrentChange(val) {
      this.params.pageIndex = val;
      this.getUserList();
    },
    handleSizeChange(val) {
      this.params.pageSize = val;
      this.getUserList();
    },
    getUserList() {
      this.loading = true;
      getNodeUserList(Object.assign({}, this.params, { pageIndex: this.params.pageIndex - 1 })).then(res => {
        this.loading = false;
        this.tableData = res.users;
        this.totalPage = res.total;
        this.$nextTick(() => {
          res.users.forEach(i => {
            if (this.selectedPersons.hasOwnProperty(i.id)) {
              this.$refs['userTable'].toggleRowSelection(i, true);
            }
          });
        });
      }).catch(e => {
        this.loading = false;
      });
    },

    searchUser(val) {
      this.params.key = val;
      this.params.pageIndex = 1;
      this.getUserList();
    },

    // 已勾选数组转对象
    formatUsers() {
      this.selectedPersons = {};
      this.list.forEach(user => {
        this.selectedPersons[user.id] = user;
      });
    },

    selectUser(item, row) {
      let isAdd = item.some(v => {
        return v.id === row.id;
      });
      if (isAdd) {
        !this.multiple && this.list.length && this.remove(this.list.slice(-1)[0]); // 单选模式清空原选中数据
        this.selectedPersons[row.id] = row;
        this.list.push(row);
      } else {
        this.list = this.list.filter(v => v.id !== row.id);
        delete this.selectedPersons[row.id];
      }
    },

    selectAllUser(item) {
      let isAdd = item.length !== 0;
      if (isAdd && !this.multiple) {
        this.$message.warning(this.$t('pc_biz_msg_singleselect').d('当前为单选'));
        this.$refs['userTable'].clearSelection();
        this.tableData.forEach(i => {
          if (this.selectedPersons[i.id]) {
            this.$refs['userTable'].toggleRowSelection(i, true);
          }
        });
        return;
      }
      ;
      // 全部勾选
      if (isAdd) {
        item.forEach(user => {
          if (!this.selectedPersons.hasOwnProperty(user.id)) {
            this.list.push(user);
          }
          this.selectedPersons[user.id] = user;
        });
      } else {
        // 取消勾选
        this.tableData.forEach(i => {
          delete this.selectedPersons[i.id];
        });
        this.list = this.list.filter(i => this.selectedPersons.hasOwnProperty(i.id));
      }
    },
    // 验证是否选中
    checkStatus() {
      if (this.list.length === 0) {
        this.isCancelSelect = false;
        this.$refs['userTable'].clearSelection();
      } else {
        this.list.forEach(user => {
          if (this.selectedPersons.hasOwnProperty(user.id)) {
            this.$refs['userTable'].toggleRowSelection(user, true);
          } else {
            this.$refs['userTable'].toggleRowSelection(user, false);
          }
        });
      }
    }

  }
};
</script>
<style lang="scss" scoped>
.tag-info {
  max-width: calc(50% - 5px)
}

.align-right {
  text-align: right;
}

.pr0 {
  padding-right: 0;
  padding-bottom: 36px;
}
</style>
