var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "standard-size-14 yxtbiz-ai-robot__coure" },
    [
      _vm.dataList.length === 0
        ? _c("EmptyData")
        : [
            _c(
              "div",
              {
                staticClass: "color-gray-7 yxtbiz-ai-robot__coure-title",
                class:
                  _vm.size === "small"
                    ? "font-size-14 lh22"
                    : "font-size-16 lh24",
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$t("pc_biz_ote_lbl_search_content", [
                        _vm.$t("pc_biz_ai_lbl_knglib"),
                      ])
                    ) +
                    "\n    "
                ),
              ]
            ),
            _vm._l(_vm.dataList, function (item) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "yxtbiz-ai-robot__coure-cell",
                  on: {
                    click: function ($event) {
                      return _vm.toDetail(item)
                    },
                  },
                },
                [
                  _c("yxtf-svg", {
                    attrs: {
                      width: "48px",
                      height: "48px",
                      "icon-class": _vm.getSvgName(item.fileType),
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "ml12 yxtbiz-ai-robot__course-contain" },
                    [
                      _c("div", {
                        staticClass: "ellipsis-2 mb4",
                        class:
                          _vm.size === "small"
                            ? "font-size-14 lh22"
                            : "font-size-16 lh24",
                        domProps: { innerHTML: _vm._s(item.title) },
                      }),
                      item.summary && _vm.size !== "small"
                        ? _c("div", { staticClass: "summary mb4" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(item.summary) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "coure-flex-center" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "standard-size-12 color-gray-7 mt4 ellipsis-1 search-knglib-small",
                          },
                          [
                            _c("span", { staticClass: "ellipsis-1" }, [
                              _vm._v(
                                _vm._s(
                                  item.updateUsername || item.createUsername
                                )
                              ),
                            ]),
                            _c("yxt-divider", {
                              attrs: { direction: "vertical" },
                            }),
                            _c(
                              "span",
                              { staticClass: "search-knglib-small-nowrap" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.timestampToTime(
                                      _vm.notFile(item)
                                        ? item.updateTime
                                        : item.createTime
                                    )
                                  ) +
                                    " " +
                                    _vm._s(
                                      _vm.$t(
                                        `pc_search_global_${
                                          !_vm.notFile(item)
                                            ? "create"
                                            : "update"
                                        }`
                                      )
                                    )
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              )
            }),
            _vm.datas.paging.count > 3
              ? _c(
                  "div",
                  {
                    staticClass: "yxtbiz-ai-robot__course-more color-gray-8",
                    on: { click: _vm.toMore },
                  },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$t("pc_biz_lbl_common_viewmore")) +
                        "\n      "
                    ),
                    _c("yxt-svg", {
                      attrs: {
                        "remote-url": `${_vm.$staticBaseUrl}ufd/55a3e0`,
                        width: "16px",
                        height: "16px",
                        "icon-class": "arrow_right",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }