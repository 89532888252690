<!-- 创建时间2023/11/17 14:18:09 -->
<!-- 创建人：Zhao bing bing -->
<!-- 组件描述：查找课程 -->
<template>
  <KngRelated type="searchCourse" v-bind="$attrs" v-on="$listeners"/>
</template>

<script>
import KngRelated from '../common/kng/kng-releated.vue';
export default {
  name: 'KngSearchCourse',
  components: {
    KngRelated
  }
};
</script>