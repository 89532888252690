import { qidaApi, apiBaseUrl } from 'yxt-biz-pc/packages/api';

export const getImportList = (type = 0) => {
  // 1 未完成 0 全量
  let param = {
    type
  };
  return apiBaseUrl.get('/aueng/au/user/tasks', param);
};

// 判断当前token是否为2.0且可用 http://api-phx-di-ali.yunxuetang.com.cn/core/doc.html#/default/token/checkUsingGET
export const checkToken = () => {
  return qidaApi.get('/token/check');
};

// 调用关闭接口，当前已完成的数据下一次不再展示
export const closeList = () => {
  return apiBaseUrl.get('/aueng/au/closed');
};
