<template>
  <div class="yxtbiz-ai-robot-demo__dialog--msg is-answer" :class="{'is-long-size': isSearch, 'bbs-search': mode === 'bbs', 'fullscreen': fullscreen}">
    <div :class="{'head-img':isSearch, 'mr12': !isSearch}" v-if="showPortrait" >
      <yxtf-portrait size="28px" @click="isSearch && !isWaitAnswer && $emit('launch')"  :class="{ 'hand': isSearch}"  :imgUrl="settings.assistHeadImg"></yxtf-portrait>
      <span v-if="isSearch">问一问</span>
    </div>
    <div v-if="msg.answerStatus === 0" class="is-answer-loading">
      <div class="is-answer-loading-text font-size-16">{{ $t('pc_biz_ai_thinking').d('正在思考') }}</div>
      <div class="is-loading"><div></div></div>
    </div>
    <div v-else-if="msg.answerStatus === 2" class="is-content font-size-16">{{ msg.contentDetail && msg.contentDetail.content }}<yxt-button class="internet-btn" v-if="settings.publicResourceEnabled" type="text" @click="$emit('resend', 'askInternet')">&nbsp;{{ $t('pc_biz_ai_question_tips').d('互联网问问') }}</yxt-button></div>
    <div v-else-if="msg.answerStatus === 3" class="is-content font-size-16">{{ (msg.contentDetail && msg.contentDetail.content) || settings.assistWele || $t('pc_biz_ai_question_default_tips').d('你好，我是AI助手，很高兴为你服务！') }}</div>
    <div v-else>
      <div class="is-content is-answer-content" :class="{'is-expanded': isExpanded }">
        <msgToolBtn :like="like" :msg="msg" v-show="!isSearch" @toolClick="toolClick" :isanswer="true"/>
        <div ref="content" class="answer-content font-size-16" v-html="content"></div>
        <div v-show="needShowMoreCover" class="is-more-cover">
          <div></div>
          <div class="text-center hand" @click="isExpanded = !isExpanded">
            <yxtf-link class="font-size-12" type="primary" :underline="false">{{ isExpanded ? $t('pc_biz_ai_retract').d('收起') : $t('pc_biz_ai_expand_all').d('展开全部') }}</yxtf-link>
            <i :class="isExpanded ? 'yxt-icon-arrow-up' : 'yxt-icon-arrow-down'"></i>
          </div>
        </div>
        <div v-if="msg.contentDetail && msg.contentDetail.linkList && msg.contentDetail.linkList.length" class="font-size-12 mt10">
          <div class="ai-content-source" style="line-height: 26px;">内容来源：</div>
          <div class="d-content" v-for="(source, index) in msg.contentDetail.linkList" :key="index">
              <div v-if="source.goUrl" @click="goUrl(source.goUrl)" class="d-content-item">
                {{source.name}}
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  submitQuestionLike
} from '../service';
import msgToolBtn from './msgToolBtn.vue';
export default {
  name: 'Answer',
  components: {
    msgToolBtn
  },
  props: {
    isWaitAnswer: {
      type: Boolean,
      default: false
    },
    showPortrait: {
      type: Boolean,
      default: false
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    msg: {
      type: Object,
      default: () => {}
    },
    isSearch: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'base'
    }
  },
  computed: {
    sourceDividerLength() {
      if (!this.msg.contentDetail) return 0;
      const { linkList } = this.msg.contentDetail;
      return linkList ? linkList.length - 1 : 0;
    },
    likeIconClass() {
      if (this.like === 0) return ['like', 'like'];
      return this.like === 1 ? ['like-hover', 'like-disable'] : ['like-disable', 'like-hover'];
    }
  },
  mounted() {
    console.log('settings', this.settings);
  },
  watch: {
    msg: {
      immediate: true,
      handler(v) {
        v.answerStatus && this.outputContent(); // 更新消息体
      }
    },
    'msg.chatLikeFlag'(v) { // 更新点赞状态
      this.like = v;
    },
    'msg.chatCorrectEnabled'(v) { // 更新反馈状态
      this.hasFeedback = v;
    }
  },
  inject: ['settings'],
  data() {
    return {
      needShowMoreCover: false,
      isExpanded: false,
      content: '',
      svgUrl: `${this.$staticBaseUrl}ufd/407a24/assistent/pc/svg/`,
      hasFeedback: this.msg.chatCorrectEnabled,
      timerId: null,
      like: this.msg.chatLikeFlag // 1-like  2-dislike
    };
  },
  methods: {
    outputContent() {
      if (this.msg.contentDetail && this.msg.contentDetail.content) {
        this.content = this.msg.contentDetail.content;
      }
      this.$nextTick(this.updateMoreCoverShow);
    },
    updateMoreCoverShow() {
      const content = this.$refs.content;
      this.needShowMoreCover = content ? content.scrollHeight > 230 : false;
    },
    // 工具按钮交互事件
    toolClick(type) {
      if (type === 'copy') {
        this.copyText(this.$refs.content);
      } else {
        this.$emit('resend', 'onceAgin');
      }
    },
    copyText(copyDom) {
      // 创建text area
      const textArea = document.createElement('textarea');
      textArea.value = copyDom.innerText;
      console.log('textArea.value', textArea.value);
      textArea.style.position = 'absolute';
      textArea.style.opacity = '0';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select(); // 选中文本
      document.execCommand('Copy'); // 执行浏览器复制命令
      this.$message.success(this.$t('pc_biz_copy_success').d('复制成功！'));
    },
    handleLike(like) {
      console.log('this.msg', this.msg);
      if (this.like) return;
      let likeAnswer = true;
      if (like === 2) {
        likeAnswer = false;
        this.$emit('feedback');
      }
      submitQuestionLike({ chatContentId: this.msg.chatContentId || this.msg.chatRecordId, likeAnswer: likeAnswer }).then(() => {
        this.like = like;
      }).catch(err => {
        if (err && err.error && err.error.message) {
          this.$message.error(err.error.message);
        }
      });
    },
    goUrl(url) {
      window.open(url, '_blank');
    }
  },
  beforeDestroy() {
    this.timerId && clearInterval(this.timerId);
  }
};
</script>
