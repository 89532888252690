<!-- 创建时间2023/11/06 13:44:37 -->
<!-- 创建人：Zhao bing bing -->
<!-- 组件描述：小助手组件，kng相关的 -->
<!-- 上传课件，创建课程，查找课件，查找课程，自学课程, 创建空间，上传知识，搜索笔记 -->
<template>
  <!-- 直接跳转 -->
  <click-to-jump :datas="datas" v-if="type === 'createCourse' || type === 'createCourseware' || type === 'uploadKnowledge'" :message="message" :targetUrl="targetUrl"/>
  <!-- 创建空间 -->
  <div v-else-if="type === 'createSpace'" class="ph12" :class="size === 'small' ? 'standard-size-14' : 'standard-size-16'">
    <span>{{ $t('pc_biz_lbl_createspace') }}</span><yxt-button class="ml8" type="text" @click="toDetail">{{ $t('pc_biz_bbs_lbl_viewDetail') }}</yxt-button>
  </div>
  <!-- 列表数据 -->
  <div v-else-if="!isEmptyData" class="yxtbiz-ai-robot__kng standard-size-14">
    <div class="mb12 ml12 color-gray-7" :class="size === 'small' ? 'standard-size-14' : 'standard-size-16'">{{title}}</div>
    <!-- 笔记 -->
    <template v-if="type === 'searchNote'">
      <div class="mt4 mb4 ph12 yxtbiz-flex-center">
        <div class="yxtbiz-ai-robot__kng-tab" :class="noteTab === 0 ? 'yxtbiz-ai-robot__kng-tab--active' : ''" @click="noteTab = 0">{{ $t('pc_biz_lbl_mypublish'/** 我发布的 */) }}</div>
        <div class="yxtbiz-ai-robot__kng-tab ml12" :class="noteTab === 1 ? 'yxtbiz-ai-robot__kng-tab--active' : ''" @click="noteTab = 1">{{ $t('pc_biz_lbl_mypraise'/** 我点赞的 */) }}</div>
      </div>
      <div class="yxtbiz-ai-robot__kng-note-cell" v-for="item in dataList" :key="item.id" @click="toDetail(item)">
        <yxtf-portrait 
          class="mr12 flex-shrink-0"
          size="48px"
          :imgUrl="item.userUrl"
          :username="item.fullName" />
        <div class="flex-1 width60">
          <div class="yxtbiz-flex-center over-hidden">
            <yxtbiz-user-name class="yxt-weight-5 ellipsis" :name="item.fullName" />
            <span class="color-gray-7 standard-size-12 ml12 ellipsis">{{ handleDateString(item.createTime) }}</span>
            <!-- 私密，被举报，精选 -->
            <yxt-tag v-if="item.featured" class="ml4" type="warning" size="mini">{{ $t('yxtbiz_ai_comments_lbl_excellent'/** 精选 */) }}</yxt-tag>
            <yxt-tag v-if="item.reported" class="ml4" type="danger" size="mini">{{ $t('yxtbiz_ai_mgmt_lbl_status_report'/** 被举报 */) }}</yxt-tag>
            <yxt-tag v-if="item.privat" class="ml4" type="info" size="mini">{{ $t('pc_biz_lbl_private'/** 私密 */) }}</yxt-tag>
          </div>
          <note-item-cell :isInCourse="item.kngType === KNG_TYPE.COURSE" :note="item" effect="light"/>
          <div class="mt8 color-gray-7 standard-size-12" v-if="item.kngName">{{ $t('pc_biz_lbl_coursename',[item.kngName]) }}</div>
        </div>
      </div>
      <yxt-empty v-if="dataList.length === 0" :empty-text="$t('pc_biz_lbl_nonotedata')"/>
    </template>
    <!-- 其他 -->
    <template v-else>
      <div class="yxtbiz-ai-robot__kng-cell" v-for="item in dataList" :key="item.id" @click="toDetail(item)">
        <yxt-image class="yxtbiz-ai-robot__kng-cover"  v-if="item.coverUrl" :src="item.coverUrl" fit="cover" />
        <div class="ml12 flex-1 over-hidden">
          <div class="ellipsis-2 break" :class="size === 'small' ? 'standard-size-14' : 'standard-size-16'"><yxt-tag v-if="type === 'selfCourse' && item.leaderRecommend" class="mr8" type="warning" size="mini">{{$t('pc_kng_studyplan_lbl_leadre')}}</yxt-tag>{{item.title}}</div>

          <div class="kng-flex-center kng-flex-wrap" :class="size === 'small' ? 'mt4' : 'mt8'">
            <!-- 自学课程 -->
            <template v-if="type === 'selfCourse'">
              <div class="w155 kng-flex-center" v-if="item.schedule < 100">
                <yxt-progress class="flex-1" :percentage="item.schedule" size="small" :show-text="false"/>
                <span class="ml8 standard-size-12 color-gray-8">{{ $t("pc_kng_plancard_lbl_have_leaned"/** 已学{0}% */, [parseInt(item.schedule || 0)]) }}</span>
              </div>
              <div v-else class="kng-flex-center">
                <yxtf-svg 
                  width="16px"
                  height="16px"
                  icon-class="biz/result-success-icon" />
                <span class="ml4 standard-size-12 color-gray-8"> {{ $t("pc_kng_study_lbl_have_leaned"/** 已完成学习 */) }}</span>
              </div>
              <yxt-divider v-if="size !== 'small' " direction="vertical"></yxt-divider>
              <div class="standard-size-12 color-gray-7"  :class="size === 'small' ? 'mt2 width-percent-100' : ''">{{getPlanTime(item)}}</div>
            </template>
            <!-- 其他 -->
            <template v-else>
              <div class="standard-size-12 color-gray-7 mr20 kng-inline-flex-center over-hidden">
                <yxtbiz-user-name class="ellipsis-1" :name="item.contributorsName"/><yxt-divider direction="vertical"></yxt-divider>
                <span class="kng-no-wrap">{{$t('pc_kng_common_lbl_update_time',[getTimeString(item.updateTime)])}}</span>
              </div>
              <div class="standard-size-12 color-gray-7 ellipsis-1 kng-no-wrap"  :class="size === 'small' ? 'mt2 width-percent-100' : ''">{{$t('pc_biz_lbl_studycount',[item.studyCount])}}</div>
            </template>
          </div>
        </div>
      </div>
    </template>
    <!-- 查看更多 -->
    <div v-if="showMore" class="yxtbiz-ai-robot__kng-more color-gray-8" @click="toMore">
      {{$t('pc_biz_lbl_common_viewmore')}}
      <yxt-svg :remote-url="`${$staticBaseUrl}ufd/55a3e0`" width="16px" height="16px" icon-class="arrow_right" />
    </div>
  </div>
  <!-- 空数据 -->
  <empty-data v-else/>
</template>

<script>
import commonUtil from 'yxt-biz-pc/packages/common-util';
import {getPlanTime, getTimeString, handleDateString} from './utils';
import propsMixin from '../props-mixin';
import ClickToJump from '../click-to-jump.vue';
import EmptyData from '../empty-data.vue';
import qs from 'qs';
import { kngApi } from 'yxt-biz-pc/packages/api';
import { KNG_TYPE } from 'yxt-biz-pc/packages/select-kng/src/const';
import NoteItemCell from './components/note-item-cell.vue';
export default {
  name: 'KngRelated',
  mixins: [propsMixin],
  components: {
    ClickToJump,
    EmptyData,
    NoteItemCell
  },
  props: {
    type: {
      type: String,
      default: '' // searchCourse | searchCourseware | selfCourse | createCourse | createCourseware | uploadKnowledge | createSpace | searchNote
    }
  },
  data() {
    return {
      KNG_TYPE,
      noteTab: 0 // 0'publish' | 1'praise'
    };
  },
  computed: {
    message() {
      if (this.type === 'createCourseware') return this.$t('pc_biz_lbl_createcourseware'/** 好的，正在为您唤起上传课件页面 */);
      else if (this.type === 'uploadKnowledge') return this.$t('pc_biz_lbl_uploadknowledge'/** 好的，正在为您唤起上传知识页面 */);
      return this.$t('pc_biz_lbl_createcourse'/** 好的，正在为您唤起创建课程页面 */);
    },
    targetUrl() {
      if (this.type === 'createCourseware') return `${window.location.origin}/kng/#/courseware/mgmt`;
      else if (this.type === 'uploadKnowledge') return `${window.location.origin}/knglib/#/spaceDetail?spaceId=${this.params.libId}`;
      return `${window.location.origin}/kng/#/course/mgmt`;
    },
    isEmptyData() {
      try {
        if (this.type === 'searchNote') {
          return this.datas.data[0].paging.count === 0 && this.datas.data[1].paging.count === 0;
        }
        return this.datas.paging.count === 0;
      } catch (e) {}
      return true;
    },
    dataList() {
      try {
        if (this.type === 'searchNote') {
          return this.datas.data[this.noteTab].datas;
        }
        return this.datas.datas;
      } catch (e) {}
      return [];
    },
    showMore() {
      try {
        if (this.type === 'searchNote') {
          return this.datas.data[this.noteTab].paging.count > 3;
        }
        return this.datas.paging.count > 3;
      } catch (e) {}
      return false;
    },
    title() {
      const titleMay = {
        searchCourseware: this.$t('pc_biz_lbl_findthiscourseware'/** 为您找到以下课件： */),
        searchCourse: this.$t('pc_biz_lbl_findthiscourse'/** 为您找到以下课程： */),
        searchNote: this.$t('pc_biz_lbl_findthisnote'/** 为您找到以下笔记： */)
      };
      return titleMay[this.type];
    }
  },
  methods: {
    handleDateString,
    getPlanTime,
    getTimeString,
    toDetail(item) {
      if (this.type === 'searchNote') {
        window.open(`/kng/#/notedetail?id=${item.id}`);
      } else if (this.type === 'createSpace') {
        window.open(`/knglib/#/spaceDetail?spaceId=${this.datas.id}`);
      } else if (this.type === 'selfCourse') {
        const {kngId} = item;
        window.open(`/kng/#/scan?kngId=${kngId}`);
      } else {
        const { id, type, catalogId, shareFlag = 0, shareKngType = 0, collegeId, status, envFlag} = item;
        const isMainCollege = collegeId === localStorage.orgId ? 1 : 0;
        const isCourse = type === KNG_TYPE.COURSE;
        const hasEditBtn = isCourse ? commonUtil.checkActionPermission('kng_course_mgmt', 'Course_detail', this) : commonUtil.checkActionPermission('kng_courseware_mgmt', 'Knowledge_detail', this) ;
        if (!hasEditBtn) {
          return this.$message.warning(this.$t('pc_biz_lbl_nopermissionview'));
        }
        if (![4, 6, 12].includes(status)) {
          return this.$message.warning(this.$t('pc_biz_lbl_notpublished'));
        }
        kngApi.post('/ulcd/kngs', {kngIds: [id]})
          .then(res => {
            if (res[0].deleted === 1) {
              return this.$message.warning(this.$t(isCourse ? 'pc_kng_course_tip_deleted' : 'pc_kng_courseware_tip_deleted'));
            }
            const url = isCourse ? '/kng/#/course/info' : '/kng/#/courseware/info';
            const query = {
              kngId: id,
              type,
              catalogId,
              hasEditBtn: 1,
              shareFlag,
              isMainCollege,
              shareKngType,
              private: !envFlag
            };
            window.open(`${url}?${qs.stringify(query)}`); // 课件 & 课程
          });
      }
    },
    toMore() {
      // 需要带上参数 关键子等
      const query = qs.stringify(this.params);
      switch (this.type) {
        case 'searchNote':
          window.open(`/kng/#/studynote?tab=${this.noteTab}&` + query);
          break;
        case 'searchCourse':
          window.open('/kng/#/course/mgmt?' + query);
          break;
        case 'searchCourseware':
          window.open('/kng/#/courseware/mgmt?' + query);
          break;
        case 'selfCourse':
          window.open('/kng/#/selfstudy?' + query);
          break;
        default:
          break;
      }
    }
    // 学习计划页面时间处理
  }
};
</script>