var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "yxtbiz-ai-robot yxtbiz-ai-robot-search" },
        [
          _vm.answerMsg
            ? _c("answer", {
                attrs: {
                  mode: _vm.mode,
                  "show-portrait": "",
                  "is-search": "",
                  msg: _vm.answerMsg,
                },
                on: {
                  feedback: function ($event) {
                    _vm.feedbackDialogVisible = true
                  },
                  resend: _vm.resend,
                  launch: _vm.launchHandler,
                },
              })
            : _vm._e(),
          _vm.mode === "base"
            ? _c("feedback-dialog", {
                attrs: { visible: _vm.feedbackDialogVisible },
                on: {
                  "update:visible": function ($event) {
                    _vm.feedbackDialogVisible = $event
                  },
                  success: _vm.updateFeedback,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("aiRobot", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.show,
            expression: "show",
          },
        ],
        attrs: { sessionId: _vm.sessionId },
        on: { close: _vm.onClose },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }