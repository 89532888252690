var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yxtbiz-ai-robot-cer-list standard-size-14" },
    [
      _c(
        "header",
        {
          staticClass: "mb12 ph12 color-gray-7",
          class: { "standard-size-16": _vm.size === "large" },
        },
        [
          _vm._v(
            _vm._s(
              _vm
                .$t("pc_biz_cer_lbl_search_cer_for_you")
                .d("为您找到以下证书：")
            )
          ),
        ]
      ),
      _vm._l(_vm.datas, function (item) {
        return _c(
          "div",
          {
            key: item.cerIssueId,
            staticClass: "yxtbiz-ai-robot-cer-list-cell",
            on: {
              click: function ($event) {
                return _vm.toDetail(item)
              },
            },
          },
          [
            _c("yxt-image", {
              staticClass: "yxtbiz-ai-robot-cer-list-cover",
              attrs: {
                width: "68px",
                height: "68px",
                src:
                  item.userCerUrl ||
                  `${_vm.$staticBaseUrl}ufd/55a3e0/cer/img/ai-cer-empty.png`,
                fit: "cover",
              },
            }),
            _c(
              "div",
              {
                staticClass:
                  "ml12 flex-1 flex yxtbiz-ai-robot-cer-list-content",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "ellipsis-2",
                    class: { "standard-size-16": _vm.size === "large" },
                  },
                  [_vm._v(_vm._s(item.cerName))]
                ),
                _vm.size === "small"
                  ? _c(
                      "div",
                      { staticClass: "mt4 standard-size-12 color-gray-7" },
                      [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("pc_biz_cer_lbl_cer_id").d("证书编号")
                            ) +
                              "：" +
                              _vm._s(item.no)
                          ),
                        ]),
                        _c("div", { staticClass: "mt2" }, [
                          _vm._v(
                            _vm._s(_vm.$t("pc_biz_cer_lbl_time").d("时间")) +
                              "：" +
                              _vm._s(
                                item.expiredTime
                                  ? `${item.issueTime}~${item.expiredTime}`
                                  : _vm
                                      .$t("pc_biz_cer_lbl_no_expired_time")
                                      .d("永久有效")
                              )
                          ),
                        ]),
                      ]
                    )
                  : _c(
                      "div",
                      {
                        staticClass:
                          "mt8 standard-size-12 color-gray-7 flex yxtbiz-ai-robot-cer-list-content-footer",
                      },
                      [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("pc_biz_cer_lbl_cer_id").d("证书编号")
                            ) +
                              "：" +
                              _vm._s(item.no)
                          ),
                        ]),
                        _c("yxt-divider", { attrs: { direction: "vertical" } }),
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.$t("pc_biz_cer_lbl_time").d("时间")) +
                              "：" +
                              _vm._s(
                                item.expiredTime
                                  ? `${item.issueTime}~${item.expiredTime}`
                                  : _vm
                                      .$t("pc_biz_cer_lbl_no_expired_time")
                                      .d("永久有效")
                              )
                          ),
                        ]),
                      ],
                      1
                    ),
              ]
            ),
          ],
          1
        )
      }),
      _c(
        "div",
        {
          staticClass: "yxtbiz-ai-robot__kng-more color-gray-8",
          on: { click: _vm.toMore },
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("pc_biz_ote_lbl_viewmore").d("查看更多")) +
              "\n    "
          ),
          _c("yxt-svg", {
            attrs: {
              "remote-url": `${_vm.$staticBaseUrl}ufd/55a3e0`,
              width: "16px",
              height: "16px",
              "icon-class": "arrow_right",
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }