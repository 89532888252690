var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "yxt-select",
    {
      ref: "grade-select",
      attrs: {
        "popper-class": "yxtbiz-grade-select",
        "value-key": "id",
        "prop-label": "name",
        clearable: true,
        "collapse-tags": true,
        multiple: "",
        filterable: "",
        "filter-method": _vm.filterMethod,
      },
      on: { clear: _vm.clear, change: _vm.change, "remove-tag": _vm.removeTag },
      model: {
        value: _vm.selected,
        callback: function ($$v) {
          _vm.selected = $$v
        },
        expression: "selected",
      },
    },
    [
      _vm.filterOptions.length
        ? _c(
            "div",
            {
              staticClass: "lh44",
              attrs: { slot: "fixed-header" },
              slot: "fixed-header",
            },
            [
              _c(
                "yxt-checkbox",
                {
                  key: _vm.key,
                  attrs: { indeterminate: _vm.isIndeterminate },
                  on: { change: _vm.changeAll },
                  model: {
                    value: _vm.isAll,
                    callback: function ($$v) {
                      _vm.isAll = $$v
                    },
                    expression: "isAll",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("pc_biz_udp_lbl_selectAll")))]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._l(_vm.filterOptions, function (item) {
        return _c(
          "yxt-option",
          {
            key: item.id,
            staticStyle: {
              height: "auto",
              "max-height": "200px",
              overflow: "auto",
            },
            attrs: { value: item.id, label: item.name },
          },
          [
            _c(
              "yxt-checkbox",
              {
                staticStyle: { width: "100%" },
                attrs: {
                  "change-text-color": true,
                  value: _vm.testChecked(item),
                },
                on: {
                  change: function ($event) {
                    return _vm.changeGrade($event, item)
                  },
                },
              },
              [
                item.newLabel
                  ? _c("div", {
                      domProps: { innerHTML: _vm._s(item.newLabel) },
                    })
                  : _c("div", [
                      _vm._v("\n        " + _vm._s(item.name) + "\n      "),
                    ]),
              ]
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }