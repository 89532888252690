<template>
  <div class="yxtbiz-i18n-text">
    <div>
      <yxt-input
        v-model="transObj.trans"
        :type="dataObj.type"
        v-bind="transObj.rule"
        :placeholder="getTranslate('pc_biz_core_tip_placeholder')"
        :maxlength="getMaxlength()"
        :show-word-limit="dataObj.rule && dataObj.rule.showWordLimit"
        @focus="openDrawer"
      >
        <template v-if="dataObj.type == 'text'" slot="suffix">
          <yxt-svg class="yxt-input-suffix-svg-btn hover-primary-6 yxtbiz-i18n-text__icon-hover" width="16px" height="16px" icon-class="language" @click.native="drawer = true"/>
        </template>
        <i
          v-if="dataObj.type == 'textarea'"
          slot="suffix"
          class="yxt-icon-edit yxt-input__icon"
        >
        </i>
      </yxt-input>
      <div v-if="dataObj.type == 'textarea'" class="yxtbiz-i18n-text__icon-btn yxtbiz-i18n-text__icon-hover" @click="drawer = true">
        <yxt-svg class="yxt-input-suffix-svg-btn hover-primary-6" width="16px" height="16px" icon-class="language" />
      </div>
    </div>
    <div v-if="drawer">
      <yxtbiz-i18n-lang  :visible.sync="drawer" :data-list="dataObj" @confirm="getResult"></yxtbiz-i18n-lang>
    </div>
  </div>
</template>

<script>
  import { getLanguage } from 'yxt-i18n/es';
  export default {
    name: 'YxtbizI18nText',
    props: {
      transObj: {
        type: Object,
        default: () => {}
      },
      inputFocusOpenDrawer: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      locale() {
        return getLanguage();
      },
      dataObj() {
        let trans = [];
        let dataObj = this.deepClone(this.transObj);
        if (typeof this.transObj.trans !== 'object') {
          trans = [
            {
              langTag: this.locale,
              transContent: this.transObj.trans || ''
            }
          ];
        } else {
          Array.isArray(this.transObj.trans) ? trans = this.transObj.trans : trans.push(this.transObj.trans);
        }
        dataObj.rule.readonly = dataObj.rule.i18nreadonly || false;
        dataObj.trans = trans;
        return dataObj;
      }
    },
    data() {
      return {
        drawer: false,
        transArr: [
          {
            name: '请输入',
            langKey: 'pc_biz_core_tip_placeholder'
          }
        ]
      };
    },
    methods: {
      getTranslate(key) {
        if (this.$attrs.module === 'op') {
          let obj = this.transArr.find(item => item.langKey === key);
          return obj ? obj.name : key;
        } else {
          return this.$t(key);
        }
      },
      deepClone(target) {
        if (!target || typeof target !== 'object') {
          return target;
        }
        const resObj = Array.isArray(target) ? [] : {};
        for (const k in target) {
          resObj[k] = this.deepClone(target[k]);
        }
        return resObj;
      },
      getResult(data) {
        this.transObj.trans = data.keys[0].trans.find(item => item.langTag === getLanguage()).transContent;
        this.$emit('confirm', data.keys[0].trans);
      },
      openDrawer() {
        this.inputFocusOpenDrawer && (this.drawer = true);
      },
      // maxlength 可能是数字，可能是对象，做兼容处理
      getMaxlength() {
        if (!this.transObj.rule || !this.transObj.rule.maxlength) return;
        // 数字或者字符串
        if (typeof this.transObj.rule.maxlength === 'number' || typeof this.transObj.rule.maxlength === 'string') {
          return Number(this.transObj.rule.maxlength);
        }
        // 对象
        if (typeof this.transObj.rule.maxlength === 'object') {
          const lang = localStorage.getItem('yxtLang');
          return this.transObj.rule.maxlength[lang] || this.transObj.rule.maxlength['zh'];
        }
        return;
      }
    }
  };
</script>