var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "orgSelect", staticClass: "org-multiple-select" }, [
    _c(
      "div",
      {
        staticClass: "yxt-input__inner",
        staticStyle: { display: "block", position: "relative" },
        on: { click: _vm.handleClick },
      },
      [
        _vm.tags && _vm.tags.length
          ? _vm._l(_vm.tags, function (tag) {
              return _c(
                "yxt-tag",
                {
                  key: tag.orgId,
                  ref: tag.orgId,
                  refInFor: true,
                  style: {
                    marginRight: _vm.marginRight + "px",
                  },
                  attrs: {
                    closable: _vm.closeable(tag),
                    "disable-transitions": "",
                    size: "mini",
                    type: "info",
                  },
                  on: {
                    close: function ($event) {
                      return _vm.close(tag)
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      ref: "text" + tag.orgId,
                      refInFor: true,
                      staticClass: "ellipsis",
                    },
                    [_vm._v(_vm._s(tag.orgName))]
                  ),
                ]
              )
            })
          : _c("span", { staticClass: "color-gray-6" }, [
              _vm._v(_vm._s(_vm.placeholder)),
            ]),
        _c("i", {
          staticClass: "yxt-input__icon color-gray-6",
          class: {
            "yxt-icon-arrow-up": _vm.visible,
            "yxt-icon-arrow-down": !_vm.visible,
          },
          staticStyle: {
            position: "absolute",
            right: "8px",
            top: "50%",
            transform: "translateY(-50%)",
          },
        }),
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "org-select-dropdown" },
      [
        _c("yxt-collapse-transition", [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.visible,
                  expression: "visible",
                },
              ],
              staticClass: "mv24",
            },
            [
              _c("yxt-tree", {
                ref: "tree",
                attrs: {
                  "check-strictly": _vm.checkStrictly,
                  props: {
                    label: "orgName",
                    children: "childList",
                  },
                  "wrap-width": "100%",
                  data: _vm.treeData,
                  "show-checkbox": "",
                  "node-key": "orgId",
                  "default-expand-all": "",
                  "expand-on-click-node": false,
                },
                on: { check: _vm.nodeClick },
              }),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }