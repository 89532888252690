<template>
  <div class="standard-size-14 yxtbiz-ai-robot__coure">
    <EmptyData v-if="dataList.length === 0" /> 
    <template v-else>
      <div class="search-enroll-list">
        <div class="search-enroll-list-tips-info" :class="{'font-size-16': size === 'large'}">
          {{ $t('pc_biz_search_enroll_content').d('为您找到以下报名：') }}
        </div>
        <div class="search-enroll-list-item" v-for="(item, index) in dataList" :key="index" @click="toDetail(item)">
          <div class="left-img">
            <img v-if="item.imgUrl" :src="item.imgUrl" alt="">
          </div>
          <div class="right-count">
            <div class="search-enroll-list-item-header ellipsis-2">
              <div class="search-enroll-list-item-header-status" :class="{
                status_1: transformStatus(item).status === 1
              }">{{ transformStatus(item).name }}</div>
              <div class="search-enroll-list-item-header-title" v-html="item.title"></div>
            </div>
            <div v-if="size === 'small'" class="search-enroll-list-item-footer-small">
              <div class="label">{{ $t('pc_biz_survey_lbl_type').d('类型') + ": " + transformTag(item.tag) }}</div>
              <div class="time">{{ transformTime(item) }}</div>
            </div>
            <div v-else class="search-enroll-list-item-footer">
              <div class="label">{{ $t('pc_biz_survey_lbl_type').d('类型') + ": " + transformTag(item.tag) }}</div>
              <div class="divider"></div>
              <div class="time">{{ transformTime(item) }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="needMore" class="yxtbiz-ai-robot__coure-more color-gray-8" @click="toMore">
        {{ $t('pc_biz_lbl_common_viewmore') }}
        <yxt-svg :remote-url="`${$staticBaseUrl}ufd/55a3e0`" width="16px" height="16px" icon-class="arrow_right" />
      </div>
    </template>
  </div>
</template>
<script>
import EmptyData from '../common/empty-data.vue';
import dayjs from 'dayjs';
export default {
  name: 'SearchEnroll',
  components: {
    EmptyData
  },
  props: {
    datas: {
      type: Object,
      default: ()=>{
        return {datas: []};
      }
    },
    params: {
      type: Object,
      default: ()=>{}
    },
    size: {
      type: String,
      default: 'small',
      validator: val => ['small', 'large'].includes(val)
    }
  },
  data() {
    return {};
  },
  computed: {
    dataList() {
      return this.datas.datas.map(item => {
        return item;
      });
    },
    needMore() {
      if (this.datas.paging && this.datas.paging.count) {
        return this.datas.paging.count > 3;
      }
      return false;
    }
  },
  created() {
    console.log(this.datas, this.params, this.size, '搜素社区');
  },
  methods: {
    transformTag(tag) {
      if (!tag) {
        return '';
      }
      const tagMap = {
        te: this.$t('pc_biz_ai_lecturer').d('讲师'),
        o2o: this.$t('pc_biz_nav_tip_train').d('培训'),
        ac: this.$t('pc_biz_o2o_lbl_activity').d('活动'),
        te_course_auth: this.$t('pc_biz_lbl_certtype_course').d('课程授权认证')
      };
      return tagMap[tag];
    },
    transformStatus(node) {
      const { startTime, endTime } = node;
      if (!startTime && !endTime) {
        return {
          status: 1,
          name: this.$t('pc_biz_search_sign_up').d('待报名')
        };
      }
      const currTime = Date.now();
      let startTimeTrans = 0;
      let endTimeTrans = 0;
      if (startTime) {
        startTimeTrans = (new Date(startTime.replace(new RegExp(/-/gm), '/'))).getTime();
      }
      if (endTime) {
        endTimeTrans = (new Date(endTime.replace(new RegExp(/-/gm), '/'))).getTime();
      }
      if (currTime < startTimeTrans) {
        // 未开始
        return {
          status: 0,
          name: this.$t('pc_biz_study_lbl_notyetgegun').d('未开始')
        };
      } else if (currTime > startTimeTrans && currTime < endTimeTrans) {
        // 待报名
        return {
          status: 1,
          name: this.$t('pc_biz_search_sign_up').d('待报名')
        };
      } else {
        // 已过期
        return {
          status: 2,
          name: this.$t('pc_biz_cer_lbl_expired').d('已过期')
        };
      }
    },
    transformTime(node) {
      const { startTime, endTime } = node;
      if (!startTime && !endTime) {
        return this.$t('pc_biz_long_term').d('长期活动');
      }
      if (startTime && endTime) {
        return `${dayjs(startTime).format('YYYY-MM-DD HH:mm')}~${dayjs(endTime).format('YYYY-MM-DD HH:mm')}`;
      }
      return endTime ? dayjs(endTime).format('YYYY-MM-DD HH:mm') : '';
    },
    toDetail(item) {
      window.open(`/enroll/#/detail/${item.id}`);
    },
    toMore() {
      window.open(`/search/#/gs/project?keyword=${this.params.searchKey}`);
    }
  }
};
</script>
<style scoped lang="scss">
.yxtbiz-ai-robot__coure{
  width: 100%;
}
.search-enroll-list {
  &-tips-info {
    font-size: 14px;
    font-weight: 400;
    padding: 0 12px 12px;
    color: #8c8c8c;
    line-height: 28px;
  }
  &-item {
    &:hover {
      border-radius: 8px;
      background-color: #F4F5F6;
    }
    cursor: pointer;
    display: flex;
    align-items: center;
    .left-img {
      width: 112px;
      height: 63px;
      border-radius: 3px;
      background-color: #8C8C8C;
      margin-right: 10px;
      flex-shrink: 0;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    padding: 12px;
    &-header {
      &-status {
        &.status_1 {
          background-color: #E6EEFF;
          color: #436BFF;
        }
        display: inline-flex;
        padding: 0px 8px;
        font-size: 12px;
        align-items: center;
        height: 20px;
        color: #595959;
        background: #F0F0F0;
        margin-right: 8px;
        border-radius: 2px;
        line-height: 18px;
      }
      &-title {
        display: inline;
        font-size: 16px;
        font-weight: 400;
        color: #262626;
        line-height: 24px;
      }
    }
    &-content {
      color: #757575;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }
    &-footer {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 12px;
      color: #8C8C8C;
      line-height: 20px;
      margin-top: 8px;
      .label {
        max-width: 200px;
      }
      .divider {
        background-color: #d8d8d8;
        width: 1px;
        height: 10px;
        margin: 0 8px;
        flex-shrink: 0;
      }
      .time {
        margin-right: 10px;
      }
    }
    &-footer-small {
      font-weight: 400;
      font-size: 12px;
      color: #8C8C8C;
      line-height: 20px;
      .label {
        margin-top: 4px;
      }
      .time {
        margin-top: 4px;
      }
    }
  }
}

.yxt-divider {
  background-color: #d8d8d8;
}


.yxtbiz-ai-robot__coure-more {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: #f4f5f6;
  }
}
</style>
