<!-- 类型搜索输入框 -->
<template>
  <yxt-input
    v-bind="$attrs"
    v-model="key"
    size="small"
    searchable
    class="yxtbiz-type-search"
    :placeholder="placeHolderLabel"
    @keyup.enter="search"
    @search="search"
  >
    <yxt-select
      v-model="type"
      slot="prepend"
      size="small"
      :placeholder="$t('pc_biz_lbl_selecttip').d('请选择')"
      @change="handleTypeChange"
    >
      <yxt-option
        v-for="item in optionList"
        :key="item.value"
        :label="$t(item.label)"
        :value="item.value"
      ></yxt-option>
    </yxt-select>
  </yxt-input>
</template>

<script>
const CACHE_KEY = 'TYPE_SEARCH_SELECT_CACHE'; // 缓存key值
const defaultOptions = [
  {
    value: 1,
    label: 'pc_biz_core_lbl_name'
  },
  {
    value: 2,
    label: 'pc_biz_core_lbl_accnum'
  },
  {
    value: 3,
    label: 'pc_biz_core_lbl_userNo'
  },
  {
    value: 4,
    label: 'biz_udp_occupation_level'
  },
  {
    value: 5,
    label: 'pc_biz_core_lbl_mail'
  },
  {
    value: 6,
    label: 'pc_biz_core_lbl_phoneNumber'
  }
];
export default {
  name: 'YxtbizTypeSearch',
  data() {
    return {
      key: '', // 搜索关键字
      type: '', // 搜索的类型
      optionList: []
    };
  },
  props: {
    // 是否开启下拉已选缓存，开启后，默认选中上次已点击的
    selectCache: {
      type: Boolean,
      default: false
    },
    // 缓存作用域，配置后将不会走全局缓存值，下拉已选缓存将会只读取该作用域下的配置，不传或没匹配到走全局
    cacheScoped: {
      type: String,
      default: ''
    },
    kwType: '',
    keywords: '',
    // 类型选项列表
    options: {
      required: false,
      // type: Array,
      default: []
    },
    useDefaultData: {
      type: Boolean,
      default: true
    } // 是否使用默认数据，使用默认数据时，option传:[1,2]
  },
  components: {},
  computed: {
    placeHolderLabel() {
      const option = this.optionList.find(i => i.value === this.type);
      return this.$t('pc_biz_lbl_input_palcehoder', {
        val: option ? this.$t(option.label) : ''
      }).d('请输入姓名搜索');
    }
  },
  watch: {
    key(val) {
      this.$emit('update:keywords', val);
    },
    type(val) {
      this.$emit('update:kwType', val);
    },
    keywords(val) {
      this.key = val || '';
    }
  },
  created() {
    this.type = this.kwType;
    this.key = this.keywords;
    this.setOptionList();
    this.initSelectValue();
    if (!this.type && this.optionList.length) {
      this.type = this.optionList[0].value;
    }
  },
  methods: {
    // 下拉框初始化选择项
    initSelectValue() {
      if (this.selectCache) {
        try {
          const KEY = this.cacheScoped ? CACHE_KEY + '_' + this.cacheScoped : CACHE_KEY;
          const value = localStorage.getItem(KEY);
          let kwType = ['undefined', 'null'].includes(value) ? '' : value;
          if (!kwType) return;
          kwType = isNaN(Number(kwType)) ? kwType : Number(kwType);
          const hasValue = this.optionList.find(item => item.value === kwType); // 匹配值先string下，因为localStorage取得值都是string
          if (!hasValue) return; // 如果缓存中的已选值不在当前下拉列表中，不做处理
          if (kwType) this.type = kwType;
        } catch (error) {
          console.log(error);
        }
      }
    },
    // 重置下拉列表
    setOptionList() {
      // 使用默认数据
      if (this.useDefaultData) {
        this.optionList = defaultOptions.filter(item => {
          return this.options.find(key => key === item.value) > -1;
        });
      } else {
        this.optionList = this.options;
      }
    },
    handleTypeChange(val) {
      if (this.selectCache) {
        // 开启下拉选择缓存
        let key = CACHE_KEY;
        if (this.cacheScoped) {
          key = CACHE_KEY + '_' + this.cacheScoped;
        }
        localStorage.setItem(key, val);
      }
      this.$emit('update:kwType', val);
      this.search();
    },
    search(val) {
      this.$emit('update:keywords', this.key);
      this.$emit('search');
    }
  }
};
</script>
<style lang="scss" scoped></style>
