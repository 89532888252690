var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "draggable-container", style: _vm.containerStyle },
    [
      _vm.isDragging || _vm.isMoveing
        ? _c("div", { staticClass: "draggable-mask" })
        : _vm._e(),
      !_vm.bFullscreen
        ? _c("div", {
            staticClass: "draggable-move",
            on: {
              mousedown: function ($event) {
                $event.stopPropagation()
                return _vm.startMove.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }