var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "yxt-input",
    _vm._b(
      {
        staticClass: "yxtbiz-type-search",
        attrs: {
          size: "small",
          searchable: "",
          placeholder: _vm.placeHolderLabel,
        },
        on: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.search.apply(null, arguments)
          },
          search: _vm.search,
        },
        model: {
          value: _vm.key,
          callback: function ($$v) {
            _vm.key = $$v
          },
          expression: "key",
        },
      },
      "yxt-input",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "yxt-select",
        {
          attrs: {
            slot: "prepend",
            size: "small",
            placeholder: _vm.$t("pc_biz_lbl_selecttip").d("请选择"),
          },
          on: { change: _vm.handleTypeChange },
          slot: "prepend",
          model: {
            value: _vm.type,
            callback: function ($$v) {
              _vm.type = $$v
            },
            expression: "type",
          },
        },
        _vm._l(_vm.optionList, function (item) {
          return _c("yxt-option", {
            key: item.value,
            attrs: { label: _vm.$t(item.label), value: item.value },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }