<template>
  <ClickToJump :message="$t('pc_biz_ote_click_to_exam' /* 好的,正在为您唤起创建考试页面 */)" :target-url="targetUrl" :datas="datas" />
</template>
 
<script>
import ClickToJump from '../common/click-to-jump.vue';
import CommonMixin from '../common/props-mixin';

export default {
  name: 'CreateExam',
  components: {
    ClickToJump
  },
  mixins: [CommonMixin],
  data() {
    return {
      targetUrl: `${window.location.origin}/ote/#/quickexam/create/exam?examType=onlineExam`
    };
  }
};
 </script>