var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.msg.chatType === 1
    ? _c("answer", {
        attrs: {
          lastMsg: _vm.lastMsg,
          isWaitAnswer: _vm.isWaitAnswer,
          "show-portrait": _vm.fullscreen,
          fullscreen: _vm.fullscreen,
          msg: _vm.msg,
        },
        on: {
          promot: (...info) => _vm.$emit("promot", ...info),
          resend: (val) => _vm.$emit("resend", val),
          feedback: function ($event) {
            return _vm.$emit("feedback")
          },
          promotEmpty: function ($event) {
            return _vm.$emit("promotEmpty")
          },
          submit: (data) => _vm.$emit("submit", data),
          scrollToEnd: function ($event) {
            return _vm.$emit("scrollToEnd")
          },
        },
      })
    : _vm.msg.chatType === 0
    ? _c("question", {
        attrs: {
          "show-portrait": _vm.fullscreen,
          fullscreen: _vm.fullscreen,
          msg: _vm.msg,
        },
      })
    : _c("div", { staticClass: "system-msg" }, [
        _vm._v(_vm._s(_vm.msg.content)),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }