<template>
  <SelectorMain
  ref="selectorMain"
  :selected="selected"
  @change="selectedChange"
  ></SelectorMain>
</template>

<script>
import SelectorMain from './selector-main';
export default {
  name: 'YxtbizExtendFieldSelector',
  components: {
    SelectorMain
  },
  model: {
    event: 'change',
    prop: 'selected'
  },
  props: {
    selected: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    selectedChange(selected) {
      this.$emit('change', selected);
    }
  }
};
</script>

<style lang="scss">
  .yxtbiz-extend-field-selector {
    height: 100%;
    &-custom-title {
      height: 56px;
      line-height: 56px;
      font-size: 22px;
      border-bottom: 1px solid #e9e9e9;
      margin: -24px;
      padding-left: 24px;
      margin-bottom: 24px;
    }
    &-main {
      height: calc(100% - 56px);
      position: relative;
      &-content {
        position: absolute;
        padding-right: 280px;
        top: 0;
        left: 0;
        right: -24px;
        height: 100%;
        > div {
          height: 100%;
        }
      }
      &-checkedlist {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        border-left: 1px solid #e9e9e9;
        width: 280px;
      }
    }
  }
  .yxt-drawer__body .yxtbiz-extend-field-selector-main-checkedlist,
  .yxt-dialog__body .yxtbiz-extend-field-selector-main-checkedlist {
    top: -24px;
    right: -24px;
    bottom: -24px;
  }
  .yxtbiz-extend-field-selector .yxt-tabs__nav-scroll {
    margin-right: 106px;
  }
  .yxtbiz-extend-field-selector .yxt-tabs__nav-more {
    right: 92px;
  }
  .yxtbiz-extend-field-selector .yxt-tabs__header.yxt-tabs__drawer-top .yxt-tabs__nav-wrap {
    padding: 0 24px 0 0;
  }
</style>