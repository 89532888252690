var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "yxtbiz-ai-robot-view-more",
      on: { click: (evt) => _vm.$emit("click", evt) },
    },
    [
      _c("div", { staticClass: "yxtbiz-ai-robot-view-more__text" }, [
        _vm._v(_vm._s(_vm.$t("pc_biz_ote_lbl_viewmore" /* 查看更多 */))),
      ]),
      _c("yxt-svg", {
        attrs: { width: "16px", height: "16px", "icon-class": "arrow_right" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }