export default {
  props: {
    enableGroupCorp: {
      type: Boolean,
      default: false
    },
    disabledOrgIds: {},
    visibleOrgIds: {
      type: Array,
      default: []
    },
    targetOrgId: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
