<template>
  <div
    class="hand flex-1 width-percent-100 yxtbiz-ai-robot-knglib__cell-content"
    @click="toDetail(data.id)"
  >
    <div class="pull-left">
      <yxtf-svg
        width="52px"
        height="52px"
        :icon-class="getSvgName(data.fileType)"
      />
    </div>
    <div class="ml64">
      <div
        :class="[
          size === 'small' ? 'font-size-14 lh22' : 'font-size-16  lh24',
          'yxtbiz-ai-robot-knglib__title color-gray-10'
        ]"
      >
        <div class="ellipsis">
          {{ data.title }}
        </div>
        <div class="yxtbiz-ai-robot-knglib__title-suffix">
          {{ data.fileExt }}
        </div>
      </div>
      <span
        class="ellipsis color-gray-7 standard-size-12 d-in-block mt8 yxtbiz-ai-robot-knglib__item-from"
        >{{ data.kngLibName }}</span
      >
    </div>
  </div>
</template>
  
  <script>
import favoriteMixin from './favorite-mixin.js';
export default {
  name: 'knglibItem',
  mixins: [favoriteMixin],
  data() {
    return {
    };
  },
  computed: {},
  methods: {
    toDetail(id) {
      let url = `${window.location.protocol}//${window.location.host}/knglib/#/detail?kngId=${id}`;
      window.open(url);
    },
    getSvgName(type) {
      switch (type) {
        case 'folder':
          return 'f_kng-file';
        case 'img':
          return 'f_kng-img';
        case 'zip':
          return 'f_kng-zip';
        case 'video':
          return 'f_kng-video';
        case 'word':
          return 'f_kng-word';
        case 'ppt':
          return 'f_kng-ppt';
        case 'pdf':
          return 'f_kng-pdf';
        case 'excel':
          return 'f_kng-excel';
        case 'audio':
          return 'f_kng-audio';
      }
    }
  }
};
</script>
  <style scoped lang="scss">
.yxtbiz-ai-robot-knglib__cell-content {
  height: 52px;
  overflow: hidden;
}

.yxtbiz-ai-robot-knglib__item-from {
  max-width: calc(100% - 152px);
}

.yxtbiz-ai-robot-knglib__title {
  display: flex;

  .yxtbiz-ai-robot-knglib__title-suffix {
    flex-shrink: 0;
  }
}
</style>
  