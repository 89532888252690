<template>
  <div class="yxtbiz-extend-field-selector-wrap">
    <yxt-row type='flex' class="yxt-user-selector-container yxt-user-multi-container">
        <div class="yxtbiz-user-search-wrap mb16">
          <yxt-select size="small" v-model="selectSpare" @change="spareChange">
            <yxt-option v-for="(item) in spares" :key="item.id" :label="item.name" :value="item.id"></yxt-option>
          </yxt-select>
          <div style="width: 200px;margin-left: 20px;">
            <yxt-input v-model="keyword" size="small" clearable @search="search" searchable :placeholder="$t('pc_biz_placehoder_optionname').d('输入选项名称')" />
          </div>
        </div>
        <yxt-table
          :ref="refTable"
          @select-all="selectAllOption"
          @select="selectOption"
          v-loading="loading"
          :height="tableHeight"
          class="yxt-user-table"
          :data="sparesOptions"
          row-key="id"
          :key="`spares-${selectSpare}`"
        >
          <template slot="empty">
            <div style="line-height:normal">
              <yxt-empty />
            </div>
          </template>
          <!-- 表格单多选 -->
          <yxt-table-column
            v-if="multiple"
            :show-overflow-tooltip="false"
            class-name="yxtbiz-table-selection"
            type="selection"
            width="42"
            reserve-selection
            clear-padding="right"
          ></yxt-table-column>
          <yxt-table-column
            v-else
            :show-overflow-tooltip="false"
            width="40"
          >
            <template slot-scope="scope">
              <yxt-radio @change="selectOption([scope.row], scope.row)" v-model="selectSparesingleOption" :label="scope.row.id"></yxt-radio>
            </template>
          </yxt-table-column>
          <yxt-table-column
            prop="name"
            :label="$t('pc_biz_optionname').d('选项名称')"
            :show-overflow-tooltip="true"
          ></yxt-table-column>
        </yxt-table>
        <!-- <yxt-row align='middle' class="yxtbiz-user-selector-mask mt16" type='flex'>
          <yxt-row type='flex' align='middle' class="col-flex-1"></yxt-row>
          <yxt-pagination
            @size-change="sizeChange"
            @current-change="pageChange"
            :current-page.sync="page"
            :page-size="20"
            simple-total
            layout="total, prev, pager, next"
            :total="count"
            :pager-count="5"
          ></yxt-pagination>
        </yxt-row> -->
    </yxt-row>
  </div>
</template>

<script>
import resizeTable from '../../mixins/resizeTable';
import { getSpares } from '../service';
import EventBus from '../eventBus';

export default {
  components: {
  },
  mixins: [resizeTable],
  props: {
    value: {
      type: Array,
      default: () => []
    },
    limit: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      refTable: 'spares',
      keyword: '',
      tableHeight: 0,
      loading: false,
      spares: [], // 扩展字段
      selectSpare: '',
      selectSparesingleOption: '',
      sparesOriginOptions: [],
      sparesOptions: [],
      multiple: false,
      sparesSelectedMap: {}
    };
  },
  watch: {
  },
  created() {
    EventBus.$on('extend-field-change', (deleted) => {
      if (deleted === null) {
        // 点了全部清除操作
        this.clearAllSelected();
      } else {
        // 删除
        const { type, deleteItem } = deleted;
        this.updateSelected(type, deleteItem);
      }
    });
    this.getSparesData();
  },
  methods: {
    getUuid() {
      let d = new Date().getTime();
      if (window.performance && typeof window.performance.now === 'function') {
        d += performance.now(); // use high-precision timer if available
      }
      let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
      return uuid;
    },
    search() {
      this.sparesOptions = this.sparesOriginOptions.filter(item => {
        return item.name.includes(this.keyword);
      });
    },
    async getSparesData() {
      this.loading = false;
      const res = await getSpares();
      this.spares = res.datas.filter(item => {
        return item.type === 'droplistMulti' || item.type === 'droplist';
      });
      this.setSparesSelectedMap(this.spares);
      if (this.spares.length) {
        this.setSpareData(this.spares[0]);
      }
      if (this.value.length) {
        // 设置默认值
        this.setDefaultSelected();
      }
    },
    setDefaultSelected() {
      // 设置默认值
      if (!this.value || !this.value.length) return;
      this.value.forEach(node => {
        const currSpare = this.spares.find(item => (node.id ? node.id === item.id : node.spareKey === item.spareKey));
        if (currSpare && this.sparesSelectedMap[currSpare.id]) {
          this.sparesSelectedMap[currSpare.id].selected = node.selected;
        }
      });
      const newSelected = JSON.parse(JSON.stringify(this.sparesSelectedMap));
      this.$emit('change', newSelected);
    },
    clearAllSelected() {
      this.selectSparesingleOption = '';
      this.setSparesSelectedMap(this.spares);
      this.$refs[this.refTable].clearSelection();
      const newSelected = JSON.parse(JSON.stringify(this.sparesSelectedMap));
      this.$emit('change', newSelected);
    },
    updateSelected(spareId, deleteNode) {
      if (this.sparesSelectedMap[spareId]) {
        this.sparesSelectedMap[spareId].selected = this.sparesSelectedMap[spareId].selected.filter(item => item !== deleteNode);
        const newSelected = JSON.parse(JSON.stringify(this.sparesSelectedMap));
        this.$emit('change', newSelected);
        if (spareId === this.selectSpare) {
          const currDelNode = this.sparesOptions.find(item => item.name === deleteNode);
          this.$refs[this.refTable].toggleRowSelection(currDelNode, false);
        }
      }
    },
    setSparesSelectedMap(spares) {
      spares.forEach(node => {
        this.sparesSelectedMap[node.id] = {
          name: node.name,
          spareKey: node.spareKey,
          id: node.id,
          multiple: node.type === 'droplistMulti',
          selected: []
        };
      });
    },
    spareChange(value) {
      this.keyword = '';
      const currSpare = this.spares.find((item) => item.id === value);
      if (currSpare) {
        this.setSpareData(currSpare);
      }
    },
    setSpareData(currSpare) {
      this.sparesOptions = [];
      this.sparesOriginOptions = [];
      // 设置扩展字段数据
      const { type, id, optionValueArray } = currSpare;
      this.multiple = type === 'droplistMulti';
      this.selectSpare = id;
      this.sparesOriginOptions = optionValueArray.map(name => ({
        id: this.getUuid(),
        name
      }));
      this.sparesOptions = [...this.sparesOriginOptions];
      this.$nextTick(() => {
        if (this.sparesSelectedMap[id]) {
          if (this.multiple) {
            this.sparesOptions.forEach((opt) => {
              if (this.sparesSelectedMap[id].selected.includes(opt.name)) {
                this.$refs[this.refTable].toggleRowSelection(opt, true);
              }
            });
          } else {
            const spare = this.sparesOptions.find(opt => this.sparesSelectedMap[id].selected[0] === opt.name);
            this.selectSparesingleOption = spare.id || '';
          }
        }
      });
    },
    selectOption(items) {
      // 选中数据
      this.sparesSelectedMap[this.selectSpare].selected = items.map(item => item.name);
      const newSelected = JSON.parse(JSON.stringify(this.sparesSelectedMap));
      this.$emit('change', newSelected);
    },
    selectAllOption(items) {
      // 全选
      this.sparesSelectedMap[this.selectSpare].selected = items.map(item => item.name);
      const newSelected = JSON.parse(JSON.stringify(this.sparesSelectedMap));
      this.$emit('change', newSelected);
    }
  }
};
</script>

<style lang="scss">
.yxtbiz-extend-field-selector-wrap {
  .yxt-table::before {
    height: 0;
  }
}
</style>
