var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("SelectorMain", {
    ref: "selectorMain",
    attrs: { selected: _vm.selected },
    on: { change: _vm.selectedChange },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }