<template>
  <yxtf-dialog
    :title="$t('pc_biz_ai_feedback_title').d('我要反馈')"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    :before-close="handleClose">
    <div v-if="settings.fastFeedbackList && settings.fastFeedbackList.length" class="mb16">
      <yxtf-tag v-for="(tag, index) in settings.fastFeedbackList" :key="index" type="info" class="hand" @click="tagClicked(tag)">{{ tag }}</yxtf-tag>
    </div>
    <yxtf-input
      v-model="value"
      type="textarea"
      :placeholder="$t('pc_biz_ai_input_feedback_conent').d('请输入反馈内容')"
      rows="4"
      resize="none"
      autofocus
      :maxlength="200"
      show-word-limit
    ></yxtf-input>
    <span slot="footer" class="dialog-footer">
      <yxtf-button @click="handleClose">{{ $t('pc_biz_ai_btn_cancel').d('取消') }}</yxtf-button>
      <yxtf-button type="primary" @click="save">{{ $t('pc_biz_ai_btn_submit').d('提交') }}</yxtf-button>
    </span>
  </yxtf-dialog>
</template>

<script>
export default {
  name: 'FeedbackDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    tags: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    visible(v) {
      this.dialogVisible = v;
      this.value = '';
    }
  },
  inject: ['settings'],
  data() {
    return {
      dialogVisible: this.visible,
      value: ''
    };
  },
  methods: {
    tagClicked(val) {
      this.value += this.value ? '，' + val : val;
    },
    handleClose() {
      this.$emit('update:visible', false);
      this.dialogVisible = false;
    },
    save() {
      if (!this.value) {
        this.$message.error(this.$t('pc_biz_ai_feedback_not_empty').d('反馈内容不能为空！'));
        return;
      }
      let content = this.value;
      this.dialogVisible = false;
      this.value = '';
      this.$emit('success', content);
    }
  }
};
</script>
