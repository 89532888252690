<!-- 创建时间2024/01/04 15:52:27 -->
<!-- 创建人：Zhao bing bing -->
<!-- 组件描述：知识空间存储方式选择 -->
<template>
  <yxt-form-item :label="config.labelName" :prop="config.name">
    <yxt-select class="width-percent-100" v-model="value_" filterable size="small" :disabled="disabled || loading">
      <yxt-option 
        v-for="item in storeList" 
        :key="item.value"
        :label="item.labelName"
        :value="item.value">
      </yxt-option>
    </yxt-select>
  </yxt-form-item>
</template>

<script>
import { fileApi } from 'yxt-biz-pc/packages/api';
export default {
  name: 'KngLibStoreSelector',
  props: {
    value: {
      type: [Number, String],
      default: ''
    },
    config: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      storeList: [],
      loading: false,
      mixConfig: {
        open: false,
        storageType: 1
      }
    };
  },
  computed: {
    value_: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
        this.change(val);
      }
    }
  },
  async created() {
    await this.getMixConfig();
    this.setStoreList();
  },
  methods: {
    async getMixConfig() {
      try {
        this.loading = true;
        const res = await fileApi.get(`local/baseConfigById?orgid=${window.getLocalStorage('orgId')}`);
        const onlyPrivate = JSON.parse(res.bizSwith).kngLib; // 是否开启只允许内网上传
        let storageType = 3; // 存储方式：1-公有云存储，2-私有云存储，3-混合存储
        const open = !!res.localSwith; // localSwith: 1-开启混部 0-没有开启
        if (!open) { // 没有开启混部，只有公有云存储
          storageType = 1;
        } else if (onlyPrivate) { // 开启只允许私有云存储
          storageType = 2;
        }
        this.mixConfig.open = open;
        this.mixConfig.storageType = storageType;
      } catch (e) {}
      this.loading = false;
    },
    setStoreList() {
      if (this.mixConfig.storageType === 3) {
        this.storeList = [
          {labelName: this.$t('pc_biz_lbl_storetype1'/** 公有云存储 */), value: 1},
          {labelName: this.$t('pc_biz_lbl_storetype2'/** 私有云存储 */), value: 2},
          {labelName: this.$t('pc_biz_lbl_storetype3'/** 混合存储 */), value: 3}
        ];
      } else if (this.mixConfig.storageType === 2) {
        this.storeList = [
          {labelName: this.$t('pc_biz_lbl_storetype2'/** 私有云存储 */), value: 2}
        ];
      } else {
        this.storeList = [
          {labelName: this.$t('pc_biz_lbl_storetype1'/** 公有云存储 */), value: 1}
        ];
      }
      if (this.value_ && this.storeList.some(item => item.value === this.value_)) {
        this.change(this.value_);
      } else {
        this.value_ = this.mixConfig.storageType;
      }
    },
    change(val) {
      const obj = this.storeList.find(item => item.value === val) || {};
      this.$emit('change', obj);
    }
  }
};
</script>