var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        "yxtbiz-ai-robot__dialog--msg": true,
        "is-question": true,
        fullscreen: _vm.fullscreen,
      },
    },
    [
      _c(
        "div",
        { staticClass: "is-content" },
        [
          _c("div", { ref: "question", staticClass: "question-msg" }, [
            _vm._v(
              _vm._s(_vm.msg.contentDetail && _vm.msg.contentDetail.content)
            ),
          ]),
          _c("msgToolBtn", { on: { toolClick: _vm.toolClick } }),
        ],
        1
      ),
      _vm.showPortrait
        ? _c("yxtf-portrait", {
            staticClass: "mt4 ml12 yxtbiz-ai-robot__dialog--portrait",
            attrs: {
              size: "28px",
              username: _vm.username,
              imgUrl: _vm.userImgUrl,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }