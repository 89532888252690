var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isDirectManage || _vm.isHavaManagePermission
    ? _c(
        "div",
        { staticClass: "yxtbiz-person-range-selector" },
        [
          _c(
            "label",
            { staticClass: "font-size-14 color-gray-10" },
            [_vm._t("default")],
            2
          ),
          _c(
            "yxt-popover",
            {
              attrs: {
                "popper-class": "select-popper",
                "append-to-body": false,
                placement: "bottom-start",
                "visible-arrow": false,
                trigger: "manual",
              },
              model: {
                value: _vm.showPopper,
                callback: function ($$v) {
                  _vm.showPopper = $$v
                },
                expression: "showPopper",
              },
            },
            [
              _c(
                "div",
                { staticClass: "pl24 pr24" },
                [
                  _c("yxt-input", {
                    staticClass: "search-input",
                    attrs: {
                      placeholder: _vm.$t("biz_udp_enter_deptmentname"),
                      size: _vm.size,
                    },
                    model: {
                      value: _vm.filterText,
                      callback: function ($$v) {
                        _vm.filterText = $$v
                      },
                      expression: "filterText",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "tree-wrapper" },
                    [
                      _c("yxt-tree", {
                        ref: "tree",
                        staticClass: "filter-tree mt16",
                        attrs: {
                          "show-checkbox": "",
                          "wrap-width": "320",
                          "check-strictly": true,
                          "node-key": "id",
                          "default-expanded-keys": [""],
                          data: _vm.data,
                          props: _vm.defaultProps,
                          "filter-node-method": _vm.filterNode,
                        },
                        on: { "check-change": _vm.handleTreeCheck },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ node }) {
                                return _c(
                                  "span",
                                  { staticClass: "yxtbiz-prs__node" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "ellipsis node-label",
                                        attrs: { title: node.label },
                                      },
                                      [_vm._v(_vm._s(node.label))]
                                    ),
                                    !node.data.id
                                      ? _c(
                                          "yxt-checkbox",
                                          {
                                            staticClass: "all-select",
                                            on: { change: _vm.checkChange },
                                            nativeOn: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                              },
                                            },
                                            model: {
                                              value: _vm.checked,
                                              callback: function ($$v) {
                                                _vm.checked = $$v
                                              },
                                              expression: "checked",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("biz_udp_all_selected")
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              },
                            },
                          ],
                          null,
                          false,
                          1141867849
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "yxt-input",
                {
                  staticClass: "yxtbiz-prs__search",
                  attrs: {
                    slot: "reference",
                    size: "medium",
                    placeholder: _vm.$t("biz_udp_select_personrange"),
                    "suffix-icon": "yxt-icon-arrow-down",
                    readonly: "",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.show.apply(null, arguments)
                    },
                  },
                  slot: "reference",
                  model: {
                    value: _vm.value,
                    callback: function ($$v) {
                      _vm.value = $$v
                    },
                    expression: "value",
                  },
                },
                [_c("template", { slot: "suffix" })],
                2
              ),
              _c(
                "div",
                { staticClass: "button-wrapper" },
                [
                  _c(
                    "yxt-button",
                    { attrs: { plain: "" }, on: { click: _vm.cancel } },
                    [_vm._v(_vm._s(_vm.$t("pc_biz_udp_btn_cancel")))]
                  ),
                  _c(
                    "yxt-button",
                    { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                    [_vm._v(_vm._s(_vm.$t("pc_biz_udp_btn_confirm")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }