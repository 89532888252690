var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["yxtbiz-nav-top-workbench"] },
    [
      _c("i", {
        staticClass: "yxt-icon-back hand",
        staticStyle: { width: "20px", height: "20px" },
        on: { click: _vm.backTo },
      }),
      _c("yxt-divider", { attrs: { direction: "vertical" } }),
      _c("span", { staticClass: "yxtbiz-nav-top__workbench_title" }, [
        _vm._v(
          "\n      " +
            _vm._s(
              _vm.currentWorkBenchNav ? _vm.currentWorkBenchNav.name : ""
            ) +
            "\n    "
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }