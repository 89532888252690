<template>
  <div>
    <yxt-row type='flex' class="yxt-user-selector-container yxt-user-multi-container">
        <div class="yxtbiz-user-search-wrap mb16">
          <yxtbiz-group-org-select @change="handleOrgChange" v-if="enableGroupCorp" :setCurrentOrg="true" :selectFirst="true" :clearable="false" :visibleOrgIds="visibleOrgIds" 
          :disabledOrgIds="disabledOrgIds"
          :targetOrgId="targetOrgId"
          :disabled="disabled"
          :functionCode="functionCode"
          :dataPermissionCode="dataPermissionCode"
          @orgInvalid="orgInvalidHandler"></yxtbiz-group-org-select>
           <select-popover v-if="isShowDeptment" class='ml12' ref="selectPopover" is-dept :selectOpition="selectOpition" >
            <div>
              <dept-tree
                v-if='(enableGroupCorp && userData.targetOrgId) || !enableGroupCorp'
                :targetOrgId='userData.targetOrgId'
                :visibleOrgSelector='false'
                :enableGroupCorp='enableGroupCorp'
                ref='deptTree'   
                :functionCode='functionCode'
                :dataPermissionCode='dataPermissionCode'
                @nodeClick="selectDept"
                :filterable="true"
              ></dept-tree>
            </div>
          </select-popover>
          <yxt-input
            :placeholder="$t('biz_udp_enter_account')"
            v-model="userValue"
            class="ml12"
            maxlength="20"
            style="width:240px"
            searchable
            @search="searchUser"
          >
          </yxt-input>
        </div>

        <yxt-table
          ref="userTable"
          @select-all="selectAllUser"
          @select="selectUser"
          v-loading="loading"
          :height="tableHeight"
          class="yxt-user-table"
          :data="users"
          :key="'persons'+timeStamp"
        >
          <template slot="empty">
            <div style="line-height:normal">
              <yxt-empty />
            </div>
          </template>
          <yxt-table-column
            :show-overflow-tooltip="false"
            class-name="yxtbiz-table-selection"
            type="selection"
            width="40"
          ></yxt-table-column>
          <yxt-table-column :label="$t('pc_biz_core_lbl_name')" :show-overflow-tooltip="true" :popover-html="true">
            <template slot-scope="scope">
              <yxtbiz-user-name v-if='isOpenData' :name='scope.row.fullname' />
              <span v-else>{{ scope.row.fullname + '(' + scope.row.username + ')' }}</span>
            </template>
          </yxt-table-column>
          <yxt-table-column
            :formatter="formatUserNo"
            :label="$t('pc_biz_udp_lbl_userNo')"
            :show-overflow-tooltip="true"
            prop="userNo"
          >
          </yxt-table-column>
          <yxt-table-column :label="$t('pc_biz_core_lbl_department')" :show-overflow-tooltip="true" :popover-html="true">
            <template slot-scope="scope">
              <span v-if="!scope.row.deptName">--</span>
              <yxtbiz-dept-name v-else-if='isOpenData' :name='scope.row.deptName' />
              <span v-else>{{scope.row.deptName}}</span>
            </template>
          </yxt-table-column>
          <yxt-table-column
            :label="$t('biz_udp_position')"
            :show-overflow-tooltip="true"
            :formatter="formatPostion"
          ></yxt-table-column>
        </yxt-table>
        <yxt-row align='middle' class="yxtbiz-user-selector-mask mt16" type='flex'>
          <yxt-row type='flex' align='middle' class="col-flex-1">
             <yxt-button
              type="text"
              class="lh32 yxtbiz-btn-search-all"
              :class="isSearchAll ? 'color-primary-4 disabled' : ''"
              v-if="showFilterAll"
              @click="searchAll"
              >{{ isCancelSelect ? $t('pc_biz_udp_btn_cancelFilter') : $t('biz_udp_all_filter_results') }}</yxt-button
            ><div class="yxtbiz-loading__all" v-loading="isSearchAll"></div>
          </yxt-row>
          <yxt-pagination
            @size-change="sizeChange"
            @current-change="pageChange"
            :current-page.sync="page"
            :page-size="userData.limit"
            simple-total
            layout="total, prev, pager, next"
            :total="count"
            :pager-count="5"
          ></yxt-pagination>
        </yxt-row>
    </yxt-row>
  </div>
</template>

<script>
import { getUserList } from '../service';
import deptTree from '../../../dept-tree';
import resizeTable from '../../mixins/resizeTable';
import SelectPopover from '../../../check-person-range/src/components/SelectPopover';
import { Pagination, Table, TableColumn, Input, Empty } from 'yxt-pc';
import openDataMixin from '../../mixins/openData';
import { i18n } from 'yxt-biz-pc/packages/common-util/changeLangs';
import groupMixin from '../../mixins/group';

export default {
  components: {
    deptTree,
    SelectPopover,
    YxtPagination: Pagination,
    YxtTable: Table,
    YxtTableColumn: TableColumn,
    YxtInput: Input,
    YxtEmpty: Empty
  },
  mixins: [resizeTable, openDataMixin, groupMixin],
  props: {
    value: {
      type: Array,
      default: () => []
    },
    functionCode: {
      type: String,
      default: ''
    },
    dataPermissionCode: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'persons'
    },
    scope: {
      type: Number
    },
    limit: {
      type: Number,
      default: 1
    },
    isShowDeptment: {
      type: Boolean,
      default: true
    },
    isOrgSelectAlone: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      refTable: 'userTable',
      orgInvalid: false, // 是否全部禁用，如果机构全部禁用了，则不能搜索
      isSearchAll: false,
      userValue: '',
      page: 1,
      count: 0,
      tableHeight: 0,
      userData: {
        scope: this.scope,
        deptId: '',
        navCode: this.functionCode,
        dataPermissionCode: this.dataPermissionCode,
        searchKey: '',
        limit: 20,
        offset: 0,
        type: 2
      },
      isCancelSelect: false,
      showFilterAll: false, // 是否显示筛选所有
      selectOpition: {
        placeholder: i18n.t('biz_udp_select_dept'),
        checkWord: ''
      },
      loading: false,
      totalPage: 0,
      users: [],
      selectedPersons: {},
      formatPostion: row => {
        if (row.positionName === undefined) {
          return (
            row.positionSet
              .map(item => {
                return item.name;
              })
              .join(';') || '--'
          );
        } else {
          const parttimePosition = row.parttimePositionSet ? Array.from(row.parttimePositionSet) : [];
          const posList = parttimePosition.map(item => item.name);
          posList.push(row.positionName || '');
          return posList.filter(item => item !== '').join(';') || '--';
        }
      },
      formatUserNo: row => {
        return row.userNo || '--';
      },
      timeStamp: ''
    };
  },
  created() {
    if (!this.enableGroupCorp || this.targetOrgId) {
      if (this.targetOrgId) this.userData.targetOrgId = this.targetOrgId;
      this.getUserData();
    }
    // 选中数组转化为对象，方便查找
    this.formatUsers(this.value);
  },
  methods: {
    handleOrgChange(data) {
      this.orgInvalid = false;
      this.userData.targetOrgId = data.orgId;
      this.userData.targetOrgName = data.orgName;
      this.selectOpition.checkWord = '';
      this.userData.deptId = '';
      this.page = 1;
      this.userData.offset = 0;
      this.getUserData();
    },
    formatUsers(users) {
      this.selectedPersons = {};
      users.forEach(user => {
        this.selectedPersons[user.id] = user;
      });
    },
    selectDept(item) {
      this.userData.deptId = item.id;
      this.userData.offset = 0;
      this.selectOpition.checkWord = item.id ? item.name : '';
      this.page = 1;
      this.$refs['selectPopover'].cancel();
      this.getUserData();
    },
    getUserData() {
      if (this.orgInvalid) return;
      this.loading = true;
      this.isCancelSelect = false;
      getUserList(this.userData, this.enableGroupCorp)
        .then(result => {
          if (this.orgInvalid) return;
          if (result.datas.length !== 0 && (this.userData.searchKey.trim() !== '' || this.userData.deptId !== '')) {
            // 搜索条件不为空，展示筛选全部按钮
            this.showFilterAll = true;
          } else {
            this.showFilterAll = false;
          }
          this.users = result.datas;
          this.users.forEach(user => {
            user.nodeType = this.type;
            if (this.enableGroupCorp) {
              user.orgId = this.userData.targetOrgId;
              user.orgName = this.userData.targetOrgName;
            }
            if (this.enableGroupCorp && this.isOrgSelectAlone) {
              // 集团版环境下，选人组件可设置成独立选人，及各个orgId下的相同一个人可以独立选择，并重置每一项的数据的org为当前选择的org
              user.oldId = user.id;
              user.id = `${user.id}&&${this.userData.targetOrgId}`;
            }
          });
          this.totalPage = result.paging.pages;
          this.count = result.paging.count;
          this.loading = false;
          this.$nextTick(() => {
            this.checkStatus();
          });
        })
        .catch(e => {
          this.users = [];
          this.loading = false;
          this.$message({
            type: 'error',
            message: e.error.message
          });
        });
    },
    searchUser() {
      this.userData.searchKey = this.userValue;
      this.userData.offset = 0;
      this.page = 1;
      this.getUserData();
    },
    cancelSelectAll() {
      getUserList(Object.assign({}, this.userData, {offset: 0, limit: this.count}))
        .then(res => {
          this.isCancelSelect = false;
          const list = res.datas;
          list.forEach(user => {
            user.nodeType = this.type;
            if (this.enableGroupCorp) {
              user.orgId = this.userData.targetOrgId;
              user.orgName = this.userData.targetOrgName;
              user.combinationName = `${this.userData.targetOrgName}-${user.fullname}`;
            }
            if (this.enableGroupCorp && this.isOrgSelectAlone) {
              // 集团版环境下，选人组件可设置成独立选人，及各个orgId下的相同一个人可以独立选择，并重置每一项的数据的org为当前选择的org
              user.oldId = user.id;
              user.id = `${user.id}&&${this.userData.targetOrgId}`;
            }
            delete this.selectedPersons[user.id];
          });
          this.updateUsers(list, false);
        });
    },
    searchAll() {
      if (this.isCancelSelect) {
        return this.cancelSelectAll();
      }
      const limitCount = 5000;
      const len = Math.ceil(this.count / limitCount);
      let count = 0;
      let postData = Object.assign({}, this.userData, { limit: limitCount });
      if (len > 0) {
        this.isSearchAll = true;
      }
      for (let i = 0; i < len; i++) {
        getUserList(Object.assign({}, postData, { offset: limitCount * i }))
          .then(result => {
            count++;
            if (count >= len) {
              this.isSearchAll = false;
              this.isCancelSelect = true;
            }
            const list = result.datas;
            list.forEach(user => {
              user.nodeType = this.type;
              if (this.enableGroupCorp) {
                user.orgId = this.userData.targetOrgId;
                user.orgName = this.userData.targetOrgName;
                user.combinationName = `${this.userData.targetOrgName}-${user.fullname}`;
              }
              if (this.enableGroupCorp && this.isOrgSelectAlone) {
                // 集团版环境下，选人组件可设置成独立选人，及各个orgId下的相同一个人可以独立选择，并重置每一项的数据的org为当前选择的org
                user.oldId = user.id;
                user.id = `${user.id}&&${this.userData.targetOrgId}`;
              }
              this.selectedPersons[user.id] = user;
            });

            this.updateUsers(list, true);
          })
          .catch(e => {
            this.isSearchAll = false;
            this.$message({
              type: 'error',
              message: e
            });
          });
      }
    },
    sizeChange(size) {
      this.userData.limit = size;
      this.page = 1;
      this.userData.offset = 0;
      this.getUserData();
    },
    pageChange(page) {
      this.page = page;
      this.userData.offset = (page - 1) * this.userData.limit;
      this.getUserData();
    },
    selectUser(item, row) {
      let isAdd = item.some(v => {
        return v.id === row.id;
      });
      if (item.length === 0) {
        isAdd = false;
        delete this.selectedPersons[row.id];
      } else {
        this.selectedPersons[row.id] = row;
      }
      row.orgName = this.userData.targetOrgName;
      if (this.enableGroupCorp && this.userData.targetOrgName) row.combinationName = `${this.userData.targetOrgName}-${row.fullname}`;
      this.updateUsers(row, isAdd);
    },
    // 触发选中人员更新
    updateUsers(items, isAdd) {
      this.$emit('input', Object.values(this.selectedPersons));
      this.$emit('update', this.type, items, isAdd);
    },
    selectAllUser(items) {
      let isAdd = items.length !== 0;
      this.users.forEach(user => {
        user.orgName = this.userData.targetOrgName;
        if (this.enableGroupCorp && this.userData.targetOrgName) user.combinationName = `${this.userData.targetOrgName}-${user.fullname}`;
        if (!isAdd) {
          delete this.selectedPersons[user.id];
        } else {
          this.selectedPersons[user.id] = user;
        }
      });

      this.updateUsers(this.users, isAdd);
    },
    // 验证是否选中
    checkStatus() {
      if (this.value.length === 0) {
        this.isCancelSelect = false;
        this.$refs['userTable'].clearSelection();
      } else {
        this.users.forEach(user => {
          if (this.selectedPersons.hasOwnProperty(user.id)) {
            this.$refs['userTable'].toggleRowSelection(user, true);
          } else {
            this.$refs['userTable'].toggleRowSelection(user, false);
          }
        });
      }
    },
    // 无可用机构-数据置空
    orgInvalidHandler() {
      this.orgInvalid = true;
      this.users = [];
      this.totalPage = 0;
      this.count = 0;
    }
  },
  watch: {
    value(v) {
      this.formatUsers(v);
      this.checkStatus();
    },
    users() {
      this.timeStamp = new Date().valueOf();
    },
    isShowDeptment(v) {}
  }
};
</script>

<style lang="scss" scoped></style>
