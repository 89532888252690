var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yxtbiz-group-source-selector" },
    [
      _c(
        "yxt-tabs",
        {
          attrs: { "drawer-top": true },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c("yxt-tab-pane", {
            attrs: {
              label: _vm.$t("pc_biz_common__select_user").d("选择人员"),
              name: "1",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "yxtbiz-group-source-selector-body" },
        [
          _c(
            "yxt-row",
            {
              staticClass:
                "yxt-user-selector-container yxt-user-multi-container",
              attrs: { type: "flex" },
            },
            [
              _c(
                "yxt-row",
                { staticClass: "mb16" },
                [
                  _c("yxt-input", {
                    staticClass: "ml12",
                    staticStyle: { width: "240px" },
                    attrs: {
                      placeholder: _vm.$t("biz_udp_enter_name"),
                      searchable: "",
                    },
                    on: { search: _vm.searchUser },
                    model: {
                      value: _vm.params.key,
                      callback: function ($$v) {
                        _vm.$set(_vm.params, "key", $$v)
                      },
                      expression: "params.key",
                    },
                  }),
                ],
                1
              ),
              _c(
                "yxt-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  key: "persons" + _vm.timeStamp,
                  ref: "userTable",
                  staticClass: "yxt-user-table",
                  attrs: { height: _vm.tableHeight, data: _vm.tableData },
                  on: {
                    "select-all": _vm.selectAllUser,
                    select: _vm.selectUser,
                  },
                },
                [
                  _c("yxt-table-column", {
                    attrs: {
                      "show-overflow-tooltip": false,
                      "class-name": "yxtbiz-table-selection",
                      type: "selection",
                      width: "40",
                    },
                  }),
                  _c("yxt-table-column", {
                    attrs: {
                      label: _vm.$t("pc_biz_core_lbl_accnum").d("账号"),
                      "show-overflow-tooltip": true,
                      "popover-html": true,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.username))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("yxt-table-column", {
                    attrs: {
                      label: _vm.$t("pc_biz_core_lbl_name"),
                      "show-overflow-tooltip": true,
                      "popover-html": true,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm.isOpenData
                              ? _c("yxtbiz-user-name", {
                                  attrs: { name: scope.row.fullname },
                                })
                              : _c("span", [
                                  _vm._v(_vm._s(scope.row.fullname)),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("yxt-table-column", {
                    attrs: {
                      label: _vm.$t("pc_biz_core_lbl_department"),
                      "show-overflow-tooltip": true,
                      "popover-html": true,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm.isOpenData
                              ? _c("yxtbiz-dept-name", {
                                  attrs: { name: scope.row.deptName },
                                })
                              : _c("span", [
                                  _vm._v(_vm._s(scope.row.deptName)),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("yxt-pagination", {
                staticClass: "mt16 align-right",
                attrs: {
                  "page-size": _vm.params.pageSize,
                  "page-sizes": [5, 10, 20, 30, 50, 100],
                  "current-page": _vm.params.pageIndex,
                  "simple-total": "",
                  layout: "total, prev, pager, next",
                  total: _vm.totalPage,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.params, "pageIndex", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.params, "pageIndex", $event)
                  },
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "yxtbiz-group-source-selector-body-selected pull-right pr0",
            },
            [
              _c("yxt-scrollbar", { attrs: { "fit-height": true } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "yxtbiz-group-source-selector-body-selected-tags",
                  },
                  _vm._l(_vm.list, function (item) {
                    return _c(
                      "yxt-tag",
                      {
                        key: item.id,
                        staticClass: "tag-info mr5 mt5 ellipsis",
                        attrs: { size: "small", closable: "", type: "info" },
                        on: {
                          close: function ($event) {
                            return _vm.remove(item)
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "close-tag" },
                          [
                            _c(
                              "yxt-tooltip",
                              {
                                staticClass: "item",
                                attrs: { effect: "dark", placement: "top" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    attrs: { slot: "content" },
                                    slot: "content",
                                  },
                                  [
                                    _vm.isOpenData
                                      ? _c("yxtbiz-user-name", {
                                          attrs: {
                                            name: item.name || item.fullname,
                                          },
                                        })
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(item.name || item.fullname)
                                          ),
                                        ]),
                                  ],
                                  1
                                ),
                                _vm.isOpenData
                                  ? _c("yxtbiz-user-name", {
                                      staticClass: "close-tag_width_tail",
                                      attrs: {
                                        name: item.name || item.fullname,
                                      },
                                    })
                                  : _c(
                                      "span",
                                      { staticClass: "close-tag_width_tail" },
                                      [
                                        _vm._v(
                                          _vm._s(item.name || item.fullname)
                                        ),
                                      ]
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  1
                ),
              ]),
              _c(
                "yxt-button",
                {
                  staticClass: "user-clear font-size-12",
                  attrs: {
                    type: "text",
                    disabled: Object.values(this.list).length === 0,
                  },
                  on: { click: _vm.clearAll },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("pz_biz_share_btn_clearall").d("清除所有")
                      ) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }