var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isShow
    ? _c("div", { staticClass: "is-content" }, [
        _vm._v(
          "\n    " +
            _vm._s(
              (_vm.msg.contentDetail && _vm.msg.contentDetail.content) ||
                _vm.settings.assistWele ||
                _vm
                  .$t("pc_biz_ai_question_default_tips")
                  .d("你好，我是AI助手，很高兴为你服务！")
            ) +
            "\n    "
        ),
        _c("div", { staticClass: "promot-content" }, [
          _c(
            "div",
            {
              class: {
                "promot-header": true,
                "fullscreen-promot-header": _vm.fullscreen,
              },
            },
            [
              _c("div", { staticClass: "mt8" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm.$t("pc_biz_ai_promot_tips").d("你可以试着这样问我：")
                    ) +
                    "\n            "
                ),
              ]),
              _vm.changeBtn
                ? _c(
                    "div",
                    {
                      staticClass: "changeList",
                      on: { click: _vm.changeList },
                    },
                    [
                      _c("yxtf-svg", {
                        staticClass: "icon-copy",
                        attrs: {
                          width: "20px",
                          height: "20px",
                          "icon-class": "turn-right",
                        },
                      }),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("pc_biz_ai_change_promot").d("换一换"))
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "promot-list",
              class: {
                "promot-space": !_vm.fullscreen,
                "fullscreen-promot": _vm.fullscreen,
              },
            },
            _vm._l(_vm.promots, function (item) {
              return _c(
                "div",
                {
                  key: item,
                  staticClass: "promot-list-item",
                  on: {
                    click: function ($event) {
                      return _vm.promptClick(item)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "list-item" }, [
                    _c("span", { staticClass: "point" }),
                    _vm._v(_vm._s(item) + "\n                "),
                  ]),
                ]
              )
            }),
            0
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }