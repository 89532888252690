<template>
  <yxtf-popover
    placement="top"
    trigger="hover"
    effect>
    <div>{{ popText }}</div>
    
    <span v-if="iconName === 'share'" @click.stop="$emit('click')" slot="reference">
      <yxt-svg
      :remote-url='svgUrl'
      class="color-gray-7 hover-primary-6 hand"
      :width="iconSize"
      :height="iconSize"
      :icon-class="iconName"
    /></span>
    <span v-else-if="iconName === 'close'" @click.stop="$emit('click')" slot="reference">
      <yxt-svg
      remote-url='https://stc.yxt.com/ufd/407a24/assistent/pc/svg'
      class="color-gray-7 hover-primary-6 hand"
      :width="iconSize"
      :height="iconSize"
      :icon-class="iconName"
    /></span>
    <span v-else-if="iconName === 'fullscreen' || iconName ==='window'" @click.stop="$emit('click')" slot="reference">
      <yxt-svg
      remote-url='https://stc.yxt.com/ufd/407a24/assistent/h5/svg'
      class="color-gray-7 hover-primary-6 hand"
      :width="iconSize"
      :height="iconSize"
      :icon-class="iconName"
    /></span>
    <span v-else @click="$emit('click')" slot="reference">
      <yxt-svg
      :remote-url='svgUrl'
      class="color-gray-7 hover-primary-6 hand"
      :width="iconSize"
      :height="iconSize"
      :icon-class="iconName"
    />
    </span>
  </yxtf-popover>
</template>

<script>
export default {
  name: 'HeaderIcon',
  props: {
    remote: {
      type: Boolean,
      default: false
    },
    popText: {
      type: String,
      default: ''
    },
    iconName: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 20
    }
  },
  computed: {
    iconSize() {
      return this.size + 'px';
    }
  },
  data() {
    return {
      svgUrl: this.remote ? `${this.$staticBaseUrl}assets/14a8350a/9a77b0e7/` : ''
    };
  }
};
</script>
