<script>
import { Dropdown, DropdownMenu, DropdownItem } from 'yxt-pc';
import {getPermisisionOrgList, getGroupLoginAuthCode } from './service';

export default {
  name: 'YxtbizNavLang',
  components: {
    YxtDropDown: Dropdown,
    YxtDropdownMenu: DropdownMenu,
    YxtDropdownItem: DropdownItem
  },
  props: ['className'],
  data() {
    return {
      activeId: localStorage.getItem('orgId'),
      orgList: []
    };
  },
  created() {
    if (!this.enableGroupCorp) return;
    getPermisisionOrgList().then(res=>{
      this.orgList = res.datas;
    }).catch(()=>{});
  },
  computed: {
    enableGroupCorp() {
      return window.localStorage.getItem('enableGroupCorp') && window.localStorage.getItem('enableGroupCorp') === 'true';
    },
    orgName() {
      return localStorage.getItem('orgName');
    }
  },
  methods: {
    handleChange(org) {
      if (org && org.orgId === this.activeId) return;
      // this.activeId = org.orgId;
      getGroupLoginAuthCode({
        targetDomain: org.domain,
        targetOrgId: org.orgId
      }).then(res=>{
        if (res.code) {
          // 切换域名跳转至登录授权页
          window.location.href = `//${org.domain}/#/group/auth?code=${res.code}`;
        }
      }).catch(err=>{
        err && err.message && this.$message.error(err.message);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.ell {
  overflow:hidden;  
  display: inline-block;
  white-space:nowrap;
	text-overflow:ellipsis;
  max-width: 112px;
  color:#000000;
  vertical-align: bottom;
  padding-right: 0;
}
</style>
