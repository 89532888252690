<template>
  <div class="yxtbiz-ai-robot__treasure-body-wrap">
    <yxtf-empty v-if="!list || list.length === 0" class="pos-center" size='medium' :empty-text="$t('pc_biz_lbl_empty_app').d('暂无应用')" />
    <div v-else v-for="(item, index) in list" :key="index">
      <div class="yxtbiz-ai-robot__treasure-body--title">{{item.categoryName}}</div>
      <div class="yxtbiz-ai-robot__treasure-body--list">
        <div class="yxtbiz-ai-robot__treasure-body--item hand" v-for="sItem in item.appBeanList" :key="sItem.id" @click="handleUseBtn(sItem)">
          <div class="yxtbiz-ai-robot__treasure-body--head">
            <div class="yxtbiz-ai-robot__treasure-body--description">
              <yxt-image :src="sItem.icon || ($staticBaseUrl + 'ufd/55a3e0/ai-select.png')" class="yxtbiz-ai-robot__treasure-body--img"></yxt-image>
              <span class="ellipsis">{{sItem.appName}}</span>
            </div>
             <yxtf-tooltip :disabled="!(!getCollectDisabled && sItem.subscribe === 1)" :content="$t('pc_biz_msg_set_max_10_app').d('最多设置10个常用应用')" placement="top">
                <div 
                class="yxtbiz-ai-robot__treasure-body--collect"
                :class="{
                  'yxtbiz-ai-robot__treasure-body--ncollect': sItem.subscribe === 0,
                'yxtbiz-ai-robot__treasure-body--subscribe': sItem.subscribe === 1,
                'no-allow': !getCollectDisabled && sItem.subscribe === 1
                }"
                @click.stop="handleCollect(item, sItem)">
                <yxt-svg
                  :remote-url="$staticBaseUrl + 'ufd/55a3e0'"
                  class="yxtbiz-ai-robot__treasure-body--collect-svg"
                  :width="28"
                  :height="28"
                  :icon-class="sItem.subscribe === 0 ? 'collect': 'ncollect'" />
              </div>
             </yxtf-tooltip>
            
          </div>
          <div class="yxtbiz-ai-robot__treasure-body--content ellipsis-2">
            {{sItem.guide}}
          </div>
          <div class="yxtbiz-ai-robot__treasure-body--footer">
            <div class="yxtbiz-ai-robot__treasure-body--footer-chat">
              <yxt-svg
                class="mt6"
                :remote-url="$staticBaseUrl + 'ufd/55a3e0'"
                :width="20"
                :height="20"
                icon-class="chat-ai" />
            </div>
            <yxtf-tooltip :disabled="sItem.permission" :content="$t('pc_biz_msg_permison_cancel_common').d('已无当前知识助手使用权限，可取消常用')" placement="top">
              <yxt-button 
                :disabled="!sItem.permission" 
                class="yxtbiz-ai-robot__treasure-body--footer-operation"
                type="primary" 
                >
                {{$t('pc_biz_lbl_use_btn').d('使用')}}
              </yxt-button>
            </yxtf-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TreasureList',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    getCollectDisabled() {
      let num = 0;
      this.list.forEach(item => {
        const scribeNums = (item.appBeanList || []).filter(sItem => {
          return sItem.subscribe === 0;
        });
        if (scribeNums) {
          num += scribeNums.length;
        }
      });
      return num < 10;
    }
  },
  methods: {
    handleUseBtn(item) {
      this.$emit('operation', item);
    },
    handleCollect(item, sItem) {
      if (sItem.subscribe === 1 && (!this.getCollectDisabled || !sItem.permission)) {
        return;
      }
      this.$emit('toggleCollect', item, sItem);
    }
  }
};
</script>