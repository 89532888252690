<!-- 我的培训 -->
<template>
  <article class="yxtbiz-ai-robot-joined-project">
    <EmptyData v-if="projectList.length === 0" />
    <template v-else>
      <header :class="['color-gray-7', size === 'small' ? 'font-size-14 lh24' : 'font-size-16 lh28']">{{$t('pc_biz_following_project_found' /* 为您找到以下培训项目： */)}}</header>
      <main>
        <ul>
          <li
            v-for="project in projectList"
            :key="project.projectId"
            @click="handleProjectClick(project)"
            class="yxtbiz-ai-robot-joined-project__item"
          >
            <yxt-image
              class="yxtbiz-ai-robot-joined-project__cover"
              :src="project.imageUrl"
            ></yxt-image>
            <div class="ml12 over-hidden">
              <div :class="[size === 'small' ? 'ellipsis-2 font-size-14 lh22' : 'ellipsis font-size-16  lh24', 'color-gray-10' ]">{{ project.name }}</div>
              <div :class="[size === 'small' ? 'mt4' : 'mt8', 'yxtbiz-flex-center lh20 color-gray-7 font-size-12']">
                <div
                  class="yxtbiz-flex-center"
                  v-if="!project.completeStatus"
                >
                  <!-- TODO: 进度为 0 - 99 判断下颜色展示 -->
                  <div :class="['mr12', {'color-orange-6': project.processRate > 0}]">{{$t('pc_biz_learned_progress' /* 已学{0}% */, [project.processRate])}}</div>
                  <div class="mr4">{{$t('pc_biz_appraise_finish_status')}}</div>
                  <div>{{ finishedProgress(project) }}</div>
                </div>
                <div
                  v-else
                  class="yxtbiz-flex-center"
                >
                  <yxt-svg
                    class="mr4"
                    width="12px"
                    height="12px"
                    icon-class="biz/mobile-result-success"
                  />
                  <div class="mr12">{{$t('pc_biz_shoptour_lbl_finished' /* 已完成 */)}}</div>
                  <div>{{ finishedProgress(project) }}</div>
                </div>
                <template v-if="size === 'large'">
                  <yxt-divider direction="vertical"></yxt-divider>
                  <div>{{getDateTimeFormatted({startTime: project.startTime, endTime: project.endTime})}}</div>
                </template>
              </div>
              <div
                v-if="size === 'small'"
                class="mt2 lh20 color-gray-7 font-size-12"
              >{{getDateTimeFormatted({startTime: project.startTime, endTime: project.endTime})}}</div>
            </div>
          </li>
        </ul>
        <ViewMoreButton
          v-if="count > 3"
          @click="handleViewMoreClick"
        />
      </main>
    </template>
  </article>
</template>

<script>
import ViewMoreButton from '../common/view-more-button';
import utils from 'yxt-biz-pc/packages/common-util/utils.js';
import propsMixin from '../common/props-mixin.js';
import EmptyData from '../common/empty-data.vue';
import qs from 'qs';

export default {
  name: 'JoinedProject',
  components: {
    ViewMoreButton,
    EmptyData
  },
  mixins: [propsMixin],
  inject: ['textLang'],
  data() {
    return {
    };
  },
  computed: {
    compulsoryText() {
      return this.textLang.compulsoryText;
    },
    electiveText() {
      return this.textLang.electiveText;
    },
    count() {
      const paging = this.datas.paging || {};
      return paging.count;
    },
    projectList() {
      return this.datas.datas || [];
    }
  },
  methods: {
    finishedProgress(project) {
      const {
        studyStandard, completeCount, taskCount, allCompleteCount, allTaskCount, requiredSetting, electiveSetting,
        completeElectiveTaskCount, completePeriodCount, periodSetting, studyScoreSetting, studyScore
      } = project;
      let finishedProgressStr = '';
      // 完成标准 默认0 完成所有必修 1完成所有任务 2完成项目内指定任务数量 3完成项目内指定阶段数量 4获得项目内指定学分
      switch (studyStandard) {
        case 0:
          finishedProgressStr = `${completeCount}/${taskCount}（${this.compulsoryText}）`;
          break;
        case 1:
          finishedProgressStr = `${allCompleteCount}/${allTaskCount}（${this.$t('pc_o2o_lbl_alltask')}）`;
          break;
        case 2:
          finishedProgressStr = `${completeCount > requiredSetting ? requiredSetting : completeCount}/${requiredSetting}（${this.compulsoryText}），${completeElectiveTaskCount > electiveSetting ? electiveSetting : completeElectiveTaskCount}/${electiveSetting}（${this.electiveText}）`;
          break;
        case 3:
          finishedProgressStr = `${completePeriodCount}/${periodSetting}（${this.$t('pc_o2o_lbl_period')}）`;
          break;
        case 4:
          finishedProgressStr = `${studyScore}/${studyScoreSetting}（${this.$t('pc_o2o_lbl_credit')}）`;
          break;
      }
      return finishedProgressStr;
    },
    getDateTimeFormatted: utils.getDateTimeFormatted,
    handleProjectClick(project) {
      const projectUrl = `${window.location.origin}/o2o/#/project/detail/${project.projectId}`; // 项目详情地址
      const projectSetUrl = `${window.location.origin}/o2o/#/project-set/${project.projectId}`; // 项目集地址
      window.open(project.category === 1 ? projectSetUrl : projectUrl, '_blank');
    },
    handleViewMoreClick() {
      const aMapForProjectRangeToCustomtype = {
        1: 2, // 进行中
        2: 1, // 未开始
        3: 3, // 已结束
        4: 4 // 已归档
      };
      const { searchKey: keyword, showUnFinish, projectRange } = this.params;
      const projectListUrl = `${window.location.origin}/o2o/#/project?${qs.stringify({
        keyword,
        showUnFinish,
        customtype: aMapForProjectRangeToCustomtype[projectRange]
      })}`;
      window.open(projectListUrl, '_blank');
    }
  }
};
</script>

<style lang="scss" scoped>
.yxtbiz-ai-robot-joined-project {
  &__cover {
    width: 112px;
    height: 64px;
    border-radius: 4px;
    flex-shrink: 0;
  }

  > header {
    padding-left: 12px;
    margin-bottom: 12px;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 12px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background: #f4f5f6;
    }
  }
}
</style>
