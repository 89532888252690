var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "yxtbiz-ai-robot__dialog--msg is-answer",
      class: {
        "is-long-size": _vm.isSearch,
        "bbs-search": _vm.mode === "bbs",
        fullscreen: _vm.fullscreen,
      },
    },
    [
      !_vm.msg.isHistory &&
      _vm.msg.contentDetail &&
      _vm.msg.contentDetail.aiTips
        ? _c("div", { staticClass: "yxtbiz-ai-robot__biz-answer--chat" }, [
            _vm._v(_vm._s(_vm.msg.contentDetail.aiTips)),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "yxtbiz-ai-robot__dialog--container" },
        [
          _vm.showPortrait
            ? _c(
                "div",
                { class: { "head-img": _vm.isSearch, mr12: !_vm.isSearch } },
                [
                  _c("yxtf-portrait", {
                    staticClass: "yxtbiz-ai-robot__dialog--portrait",
                    class: { hand: _vm.isSearch },
                    attrs: { size: "28px", imgUrl: _vm.settings.assistHeadImg },
                    on: {
                      click: function ($event) {
                        _vm.isSearch && !_vm.isWaitAnswer && _vm.$emit("launch")
                      },
                    },
                  }),
                  _vm.isSearch
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.$t("pc_biz_ai_ask").d("问一问"))),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.msg.type === 3
            ? _c(
                "biz-answer",
                {
                  attrs: {
                    msg: _vm.msg,
                    incorrectText: _vm.aiIncorrectText,
                    lastMsg: _vm.lastMsg,
                    isWaitAnswer: _vm.isWaitAnswer,
                  },
                  on: { resend: (val) => _vm.$emit("resend", val) },
                },
                [
                  _c(
                    _vm.anserComponents,
                    {
                      tag: "component",
                      attrs: {
                        mode: _vm.clientType,
                        datas: _vm.responeseData,
                        params: _vm.requestData,
                        size: _vm.fullscreen ? "large" : "small",
                      },
                    },
                    [
                      _c("span", { staticClass: "ph12" }, [
                        _vm._v(
                          _vm._s(
                            _vm
                              .$t("pc_biz_msg_error_refersh")
                              .d("抱歉，系统发生异常，请稍后再试")
                          )
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _c(_vm.anserComponents, {
                tag: "component",
                attrs: {
                  msg: _vm.msg,
                  isWaitAnswer: _vm.isWaitAnswer,
                  isSearch: _vm.isSearch,
                  fullscreen: _vm.fullscreen,
                  lastMsg: _vm.lastMsg,
                  size: _vm.fullscreen ? "large" : "small",
                },
                on: {
                  resend: (val) => _vm.$emit("resend", val),
                  feedback: function ($event) {
                    return _vm.$emit("feedback")
                  },
                  PromotClick: _vm.PromotClick,
                  submit: (data) => _vm.$emit("submit", data),
                  promotEmpty: function ($event) {
                    return _vm.$emit("promotEmpty")
                  },
                  scrollToEnd: function ($event) {
                    return _vm.$emit("scrollToEnd")
                  },
                },
              }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }