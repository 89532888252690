<template>
  <div>
    <yxt-row
      type="flex"
      class="yxt-user-selector-container yxt-user-multi-container"
    >
      <div class="yxtbiz-user-search-wrap mb16">
        <yxt-select v-if="isShowTeamSelect" size="small" class="mr12" v-model="currSelectTeamId" @change="handleTeamType">
          <yxt-option v-for="(item, index) in teamSelectOptions" :key="index" :label="item.teamName" :value="item.teamId" />
        </yxt-select>
        <yxt-popover
          :append-to-body="false"
          popper-class="yxtbiz-user-selector-popover"
          trigger="manual"
          width="338"
          placement="bottom-start"
          :visible-arrow="false"
          v-model="showMoreFilter"
        >
          <yxt-button
            slot="reference"
            plain
            size="small"
            @click.stop="showFilterBox"
            style="height: 32px;"
            class="yxtbiz-filter-btn mr12 pull-left"
          >
            <yxt-svg
              width="20px"
              height="20px"
              icon-class="filter"
              class="v-top"
            />
            <span class="ml8 v-top" style="font-size: 14px;color: #595959;">{{ $t('pc_biz_udp_lbl_moreFilter') }}</span>
            <i
              class="ml8"
              :class="
                showMoreFilter ? 'yxt-icon-arrow-up' : 'yxt-icon-arrow-down'
              "
            ></i>
          </yxt-button>
          <yxt-form label-position="top" label-width="100px">
            <yxt-form-item :label="$t('pc_biz_rank_dept').d('部门')" prop="department" size="small">
              <tree-select
                style="width: 100%;"
                v-model="depts"
                :placeholder='$t("pc_biz_lbl_selecttip").d("请选择")'
                @click.native='showTree("department")'
                @remove-tag='removeDept'
              ></tree-select>
            </yxt-form-item>
            <yxt-form-item :label="$t('biz_udp_position').d('岗位')" prop="position" size="small">
              <tree-select
                style="width: 100%;"
                v-model="positions"
                @remove-tag='removePosition'
                :placeholder='$t("pc_biz_lbl_selecttip").d("请选择")'
                @click.native='showTree("position")'
              ></tree-select>
            </yxt-form-item>
            <yxt-form-item :label="$t('pc_biz_range_select_001').d('入职时间')" prop="hireTime" size="small">
              <yxt-date-picker
                style="width: 100%;"
                v-model="hireTime"
                size='small'
                value-format="yyyy-MM-dd"
                type="daterange"
                :placeholder="$t('pc_biz_rule_select_date').d('选择日期')">
              </yxt-date-picker>
            </yxt-form-item>
            <yxt-form-item size="small" class="btn-item">
              <yxt-button type="primary" @click="filterUsers">{{ $t('pc_biz_udp_btn_confirm').d('确定') }}</yxt-button>
              <yxt-button  style="margin-left:12px" plain @click="reset">{{ $t('pc_comp_table_resetFilter').d('重置') }}</yxt-button>
            </yxt-form-item>
          </yxt-form>
        </yxt-popover>
        <yxtbiz-type-search
          class="mr12"
          size="small"
          maxlength="20"
          :kwType.sync="userData.kwType"
          :keywords.sync="userValue"
          :options="[1, 2]"
          :selectCache="true"
          searchable
          @search="searchUser"
        ></yxtbiz-type-search>
      </div>
      <yxt-table
        :ref="refTable"
        @select-all="selectAllUser"
        @select="selectUser"
        v-loading="loading"
        :height="tableHeight"
        class="yxt-user-table"
        :data="persons"
        :row-key="row => row.id"
        :key="'persons' + timeStamp"
      >
        <template slot="empty">
          <div style="line-height:normal">
            <yxt-empty />
          </div>
        </template>
        <!-- 表格单多选 -->
        <yxt-table-column
          v-if="multiple"
          :show-overflow-tooltip="false"
          class-name="yxtbiz-table-selection"
          type="selection"
          width="42"
          :selectable="(row) => !row.disabled"
          reserve-selection
          clear-padding="right"
        ></yxt-table-column>
        <yxt-table-column v-else :show-overflow-tooltip="false" width="40">
          <template slot-scope="scope">
            <yxt-radio
              v-model="singleSelect"
              @change="selectUser(scope.row.id, scope.row)"
              :label="scope.row.id"
            ></yxt-radio>
          </template>
        </yxt-table-column>
        <yxt-table-column
          width="18"
          align="right"
          clear-padding="left-right"
          v-if="multiple"
        >
          <template slot="header">
            <yxt-dropdown
              placement="bottom-start"
              @command="tableHeaderCommand"
            >
              <span> </span>
              <yxt-dropdown-menu slot="dropdown">
                <yxt-dropdown-item command="selectCurrent">{{
                  $t('pc_biz_enroll_select_current_page').d('选择本页')
                }}</yxt-dropdown-item>
                <yxt-dropdown-item command="selectAll">{{
                  $t('pc_biz_enroll_select_all').d('选择全部')
                }}</yxt-dropdown-item>
                <yxt-dropdown-item command="clearSelect">{{
                  $t('pc_biz_enroll_clear_all').d('清空所选')
                }}</yxt-dropdown-item>
              </yxt-dropdown-menu>
            </yxt-dropdown>
          </template>
        </yxt-table-column>
        <yxt-table-column
          :label="$t('pc_biz_core_lbl_name')"
          :show-overflow-tooltip="true"
          :popover-html="true"
        >
          <template slot-scope="scope">
            <yxtbiz-user-name v-if="isOpenData" :name="scope.row.fullname" />
            <span v-else>{{ scope.row.fullname }}</span>
          </template>
        </yxt-table-column>
        <yxt-table-column :label="$t('pc_biz_core_lbl_accnum')" prop="username" :show-overflow-tooltip="true" :popover-html="true"></yxt-table-column>
        <yxt-table-column
          :formatter="formatUserNo"
          :label="$t('pc_biz_udp_lbl_userNo')"
          :show-overflow-tooltip="true"
          prop="userNo"
        >
        </yxt-table-column>
        <yxt-table-column
          :label="$t('pc_biz_core_lbl_department')"
          :show-overflow-tooltip="true"
          :popover-html="true"
        >
          <template slot-scope="scope">
            <span v-if="!scope.row.deptName">--</span>
            <yxtbiz-dept-name
              v-else-if="isOpenData"
              :name="scope.row.deptName"
            />
            <span v-else>{{ scope.row.deptName }}</span>
          </template>
        </yxt-table-column>
        <yxt-table-column
          :label="$t('biz_udp_position')"
          :show-overflow-tooltip="true"
          :formatter="formatPosition"
        ></yxt-table-column>
      </yxt-table>
      <yxt-row
        align="middle"
        class="yxtbiz-user-selector-mask mt16"
        type="flex"
      >
        <yxt-row type="flex" align="middle" class="col-flex-1"></yxt-row>
        <yxt-pagination
          @size-change="sizeChange"
          @current-change="pageChange"
          :current-page.sync="page"
          :page-size="userData.limit"
          simple-total
          layout="total, prev, pager, next"
          :total="count"
          :pager-count="5"
        ></yxt-pagination>
      </yxt-row>
    </yxt-row>
    <yxt-drawer
      :title='$t("biz_udp_select_dept")'
      :append-to-body="true"
      :modal-append-to-body="true"
      :destroy-on-close='true'
      size='480px'
      :visible.sync='drawerDeptVisible'
    >
      <div class="yxtbiz-dept-tree-full-height">
        <yxtbiz-dept-tree
          size="small"
          version="v1"
          class="department_tree"
          ref="deptTree"
          show-checkbox
          filterable
          :openSelectSiblingNode="true"
          :count="true"
          :check-strictly="true"
          functions
          wrap-width="100%"
          :childrenIncluded="2"
          :default-checked-keys="defaultDeptCheckeds"
          :checkedLinkage="1"
          :teamId="currSelectTeamId"
          core="team"
          nodeKey="id"
          ></yxtbiz-dept-tree>
      </div>
      <div slot='footer'>
        <yxt-button
          plain
          @click='cancelTree'
        >{{$t('pc_biz_udp_btn_cancel')}}</yxt-button>
        <yxt-button
          type='primary'
          @click='confirmTree'
        >{{$t('pc_biz_udp_btn_confirm')}}</yxt-button>
      </div>
    </yxt-drawer>
    <yxt-drawer
      :title='$t("pc_biz_udp_tit_selectPotision")'
      :visible.sync='drawerPosVisible'
      :destroy-on-close='true'
      size='960px'
    >
      <yxtbiz-range-selector
      ref="position"
      model="array"
      v-model="selectedPositions"
      :tabs="['position']"
      ></yxtbiz-range-selector>
      <div
        slot='footer'
        class='dialog-footer'
      >
        <yxt-button
          plain
          @click='drawerPosVisible = false'
        >{{$t('pc_biz_udp_btn_cancel')}}</yxt-button>
        <yxt-button
          type='primary'
          @click='confirmPos'
        >{{$t('pc_biz_udp_btn_confirm')}}</yxt-button>
      </div>
    </yxt-drawer>
  </div>
</template>

<script>
import deptTree from '../../../dept-tree';
import SelectPopover from '../components/SelectPopover';
import TreeSelect from '../components/tree-select';
import resizeTable from '../../mixins/resizeTable';
import openDataMixin from '../../mixins/openData';
import commonMixin from '../../mixins/common';
import { getTeamUsers, getTeamSelect } from '../service';
import { i18n } from 'yxt-biz-pc/packages/common-util/changeLangs';

export default {
  components: {
    deptTree,
    SelectPopover,
    TreeSelect
  },
  mixins: [resizeTable, openDataMixin, commonMixin],
  props: {
    value: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'persons'
    },
    scope: {
      type: Number
    },
    showAll: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 0
    },
    isShowDeptment: {
      type: Boolean,
      default: true
    },
    isOrgSelectAlone: {
      type: Boolean,
      default: false
    },
    teamId: {
      type: String,
      default: ''
    },
    isSelectSelf: {
      // 是否能选择自己
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      refTable: 'userTable',
      drawerDeptVisible: false,
      drawerPosVisible: false,
      depts: [],
      positions: [],
      selectedPositions: [],
      hireTime: [],
      defaultDeptCheckeds: [],
      showMoreFilter: false,
      isSearchAll: false,
      isShowTeamSelect: true, // 是否显示团队下拉
      currSelectTeamId: '', // 当前选中的teamId
      userValue: '',
      page: 1,
      count: 0,
      tableHeight: 0,
      userData: {
        kwType: 1,
        scope: this.scope,
        showAll: this.showAll ? 1 : 0,
        deptInfos: [],
        positionIds: [],
        hireDateStart: '',
        hireDateEnd: '',
        searchKey: '',
        limit: 20,
        offset: 0,
        type: 3
      },
      loading: false,
      totalPage: 0,
      persons: [], // table数据
      teamSelectOptions: [], // 团队下拉选择项
      timeStamp: '',
      formatPosition: row => {
        if (row.positionName === undefined) {
          return (
            row.positionSet
              .map(item => {
                return item.name;
              })
              .join(';') || '--'
          );
        } else {
          const parttimePosition = row.parttimePositionSet ? Array.from(row.parttimePositionSet) : [];
          const posList = parttimePosition.map(item => item.name);
          posList.push(row.positionName || '');
          return posList.filter(item => item !== '').join(';') || '--';
        }
      },
      formatUserNo: row => {
        return row.userNo || '--';
      }
    };
  },
  watch: {
    selectData: {
      deep: true,
      handler() {
        console.log('监听器也触发啦');
        this.performanceUpateSelected('persons');
        // 基础组件 toggleRowSelection 有个坑，传入的row必须是当前table所使用的那个数组中的row
        // 否则不管用，列如 persons[0] 与 row哪怕长得一样，persons[0]可以生效而row不生效，故这里
        // 将把获取到的数据进行重新处理
        this.persons = [...this.persons]; // 一种解决方案，直接把当前table数据重新赋值，即可解决
      }
    }
  },
  computed: {
    getTeamType() {
      if (this.currSelectTeamId === 'lineManager') return 1;
      if (this.currSelectTeamId === 'deptManager') return 2;
      return 3;
    },
    currUserId() {
      if (this.isSelectSelf) return '';
      return window.localStorage.userId;
    }
  },
  async created() {
    console.log(this.teamId, 'teamId', 'limit', this.limit);
    if (this.teamId) {
      // 如果传入了teamId，则不显示团队拉框，采用传入的teamId
      this.currSelectTeamId = this.teamId;
      this.userData.teamId = this.currSelectTeamId;
      this.userData.type = this.getTeamType;
      this.isShowTeamSelect = false;
    } else {
      await this.getTeamSelect();
    }
    this.getUserData();
  },
  methods: {
    removeDept(dept) {
      this.depts = this.depts.filter(item => item.id !== dept.id);
    },
    removePosition(pos) {
      this.positions = this.positions.filter(item => item.id !== pos.id);
    },
    filterUsers() {
      const [hireDateStart = '', hireDateEnd = ''] = this.hireTime;
      this.userData.deptInfos = this.depts.map(item => ({ id: item.id, includeAll: item.includeAll }));
      this.userData.positionIds = this.positions.map(item => item.id);
      this.userData.hireDateStart = hireDateStart;
      this.userData.hireDateEnd = hireDateEnd;
      this.userData.offset = 0;
      this.getUserData();
      this.showMoreFilter = false;
    },
    reset() {
      this.resetFilter();
      this.filterUsers();
    },
    resetFilter() {
      this.depts = [];
      this.userValue = '';
      this.positions = [];
      this.hireTime = [];
      this.selectedPositions = [];
      this.userData.deptInfos = [];
      this.userData.positionIds = [];
      this.userData.hireDateStart = '';
      this.userData.hireDateEnd = '';
    },
    confirmTree() {
      const depts = this.$refs.deptTree.getCheckedNodes();
      if (depts.length > 200) {
        this.$message.error(i18n.t('pc_biz_range_select_002').d('最多只能选择200个部门'));
        return;
      }
      this.depts = depts;
      this.cancelTree();
    },
    cancelTree() {
      this.drawerDeptVisible = false;
    },
    async confirmPos() {
      const positions = await this.$refs.position.getSelectDataArray();
      if (positions.length > 200) {
        this.$message.error(i18n.t('pc_biz_range_select_003').d('最多只能选择200个岗位'));
        return;
      }
      this.positions = positions;
      this.drawerPosVisible = false;
    },
    showTree(type) {
      if (type === 'department') {
        this.drawerDeptVisible = true;
        this.setSelectedDepts('defaultDeptCheckeds', this.depts);
      } else {
        this.selectedPositions = [...this.positions];
        this.drawerPosVisible = true;
      }
    },
    setSelectedDepts(defaultCheckeds, checkeds) {
      this[defaultCheckeds] = checkeds.map(item => item.id);
    },
    handleTeamType(value) {
      this.getTeamType === 3 ? this.userData.teamId = value : this.userData.teamId = '';
      this.userData.type = this.getTeamType;
      this.userData.offset = 0;
      this.resetFilter();
      this.getUserData();
    },
    showFilterBox() {
      this.showMoreFilter = !this.showMoreFilter;
    },
    async getTeamSelect() {
      try {
        const res = await getTeamSelect();
        console.log(res);
        this.teamSelectOptions = res.datas.map(item => {
          if (item.type === 1) {
            return {
              ...item,
              teamId: 'lineManager'
            };
          }
          if (item.type === 2) {
            return {
              ...item,
              teamId: 'deptManager'
            };
          }
          return item;
        });
        if (this.teamSelectOptions.length) {
          this.currSelectTeamId = this.teamSelectOptions[0].teamId;
          this.userData.teamId = this.currSelectTeamId;
          this.userData.type = this.getTeamType;
        } else {
          this.isShowTeamSelect = false;
        }
      } catch (e) {
        this.$message.error(e.error.message);
      }
    },
    tableHeaderCommand(row) {
      this[row]();
    },
    selectCurrent() {
      // 选择本页
      this.selectCurrData('persons');
    },
    selectAll() {
      // 选择所有
      this.loadAll();
    },
    clearSelect() {
      // 清空所选
      this.$refs[this.refTable].clearSelection();
      this.$emit('change', [], 'persons');
    },
    async getUserData() {
      this.loading = true;
      try {
        this.userData.searchKey = this.userValue;
        const res = await getTeamUsers(this.userData);
        const datas = res.datas || [];
        const paging = res.paging || { count: 0, pages: 0 };
        this.persons = datas || [];
        this.persons.forEach(user => {
          user.nodeType = this.type;
          user.disabled = (user.id === this.currUserId);
        });
        this.totalPage = paging.pages;
        this.count = paging.count;
        this.loading = false;
        this.performanceUpateSelected('persons');
        console.log('997876');
      } catch (e) {
        this.persons = [];
        this.loading = false;
        this.$message({
          type: 'error',
          message: e.error.message
        });
      }
    },
    searchUser() {
      this.userData.offset = 0;
      this.page = 1;
      this.getUserData();
    },
    async loadAll() {
      this.loading = true;
      const limit = 3000;
      const reqCount = Math.ceil(this.count / limit); // 循环次数
      const reqArrs = []; // 请求数组
      for (let i = 0; i < reqCount; i++) {
        const params = Object.assign({}, this.userData, {
          limit: limit,
          offset: limit * i
        });
        reqArrs.push(params);
      }
      try {
        const results = await Promise.all(
          reqArrs.map(params => getTeamUsers(params))
        );
        const dataSource = [];
        results.forEach(res => {
          dataSource.push(...res.datas);
        });
        let data = dataSource.map(user => {
          user.nodeType = this.type;
          user.disabled = (user.id === this.currUserId);
          return user;
        });
        this.selectAllData(data, 'persons');
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    sizeChange(size) {
      this.userData.limit = size;
      this.page = 1;
      this.userData.offset = 0;
      this.getUserData();
    },
    pageChange(page) {
      this.page = page;
      this.userData.offset = (page - 1) * this.userData.limit;
      this.getUserData();
    },
    selectUser(items, row) {
      // 选中数据
      if (Array.isArray(items)) {
        // 多选
        this.performanceSelectData(items, 'persons');
      } else {
        this.$emit('change', [row], 'persons');
      }
    },
    selectAllUser(items) {
      // 全选
      this.performanceSelectData(items, 'persons');
    }
  }
};
</script>

<style lang="scss" scoped></style>
