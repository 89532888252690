<template>
  <div v-if="visible" class="yxtbiz-i18n-custom-template">
    <yxt-drawer
      :visible.sync="visible"
      v-bind="$attrs"
      :size="size"
      :before-close="beforeDrawClose"
      @close="close"
    >
      <div slot="title">
        <span class="font-size-20">{{title}}</span>
        <yxt-tooltip class="item" effect="dark" :content="$t('biz_msg_drawerTip')" placement="top">
          <span>
            <yxt-svg class='yxtbiz-i18n-custom-template__svgIcon color-gray-6 hover-primary-6' width="20px" height="20px" icon-class="question-cirlce-o"/>
          </span>
        </yxt-tooltip>
      </div>
      <div class="yxtbiz-i18n-custom-template__main">
        <yxt-form
          label-width="100px"
          :model="templateDrawer.formData"
          enableDetectFieldsChange
          ref="form"
          v-if="showForm"
        >
          <!-- 消息标题 -->
          <yxt-form-item
            v-for="(field, index) in templateDrawer.fields"
            :key="field.prop"
            :label="field.label"
            lable-width="120px"
            :prop="field.prop"
            :rules="field.rules"
            :class="'form-item-' + field.prop"
            :label-tooltip="field.type==='textarea'?$t('biz_msg_customMsgTip'):''"
          >
            <!-- pc内容 -->
            <yxtbiz-richeditor
              v-if="field.type === 'richeditor'"
              ref="control"
              @change="handleEditorChange($event, index)"
              :content="templateDrawer.formData[field.prop]"
              function-name="richeditor"
              :max-length="field.maxlength"
              tools-height="58px"
              height="500px"
              org-code="xxv2"
              :showI18nBtn="enableI18n"
              :filterContent ="false"
              app-code="core"
              module-name="msg"
              @click.native.capture="showLangDrawer($event,'richeditor')"
            ></yxtbiz-richeditor>
            <template v-if="enableI18n">
              <!-- h5内容 或 标题 -->
              <yxtbiz-i18n-input
                ref="control"
                style="width:100%"
                v-if="(tunnelName&&field.type==='textarea')||field.type==='text'"
                v-model="templateDrawer.formData[field.prop]"
                :maxlength="typeof field.maxlength === 'object' ? field.maxlength[curLang] : field.maxlength"
                :show-word-limit="field.showWordLimit"
                :placeholder="field.placeholder"
                :type="field.type"
                @click.native.capture="showLangDrawer($event,field.type)"
              ></yxtbiz-i18n-input>
            </template>
            <template v-else>
              <!-- h5内容 或 标题 -->
              <yxt-input
                ref="control"
                style="width:100%"
                v-if="(tunnelName&&field.type==='textarea')||field.type==='text'"
                v-model="templateDrawer.formData[field.prop]"
                :maxlength="typeof field.maxlength === 'object' ? field.maxlength[curLang] : field.maxlength"
                :show-word-limit="field.showWordLimit"
                :placeholder="field.placeholder"
                :type="field.type"
              ></yxt-input>
            </template>
          </yxt-form-item>
        </yxt-form>

        <!-- 多语言维护抽屉 -->
        <div v-if="langDrawer.visible" class="lang-drawer-wrap">
            <!-- :data-list="langDrawer.fields" -->
          <yxtbiz-i18n-lang
            :visible.sync="langDrawer.visible"
            :data-list="currentFields"
            :module="module"
            drawer-class="i18ncustom-langconf-drawer"
            @confirm="handleConfirmLang"
            :size="size"
          />
        </div>
      </div>
      <div class="lang-drawer-pic" v-if="tipInfoName || ifThirdImage">
        <yxtbiz-upload-image
          @fileAdded="fileAdded"
          @fileRemoved="fileRemoved"
          :list="list"
          :limit="1"
          :size="1024"
          :suggest="{w: 600, h: 256}"
          :filters="'.jpg,.png'"
          no-preview
        ></yxtbiz-upload-image>
        <span class="lang-drawer-pic_tip" v-if="tipInfoName">{{$t('biz_msg_tip_uploadTip',[tipInfoName])}}</span>
        <span class="lang-drawer-pic_tip" v-else-if="ifThirdImage">{{$t('pc_biz_imgcropper_title')}}</span>
      </div>
      <!-- 抽屉底部 -->
      <div slot="footer">
        <yxt-button plain @click="close">{{
          $t('pc_biz_udp_btn_cancel')
        }}</yxt-button>
        <yxt-button type="primary" @click="save">{{
          $t('pc_biz_core_btn_confirm')
        }}</yxt-button>
      </div>
    </yxt-drawer>
  </div>
</template>

<script>
import { getLanguage, saveLangKey } from 'yxt-i18n/es';
import { getCustomTemplateSvc, saveCustomTemplateSvc, getCusTemp} from './service';
import * as util from 'yxt-biz-pc/src/utils/util';
/**
 * 消息公告 自定义消息模板组件
 */

export default {
  name: 'CustomTemplate',
  props: {
    visible: { // 显示 自定义消息模板组件
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      default() {
        return this.$t('biz_msg_customMsg');
      }
    },
    size: {
      type: String,
      default: '960px'
    },
    code: { // 模块编号
      type: String | Number,
      required: true
    },
    targetId: { // 项目id
      type: String | Number,
      required: true
    },
    designId: {
      type: String | Number,
      default: ''
    },
    module: {
      type: String, // xuanxing(主站) op(boss平台)
      default: 'xuanxing'
    }
  },
  data() {
    return {
      curLang: 'zh', // 用户的当前语言
      enableI18n: null, // 开启国际化
      templateDrawer: { // 自定义模板抽屉
        fields: [],
        formData: {
          title: '',
          contPc: '',
          contApp: ''
        }
      },
      langDrawer: { // 多语言配置抽屉
        visible: false,
        fields: []
      },
      currentFields: [], // 当前选中的语种内容
      orgId: window.localStorage.getItem('orgId'),
      temCode: '',
      switches: {
        apppush: 0,
        appIm: 0,
        box: 0,
        ding: 0,
        feishu: 0,
        mail: 0,
        third: 0,
        wxcorp: 0,
        wxcorpIsv: 0,
        feishuIsv: 0
      },
      showForm: false, // 显示form
      tunnelName: '',
      tipInfoName: '',
      tunnelMap: {
        feishuIsv: this.$t('biz_lbl_feishuIsv'),
        feishu: this.$t('biz_lbl_feishu'),
        wxcorpIsv: this.$t('biz_lbl_wxworkIsv'),
        wxcorp: this.$t('biz_lbl_wxwork'),
        appIm: this.$t('biz_lbl_IM'),
        ding: this.$t('biz_lbl_dingtalk')
      },
      list: [],
      url: `${this.$staticBaseUrl}ufd/3f5568/common/pc_backstage/svg`,
      ifThirdImage: false // 对接了第三方消息通道且勾选了支持图文消息
    };
  },
  watch: {
    visible: {
      immediate: true,
      handler(isVisbile) {
        isVisbile && this.getCustomTemplate(); // start
      }
    },
    'templateDrawer.formData': {
      deep: true,
      handler(formData) {
        // 未开启国际化 提交formData的值 不需要同步
        if (!this.enableI18n) {
          return;
        }

        // 同步formdata 到 langdrawer.fields
        util.eachKey(formData, (value, prop) => {
          const langField = this.langDrawer.fields.find(field => field.prop === prop);
          if (!langField) {
            this.warn('not found langField', langField);
            return;
          }
          const curLangTran = this.getCurLangTran(langField.trans);
          curLangTran.transContent = value;
        });
      }

    }
  },
  computed: {
    hasPCChannel() {
      return /* this.switches.box ||  */this.switches.mail; // box 消息通道已不再使用
    },
    hasAppChannel() {
      return (
        this.switches.appIm ||
        this.switches.ding ||
        this.switches.third ||
        this.switches.wxcorp ||
        this.switches.wxcorpIsv ||
        this.switches.feishu ||
        this.switches.feishuIsv
      );
    },
    hasAppPushChannel() {
      return this.switches.apppush;
    }
  },
  created() {
    this.curLang = getLanguage();
    this.enableI18n = util.storage.get('enableI18n', true) === 1;
    this.languages = util.storage.get('lang4Get', true) || []; // 机构勾选的语言 只读数据不需要响应式
    this.log('开启国际化:', this.enableI18n);
    if (!this.curLang) {
      this.warn('获取不到当前语言:', this.curLang);
    } else {
      this.log('当前语言:', this.curLang);
    }

    // this.enableI18n = false; // for test
    // window.vvmm = this;
    // window.yxtI18n = yxtI18n;
    this.getCusTempCode();
  },
  methods: {
    getCusTempCode() {
      this.showForm = false;
      getCusTemp(this.orgId, this.code).then(res => {
        let nameList = [];
        let tipInfo = [];
        Object.keys(res.interConfigBean).forEach(key=>{
          if (res.interConfigBean[key] === 1 && this.tunnelMap[key]) nameList.push(this.tunnelMap[key]);
          if (res.eco.includes(key) && res.interConfigBean[key] === 1) tipInfo.push(this.tunnelMap[key]);
        });
        // 用于表单的name
        this.tunnelName = nameList.join(' & ');
        // 用于图片上传时候的提示
        this.tipInfoName = tipInfo.join('、');
        this.showForm = true;
        // 判断对接了第三方消息通道且勾选了支持图文消息
        this.ifThirdImage = res.interConfigBean.third === 1;
      }).catch(()=>{
        this.showForm = false;
      });
    },
    ...util.createLogger('i18n-custom-template'),
    showLangDrawer(event, type) {
      if (this.getIEVersion() === -1) { // IE浏览器不支持
        event.target.blur();
      }
      if (this.enableI18n && this.languages.length > 1) {
        event.stopPropagation();
        this.langDrawer.visible = true;
        this.currentFields = this.langDrawer.fields.filter(item => item.type === type);
      } else {
        // this.log('未开启国际化或语言数小于2');
      }
    },
    getIEVersion() {
      var userAgent = navigator.userAgent; // 取得浏览器的userAgent字符串
      var isIE = userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1; // 判断是否IE<11浏览器
      var isEdge = userAgent.indexOf('Edge') > -1 && !isIE; // 判断是否IE的Edge浏览器
      var isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf('rv:11.0') > -1;
      if (isIE) {
        var reIE = new RegExp('MSIE (\\d+\\.\\d+);');
        reIE.test(userAgent);
        var fIEVersion = parseFloat(RegExp['$1']);
        if (fIEVersion === 7) {
          return 7;
        } else if (fIEVersion === 8) {
          return 8;
        } else if (fIEVersion === 9) {
          return 9;
        } else if (fIEVersion === 10) {
          return 10;
        } else {
          return 6; // IE版本<=7
        }
      } else if (isEdge) {
        return 'edge'; // edge
      } else if (isIE11) {
        return 11; // IE11
      } else {
        return -1; // 不是ie浏览器
      }
    },
    getZhLangTran(...args) {
      return this.getCurLangTran(...args, 'zh');
    },
    getCurLangTran(trans = [], curLang) {
      curLang = curLang || this.curLang;
      let curLangTran = trans.find(tran => tran.langTag === curLang);
      if (!curLangTran) {
        this.warn('没有跟当前语言对应的数据', trans, curLang);
        curLangTran = {};
      }
      return curLangTran;
    },
    handleConfirmLang(data = {}) { // 多语言抽屉确认
      // data = {orgId, keys: [{groupCode, langKey, trans: [{langtag, transContent}]}]}

      if (data.keys && data.keys.length) {
        data.keys.forEach(newField => {
          const matchedField = this.langDrawer.fields.find(langField => langField.langKey === newField.langKey);
          if (matchedField) {
            // 更新 langDrawer.fields trans
            matchedField.trans = newField.trans;

            // 更新 templateDrawer.formData
            const curLangTran = this.getCurLangTran(newField.trans);
            const newValue = curLangTran.transContent || matchedField.initVal;
            this.templateDrawer.formData[matchedField.prop] = newValue;
          }
        });
      }
    },
    richeditorMaxValidator(rule, value, callback) {
      if (!this.editorVm) { // 没有编辑过富文本
        callback();
      } else {
        const text = this.editorVm.getText();
        callback(text.length > 500 ? true : undefined);
      }
    },
    getTemplateFields() {
      const titleMaxlength = {
        zh: 200,
        ha: 200,
        en: 200
      };
      const fields = [
        {
          enable: true,
          type: 'text',
          label: this.$t('biz_msg_title'),
          prop: 'title',
          placeholder: this.$t('biz_msg_tip_inputMsgTitle'), /* 请输入消息标题 */
          // maxlength: 50,
          maxlength: titleMaxlength,
          showWordLimit: true,
          rules: [
            { required: true, message: this.$t('biz_msg_tip_inputMsgTitle') /* 请输入消息标题 */ },
            { max: titleMaxlength[this.curLang], message: this.$t('biz_msg_nav1to50characters') }
          ]
        },
        {
          enable: this.hasPCChannel,
          type: 'richeditor',
          label: this.$t('biz_msg_lbl_email'), /* 邮件 */
          prop: 'contPc',
          // maxlength: 500,
          // showWordLimit: true,
          rules: [
            {
              required: true,
              message: this.$t('biz_msg_tip_inputEmailCon') /* 请输入邮件的消息内容 */
            }/* ,
            { max: 500, message: this.$t('biz_msg_nav1to500characters'), validator: this.richeditorMaxValidator, trigger: 'blur' } */
          ]
        },
        {
          enable: this.hasAppChannel,
          type: 'textarea',
          // label: this.$t('biz_msg_tip_corpnoDingImFeishu'), /* 企业号&钉钉&IM&飞书 */
          label: this.tunnelName, /* 企业微信&钉钉&IM&飞书 */
          prop: 'contApp',
          maxlength: 500,
          showWordLimit: true,
          rules: [
            {
              required: true,
              message: this.$t('biz_msg_tip_inputMsgConNew', [this.tunnelName]) /* 请输入企业号&钉钉&IM&飞书的消息内容 */
            }
          ]
        }
      ];

      return fields.filter(field => field.enable);
    },
    handleEditorChange(html, index) {
      const editorVm = this.$refs.control[index];
      this.editorVm = editorVm;
      const text = editorVm.getText();
      const value = text ? html : ''; // 有内容 则用html; 空标签/无内容 用空串
      this.templateDrawer.formData.contPc = value;
    },
    genLangKey(prop) {
      const type = (this.getChannelType(prop) || '').toLowerCase(); // lower channelType
      const orgId = this.orgId || localStorage.orgId || 'no-orgid';
      this.random = this.random || Math.floor(Math.random() * 1000);
      return `keys.org.msg.customtemp.${type}.${orgId}.${this.random}`;
    },
    makeTransArr(transObj = {}) {
      const trans = [];
      util.eachKey(transObj, (val, key) => {
        trans.push({langTag: key, transContent: val});
      });
      return trans;
    },
    handleChannels(channels = []) {
      /*
      channels = [
        {
          appCode: '',
          channelCont: 'pc今天开始欢迎新同学',
          channelContKey: 'keys.org.msg.customtemp.conpc.orgid.100',
          channelContTrans: { zh: 'pc今天开始欢迎新同学', ha: '繁体pc今天开始欢迎新同学', en: 'pc英文翻译内容' },
          channelType: 'PC',
          title: '新人培训课通知',
          titleKey: '',
          titleTrans: { zh: '新人培训课通知', ha: '繁体新人培训课通知', en: '英文翻译标题' }
        },
      ]
      */
      const firstChannel = channels[0];

      const createField = (channel, titleField) => {
        const tempField = titleField || channel.myField;
        const maxlength = tempField.type === 'richeditor' ? 'maxLength' : 'maxlength';
        const field = {
          ...util.pick(tempField, [['label', 'name'], 'prop', 'type']),
          initVal: channel[titleField ? 'title' : 'channelCont'],
          langKey: channel[titleField ? 'titleKey' : 'channelContKey'] || this.genLangKey(tempField.prop),
          trans: this.makeTransArr(channel[titleField ? 'titleTrans' : 'channelContTrans']),
          rule: {
            [maxlength]: tempField.maxlength,
            showWordLimit: tempField.showWordLimit
          }
        };
        if (field.prop === 'contApp') field.name = this.tunnelName || field.name;
        return field;
      };

      const formData = {};
      try {
        // 开启国际化 用 xxxTrans对象内的数据，没开国际化  用 title or channelCont 字段
        formData.title = (this.enableI18n && firstChannel.titleTrans) ? firstChannel.titleTrans[this.curLang] : firstChannel.title;
        channels.forEach(channel => {
          const prop = this.getProp(channel.channelType); // PC H5 才有对应prop， 其他渠道忽略
          const field = this.templateDrawer.fields.find(field => field.prop === prop) || {};
          if (prop) {// PC H5 才有对应prop 并附加字段myField， 其他渠道忽略
            formData[prop] = (this.enableI18n && channel.channelContTrans) ? channel.channelContTrans[this.curLang] : channel.channelCont;
            channel.myField = field; // 增加字段 myField channelType和消息表单字段关联起来
          }
        });

        // langFields 内部field顺序是固定的
        const langFieldMap = {title: null, pc: null, h5: null};
        channels.forEach((channel, i) => {
          if (!channel.myField || !channel.myField.enable) return; // 跳过没开通的渠道
          if (!langFieldMap.title) {
            const langTitleField = createField(channel, this.templateDrawer.fields[0]);
            // langFields.push(langTitleField);
            langFieldMap.title = langTitleField;
          }
          if (channel.channelType.toUpperCase() === 'PC') {
            const langField = createField(channel);
            langFieldMap.pc = langField;
          }
          if (channel.channelType.toUpperCase() === 'H5') {
            const langField = createField(channel);
            langFieldMap.h5 = langField;
          }
          // langFields.push(langField);
        });
        const langFields = [langFieldMap.title, langFieldMap.pc, langFieldMap.h5].filter(v => !!v);
        return {formData, langFields};
      } catch (error) {
        console.warning('请在运营后台维护该消息模板的多语言');
      }
    },
    ensureChannelsMatchSwitches(channels = []) {
      // 自定义后，操作消息通知方式，可能会导致 开关和channels数据不一致，
      // 进而影响到富文本校验不过，无法保存的问题
      if (!channels.length) {
        console.error('数据异常，channels为空，无法进行数据兼容');
        return [];
      }

      const { fields = [] } = this.templateDrawer;
      const otherFields = fields.slice(1);

      otherFields.forEach(field => {
        const channelType = this.getChannelType(field.prop);
        let matched = channels.find(ch => ch.channelType === channelType);
        const firstChannel = channels[0];
        if (!matched) {
          console.log('channels数据异常, 做数据兼容', this.code, this.targetId);
          const targetChannel = util.pick(firstChannel, ['title', 'titleKey', 'titleTrans']);
          targetChannel.channelType = channelType;
          targetChannel.channelCont = '';
          targetChannel.channelContKey = this.genLangKey(field.prop);
          targetChannel.channelContTrans = { en: '', ha: '', zh: '' };
          channels.push(targetChannel);
        }
      });

      return channels;
    },
    async getCustomTemplate() { // 获取自定义消息模板
      try {
        const res = await getCustomTemplateSvc(this.code, this.targetId, this.designId);
        const { fileUrl, channels, orgId, temCode, ...switches } = res;
        this.switches = switches;
        this.list = fileUrl ? [{ url: fileUrl }] : [];
        this.templateDrawer.fields = this.getTemplateFields(); // 根据开关 获取消息模板包含的字段
        this.ensureChannelsMatchSwitches(channels);
        this.channels = channels || []; // 非响应数据
        if (channels && channels.length) {
          const { formData, langFields } = this.handleChannels(channels);
          this.templateDrawer.formData = formData;
          this.langDrawer.fields = langFields;
        } else {
          this.$message.warning(this.$t('biz_msg_tip_notFoundTemplate') /* 未找到模板内容 */);
        }
        this.orgId = orgId;
        this.temCode = temCode;
      } catch (e) {
        console.error('getCustomTemplateSvc error:', e);
      }
    },
    getProp(channelType) {
      return {PC: 'contPc', H5: 'contApp'}[channelType];
    },
    getChannelType(prop) {
      return {contPc: 'PC', contApp: 'H5'}[prop];
    },
    async getSavingData() {
      const { code, targetId } = this;

      const titleField = this.langDrawer.fields.find(field => field.prop === 'title') || {};
      let titleKey = titleField.langKey;
      let title = '';
      let i18nData = {
        orgId: this.orgId,
        keys: []
      };
      if (this.enableI18n) {
        // const curLangTitle = this.getCurLangTran(titleField.trans);
        // title = curLangTitle.transContent;
        const zhLangTitle = this.getZhLangTran(titleField.trans);
        // 开启国际化时，title还是传中文语种的
        title = zhLangTitle.transContent;

      } else { // 没开启国际化，取外层字段
        title = this.templateDrawer.formData[titleField.prop];
        // 没开通多语言要进行多语言保存
        i18nData = this.filterSaveLangData(i18nData, titleField.trans, title, titleKey);
      }

      // 数据异常 没有可用渠道时，兼容titleKey and title的获取
      if (!titleKey) {
        const firstChannel = this.channels[0] || {};
        titleKey = firstChannel.titleKey;
      }

      if (!title) {
        title = this.templateDrawer.formData.title;
      }

      let channels = [];
      ['contPc', 'contApp'].forEach(prop => {
        const langField = this.langDrawer.fields.find(field => field.prop === prop);
        if (langField && langField.trans) {
          const channelContKey = langField.langKey;
          const channelType = this.getChannelType(prop);
          let channelCont = '';
          if (this.enableI18n) {
            // const curLangTran = this.getCurLangTran(langField.trans);
            // channelCont = curLangTran.transContent;
            // 开启国际化，还是传中文语种值
            const zhLangTran = this.getZhLangTran(langField.trans);
            channelCont = zhLangTran.transContent;
          } else {
            channelCont = this.templateDrawer.formData[langField.prop];
            i18nData = this.filterSaveLangData(i18nData, langField.trans, channelCont, channelContKey);
          }
          channels.push({channelType, channelContKey, channelCont});
        }
      });

      // 没有可用渠道时，兼容  返回接口的渠道数据
      if (!channels.length) {
        channels = this.channels.map(channel => util.pick(channel, ['channelType', 'channelContKey', 'channelCont']));
      }
      try {
        (!this.enableI18n) && await saveLangKey(this.module, i18nData);
        return { channels, titleKey, title, code, targetId};
      } catch (error) {
        this.$message.error('国际化多语言保存失败');
      }
      // need other channels??
    },
    filterSaveLangData(i18nData, oriData, title, key) {
      const langData = oriData.find(item=>item.langTag === (window.localStorage.getItem('yxtLang') || 'zh')) || {};
      langData.transContent = title;
      i18nData.keys.push({
        groupCode: '',
        langKey: key,
        trans: oriData
      });
      return i18nData;
    },
    async save() {
      const needLangDrawer = this.enableI18n && this.languages.length > 1;
      if (!needLangDrawer) {
        const valid = await this.$refs.form.validate().catch(val => val);
        if (!valid) {
          this.$message.warning('请检查填写项');
          return;
        }
      }

      const savingData = await this.getSavingData();
      savingData.designId = this.designId;
      try {
        const fileUrl = this.list[0] && this.list[0].url || '';
        const res = await saveCustomTemplateSvc({...savingData, fileUrl});
        this.log('res', res);
        this.$message.success(this.$t('biz_msg_save_success'));
      } catch (e) {
        this.$message.warning(this.$t('biz_msg_save_failed'));
        this.warn('saveCustomTemplateSvc err:', e);
      }

      this.close();
    },
    close() {
      this.$emit('update:visible', false);
    },
    fileAdded({ url }) {
      this.list = [{ url }];
    },
    fileRemoved() {
      this.list = [];
    },
    beforeDrawClose(done) {
      this.$refs.form.getFormChangedStatus((isChanged, changedFields) => {
        done(isChanged);
      });
    }
  }
};
</script>
