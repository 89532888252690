<template>
  <div>
    <div class="yxtbiz-ai-robot yxtbiz-ai-robot-search">
      <answer v-if="answerMsg" :mode="mode" show-portrait is-search :msg="answerMsg" @feedback="feedbackDialogVisible = true" @resend="resend"  @launch="launchHandler"></answer>
      <feedback-dialog v-if="mode === 'base'" :visible.sync="feedbackDialogVisible" @success="updateFeedback"></feedback-dialog>
    </div>
    <aiRobot v-show="show" @close="onClose" :sessionId="sessionId"></aiRobot>
  </div>
   
</template>

<script>
import FeedbackDialog from './components/feedbackDialog.vue';
import Answer from './components/answerContent.vue';
import aiRobot from './main.vue';
import {
  submitQuestion,
  getChatSetting,
  submitQuestionFeedback,
  getUid,
  getStaticBaseUrl
} from './service';

export default {
  name: 'YxtbizAiRobotSearch',
  components: {
    Answer,
    FeedbackDialog,
    aiRobot
  },
  props: {
    question: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'base'
    },
    sessionId: {
      type: String,
      default: () => {
        return getUid();
      }
    }
  },
  watch: {
    question: {
      immediate: true,
      handler(value) {
        value && this.getAnswer(value);
      }
    }
  },
  provide() {
    return {
      settings: this.settings
    };
  },
  data() {
    return {
      show: false,
      askContent: '',
      answerMsg: null,
      isWaitAnswer: false,
      feedbackDialogVisible: false,
      settings: {
        answerInteract: '', // 未知
        publicResourceEnabled: false, // 是否开通互联网
        factorOpenFlag: false, // 是否开通
        assistEnabled: false, // 是否开启
        accessEnabled: false, // 是否有权限
        assistHeadImg: `${getStaticBaseUrl()}ufd/407a24/assistent/pc/img/avatar.jpg`,
        assistWele: '',
        cheatTips: '',
        fastFeedbackList: []
      }
    };
  },
  computed: {
    AIdisabled() {
      return (this.isWaitAnswer || !this.settings.factorOpenFlag || !this.settings.assistEnabled || !this.settings.accessEnabled);
    }
  },
  methods: {
    async getChatSetting() {
      let res = await getChatSetting();
      if (res) {
        this.settings.answerInteract = !!res.answerInteract;
        this.settings.assistHeadImg = res.assistHeadImg || `${getStaticBaseUrl()}ufd/407a24/assistent/pc/img/avatar.jpg`;
        this.settings.assistWele = res.assistWele;
        this.settings.assistEnabled = !!res.assistEnabled;
        this.settings.factorOpenFlag = !!res.factorOpenFlag;
        this.settings.accessEnabled = !!res.accessEnabled;
        this.settings.publicResourceEnabled = !!res.publicResourceEnabled;
        this.settings.cheatTips = res.cheatTips;
        this.settings.fastFeedbackList = res.fastFeedbackList || [];
      }
    },
    async getAnswer(value, resendType) {
      let self = this;
      await self.getChatSetting(); // 每次提问先获取配置
      if (self.AIdisabled) return; // 不能用的情况下，不发送提问数据
      let askContent = value || self.askContent; // 取值
      if (!askContent) return; // 没内容则返回
      self.isWaitAnswer = true;
      self.answerMsg = {
        answerStatus: 0,
        chatType: 1
      };
      if (value) {
        self.askContent = value;
      }
      // TODO: 接口对接
      submitQuestion({
        askContent,
        askHistory: [], // 带上最近的三条记录
        askSessionId: self.sessionId,
        askInternet: resendType === 'askInternet' ? 1 : 0
      }, {
        onmessage: (msg) => {
          let msgData = JSON.parse(msg.data);
          if (msgData.type === 0 || msgData.type === 1) { // 进行中
            self.addAnswer({
              answerStatus: msgData.answerStatus ? msgData.answerStatus : 1,
              chatCorrectEnabled: msgData.chatCorrectEnabled || 0, // 是否启用纠错
              chatLikeFlag: msgData.chatLikeFlag || 0, // 是否评价过
              chatContentId: msgData.chatContentId, // 消息id
              chatType: 1, // 消息类型 1回答 0 提问
              contentDetail: {
                content: msgData.contentChip || '',
                linkList: msgData.linkList && msgData.linkList.length ? msgData.linkList : (msg.linkList && msg.linkList.length ? msg.linkList : [])
              }
            });
          } else if (msgData.type === 2) {
            self.isWaitAnswer = false;
            if (msgData.errorMsg) {
              self.answerMsg = {
                answerStatus: 3,
                contentDetail: {
                  content: msgData.errorMsg
                },
                chatType: 1
              };
            }
          }
        },
        onclose(msg) {
          self.isWaitAnswer = false;
          if (msg === 'timeout') { // 超时错误
            self.answerMsg = {
              answerStatus: 3,
              contentDetail: {
                content: self.$t('pc_biz_ai_network_timeout').d('网络超时，请重试！')
              },
              chatType: 1
            };
          }
        },
        onerror(err) {
          self.isWaitAnswer = false;
          console.log('onerror', err);
        }
      }).then(res => {
        console.log(res);
      });
    },
    resend(resendType) {
      this.getAnswer(null, resendType);
    },
    // 更新反馈状态
    updateFeedback(content) {
      submitQuestionFeedback({ chatContentId: this.answerMsg.chatContentId, correctContent: content})
        .then(_res => {
          // 更新子消息反馈状态
          this.answerMsg.chatCorrectEnabled = 1;
          this.feedbackDialogVisible = false;
        });
    },
    addAnswer(answer) {
      if (this.answerMsg && this.answerMsg.chatType && this.answerMsg.chatContentId && this.answerMsg.chatContentId === answer.chatContentId) { // 如果是回答id相同则表示内容跟新
        this.answerMsg = {
          ...answer,
          contentDetail: {
            content: this.answerMsg.contentDetail.content + answer.contentDetail.content, // 拼接消息体
            linkList: answer.contentDetail.linkList || this.answerMsg.contentDetail.linkList || []
          }
        };
      } else {
        this.answerMsg = answer;
      }
    },
    feedbackSuccess() {

    },
    launchHandler() {
      this.show = true;
      this.$emit('launch', this.sessionId);
    },
    onClose() {
      this.show = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.yxtbiz-ai-robot-search {
  display: flex;
}
</style>