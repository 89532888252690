import treeSlot from './components/tree-slot';
import {
  Tree,
  TreeNative
} from 'yxt-pc';

export default {
  functional: true,
  name: 'YxtbizFunsTree',
  props: {
    functions: {
      default: true,
      type: Boolean
    },
    functionsRender: {
      type: Function,
      default: () => {
        return [];
      }
    },
    placement: {
      type: String,
      default: 'bottom-start'
    },
    native: {
      type: Boolean,
      default: false
    },
    dropdownToBody: {
      type: Boolean,
      default: true
    }
  },
  render: function(createElement, context) {
    const scopedSlots = context.data.scopedSlots || {
      $stable: true,
      default: ({ data, node }) => node.label
    }; // 兼容处理，当没传 scopedSlots时，设置默认slot
    function treeSlotComponent(props) {
      return createElement(treeSlot, {
        props: {
          ...props,
          functions: context.props.functions,
          functionsRender: context.props.functionsRender,
          placement: context.props.placement,
          dropdownToBody: context.props.dropdownToBody
        },
        scopedSlots: scopedSlots
      });
    }
    let treeEleName = Tree;
    if (context.props.native) {
      treeEleName = TreeNative;
    }
    // console.log(treeEleName);
    return createElement(treeEleName, {
      ...context.data,
      class: {
        'yxtbiz-funs-tree': true
      },
      style: {
        height: 'calc(100% - 48px)'
      },
      scopedSlots: {
        default: props => createElement('div', {
          class: {
            'yxtbiz-funs-tree__node': true
          }
        }, [
          treeSlotComponent(props)
        ])
      }
    }, context.children);
  }
};
