<template>
  <div class="more_position">
    <div class="table_wrapper">
      <div class="table_wrapper_search">
        <yxtbiz-group-org-select
          class='mr12'
          @change="handleOrgChange"
          :functionCode="functionCode"
          :dataPermissionCode="dataPermissionCode"
          :selectFirst='true'
          :disabled="disabled"
          :targetOrgId='targetOrgId'
          :setCurrentOrg="true"
          :clearable="false"
          v-if="enableGroupCorp && visibleOrgSelector"
          :visibleOrgIds="visibleOrgIds">
          </yxtbiz-group-org-select>
        <select-popover ref="selectPopover" :selectOpition="selectOpition" >
          <div class="table_tree" >
            <yxtbiz-user-group-tree v-if='(enableGroupCorp && params.targetOrgId) || !enableGroupCorp'
                                    :targetOrgId='params.targetOrgId'
                                    :enableGroupCorp='enableGroupCorp'
                                    :placeholder="$t('biz_udp_usergroup_catalogname')"
                                    filterable size="large"
                                    ref="deptTree"
                                    :default-expanded-keys="['']"
                                    @nodeClick="nodeClick"
                                    :functionCode="functionCode"
                                    :dataPermissionCode="dataPermissionCode">
            </yxtbiz-user-group-tree>
          </div>
        </select-popover>
        <yxt-input
          class="keyword ml12"
          @keyup.enter.native="getPositionData(true)"
          v-model="params.name"
          :placeholder="$t('biz_udp_enter_usergroup')"
          style="width: 240px;"
          searchable
          @search='getPositionData(true)'
        >
        </yxt-input>
      </div>
      <yxt-table
        :height="tableHeight"
        ref="multipleTable"
        class="person-table"
        @select="handleSingleSeletct"
        @select-all="handleSelectAll"
        row-key="id"
        v-loading="loading"
        :data="tableData"
        style="width:100%;"
      >
        <yxt-table-column
          type="selection"
          class-name="table-selection-odd"
          width=40
          :show-overflow-tooltip='false'
        >
        </yxt-table-column>
        <yxt-table-column
          prop="name"
          show-overflow-tooltip
          :label="$t('biz_udp_usergroup')"
          min-width="180"
        >
         <template slot-scope="scope">
           <span>{{scope.row.name}}</span>
            <yxt-tag
              v-if="scope.row.userGroupType === 1"
              class="ml8"
              effect="dark"
              size="mini">
              {{$t('pc_biz_ls_group').d('集团')}}
            </yxt-tag>
          </template>
        </yxt-table-column>
        <yxt-table-column
          show-overflow-tooltip
          prop="remark"
          :label="$t('pc_biz_udp_lbl_description')"
          min-width="180"
        >
        </yxt-table-column>
        <!-- <yxt-table-column
          prop="userCount"
          :label="$t('pc_biz_udp_lbl_userCount')"
          align='right'
        >
        </yxt-table-column> -->
        <template slot="empty">
          <div style="line-height:normal">
            <yxt-empty />
          </div>
        </template>
      </yxt-table>
      <div class="yxtbiz-page__content">
        <div class="yxtbiz-page__content-right">
          <yxt-button
            type="text"
            class="lh32"
            :class="loadingAll?'color-primary-4 disabled':''"
            @click="loadAll"
            :disabled="!tableData.length"
            >{{$t('biz_udp_all_filter_results')}}</yxt-button>
            <div class="yxtbiz-loading__all" v-loading="loadingAll"></div>
        </div>
        <yxt-pagination
          small
          class="pages"
          :page-size="params.limit"
          :page-sizes="[5, 10, 20, 30, 50, 100]"
          @size-change="handleSizeChange"
          :current-page.sync="currentPage"
          layout="total, prev, pager, next"
          @current-change="handleCurrentChange"
          :total="total"
          simple-total
        >
        </yxt-pagination>
      </div>
    </div>
    <!-- <div class="list">
      <checked-list @clear="clear" @close="closeTag" :list="tempCheckedPositons" />
    </!-->
  </div>
</template>

<script>
import { getUserGroupList } from '../service';
import echoMixin from '../mixin/echo';
import resizeTable from '../../../user-selector/mixins/resizeTable';
import SelectPopover from './SelectPopover';
import authMixin from '../mixin/auth';
import { Table, TableColumn, Pagination, Empty } from 'yxt-pc';
import { i18n } from 'yxt-biz-pc/packages/common-util/changeLangs';
import { enmuFunctions } from '../../../function-points';
import { getFunStatus } from '../../../common-util/factorUtils';

export default {
  name: 'UserGroup',
  components: {
    SelectPopover,
    YxtTable: Table,
    YxtTableColumn: TableColumn,
    YxtPagination: Pagination,
    YxtEmpty: Empty
  },
  mixins: [echoMixin, resizeTable, authMixin],
  computed: {
    showRelation() {
      // 是否显示动态用户组
      return getFunStatus(enmuFunctions.UDP_DYNAMIC_USERGROUP).enabled;
    }
  },
  data() {
    return {
      loadingAll: false,
      tableHeight: 0,
      refTable: 'multipleTable',
      currentPage: 1,
      total: 0,
      loading: true,
      params: {
        name: '',
        catalogId: '',
        limit: 20,
        offset: 0,
        scope: 0
      },
      tableData: [],
      selectOpition: {
        placeholder: i18n.t('biz_udp_usergroup_select'),
        checkWord: ''
      }
    };
  },
  mounted() {
    if (!this.enableGroupCorp) this.getPositionData();
  },
  watch: {
    targetOrgId: {
      handler() {
        if (this.enableGroupCorp && this.targetOrgId) {
          this.params.targetOrgId = this.targetOrgId;
          this.getPositionData();
        }
      },
      immediate: true
    }
  },
  methods: {
    handleOrgChange(data) {
      this.params.targetOrgId = data.orgId;
      this.targetOrgName = data.orgName;
      this.params.name = '';
      this.params.catalogId = '';
      this.getPositionData();
    },
    loadAll() {
      this.loadingAll = true;
      const params = Object.assign({}, this.params, {
        limit: this.total,
        offset: 0
      });
      const type = this.showRelation ? -1 : 0; // -1 查询普通与动态用户组，0 只查询普通用户组
      getUserGroupList(params, type).then(res => {
        if (res) {
          let data = res.datas.map(item => {
            item['_type'] = 2;
            item.orgId = this.params.targetOrgId;
            item.orgName = this.targetOrgName;
            return item;
          });
          this.$refs['multipleTable'].clearSelection();
          this.$refs['multipleTable'].toggleAllSelection();
          this.selectTotal(data);
        }
      }).catch(e => {
        console.error(e);
      }).then(() => {
        this.loadingAll = false;
      });
    },
    nodeClick(a, b, c) {
      this.params.catalogId = a.id;
      this.selectOpition.checkWord = a.id ? a.name : '';
      this.$refs['selectPopover'].cancel();
      this.getPositionData(true);
    },
    getPositionData(reset = false) {
      this.loading = true;
      if (reset) {
        this.params.offset = 0;
        this.currentPage = 1;
      }
      this.params.dataPermissionCode = this.dataPermissionCode;
      this.params.navCode = this.functionCode;
      const type = this.showRelation ? -1 : 0; // -1 查询普通与动态用户组，0 只查询普通用户组
      getUserGroupList(this.params, type).then(res => {
        if (res) {
          this.tableData = res.datas.map(item => {
            item['_type'] = 2;
            item.orgId = this.params.targetOrgId;
            item.orgName = this.targetOrgName;
            return item;
          });
          this.total = res.paging.count;
          this.update();
        }
      }).catch((e) => {
        console.error(e);
      }).then(() => {
        this.loading = false;
      });
    },
    handleCurrentChange(val) {
      this.params.offset = (val - 1) * this.params.limit;
      this.getPositionData();
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.params.limit = val;
      this.getPositionData();
    }
  }
};
</script>
