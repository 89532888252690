<!-- 我的收藏 -->
<template>
  <article class="yxtbiz-ai-robot-my-comments">
    <EmptyData v-if="dataList.length === 0" />
    <template v-else>
      <header
        :class="[
          'color-gray-7',
          size === 'small' ? 'font-size-14 lh24' : 'font-size-16 lh28'
        ]"
      >
        {{
          $t('yxtbiz_ai_lbl_followingcomments' /* 为您找到以下{0}评论 */, [
            filterTitle
          ])
        }}
      </header>
      <main>
        <ul>
          <li
            v-for="item in dataList"
            :key="item.id"
            class="yxtbiz-ai-robot-my-comments__item"
          >
            <comment :params="params" :size="size" :item="item">
              <span
                slot="title"
                slot-scope="{ content }"
                v-html="filterXss(content.content)"
              ></span>

              <span
                slot="catalogName"
                slot-scope="{ catalogName }"
                v-html="filterXss(catalogName.catalogName)"
              >
              </span>
            </comment>
          </li>
        </ul>
        <ViewMoreButton v-if="count > 3" @click="handleViewMoreClick" />
      </main>
    </template>
  </article>
</template>
  
  <script>
import comment from './components/comment.vue';
import ViewMoreButton from '../common/view-more-button';
import EmptyData from '../common/empty-data.vue';
import utils from 'yxt-biz-pc/packages/common-util/utils.js';
import propsMixin from '../common/props-mixin.js';
import qs from 'qs';

export default {
  name: 'MyComment',
  components: {
    ViewMoreButton,
    EmptyData,
    comment
  },
  mixins: [propsMixin],
  computed: {
    count() {
      const paging = (this.datas && this.datas.data.paging) || {};
      return paging.count;
    },
    filterTitle() {
      const aMap = {
        1: this.$t('yxtbiz_ai_lbl_course' /* 课程 */),
        2: this.$t('yxtbiz_ai_lbl_kng' /* 知识 */),
        3: this.$t('yxtbiz_ai_lbl_bbs' /* 社区 */)
      };
      return aMap[this.params.commentType];
    },
    dataList() {
      return (this.datas && this.datas.data && this.datas.data.datas) || [];
    }
  },
  created() {
    this.params.commentType += '';
  },
  methods: {
    getDateTimeFormatted: utils.getDateTimeFormatted,
    /**
     * 过滤xss攻击代码
     * @param {String} text 文本
     */
    filterXss(text) {
      if (!text) {
        return '';
      }

      // 去掉script标签
      text = text.replace(/<script[\s\S]*?(<\/script>|\/>)/gi, '');
      // 去除on事件
      const onTagReg =
        /<[^>]*?( |\/)(on[\W\w]*?=([""'])?([^'""]+)([""'])?)[\S\s]*?>/gi;
      const onReg = /( |\/)on[\W\w]*?=([""'])?([^'""]+)([""'])?/gi;
      let onMatches = onTagReg.exec(text);
      while (onMatches) {
        text = text.replace(onMatches[0], onMatches[0].replace(onReg, ''));
        onMatches = onTagReg.exec(text);
      }
      // 去除非链接href
      const hrefReg = /<a[^>]*(href=([""']?([^'""]+)([""'])?))[\S\s]*?>/gi;
      let hrefMatches = hrefReg.exec(text);
      while (hrefMatches) {
        hrefMatches[3].indexOf('http') !== 0 &&
          (text = text.replace(
            hrefMatches[0],
            hrefMatches[0].replace(hrefMatches[1], '')
          ));
        hrefMatches = hrefReg.exec(text);
      }
      // 去除eval(...)和expression(...)
      text = text.replace(/(eval|expression)\(.*?\)/gi, '');

      return text;
    },
    handleViewMoreClick() {
      const { commentType, searchKey } = this.params;
      const url = `${window.location.origin}/kng/#/mycomments?${qs.stringify({
        keyword: searchKey,
        active: commentType
      })}`;
      window.open(url, '_blank');
    }
  }
};
</script>
  
  <style lang="scss" scoped>
.yxtbiz-ai-robot-my-comments {
  &__cover {
    width: 112px;
    height: 64px;
    border-radius: 4px;
    flex-shrink: 0;
  }

  > header {
    padding-left: 12px;
    margin-bottom: 12px;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 12px;
    border-radius: 8px;

    &:hover {
      background: #f4f5f6;
    }
  }

  &__item-tag {
    vertical-align: text-top;
  }
}
</style>