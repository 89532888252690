import {searchApi, miscApi} from 'yxt-biz-pc/packages/api';

export const getSearchHistories = () => {
  return searchApi.get('histories/users/top');
};

export const deleteSearchHistories = () => {
  return searchApi.delete('histories/mine');
};

export const getHistoriesTop = (searchKey) => {
  return searchApi.post('histories/search', {searchKey: searchKey});
};

// 获取机构参
export const getOrgpParameterOpen = (code, orgId) => {
  return miscApi.get(`orgparsetting/code/open?code=${code}&orgId=${orgId}`);
};
