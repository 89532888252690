(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("YXTPC"), require("Vue"), require("axios"), require("Vuex"), require("VueI18n"));
	else if(typeof define === 'function' && define.amd)
		define(["YXTPC", "Vue", "axios", "Vuex", "VueI18n"], factory);
	else if(typeof exports === 'object')
		exports["YXTBIZ"] = factory(require("YXTPC"), require("Vue"), require("axios"), require("Vuex"), require("VueI18n"));
	else
		root["YXTBIZ"] = factory(root["YXTPC"], root["Vue"], root["axios"], root["Vuex"], root["VueI18n"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__11__, __WEBPACK_EXTERNAL_MODULE__22__, __WEBPACK_EXTERNAL_MODULE__24__, __WEBPACK_EXTERNAL_MODULE__82__) {
return 