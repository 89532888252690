<template>
  <SelectorMain
  ref="selectorMain"
  :multiple="multiple"
  :tabs="tabs"
  :functionCode="functionCode"
  :dataPermissionCode="dataPermissionCode"
  :selected="selected"
  :limit="limit"
  :disabledOrgIds="disabledOrgIds"
  :visibleOrgIds="visibleOrgIds"
  :targetOrgId="targetOrgId"
  :enableGroupCorp='enableGroupCorp'
  :visibleOrgSelector="visibleOrgSelector"
  :isOrgSelectAlone="isOrgSelectAlone"
  :isShowDeptment="isShowDeptment"
  :showTab="showTab"
  @onProcess="onProcess"
  @change="selectChange"
  >
    <slot
      slot='customTab'
      name="customTab"
      ref="customTab"
    />
  </SelectorMain>
</template>
<script>
import SelectorMain from './selector-main';
import SelectorPropsMixin from '../mixins/selector-props';
import { getGroupUserList, depMessageQuery } from './service';
import cloneDeep from 'lodash/cloneDeep';
export default {
  name: 'YxtbizRangeSelector',
  components: {
    SelectorMain
  },
  mixins: [SelectorPropsMixin],
  created() {
    this.getOrgDepartConfig();
  },
  computed: {
    isUserGroupPerson() {
      const userGroup = this.tabs.find(tab => {
        if (typeof tab === 'string') return tab === 'userGroup';
        if (typeof tab === 'object') return tab.type === 'userGroup';
      });
      if (userGroup && userGroup.isPerson) {
        return true;
      }
      return false;
    },
    tab0Type() {
      if (typeof this.tabs[0] === 'string') {
        return this.tabs[0];
      } else {
        return this.tabs[0].type;
      }
    }
  },
  methods: {
    // 导入流程阶段状态变化回调
    onProcess(processing) {
      this.processing = processing;
    },
    setActiveTab(tabType) {
      if (tabType) this.$refs['selectorMain'].activeTabName = tabType;
    },
    async validate() {
      const selectData = cloneDeep(this.$refs['selectorMain'].selectData);
      if (this.isUserGroupPerson) {
        const { userGroup, persons } = selectData;
        const personIds = persons.map(item => item.id);
        const personSet = new Set(personIds);
        if (userGroup.length > 0) {
          const users = await this.transformPerson(userGroup.map(item => item.id));
          users.forEach(item => {
            if (personSet.has(item.id)) {
              // 代表选的人已存在
            } else {
              persons.push(item);
            }
          });
        }
        selectData.persons = this.transformPersonSelectAlone(persons);
      }
      let count = 0;
      for (const type in selectData) {
        if (this.isUserGroupPerson && type === 'userGroup') continue;
        if (selectData[type] && selectData[type].length) {
          count += selectData[type].length;
        }
      }
      if (count > this.limit && this.limit > 0) {
        this.showLimitError();
        return false;
      }
      return true;
    },
    setSelectData(selectData) {
      // 设置选人数据
      this.selected = selectData;
    },
    async getSelectData() {
      // 获取选中的数据，以对象的形式进行分组区分
      const selectData = cloneDeep(this.$refs['selectorMain'].selectData);
      const { userGroup, persons } = selectData;
      if (this.isUserGroupPerson) {
        const personIds = persons.map(item => item.id);
        const personSet = new Set(personIds);
        if (userGroup.length > 0) {
          const users = await this.transformPerson(userGroup.map(item => item.id));
          users.forEach(item => {
            if (personSet.has(item.id)) {
              // 代表选的人已存在
            } else {
              persons.push(item);
            }
          });
        }
      }
      selectData.persons = this.transformPersonSelectAlone(persons);
      return selectData;
    },
    transformPersonSelectAlone(persons) {
      // 把在集团版下，独立选择的人员的id进行还原
      if (this.isOrgSelectAlone && this.enableGroupCorp) {
        return persons.map(item => {
          const [id] = item.id.split('&&');
          return {
            ...item,
            id
          };
        });
      };
      return persons;
    },
    async transformPerson(ids) {
      // 在用户组选人模式下，需要把选择的用户组取查找对应的人员
      const params = {
        groupIds: ids,
        type: 2,
        navCode: this.functionCode,
        dataPermissionCode: this.dataPermissionCode
      };
      if (this.enableGroupCorp) {
        params.targetOrgId = this.targetOrgId;
      }
      try {
        const { datas } = await getGroupUserList(params);
        return datas;
      } catch (error) {
        console.log(error);
      }
    },
    async getSelectDataArray() {
      // 获取选中的数据，返回值是将所有数据组装成了一个数组，兼容业务端之前的逻辑
      const data = await this.getSelectData();
      let newData = [];
      for (const type in data) {
        if (this.isUserGroupPerson && type === 'userGroup') {
          // 因为用户组已经换成人了，这里直接去除掉
        } else {
          const elementData = data[type].map(item => {
            const obj = { ...item, nodeType: type };
            if (type === 'department' && obj.children) obj.children = null; // 部门的数据如果存在children直接置空，提高性能
            return obj;
          });
          newData = newData.concat(elementData);
        }
      }
      return newData;
    },
    selectChange(data) {
      const transData = cloneDeep(data);
      transData.persons = this.transformPersonSelectAlone(transData.persons);
      if (this.model === 'array') {
        let newData = [];
        for (const type in transData) {
          const elementData = data[type].map(item => {
            const obj = { ...item, nodeType: type };
            if (type === 'department' && obj.children) obj.children = null; // 部门的数据如果存在children直接置空，提高性能
            return obj;
          });
          newData = newData.concat(elementData);
        }
        this.$emit('change', newData);
      } else {
        this.$emit('change', this.singleTabChange(transData));
      }
    },
    singleTabChange(data) {
      // 只有一个tab页时并且是单选情况下，直接返回当前选中的对象
      if (!this.multiple && this.tabs.length === 1) {
        const [node] = data[this.tab0Type];
        return node;
      }
      return data;
    },
    calcGroupLimit(selectedObjs) {
      if (!this.enableGroupCorp || !this.groupLimit) return;
      let recordCount = {};
      let bol = false;
      Object.values(selectedObjs).forEach(item => {
        recordCount[item.orgId] = recordCount[item.orgId]
          ? recordCount[item.orgId] + 1
          : 1;
        if (recordCount[item.orgId] > this.groupLimit) {
          bol = true;
        }
      });
      if (bol) {
        this.$alert(this.$t('pc_biz_select_msg_select_user', {0: this.groupLimit}).d(`每个平台最多选择${this.groupLimit}个用户`), this.$t('pc_biz_ls_tips').d('提示'), {
          confirmButtonText: this.$t('biz_tcm_checkbtn'),
          type: 'error'
        });
        return bol;
      }
      return bol;
    },
    showLimitError() {
      this.$alert(
        this.$t('pc_biz_select_msg_limit').d('已选数量超过限制，请检查'),
        this.$t('biz_tcm_limitnum', { x: this.limit }),
        {
          confirmButtonText: this.$t('biz_tcm_checkbtn'),
          type: 'error'
        }
      );
    },
    // 机构开启隐藏部门全路径时，学员端导航调用选人组件隐藏部门选择框
    getOrgDepartConfig() {
      if (!this.functionCode) return;
      const nav = this.getNavByCode(this.functionCode);
      // const nav = this.getNavByCode('knglib_index');
      if (this.isShowDeptment && nav && nav.type === 1) {
        // 学员端导航且机构开启隐藏部门全路径，则选人组件隐藏部门选择框
        depMessageQuery().then(res => {
          this.isShowDeptment = !res.pathState;
        });
      }
    },
    getNavByCode(navCode) {
      try {
        let per = null;
        let navsAll = this.$store.state.navManageStore.navList;
        if (navsAll && navsAll.length > 0) {
          for (let index = 0; index < navsAll.length; index++) {
            const element = navsAll[index];
            if (element.code === navCode) {
              per = element;
              break;
            }
          }
        }
        return per;
      } catch (error) {
        // error
      }
      return null;
    }
  },
  model: {
    event: 'change',
    prop: 'selected'
  },
  props: {
    selected: [Object, Array],
    model: {
      type: String,
      default: 'object'
    }
  },
  data() {
    return {
      // 子组件状态，是否在处理内部数据（用于标记是否在导入用户流程中）
      processing: false,
      // 用于父组件调用，标记当前选人组件在抽屉中是否需要关闭提示，true：需要提示，false：不需要提示
      showTip: false,
      isShowDeptment: true // 这个参数只给快速选人与高级选人使用
    };
  }
};
</script>
<style lang="scss">
  .yxtbiz-range-selector {
    height: 100%;
    &-custom-title {
      height: 56px;
      line-height: 56px;
      font-size: 22px;
      border-bottom: 1px solid #e9e9e9;
      margin: -24px;
      padding-left: 24px;
      margin-bottom: 24px;
    }
    &-main {
      height: calc(100% - 56px);
      position: relative;
      &-content {
        position: absolute;
        padding-right: 280px;
        top: 0;
        left: 0;
        right: -24px;
        height: 100%;
        > div {
          height: 100%;
        }
      }
      &-checkedlist {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        border-left: 1px solid #e9e9e9;
        width: 280px;
      }
    }
  }
  .yxt-drawer__body .yxtbiz-range-selector-main-checkedlist,
  .yxt-dialog__body .yxtbiz-range-selector-main-checkedlist {
    top: -24px;
    right: -24px;
    bottom: -24px;
  }
  .yxtbiz-range-selector .yxt-tabs__nav-scroll {
    margin-right: 106px;
  }
  .yxtbiz-range-selector .yxt-tabs__nav-more {
    right: 92px;
  }
  .yxtbiz-range-selector .yxt-tabs__header.yxt-tabs__drawer-top .yxt-tabs__nav-wrap {
    padding: 0 24px 0 0;
  }
</style>
