var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "yxtbiz-ai-robot",
      class: { "is-fullscreen": _vm.bFullscreen, "is-max": _vm.max },
    },
    [
      _c(
        "draggable-container",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: !_vm.scriptLoaded,
              expression: "!scriptLoaded",
            },
          ],
          staticClass: "yxtbiz-ai-robot--container",
          attrs: {
            "yxt-loading-background": "rgba(255, 255, 255, 0)",
            bFullscreen: _vm.bFullscreen,
          },
          on: { resize: () => _vm.$refs.msgDialog.scrollToEnd() },
        },
        [
          _vm.scriptLoaded
            ? [
                _c("broadside", {
                  attrs: {
                    visible: _vm.chatListVisible,
                    waitAnswer: _vm.waitAnswer,
                    bFullscreen: _vm.bFullscreen,
                    updateMessage: _vm.updateMessage,
                    title: _vm.title,
                    "small-client": !_vm.bFullscreen,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.chatListVisible = $event
                    },
                    selectChat: _vm.selectChat,
                  },
                }),
                _c("msg-dialog", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.chatListVisible,
                      expression: "!chatListVisible",
                    },
                  ],
                  ref: "msgDialog",
                  attrs: {
                    waitAnswer: _vm.waitAnswer,
                    clientType: _vm.getClientType,
                    title: _vm.title,
                    max: _vm.max,
                    bFullscreen: _vm.bFullscreen,
                  },
                  on: {
                    getChatSetting: _vm.getChatSetting,
                    "update:waitAnswer": function ($event) {
                      _vm.waitAnswer = $event
                    },
                    "update:wait-answer": function ($event) {
                      _vm.waitAnswer = $event
                    },
                    "update-message": _vm.handleUpdateMessage,
                    fullscreen: (v) => (_vm.bFullscreen = v),
                    feedback: function ($event) {
                      _vm.feedbackDialogVisible = true
                    },
                    close: _vm.closeAI,
                    showChatList: _vm.showChatList,
                  },
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c("feedback-dialog", {
        attrs: { visible: _vm.feedbackDialogVisible },
        on: {
          "update:visible": function ($event) {
            _vm.feedbackDialogVisible = $event
          },
          success: _vm.feedbackSuccess,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }