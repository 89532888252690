var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isOpenI18n
    ? _c(
        "div",
        { staticClass: "yxtbiz-nav-lang", class: { "is-stu": _vm.isStu } },
        [
          _vm.isStu
            ? _c(
                "yxtf-dropdown",
                _vm._b({}, "yxtf-dropdown", _vm.$attrs, false),
                [
                  !_vm.$slots.default
                    ? _c(
                        "div",
                        {
                          staticClass: "color-gray-9 yxtbiz-nav-lang__content",
                        },
                        [
                          _c("img", {
                            staticClass: "yxtbiz-nav-lang__img",
                            attrs: { src: _vm.imgSrc },
                          }),
                          _c("span", { staticClass: "yxtbiz-nav-lang__name" }, [
                            _vm._v(_vm._s(_vm.activeName)),
                          ]),
                        ]
                      )
                    : _vm._t("default"),
                  _c(
                    "yxtf-dropdown-menu",
                    {
                      attrs: {
                        slot: "dropdown",
                        scrollable: false,
                        "append-to-body": _vm.appendToBody,
                        popperOptions: {
                          modifiers: [
                            "shift",
                            "offset",
                            "keepTogether",
                            "arrow",
                            "flip",
                            "applyStyle",
                          ],
                          gpuAcceleration: false,
                        },
                      },
                      slot: "dropdown",
                    },
                    _vm._l(_vm.langList, function (lang) {
                      return _c(
                        "yxtf-dropdown-item",
                        {
                          key: lang.langTag,
                          class: [
                            "yxtbiz-nav-lang__default-lang",
                            {
                              "yxtbiz-nav-lang__active-langs":
                                _vm.activeLang == lang.langTag,
                            },
                          ],
                          attrs: { command: lang.langTag },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.handleLang(lang)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n        " + _vm._s(lang.langName) + "\n        "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                2
              )
            : _c(
                "yxt-dropdown",
                _vm._b({}, "yxt-dropdown", _vm.$attrs, false),
                [
                  !_vm.$slots.default
                    ? _c(
                        "div",
                        {
                          staticClass: "color-gray-9 yxtbiz-nav-lang__content",
                        },
                        [
                          _c("img", {
                            staticClass: "yxtbiz-nav-lang__img",
                            attrs: { src: _vm.imgSrc },
                          }),
                          _c("span", { staticClass: "yxtbiz-nav-lang__name" }, [
                            _vm._v(_vm._s(_vm.activeName)),
                          ]),
                        ]
                      )
                    : _vm._t("default"),
                  _c(
                    "yxt-dropdown-menu",
                    {
                      attrs: {
                        slot: "dropdown",
                        scrollable: false,
                        "append-to-body": _vm.appendToBody,
                        popperOptions: {
                          modifiers: [
                            "shift",
                            "offset",
                            "keepTogether",
                            "arrow",
                            "flip",
                            "applyStyle",
                          ],
                          gpuAcceleration: false,
                        },
                      },
                      slot: "dropdown",
                    },
                    _vm._l(_vm.langList, function (lang) {
                      return _c(
                        "yxt-dropdown-item",
                        {
                          key: lang.langTag,
                          class: [
                            "yxtbiz-nav-lang__default-lang",
                            {
                              "yxtbiz-nav-lang__active-langs":
                                _vm.activeLang == lang.langTag,
                            },
                          ],
                          attrs: { command: lang.langTag },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.handleLang(lang)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n        " + _vm._s(lang.langName) + "\n        "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                2
              ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }