<template>
  <div class="standard-size-14 yxtbiz-ai-robot__coure">
    <EmptyData v-if="dataList.length === 0" />
    <template v-else>
      <div :class="size === 'small'? 'font-size-14 lh22': 'font-size-16 lh24'" class="color-gray-7 yxtbiz-ai-robot__coure-title">
        <yxtbiz-language-slot inline lang-key="pc_biz_search_title">
          <span style="color: #436BFF; padding: 0 6px;" slot="0">{{ totalCount }}</span>
          <template slot="1" v-if="techtodo.length && stutodo.length">{{ $t('pc_biz_ai_todo') }}</template>
          <template slot="1" v-else-if="techtodo.length">{{ $t('pc_biz_ai_study_todo') }}</template>
          <template slot="1" v-else-if="stutodo.length">{{ $t('pc_biz_ai_teach_todo') }}</template>
          <template slot="1" v-else>{{ $t('pc_biz_ai_todo') }}</template>
        </yxtbiz-language-slot>
      </div>
      <div class="tag-box" v-if="techtodo.length && stutodo.length">
        <span :class="sceneTarget === 1 ? 'active' : ''"  @click="changeSceneTarget(1)">{{$t('pc_biz_ai_study_todo')}}</span>
        <span :class="sceneTarget === 0 ? 'active' : ''"  @click="changeSceneTarget(0)">{{$t('pc_biz_ai_teach_todo')}}</span>
      </div>
      <div class="yxtbiz-ai-robot__coure-cell" v-for="item in dataList" :key="item.id" @click="toDetail(item)">
        <yxtf-portrait class="yxtbiz-ai-robot__course-cover" size="48px" v-if="item.catIcon" :imgUrl="item.catIcon"></yxtf-portrait>
        <div class="ml12 yxtbiz-ai-robot__course-contain">
          <div :class="size === 'small'? 'font-size-14 lh22': 'font-size-16 lh24'" class="ellipsis-2 mb4" v-html="item.title"></div>
          <div v-if="size !== 'small'" class="mt4">
            <div class="standard-size-12 color-gray-7 mt4 ellipsis-1 lh20">
              {{  $t('pc_biz_cer_type').d('类型') + ': ' + item.tag }}
              <yxtf-divider direction="vertical" />
              {{ $t('pc_biz_cer_lbl_time').d('时间') + ': ' + timeStr(item) }}
            </div>
          </div>
          <div v-else class="mt4">
            <div class="standard-size-12 color-gray-7 mt4 ellipsis-1 lh20">
              {{ $t('pc_biz_cer_type').d('类型') + ': ' + item.tag }}
            </div>
            <div class="standard-size-12 color-gray-7 mt4 ellipsis-1 lh20">
              {{ $t('pc_biz_cer_lbl_time').d('时间') + ': ' + timeStr(item) }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="needMore" ref="toMoreButton" class="yxtbiz-ai-robot__coure-more color-gray-8" @click="toMore">
        {{ $t('pc_biz_lbl_common_viewmore') }}
        <yxt-svg width="16px" height="16px" icon-class="arrow-down" />
      </div>
    </template>
    
  </div>
</template>
<script>
import EmptyData from '../common/empty-data.vue';
import { apiBaseUrl } from 'yxt-biz-pc/packages/api';
export default {
  name: 'AllTodo',
  components: {
    EmptyData
  },
  props: {
    datas: {
      type: Object,
      default: ()=>{
        return {datas: []};
      }
    },
    params: {
      type: Object,
      default: ()=>{}
    },
    size: {
      type: String,
      default: 'small',
      validator: val => ['small', 'large'].includes(val)
    }
  },
  data() {
    console.log(this.datas);
    const techtodo = [];
    const stutodo = [];
    let sceneTarget = 1;
    function filtertype(item) {
      if (item.sceneTarget === '1') {
        techtodo.push(item);
      } else if (item.sceneTarget === '0') {
        stutodo.push(item);
      }
    }
    if (this.datas.datas) {
      this.datas.datas.map(filtertype);
    }
    if (this.datas.studyList) {
      this.datas.studyList.datas.map(filtertype);
    }
    if (this.datas.teachList) {
      this.datas.teachList.datas.map(filtertype);
    }
    if (!techtodo.length) {
      sceneTarget = 0;
    }
    return {
      sceneTarget: sceneTarget,
      techtodo: techtodo,
      stutodo: stutodo,
      techPagesOffset: 1,
      stuPagesOffset: 1
    };
  },
  computed: {
    pages() {
      const pages = {
        searchKey: this.params.searchKey,
        offset: this.sceneTarget === 1 ? this.techPagesOffset : this.stuPagesOffset,
        endTime: '',
        searchType: 0,
        startTime: ''
      };
      return pages;
    },
    dataList() {
      try {
        if (this.sceneTarget === 1 && this.techtodo.length) {
          return this.techtodo;
        } else if (this.sceneTarget === 0 && this.stutodo.length) {
          return this.stutodo;
        }
      } catch (e) { }
      return [];
    },
    needMore() {
      let res = false;
      if (this.sceneTarget === 1) {
        if (this.datas.paging && this.techtodo.length < this.datas.paging.count) {
          res = true;
        } else if (this.datas.teachList && this.techtodo.length < this.datas.teachList.paging.count) {
          res = true;
        }
      } else {
        if (this.datas.paging && this.stutodo.length < this.datas.paging.count) {
          res = true;
        } else if (this.datas.studyList && this.stutodo.length < this.datas.studyList.paging.count) {
          res = true;
        }
      }
      return res;
    },
    // 总数量
    totalCount() {
      let total = 0;
      if (this.datas.studyList) {
        total += this.datas.studyList.paging.count;
      }
      if (this.datas.teachList) {
        total += this.datas.teachList.paging.count;
      }
      if (this.datas.paging) {
        total += this.datas.paging.count;
      }
      return total;
    }
  },
  methods: {
    changeSceneTarget(val) {
      this.sceneTarget = val;
    },
    toDetail(item) {
      window.open(item.targetUrl);
    },
    toMore() {
      if (this.sceneTarget === 1 && this.techtodo.length) {
        this.techPagesOffset += 3;
      } else if (this.sceneTarget === 0 && this.stutodo.length) {
        this.stuPagesOffset += 3;
      }
      apiBaseUrl.post(`/audit/todos/list/${this.sceneTarget === 1 ? 'teach' : 'study'}/ai`, {
        ...this.pages
      }).then(res => {
        const data = res.datas || res.datas || [];
        if (this.sceneTarget === 1) {
          this.techtodo = this.techtodo.concat(data);
        } else {
          this.stutodo = this.stutodo.concat(data);
        }
        this.$nextTick(()=>{
          this.$refs.toMoreButton.scrollIntoView(false);
        });
      }).catch((e) => {
        console.log(e);
        if (this.sceneTarget === 1 && this.techtodo.length) {
          this.techPagesOffset -= 3;
        } else if (this.sceneTarget === 0 && this.stutodo.length) {
          this.stuPagesOffset -= 3;
        }
      });
    },
    timeStr(item) {
      const start = item.startTime;
      const end = item.endTime;
      if (start && end) {
        return `${this.formatTimeStr(start)} ~ ${this.formatTimeStr(end)}`;
      } else if (start && !end) {
        return this.$t('h5_core_lbl_fromthen', {str: this.formatTimeStr(start)}).d('{str} 开始');
      } else if (!start && end) {
        return this.$t('h5_core_lbl_deadlinethen', {str: this.formatTimeStr(end)}).d('{str} 截止');
      } else {
        return this.$t('h5_core_lbl_notimelimit').d('不限时间');
      }
    },
    formatTimeStr(str) {
      const time = str.split(' ');
      return `${time[0]} ${time[1].slice(0, 5)}`;
    }
  }
};
</script>
<style scoped lang="scss">
.yxtbiz-ai-robot__coure{
  width: 100%;
}
.yxtbiz-ai-robot__coure-title{
  padding: 0px 12px 12px;
}
.tag-box {
  padding-left: 10px;
  span{
    height: 32px;
    display: inline-block;
    padding: 5px 12px;
    margin: 0 12px 16px 0;
    background: #F5F5F5;
    border-radius: 8px;
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #262626;
    line-height: 22px;
    box-sizing: border-box;
    cursor: pointer;
  }
  .active{
    background: #E6EEFF;
    color: #436BFF;
  }
}
.yxtbiz-ai-robot__course-contain{
  flex-grow: 1;
  overflow: hidden;
}
.yxtbiz-ai-robot__coure-cell {
  display: flex;
  overflow: hidden;
  align-items: center;
  border-radius: 8px;
  padding: 12px;
  cursor: pointer;
  &:hover {
    background-color: #f4f5f6;
  }
}
.yxt-divider {
  background-color: #d8d8d8;
}
.yxt-progress__text {
  font-size: 12px !important;
  line-height: 20px;

  &>span {
    font-size: 12px !important;
  }
}
.coure-flex-center {
  display: flex;
  align-items: center;
}
.yxtbiz-ai-robot__course-cover {
  flex-shrink: 0;
  flex-grow: 0;
}
.yxtbiz-ai-robot__coure-more {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: #f4f5f6;
  }
}
</style>
