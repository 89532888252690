<template>
  <div
      :class="['yxtbiz-search', size ? `yxtbiz-search--${size}` : 'yxtbiz-search--medium', softFocus ? 'yxt-biz-search--focus' : '']"
      v-clickoutside="handleClose"
      @mouseenter="handleMouseenter"
      @mouseleave="handleMouseleave"
  >
    <yxtf-input
        ref="reference"
        :size="size"
        v-model.enter='searchKey'
        @search='enterSearch'
        :searchable="searchable"
        :placeholder='placeholder'
        :radusType="radusType"
        :styleType="styleType"
        :pendType="pendType"
        @focus="handleFocus"
        @blur="handleBlur"
        @input="handleInput"
        :maxlength="max"
    >
      <template slot="append">
        <slot></slot>
      </template>
      <template slot="suffix">
        <i
           class="yxt-input__icon yxt-icon-error yxt-input__clear"
           @mousedown.prevent
           @click="clear"
           v-if="showClear"
        ></i>
        <slot name="suffix"></slot>
      </template>
    </yxtf-input>
    <transition v-if="isShowSearchFind" name="yxt-zoom-in-top" @after-leave="doDestroy">
      <search-selections
        v-show="isShowList"
        :searchKey="searchKey"
        ref="popper"
        :options.sync="options"
        appendToBody
        :hotOnly="hotOnly"
        :offsetTop="offsetTop"
        @selected="handleSelected"
        :isTransition="isTransition"
      ></search-selections>
    </transition>
  </div>
</template>

<script>
import {getSearchHistories, getHistoriesTop, getOrgpParameterOpen} from './service';
import searchSelections from './selections';
import Emitter from 'yxt-biz-pc/src/mixins/emitter';
import Focus from 'yxt-biz-pc/src/mixins/focus';
import {
  addResizeListener,
  removeResizeListener
} from 'yxt-biz-pc/src/utils/resize-event';
import { Clickoutside } from 'yxt-pc';
import { FrontInput } from 'yxt-pc';

export default {
  name: 'YxtbizSearch',
  components: {
    searchSelections,
    YxtfInput: FrontInput
  },
  mixins: [Emitter, Focus('reference')],
  directives: { Clickoutside },
  props: {
    size: {
      type: String
    },
    placeholder: {
      type: String,
      default: '请输入关键字搜索'
    },
    radusType: {
      type: String
    },
    styleType: {
      type: String
    },
    searchable: {
      type: Boolean,
      default: true
    },
    pendType: {
      type: String
    },
    offsetTop: {
      type: Number,
      default: 0
    },
    popperClass: {
      type: String
    },
    highlight: {
      type: Boolean,
      default: true
    },
    limit: {
      type: Number,
      default: 10
    },
    min: {
      type: Number
    },
    max: {
      type: Number
    },
    hotOnly: {
      type: Boolean,
      default: false // 只显示热门搜索，此时会隐藏历史搜索及分组标题
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchKey: '',
      prevSearchKey: '',
      orgHistories: [],
      isShowList: false,
      isShowSearchFind: true,
      options: {
        orgHistories: [],
        userHistories: []
      },
      softFocus: false,
      inputWidth: 0,
      hoverShowClear: false,
      isTransition: false // 是否处于历史搜索和搜索的转换期间
    };
  },
  created() {
    this.getOrgpParameterOpenForShowSearchFind();
  },
  computed: {
    showClear() {
      if (!this.clearable) {
        return;
      }
      return this.searchKey && this.searchKey.length && (this.hoverShowClear || this.softFocus);
    }
  },
  watch: {
    softFocus() {
      if (!this.isShowList && !this.softFocus) {
        this.$emit('leave');
      }
    },
    isShowList() {
      if (!this.isShowList && !this.softFocus) {
        this.$emit('leave');
      }
    },
    searchKey() {
      this.$emit('input', this.searchKey);
    }
  },
  mounted() {
    addResizeListener(this.$el, this.handleResize);
    const reference = this.$refs.reference;
    this.$nextTick(() => {
      if (reference && reference.$el) {
        this.inputWidth = reference.$el.getBoundingClientRect().width;
      }
    });
  },
  methods: {
    async getOrgpParameterOpenForShowSearchFind() {
      try {
        const res = await getOrgpParameterOpen('ShowSearchFind', window.localStorage.orgId);
        if (res.value) {
          this.isShowSearchFind = !!(Number(res.value));
        }
      } catch (error) {
        this.$message.error(error.message);
      }
    },
    search() {
      if (this.min && this.searchKey.length < this.min) {
        this.isShowList = false;
        return;
      }
      if (this.max && this.searchKey.length > this.max) {
        this.isShowList = false;
        return;
      }
      if (this.searchKey === '' || this.searchKey === undefined || this.searchKey === null) {
        getSearchHistories().then((res) => {
          this.options.userHistories = res.datas;
          if (this.options.userHistories && this.options.userHistories.length) {
            this.isShowList = true;
          } else {
            this.isShowList = false;
          }
          if (this.softFocus) {
            this.broadcast('SearchSelections', 'updatePopper');
          }
          this.isTransition = false;
          this.prevSearchKey = this.searchKey;
        }).catch(() => {
          this.isShowList = false;
          this.isTransition = false;
          this.prevSearchKey = this.searchKey;
        });
        getHistoriesTop(this.searchKey).then((res) => {
          this.options.orgHistories = res.datas;
          if (this.options.orgHistories && this.options.orgHistories.length) {
            this.isShowList = true;
          } else {
            this.isShowList = false;
          }
          if (this.softFocus) {
            this.broadcast('SearchSelections', 'updatePopper');
          }
          this.isTransition = false;
          this.prevSearchKey = this.searchKey;
        }).catch(() => {
          this.isShowList = false;
          this.isTransition = false;
          this.prevSearchKey = this.searchKey;
        });
      } else {
        getHistoriesTop(this.searchKey).then((res) => {
          this.options.orgHistories = res.datas;
          if (this.options.orgHistories && this.options.orgHistories.length) {
            this.isShowList = true;
          } else {
            this.isShowList = false;
          }
          if (this.softFocus) {
            this.broadcast('SearchSelections', 'updatePopper');
          }
          this.isTransition = false;
          this.prevSearchKey = this.searchKey;
        }).catch(() => {
          this.isShowList = false;
          this.isTransition = false;
          this.prevSearchKey = this.searchKey;
        });
      }
    },
    handleFocus() {
      if (!this.softFocus) {
        this.softFocus = true;
        this.search();
      } else {
        this.softFocus = false;
      }
    },
    handleBlur() {
      this.softFocus = false;
      this.$emit('blur');
    },
    handleClose() {
      this.isShowList = false;
    },
    doDestroy() {
      this.$refs.popper && this.$refs.popper.doDestroy();
    },
    handleResize() {
      this.inputWidth = this.$refs.reference.$el.getBoundingClientRect().width;
    },
    handleSelected(item) {
      this.isShowList = false;
      this.searchKey = item;
      this.$emit('selected', item);
    },
    handleInput() {
      this.softSearch();
    },
    debounce(fn, wait) {
      if (this.fun !== null) {
        clearTimeout(this.fun);
      }
      this.fun = setTimeout(fn, wait);
    },
    softSearch() {
      if (this.prevSearchKey === '' && this.searchKey !== '') {
        this.isTransition = true;
      }
      if (this.prevSearchKey !== '' && this.searchKey === '') {
        this.isTransition = true;
      }
      this.debounce(this.search, 300);
    },
    getSearchKey() {
      return this.searchKey;
    },
    setSearchKey(text) {
      this.searchKey = text;
    },
    enterSearch() {
      this.isShowList = false;
      this.$emit('selected', this.searchKey);
    },
    clear() {
      this.searchKey = '';
      this.search();
    },
    handleMouseenter() {
      this.hoverShowClear = true;
    },
    handleMouseleave() {
      this.hoverShowClear = false;
    }
  },
  beforeDestroy() {
    if (this.$el && this.handleResize) {
      removeResizeListener(this.$el, this.handleResize);
    }
  }
};
</script>
