var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "common-answer width-percent-100" }, [
    _c(
      "div",
      {
        staticClass: "is-content is-answer-content",
        class: { "is-expanded": _vm.isExpanded },
      },
      [
        _c("msgToolBtn", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isSearch && !_vm.msg.isFunction,
              expression: "!isSearch && !msg.isFunction",
            },
          ],
          attrs: { like: _vm.like, msg: _vm.msg, isanswer: true },
          on: { toolClick: _vm.toolClick },
        }),
        _c("div", {
          ref: "content",
          staticClass: "answer-content origin-content",
          domProps: { innerHTML: _vm._s(_vm.content) },
        }),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.needShowMoreCover && _vm.isSearch,
                expression: "needShowMoreCover && isSearch",
              },
            ],
            staticClass: "is-more-cover",
          },
          [
            _c("div"),
            _c(
              "div",
              {
                staticClass: "text-center hand",
                on: {
                  click: function ($event) {
                    _vm.isExpanded = !_vm.isExpanded
                  },
                },
              },
              [
                _c(
                  "yxtf-link",
                  {
                    staticClass: "font-size-12",
                    attrs: { type: "primary", underline: false },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.isExpanded
                          ? _vm.$t("pc_biz_ai_retract").d("收起")
                          : _vm.$t("pc_biz_ai_expand_all").d("展开全部")
                      )
                    ),
                  ]
                ),
                _c("i", {
                  class: _vm.isExpanded
                    ? "yxt-icon-arrow-up"
                    : "yxt-icon-arrow-down",
                }),
              ],
              1
            ),
          ]
        ),
        _vm.msg.contentDetail &&
        _vm.msg.contentDetail.linkList &&
        _vm.msg.contentDetail.linkList.length
          ? _c("sourceContent", {
              attrs: {
                msg: _vm.msg,
                contentDetail: _vm.msg.contentDetail,
                isSearch: _vm.isSearch,
                fullscreen: _vm.fullscreen,
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _vm.like && !_vm.msg.isFunction
      ? _c(
          "div",
          { class: { "is-like": true, "last-is-like": _vm.lastMsg } },
          [
            _vm.like === 1
              ? _c("yxt-svg", {
                  attrs: {
                    "remote-url": _vm.h5Svg,
                    width: 20,
                    height: 20,
                    "icon-class": "icon-satisfied",
                  },
                })
              : _vm._e(),
            _vm.like === 2
              ? _c("yxt-svg", {
                  attrs: {
                    "remote-url": _vm.h5Svg,
                    width: 20,
                    height: 20,
                    "icon-class": "icon-dissatisfied",
                  },
                })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _vm.msg.answerStatus === 1 && !_vm.msg.isFunction && !_vm.isWaitAnswer
      ? _c("div", { staticClass: "answer-footer" }, [
          _c("span", [
            _vm.lastMsg
              ? _c(
                  "span",
                  { staticClass: "re-answer", on: { click: _vm.toolClick } },
                  [
                    _c("yxtf-svg", {
                      staticClass: "icon-copy",
                      attrs: {
                        width: "20px",
                        height: "20px",
                        "icon-class": "turn-right",
                      },
                    }),
                    _c("span", [
                      _vm._v(
                        " " + _vm._s(_vm.$t("pc_biz_ai_reanswer").d("重新回答"))
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          !_vm.like
            ? _c("span", { staticClass: "like-btn" }, [
                _c(
                  "span",
                  {
                    staticClass: "like-btn-item",
                    on: {
                      click: function ($event) {
                        return _vm.handleLike(1)
                      },
                    },
                  },
                  [
                    _c("yxtf-svg", {
                      attrs: {
                        "remote-url": _vm.svgUrl,
                        width: "20px",
                        height: "20px",
                        "icon-class": "good",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "span",
                  {
                    staticClass: "like-btn-item",
                    on: {
                      click: function ($event) {
                        return _vm.handleLike(2)
                      },
                    },
                  },
                  [
                    _c("yxtf-svg", {
                      staticClass: "transformY-1",
                      attrs: {
                        "remote-url": _vm.svgUrl,
                        width: "20px",
                        height: "20px",
                        "icon-class": "not-good",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }