var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "yxt-select",
    {
      attrs: {
        placeholder: _vm.placeholder,
        "popper-class": "user-selector-select-checkbox",
        clearable: _vm.clearable,
        "collapse-tags": true,
        multiple: "",
      },
      on: { clear: _vm.clear, "remove-tag": _vm.removeTag },
      model: {
        value: _vm.selected,
        callback: function ($$v) {
          _vm.selected = $$v
        },
        expression: "selected",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "fixed-header" }, slot: "fixed-header" },
        [
          _c(
            "yxt-checkbox",
            {
              on: { change: _vm.changeAll },
              model: {
                value: _vm.isAll,
                callback: function ($$v) {
                  _vm.isAll = $$v
                },
                expression: "isAll",
              },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("pc_biz_udp_lbl_selectAll")) +
                  "\n    "
              ),
            ]
          ),
        ],
        1
      ),
      _vm._l(_vm.options, function (item, index) {
        return _c(
          "yxt-option",
          {
            key: index,
            staticStyle: {
              height: "auto",
              "max-height": "200px",
              overflow: "auto",
            },
            attrs: { value: item, label: item },
          },
          [
            _c(
              "yxt-checkbox",
              {
                staticStyle: {
                  "z-index": "999",
                  display: "inline-block",
                  width: "100%",
                  "padding-left": "20px",
                  "box-sizing": "border-box",
                },
                attrs: {
                  "change-text-color": true,
                  value: _vm.testChecked(item),
                },
                on: {
                  change: function ($event) {
                    return _vm.changeGrade($event, item)
                  },
                },
              },
              [_vm._v("\n      " + _vm._s(item) + "\n    ")]
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }