var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.currentNav && _vm.currentNav.subMenuInfoList.length > 0
    ? _c(
        "div",
        {
          staticClass: "yxtbiz-nav-left",
          class: {
            "yxtbiz-nav-left--collapse": _vm.collapse,
            "yxtbiz-nav-left--hover": _vm.hovered,
          },
        },
        [
          _c(
            "div",
            {
              class: [
                "yxtbiz-nav-left__inner",
                !_vm.isOperated ? "yxtbiz-nav-left__inner--notransition" : "",
              ],
            },
            [
              _vm.appNavLeft
                ? _c(
                    "div",
                    {
                      class: [
                        "yxtbiz-nav-left__appname ellipsis",
                        _vm.collapse ? "text-center" : "",
                      ],
                    },
                    [
                      !_vm.collapse
                        ? _c(
                            "span",
                            [
                              _c(
                                "yxt-tooltip",
                                {
                                  attrs: {
                                    content: _vm.currentNav.name,
                                    placement: "right",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.currentNav.name)),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.currentNav.functionCode === "core_user_center"
                        ? _c("yxt-svg", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.collapse,
                                expression: "collapse",
                              },
                            ],
                            attrs: {
                              "remote-url": _vm.iconPath,
                              "icon-class": _vm.currentNav.navIcon,
                              query: _vm.iconUpdateParam,
                              width: "32px",
                              height: "32px",
                            },
                          })
                        : _c("img", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.collapse,
                                expression: "collapse",
                              },
                            ],
                            attrs: { src: _vm.currentNav.icon },
                          }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "yxt-collapse",
                {
                  model: {
                    value: _vm.menusShow,
                    callback: function ($$v) {
                      _vm.menusShow = $$v
                    },
                    expression: "menusShow",
                  },
                },
                [
                  _vm._l(_vm.currentNav.subMenuInfoList, function (menu) {
                    return [
                      menu.showed
                        ? _c(
                            "yxt-collapse-item",
                            {
                              key: menu.id,
                              attrs: {
                                name: menu.id,
                                index: menu.code,
                                "show-item-icon": false,
                              },
                            },
                            [
                              _c("template", { slot: "title" }, [
                                _c(
                                  "div",
                                  { staticClass: "yxtbiz-nav-left__menu-wrap" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        ref: menu.code,
                                        refInFor: true,
                                        class: [
                                          "yxtbiz-nav-left__menu yxtbiz-nav-left__menu--main hover-primary-6-i",
                                          _vm.isMenuSelected(
                                            menu,
                                            _vm.pageCodeStartIndex,
                                            _vm.collapse,
                                            false,
                                            true
                                          )
                                            ? "yxtbiz-nav-left__menu--actived color-primary-6-i"
                                            : "",
                                          _vm.isMenuSelected(
                                            menu,
                                            _vm.pageCodeStartIndex,
                                            false,
                                            false,
                                            true
                                          )
                                            ? "bg-primary-1-i"
                                            : "",
                                          _vm.collapse
                                            ? "hover-primary-6-i"
                                            : "",
                                          _vm.menuHover.id === menu.id
                                            ? "yxtbiz-nav-left__menu--hover"
                                            : "",
                                        ],
                                        on: {
                                          click: function ($event) {
                                            return _vm.toggleShow(menu)
                                          },
                                          mouseenter: function ($event) {
                                            return _vm.setMenuHover(menu)
                                          },
                                          mouseleave: function ($event) {
                                            return _vm.setMenuHover({})
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            class: [
                                              "yxtbiz-nav-left__menu-icon",
                                            ],
                                          },
                                          [
                                            _vm.getIconPath(menu)
                                              ? _c("yxt-svg", {
                                                  class: [
                                                    _vm.isMenuSelected(
                                                      menu,
                                                      _vm.pageCodeStartIndex,
                                                      true
                                                    )
                                                      ? "color-primary-6-i"
                                                      : "",
                                                  ],
                                                  attrs: {
                                                    "remote-url":
                                                      _vm.getIconPath(menu)
                                                        .remoteUrl,
                                                    "icon-class":
                                                      _vm.getIconPath(menu)
                                                        .name,
                                                    query: _vm.iconUpdateParam,
                                                    width: "16px",
                                                    height: "16px",
                                                  },
                                                })
                                              : _vm.iconPath && menu.code
                                              ? _c("yxt-svg", {
                                                  class: [
                                                    _vm.isMenuSelected(
                                                      menu,
                                                      _vm.pageCodeStartIndex,
                                                      true
                                                    )
                                                      ? "color-primary-6-i"
                                                      : "",
                                                  ],
                                                  attrs: {
                                                    "remote-url": _vm.iconPath,
                                                    "icon-class": menu.code,
                                                    query: _vm.iconUpdateParam,
                                                    width: "16px",
                                                    height: "16px",
                                                  },
                                                })
                                              : _c("i", {
                                                  staticClass:
                                                    "yxt-icon-picture-outline",
                                                  class: [
                                                    _vm.isMenuSelected(
                                                      menu,
                                                      _vm.pageCodeStartIndex,
                                                      true
                                                    )
                                                      ? "color-primary-6-i"
                                                      : "",
                                                  ],
                                                  staticStyle: {
                                                    width: "16px",
                                                    height: "16px",
                                                  },
                                                }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "yxtbiz-nav-left__menu-name",
                                            class: {
                                              "color-primary-6-i":
                                                _vm.isMenuSelected(
                                                  menu,
                                                  _vm.pageCodeStartIndex,
                                                  true
                                                ),
                                              "yxtbiz-nav-left__menu-name--full":
                                                !_vm.isDisplaySubMenus(menu),
                                            },
                                          },
                                          [
                                            _c(
                                              "yxt-tooltip",
                                              {
                                                staticClass:
                                                  "yxtbiz-nav-left__menu-tip ellipsis",
                                                attrs: {
                                                  content: menu.name,
                                                  placement: "right",
                                                  "open-filter": true,
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  [
                                                    _c(
                                                      "a-link",
                                                      {
                                                        attrs: {
                                                          url: menu.pageUrl,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(menu.name)
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm.isDisplaySubMenus(menu)
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "yxtbiz-nav-left__arrow",
                                                class: [
                                                  _vm.menusShow.indexOf(
                                                    menu.id
                                                  ) >= 0
                                                    ? "yxtbiz-nav-left__arrow--active"
                                                    : "",
                                                ],
                                              },
                                              [
                                                _c("div", {
                                                  staticClass:
                                                    "yxtbiz-nav-left__arrow-l hover-border-primary-6-i",
                                                  class: [
                                                    _vm.isMenuSelected(
                                                      menu,
                                                      _vm.pageCodeStartIndex,
                                                      true
                                                    )
                                                      ? "border-primary-6-i"
                                                      : "",
                                                  ],
                                                }),
                                                _c("div", {
                                                  staticClass:
                                                    "yxtbiz-nav-left__arrow-r hover-border-primary-6-i",
                                                  class: [
                                                    _vm.isMenuSelected(
                                                      menu,
                                                      _vm.pageCodeStartIndex,
                                                      true
                                                    )
                                                      ? "border-primary-6-i"
                                                      : "",
                                                  ],
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.isMenuSelected(
                                          menu,
                                          _vm.pageCodeStartIndex,
                                          false,
                                          false,
                                          true
                                        )
                                          ? _c("div", {
                                              staticClass:
                                                "yxtbiz-nav-left__menu-leftbar bg-primary-6",
                                            })
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                              _vm.isDisplaySubMenus(menu)
                                ? _c(
                                    "div",
                                    _vm._l(
                                      menu.subMenuInfoList,
                                      function (submenu) {
                                        return _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  submenu.showed &&
                                                  !_vm.collapse,
                                                expression:
                                                  "submenu.showed && !collapse",
                                              },
                                            ],
                                            key: submenu.id,
                                            ref: submenu.code,
                                            refInFor: true,
                                            staticClass:
                                              "yxtbiz-nav-left__menu yxtbiz-nav-left__menu--sub",
                                            class: [
                                              "hover-primary-6-i hand",
                                              _vm.isMenuSelected(submenu)
                                                ? "yxtbiz-nav-left__menu--actived color-primary-6-i bg-primary-1-i"
                                                : "",
                                            ],
                                            attrs: { index: submenu.code },
                                            on: {
                                              click: function ($event) {
                                                return _vm.toPage(submenu)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "yxt-tooltip",
                                              {
                                                staticClass:
                                                  "ellipsis yxtbiz-nav-left__menu-name color-gray-9 hover-primary-6-i",
                                                class: [
                                                  _vm.isMenuSelected(submenu)
                                                    ? "color-primary-6-i yxt-weight-5"
                                                    : "",
                                                ],
                                                attrs: {
                                                  content: _vm._f("Globalize")(
                                                    submenu,
                                                    "name"
                                                  ),
                                                  placement: "right",
                                                  "open-filter": true,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "a-link",
                                                      {
                                                        attrs: {
                                                          url: submenu.pageUrl,
                                                          "page-node": submenu,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f("Globalize")(
                                                              submenu,
                                                              "name"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _vm.isMenuSelected(submenu)
                                              ? _c("div", {
                                                  staticClass:
                                                    "yxtbiz-nav-left__menu-leftbar bg-primary-6",
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                            ],
                            2
                          )
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm._t("leftbottom", null, { collapse: _vm.collapse }),
          _c(
            "div",
            {
              staticClass:
                "yxtbiz-nav-left__aside-bottom hover-primary-6-i color-gray-10 hand",
              on: {
                click: _vm.setBottomCollapse,
                mouseleave: _vm.leaveBottom,
                mouseenter: _vm.enterBottom,
              },
            },
            [
              _c("yxt-svg", {
                style: { transform: _vm.collapse ? "rotate(180deg)" : "" },
                attrs: {
                  "icon-class": "collapse",
                  "remote-url": _vm.remoteUrl,
                  width: "18px",
                  height: "18px",
                },
              }),
            ],
            1
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }