<template>
  <div
    class="yxtbiz-ai-robot__dialog"
    :class="{ 'fullscreen-dialog': bFullscreen }"
  >
 
    <!-- 对话框头部 -->
    <div class="yxtbiz-ai-robot__dialog--header">
      <div class="yxtbiz-ai-robot__dialog--header-left">
        <yxtf-portrait
          v-if="!bFullscreen && settings.assistHeadImg"
          class="mr12 hand"
          size="small"
          username="AI"
          :imgUrl="settings.assistHeadImg"
          @click="showChatList"
        />
        <span v-if="!bFullscreen">{{ title }}</span>
      </div>
      <div class="yxtbiz-ai-robot__dialog--header-right">
        <!-- TODO 本期不支持 -->
        <!-- <header-icon
          class="mr16"
          :size="iconSize"
          icon-name="question-cirlce-o"
          :pop-text="$t('pc_biz_ai_help_center').d('帮助中心')"
        /> -->
        <header-icon
          class="mr16"
          :size="iconSize"
          @click="share"
          icon-name="share"
          :pop-text="$t('pc_biz_ai_share').d('分享')"
          remote
        />
        <Share v-if="showShare" />

        <template v-if="!max">
          <header-icon
            class="mr16"
            remote
            :size="iconSize"
            @click="changeFullSize"
            :icon-name="bFullscreen ? 'ai-window' : 'ai-screen'"
            :pop-text="
              bFullscreen
                ? $t('pc_biz_ai_small_window').d('小窗口')
                : $t('pc_biz_ai_full_screen').d('全屏')
            "
          />
          <!-- 已有的关闭icon尺寸比设计图的小2px -->
          <header-icon
            class="mr16"
            remote
            :size="iconSize"
            @click="handleClose"
            icon-name="ai-closed"
            :pop-text="$t('pc_biz_ai_close').d('关闭')"
          />
        </template>
      </div>
    </div>
    <!-- 对话框主体 -->
    <div
      class="yxtbiz-ai-robot__dialog--body"
      :style="aiBodyStyle"
    >
      <yxtf-scrollbar
        class="yxtbiz-ai-robot-scrollbar"
        ref="scrollbar"
        :fit-height="true"
      >
        <infinite-scroll
          v-if="!noMoreHistory"
          @more="getHistory"
        ></infinite-scroll>
        <div :class="{'ml24 mr24':bFullscreen}">
          <ul
            ref="dialogBody"
            class="is-msg-list"
          >
            <li
              v-for="(msg, index) in msgList"
              :key="index"
              class="width-percent-100"
              :class="{ 'yxtbiz-ai-robot__dialog--msg-question': msg.chatType === 0 }"
            >
              <Message
                :msg="msg"
                :lastMsg="index === msgList.length - 1"
                :isWaitAnswer="isWaitAnswer"
                :fullscreen="bFullscreen"
                @feedback="showFeedbackDialog(index)"
                @promot="onPromot"
                @resend="val => resend(msg, val, index)"
                @scrollToEnd="scrollToEnd"
                @submit="handleSubmitMessage"
                @promotEmpty="onPromotEmpty"
              ></Message>
            </li>
          </ul>
        </div>
      </yxtf-scrollbar>
    </div>
    <!-- 底部输入框 -->
    <div
      class="yxtbiz-ai-robot__dialog--footer"
      ref="aiFooter"
    >
    <Input-magic
      v-if="settings.hasShortcutInstruction === 1"
      @change="handleChangeMagic"
      :size="bFullscreen ? 'large': 'small'"
      ref="inputMagicRef"
    />
      <div :class="{
          'textarea-content': true,
          'textarea-focus': focus,
          'more-lines': inputHeight > 52 || (magicTag && magicTag.appName)
        }">
        <span
          class="stop-answer"
          :class="{ 'stop-instruction-answer': settings.hasShortcutInstruction === 1 }"
          v-if="isWaitAnswer"
          @click="stopAIAnswer()"
        >
          <span class="block"></span>
          <span> {{ $t('pc_biz_lbl_stop_reply').d('停止回答') }}</span>
        </span>
        <div class="yxtbiz-ai-robot__wrap">
          <div
            v-if="magicTag"
            class="yxtbiz-ai-robot__wrap--tag"
          >
            <yxt-tag
              closable
              @close="magicTag = null"
            >{{ magicTag.appName || '' }}</yxt-tag>
          </div>
          <textarea
            :class="{ 'no-question': !isSendShow,'font-size-14': !bFullscreen}"
            class="no-shortcut-textarea"
            ref="textarea"
            v-model="question"
            maxlength="2000"
            :placeholder="getInputHolder"
            @keydown="keydown"
            @input="handleInputQuestion"
            @focus="focus = true"
            @blur="focus = false"
          ></textarea>
          <yxt-tooltip
            v-if="isSendShow"
            :content="$t('pc_biz_ai_send_wait').d('暂时不能发送，请稍后再试！')"
            placement="top"
            :disabled="!isWaitAnswer"
          >
            <div
              :class="{ 'ai-robot-btn-send': true, 'disbale-send': isWaitAnswer }"
              @click="isWaitAnswer ? null : sendMsg()"
            >
              <img :src="sendSvg" />
            </div>
          </yxt-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  submitQuestion,
  questionHistory,
  submitQuestionFeedback,
  clearHistory,
  getAskReqId,
  stopQuestion,
  todayIsVisit,
  getStaticBaseUrl,
  postInspection
} from '../service';
import Message from './message.vue';
import HeaderIcon from './headerIcon.vue';
import InfiniteScroll from './infiniteScroll.vue';
import Share from './share.vue';
import InputMagic from './inputMagic.vue';
import { timestampToTime, CommonAskIdHandler } from '../utils';

export default {
  name: 'MsgDialog',
  components: {
    InfiniteScroll,
    Message,
    HeaderIcon,
    Share,
    InputMagic
  },
  props: {
    max: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default() {
        return this.$t('pc_biz_ai_title').d('企业AI助手');
      }
    },
    bFullscreen: {
      type: Boolean,
      default: false
    },
    clientType: {
      type: Number,
      default: 0 // 0-学员端，1-管理端
    },
    waitAnswer: Boolean
  },
  inject: ['settings'],
  data() {
    return {
      sendSvg: `${getStaticBaseUrl()}ufd/407a24/assistent/pc/svg/send.svg`,
      stopAnswerList: {}, // 待停止的回答队列ID集合
      isfirstLoad: true,
      maxCreateTime: '',
      excludeIds: [],
      resendMsg: null,
      showShare: false,
      isWaitAnswer: false,
      inputHeight: 52, // 输入框高度
      question: '',
      askQuestion: '',
      msgList: [],
      askHistory: [],
      screenId: '',
      focus: false,
      currentFeedbackIndex: 0,
      magicTag: null, // 显示的魔法标签
      noMoreHistory: true, // 无更多历史消息
      commonAskIdHandler: null
    };
  },
  computed: {
    getInputHolder() {
      if (this.magicTag && this.magicTag.guide) {
        return this.magicTag.guide;
      }

      return this.$t(this.settings.hasShortcutInstruction === 1
        ? 'pc_biz_lbl_ai_holder_order' : this.settings.hasShortcutInstruction === 0
          ? 'pc_biz_ai_input_problem' : ''
      );
    },
    aiBodyStyle() {
      if (this.magicTag) {
        return { height: 'calc( 100% - ' + (this.inputHeight + 92 + 38 + 29) + 'px )' };
      } else {
        return { height: 'calc( 100% - ' + (this.inputHeight + 92 + (this.settings.hasShortcutInstruction === 1 ? 32 : 0)) + 'px )' };
      }
    },
    iconSize() {
      return this.bFullscreen ? 24 : 20;
    },
    closeIconSize() {
      const size = this.bFullscreen ? 26 : 22;
      return `${size}px`;
    },
    isSendShow() {
      const q = this.question.trim();
      return q && q !== '/';
    }
  },
  watch: {
    question: function(v) {
      this.resizeTextarea(v);
    },
    isWaitAnswer: function(val) {
      this.$emit('update:waitAnswer', val);
    }
  },
  created() {
    this.$root.$on('ASK_INTERNET', this.askInternet);
  },
  mounted() {
    document.body.addEventListener('click', this.handleShareVisible);
  },
  methods: {
    changeUpdateAI(screenId, isNew) {
      if (!this.commonAskIdHandler) this.commonAskIdHandler = new CommonAskIdHandler();

      if (!isNew) {
        Object.assign(this.$data, {...this.$options.data.call(this), commonAskIdHandler: this.commonAskIdHandler});
        this.screenId = screenId;
        if (!this.screenId) {
          // 新窗口 默认提示语
          this.initDialogData();
        } else {
          this.noMoreHistory = false;
        }
      } else {
        this.screenId = screenId;
      }
    },
    handleClose() {
      this.commonAskIdHandler.destoryReleaseAsset();
      this.commonAskIdHandler = null;
      this.$emit('close');
    },
    initDialogData() {
      todayIsVisit().then(IsVisit => {
        this.msgList = [{
          chatType: 1,
          answerStatus: IsVisit ? 3 : 4
        }];
      });
    },
    // 小窗口点击头像
    showChatList() {
      this.$emit('showChatList');
    },
    handleSubmitMessage(data) {
      // 补全集合
      this.question = data.question || '';

      this.sendMsg(null, {
        askType: 2,
        bizAppId: data.bizAppId,
        askId: data.askId,
        bizMissParam: data.bizMissParam,
        bizOkParam: data.bizOkParam,
        needIntent: 0
      });
    },
    handleChangeMagic(v) {
      if (v.showType === 'treasure') {
        this.question = v.guide || '';
      } else {
        if (this.question === '/' || this.magicTag && this.magicTag.showType === 'treasure') {
          this.question = '';
        }
      }
      this.magicTag = v;
    },
    handleShareVisible() {
      this.showShare = false;
    },
    handleInputQuestion(e) {
      if (this.settings.hasShortcutInstruction !== 1) return;

      const value = e.target.value.trim();
      if (value && value.length === 1 && value[0] === '/') {
        // 匹配指令
        this.$refs.inputMagicRef && this.$refs.inputMagicRef.show();
      }
    },
    async stopAIAnswer() {
      this.submitRequest.abort && this.submitRequest.abort();
      // 获取队列中最新条的key
      let lastKey = Object.keys(this.stopAnswerList)[
        Object.keys(this.stopAnswerList).length - 1
      ];
      console.log(lastKey);
      this.stopAnswerList[lastKey] = true;
      this.isWaitAnswer = false;
      let { answerStatus, contentDetail } = this.msgList[
        this.msgList.length - 1
      ]; // 数组最后一项
      await stopQuestion({
        askReqId: lastKey,
        stopText: (contentDetail && contentDetail.content) || '已停止回答',
        stopOtherText: (contentDetail && contentDetail.otherSourceAnswer) || ''
      });
      if (answerStatus === 0) {
        let stopMessage = {
          chatType: 1,
          answerStatus: 3,
          contentDetail: {
            content: this.$t('pc_biz_ai_stoped_answer').d('已停止回答'), // 拼接消息体
            linkList: []
          }
        };
        // 如果在回答中
        this.msgList.pop();
        this.msgList.push(stopMessage);
        this.resendMsg = null;
      }
      this.scrollToEnd();
    },
    // 自适应输入框调整
    resizeTextarea(v) {
      const textarea = this.$refs.textarea;
      this.inputHeight = 52;
      textarea.style.height = 52 + 'px';
      if (v) {
        this.$nextTick(()=>{
          if (textarea.scrollHeight > 52) {
            this.inputHeight = Math.min(textarea.scrollHeight, 165);
            textarea.style.height = this.inputHeight + 'px';
          }
        });
      }
    },
    // 获取历史消息
    getHistory() {
      const dialogBody = this.$refs.dialogBody;
      const offsetH = dialogBody.offsetHeight;
      this.msgList.unshift({
        // 舔加加载历史等待效果
        answerStatus: 0,
        chatType: 1
      });
      // TODO: 接口对接
      questionHistory({
        excludeIds: this.excludeIds,
        askSessionId: this.commonAskIdHandler.getAskId(),
        sessionId: this.commonAskIdHandler.getAskId(),
        maxCreateTime: this.maxCreateTime,
        chatSessionId: this.screenId,
        clientType: this.clientType
      }).then(async res => {
        this.msgList.shift(); // 清除首位置添加的等待效果
        if (res && res.list && res.list.length) {
          // 格式化消息记录消息体
          let chatLog = res.list;
          chatLog.reverse(); // 聊天记录顺序反转一次
          this.msgList.unshift(...chatLog.map(chat => ({ ...chat, isHistory: true }))); // 拼接历史消息记录
          this.$nextTick(() => {
            // 滑动效果
            this.scrollTo(dialogBody.offsetHeight - offsetH);
          });
          if (res.excludeIds && res.excludeIds.length) {
            // 如果还有更多历史消息
            this.excludeIds = res.excludeIds;
            this.maxCreateTime = res.maxCreateTime;
          }
          if (this.msgList.length < 10) {
            // 消息总条数少于10条
            this.noMoreHistory = true;
          }
        } else {
          this.noMoreHistory = true;
          if (this.msgList.length > 5) {
            this.msgList.unshift({
              chatType: 2,
              content: this.$t('pc_biz_ai_no_more').d('暂无更多')
            });
          }
        }
        // 判断是否展示提示语
        let IsVisit = await todayIsVisit();
        if (!IsVisit) {
          this.msgList.push({
            // 创建默认问候语，问候语不存于聊天记录中
            chatType: 1,
            answerStatus: 4
          });
        }
        if (this.isfirstLoad) {
          this.getQueryAskHistory();
        }
        this.isfirstLoad = false;
      });
    },
    onPromotEmpty() {
      const weekIndex = this.msgList.findIndex(item => item.answerStatus === 4 && item.chatType === 1);
      weekIndex >= 0 && (this.msgList.splice(weekIndex, 1));
    },
    keydown(e) {
      if (e.shiftKey || e.altKey) return;
      if (e.keyCode === 13) {
        e.preventDefault();
        if (e.ctrlKey) {
          console.log('keydown', e);
          this.question += '\n';
        } else {
          this.sendMsg();
        }
      }
    },
    onPromot(val, bizAppId = '', needIntent) {
      // 初始化的试着问一问
      if (this.isWaitAnswer) {
        // 等待中
        this.$message.warning(this.$t('pc_biz_ai_send_wait').d('暂时不能发送，请稍后再试！'));
        return;
      }
      this.question = val;
      this.sendMsg(null, {
        askType: bizAppId ? 1 : 0,
        bizAppId,
        needIntent
      });
    },
    // 重新发送
    resend(msg, resendType, index) {
      for (let i = index; i >= 0; i--) {
        // 往前找提问消息 找到第一个终止
        if (this.msgList[i] && this.msgList[i].chatType === 0) {
          this.resendMsg = {
            contentDetail: {
              content:
                this.msgList[i].contentDetail &&
                this.msgList[i].contentDetail.content
            },
            chatType: 0
          };
          if (msg.askInternet) {
            this.sendMsg('askInternet');
          } else {
            this.sendMsg(resendType, {
              askType: msg.bizAppId ? 1 : 0,
              bizAppId: msg.bizAppId
            });
          }
          break;
        }
      }
    },
    askInternet(msg) {
      const index = this.msgList.findIndex(item => item.askId === msg.askId);
      for (let i = index; i >= 0; i--) {
        // 往前找提问消息 找到第一个终止
        if (this.msgList[i] && this.msgList[i].chatType === 0) {
          this.resendMsg = {
            contentDetail: {
              content:
                this.msgList[i].contentDetail &&
                this.msgList[i].contentDetail.content
            },
            chatType: 0,
            snowId: msg.askReqId
          };
          this.sendMsg('askInternet', {
            askId: msg.askId
          });
          break;
        }
      }
    },
    async sendMsg(resendType, { askType, bizAppId, bizMissParam, bizOkParam, askId, needIntent = 1 } = {}) {
      let self = this;
      // 答案等待中，不可发消息
      if (self.isWaitAnswer) {
        // 等待中
        this.$message.warning(
          this.$t('pc_biz_ai_send_wait').d('暂时不能发送，请稍后再试！')
        );
        return;
      }
      let str = this.question.replace(/(^\s*)|(\s*$)/g, '');
      if (!str && !this.resendMsg) {
        this.$message.warning(
          this.$t('pc_biz_ai_input_not_empty').d('发送内容不能为空！')
        );
        this.question = '';
        return;
      }

      if (resendType !== 'askInternet') {
        const inspectionData = await postInspection((self.resendMsg && self.resendMsg.contentDetail.content) || str);
        if (inspectionData && inspectionData.sourceContent !== inspectionData.targetContent) {
          return this.$message.warning(this.$t('pc_biz_msg_inspection_reinput').d('您输入的内容含有企业敏感词，请重新输入'));
        }
      }

      self.$emit('getChatSetting'); // 每次发送消息都重新拿一下配置
      let { snowId } = resendType === 'askInternet' ? this.resendMsg : await getAskReqId();
      self.stopAnswerList[snowId] = false;
      if (resendType !== 'askInternet') {
        self.addQuestion();
        self.addAnswer(); // loadding
      }
      self.isWaitAnswer = true;

      this.submitRequest = submitQuestion(
        {
          askContent: (self.resendMsg && self.resendMsg.contentDetail.content) || self.askQuestion,
          askHistory: self.askHistory, // 带上最近的三条记录
          askSessionId: self.commonAskIdHandler.getAskId(),
          sessionId: self.commonAskIdHandler.getAskId(),
          askReqId: snowId,
          askType: askType || (this.magicTag && !['kng'].includes(this.magicTag.categoryCode) ? 1 : 0),
          bizAppId: bizAppId || (this.magicTag ? this.magicTag.id : ''),
          bizMissParam,
          bizOkParam,
          chatSessionId: self.screenId,
          askInternet: resendType === 'askInternet' ? 1 : 0,
          askId,
          clientType: this.clientType,
          needIntent
        },
        {
          onmessage: msg => {
            if (this.stopAnswerList[snowId]) return;
            let msgData = JSON.parse(msg.data);

            if ([0, 1, 3, 4, 5, 6, 8].includes(msgData.type)) {
              if (resendType === 'askInternet') {
                let lastMessage = this.msgList[this.msgList.length - 1];
                self.addAnswer({
                  ...lastMessage,
                  contentDetail: {
                    otherSourceAnswer: msgData.contentChip || ''
                  }
                });
              } else {
                // 进行中  3 指令响应数据 4 指令参数缺失需要补齐二次框 5 回显二次框 6 换一换
                self.addAnswer({
                  answerStatus: msgData.aiTips ? 0 : (msgData.answerStatus ? msgData.answerStatus : 1),
                  chatCorrectEnabled: msgData.chatCorrectEnabled || 0, // 是否启用纠错
                  chatLikeFlag: msgData.chatLikeFlag || 0, // 是否评价过
                  chatContentId: msgData.chatContentId, // 消息id
                  askInternet: resendType === 'askInternet' ? 1 : 0,
                  askReqId: snowId,
                  chatType: 1, // 消息类型 1回答 0 提问
                  contentDetail: {
                    aiTips: msgData.aiTips || '',
                    content: msgData.contentChip || '',
                    linkList:
                      msgData.linkList && msgData.linkList.length
                        ? msgData.linkList
                        : msg.linkList && msg.linkList.length
                          ? msg.linkList
                          : []
                  },
                  type: msgData.type,
                  bizAppId: msgData.bizAppId,
                  askId: msgData.askId,
                  bizMissParam: msgData.bizMissParam,
                  bizOkParam: msgData.bizOkParam,
                  bizModuleCode: msgData.bizModuleCode,
                  bizAppName: msgData.bizAppName || ''
                });
              }

              if (msgData.type !== 0) {
                self.isWaitAnswer = false;
                if (msgData.type === 1) {
                  self.getQueryAskHistory({
                    answer:
                      self.msgList[self.msgList.length - 1].contentDetail.content,
                    ask:
                      self.msgList[self.msgList.length - 2].contentDetail.content
                  });
                }
                if ((!self.screenId || msgData.askTime) && resendType !== 'askInternet') {
                  self.$nextTick(() => {
                    self.$emit('update-message', {
                      chatSessionId: self.screenId,
                      time: timestampToTime(msgData.askTime)
                    });
                  });
                }
              }

            } else if (msgData.type === 2) {
              if (self.stopAnswerList[snowId]) return;
              self.isWaitAnswer = false;
              if (msgData.errorMsg) {
                self.addAnswer({
                  answerStatus: 3, // 错误消息
                  askReqId: snowId,
                  chatType: 1, // 消息类型 1回答 0 提问
                  contentDetail: {
                    content: msgData.errorMsg || '',
                    linkList: []
                  }
                });
              }
            }

            self.$nextTick(() => {
              self.scrollToEnd();
            });
          },
          onclose(msg) {
            if (self.stopAnswerList[snowId]) return;
            if (msg === 'timeout') {
              // 超时错误
              self.$message.error(
                self.$t('pc_biz_ai_network_timeout').d('网络超时，请重试！')
              );
              self.msgList.pop(); // 清除等待消息效果
            }
            if (self.isWaitAnswer) {
              self.isWaitAnswer = false;
            }
            self.resendMsg = null; // 清空重新发送数据
            self.$nextTick(() => {
              self.scrollToEnd();
            });
          },
          onerror(err) {
            if (self.isWaitAnswer) {
              self.isWaitAnswer = false;
            }
            self.resendMsg = null; // 清空重新发送数据
            console.log('onerror', err);
          }
        });

    },
    // 提问题
    addAnswer(answer) {
      if (answer) {
        if (this.msgList.length && this.msgList.length > 1) {
          // 有消息记录
          let lastMessage = this.msgList[this.msgList.length - 1]; // 数组最后一项
          if (
            lastMessage.chatType &&
            (lastMessage.chatContentId && lastMessage.chatContentId === answer.chatContentId) ||
            (lastMessage.contentDetail && lastMessage.contentDetail.aiTips && lastMessage.askReqId === answer.askReqId)
          ) {
            // 如果是回答id相同则表示内容跟新
            let params = {
              ...answer,
              contentDetail: {
                aiTips: lastMessage.contentDetail.aiTips || '',
                content:
                  (lastMessage.contentDetail.content || '') +
                  (answer.contentDetail.content || ''), // 拼接消息体
                linkList: [...(lastMessage.contentDetail.linkList || []), ...(answer.contentDetail.linkList || [])],
                otherSourceAnswer: (lastMessage.contentDetail.otherSourceAnswer || '') + (answer.contentDetail.otherSourceAnswer || '')
              }
            };
            this.msgList.splice(this.msgList.length - 1, 1, params);
            console.log('更新消息内容', params);
          } else {
            // 创建新回答
            console.log('创建新消息', answer);
            this.msgList.splice(-1, 1, answer);
          }
        } else {
          // 无历史消息
          // 加载答案
          this.msgList.splice(-1, 1, answer);
        }
      } else {
        // 加载等待消息
        this.msgList.push({
          answerStatus: 0,
          chatType: 1
        });
      }
      this.scrollToEnd();
    },

    // 获取查询所需的历史记录
    getQueryAskHistory(newlog) {
      if (newlog) {
        if (this.askHistory.length < 3) {
          this.askHistory.push(newlog);
        } else {
          this.askHistory.push(newlog);
          this.askHistory.shift();
        }
      }
    },
    // 添加提问记录
    addQuestion() {
      // 添加提问记录
      this.msgList.push(
        this.resendMsg || {
          chatType: 0,
          contentDetail: {
            content: this.question
          }
        }
      );
      this.askQuestion = this.question;
      // 清空输入框
      this.question = '';
      this.$refs.textarea.height = '52px';
    },
    // 改变全屏尺寸
    changeFullSize() {
      let bFullscreen = !this.bFullscreen;
      this.$emit('fullscreen', bFullscreen);
      this.scrollToEnd();
    },
    // 分享功能
    share(event) {
      this.showShare = !this.showShare;
    },
    // 清空历史记录接口
    clearHistory() {
      clearHistory().then(() => {
        this.msgList = [
          {
            chatType: 1,
            answerStatus: 3
          }
        ];
        window.localStorage.setItem('sid', '');
      });
    },
    // 展示反馈弹窗
    showFeedbackDialog(index) {
      this.currentFeedbackIndex = index;
      this.$emit('feedback');
    },
    // 更新反馈状态
    updateFeedback(content) {
      submitQuestionFeedback({
        chatContentId: this.msgList[this.currentFeedbackIndex].chatContentId,
        correctContent: content
      }).then(_res => {
        this.$message.success(
          this.$t('pc_biz_ai_feedback_success').d('感谢您的反馈！')
        );
        this.msgList[this.currentFeedbackIndex].chatCorrectEnabled = 1; // 更新反馈状态
      });
    },
    // 滑动到指定元素
    scrollTo(p) {
      const scrollElement =
        this.$refs.scrollbar && this.$refs.scrollbar.$el.firstChild;
      scrollElement && scrollElement.scrollTo(0, p);
    },
    // 滑动底部
    scrollToEnd() {
      this.$nextTick(() => {
        this.scrollTo(this.$refs.dialogBody.scrollHeight);
      });
    }
  },
  beforeDestroy() {
    this.$root.$off('ASK_INTERNET', this.askInternet);
    document.body.removeEventListener('click', this.handleShareVisible);
    this.commonAskIdHandler && this.commonAskIdHandler.destoryReleaseAsset();
  }
};
</script>
