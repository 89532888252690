<template>
  <div v-if="options.length > 1">
    <label class="font-size-14 color-gray-10"><slot></slot></label>
    <yxt-select v-model="value"  @change="change" v-if="options.length" :size="size" style="width:120px">
      <yxt-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
      >
      </yxt-option>
    </yxt-select>
  </div>
</template>

<script>
import { getAuthSelectData } from './service';

const CREATE_BY_SELF = 1; // 我管辖的
const CREATE_BY_ALL = 2; // 全部

export default {
  name: 'YxtbizPersonSelector',
  props: {
    size: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      value: CREATE_BY_SELF,
      isDeptManage: 0,
      isHavaManagePermission: 0,
      isDirectManage: 0,
      defaultOptions: [
        {
          value: CREATE_BY_SELF,
          label: '我管辖的'
        },
        {
          value: CREATE_BY_ALL,
          label: '全部'
        }
      ]
    };
  },
  computed: {
    options() {
      return this.defaultOptions.filter(v => this.isDirectManage || this.isDeptManage || this.isHavaManagePermission);
    }
  },
  created() {
    getAuthSelectData({type: 4}).then(res => {
      this.isDeptManage = res.isDeptManage;
      this.isHavaManagePermission = res.isHavaManagePermission;
      this.isDirectManage = res.isDirectManage;
      this.$emit('change', this.value);
    });
  },
  methods: {
    change(value) {
      this.$emit('change', value);
    }
  }
};
</script>

<style scoped>

</style>

