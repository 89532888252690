<template>
  <div ref='orgSelect' class='org-multiple-select'>
    <div class="yxt-input__inner" style='display: block;position: relative' @click='handleClick'>
      <template v-if='tags && tags.length'>
        <yxt-tag
          v-for="tag in tags"
          :ref='tag.orgId'
          :key="tag.orgId"
          :closable='closeable(tag)'
          :style='{
          marginRight: marginRight + "px"
        }'
          disable-transitions
          size="mini"
          @close='close(tag)'
          type="info">
          <span class='ellipsis' :ref='"text"+ tag.orgId'>{{tag.orgName}}</span>
        </yxt-tag>
      </template>
      <span class='color-gray-6' v-else>{{placeholder}}</span>
      <i
        style='position: absolute;right: 8px;top: 50%; transform: translateY(-50%)'
        class="yxt-input__icon color-gray-6"
        :class="{'yxt-icon-arrow-up': visible, 'yxt-icon-arrow-down': !visible}"
      />
    </div>
    <div class='org-select-dropdown'>
      <yxt-collapse-transition>
        <div v-show="visible" class='mv24'>
          <yxt-tree
            :check-strictly='checkStrictly'
            :props='{
              label: "orgName",
              children: "childList"
            }'
            wrap-width='100%'
            ref='tree'
            :data="treeData"
            show-checkbox
            node-key="orgId"
            default-expand-all
            @check='nodeClick'
            :expand-on-click-node="false">
          </yxt-tree>
        </div>
      </yxt-collapse-transition>
    </div>
  </div>
</template>

<script>
import { getGroupOrgList } from './service';

const arrowWidth = 20;
const marginLWh = 24;
const borderWidth = 2;
const closeIconWidth = 26;

function isEmptyArray(array) {
  return Array.isArray(array) && !array.length;
};
export default {
  name: 'SelectPosition',
  props: {
    selected: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String
    },
    checkStrictly: {
      default: true
    },
    setCurrentOrg: {
      type: Boolean,
      default: false
    },
    functionCode: {
      type: String,
      default: ''
    },
    dataPermissionCode: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tags: [],
      visible: false,
      marginRight: 6,
      treeData: [],
      loaded: false,
      currentLoginOrgId: localStorage.getItem('orgId'),
      allGroup: {},
      disabledTags: []
    };
  },
  async mounted() {
    this.calcContainerWidth();
    await this.initOrgNames();
    this.init();
    document.body.addEventListener('click', this.handleClickOutSide);
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.handleClickOutSide);
  },
  watch: {
    selected: {
      handler(value) {
      }
    }
  },
  methods: {
    closeable(tag) {
      if (this.disabledTags.find(i=>i.orgId === tag.orgId)) return false;
      return this.setCurrentOrg ? (tag.orgId && tag.orgId !== this.currentLoginOrgId) : !!tag.orgId;
    },
    // 拿全部数据用来回显
    initOrgNames() {
      return getGroupOrgList(0).then(res => {
        res.datas.forEach(i=>{
          this.allGroup[i.orgId] = i;
        });
      }).catch(()=>{});
    },
    handleClick() {
      if (this.disabled) return;
      this.visible = !this.visible;
      this.clickOn = true;
    },
    handleClickOutSide(e, v) {
      if (this.clickOn) return (this.clickOn = false);
      this.visible = false;
    },
    calcContainerWidth() {
      const containerWidth = this.$refs['orgSelect'].getBoundingClientRect().width;
      this.calcWidth = containerWidth - marginLWh - arrowWidth - borderWidth;
    },
    findOrgName(treeData) {
      let record = {};
      function f(node) {
        for (let i = 0; i < node.length; i++) {
          record[node[i].orgId] = node[i].orgName;
          node[i].childList && f(node[i].childList);
        };
      }
      f(treeData);
      return record;
    },
    setNodes() {
      const record = this.findOrgName(this.treeData);
      this.$nextTick(() => {
        this.selected.forEach(item => {
          item.orgName = record[item.orgId];
        });
        this.tags = this.selected;
        this.$refs['tree'] && this.$refs['tree'].setCheckedNodes(this.selected);
        this.nodeClick();
      });
    },
    setCurrentLoginOrg() {
      if (this.setCurrentOrg && !this.selected.find(item => item.orgId === this.currentLoginOrgId)) {
        this.selected.push({
          orgId: this.currentLoginOrgId
        });
      }
    },
    setDisabled(data) {
      // if (!this.setCurrentOrg) return data;
      const f = (node) => {
        for (let i = 0; i < node.length; i++) {
          node[i].childList && f(node[i].childList);
          this.allGroup[node[i].orgId].hasPermisison = true;
          if (this.setCurrentOrg && node[i].orgId === this.currentLoginOrgId) {
            node[i].disabled = true;
          }
        };
      };
      f(data);
      return data;
    },
    reset() {
      this.getNotPermissionSelected();
      this.setCurrentLoginOrg();
      this.setNodes();
    },
    init() {
      getGroupOrgList(1, this.functionCode, this.dataPermissionCode).then(res => {
        this.treeData = this.setDisabled(res.datas || []);
        this.getNotPermissionSelected();
        this.setCurrentLoginOrg();
        this.setNodes();
      });
    },
    // 获取已选数据中无权限的部分，展示tag且不可删除
    getNotPermissionSelected() {
      this.disabledTags = [];
      this.selected.forEach(i => {
        if (!this.allGroup[i.orgId] || !this.allGroup[i.orgId].hasPermisison) {
          this.disabledTags.push(Object.assign({
            orgId: i.orgId,
            hasPermisison: false
          }, this.allGroup[i.orgId]
          ));
        }
      });
    },
    close(item) {
      this.$refs['tree'].setChecked(item, false, true);
      this.nodeClick();
    },
    getAllNodes() {
      return this.$refs['tree'].getCheckedNodes(false, true);
    },
    nodeClick() {
      const nodes = this.getAllNodes().concat(this.disabledTags);
      this.$emit('selectedChange', nodes);
      if (isEmptyArray(nodes)) {
        this.tags = [];
        return;
      }
      this.tags = nodes;
      this.$nextTick(() => {
        let total = 0;
        for (let i = 0; i < nodes.length; i++) {
          const tagEl = this.$refs[nodes[i].orgId][0].$el;
          const textEl = this.$refs['text' + nodes[i].orgId][0];
          textEl.style.maxWidth = this.calcWidth - closeIconWidth + 'px';
          total += tagEl.clientWidth + this.marginRight;
          if (this.calcWidth < total) {
            this.tags = nodes.slice(0, Math.max(1, i - 1));
            if (nodes.length > 1) {
              this.tags.push({ orgName: `+${nodes.length - i + 1}`});
              textEl.style.maxWidth = this.calcWidth - closeIconWidth - 60 + 'px';
            }
            break;
          }
        }
      });
    }
  }
};
</script>

<style lang="scss">
.org-multiple-select {
  cursor: pointer;
  position: relative;
}
.org-select-dropdown {
  width: 100%;
  position: absolute;
  z-index: 1001;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 #eeeeee;
  box-sizing: border-box;
  margin: 5px 0;
}
</style>
