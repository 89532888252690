export const isNullOrEmpty = s => {
  return s === null || s === '';
};

export const isString = (str) => {
  return typeof str === 'string';
};

export const dateToString = (text, format, notReplace) => {
  if (isNullOrEmpty(text)) {
    return '';
  }
  if (!notReplace && isString(text)) {
    text = text.replace(/\.\d+/, '').replace(/-/g, '/');
  }
  const date = new Date(text);
  if (isNullOrEmpty(date) || isNaN(date.getDate())) {
    return '';
  }
  let dateStr = format;
  dateStr = dateStr
    .replace('yyyy', date.getFullYear())
    .replace('MM', (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1))
    .replace('dd', (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()))
    .replace('HH', (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()))
    .replace('mm', (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()))
    .replace('ss', (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()));
  dateStr = dateStr
    .replace('M', date.getMonth() + 1)
    .replace('d', date.getDate())
    .replace('H', date.getHours())
    .replace('m', date.getMinutes())
    .replace('s', date.getSeconds());
  return dateStr;
};

// 显示--（空或字符串）
export const getStringValue = (val) => {
  if (!val) {
    return '--';
  }
  return val;
};

/**
 * @param {string} val 时间字符串
 */
export const shortDateTime = (val) => {
  return getStringValue(dateToString(val, 'yyyy-MM-dd HH:mm:ss'));
};

/**
 * 日期格式化
 * @param {Object} value 日期值
 * @param {String} part 格式
 */
export const dateFormat = (value, part) => {
  let date;
  if (value instanceof Date) {
    date = value;
  } else if (typeof value === 'number') { // 时间戳
    date = new Date(value);
  } else {
    if (typeof value === 'undefined' || value === null || value === '' || value === '-' || value.indexOf('0001') >= 0 || value.indexOf('1900') >= 0) {
      return '-';
    }
    if (value.length > 10) {
      value = value.replace(/T/, ' ');
    }
    date = new Date(value.replace(/-/g, '/').split('.')[0]);
  }
  if (date === new Date(1970, 0, 1)) {
    return '-';
  }
  let redate = '';
  part = (part == null) ? 'yyyy-MM-dd HH:mm' : part;
  const y = date.getFullYear();
  const M = date.getMonth() + 1;
  const d = date.getDate();
  const H = date.getHours();
  const m = date.getMinutes();
  const s = date.getSeconds();
  const MM = (M > 9) ? M : '0' + M;
  const dd = (d > 9) ? d : '0' + d;
  const HH = (H > 9) ? H : '0' + H;
  const mm = (m > 9) ? m : '0' + m;
  const ss = (s > 9) ? s : '0' + s;
  redate = part.replace('yyyy', y).replace('MM', MM).replace('dd', dd).replace('HH', HH).replace('mm', mm).replace('ss', ss).replace('M', M).replace('d', d).replace('H', H).replace('m', m).replace('s', s);
  return redate;
};

export const getDateTime = (value) => {
  let date = dateFormat(value, 'yyyy-MM-dd HH:mm:ss');
  if (date === '-') {
    date = new Date();
  } else {
    date = new Date(value.replace(/-/g, '/').split('.')[0]);
  }
  return date.getTime();
};
