<template>
  <div class="yxt-user-selector-wrap">
    <yxt-tabs
      :drawer-top="drawerTop"
      @tab-click="name => $emit('tab-click', name)"
      v-model="activeName"
      :before-leave="tabBeforeLeave"
    >
      <yxt-tab-pane
        :key="index"
        v-for="(tab, index) in setTabs"
        :name="tab.value"
      >
        <span slot="label">{{ tab.label }}</span>
        <yxt-row class="yxtbiz-user-selector-container" type="flex">
          <template v-if="activeName === tab.value">
            <slot
              v-if="$slots[tab.value]"
              :name="tab.value"
              :ref="tab.value"
              @update="update"
            />
            <component
              v-else
              :targetOrgId="targetOrgId"
              :disabled="disabledOrgSelect"
              :isOrgSelectAlone="isOrgSelectAlone"
              :enableGroupCorp="enableGroupCorp"
              :disabledOrgIds="disabledOrgIds"
              :visibleOrgIds="visibleOrgIds"
              :is="tab.component"
              :functionCode="functionCode"
              :dataPermissionCode="dataPermissionCode"
              :scope="scope"
              v-model="selectedProps[tab.model]"
              :type="tab.model"
              :isShowDeptment="isShowDeptment"
              :relationTips="relationTips"
              :projectGroupId="projectGroupId"
              @update="update"
              @onProcess="onProcess"
              @org-change="orgChange"
            ></component>
          </template>
          <aside class="yxt-user-selection">
            <yxt-scrollbar :fit-height="true">
              <check-list :data="list">
                <template slot-scope="scope">
                  <yxt-tooltip
                    :key="item.id"
                    v-for="item in scope.list"
                    placement="top"
                    effect="dark"
                  >
                    <template slot="content">
                      <yxtbiz-user-name
                        class="yxtbiz-user-selector-tag"
                        :name="
                          enableGroupCorp ? item.combinationName : (item.fullname || item.name)
                        "
                      />
                    </template>
                    <yxt-tag
                      @close="deleteItem(item)"
                      class="mr5"
                      size="small"
                      type="info"
                      :class="{
                        autoWidth: enableGroupCorp
                      }"
                      :disableTransitions="true"
                      style="margin-bottom:5px"
                      closable
                      ><yxt-svg
                        class="mr5 v-mid"
                        width="14px"
                        height="14px"
                        :icon-class="getTypeIcon(item)"
                      /><span
                        v-if="!isOpenData || item.nodeType === 'userGroup'"
                        class="yxtbiz-user-selector-tag"
                        >{{ item | tag }}</span
                      >
                      <yxtbiz-user-name
                        class="yxtbiz-user-selector-tag"
                        v-else
                        :name="
                          enableGroupCorp ? item.combinationName : item.fullname
                        "
                      />
                    </yxt-tag>
                  </yxt-tooltip>
                </template>
              </check-list>
            </yxt-scrollbar>
            <yxt-button
              type="text"
              class="yxtbiz-user-clear font-size-12 color-gray-9 hover-primary-6"
              @click="clear"
              :disabled="list.length === 0"
            >
              {{ $t('biz_udp_clear_all') }}
            </yxt-button>
          </aside>
        </yxt-row>
      </yxt-tab-pane>
    </yxt-tabs>
  </div>
</template>

<script>
import Persons from './components/persons';
import UserGroup from './components/user-group';
import Advance from './components/advance';
import Postions from './components/Positions';
import CheckList from './components/checkList';
import Import from './components/import';
import GroupPerson from './components/group-person';
import RelationDynamicUserGroup from './components/RelationDynamicUserGroup';
import { getGroupUserList, depMessageQuery } from './service';
import { Tabs, TabPane, Scrollbar, Pagination, Tag } from 'yxt-pc';
import openDataMixin from '../mixins/openData';
import { MessageBox } from 'yxt-pc';
import { i18n } from 'yxt-biz-pc/packages/common-util/changeLangs';
import { enmuFunctions } from '../../function-points';
import { getFunStatus } from '../../common-util/factorUtils';
// import { getNavByCode } from 'yxt-biz-pc/packages/common-util/getNav';

const TYPE = {
  USER: 'persons',
  GROUP: 'userGroup',
  RELATION: 'relationDynamicUserGroup'
};
const mapType = function() {
  let result = {};
  let resutArray = {};
  Object.values(TYPE).forEach(type => {
    result[type] = {};
    resutArray[type] = [];
  });

  return [result, resutArray];
};

const testType = function(item) {
  if (item.nodeType === 'userGroup') {
    return TYPE.GROUP;
  }

  if (item.type === 'relation') return TYPE.RELATION;

  return item.nodeType ? item.nodeType : TYPE.USER;
};
export default {
  name: 'YxtUserSelector',
  mixins: [openDataMixin],
  components: {
    Persons,
    UserGroup,
    Advance,
    CheckList,
    Postions,
    Import,
    RelationDynamicUserGroup,
    GroupPerson,
    YxtTabs: Tabs,
    YxtTabPane: TabPane,
    YxtScrollbar: Scrollbar,
    YxtPagination: Pagination,
    YxtTag: Tag
  },
  props: {
    drawerTop: {
      default: true,
      type: Boolean
    },
    // string Or Object
    // Object:{label, value}  (value:slotName)
    tabs: {
      type: Array,
      default: () => []
    },
    scope: {
      type: Number,
      default: 0
    },
    relationTips: {
      type: String,
      default: ''
    },
    functionCode: {
      type: String,
      default: ''
    },
    dataPermissionCode: {
      type: String,
      default: ''
    },
    data: {
      type: Array,
      default: () => []
    },
    limit: {
      type: Number,
      default: 0,
      validator(v) {
        return v >= 0;
      }
    },
    enableGroupCorp: {
      type: Boolean,
      default: false
    },
    groupLimit: {
      type: Number,
      default: ''
    },
    disabledOrgIds: {},
    visibleOrgIds: {
      type: Array,
      default: []
    },
    targetOrgId: {
      type: String,
      default: ''
    },
    isShowDeptment: {
      type: Boolean,
      default: true
    },
    disabledOrgSelect: {
      type: Boolean,
      default: false
    },
    isOrgSelectAlone: {
      type: Boolean,
      default: false
    },
    projectGroupId: {
      type: String,
      default: ''
    }
  },
  data() {
    // 记录组件初始化的原始数据key值
    this.oldObjKeys = [];
    const hasGroupPerson = localStorage.getItem('sourceCode') === '104';
    const hasGroupId = this.projectGroupId;
    const defaultTabs = [
      {
        label: i18n.t('pc_biz_udp_lbl_quickSelect'),
        value: 'persons',
        component: 'Persons',
        model: TYPE.USER
      },
      {
        label: i18n.t('pc_biz_udp_lbl_selectGroup'),
        value: 'userGroup',
        component: 'UserGroup',
        model: TYPE.GROUP
      },
      {
        label: i18n.t('pc_biz_udp_lbl_relation_selectGroup').d('关联动态用户组'),
        value: 'relationDynamicUserGroup',
        component: 'RelationDynamicUserGroup',
        model: TYPE.RELATION
      },
      {
        label: i18n.t('pc_biz_udp_lbl_advanceSelect'),
        value: 'advance',
        component: 'Advance',
        model: TYPE.USER
      },
      {
        label: i18n.t('pc_biz_udp_lbl_importUser'),
        value: 'import',
        component: 'Import',
        model: TYPE.USER
      }
    ];
    hasGroupPerson &&
      hasGroupId &&
      defaultTabs.push({
        label: i18n.t('pc_biz_udp_lbl_groupPerson'),
        value: 'groupPerson',
        component: 'GroupPerson',
        model: TYPE.USER
      });
    return {
      currentOrgId: '', // 集团版下组件选中的orgId
      selectedObjs: {
        ...mapType()[0],
        total: {} // 所有选中对象
      },
      selectedProps: {
        ...mapType()[1]
      },
      defaultTabs,
      activeName: 'persons',
      // 子组件状态，是否在处理内部数据（用于标记是否在导入用户流程中）
      processing: false,
      // 用于父组件调用，标记当前选人组件在抽屉中是否需要关闭提示，true：需要提示，false：不需要提示
      showTip: false,
      hideDepart: false //  是否隐藏部门选择框
    };
  },
  created() {
    this.getOrgDepartConfig();
    this.data.forEach(val => {
      const item = { ...val };
      let combinationName = item.fullname;
      if (item.orgName) combinationName = `${item.orgName}-${combinationName}`;
      if (this.enableGroupCorp) {
        item.combinationName = combinationName;
      }
      if (this.isOrgSelectAlone && this.enableGroupCorp) {
        item.oldId = item.id;
        item.id = `${item.id}&&${item.orgId}`;
      }
      this.selectedObjs.total[item.id] = item;
      const type = testType(item);
      switch (type) {
        case TYPE.USER:
          this.selectedObjs[TYPE.USER][item.id] = item;
          this.selectedProps[TYPE.USER] = Object.values(
            this.selectedObjs[TYPE.USER]
          );
          break;
        case TYPE.GROUP:
          this.selectedObjs[TYPE.GROUP][item.id] = item;
          this.selectedProps[TYPE.GROUP] = Object.values(
            this.selectedObjs[TYPE.GROUP]
          );
          break;
        case TYPE.RELATION:
          this.selectedObjs[TYPE.RELATION][item.id] = item;
          this.selectedProps[TYPE.RELATION] = Object.values(
            this.selectedObjs[TYPE.RELATION]
          );
          break;
      }
    });
    this.oldObjKeys = Object.keys(this.selectedObjs.total);
  },
  computed: {
    showRelation() {
      // 是否显示动态用户组tab
      return getFunStatus(enmuFunctions.UDP_DYNAMIC_USERGROUP).enabled;
    },
    hasLimit() {
      return this.limit !== 0;
    },
    setTabs() {
      if (this.tabs.length === 0) {
        return this.defaultTabs;
      } else {
        return this.tabs
          .map(tab => {
            if (typeof tab === 'string') {
              if (!this.showRelation && tab === 'relationDynamicUserGroup') {
                // 表示动态用户组未增购，不管业务方传没传，直接不显示该tab
                return null;
              }
              return this.defaultTabs.find(item => item.value === tab);
            } else if (typeof tab === 'object' && tab.label && tab.value) {
              return { ...tab };
            } else {
              return null;
            }
          })
          .filter(item => {
            return item !== null && item !== undefined;
          });
      }
    },
    list() {
      console.log(Object.values(this.selectedObjs.total));
      return Object.values(this.selectedObjs.total);
    }
  },
  methods: {
    orgChange(data) {
      this.currentOrgId = data.orgId;
    },
    getTypeIcon(item) {
      if (testType(item) === TYPE.GROUP || testType(item) === TYPE.RELATION) {
        return 'usergroup';
      } else {
        return 'person-center';
      }
    },
    splitObj(items) {
      items.forEach(item => {
        const type = testType(item);
        this.selectedObjs.total[item.id] = item;
        this.selectedObjs[type][item.id] = item;
        this.selectedProps[type].push(item);
      });
    },

    deleteItem(item) {
      this.$delete(this.selectedObjs.total, item.id);
      const type = testType(item);
      this.$delete(this.selectedObjs[type], item.id);
      this.$set(
        this.selectedProps,
        type,
        Object.values(this.selectedObjs[type])
      );
      this.emitChange();
    },
    clear() {
      for (let item in this.selectedObjs) {
        this.selectedObjs[item] = {};
      }

      this.emitChange();

      for (let item in this.selectedProps) {
        this.selectedProps[item] = [];
      }
    },

    updateObj(type, items, isAdd) {
      if (type === TYPE.RELATION) {
        // 动态用户组更新逻辑
        const obj = Object.assign({}, this.selectedObjs[type]);
        const { prev, current } = items;
        if (current.id) obj[current.id] = current;
        if (prev.id && obj[prev.id]) delete obj[prev.id];
        this.$set(this.selectedObjs, type, obj);
        this.selectedProps[type] = Object.values(this.selectedObjs[type]);
        this.emitChange();
        return;
      }

      let obj = Object.assign({}, this.selectedObjs[type]);

      items.forEach(item => {
        if (isAdd) {
          obj[item.id] = item;
        } else {
          delete obj[item.id];
        }
      });

      this.$set(this.selectedObjs, type, obj);
      this.selectedProps[type] = Object.values(this.selectedObjs[type]);
      this.emitChange();
    },

    emitChange() {
      const changePersons = Object.values(this.selectedObjs[TYPE.USER]).map(
        item => {
          const params = { ...item };
          if (this.isOrgSelectAlone) params.id = item.oldId;
          return params;
        }
      );
      // 暴露出去的参数，第一个是选人的，第二个是用户组的，第三个是关联动态用户组的
      this.$emit(
        'change',
        changePersons,
        Object.values(this.selectedObjs[TYPE.GROUP]),
        Object.values(this.selectedObjs[TYPE.RELATION])
      );
      this.showTipHandler();
    },

    update(type, items, isAdd) {
      if (type === TYPE.RELATION) {
        // 动态用户组更新逻辑
        const { prev, current } = items;
        const totalObj = Object.assign({}, this.selectedObjs.total);
        if (current.id) totalObj[current.id] = current;
        if (prev.id && totalObj[prev.id]) delete totalObj[prev.id];
        this.$set(this.selectedObjs, 'total', totalObj);
        this.updateObj(type, items);
        return;
      }

      if (!(items instanceof Array)) {
        items = [items];
      }
      let total = Object.assign({}, this.selectedObjs.total);

      items.forEach(item => {
        if (isAdd) {
          total[item.id] = item;
        } else {
          delete total[item.id];
        }
        // 更新相应类型的选中对象
      });

      this.$set(this.selectedObjs, 'total', total);
      this.updateObj(type, items, isAdd);
    },
    showLimitError() {
      this.$alert(
        this.$t('pc_biz_udp_msg_outRange'),
        this.$t('biz_tcm_limitnum', { x: this.limit }),
        {
          confirmButtonText: this.$t('biz_tcm_checkbtn'),
          type: 'error'
        }
      );
    },
    calcGroupLimit(selectedObjs) {
      if (!this.enableGroupCorp || !this.groupLimit) return;
      let recordCount = {};
      let bol = false;
      Object.values(selectedObjs).forEach(item => {
        recordCount[item.orgId] = recordCount[item.orgId]
          ? recordCount[item.orgId] + 1
          : 1;
        if (recordCount[item.orgId] > this.groupLimit) {
          bol = true;
        }
      });
      if (bol) {
        this.$alert(this.$t('pc_biz_select_msg_select_user', {0: this.groupLimit}).d(`每个平台最多选择${this.groupLimit}个用户`), this.$t('pc_biz_ls_tips').d('提示'), {
          confirmButtonText: this.$t('biz_tcm_checkbtn'),
          type: 'error'
        });
        return bol;
      }
      return bol;
    },
    getSelected() {
      /* eslint-disable no-undef */
      return new Promise((resolve, reject) => {
        const relation = Object.values(this.selectedObjs[TYPE.RELATION]); // 选中的动态用户组数据
        const ids = Object.keys(this.selectedObjs[TYPE.GROUP]);
        const userIds = Object.keys(this.selectedObjs[TYPE.USER]).map(item => {
          if (this.isOrgSelectAlone) {
            const [id] = item.split('&&');
            return id;
          }
          return item;
        });
        let userSet = new Set(userIds);
        var groupUsers = [];
        if (ids.length > 0) {
          const params = {
            groupIds: ids,
            type: 2,
            navCode: this.functionCode,
            dataPermissionCode: this.dataPermissionCode
          };
          if (this.enableGroupCorp) {
            params.targetOrgId = this.currentOrgId || this.targetOrgId;
          }
          getGroupUserList(params)
            .then(result => {
              groupUsers = result.datas;
              if (this.hasLimit) {
                groupUsers.forEach(user => {
                  userSet.add(user.id);
                });

                if (userSet.size > this.limit) {
                  this.showLimitError();
                  return;
                }
              }

              groupUsers.forEach(user => {
                this.selectedObjs[TYPE.USER][user.id] = user;
              });
              resolve([
                ...Object.values(this.selectedObjs[TYPE.USER]),
                ...relation
              ]);
            })
            .catch(e => {
              this.$message({
                type: 'error',
                message: e
              });
              reject(e);
            });
        } else {
          if (this.calcGroupLimit(this.selectedObjs[TYPE.USER])) {
            return;
          }
          if (this.hasLimit && userSet.size > this.limit) {
            this.showLimitError();
            return;
          }
          const changePersons = Object.values(this.selectedObjs[TYPE.USER]).map(
            item => {
              const params = { ...item };
              if (this.isOrgSelectAlone) params.id = item.oldId;
              return params;
            }
          );
          resolve([...changePersons, ...relation]);
        }
      });
    },
    tabBeforeLeave() {
      if (this.processing) {
        return MessageBox.confirm(
          this.$t('pc_biz_udp_msg_closeMsg'),
          this.$t('pc_biz_udp_lbl_closeTitle'),
          {
            confirmButtonText: this.$t('pc_biz_udp_btn_confirm'),
            cancelButtonText: this.$t('pc_biz_udp_btn_cancel'),
            type: 'warning'
          }
        );
      } else {
        return true;
      }
    },
    // 子组件处理状态变更
    onProcess(processing) {
      this.processing = processing;
      this.showTipHandler();
    },
    showTipHandler() {
      // 以下情况需要关闭提示：数据发现了变化或者子组件正在处理数据
      this.showTip =
        this.processing ||
        JSON.stringify(this.oldObjKeys) !==
          JSON.stringify(Object.keys(this.selectedObjs.total));
    },
    // 机构开启隐藏部门全路径时，学员端导航调用选人组件隐藏部门选择框
    getOrgDepartConfig() {
      if (!this.functionCode) return;
      const nav = this.getNavByCode(this.functionCode);
      // const nav = this.getNavByCode('knglib_index');
      if (this.isShowDeptment && nav && nav.type === 1) {
        // 学员端导航且机构开启隐藏部门全路径，则选人组件隐藏部门选择框
        depMessageQuery().then(res => {
          this.isShowDeptment = !res.pathState;
        });
      }
    },
    getNavByCode(navCode) {
      try {
        let per = null;
        let navsAll = this.$store.state.navManageStore.navList;
        if (navsAll && navsAll.length > 0) {
          for (let index = 0; index < navsAll.length; index++) {
            const element = navsAll[index];
            if (element.code === navCode) {
              per = element;
              break;
            }
          }
        }
        return per;
      } catch (error) {
        // error
      }
      return null;
    }
  },
  watch: {
    setTabs: {
      immediate: true,
      handler(v) {
        this.activeName = v[0].value;
      }
    },
    data(v) {
      this.clear();
      this.splitObj(v);
    }
  },
  filters: {
    tag(input) {
      const type = testType(input);
      if (type === TYPE.USER) {
        return input.combinationName || input.fullname || input.username;
      } else {
        return input.name;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.autoWidth {
  width: 100% !important;
  max-width: none !important;
}
</style>
