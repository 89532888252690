var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "yxtbiz-global-notice" }, [
    _vm.topNotice && _vm.topNotice.content
      ? _c("div", { staticClass: "yxtbiz-nav-global-notice" }, [
          _vm._v("\n    " + _vm._s(_vm.topNotice.content) + "\n    "),
          _vm.showClose
            ? _c(
                "div",
                {
                  staticClass: "pull-right hand",
                  on: {
                    click: function ($event) {
                      return _vm.closeNotice(_vm.topNotice)
                    },
                  },
                },
                [_c("i", { staticClass: "yxt-icon-close" })]
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }