<template>
  <yxt-select :size="size"  v-model="orgId" :placeholder="getPlaceholder" :disabled="disabled" @change="changeHandler" style="width: 100%"  :clearable="clearable">
    <yxt-option
      v-for="item in options"
      v-show="item.showed"
      :disabled="item.disabled"
      :key="item.orgId"
      :label="item.orgName"
      :value="item.orgId"
    >
    </yxt-option>
  </yxt-select>
</template>

<script>
import { getGroupOrgList } from './service';
import multipleSelect from './multiple-select';
import { i18n } from '../../common-util/changeLangs';
export default {
  components: {
    multipleSelect
  },
  name: 'YxtbizGroupOrgSelect',
  props: {
    functionCode: {
      type: String,
      default: ''
    },
    dataPermissionCode: {
      type: String,
      default: ''
    },
    hideOrgIds: { // 隐藏机构id
      type: Array,
      default: []
    },
    disabledOrgIds: { // 禁用机构id
      type: Array,
      default: []
    },
    setCurrentOrg: { // 是否默认选中当前登录机构,优先级高于defaultId
      type: Boolean,
      default: false
    },
    defaultId: {
      type: String, // 默认选中机构id
      default: ''
    },
    multiple: {
      default: true
    },
    placeholder: {
      default: ''
    },
    clearable: {
      type: Boolean,
      default: true
    },
    selectFirst: {
      type: Boolean,
      default: false
    },
    allgroup: {
      default: false
    },
    visibleOrgIds: {
      type: Array,
      default: []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    targetOrgId: {
      type: String,
      default: ''
    },
    size: { // 单选选择器尺寸 medium/small/mini
      type: String,
      default: ''
    }
  },
  data() {
    return {
      orgId: '', // 机构
      options: []
    };
  },
  created() {
    this.getGroupOrgList();
  },
  computed: {
    manageMode() {
      // return this.$store.state.navManageStore.groupCorpInfo.manageMode;
      return 2;
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : i18n.t('pc_biz_kng_lbl_chooseorg').d('请选择机构');
    }
  },
  mounted() {},
  methods: {
    // 获取集团内的机构 根据用户身份 及functionCode dataPermissionCode获取当前集团内下属机构列表
    getGroupOrgList() {
      getGroupOrgList(0, this.functionCode, this.dataPermissionCode).then(res => {
        if (res.datas && res.datas.length) {
          if (this.allgroup && this.manageMode === 2) {
            res.datas.unshift({
              orgName: '全集团',
              orgId: 'all',
              allgroup: 1
            });
          }
          // 只展示 visibleOrgIds 传的机构，如果传进来的orgId不在返回的列表中，不显示
          if (this.visibleOrgIds && this.visibleOrgIds.length) {
            res.datas = res.datas.filter(item => {
              return this.visibleOrgIds.find(orgId => item.orgId === orgId);
            }).map(item => ({
              orgName: item.orgName,
              orgId: item.orgId
            }));
          }
          this.options = res.datas;
          this.options.forEach(item => {
            item.showed = !this.hideOrgIds.includes(item.orgId);
            item.disabled = this.disabledOrgIds.includes(item.orgId);
          });

          const org = this.getInitOrg(res.datas);
          if (!org) return this.$emit('orgInvalid');
          this.orgId = org.orgId;
          this.$emit('change', org);
        }
      });
    },

    // 获取初始机构
    getInitOrg(list) {
      list = list.filter(i=>i.showed && !i.disabled);
      // 无可用机构
      if (!list || !list.length) return;

      let orgId = '';
      // 默认选中当前机构
      if (this.targetOrgId) {
        orgId = this.targetOrgId;
      } else if (this.setCurrentOrg && list.find(i=>i.orgId === localStorage.getItem('orgId'))) {
        orgId = localStorage.getItem('orgId');
      } else if (this.selectFirst) {
        orgId = list[0].orgId;
      } else {
        orgId = this.defaultId || '';
      }
      const org = list.find(i=>i.orgId === orgId);
      return org;
    },
    changeHandler(orgId) {
      const orgInfo = this.options.find(item=>{
        return item.orgId === orgId;
      });
      this.$emit('change', orgInfo, this.options);
    }
  }
};
</script>
