var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "yxtbiz-search__selections",
      class: [_vm.popperClass],
      style: { marginTop: _vm.offsetTop + 5 + "px" },
    },
    [
      _c("yxt-scrollbar", [
        _c(
          "div",
          {
            staticClass: "yxtbiz-search__wrap",
            style: { maxHeight: 40 * 10 + 16 + 36 * 2 + "px" },
          },
          [
            _vm.searchKey === "" ||
            _vm.searchKey === null ||
            _vm.searchKey === undefined
              ? _c("div", [
                  _vm.userHistories && _vm.userHistories.length && !_vm.hotOnly
                    ? _c(
                        "ul",
                        { staticClass: "yxtbiz-search__group" },
                        [
                          _c(
                            "li",
                            {
                              staticClass:
                                "yxtbiz-search__group-label color-gray-6",
                            },
                            [
                              _vm._v("历史搜索"),
                              _c(
                                "div",
                                { staticClass: "yxtbiz-search__delete" },
                                [
                                  _c("yxt-svg", {
                                    staticClass: "color-gray-6 hover-primary-6",
                                    attrs: {
                                      width: "16px",
                                      height: "16px",
                                      "icon-class": "delete",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.deleteSearchHistories.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._l(_vm.userHistories, function (item) {
                            return _c(
                              "li",
                              {
                                key: item.id,
                                staticClass:
                                  "yxtbiz-search__item color-gray-10 hover-primary-6 hover-bg-primary-1",
                                style: { width: _vm.innerWidth },
                                on: {
                                  click: function ($event) {
                                    return _vm.selectedOption(item)
                                  },
                                },
                              },
                              [
                                _vm.searchKey === "" ||
                                _vm.searchKey === undefined ||
                                _vm.searchKey === null
                                  ? _c("div", {
                                      staticClass: "yxtbiz-search__ellipsis",
                                      domProps: {
                                        textContent: _vm._s(item.name),
                                      },
                                    })
                                  : _c("div", {
                                      staticClass: "yxtbiz-search__ellipsis",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.textRender(item.name)
                                        ),
                                      },
                                    }),
                              ]
                            )
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.orgHistories && _vm.orgHistories.length
                    ? _c(
                        "ul",
                        { staticClass: "yxtbiz-search__group" },
                        [
                          !_vm.hotOnly
                            ? _c(
                                "li",
                                {
                                  staticClass:
                                    "yxtbiz-search__group-label color-gray-6",
                                },
                                [_vm._v("热门搜索")]
                              )
                            : _vm._e(),
                          _vm._l(_vm.orgHistories, function (item) {
                            return _c(
                              "li",
                              {
                                key: item.id,
                                staticClass:
                                  "yxtbiz-search__item color-gray-10 hover-primary-6 hover-bg-primary-1",
                                style: { width: _vm.innerWidth },
                                on: {
                                  click: function ($event) {
                                    return _vm.selectedOption(item)
                                  },
                                },
                              },
                              [
                                _vm.searchKey === "" ||
                                _vm.searchKey === undefined ||
                                _vm.searchKey === null
                                  ? _c("div", {
                                      staticClass: "yxtbiz-search__ellipsis",
                                      domProps: { textContent: _vm._s(item) },
                                    })
                                  : _c("div", {
                                      staticClass: "yxtbiz-search__ellipsis",
                                      domProps: {
                                        innerHTML: _vm._s(_vm.textRender(item)),
                                      },
                                    }),
                              ]
                            )
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                ])
              : !_vm.isTransition
              ? _c(
                  "ul",
                  { staticClass: "pt8 pb8" },
                  _vm._l(_vm.orgHistories, function (item) {
                    return _c(
                      "li",
                      {
                        key: item.id,
                        staticClass:
                          "yxtbiz-search__item color-gray-10 hover-primary-6 hover-bg-primary-1",
                        style: { width: _vm.innerWidth },
                        on: {
                          click: function ($event) {
                            return _vm.selectedOption(item)
                          },
                        },
                      },
                      [
                        _vm.searchKey === "" ||
                        _vm.searchKey === undefined ||
                        _vm.searchKey === null
                          ? _c("div", {
                              staticClass: "yxtbiz-search__ellipsis",
                              domProps: { textContent: _vm._s(item) },
                            })
                          : _c("div", {
                              staticClass: "yxtbiz-search__ellipsis",
                              domProps: {
                                innerHTML: _vm._s(_vm.textRender(item)),
                              },
                            }),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }