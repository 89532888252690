<template>
  <div class="yxtbiz-ai-robot-empty-data">{{ 
    taskName ? $t('pc_biz_search_no_result', [taskName] /** 好的，已为您进行{0}查询，但未找到相关内容 */):
    $t('pc_biz_sorry_no_result' /* 抱歉，没有找到相关内容 */)
     }}</div>
</template>
<script>
export default {
  name: 'EmptyData',
  props: {
    taskName: {
      type: String,
      default: ''
    }
  }
};
</script>
<style lang="scss" scoped>
.yxtbiz-ai-robot-empty-data {
  padding-left: 12px;
  color: #262626;
}
</style>