var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "yxt-select",
    {
      staticStyle: { width: "100%" },
      attrs: {
        size: _vm.size,
        placeholder: _vm.getPlaceholder,
        disabled: _vm.disabled,
        clearable: _vm.clearable,
      },
      on: { change: _vm.changeHandler },
      model: {
        value: _vm.orgId,
        callback: function ($$v) {
          _vm.orgId = $$v
        },
        expression: "orgId",
      },
    },
    _vm._l(_vm.options, function (item) {
      return _c("yxt-option", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: item.showed,
            expression: "item.showed",
          },
        ],
        key: item.orgId,
        attrs: {
          disabled: item.disabled,
          label: item.orgName,
          value: item.orgId,
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }