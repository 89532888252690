var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "yxtbiz-mport-proc" }, [
    _c("ul", { staticClass: "yxt-upload-explain-list" }, [
      _c(
        "li",
        [
          _c("span", { staticClass: "yxt-upload-explain-num color-gray-8" }, [
            _vm._v("1"),
          ]),
          _c(
            "div",
            {
              staticClass:
                "standard-size-14 color-gray-10 yxt-upload-explain-title",
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      "pc_biz_import_tip_clickdownload" /* 点击“下载”按钮下载需要导入的模板文件。 */
                    )
                  ) +
                  "\n      "
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "yxt-upload-explain-summary color-gray-8" },
            [
              _c("span", { staticClass: "color-red-6" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "pc_biz_import_tip_readme" /* 请仔细阅读模板中的说明， */
                    )
                  )
                ),
              ]),
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "pc_biz_import_tip_improtformat" /* 并按要求格式填写导入的数据。 */
                  )
                ) + "\n      "
              ),
            ]
          ),
          _c(
            "yxt-button",
            {
              staticClass: "mt16",
              attrs: { type: "primary" },
              on: { click: _vm.downloadExcel },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("pc_biz_import_btn_download" /* 下载 */)) +
                  "\n      "
              ),
            ]
          ),
        ],
        1
      ),
      _c("li", [
        _c("span", { staticClass: "yxt-upload-explain-num color-gray-8" }, [
          _vm._v("2"),
        ]),
        _c(
          "div",
          {
            staticClass:
              "standard-size-14 color-gray-10 yxt-upload-explain-title",
          },
          [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.$t(
                    "pc_biz_import_tip_uploadmethod" /* 完成文件的填写后，点击或将文件拖拽到上传区域进行上传。 */
                  )
                ) +
                "\n      "
            ),
          ]
        ),
        _c("div", { staticClass: "yxt-upload-explain-summary color-gray-8" }, [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm.$t(
                  "pc_biz_import_tip_uploadverify" /* 系统会校验填写数据的正确性，并提示错误的数据；在校验无错误数据的情况下，系统将数据导入到系统中。 */
                )
              ) +
              "\n      "
          ),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "yxt-upload-container" },
      [
        _vm.stateType < _vm.importState.uploaded
          ? _vm._t("upload")
          : _vm.stateType === _vm.importState.uploaded && _vm.$slots.uploaded
          ? [_vm._t("uploaded")]
          : _c(
              "ul",
              { staticClass: "yxt-upload-item-list" },
              [
                _c(
                  "li",
                  { staticClass: "is-completed color-primary-6" },
                  [
                    _c("span", { staticClass: "yxt-upload-status" }),
                    _c(
                      "yxt-row",
                      {
                        staticClass: "color-gray-10 yxt-upload-item-title",
                        attrs: { type: "flex", align: "middle" },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.file && _vm.file.name) +
                            "\n          "
                        ),
                        _vm.stateType !== _vm.importState.verifying
                          ? _c(
                              "yxt-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.reset },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.stateType ===
                                        _vm.importState.completed &&
                                        (_vm.$slots.success ||
                                          (_vm.completeData &&
                                            _vm.completeData.isSuccess))
                                        ? _vm.$t(
                                            "pc_biz_import_btn_continueupload" /* 继续上传 */
                                          )
                                        : _vm.$t(
                                            "pc_biz_import_btn_uploadagain" /* 重新上传 */
                                          )
                                    ) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "yxt-row",
                      {
                        staticClass: "standard-size-12 lh20 mt4",
                        attrs: { type: "flex", align: "middle" },
                      },
                      [
                        _c(
                          "yxt-link",
                          { attrs: { underline: false, type: "success" } },
                          [_c("i", { staticClass: "yxt-icon-success" })]
                        ),
                        _c("span", { staticClass: "color-gray-8 ml3" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "pc_biz_import_tip_uploadcompleted" /* 上传成功 */
                              )
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.stateType === _vm.importState.verifying
                  ? _c(
                      "li",
                      { staticClass: "is-active color-primary-6" },
                      [
                        _c("span", { staticClass: "yxt-upload-status" }),
                        _c(
                          "div",
                          {
                            staticClass: "color-gray-10 yxt-upload-item-title",
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t(
                                    "pc_biz_import_tip_verifyingformat" /* 正在检验数据格式 */
                                  )
                                ) +
                                "\n        "
                            ),
                          ]
                        ),
                        _c(
                          "yxt-row",
                          {
                            staticClass: "standard-size-12 lh20 mt4",
                            attrs: { type: "flex", align: "middle" },
                          },
                          [
                            _c("span", { staticClass: "color-gray-8 ml16" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "pc_biz_import_tip_verifying" /* 检查中... */
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm.stateType === _vm.importState.completed
                  ? [
                      _c(
                        "li",
                        {
                          staticClass: "color-primary-6",
                          class: _vm.isSuccess ? "is-completed" : "is-active",
                        },
                        [
                          _c("span", { staticClass: "yxt-upload-status" }),
                          _vm.$slots.error
                            ? [_vm._t("error")]
                            : _vm.completeData && !_vm.completeData.isSuccess
                            ? [
                                _c(
                                  "yxt-row",
                                  {
                                    staticClass:
                                      "color-gray-10 yxt-upload-item-title",
                                    attrs: { type: "flex", align: "middle" },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.completeData.title) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _c(
                                  "yxt-row",
                                  {
                                    staticClass: "standard-size-12 lh20 mt4",
                                    attrs: { type: "flex", align: "middle" },
                                  },
                                  [
                                    _c(
                                      "yxt-link",
                                      {
                                        attrs: {
                                          underline: false,
                                          type: "danger",
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "yxt-icon-error",
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "color-gray-8 ml3" },
                                      [_vm._v(_vm._s(_vm.completeData.message))]
                                    ),
                                    _vm.completeData.linkHandler ||
                                    _vm.completeData.linkUrl
                                      ? _c(
                                          "yxt-link",
                                          {
                                            staticClass: "standard-size-12 ml8",
                                            attrs: {
                                              type: "primary",
                                              underline: false,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.linkHandler(
                                                  _vm.completeData
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.completeData
                                                    .linkMessage ||
                                                    _vm.$t(
                                                      "pc_biz_import_btn_downloaderrorfile" /* 下载失败数据 */
                                                    )
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            : [
                                _c(
                                  "yxt-row",
                                  {
                                    staticClass:
                                      "color-gray-10 yxt-upload-item-title",
                                    attrs: { type: "flex", align: "middle" },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            "pc_biz_import_tip_verifyfile" /* 检查文件 */
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _c(
                                  "yxt-row",
                                  {
                                    staticClass: "standard-size-12 lh20 mt4",
                                    attrs: { type: "flex", align: "middle" },
                                  },
                                  [
                                    _c(
                                      "yxt-link",
                                      {
                                        attrs: {
                                          underline: false,
                                          type: "success",
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "yxt-icon-success",
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "color-gray-8 ml3" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "pc_biz_import_tip_verifysuccess" /* 检查通过 */
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                        ],
                        2
                      ),
                      _vm.isSuccess
                        ? _c(
                            "li",
                            { staticClass: "is-completed color-primary-6" },
                            [
                              _c("span", { staticClass: "yxt-upload-status" }),
                              _vm.$slots.success
                                ? [_vm._t("success")]
                                : _vm.completeData && _vm.completeData.isSuccess
                                ? [
                                    _c(
                                      "yxt-row",
                                      {
                                        staticClass:
                                          "color-gray-10 yxt-upload-item-title",
                                        attrs: {
                                          type: "flex",
                                          align: "middle",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(_vm.completeData.title) +
                                            "\n            "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "yxt-row",
                                      {
                                        staticClass:
                                          "standard-size-12 lh20 mt4",
                                        attrs: {
                                          type: "flex",
                                          align: "middle",
                                        },
                                      },
                                      [
                                        _c(
                                          "yxt-link",
                                          {
                                            attrs: {
                                              underline: false,
                                              type: "success",
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "yxt-icon-success",
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "color-gray-8 ml3" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "pc_biz_import_tip_importsuccess" /* 导入成功 */
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm.completeData.linkHandler ||
                                        _vm.completeData.linkUrl
                                          ? _c(
                                              "yxt-link",
                                              {
                                                staticClass:
                                                  "standard-size-12 ml8",
                                                attrs: {
                                                  type: "primary",
                                                  underline: false,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.linkHandler(
                                                      _vm.completeData
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      _vm.completeData
                                                        .linkMessage ||
                                                        _vm.$t(
                                                          "pc_biz_import_btn_downloadfile" /* 下载数据 */
                                                        )
                                                    ) +
                                                    "\n              "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                : [
                                    _c(
                                      "yxt-row",
                                      {
                                        staticClass:
                                          "color-gray-10 yxt-upload-item-title",
                                        attrs: {
                                          type: "flex",
                                          align: "middle",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$t(
                                                "pc_biz_import_tip_importfinish" /* 导入完成 */
                                              )
                                            ) +
                                            "\n            "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "yxt-row",
                                      {
                                        staticClass:
                                          "standard-size-12 lh20 mt4",
                                        attrs: {
                                          type: "flex",
                                          align: "middle",
                                        },
                                      },
                                      [
                                        _c(
                                          "yxt-link",
                                          {
                                            attrs: {
                                              underline: false,
                                              type: "success",
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "yxt-icon-success",
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "color-gray-8 ml3" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "pc_biz_import_tip_importsuccess" /* 导入成功 */
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                            ],
                            2
                          )
                        : _c("li", { staticClass: "color-primary-6" }, [
                            _c("span", { staticClass: "yxt-upload-status" }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "color-gray-8 yxt-upload-item-title",
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t(
                                        "pc_biz_import_tip_importdata" /* 导入数据 */
                                      )
                                    ) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ]),
                    ]
                  : _vm._e(),
              ],
              2
            ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }