var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "currentEle",
      staticClass: "yxtbiz-user-group-tree",
      class: { "yxtbiz-dept-tree--autosize": _vm.autosize },
    },
    [
      _vm.enableGroupCorp && _vm.visibleOrgSelector
        ? _c("yxtbiz-group-org-select", {
            staticClass: "mr12 mb12",
            staticStyle: { width: "100%" },
            attrs: {
              setCurrentOrg: true,
              clearable: false,
              selectFirst: true,
              functionCode: _vm.functionCode,
              dataPermissionCode: _vm.dataPermissionCode,
            },
            on: { change: _vm.handleOrgChange },
          })
        : _vm._e(),
      _vm.filterable
        ? _c("yxt-input", {
            ref: "input",
            staticClass: "yxtbiz-dept-tree__input",
            style: { width: _vm.wrapWidth + "px" },
            attrs: {
              placeholder: _vm.placeholder,
              size: _vm.size,
              searchable: "",
            },
            on: { search: _vm.filter },
            model: {
              value: _vm.filterText,
              callback: function ($$v) {
                _vm.filterText = $$v
              },
              expression: "filterText",
            },
          })
        : _vm._e(),
      _c("yxtbiz-funs-tree", {
        ref: "tree",
        attrs: {
          props: _vm.props,
          "highlight-current": _vm.highlightCurrent,
          data: _vm.data,
          "node-key": "id",
          "wrap-width": _vm.wrapWidth,
          "check-strictly": _vm.checkStrictly,
          "show-checkbox": _vm.showCheckbox,
          "default-expanded-keys": _vm.expandTempShow,
          functions: _vm.functions,
          functionsRender: _vm.functionsRender,
          "filter-node-method": _vm.filterNode,
          native: _vm.native,
          dropdownToBody: _vm.dropdownToBody,
        },
        on: {
          "node-click": _vm.nodeClick,
          "node-expand": _vm.nodeExpand,
          "node-collapse": _vm.nodeCollapse,
          check: _vm.handlerCheck,
          "check-change": _vm.handleCheckChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }