<template>
  <div class="yxtbiz-nav-breadcrumb" :class="{'yxtbiz-nav-breadcrumb__containner': padding}">
    <yxtf-breadcrumb separator-class="yxt-icon-arrow-right" v-if="type === 1">
      <yxtf-breadcrumb-item v-for="(cnav, index) in cnavs" v-show="index != 0 || topNav"
                            :key="index"
                            class="hand"
                            isLink
                            @click.native="toPage(cnav)">{{cnav | Globalize("name")}}</yxtf-breadcrumb-item>
    </yxtf-breadcrumb>
    <yxt-breadcrumb separator-class="yxt-icon-arrow-right" v-if="type === 2">
      <yxt-breadcrumb-item v-for="(cnav, index) in cnavs" v-show="index != 0 || topNav"
                            :key="index"
                            class="hand"
                            isLink
                            @click.native="toPage(cnav)">{{cnav | Globalize("name")}}</yxt-breadcrumb-item>
    </yxt-breadcrumb>
  </div>
</template>

<script>
import { Globalize, goNavPage } from './common';
import {
  Breadcrumb,
  BreadcrumbItem,
  FrontBreadcrumb,
  FrontBreadcrumbItem
} from 'yxt-pc';
export default {
  name: 'YxtbizNavBreadcrumb',
  components: {
    YxtBreadcrumbItem: BreadcrumbItem,
    YxtBreadcrumb: Breadcrumb,
    YxtfBreadcrumb: FrontBreadcrumb,
    YxtfBreadcrumbItem: FrontBreadcrumbItem
  },
  props: {
    // 追加的菜单
    appendMenus: {
      type: Array,
      default: () => { return []; }
    },
    // 类型 1：pc学员端，2pc管理员
    type: {
      type: Number,
      default: 2
    },
    // 是否包含间距
    padding: {
      type: Boolean,
      default: true
    },
    // 是否包含一级导航
    topNav: {
      type: Boolean,
      default: false
    },
    mainPathname: {
      type: String,
      default: ''
    }
  },
  computed: {
    cnavs() {
      let navsAll = [...this.$store.state.navManageStore.cnavs];
      if (this.appendMenus && this.appendMenus.length > 0) {
        this.appendMenus.forEach(element => {
          navsAll.push(element);
        });
      }
      return navsAll;
    }
  },
  methods: {
    toPage(page) {
      if (page.id) {
        // 正常的导航
        let goPage = page;
        if (!page.pageUrl) {
          if (page.subMenuInfoList && page.subMenuInfoList.length > 0) {
            goPage = page.subMenuInfoList[0];
          } else {
            return;
          }
        }
        goNavPage(goPage, this.mainPathname, this);
      } else if (page.pageUrl) {
        // 自定义
        location.href = page.pageUrl;
      }
    }
  },
  filters: {
    Globalize(data, key) {
      return Globalize(data, key);
    }
  }
};
</script>
