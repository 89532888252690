import { udpApi } from 'yxt-biz-pc/packages/api';

/**
 * 获取用户组分类树
 * @param query
 * @return {*}
 */
export const getUserGroupCatalogsTree = (query) => {
  return udpApi.get('usergroupcatalogs/tree', {params: query});
};
