<!-- 我的收藏 -->
<template>
  <article class="yxtbiz-ai-robot-my-favorite">
    <EmptyData v-if="dataList.length === 0" />
    <template v-else>
      <header
        :class="[
          'color-gray-7',
          size === 'small' ? 'font-size-14 lh24' : 'font-size-16 lh28'
        ]"
      >
        {{
          $t('yxtbiz_ai_lbl_followingfavorites' /* 为您找到以下{0}收藏 */, [
            filterTitle
          ])
        }}
      </header>
      <main>
        <ul>
          <li
            v-for="item in dataList"
            :key="item.id"
            class="yxtbiz-ai-robot-my-favorite__item"
          >
            <components
              :is="componentName"
              :ref="componentName"
              :params="params"
              :size="size"
              :data="item"
            />
          </li>
        </ul>
        <ViewMoreButton v-if="count > 3" @click="handleViewMoreClick" />
      </main>
    </template>
  </article>
</template>
  
  <script>
import projectItem from './components/projectItem.vue';
import knglibItem from './components/knglibItem.vue';
import kngItem from './components/kngItem.vue';
import bbsItem from './components/bbsItem.vue';
import discussItem from './components/discussItem.vue';
import ViewMoreButton from '../common/view-more-button';
import EmptyData from '../common/empty-data.vue';
import utils from 'yxt-biz-pc/packages/common-util/utils.js';
import propsMixin from '../common/props-mixin.js';

export default {
  name: 'MyFavorite',
  components: {
    ViewMoreButton,
    EmptyData,
    projectItem,
    knglibItem,
    kngItem,
    bbsItem,
    discussItem
  },
  mixins: [propsMixin],
  created() {
    this.params.collectType += '';
  },
  computed: {
    count() {
      const paging = (this.datas && this.datas.data.paging) || {};
      return paging.count;
    },
    filterTitle() {
      const aMap = {
        '1': this.$t('yxtbiz_ai_lbl_course' /* 课程 */),
        '2': this.$t('yxtbiz_ai_lbl_coursebbs' /* 课程中的讨论 */),
        '3': this.$t('yxtbiz_ai_lbl_kng' /* 知识 */),
        '4': this.$t('yxtbiz_ai_lbl_o2o' /* 培训项目 */),
        '5': this.$t('yxtbiz_ai_lbl_o2obbs' /* 项目讨论 */),
        '6': this.$t('yxtbiz_ai_lbl_bbs' /* 社区 */)
      };
      return aMap[this.params.collectType];
    },
    componentName() {
      const aMap = {
        '1': 'kngItem',
        '2': 'discussItem',
        '3': 'knglibItem',
        '4': 'projectItem',
        '5': 'discussItem',
        '6': 'bbsItem'
      };
      return aMap[this.params.collectType];
    },
    dataList() {
      return (this.datas && this.datas.data.datas) || [];
    }
  },
  methods: {
    getDateTimeFormatted: utils.getDateTimeFormatted,
    handleViewMoreClick() {
      const aMap = {
        '1': '/utility/#/collect/kng',
        '2': '/utility/#/collect/kng?type=1',
        '3': '/utility/#/collect/knglib',
        '4': '/utility/#/collect/project',
        '5': '/utility/#/collect/project?type=1',
        '6': '/utility/#/collect/bbs'
      };
      const url = aMap[this.params.collectType];
      window.open(`${window.location.origin + url}`, '_blank');
    }
  }
};
</script>
  
  <style lang="scss" scoped>
.yxtbiz-ai-robot-my-favorite {
  &__cover {
    width: 112px;
    height: 64px;
    border-radius: 4px;
    flex-shrink: 0;
  }

  > header {
    padding-left: 12px;
    margin-bottom: 12px;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 12px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background: #f4f5f6;
    }
  }

  &__item-tag {
    vertical-align: text-top;
  }
}
</style>