var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "contents" }, [
    _c(
      "ul",
      _vm._l(_vm.data, function (d, index) {
        return _c("li", { key: index }, [
          _c(
            "p",
            {
              on: {
                click: function ($event) {
                  return _vm.handleClick(d)
                },
              },
            },
            [
              _c("i", { class: _vm.showIcon(d) }),
              _vm._v(_vm._s(d.name || d.title)),
            ]
          ),
          _c(
            "div",
            { staticClass: "content-desc" },
            [
              d.bodyType === 0 || _vm.type === "hotIssues"
                ? [
                    _vm.active === d.id
                      ? _c("div", {
                          staticClass: "desc",
                          domProps: {
                            innerHTML: _vm._s(_vm.displaySummary(d)),
                          },
                        })
                      : _vm._e(),
                    _vm.active === d.id && d.summary && d.summary.length > 150
                      ? _c(
                          "yxt-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleShowDetail(d)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        )
                      : _vm._e(),
                  ]
                : [
                    _vm.active === d.id
                      ? _c("div", { staticClass: "video-cover" }, [
                          _c("h2", [_vm._v(_vm._s(d.name || d.title))]),
                          _c(
                            "button",
                            {
                              staticClass: "play-button",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleShowVideo(d)
                                },
                              },
                            },
                            [_vm._v("立即观看")]
                          ),
                        ])
                      : _vm._e(),
                  ],
            ],
            2
          ),
        ])
      }),
      0
    ),
    _vm.showVideo
      ? _c("div", { staticClass: "video-wrap" }, [
          _c(
            "div",
            { staticClass: "video-play-wrap" },
            [
              _c("i", {
                staticClass: "yxt-icon-circle-close",
                on: { click: _vm.handleCloseVideo },
              }),
              _vm.videoObjects.length > 0
                ? _c("yxtbiz-video", {
                    ref: "video",
                    attrs: {
                      options: _vm.videoObjects,
                      width: "640",
                      height: "359.62",
                      playRate: "",
                    },
                    on: {
                      onBeforePlay: _vm.onBeforePlay,
                      onQualityChange: _vm.onQualityChange,
                      onTime: _vm.onTime,
                      onError: _vm.onError,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }