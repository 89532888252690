<!-- 创建时间2023/11/17 13:43:08 -->
<!-- 创建人：Zhao bing bing -->
<!-- 组件描述：上传课件-->
<template>
  <KngRelated type="createCourseware" v-bind="$attrs" v-on="$listeners"/>
</template>

<script>
import KngRelated from '../common/kng/kng-releated.vue';
export default {
  name: 'KngCreateCourseware',
  components: {
    KngRelated
  }
};
</script>