<template>
  <div ref="content" 
  draggable="true"
  @dragstart="dragstart"
  @dragend="dragend"
  v-if="sourceList.length && !isAsyncNoticeClosed"
  :class="['yxtbiz-async-import-list right', slideup ? '' : 'slideup']" :style="`right: ${styleObj.right};bottom:${styleObj.bottom}`">
    <div class="yxtbiz-async-import-list-header">
      {{$t('pc_biz_data_process').d('数据处理')}}<span>{{completeLen}}/{{sourceList.length}}</span>
      <div v-if="done" class="yxtbiz-async-import-list-done">
        <!-- <i class="yxt-icon-success yxt-color-success async-import-icon"/> -->
        <yxtf-svg width="18px" height="18px" icon-class="ok-facial" class="yxt-color-success"/>
        <span>{{$t('pc_biz_shoptour_lbl_finished').d('已完成')}}</span>
      </div>
      <div class="yxtbiz-async-import-list-header-icon-box">
        <i v-if="!slideup" @click="()=> slideup = true" class="yxt-icon-full-screen"></i>
        <i v-if="slideup" @click="()=> slideup = false" class="yxt-icon-slide-screen"></i>
        <i @click="closeList" class="yxt-icon-close"></i>
      </div>
    </div>
    <div v-if="slideup" class="yxtbiz-async-import-list-info">
      <ul>
        <li v-for="(item, index) in sourceList" :key="index">
          <yxt-tooltip class="item" :open-filter="true" :content="item.taskName" placement="top">
            <p>{{item.taskName}}</p>
          </yxt-tooltip>
          <div class="yxtbiz-async-import-list-progress">
            <div class="yxtbiz-async-import-list-progress-percent"><span :style="{width: item.progress + '%'}"></span></div>
            <div class="yxtbiz-async-import-list-progress-des">
              <b v-if="item.progress !== 100 && !item.ifError">{{item.progress}}%</b>
              <div v-else-if="item.ifError">
                <!-- <i class="yxt-icon-error yxt-color-success async-import-icon"/> -->
                <yxtf-svg width="16px" height="16px" icon-class="delete-facial" class="yxt-color-danger"/>
                <span>{{$t('pc_fail').d('失败')}}</span>
              </div>
              <div v-else>
                <!-- <i class="yxt-icon-success yxt-color-success async-import-icon"/> -->
                <yxtf-svg width="18px" height="18px" icon-class="ok-facial" class="yxt-color-success"/>
                <span>{{$t('pc_biz_shoptour_lbl_finished').d('已完成')}}</span>
              </div>
            </div>
          </div>
          <div v-if="item.ifError && item.errorMsg" class="yxtbiz-async-import-list-complete">
            <span><b class="fail-len">{{item.errorMsg}}</b></span>
          </div>
          <div v-if="item.progress === 100 && item.finish === 1 && (item.successNum || item.failedNum)" class="yxtbiz-async-import-list-complete">
            <span v-if="item.successNum"><b class="sucess-len">{{item.successNum}}</b>{{$t('pc_biz_unit_lines').d('条')}}{{$t('pc_biz_import').d('导入')}}{{$t('pc_success').d('成功')}}</span>
            <span v-if="item.failedNum && item.successNum">，</span>
            <span v-if="item.failedNum">
              <b class="fail-len">{{item.failedNum}}</b>{{$t('pc_biz_unit_lines').d('条')}}{{$t('pc_biz_import').d('导入')}}{{$t('pc_fail').d('失败')}}
              </span>
            <span @click="downLoadFailData(item)" v-if="item.failedNum" class="download-fail-data">{{$t('pc_biz_download_fail_data').d('下载失败数据')}}</span>
          </div>
        </li>
      </ul>
      <!-- 根据文档第8条，隐藏 查看全部按钮  文档地址：  https://alidocs.dingtalk.com/i/nodes/KOEmgBoGwD78vlKPlkq7VndLerP9b30a?cid=51680755396&iframeQuery=utm_medium%3Dim_card%26utm_source%3Dim&utm_medium=im_group_card&utm_source=im&dontjump=true&corpId=ding23a92d4eb3bf631f   -->
      <!-- <div @click="toDownload" class="yxtbiz-async-import-more-list">{{$t('pc_biz_check_more' /* 查看全部 */)}}</div> -->
    </div>
  </div>
</template>

<script>
import WebSocketClass from './websocket.js';
import { apiBaseUrl } from './domain.js';
import { checkToken, closeList} from './service';
window.getLocalStorage = function(key) {
  let value;
  try {
    value = localStorage.getItem(key);
  } catch (n) {
    value = window.getCookie(key);
  };
  return value;
};

const wsUrl = `${apiBaseUrl('wsBaseUrl')}` || '';
if (!wsUrl) {
  console.error('未获取到 socket url');
}
let wsCbIndex = 0;
export default {
  name: 'YxtbizAsyncImportList',
  props: {
    limit: {
      type: Number,
      default: 2
    },
    toDownloadCenter: {
      type: Boolean,
      default: false
    },
    onNoticeClosed: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      done: false,
      slideup: false,
      isAsyncNoticeClosed: false,
      // 资源列表websocket信息
      WSInfo: {
        wsUrl: `${wsUrl}websocketsvc`,
        moudleName: 'progress',
        project: 'auEng',
        id: window.localStorage.userId
      },
      token: window.getLocalStorage('token') || '',
      wsInstance: null,
      sourceList: [],
      styleObj: {
        bottom: '16px',
        right: '30px'
      },
      dragging: false,
      startX: 0,
      startY: 0
    };
  },
  computed: {
    // 已完成的数量
    completeLen() {
      return this.sourceList.filter(item => item.progress === 100).length;
    }
  },
  created() {
    checkToken().then(res => {
      if (res.isValid) {
        this.getNoticeOpenState();
      } else {
        console.log('token 无效 当前非2.0登录模式');
      }
    });
  },
  methods: {
    dragstart(v) {
      // console.log(1, v);
      this.startX = v.offsetX;
      this.startY = v.offsetY;
    },
    dragend(v) {
      const x = v.offsetX - this.startX;
      const y = v.offsetY - this.startY;
      const right = parseFloat(this.styleObj.right.slice(0, -2)) - x;
      const bottom = parseFloat(this.styleObj.bottom.slice(0, -2)) - y;
      // 侧边栏导航宽度
      const sideWidth = document.getElementsByClassName('yxtbiz-nav-main__side')[0] ? document.getElementsByClassName('yxtbiz-nav-main__side')[0].clientWidth : 0;
      // 顶部导航高度
      const topWidth = document.getElementsByClassName('yxtbiz-nav-main-top')[0] ? document.getElementsByClassName('yxtbiz-nav-top')[0].clientHeight : 0;
      // console.log('right:' + right + v.target.clientWidth);
      // console.log('bottom:' + bottom + v.target.clientHeight);
      // 不允许拖拽到导航区域
      if (right + v.target.clientWidth < document.body.clientWidth - sideWidth - 20 && bottom + v.target.clientHeight < document.body.clientHeight - topWidth - 20 && right > 0 && bottom > 0) {
        this.styleObj.right = right + 'px';
        this.styleObj.bottom = bottom + 'px';
      }
      // console.log(3, v);
    },
    // 建立ws连接并获取当前列表数据
    getNoticeOpenState() {
      // 获取本地缓存是否已点击关闭此通知
      let isAsyncNoticeClosed = window.getLocalStorage('isAsyncNoticeClosed') === 'true';
      this.isAsyncNoticeClosed = isAsyncNoticeClosed;
      if (isAsyncNoticeClosed) {
        console.log('提示关闭 不调用接口');
      } else {
        console.log('提示开启 调用异步列表组件');
        this.createWebsocket();
      }
      window.resetAsyneImportListNoticeState = () => {
        console.log('resetAsyneImportListNoticeState 全局方法被调用');
        if (window.localStorage.getItem('isAsyncNoticeClosed') === 'true') {
          this.setNoticeOpenState(true);
          this.createWebsocket();
        }
      };
    },
    setNoticeOpenState(flag) {
      this.isAsyncNoticeClosed = !flag;
      window.localStorage && window.localStorage.setItem('isAsyncNoticeClosed', !flag);
    },
    // ws消息回调
    wsCallback(res) {
      if (!this.wsInstance) return;
      // dataType:1进度,0 创建,2 全量数据
      let data = this.getWsData(res);
      console.error(`--------2.第${wsCbIndex}次ws回调返回数据---------`);
      console.info(data);
      let hasChanged = false; // 是否发生数据更新
      if (data && Array.isArray(data) && data.length) {
        for (let i = 0; i < data.length; i++) {
          const item = data[i];
          // if (!item || !item.hasOwnProperty('filePath')) break; // 非有效数据忽略
          item.progress = item.progress || 0;
          // i % 2 && (item.ifError = 1);
          // item.errorMsg = '错误原因:服务器繁忙，请稍后重试';
          item.progress > 100 && (item.progress = 100);
          let index = this.sourceList.findIndex(i => i.taskId === item.taskId);
          // 新增数据则插入到第一条
          if (index < 0) {
            hasChanged = true;
            if (item.dataType === 2) {
              this.sourceList.push(item); // 全量数据直接插入最后
            } else {
              this.sourceList = [item, ...this.sourceList]; // 新增数据插入最前
            }
          } else if (item.progress >= this.sourceList[index].progress) {
            // 已有数据进度发生变化则更新当条数据
            hasChanged = true;
            this.$set(this.sourceList, index, item);
          }
        }
        // window.localStorage && window.localStorage.setItem('asyncImportList', JSON.stringify(this.sourceList));
        console.log(`数据是否发生变化：${hasChanged}`);
      } else {
        console.log('------未接收到有效数据 -----');
      }
      this.$nextTick(()=>{
        // console.log(this.sourceList);
        // if (wsCbIndex++ === 1 && this.sourceList.length) this.showPop();
        this.isDone();
      });
    },
    // 获取正确的ws回调数据格式
    getWsData(res) {
      let wsRes = res;
      try {
        wsRes = JSON.parse(wsRes);
        return this.jsonParse(wsRes.data);
      } catch (error) {
        return;
      }
    },
    // json格式转换
    jsonParse(data) {
      try {
        if (data && typeof data === 'object') {
          return data;
        } else {
          return JSON.parse(data);
        }
      } catch (e) {
        return null;
      }
    },
    /*
      moudleName:模块标识
      WSCb:成功后的回调，
      project:项目标识
    */
    // 初始化资源列表websocket实例
    createWebsocket(moudleName = this.WSInfo.moudleName, WSCb = this.wsCallback, project = this.WSInfo.project, id = this.WSInfo.id) {
      this.destoryWebsocket();
      let wsInstance = new WebSocketClass(this.WSInfo.wsUrl, WSCb, `${project}_${moudleName}`);
      wsInstance.connect({
        jwt: this.token,
        project,
        module: moudleName,
        id: id
      });
      console.error('--------1.建立全局异步上传组件的websocket连接---------');
      this.wsInstance = wsInstance;
      window.wsInstanceAsync = wsInstance;
    },
    // 关闭websocket
    destoryWebsocket() {
      try {
        if (!this.wsInstance) return;
        this.wsInstance.closeMyself();
        this.wsInstance = null;
        wsCbIndex = 0;
        window.wsInstanceAsync = null;
        console.error('------3.成功销毁全局异步上传ws实例---------');
        console.log(`this.wsinstance:${this.wsInstance},window.wsInstanceAsync:${window.wsInstanceAsync}`);
      } catch (e) {
        console.error(e);
        console.error('------3.销毁全局异步上传ws实例失败---------');
      }
    },
    closeList() {
      // 有新任务发起时弹框会自动打开
      this.$confirm(this.$t('pc_biz_close_then_tips').d('有新任务发起时弹框会自动打开'), this.$t('pc_biz_confirm_close'/* 确认关闭吗 */) + '?', {
        confirmButtonText: this.$t('pc_biz_common_btn_sure').d('确定'),
        cancelButtonText: this.$t('pc_biz_common_cancel_mini').d('取消'),
        type: 'warning'
      }).then(() => {
        this.toDownloadCenter && this.toDownload();
        this.setNoticeOpenState(false); // 设置开启状态
        this.onNoticeClosed(); // 关闭回调
        this.destoryWebsocket(); // 关闭ws通道
        this.removePopEle(); // 删除notification dom
        this.closeFinished(); // 关闭已完成的数据
        this.initData();
        // window.localStorage && window.localStorage.removeItem('asyncImportList'); // 删除本地缓存
      });
    },
    async closeFinished() {
      try {
        await closeList();
      } catch (error) {
        console.error(error);
      }
    },
    downLoadFailData(item) {
      window.open(item.filePath, '_blank');
    },
    toDownload() {
      let href = window.location.origin + '/down/#/download';
      window.open(href, '_blank');
    },
    // 借用notification组件自动定位的能力来找到dom的定位，实际notification组件在页面上无感知,兼容部分浏览器下css样式无效的场景
    showPop() {
      let ele = document.querySelector('#async-import-list-notify');
      if (ele) return;
      let notifyEle = this.$notify({
        title: 'pc_biz_data_process' /* 数据处理 */,
        duration: 0, // 不自动消失
        position: 'bottom-right',
        dangerouslyUseHTMLString: true // 内容以html片段展示
      });
      notifyEle.$el.id = 'async-import-list-notify';
      ele = document.querySelector('#async-import-list-notify');
      setTimeout(() => {
        this.styleObj.bottom = window.getComputedStyle(ele).bottom;;
        ele.style.zIndex = -1;
      });
    },
    initData() {
      this.done = false;
      this.slideup = false;
      this.isAsyncNoticeClosed = false;
      this.wsInstance = null;
      window.wsInstanceAsync = null;
      this.sourceList = [];
      this.styleObj = {
        bottom: '16px',
        right: '30px'
      };
    },
    isDone() {
      this.done = this.sourceList.every(item => item.progress === 100);
      // this.done ? this.slideup = false : this.done = true;
    },
    removePopEle() {
      try {
        document.body.removeChild(document.querySelector('#async-import-list-notify'));
      } catch (error) {}
    }
  },
  beforeDestroy() {
    this.destoryWebsocket();
    this.removePopEle();
  }
};
</script>
