import { msgApi } from 'yxt-biz-pc/packages/api';

/*
channels	渠道列表		false array
  appCode	App推送的渠道专用		false string
  channelCont	渠道内容		false string
  channelContKey	渠道内容国际化key		false string
  channelType	渠道类型，PC，H5，DD_ISV ,SMS, APP_PUSH		false string
code	模板编号		true string
targetId	项目id		true string
title	模板标题, 存中文下的翻译		true string
titleKey	标题国际化key		true string
*/

// https://api-msg-phx.yunxuetang.com.cn/v2/doc.html#/msgapi/%E4%B8%AA%E6%80%A7%E6%A8%A1%E6%9D%BF/addInterCustomTemplateMapUsingPOST
// 新增/编辑个性模板内容
export const saveCustomTemplateSvc = data => {
  return msgApi.post('custom/inter', data);
};
// 获取客户端的开通的消息通道
export const getCusTemp = (orgId, temCode) => {
  return msgApi.post(`custom/config/${orgId}/${temCode}`);
};

// https://api-msg-phx-feat.yunxuetang.com.cn/v2/doc.html#/msgapi/%E4%B8%AA%E6%80%A7%E6%A8%A1%E6%9D%BF/getInterTemplateListUsingGET
// 获取个性模板详情 - 支持国际化
export const getCustomTemplateSvc = (code, targetId, designId) => {
  // return {
  //   appIm: 1,
  //   apppush: 1,
  //   box: 0,
  //   channels: [
  //     {
  //       appCode: '',
  //       channelCont: 'pc今天开始欢迎新同学',
  //       // channelContKey: 'keys.org.msg.customtemp.conpc.orgid.100',
  //       channelContKey: '',
  //       channelContTrans: { zh: 'pc今天开始欢迎新同学', ha: '繁体pc今天开始欢迎新同学', en: 'pc英文翻译内容' },
  //       channelType: 'PC',
  //       title: '新人培训课通知',
  //       titleKey: '',
  //       titleTrans: { zh: '新人培训课通知', ha: '繁体新人培训课通知', en: '英文翻译标题' }
  //     },
  //     {
  //       appCode: '',
  //       channelCont: 'h5今天开始欢迎新同学',
  //       // channelContKey: 'keys.org.msg.customtemp.conh5.orgid.100',
  //       channelContKey: '',
  //       channelContTrans: { zh: 'h5今天开始欢迎新同学', ha: '繁体h5今天开始欢迎新同学', en: 'h5英文翻译内容' },
  //       channelType: 'H5',
  //       title: '新人培训课通知',
  //       titleKey: 'keys.org.msg.customtemp.title.orgid.100',
  //       titleTrans: { zh: '新人培训课通知', ha: '繁体新人培训课通知', en: '英文翻译标题' }
  //     }
  //   ],
  //   ding: 1,
  //   feishu: 1,
  //   mail: 1,
  //   orgId: '',
  //   temCode: '',
  //   third: 1,
  //   wxcorp: 1
  // };

  return msgApi.get(`custom/inter/${code}/${targetId}?designId=${designId || ''}`);
};

/*
{
	"appIm": 0,
	"apppush": 0,
	"box": 0,
	"channels": [
		{
			"appCode": "",
			"channelCont": "",
			"channelContKey": "",
			"channelContTrans": {},
			"channelType": "PC", // 渠道类型，PC，H5，DD_ISV ,SMS, APP_PUSH
			"title": "",
			"titleKey": "",
			"titleTrans": {}
		}
	],
	"ding": 0,
	"feishu": 0,
	"mail": 0,
	"orgId": "",
	"temCode": "",
	"third": 0,
	"wxcorp": 0
}
*/
