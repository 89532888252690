<template>
  <div v-show="showElement" class='jw-icon jw-icon-tooltip jw-button-color jw-reset jw-ai'>
    <span :class='{"jw-ai-disabled": !enabled}' class='jw-icon-ai' @mouseleave='hideHandler' @mouseenter='hoverHandler'>
        {{ translation('pc_biz_video_lbl_subtitles') }}</span>
    <div ref="popover" v-show='showPopover' class='yxt-popover yxt-popper yxt-poper-arrow-flag jw-ai-popover is-dark'>
      <div @mouseenter='hoverHandler' @mouseleave='hideHandler'>
        <div class='jw-ai-enabled'>
          <yxt-switch @change="changeHandler('enableChange', arguments)" v-model='aiEnabled' class="mr8"></yxt-switch>
          {{ translation('pc_biz_video_lbl_subtitles') }}
        </div>
        <div class='jw-ai-setting'>
          <div class='jw-ai-set-title' v-if="showLan">{{ translation('pc_biz_video_lbl_subtitles_lan') }}</div>
          <yxt-radio-group v-if="showLan" @change="changeHandler('ai-lan-change', arguments)" v-model="textLan">
            <yxt-radio :label='item.value' :key="item.value" v-for='item in lanList'>{{ item.label }}</yxt-radio>
          </yxt-radio-group>
          <div class='jw-ai-set-title'>{{ translation('pc_biz_video_lbl_subtitles_size') }}</div>
          <yxt-radio-group @change="changeHandler('ai-text-size', arguments)" v-model="textSize">
            <yxt-radio :label='item.value' :key="item.value" v-for='item in textSizeList'>{{ item.label }}</yxt-radio>
          </yxt-radio-group>
          <div class='jw-ai-set-title'>{{ translation('pc_biz_video_lbl_bg_opacity') }}</div>
          <yxt-slider
            input-size='mini'
            @change="changeHandler('ai-text-opacity', arguments)"
            v-model='bgOpacity'
          ></yxt-slider>
          <div class='jw-ai-set-title'>{{ translation('pc_biz_video_lbl_drag') }}</div>
          <div class='jw-set-row-flex mt8'>
            <yxt-switch @change="changeHandler('drag-enable-change', arguments)" v-model='enableDrag' class="mr8"></yxt-switch>
            {{ translation('pc_biz_video_lbl_drag_setting') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { i18n } from 'yxt-biz-pc/packages/common-util/changeLangs';
export default {
  props: {
    enabled: {
      type: Boolean,
      default: true
    },
    hasContent: {
      type: Boolean,
      default: false
    },
    lan: {
      type: String,
      default: 'zh'
    },
    size: {
      type: String,
      default: 'medium',
      validate: function(value) {
        return ['large', 'medium', 'small'].includes(value);
      }
    },
    textDragEnable: {
      type: Boolean,
      default: false
    },
    showLan: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      aiEnabled: true,
      textLan: 'zh',
      textSize: 'medium',
      lanList: [{
        label: this.translation('pc_biz_video_lbl_subtitles_ch'),
        value: 'zh'
      }, {
        label: this.translation('pc_biz_video_lbl_subtitles_en'),
        value: 'en'
      }],
      textSizeList: [{
        label: this.translation('pc_biz_video_lbl_size_small'),
        value: 'small'
      }, {
        label: this.translation('pc_biz_video_lbl_size_medium'),
        value: 'medium'
      }, {
        label: this.translation('pc_biz_video_lbl_size_large'),
        value: 'large'
      }],
      bgOpacity: 60,
      enableDrag: false,
      showPopover: false,
      timer: null,
      player: null
    };
  },
  computed: {
    showElement() {
      return this.hasContent && this.enabled;
    }
  },
  methods: {
    translation(...args) {
      return i18n.t(...args);
    },
    hoverHandler() {
      if (!this.enabled) return;
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.showPopover = true;
      this.setPosition();
    },
    setPosition() {
      if (!this.player) return;
      this.$nextTick(() => {
        const popover = this.$refs.popover;
        const container = this.player.getContainer();
        const containerRect = container.getBoundingClientRect();
        const cRight = containerRect.right;
        const pRight = popover.getBoundingClientRect().right;

        if (cRight < pRight) {
        // 判断popover右边缘是否出界，调整位置
          const left = window.getComputedStyle(popover).left;
          popover.style.left = `${parseFloat(left) - (pRight - cRight)}px`;
        }
      });
    },
    setLan(lan) {
      this.textLan = lan;
      this.changeHandler('ai-lan-change', [lan]);
    },
    hideHandler() {
      if (!this.enabled) return;
      this.timer = setTimeout(() => {
        this.showPopover = false;
      }, 200);
    },
    changeHandler(name, args) {
      this.$emit(name, args[0]);
    }
  },
  watch: {
    showElement(v) {
      if (!v) {
        this.showPopover = false;
      }
    }
  }
};
</script>
