var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.navsFirst && _vm.navsFirst.length > 0
    ? _c(
        "div",
        {
          class: [
            "yxtbiz-nav-immersive",
            {
              "yxtbiz-nav-immersive--notop": !_vm.isShowTopNav,
              "yxtbiz-nav-immersive--visible": _vm.menusVisible,
            },
          ],
        },
        [
          _c(
            "yxt-layout",
            { attrs: { root: "" } },
            [
              _vm.isShowTopNav
                ? _c(
                    "yxt-layout-main",
                    { staticClass: "yxtbiz-nav-immersive__top" },
                    [
                      _c(
                        "yxt-layout-header",
                        { staticClass: "yxtbiz-nav-immersive__header" },
                        [
                          _vm.cnavs && _vm.cnavs.length >= 2
                            ? _c(
                                "div",
                                {
                                  ref: "menusBtn",
                                  staticClass: "yxtbiz-nav-immersive__menu-btn",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "yxtbiz-nav-immersive__menu-btn-icon",
                                      on: {
                                        mouseenter: function ($event) {
                                          return _vm.openMenus()
                                        },
                                        mouseleave: function ($event) {
                                          return _vm.hideMenus()
                                        },
                                      },
                                    },
                                    [
                                      _c("yxt-svg", {
                                        style: {
                                          transform: _vm.collapse
                                            ? "rotate(180deg)"
                                            : "",
                                        },
                                        attrs: {
                                          "icon-class": "all",
                                          width: "20px",
                                          height: "20px",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "yxtbiz-nav-immersive__menu-btn-text",
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm._f("Globalize")(
                                              _vm.cnavs[1],
                                              "name"
                                            )
                                          ) +
                                          "\n          "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "transition",
                                    { attrs: { name: "yxt-zoom-in-top" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.menusVisible,
                                              expression: "menusVisible",
                                            },
                                          ],
                                          ref: "menus",
                                          staticClass:
                                            "yxtbiz-nav-immersive__panel-pop",
                                          style: {
                                            top: _vm.popmenuTop + "px",
                                            height:
                                              "calc(100vh - " +
                                              _vm.popmenuTop +
                                              "px)",
                                          },
                                          on: {
                                            mouseenter: function ($event) {
                                              return _vm.openMenus()
                                            },
                                            mouseleave: function ($event) {
                                              return _vm.hideMenus()
                                            },
                                            mousemove: function ($event) {
                                              _vm.menuHoving = true
                                            },
                                          },
                                        },
                                        [
                                          _c("div", {
                                            staticClass:
                                              "yxtbiz-nav-immersive__dialog-arrow",
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "yxtbiz-nav-immersive__logo",
                                            },
                                            [
                                              _vm.logo
                                                ? _c("img", {
                                                    attrs: {
                                                      src: _vm.logo,
                                                      alt: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.goMainIndex()
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "yxtbiz-nav-immersive__menu-wrap",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "yxtbiz-nav-immersive__menus yxtbiz-nav-immersive__menus--first",
                                                },
                                                [
                                                  _c(
                                                    "yxt-scrollbar",
                                                    {
                                                      attrs: {
                                                        "fit-height": true,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "ul",
                                                        _vm._l(
                                                          _vm.navsFirst,
                                                          function (menu) {
                                                            return _c(
                                                              "li",
                                                              {
                                                                key: menu.id,
                                                                staticClass:
                                                                  "yxtbiz-nav-immersive__menu",
                                                                class: {
                                                                  "color-primary-6-i bg-primary_x-1-i":
                                                                    _vm.hoverId ===
                                                                      menu.id ||
                                                                    (_vm
                                                                      .pageCodes
                                                                      .length >
                                                                      0 &&
                                                                      _vm
                                                                        .pageCodes[0] ===
                                                                        menu.code),
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.toSubPage(
                                                                        menu
                                                                      )
                                                                    },
                                                                  mouseover:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.hoverPage(
                                                                        menu
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "ellipsis yxtbiz-nav-immersive__menu-name",
                                                                  },
                                                                  [
                                                                    menu.id ===
                                                                    "_app_all"
                                                                      ? [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "pc_biz_nav_btn_allapplication"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      : [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm._f(
                                                                                "Globalize"
                                                                              )(
                                                                                menu,
                                                                                "name"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ],
                                                                  ],
                                                                  2
                                                                ),
                                                                menu.subMenuInfoList &&
                                                                menu
                                                                  .subMenuInfoList
                                                                  .length > 0
                                                                  ? _c(
                                                                      "yxt-svg",
                                                                      {
                                                                        staticClass:
                                                                          "color-gray-6 yxtbiz-nav-immersive__arrow",
                                                                        class: {
                                                                          "color-primary-6-i":
                                                                            _vm.hoverId ===
                                                                              menu.id ||
                                                                            (_vm
                                                                              .pageCodes
                                                                              .length >
                                                                              0 &&
                                                                              _vm
                                                                                .pageCodes[0] ===
                                                                                menu.code),
                                                                        },
                                                                        attrs: {
                                                                          width:
                                                                            "16px",
                                                                          height:
                                                                            "16px",
                                                                          "icon-class":
                                                                            "arrow-right",
                                                                        },
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      ),
                                                      _c(
                                                        "ul",
                                                        {
                                                          staticClass:
                                                            "yxtbiz-nav-immersive__sub-menus",
                                                        },
                                                        [
                                                          !_vm.SOURCE_100_WXISV &&
                                                          !_vm.EDITION_TOOL
                                                            ? _c(
                                                                "li",
                                                                {
                                                                  staticClass:
                                                                    "yxtbiz-nav-immersive__menu hover-primary-6 hover-bg-primary_x-1-i",
                                                                  on: {
                                                                    mouseover:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.hoverPage()
                                                                      },
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.goSetting()
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "ellipsis yxtbiz-nav-immersive__menu-name",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "pc_biz_nav_btn_usercenter"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _c(
                                                            "li",
                                                            {
                                                              staticClass:
                                                                "yxtbiz-nav-immersive__menu hover-primary-6 hover-bg-primary_x-1-i",
                                                              on: {
                                                                mouseover:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.hoverPage()
                                                                  },
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.logout()
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "ellipsis yxtbiz-nav-immersive__menu-name",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "pc_biz_nav_btn_logout"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "yxtbiz-nav-immersive__menus yxtbiz-nav-immersive__menus--second",
                                                  class: {
                                                    "yxtbiz-nav-immersive__menus--hide":
                                                      _vm.navsSecond.length ===
                                                      0,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "yxt-scrollbar",
                                                    {
                                                      attrs: {
                                                        "fit-height": true,
                                                      },
                                                    },
                                                    [
                                                      _vm.navsSecond.length > 0
                                                        ? _c(
                                                            "ul",
                                                            _vm._l(
                                                              _vm.navsSecond,
                                                              function (menu) {
                                                                return _c(
                                                                  "li",
                                                                  {
                                                                    key: menu.id,
                                                                    staticClass:
                                                                      "yxtbiz-nav-immersive__menu hover-primary-6-i hover-bg-primary_x-1-i",
                                                                    class: {
                                                                      "color-primary-6-i bg-primary_x-1-i":
                                                                        _vm
                                                                          .pageCodes
                                                                          .length >
                                                                          1 &&
                                                                        _vm
                                                                          .pageCodes[1] ===
                                                                          menu.code,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.toSubPage(
                                                                            menu
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "ellipsis yxtbiz-nav-immersive__menu-name",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm._f(
                                                                              "Globalize"
                                                                            )(
                                                                              menu,
                                                                              "name"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c("div", {
                                            staticClass:
                                              "yxtbiz-nav-immersive__dialog-bottom",
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.cnavs && _vm.cnavs.length >= 3
                            ? _c(
                                "ul",
                                {
                                  staticClass:
                                    "yxtbiz-nav-immersive__menus-top",
                                },
                                _vm._l(
                                  _vm.cnavs[1].subMenuInfoList,
                                  function (menu) {
                                    return _c(
                                      "li",
                                      {
                                        key: menu.id,
                                        staticClass:
                                          "yxtbiz-nav-immersive__menu-top",
                                        class: {
                                          "yxtbiz-nav-immersive__menu-top--active":
                                            _vm.cnavs[2].id === menu.id,
                                        },
                                        attrs: { "data-code": menu.code },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openPage(menu)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              _vm._f("Globalize")(menu, "name")
                                            ) +
                                            "\n          "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "yxt-layout-main",
                { staticClass: "yxtbiz-nav-immersive__body" },
                [
                  _c(
                    "yxt-layout",
                    { staticClass: "yxtbiz-nav-immersive__content" },
                    [_vm._t("default")],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }