var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "checkedlist" }, [
    _c(
      "div",
      { staticClass: "header" },
      [
        _c("span", [
          _c("span", [
            _vm._v(_vm._s(_vm.$t("pc_biz_signup_lbl_checked").d("已选"))),
          ]),
          _c("span", { staticStyle: { color: "#999" } }, [
            _c("span", { staticStyle: { color: "#262626" } }, [
              _vm._v("：" + _vm._s(_vm.selectedCount)),
            ]),
          ]),
        ]),
        _vm.showClearAllBtn
          ? _c(
              "yxt-button",
              { attrs: { type: "text" }, on: { click: _vm.clearAll } },
              [_vm._v(_vm._s(_vm.$t("pc_biz_labelling_clearall").d("清空")))]
            )
          : _vm._e(),
      ],
      1
    ),
    _c(
      "main",
      { staticClass: "checkedlist-main" },
      [
        !_vm.showClearAllBtn
          ? _c("div", { staticClass: "checkedlist-main-placeholder" }, [
              _vm._v(
                _vm._s(_vm.$t("biz_udp_please_select").d("请选择")) + "..."
              ),
            ])
          : _vm._e(),
        _vm._l(_vm.selected, function (group, index) {
          return _c("div", { key: index, staticClass: "group-main" }, [
            group.selected.length
              ? _c("div", { staticClass: "group-main-title" }, [
                  _c("span", [_vm._v(_vm._s(group.name) + "：")]),
                  _c("span", [_vm._v(_vm._s(group.selected.length))]),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "group-main-content" },
              _vm._l(group.selected, function (name, key) {
                return _c(
                  "yxt-tag",
                  {
                    key: key,
                    staticClass: "mr5",
                    staticStyle: {
                      "margin-bottom": "8px",
                      "max-width": "100%",
                    },
                    attrs: {
                      size: "small",
                      type: "info",
                      disableTransitions: true,
                      closable: "",
                    },
                    on: {
                      close: function ($event) {
                        return _vm.deleteItem(group.id, name)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "checkedlist-tag" }, [
                      _c(
                        "span",
                        [
                          _c(
                            "yxt-tooltip",
                            {
                              staticClass: "item",
                              attrs: { effect: "dark", placement: "top" },
                            },
                            [
                              _c("template", { slot: "content" }, [
                                _c("span", [_vm._v(_vm._s(name))]),
                              ]),
                              _c("span", [_vm._v(_vm._s(name))]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                )
              }),
              1
            ),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }