var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "yxtbiz-ai-robot-collect-project__item width-percent-100 over-hidden hand",
      on: {
        click: function ($event) {
          return _vm.toDetail(_vm.data.projectId)
        },
      },
    },
    [
      _vm.data.imageUrl
        ? _c(
            "div",
            { staticClass: "yxtbiz-ai-robot-collect-project__cover mr12" },
            [
              _c("yxt-image", {
                attrs: { src: _vm.data.imageUrl, alt: "", fit: "cover" },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "over-hidden" }, [
        _c(
          "div",
          {
            class: [
              _vm.size === "small"
                ? "ellipsis-2 font-size-14 lh22"
                : "ellipsis font-size-16  lh24",
              "color-gray-10",
            ],
          },
          [_vm._v("\n      " + _vm._s(_vm.data.projectName) + "\n    ")]
        ),
        _c(
          "div",
          {
            class: [
              _vm.size === "small" ? "mt4" : "mt8",
              "yxtbiz-flex-center lh20 color-gray-7 font-size-12",
            ],
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.$t("pc_biz_collect_studyCount", {
                    num: _vm.data.memberSize,
                  })
                ) +
                "\n    "
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }