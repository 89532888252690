<template>
  <div class="standard-size-14 yxtbiz-ai-robot__coure">
    <EmptyData v-if="dataList.length === 0" />
    <template v-else>
      <div :class="size === 'small'? 'font-size-14 lh22': 'font-size-16 lh24'" class="color-gray-7 yxtbiz-ai-robot__coure-title">
        {{ $t('pc_biz_ote_lbl_search_content', [$t('pc_biz_ai_lbl_knglib')]) }}
      </div>
      <div class="yxtbiz-ai-robot__coure-cell" v-for="item in dataList" :key="item.id" @click="toDetail(item)">
        <yxtf-svg
          width="48px"
          height="48px"
          :icon-class="getSvgName(item.fileType)"
        />
        <div class="ml12 yxtbiz-ai-robot__course-contain">
          <div :class="size === 'small'? 'font-size-14 lh22': 'font-size-16 lh24'" class="ellipsis-2 mb4" v-html="item.title"></div>
          <div v-if="item.summary && size !== 'small'" class="summary mb4">
            {{ item.summary }}
          </div>
          <div class="coure-flex-center">
            <div class="standard-size-12 color-gray-7 mt4 ellipsis-1 search-knglib-small">
              <span class="ellipsis-1">{{ item.updateUsername || item.createUsername  }}</span>
              <yxt-divider direction="vertical"></yxt-divider>
              <span class="search-knglib-small-nowrap">{{ timestampToTime(notFile(item) ? item.updateTime : item.createTime) }}&nbsp;{{ $t(`pc_search_global_${!notFile(item) ? 'create' : 'update'}`) }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="datas.paging.count > 3" class="yxtbiz-ai-robot__course-more color-gray-8" @click="toMore">
        {{ $t('pc_biz_lbl_common_viewmore') }}
        <yxt-svg :remote-url="`${$staticBaseUrl}ufd/55a3e0`" width="16px" height="16px" icon-class="arrow_right" />
      </div>
    </template>
  </div>
</template>

<script>
import EmptyData from '../common/empty-data.vue';
import { timestampToTime } from '../../utils';
export default {
  name: 'SearchKngLib',
  components: {
    EmptyData
  },
  props: {
    datas: {
      type: Object,
      default: ()=>{
        return {datas: []};
      }
    },
    params: {
      type: Object,
      default: ()=>{}
    },
    size: {
      type: String,
      default: 'small',
      validator: val => ['small', 'large'].includes(val)
    }
  },
  data() {
    console.log(this.datas);
    return {};
  },
  computed: {
    dataList() {
      return this.datas.datas.slice(0, 3);
    }
  },
  methods: {
    notFile(item) {
      // 0-知识库、1-文件夹
      return [0, 1].includes(item.fileType);
    },
    toDetail(item) {
      const type = item.type; // 知识库类型 1.全员 2.协作 3.个人
      const userId = localStorage.getItem('userId');
      const createUserId = item.createUserId; // 创建userId
      const libId = item.libId; // 知识库id
      const originUrl = `${window.location.protocol}//${window.location.hostname}`;
      const fileType = item.fileType;
      const id = item.id;
      let targetUrl;

      if (fileType === 0) { // 知识库
        if (type === 3 && createUserId === userId) {
          targetUrl = `${originUrl}/knglib/#/spaceDetail?spaceId=${libId}&folderId=${id}`; // 我的
        } else if (type === 3 && localStorage.getItem('admin') === '1') {
          targetUrl = `${originUrl}/knglib/#/spaceDetail?spaceId=${id}`; // 员工知识库-知识库
        } else {
          targetUrl = `${originUrl}/knglib/#/spaceDetail?spaceId=${id}`; // 协作知识库-知识库
        }
      } else if (fileType === 1) { // 文件夹
        if (type === 3 && createUserId === userId) {
          targetUrl = `${originUrl}/knglib/#/spaceDetail?spaceId=${libId}&folderId=${id}`; // 我的
        } else if (type === 3 && localStorage.getItem('admin') === '1') {
          targetUrl = `${originUrl}/knglib/#/spaceDetail?spaceId=${libId}&folderId=${id}`; // 员工知识库-文件夹
        } else {
          targetUrl = `${originUrl}/knglib/#/spaceDetail?spaceId=${libId}&folderId=${id}`; // 协作知识库-文件夹
        }
      } else if (fileType === 10) {
        targetUrl = `${originUrl}/knglib/#/notedetail/${id}`; // 随记详情
      } else {
        targetUrl = `${originUrl}/knglib/#/detail?kngId=${id}`; // 知识详情
      }
      window.open(targetUrl, '_blank');
    },
    toMore() {
      window.open(`/search/#/gs/kngbase?keyword=${this.params.searchKey}`);
    },
    getSvgName(type) {
      return ['f_kng-kng', 'f_kng-folder', 'f_kng-img', 'f_kng-zip', 'f_kng-video', 'f_kng-word', 'f_kng-ppt', 'f_kng-pdf', 'f_kng-excel', 'f_kng-audio'][type];
    },
    timestampToTime(time) {
      return timestampToTime(time);
    }
  }
};
</script>
<style scoped lang="scss">
.search-knglib-small{
  display: flex;
  .search-knglib-small-nowrap{
    white-space: nowrap;
  }
}
.yxtbiz-ai-robot__coure{
  width: 100%;
}
.yxtbiz-ai-robot__coure-title{
  padding: 0px 12px 12px;
}
.summary {
  flex: 1;
  min-width: 0;
  font-size: 12px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #757575;
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.yxtbiz-ai-robot__course-contain{
  flex-grow: 1;
  overflow: hidden;
}
.yxtbiz-ai-robot__coure-cell {
  display: flex;
  overflow: hidden;
  align-items: center;
  border-radius: 8px;
  padding: 12px;
  cursor: pointer;
  &:hover {
    background-color: #f4f5f6;
  }
}
.yxt-divider {
  background-color: #d8d8d8;
}
.yxt-progress__text {
  font-size: 12px !important;
  line-height: 20px;

  &>span {
    font-size: 12px !important;
  }
}
.coure-flex-center {
  display: flex;
  align-items: center;
}
.yxtbiz-ai-robot__course-cover {
  width: 112px;
  height: 63px;
  border-radius: 4px;
  flex-shrink: 0;
  flex-grow: 0;
}
.yxtbiz-ai-robot__course-more {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: #f4f5f6;
  }
}
</style>
