var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yxtbiz-qrcode" },
    [
      _vm.name
        ? [
            _vm.isProject
              ? _c("div", { staticClass: "yxtbiz-qrcode__project" }, [
                  _c("div", { staticClass: "yxtbiz-qrcode__project-name" }, [
                    _vm._v(_vm._s(_vm.name)),
                  ]),
                  _vm.projectTip
                    ? _c("div", { staticClass: "yxtbiz-qrcode__project-tip" }, [
                        _vm._v(_vm._s(_vm.projectTip)),
                      ])
                    : _vm._e(),
                ])
              : _c("div", { staticClass: "yxtbiz-qrcode__name" }, [
                  _vm._v(_vm._s(_vm.name)),
                ]),
          ]
        : _vm._e(),
      _c("div", { staticClass: "yxtbiz-qrcode__img" }, [
        _c("div", { ref: "qrcode" }),
        _c("div", { ref: "hidQrcode", staticClass: "d-none" }),
      ]),
      _vm._t("below-code"),
      !_vm.hideLink
        ? _c("div", { staticClass: "yxtbiz-qrcode__link" }, [
            _c("div", { staticClass: "yxtbiz-qrcode__link-text" }, [
              _vm._v(_vm._s(_vm.$t("pc_biz_qrcode_lbl_link"))),
            ]),
            _c("div", { staticClass: "yxtbiz-qrcode__link-url" }, [
              _c("input", {
                ref: "inputUrl",
                attrs: { readonly: "" },
                domProps: { value: this.url },
              }),
            ]),
            _c(
              "div",
              {
                staticClass: "yxtbiz-qrcode__link-copy color-primary-6",
                on: { click: _vm.copyUrl },
              },
              [_vm._v(_vm._s(_vm.$t("pc_biz_qrcode_lbl_copy")))]
            ),
          ])
        : _vm._e(),
      !_vm.hideDownload
        ? _c("div", { staticClass: "yxtbiz-qrcode__download" }, [
            _c("label", [_vm._v(_vm._s(_vm.$t("pc_biz_qrcode_lbl_download")))]),
            _c(
              "span",
              {
                staticClass: "color-primary-6",
                on: {
                  click: function ($event) {
                    return _vm.download(200)
                  },
                },
              },
              [_vm._v("200x200")]
            ),
            _c(
              "span",
              {
                staticClass: "color-primary-6",
                on: {
                  click: function ($event) {
                    return _vm.download(400)
                  },
                },
              },
              [_vm._v("400x400")]
            ),
            _c(
              "span",
              {
                staticClass: "color-primary-6",
                on: {
                  click: function ($event) {
                    return _vm.download(800)
                  },
                },
              },
              [_vm._v("800x800")]
            ),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }