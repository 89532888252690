<!-- 创建时间2023/11/17 09:54:47 -->
<!-- 创建人：Zhao bing bing -->
<!-- 组件描述：选择企课堂 -->
<template>
  <yxt-form-item :label="config.labelName" v-if="showCollegeSelect" :prop="config.name">
    <yxt-select class="width-percent-100" v-model="value_" filterable size="small" :disabled="disabled || loading">
      <yxt-option 
        v-for="item in collegeList" 
        :key="item.id"
        :label="item.collegeName"
        :value="item.id">
      </yxt-option>
  </yxt-select>
  </yxt-form-item>
</template>

<script>
import { kngApi } from 'yxt-biz-pc/packages/api';
import commonUtil from 'yxt-biz-pc/packages/common-util';
export default {
  name: 'KngCollegeSelector',
  props: {
    value: {
      type: [Number, String],
      default: ''
    },
    config: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      collegeList: [],
      loading: false,
      showCollegeSelect: false
    };
  },
  computed: {
    value_: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
        this.change(val);
      }
    }
  },
  async created() {
    try {
      await commonUtil.preCheckFunctions(['college_course_mgmt_contents_choose']);
      this.showCollegeSelect = commonUtil.checkTimeOutFnc('college_course_mgmt_contents_choose') >= 2;
    } catch (e) {}
    if (this.showCollegeSelect) {
      this.getCollegeList();
    }
  },
  methods: {
    getCollegeList() {
      this.loading = true;
      kngApi.get('/college/listWithMaintain')
        .then(res => {
          this.collegeList = res || [];
          if (this.value_ && this.collegeList.some(item => (item.id === this.value_ || item.collegeName === this.value_))) {
            const obj = this.collegeList.find(item => item.id === this.value_ || item.collegeName === this.value_);
            this.value_ = obj.id;
          } else if (localStorage.orgId && this.collegeList.indexOf(localStorage.orgId) > -1) {
            this.value_ = localStorage.orgId || '';
          } else if (this.collegeList.length > 0) {
            this.value_ = this.collegeList[0].id;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    change(val) {
      const obj = this.collegeList.find(item => item.id === val) || {};
      const {id, collegeName} = obj;
      this.$emit('change', {value: id, labelName: collegeName});
    }
  }
};
</script>