var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-list", {
    attrs: {
      dataList: _vm.datas,
      size: _vm.size,
      tagLists: _vm.tagLists,
      searchName: "pc_biz_ote_lbl_exam",
      tagType: "status",
      listId: "paperId",
    },
    on: { "to-list": _vm.toList, "to-more": _vm.toListMore },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ list }) {
          return [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t("pc_biz_ote_lbl_test_method", [
                    list.buildType === 0
                      ? _vm.$t("pc_biz_ote_lbl_exampapertype0" /* 固定出卷 */)
                      : _vm.$t("pc_biz_ote_lbl_exampapertype1" /* 随机出卷 */),
                  ])
                )
              ),
            ]),
            _c("span", { staticClass: "ml20" }, [
              _vm._v(
                _vm._s(_vm.$t("pc_biz_ote_lbl_quotetimes", [list.usedQty]))
              ),
            ]),
            _c("span", { staticClass: "ml20" }, [
              _vm._v(_vm._s(list.createFullname || "--")),
            ]),
            _c("yxt-divider", { attrs: { direction: "vertical" } }),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t("pc_kng_common_lbl_update_time", [list.updateTime])
                )
              ),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }