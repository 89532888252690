<template>
  <div class="yxtbiz-select-exams">
    <div class="clearfix font-size-14">
      <!-- 分类 -->
      <catalogDropdown
        :data="datasCatalog"
        class="pull-left mr12"
        :dropToolTip="
          $t('pc_biz_lbl_selectcatalogforsearch', [$t('pc_biz_ote_lbl_exam')])
        "
        :catalogId.sync="datasSearch.catalogId"
        :catalogName.sync="datasSearch.catalogName"
        @change="selectCatalog"
      ></catalogDropdown>
      <yxt-input
        style="width: 240px"
        class="pull-left"
        :placeholder="
          $t('pc_biz_lbl_inputnameforsearch', [
            $t('pc_biz_ote_lbl_exampapername')
          ])
        "
        v-model.trim="datasSearch.name"
        searchable
        @search="examNameSearch"
        maxlength="50"
      >
      </yxt-input>
    </div>
    <yxt-table
      :ref="refTable"
      :height="tableHeight"
      v-loading="loading"
      class="mt16"
      :data="datasList"
      :default-sort="{ prop: 'updateTime', order: 'desc' }"
      @sort-change="sortChange"
      @select-all="selectAll"
      @select="select"
    >
      <yxt-table-column
        :selectable="selectable"
        type="selection"
        :show-overflow-tooltip="false"
        width="38"
        align="right"
        clear-padding="left-right"
      >
      </yxt-table-column>
      <yxt-table-column
        :label="$t('pc_biz_ote_lbl_exampapername')"
        :show-overflow-tooltip="false"
      >
        <template slot-scope="scope">
          <yxt-tooltip
            open-filter
            :max-width="300"
            effect="dark"
            class="ellipsis"
            :content="scope.row.name"
            placement="top"
          >
            <div
              @click="goExamPreview(scope.row.id)"
              type="primary"
              class="color-primary-6 hand"
            >
              {{ scope.row.name }}
            </div>
          </yxt-tooltip>
        </template>
      </yxt-table-column>
      <yxt-table-column
        :label="$t('pc_biz_ote_lbl_exampapertype')"
        prop="buildType"
        align="left"
        width="160"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.buildType === 0">{{
            $t('pc_biz_ote_lbl_exampapertype0')
          }}</span>
          <span v-if="scope.row.buildType === 1">{{
            $t('pc_biz_ote_lbl_exampapertype1')
          }}</span>
        </template>
      </yxt-table-column>
      <yxt-table-column
        :label="$t('pc_biz_ote_lbl_updatetime')"
        prop="updateTime"
        width="140"
        sortable="custom"
        :formatter="shortDate"
        align="left"
      ></yxt-table-column>
    </yxt-table>
    <div class="mt16 clearfix">
      <yxt-pagination
        class="pull-right"
        :page-size="datasSearch.limit"
        :total="count"
        :current-page="page"
        layout="total, prev, pager, next"
        @current-change="pageChange"
        @size-change="sizeChange"
      ></yxt-pagination>
    </div>
  </div>
</template>

<script>
import { getExamListOG, getCatalogListOG } from './service';
import dateUtil from 'yxt-biz-pc/src/utils/date';
import resizeTable from '../../user-selector/mixins/resizeTable';
import { Table, TableColumn, Pagination, Tooltip, Tree } from 'yxt-pc';
import catalogDropdown from '../../question-selector/src/selector/catalogDropdown.vue';
import rootCatalog from '../../question-selector/src/selector/rootCatalog';

export default {
  name: 'YxtbizSelectExams',
  components: {
    catalogDropdown,
    YxtTable: Table,
    YxtTableColumn: TableColumn,
    YxtPagination: Pagination,
    YxtTooltip: Tooltip,
    YxtTree: Tree
  },
  mixins: [resizeTable, rootCatalog],
  props: {
    value: {
      type: Array,
      default: []
    },
    selectable: {
      type: Function,
      default: () => true
    }
  },
  watch: {
    examCatalogSearch(val) {
      this.$refs.tree.filter(val);
    },
    value(v) {
      this.formatModel(v);
    }
  },
  data() {
    return {
      examCatalogSearch: '',
      refTable: 'stb',
      tableHeight: 0,
      page: 1,
      datasSearch: {
        buildType: -1,
        disabled: 0,
        needCheck: -1,
        keywordType: 0,
        status: 4, // 已发布的
        catalogId: '',
        name: '',
        limit: 20,
        offset: 0,
        orderby: 'updateTime',
        direction: 'DESC'
      },
      loading: true,
      totalPage: 0,
      count: 0,
      datasList: [],
      datasCatalog: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      selected: []
    };
  },
  created() {
    this.formatModel(this.value);
    this.getCatalogList();
    this.getExamList();
  },
  methods: {
    formatModel(v) {
      this.selected = {};
      if (v) {
        v.forEach(obj => {
          this.selected[obj.id] = obj;
        });
        this.setChecked();
      }
    },
    setChecked() {
      this.datasList.forEach(obj => {
        if (this.selected.hasOwnProperty(obj.id)) {
          this.$refs[this.refTable].toggleRowSelection(obj, true);
        } else {
          this.$refs[this.refTable].toggleRowSelection(obj, false);
        }
      });
    },
    shortDate(row, col, val, index) {
      if (!val) return '';
      if (typeof val === 'string') {
        val = val.replace(/-/g, '/');
      }
      return dateUtil.format(new Date(val), 'yyyy-MM-dd');
    },
    filterNode(value, data) {
      return !value || data.label.indexOf(value) !== -1;
    },
    selectCatalog(item) {
      this.page = 1;
      this.datasSearch.offset = 0;
      this.datasSearch.catalogId = item.id;
      this.datasSearch.routingPath = item.routingPath;
      this.getExamList();
    },
    getCatalogList() {
      this.setCatalogType(1);
      this.datasCatalog = this.wrapRootCatalog();
      getCatalogListOG()
        .then(catalogs => {
          this.datasCatalog[0].children = [];
          this.getCatasOfTree(this.datasCatalog[0].children, '', catalogs);
        })
        .catch(e => {
          console.log(e);
        });
    },
    getExamList(isReset) {
      if (isReset) {
        this.page = 1;
        this.datasSearch.offset = 0;
      }
      this.datasSearch.name = this.datasSearch.name.trim();
      this.loading = true;
      getExamListOG(this.datasSearch)
        .then(result => {
          this.datasList = result.datas;
          this.datasList.forEach(data => {
            delete data.sourceType;
          });
          this.totalPage = result.paging.pages;
          this.count = result.paging.count;
          this.loading = false;
          this.$nextTick(() => {
            this.setChecked();
          });
        })
        .catch(e => {
          console.log(e);
        });
    },
    examNameSearch() {
      this.datasSearch.searchKey = this.examCatalogSearch;
      this.getExamList(true);
    },
    sortChange(sort) {
      this.datasSearch.direction = sort.order;
      this.datasSearch.orderby = sort.prop;
      this.getExamList();
      return false;
    },
    sizeChange(size) {
      this.datasSearch.limit = size;
      this.getExamList(true);
    },
    pageChange(page) {
      this.page = page;
      this.datasSearch.offset = (page - 1) * this.datasSearch.limit;
      this.getExamList();
    },
    getCatasOfTree(arrNew, pId, cataAll) {
      cataAll.forEach(cata => {
        if (cata.parentId === pId || (!pId && !cata.parentId)) {
          cata.label = cata.name;
          cata.children = [];
          this.getCatasOfTree(cata.children, cata.id, cataAll);
          arrNew.push(cata);
        }
      });
    },
    goExamPreview(id) {
      window.open('/ote/#/examPaperPreview?examid=' + id);
    },
    // 选择某个
    select(item, row) {
      let isAdd = item.some(v => {
        return v.id === row.id;
      });
      if (!isAdd) {
        delete this.selected[row.id];
      } else {
        this.selected[row.id] = row;
      }
      this.outPut();
    },
    // 全选
    selectAll(items) {
      let isAdd = items.filter((item) => {
        return this.selectable(item);
      }).length !== 0;
      this.datasList.forEach(obj => {
        if (this.selectable(obj)) {
          if (!isAdd) {
            delete this.selected[obj.id];
          } else {
            this.selected[obj.id] = obj;
          }
        }
      });
      this.outPut();
    },
    outPut() {
      const selArray = Object.values(this.selected);
      this.$emit('input', selArray);
      this.$emit('select', selArray);
    }
  }
};
</script>
