<template>
  <div class="hand">
    <yxt-badge :value="noticeCount" :max="99" class="yxtbiz-nav-top-notice-bell" :hidden="!noticeCount" @click.native="page2NoticeCenter">
      <yxt-svg  class="hover-primary-6-i color-gray-9" :remote-url="mediaPath" width="18px" height="18px" icon-class="nav-msg" />
    </yxt-badge>
  </div>
</template>

<script>
import { CoreSvgPath } from './const';
import { defaultProductAllPath } from './const';
export default {
  name: 'noticeBell',
  data() {
    return {
      mediaPath: CoreSvgPath
    };
  },
  created() {
    this.init();
  },
  computed: {
    noticeCount() {
      return this.$store.state.navManageStore.unReadNoticeCnt;
    }
  },
  methods: {
    init() {
      this.$store.dispatch('navManageStore/getUnreadNoticeCnt');
    },
    page2NoticeCenter() {
      window.location.href = `${defaultProductAllPath}/#/user/noticecenter`;

    }
  },
  beforeDestroy() {
  }
};
</script>
