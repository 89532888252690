<!-- 组件封装的携带checkbox -->
<template>
  <yxt-select
    :placeholder="placeholder"
    v-model="selected"
    popper-class="user-selector-select-checkbox"
    :clearable="clearable"
    :collapse-tags="true"
    multiple
    @clear="clear"
    @remove-tag="removeTag"
  >
    <div slot="fixed-header">
      <yxt-checkbox
        v-model="isAll"
        @change="changeAll"
      >
        {{ $t('pc_biz_udp_lbl_selectAll') }}
      </yxt-checkbox>
    </div>
    <yxt-option
      v-for="(item, index) in options"
      :key="index"
      :value="item"
      :label="item"
      style="height: auto; max-height: 200px; overflow: auto;"
    >
      <yxt-checkbox
        :change-text-color="true"
        :value="testChecked(item)"
        style="z-index: 999; display: inline-block; width: 100%; padding-left: 20px;box-sizing: border-box;"
        @change="changeGrade($event, item)"
      >
        {{ item }}
      </yxt-checkbox>
    </yxt-option>
  </yxt-select>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    enableGroupCorp: {
      defalut: false
    },
    placeholder: String,
    options: Array,
    labelkey: String,
    clearable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selected: [],
      isAll: false
    };
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.setSelected();
      }
    }
  },
  methods: {
    setSelected() {
      let selected = [];
      if (!this.options) {
        return;
      }
      this.options.forEach((item) => {
        if (this.value.find((i) => i === item)) {
          selected.push(item);
        }
      });
      this.isAll = this.options.length === selected.length;
      this.selected = selected;
    },
    change(items) {
      this.isAll = this.options.every((item) => {
        return items.find((node) => item === node);
      });
      this.$emit(
        'change',
        items.map((item) => item)
      );
    },
    changeAll(v) {
      this.isAll = v;
      this.checkAllStatus();
    },
    checkAllStatus() {
      if (this.isAll) {
        this.selected = [].concat(this.options);
      } else {
        this.selected = [];
      }
      this.$emit(
        'change',
        this.selected.map((item) => item)
      );
    },
    changeGrade(checked, item) {
      if (this.testChecked(item)) {
        this.selected = this.selected.filter((node) => node !== item);
      } else {
        this.selected.push(item);
      }
      this.change(this.selected);
    },
    testChecked(item) {
      let index = this.selected.findIndex((node) => {
        return node === item;
      });
      return index > -1;
    },
    removeTag(tag) {
      let s = new Set(this.selected.map((item) => item));
      s.delete(tag);
      this.$emit('change', Array.from(s));
    },
    clear() {
      this.$emit('change', []);
    }
  }
};
</script>
