<template>
  <div :class="[ns.b(), { small: isSmall }]">
    <div :class="[ns.e('msg'), 'color-gray-10']">
      {{
        empty
          ? $t('pc_biz_live_searchEmpty' /** 抱歉，未找到相关内容*/)
          : $t('pc_biz_live_searchResult' /** 为您找到以下直播：*/)
      }}
    </div>
    <div
      :class="ns.e('card')"
      v-for="item in list"
      :key="item.id"
      @click="toLiveDetail(item)"
    >
      <div :class="ns.e('cover')">
        <yxt-image :src="item.cover || defaultCover" fit="cover" lazy />
        <div :class="[ns.e('tag'), getTagCls(item)]">
          {{ getTagName(item) }}
        </div>
      </div>
      <div :class="ns.e('detail')">
        <div :class="ns.e('title')">
          <div :class="ns.e('title-tag')" v-if="item.orgType">
            {{ $t('pc_biz_live_group' /** 集团 */) }}
          </div>
          <span :class="ns.e('title-con')">{{ item.roomName }}</span>
        </div>
        <div :class="ns.e('subtitle')">
          <div :class="ns.e('teacher')">
            {{
              $t(
                'pc_biz_live_teacher',
                [getTeachersName(item)] /** 讲师：{0} */
              )
            }}
          </div>
          <div :class="ns.e('line')">|</div>
          <div :class="ns.e('time')">
            {{
              $t(
                'pc_biz_live_time',
                [
                  getDateTimeFormatted({ startTime: item.beginTime })
                ] /** 时间：{0} */
              )
            }}
          </div>
        </div>
      </div>
    </div>
    <div :class="ns.e('more')" v-if="hasMore" @click="toMyLive">
      <span>{{ $t('pc_biz_live_viewmore' /* 查看更多 */) }}</span>
      <yxt-svg
        :class="ns.e('more-icon')"
        width="16px"
        height="16px"
        icon-class="arrow_right"
      />
    </div>
  </div>
</template>

<script>
import utils from 'yxt-biz-pc/packages/common-util/utils.js';
import CommonMixin from '../common/props-mixin';
import { useNamespace } from './bem';

const ROOM_STATUS = {
  NOSTART: 0,
  LIVING: 1,
  ENDED: 2
};

const TEACHER_TYPE = {
  ISTEACHER: 1
};

export default {
  name: 'SearchLive',
  mixins: [CommonMixin],
  props: {
    isTrack: {
      type: Boolean,
      default: false
    }
  },
  data() {
    // 数据兼容
    // see isTrack===false https://api-phx-di-hw.yunxuetang.com.cn/tlive/doc.html#/default/%E5%AD%A6%E5%91%98%E7%AB%AF-%E7%9B%B4%E6%92%AD%E9%97%B4%E5%88%97%E8%A1%A8/searchLiveRoom4AIUsingPOST
    // see isTrack===true https://api-phx-di-hw.yunxuetang.com.cn/tlive/doc.html#/default/%E7%AE%A1%E7%90%86%E7%AB%AF-%E7%9B%B4%E6%92%AD%E9%97%B4%E7%9B%B8%E5%85%B3%E5%88%86%E9%A1%B5%E6%8E%A5%E5%8F%A3/pageSearchLiveRoomUsingGET
    const records = !this.isTrack
      ? this.datas.records
      : this.datas.datas.map(item => ({
        ...item,
        recorded: 1, // 回放设置：0开启，1未开启
        teachers: item.teacherNameList,
        orgType: 0 // 直播机构类型：0普通直播，1集团版直播
      }));
    return {
      records,
      ns: useNamespace('search-live'),
      list: records.slice(0, 3),
      defaultCover: `${this.$staticBaseUrl}/0f43ff1b/c8ea2516/bgCover.svg`,
      getDateTimeFormatted: utils.getDateTimeFormatted
    };
  },
  computed: {
    isSmall() {
      return this.size === 'small';
    },
    empty() {
      return this.records.length === 0;
    },
    hasMore() {
      return this.records.length > 3;
    }
  },
  methods: {
    getTagName(item) {
      if (item.roomStatus === ROOM_STATUS.LIVING) {
        return this.$t('pc_biz_live_live' /* 直播中 */);
      } else if (item.roomStatus === ROOM_STATUS.NOSTART) {
        return this.$t('pc_biz_live_unstarted' /* 未开始 */);
      } else if (item.roomStatus === ROOM_STATUS.ENDED && item.recorded) {
        return this.$t('pc_biz_live_ended' /* 已结束 */);
      }

      return this.$t('pc_biz_live_replay' /* 可回放 */);
    },
    getTagCls(item) {
      if (item.roomStatus === ROOM_STATUS.LIVING) {
        return this.ns.em('tag', 'live');
      } else if (item.roomStatus === ROOM_STATUS.NOSTART) {
        return this.ns.em('tag', 'unstarted');
      } else if (item.roomStatus === ROOM_STATUS.ENDED && item.recorded) {
        return this.ns.em('tag', 'ended');
      }

      return this.ns.em('tag', 'replay');
    },
    getTeachersName(item) {
      const teacher = (item.teachers || []).find(
        teacher => teacher.teacherType === TEACHER_TYPE.ISTEACHER
      );
      return teacher ? teacher.viewName : '  ';
    },
    toLiveDetail(item) {
      window.open(
        !this.isTrack
          ? `${window.location.origin}/tlive/#/livedetail/${item.id}`
          : `${window.location.origin}/tlive/#/detail?id=${item.id}`,
        '_blank'
      );
    },
    toMyLive() {
      if (!this.isTrack) {
        // 默认值
        this.params = {
          roomStatus: 0,
          recent7Days: false,
          roomModuleList: 0,
          ...this.params
        };

        localStorage.setItem(
          'aiRobotSearchLiveQuery',
          JSON.stringify(this.params)
        );
        window.open(`${window.location.origin}/tlive/#/livelist`, '_blank');
      } else {
        // 默认值
        this.params = {
          queryString: '',
          mobile: null,
          roomStatus: null,
          ...this.params
        };

        localStorage.setItem(
          'aiRobotTrackLiveQuery',
          JSON.stringify(this.params)
        );
        window.open(`${window.location.origin}/tlive/#/list`, '_blank');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import './bem.scss';

@include b(search-live) {
  &__msg {
    line-height: 28px;
    font-size: 16px;
    padding: 0 12px;
    color: #8c8c8c;
  }

  &.small &__msg {
    line-height: 24px;
    font-size: 14px;
  }

  &__card {
    padding: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: #f4f5f6;
    }
  }

  &__msg + &__card {
    margin-top: 12px;
  }

  &__cover {
    position: relative;
    width: 113px;
    height: 64px;
    border-radius: 4px;
    overflow: hidden;
  }

  &__tag {
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    height: 24px;
    padding: 4px 8px;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    border-radius: 4px 0;

    &--living {
      background: linear-gradient(90deg, #fa8943 0%, #f9622b 100%);
    }

    &--unstarted {
      background: linear-gradient(90deg, #40c2f9 0%, #2ba2f5 100%);
    }

    &--replay {
      background: linear-gradient(90deg, #4cc3bb 0%, #3bbeb5 100%);
    }

    &--ended {
      background: linear-gradient(90deg, #668290 0%, #94afbb 100%);
    }
  }

  &__detail {
    flex: 1;
    margin-left: 12px;
  }

  &__title {
    font-size: 16px;
    line-height: 24px;
    color: #262626;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;

    &-tag {
      margin-right: 4px;
      padding: 0 8px;
      font-size: 12px;
      line-height: 16px;
      display: inline-block;
      color: #b8741a;
      text-align: center;
      border: 1px solid #b8741a;
      border-radius: 3px;
      box-sizing: border-box;
    }

    &-con {
      vertical-align: middle;
    }
  }

  &.small &__title {
    line-height: 20px;
    font-size: 14px;
    -webkit-line-clamp: 2;
  }

  &__subtitle {
    margin-top: 8px;
    font-size: 12px;
    line-height: 20px;
    color: #8c8c8c;
    display: flex;
    flex-direction: row;
  }

  &.small &__subtitle {
    margin-top: 4px;
    line-height: 18px;
    flex-direction: column;
  }

  &__line,
  &__time {
    margin-left: 8px;
  }

  &.small &__line {
    margin-left: 0;
    display: none;
  }

  &.small &__time {
    margin-left: 0;
    margin-top: 4px;
  }

  &__more {
    padding: 8px 0;
    color: #757575;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 22px;
    border-radius: 8px;
    cursor: pointer;

    &-icon {
      margin-left: 4px;
    }

    &:hover {
      background-color: #f4f5f6;
    }
  }
}
</style>
