<script>
import { i18n } from 'yxt-biz-pc/packages/common-util/changeLangs';
export default {
  name: 'YxtbizLanguageSlot',
  functional: true,
  props: {
    langKey: {
      // 多语言key
      type: String,
      default: ''
    },
    className: {
      // class类名
      type: String,
      default: ''
    },
    inline: {
      type: Boolean,
      default: false
    }
  },
  render(createElement, context) {
    const _this = context;
    // let text = handleText(_this.props)
    let text = i18n.t(_this.props.langKey);
    let reg = /\{(.+?)\}/gi;
    // 需要插槽的{name}的List
    let matchList = text.match(reg) || [];
    let splitStrReg = RegExp(matchList.join('|').replace(new RegExp('{', 'gm'), '\\{').replace(new RegExp('}', 'gm'), '\\}'));
    // 文字数组
    const textArray = text.split(splitStrReg) || [];
    let elList = [];
    for (let i = 0; i < textArray.length; i++) {
      elList.push(textArray[i]);
      if (i < matchList.length) {
        const name = matchList[i].replace('{', '').replace('}', '');
        if (_this.scopedSlots && _this.scopedSlots[name]) {
          elList.push(_this.scopedSlots[name]());
        }
      }
    }
    const actualTag = _this.props.inline ? 'span' : 'div';
    const el = createElement(
      actualTag,
      { attrs: { class: 'yxtbiz-language-slot ' + _this.props.className } },
      elList
    );
    return el;
  }
};
</script>
