var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "navigation" }, [
    _c(
      "ul",
      _vm._l(_vm.data, function (d, index) {
        return _c(
          "li",
          {
            key: index,
            on: {
              click: function ($event) {
                return _vm.handleShowNav(d)
              },
            },
          },
          [_vm._v(_vm._s(d.name))]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }