import { render, staticRenderFns } from "./nav-org-list-stu.vue?vue&type=template&id=8610058a&scoped=true"
import script from "./nav-org-list-stu.vue?vue&type=script&lang=js"
export * from "./nav-org-list-stu.vue?vue&type=script&lang=js"
import style0 from "./nav-org-list-stu.vue?vue&type=style&index=0&id=8610058a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8610058a",
  null
  
)

export default component.exports