<template>
  <div
    class="yxtbiz-ai-robot-collect-project__item width-percent-100 over-hidden hand"
    @click="toDetail(data.projectId)"
  >
    <div
      v-if="data.imageUrl"
      class="yxtbiz-ai-robot-collect-project__cover mr12"
    >
      <yxt-image :src="data.imageUrl" alt fit="cover" />
    </div>
    <div class="over-hidden">
      <div
        :class="[
          size === 'small'
            ? 'ellipsis-2 font-size-14 lh22'
            : 'ellipsis font-size-16  lh24',
          'color-gray-10'
        ]"
      >
        {{ data.projectName }}
      </div>
      <div
        :class="[
          size === 'small' ? 'mt4' : 'mt8',
          'yxtbiz-flex-center lh20 color-gray-7 font-size-12'
        ]"
      >
        {{ $t('pc_biz_collect_studyCount', { num: data.memberSize }) }}
      </div>
    </div>
  </div>
</template>
  
  <script>
import favoriteMixin from './favorite-mixin.js';
export default {
  name: 'projectItem',
  mixins: [favoriteMixin],
  data() {
    return {
      loading: false
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    console.log(this.data);
  },
  methods: {
    toDetail(id) {
      let url = `${window.location.protocol}//${window.location.host}/o2o/#/project/detail/${id}`;
      window.open(url);
    }
  }
};
</script>
  <style scoped lang="scss">
.yxtbiz-ai-robot-collect-project__item {
  display: flex;
  align-items: center;

  .yxtbiz-ai-robot-collect-project__cover {
    flex-shrink: 0;
    width: 112px;
    height: 68px;
    overflow: hidden;
    border-radius: 4px;

    ::v-deep .yxt-image {
      width: 112px;
      height: 68px;
    }

    ::v-deep img {
      width: 100% !important;
      height: 100%;
    }
  }
}
</style>
  