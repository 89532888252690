var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["yxtbiz-nav-top", _vm.tinyNav && "yxtbiz-nav-top-tiny"] },
    [
      _c(
        "div",
        { on: { click: _vm.goStuIndex } },
        [
          !_vm.tinyNav
            ? _c("a-link", {
                staticClass: "yxtbiz-nav-top__logo hand",
                style: `background-image:url(${_vm.logo})`,
                attrs: {
                  url: `${_vm.defaultProductPath}/#/studyindex?norequest=1`,
                  mode: "flex",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          class: [
            "yxtbiz-nav-top__menus",
            _vm.tinyNav && "yxtbiz-nav-top-tiny__menus",
          ],
        },
        [
          _c(
            "ul",
            { attrs: { id: _vm.navMenusAllId } },
            [
              _vm._l(_vm.navsOnMenu, function (menu, index) {
                return _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: index <= _vm.menuSet.max - 1,
                        expression: "index <= menuSet.max -1 ",
                      },
                    ],
                    key: menu.id,
                    staticClass:
                      "yxtbiz-nav-top__menu hover-primary-6-i yxtbiz-nav-top__menu-item",
                    class: {
                      "yxtbiz-nav-top__menu--active":
                        menu.code === _vm.pageCodes[0],
                      "color-primary-6-i": menu.code === _vm.pageCodes[0],
                    },
                    on: {
                      click: function ($event) {
                        return _vm.toSubPage(menu)
                      },
                    },
                  },
                  [
                    menu.id === "_app_all"
                      ? _c(
                          "yxt-popover",
                          {
                            ref: "appPopper",
                            refInFor: true,
                            attrs: {
                              trigger: "hover",
                              "visible-arrow": false,
                              placement: "bottom-start",
                              "popper-class": `yxtbiz-nav-top__sub-menus ${
                                _vm.tinyNav
                                  ? "yxtbiz-nav-top-tiny__sub-menus"
                                  : ""
                              }`,
                            },
                            on: {
                              show: _vm.changeAppPopper,
                              hide: _vm.changeAppPopper,
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "yxtbiz-nav-top__apps" },
                              _vm._l([0, 1], function (col) {
                                return _c(
                                  "div",
                                  {
                                    key: col,
                                    staticClass: "yxtbiz-nav-top__block",
                                  },
                                  [
                                    _vm._l(
                                      menu.subMenuInfoList,
                                      function (smenu, index) {
                                        return [
                                          index % 2 === col &&
                                          smenu.subMenuInfoList &&
                                          smenu.subMenuInfoList.length > 0
                                            ? _c(
                                                "a-link",
                                                {
                                                  key: smenu.id,
                                                  staticClass:
                                                    "yxtbiz-nav-top__app",
                                                  attrs: {
                                                    mode: "flex",
                                                    url: smenu.pageUrl,
                                                    "page-node": smenu,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.toSubPage(
                                                            smenu
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("yxt-image", {
                                                        class: [
                                                          "yxtbiz-nav-top__app-icon",
                                                          _vm.tinyNav &&
                                                            "yxtbiz-nav-top-tiny__app-icon",
                                                        ],
                                                        attrs: {
                                                          src: smenu.icon,
                                                        },
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "yxtbiz-nav-top__app-menu",
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm._f(
                                                                  "Globalize"
                                                                )(smenu, "name")
                                                              )
                                                            ),
                                                          ]),
                                                          smenu.remark
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    smenu.remark
                                                                  )
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                )
                              }),
                              0
                            ),
                            _c("template", { slot: "reference" }, [
                              _c(
                                "span",
                                {
                                  class: [
                                    "yxtbiz-nav-top__menu-name",
                                    _vm.tinyNav &&
                                      "yxtbiz-nav-top-tiny__menu-name",
                                  ],
                                },
                                [
                                  _vm.tinyNav &&
                                  menu.code === _vm.pageCodes[0] &&
                                  _vm.cnavs &&
                                  _vm.cnavs[1]
                                    ? [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("Globalize")(
                                              _vm.cnavs[1],
                                              "name"
                                            )
                                          )
                                        ),
                                      ]
                                    : [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "pc_biz_nav_btn_allapplication"
                                            )
                                          )
                                        ),
                                      ],
                                  _c("i", {
                                    class: _vm.showAppPopper
                                      ? "yxt-icon-arrow-up"
                                      : "yxt-icon-arrow-down",
                                  }),
                                ],
                                2
                              ),
                            ]),
                          ],
                          2
                        )
                      : _c(
                          "span",
                          {
                            class: [
                              "yxtbiz-nav-top__menu-name",
                              _vm.tinyNav && "yxtbiz-nav-top-tiny__menu-name",
                            ],
                          },
                          [
                            _c(
                              "a-link",
                              {
                                attrs: { url: menu.pageUrl, "page-node": menu },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm._f("Globalize")(menu, "name"))
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                  ],
                  1
                )
              }),
              _c(
                "li",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.menuSet.more,
                      expression: "menuSet.more",
                    },
                  ],
                  staticClass: "yxtbiz-nav-top__menu_more yxtbiz-nav-top__menu",
                  attrs: { id: _vm.navMenuMoreId },
                },
                [
                  _c(
                    "yxt-dropdown",
                    {
                      attrs: {
                        placement: "bottom-start",
                        "custom-elm": "",
                        trigger: "hover",
                      },
                      on: {
                        "visible-change": function ($event) {
                          _vm.isShowMore = !_vm.isShowMore
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "yxtbiz-nav-top__menu-name hover-primary-6-i",
                          class: {
                            "yxtbiz-nav-top-tiny__menu-name": _vm.tinyNav,
                            "yxtbiz-nav-top-tiny__menu-name-font": _vm.tinyNav,
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("pc_biz_nav_btn_more")) +
                              "\n            "
                          ),
                          _c("i", {
                            class: !_vm.isShowMore
                              ? "yxt-icon-arrow-down"
                              : "yxt-icon-arrow-up",
                          }),
                        ]
                      ),
                      _c(
                        "yxt-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        [
                          _vm._l(_vm.navsOnMenu, function (sMenu, sIndex) {
                            return [
                              sIndex + 1 > _vm.menuSet.max
                                ? _c(
                                    "yxt-dropdown-item",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: sMenu.showed,
                                          expression: "sMenu.showed",
                                        },
                                      ],
                                      key: sMenu.id,
                                      nativeOn: {
                                        click: function ($event) {
                                          return (() =>
                                            _vm.toSubPage(sMenu)).apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "a-link",
                                        {
                                          attrs: {
                                            url: sMenu.pageUrl,
                                            "page-node": sMenu,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("Globalize")(sMenu, "name")
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ]
      ),
      _c(
        "yxt-row",
        {
          staticClass: "yxtbiz-nav-top__my-panel",
          attrs: { type: "flex", align: "middle" },
        },
        [
          _c("nav-production-enter", { attrs: { tiny: _vm.tinyNav } }),
          _c("nav-ai-helper", {
            attrs: { isXxv2: _vm.isXxv2, tiny: _vm.tinyNav },
          }),
          _c(
            "yxt-tooltip",
            {
              attrs: {
                content: _vm.$t("biz_core_notice_center").d("通知中心"),
              },
            },
            [
              _c(
                "a-link",
                {
                  staticClass: "yxtbiz-nav-top__notice-bell",
                  attrs: {
                    mode: "block",
                    url: `${_vm.defaultProductAllPath}/#/user/noticecenter`,
                  },
                },
                [_c("notice-bell")],
                1
              ),
            ],
            1
          ),
          _c(
            "yxt-dropdown",
            { attrs: { trigger: "hover", "hide-on-click": false } },
            [
              _c("yxt-avatar", {
                staticClass: "hand yxtbiz-nav-top__my-avatar",
                attrs: {
                  shape: "circle",
                  size: `${_vm.tinyNav ? "20" : "30"}px`,
                  username: _vm.userInfo.userShortName,
                  imgUrl: _vm.userInfo.headPictureUrl,
                },
              }),
              _c(
                "span",
                {
                  class: [
                    "color-gray-9 yxtbiz-nav-top__avatar-name",
                    _vm.tinyNav && "yxtbiz-nav-top-tiny__avatar-name",
                  ],
                },
                [
                  _c(
                    "span",
                    [
                      _c("yxtbiz-user-name", {
                        attrs: { name: _vm.userInfo.userShortName },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "yxt-dropdown-menu",
                {
                  staticClass: "yxtbiz-nav-top__my-menus",
                  attrs: { slot: "dropdown", scrollable: false },
                  slot: "dropdown",
                },
                [
                  _c("nav-org-list", {
                    attrs: {
                      className: [
                        "yxtbiz-nav-top__my-menu",
                        _vm.tinyNav && "yxtbiz-nav-top-tiny__my-menu",
                        "color-gray-9",
                      ],
                    },
                  }),
                  _c(
                    "yxt-dropdown-item",
                    {
                      attrs: { disabled: _vm.goStudentButtonLoading },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.goStuIndex.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          class: [
                            "yxtbiz-nav-top__my-menu",
                            _vm.tinyNav && "yxtbiz-nav-top-tiny__my-menu",
                          ],
                        },
                        [_vm._v(_vm._s(_vm.$t("pc_biz_nav_btn_gostudent")))]
                      ),
                    ]
                  ),
                  !_vm.EDITION_TOOL
                    ? _c(
                        "yxt-dropdown-item",
                        {
                          nativeOn: {
                            click: function ($event) {
                              return _vm.personCenter.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "a-link",
                            {
                              class: [
                                "yxtbiz-nav-top__my-menu",
                                _vm.tinyNav && "yxtbiz-nav-top-tiny__my-menu",
                              ],
                              attrs: {
                                url: _vm.isOpenData()
                                  ? `${_vm.allPath}/#/user/noticecenter`
                                  : `${_vm.allPath}/#/userinfo`,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("pc_biz_nav_btn_usercenter"))
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$attrs.isOpenI18n && _vm.isXxv2
                    ? _c(
                        "yxt-dropdown-item",
                        { staticClass: "yxtbiz-nav-top__hover-lang" },
                        [
                          _c(
                            "yxtbiz-nav-lang",
                            _vm._b(
                              {
                                attrs: {
                                  placement: "left",
                                  "append-to-body": false,
                                },
                              },
                              "yxtbiz-nav-lang",
                              _vm.$attrs,
                              false
                            ),
                            [
                              _c(
                                "span",
                                {
                                  class: [
                                    "yxtbiz-nav-top__my-menu",
                                    _vm.tinyNav &&
                                      "yxtbiz-nav-top-tiny__my-menu",
                                    "color-gray-9",
                                  ],
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("pc_biz_core_showlanguage"))
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isISV && !_vm.isHideLogout
                    ? _c(
                        "yxt-dropdown-item",
                        {
                          nativeOn: {
                            click: function ($event) {
                              return _vm.logout.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              class: [
                                "yxtbiz-nav-top__my-menu",
                                _vm.tinyNav && "yxtbiz-nav-top-tiny__my-menu",
                              ],
                            },
                            [_vm._v(_vm._s(_vm.$t("pc_biz_nav_btn_logout")))]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("work-bench", {
            attrs: { mainPathname: _vm.mainPathname, tiny: _vm.tinyNav },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }