var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isShowDrawer
    ? _c(
        "div",
        { staticClass: "yxtbiz-i18n-lang" },
        [
          _c(
            "yxt-drawer",
            _vm._b(
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                class: _vm.drawerClass,
                attrs: {
                  title: _vm.getTranslate("pc_biz_core_tip_i18n"),
                  visible: _vm.visible,
                  "before-close": _vm.beforeClose,
                  size: _vm.$attrs.size || "640px",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.visible = $event
                  },
                },
              },
              "yxt-drawer",
              _vm.$attrs,
              false
            ),
            [
              _vm._l(_vm.listData, function (item) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "yxtbiz-i18n-lang__border-bottom",
                  },
                  [
                    item.type != "image"
                      ? _c(
                          "div",
                          [
                            _c(
                              "h3",
                              { staticClass: "yxtbiz-i18n-lang__drawer-title" },
                              [_vm._v(_vm._s(item.name))]
                            ),
                            _c(
                              "yxt-form",
                              {
                                ref: "ruleForm" + item.id,
                                refInFor: true,
                                staticClass: "yxtbiz-i18n-lang__drawer-form",
                                attrs: {
                                  model: item.ruleForm,
                                  rules: item.rules,
                                  "label-width": "100px",
                                },
                              },
                              _vm._l(_vm.langList, function (lang) {
                                return _c(
                                  "yxt-form-item",
                                  {
                                    key: item.id,
                                    attrs: {
                                      label: lang.langName,
                                      prop: lang.langTag,
                                    },
                                  },
                                  [
                                    item.type != "richeditor"
                                      ? _c(
                                          "yxt-input",
                                          _vm._b(
                                            {
                                              attrs: {
                                                type: item.type,
                                                placeholder: _vm.getTranslate(
                                                  "pc_biz_core_tip_placeholder"
                                                ),
                                                "show-word-limit":
                                                  item.rule &&
                                                  item.rule.showWordLimit,
                                                maxlength:
                                                  (item.rule &&
                                                    item.rule.maxlength &&
                                                    item.rule.maxlength[
                                                      lang.langTag
                                                    ]) ||
                                                  "2000",
                                              },
                                              model: {
                                                value:
                                                  item.ruleForm[lang.langTag],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item.ruleForm,
                                                    lang.langTag,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.ruleForm[lang.langTag]",
                                              },
                                            },
                                            "yxt-input",
                                            item.rule,
                                            false
                                          )
                                        )
                                      : _vm._e(),
                                    item.type == "richeditor"
                                      ? _c(
                                          "yxtbiz-richeditor",
                                          _vm._b(
                                            {
                                              ref:
                                                "richeditor" +
                                                lang.langTag +
                                                item.id,
                                              refInFor: true,
                                              staticClass: "richeditor-class",
                                              attrs: {
                                                "function-name": "richeditor",
                                                "org-code": "xxv2",
                                                "app-code": "core",
                                                "module-name": "msg",
                                                content:
                                                  item.ruleForm[lang.langTag],
                                              },
                                              on: {
                                                change: (ctx) =>
                                                  _vm.changeCtx(
                                                    item,
                                                    lang.langTag,
                                                    ctx
                                                  ),
                                              },
                                            },
                                            "yxtbiz-richeditor",
                                            item.rule,
                                            false
                                          )
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    item.type == "image"
                      ? _c(
                          "div",
                          [
                            _c(
                              "h3",
                              { staticClass: "yxtbiz-i18n-lang__drawer-title" },
                              [
                                _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "yxtbiz-i18n-lang__drawer-title",
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                ),
                                (item.rule &&
                                  item.rule.suggest &&
                                  item.rule.suggest.w &&
                                  item.rule.suggest.h) ||
                                item.rule.filters ||
                                item.rule.size
                                  ? _c("span", [
                                      _vm._v("（\n            "),
                                      item.rule &&
                                      item.rule.suggest &&
                                      item.rule.suggest.w &&
                                      item.rule.suggest.h
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getTranslate(
                                                  "pc_biz_core_tip_size"
                                                )
                                              ) +
                                                "：" +
                                                _vm._s(
                                                  (item.rule.suggest &&
                                                    item.rule.suggest.w) ||
                                                    "750"
                                                ) +
                                                "*" +
                                                _vm._s(
                                                  item.rule.suggest **
                                                    item.rule.suggest.h || "348"
                                                ) +
                                                "px；"
                                            ),
                                          ])
                                        : _vm._e(),
                                      item.rule && item.rule.filters
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getTranslate(
                                                  "pc_biz_imgcropper_lbl_filter"
                                                )
                                              ) +
                                                "：" +
                                                _vm._s(
                                                  item.rule.filters ||
                                                    ".jpg,.gif,.png,.jpeg,.bmp,.svg"
                                                ) +
                                                "；"
                                            ),
                                          ])
                                        : _vm._e(),
                                      item.rule && item.rule.size
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getTranslate(
                                                  "pc_biz_core_lbl_sizesnotexceed"
                                                )
                                              ) +
                                                _vm._s(
                                                  item.rule.size || "512"
                                                ) +
                                                "kb"
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v("）\n          "),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "yxt-form",
                              {
                                ref: "ruleForm" + _vm.idx,
                                refInFor: true,
                                staticClass:
                                  "yxtbiz-i18n-lang__drawer-form yxtbiz-i18n-lang__drawer-form-img",
                                attrs: {
                                  model: item.ruleForm,
                                  rules: _vm.imgRules,
                                  "label-width": "100px",
                                },
                              },
                              _vm._l(_vm.langList, function (lang) {
                                return _c(
                                  "yxt-form-item",
                                  {
                                    key: lang.langTag,
                                    attrs: {
                                      label: lang.langName,
                                      prop: lang.langTag,
                                    },
                                  },
                                  [
                                    _c(
                                      "yxtbiz-upload-image",
                                      _vm._b(
                                        {
                                          attrs: {
                                            list: item.ruleForm[lang.langTag]
                                              ? [
                                                  {
                                                    url:
                                                      item.ruleForm[
                                                        lang.langTag
                                                      ] || "",
                                                  },
                                                ]
                                              : [],
                                          },
                                          on: {
                                            fileAdded: ({ index, url, file }) =>
                                              _vm.fileAdded(
                                                { index, url, file },
                                                item.langKey,
                                                lang.langTag
                                              ),
                                            fileRemoved: () =>
                                              _vm.fileRemoved(
                                                item.langKey,
                                                lang.langTag
                                              ),
                                            update: _vm.update,
                                            uploadStart: _vm.onUploadStart,
                                            uploadComplete:
                                              _vm.onUploadComplete,
                                          },
                                        },
                                        "yxtbiz-upload-image",
                                        item.rule,
                                        false
                                      )
                                    ),
                                  ],
                                  1
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                )
              }),
              _c(
                "div",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "yxt-button",
                    { attrs: { plain: "" }, on: { click: _vm.handleClose } },
                    [_vm._v(_vm._s(_vm.getTranslate("pc_biz_ote_btn_cancle")))]
                  ),
                  _c(
                    "yxt-button",
                    { attrs: { type: "primary" }, on: { click: _vm.complete } },
                    [
                      _vm._v(
                        _vm._s(_vm.getTranslate("pc_biz_core_btn_confirm"))
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }