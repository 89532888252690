var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "checkedlist" }, [
    _c(
      "div",
      { staticClass: "header" },
      [
        _vm.tabs.length > 1
          ? _c("span", [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("pc_biz_signup_lbl_checked").d("已选"))),
              ]),
              _vm.limit > 0
                ? _c("span", { staticStyle: { color: "#999" } }, [
                    _c("span", { staticStyle: { color: "#262626" } }, [
                      _vm._v("：" + _vm._s(_vm.checkedDataCount)),
                    ]),
                    _vm._v("\n         /" + _vm._s(_vm.limit)),
                  ])
                : _vm._e(),
            ])
          : _c("span", [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("pc_biz_signup_lbl_checked").d("已选")) +
                    _vm._s(
                      _vm.checkedData[_vm.tab0Type].length
                        ? `：${_vm.checkedData[_vm.tab0Type].length}`
                        : "：0"
                    )
                ),
              ]),
              _vm.limit > 0
                ? _c("span", { staticStyle: { color: "#999" } }, [
                    _vm._v(" /" + _vm._s(_vm.limit)),
                  ])
                : _vm._e(),
            ]),
        _vm.showClearAllBtn
          ? _c(
              "yxt-button",
              { attrs: { type: "text" }, on: { click: _vm.clearAll } },
              [_vm._v(_vm._s(_vm.$t("pc_biz_labelling_clearall").d("清空")))]
            )
          : _vm._e(),
      ],
      1
    ),
    _c(
      "main",
      { staticClass: "checkedlist-main" },
      [
        !_vm.showClearAllBtn
          ? _c("div", { staticClass: "checkedlist-main-placeholder" }, [
              _vm._v(
                _vm._s(_vm.$t("biz_udp_please_select").d("请选择")) + "..."
              ),
            ])
          : _vm._e(),
        _vm._l(_vm.checkedList, function (group, index) {
          return _c("div", { key: index, staticClass: "group-main" }, [
            _vm.tabs.length > 1
              ? _c(
                  "div",
                  { staticClass: "group-main-title" },
                  [
                    _c("yxt-svg", {
                      staticClass: "mr5 v-mid",
                      attrs: {
                        width: "14px",
                        height: "14px",
                        "icon-class": group.iconClass,
                      },
                    }),
                    _c("span", [_vm._v(_vm._s(group.name) + "：")]),
                    _c("span", [_vm._v(_vm._s(group.count))]),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "group-main-content" },
              [
                _vm._l(
                  group.data.slice(0, group.showCount),
                  function (item, key) {
                    return _c(
                      "yxt-tag",
                      {
                        key: key,
                        staticClass: "mr5",
                        staticStyle: {
                          "margin-bottom": "8px",
                          "max-width": "100%",
                        },
                        attrs: {
                          size: "small",
                          type: "info",
                          disableTransitions: true,
                          closable: "",
                        },
                        on: {
                          close: function ($event) {
                            return _vm.deleteItem(group.type, item)
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "checkedlist-tag" }, [
                          group.type === "department"
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "yxt-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("template", { slot: "content" }, [
                                        _c(
                                          "span",
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.splicingOrgName(
                                                    item.orgName
                                                  )
                                                ) +
                                                "\n                    "
                                            ),
                                            _c("yxtbiz-dept-name", {
                                              attrs: { name: item.selectName },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _c(
                                        "span",
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.splicingOrgName(
                                                  item.orgName
                                                )
                                              ) +
                                              "\n                  "
                                          ),
                                          _c("yxtbiz-dept-name", {
                                            attrs: { name: item.selectName },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            : group.type === "persons"
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "yxt-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("template", { slot: "content" }, [
                                        _c(
                                          "span",
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.splicingOrgName(
                                                    item.orgName
                                                  )
                                                ) +
                                                "\n                    "
                                            ),
                                            _c("yxtbiz-user-name", {
                                              attrs: { name: item.selectName },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _c(
                                        "span",
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.splicingOrgName(
                                                  item.orgName
                                                )
                                              ) +
                                              "\n                  "
                                          ),
                                          _c("yxtbiz-user-name", {
                                            attrs: { name: item.selectName },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "span",
                                [
                                  _c(
                                    "yxt-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("template", { slot: "content" }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.splicingOrgName(item.orgName)
                                            ) +
                                            "\n                  "
                                        ),
                                        _c("span", [
                                          _vm._v(_vm._s(item.selectName)),
                                        ]),
                                      ]),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.splicingOrgName(item.orgName)
                                          ) + _vm._s(item.selectName)
                                        ),
                                      ]),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                        ]),
                        item.includeAll === 1
                          ? _c(
                              "span",
                              {
                                staticClass: "ml10",
                                staticStyle: { color: "#BFBFBF" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    item.includeAll === 1
                                      ? _vm.$t(
                                          "biz_udp_subsequent_all"
                                        ) /*后续全部*/
                                      : ""
                                  )
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    )
                  }
                ),
                group.showCount < group.count
                  ? _c(
                      "div",
                      [
                        _c(
                          "yxt-tag",
                          {
                            staticClass: "mr5",
                            staticStyle: {
                              "margin-bottom": "8px",
                              cursor: "pointer",
                            },
                            attrs: {
                              size: "small",
                              type: "info",
                              disableTransitions: true,
                            },
                            on: {
                              click: () => {
                                group.showCount += 50
                                _vm.showCountObj[group.type] += 50
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "checkedlist-tag" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("biz_pc_expand_more").d("展开更多")
                                )
                              ),
                            ]),
                            _c("i", {
                              staticClass: "yxt-icon-arrow-down",
                              staticStyle: { "margin-left": "6px" },
                            }),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                group.count > 6 && group.showCount >= group.count
                  ? _c(
                      "div",
                      [
                        _c(
                          "yxt-tag",
                          {
                            staticClass: "mr5",
                            staticStyle: {
                              "margin-bottom": "8px",
                              cursor: "pointer",
                            },
                            attrs: {
                              size: "small",
                              type: "info",
                              disableTransitions: true,
                            },
                            on: {
                              click: () => {
                                group.showCount = 6
                                _vm.showCountObj[group.type] = 6
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "checkedlist-tag" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("app_common_yxt_btn_close").d("收起")
                                )
                              ),
                            ]),
                            _c("i", {
                              staticClass: "yxt-icon-arrow-up",
                              staticStyle: { "margin-left": "6px" },
                            }),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              2
            ),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }