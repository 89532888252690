<template>
  <div class="more_position">
    <div class="table_wrapper">
      <div class="table_wrapper_search">
        <yxtbiz-group-org-select 
          class='mr12'
          @change="handleOrgChange"
          :selectFirst='true'
          :clearable="false"
          :disabled="disabled"
          :targetOrgId='targetOrgId'
          :setCurrentOrg="true"
          v-if="enableGroupCorp && visibleOrgSelector"
          :functionCode="functionCode"
          :dataPermissionCode="dataPermissionCode"
          :visibleOrgIds="visibleOrgIds">
          </yxtbiz-group-org-select>
        <select-popover v-if="isShowDeptment" style="margin-bottom: 16px;" ref="selectPopover" is-dept :selectOpition="selectOpition" >
          <div>
            <dept-tree
              v-if='(enableGroupCorp && params.targetOrgId) || !enableGroupCorp'
              :targetOrgId='params.targetOrgId'
              :visibleOrgSelector='false'
              :enableGroupCorp='enableGroupCorp'
              :placeholder="$t('biz_udp_deptmentname')"
              size="small"
              :wrap-width="260"
              filterable ref="deptTree"
              :default-expanded-keys="['']"
              @nodeClick="nodeClick"
              :function-code="functionCode"
              :data-permission-code="dataPermissionCode"
              :count="showDeptCount"
            ></dept-tree>
          </div>
        </select-popover>
        <yxt-input
          class="keyword ml12"
          @keyup.enter.native="getPositionData(true)"
          v-model="params.searchKey"
          maxlength="20"
          :placeholder="$t('biz_udp_enter_account')"
          style="width: 240px;"
          searchable
          @search='getPositionData(true)'
        >
        </yxt-input>
      </div>
      <yxt-table
        ref="multipleTable"
        class="person-table"
        @select="handleSingleSeletct"
        @select-all="handleSelectAll"
        row-key="id"
        :height="tableHeight"
        v-loading="loading"
        :data="tableData"
        style="width:100%;"
      >
        <yxt-table-column
          type="selection"
          :show-overflow-tooltip='false'
          class-name="table-selection-odd"
          width="40"
        >
        </yxt-table-column>
        <yxt-table-column
          show-overflow-tooltip
          popover-html
          :label="$t('pc_biz_udp_lbl_name')"
          min-width="180"
        >
          <template slot-scope="{ row }">
            <yxtbiz-user-name :name="row.fullname"></yxtbiz-user-name>
          </template>
        </yxt-table-column>
        <yxt-table-column
          show-overflow-tooltip
          :formatter="formatPostion"
          :label="$t('biz_udp_position')"
          min-width="180"
        >
        </yxt-table-column>
        <template slot="empty">
          <div style="line-height:normal">
            <yxt-empty />
          </div>
        </template>
      </yxt-table>
      <div class="yxtbiz-page__content">
        <div class="yxtbiz-page__content-right">
          <yxt-button
            type="text"
            class="lh32"
            :class="loadingAll?'color-primary-4 disabled':''"
            @click="loadAll"
            :disabled="!tableData.length"
            >{{$t('biz_udp_all_filter_results')}}</yxt-button>
            <div class="yxtbiz-loading__all" v-loading="loadingAll"></div>
        </div>
        <yxt-pagination
          small
          class="pages"
          :page-size="params.limit"
          :page-sizes="[5, 10, 20, 30, 50, 100]"
          @size-change="handleSizeChange"
          :current-page.sync="currentPage"
          layout="total, prev, pager, next"
          @current-change="handleCurrentChange"
          :total="total"
          simple-total
        >
        </yxt-pagination>
      </div>
    </div>
    <!-- <div class="list">
      <checked-list @clear="clear" @close="closeTag" :list="tempCheckedPositons" />
    </!-->
  </div>
</template>

<script>
import { getUserList } from '../service';
import deptTree from '../../../dept-tree';
import echoMixin from '../mixin/echo';
import resizeTable from '../../../user-selector/mixins/resizeTable';
import SelectPopover from './SelectPopover';
import authMixin from '../mixin/auth';
import { Table, TableColumn, Pagination, Empty } from 'yxt-pc';
import YxtBizUserName from 'yxt-biz-pc/packages/user-name';
import { i18n } from 'yxt-biz-pc/packages/common-util/changeLangs';

export default {
  components: {
    deptTree,
    SelectPopover,
    YxtTable: Table,
    YxtTableColumn: TableColumn,
    YxtPagination: Pagination,
    YxtEmpty: Empty,
    YxtBizUserName
  },
  name: 'UserGroup',
  mixins: [echoMixin, resizeTable, authMixin],
  props: {
    isShowDeptment: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loadingAll: false,
      refTable: 'multipleTable',
      currentPage: 1,
      total: 0,
      loading: true,
      tableHeight: 0,
      params: {
        searchKey: '',
        deptId: '',
        limit: 20,
        offset: 0,
        scope: 0
      },
      tableData: [],
      formatPostion: row => {
        if (row.positionName) {
          return row.positionName;
        }
        return row.positionSet
          .map(item => {
            return item.name;
          })
          .join(';');
      },
      selectOpition: {
        placeholder: i18n.t('biz_udp_select_dept'),
        checkWord: ''
      }
    };
  },
  mounted() {
    if (!this.enableGroupCorp) this.getPositionData();
  },
  watch: {
    targetOrgId: {
      handler() {
        if (this.enableGroupCorp && this.targetOrgId) {
          this.params.targetOrgId = this.targetOrgId;
          this.getPositionData();
        }
      },
      immediate: true
    }
  },
  methods: {
    handleOrgChange(data) {
      this.params.targetOrgId = data.orgId;
      this.targetOrgName = data.orgName;
      this.params.deptId = '';
      this.selectOpition.checkWord = '';
      this.getPositionData();
    },
    loadAll() {
      this.loadingAll = true;
      const params = Object.assign({}, { ...this.params, showAll: (this.options && this.options.showAll) ? 1 : 0 }, {
        limit: this.total,
        offset: 0
      });
      getUserList(params, this.enableGroupCorp).then(res => {
        if (res) {
          let data = res.datas.map(item => {
            item['_type'] = 5;
            item.orgId = this.params.targetOrgId;
            item.orgName = this.targetOrgName;
            return item;
          });
          this.$refs['multipleTable'].clearSelection();
          this.$refs['multipleTable'].toggleAllSelection();
          this.selectTotal(data);
        }
      }).catch(e => {
        console.error(e);
      }).then(() => {
        this.loadingAll = false;
      });
    },
    nodeClick(a, b, c) {
      this.params.deptId = a.id;
      this.selectOpition.checkWord = a.id ? a.name : '';
      this.$refs['selectPopover'].cancel();
      this.getPositionData(true);
    },
    getPositionData(reset = false) {
      this.loading = true;
      if (reset) {
        this.params.offset = 0;
        this.currentPage = 1;
      }
      this.params.navCode = this.functionCode;
      this.params.dataPermissionCode = this.dataPermissionCode;
      getUserList({ ...this.params, showAll: (this.options && this.options.showAll) ? 1 : 0 }, this.enableGroupCorp).then(res => {
        if (res) {
          this.tableData = res.datas.map(item => {
            item['_type'] = 5;
            item.orgId = this.params.targetOrgId;
            item.orgName = this.targetOrgName;
            return item;
          });
          this.total = res.paging.count;
          this.update();
        }
      }).catch((e) => {
        console.error(e);
      }).then(() => {
        this.loading = false;
      });
    },
    handleCurrentChange(val) {
      this.params.offset = (val - 1) * this.params.limit;
      this.getPositionData();
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.params.limit = val;
      this.getPositionData();
    }
  }
};
</script>
