var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yxtbiz-support-sidebar" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.drawer,
              expression: "!drawer",
            },
          ],
          staticClass: "sidebar-entry sidebar-entry__show",
        },
        [
          _vm.showAi
            ? _c(
                "div",
                { staticClass: "sidebar-ai hand", on: { click: _vm.openAi } },
                [
                  _c("yxt-svg", {
                    staticClass: "sidebar-ai-icon",
                    attrs: {
                      "remote-url": `${_vm.$staticBaseUrl}ufd/407a24/assistent/pc/svg`,
                      width: "16",
                      height: "16",
                      "icon-class": "ai-log1",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.showSupport
            ? _c(
                "span",
                { staticClass: "center", on: { click: _vm.handleShowDrawer } },
                [
                  _vm._v("帮助中心"),
                  _c("i", { staticClass: "yxt-icon-arrow-left" }),
                ]
              )
            : _vm._e(),
          _vm.showCustomService && _vm.onlineServiceShow
            ? _c("span", { staticClass: "custom support-customerService" }, [
                _c("i", { staticClass: "custom-icon" }),
                _vm._v("客服"),
              ])
            : _vm._e(),
          _vm.isShowHotline
            ? _c("div", { staticClass: "support-hotline" }, [
                _c(
                  "div",
                  { staticClass: "support-hotline__inner standard-size-12" },
                  [
                    _c("yxt-svg", {
                      staticClass: "color-gray-9",
                      attrs: { "icon-class": "hotline" },
                    }),
                    _vm.isTieFinance
                      ? _c(
                          "div",
                          { staticClass: "color-gray-10 yxt-weight-5" },
                          [_vm._v("\n          400-900-2217\n        ")]
                        )
                      : _c(
                          "div",
                          { staticClass: "color-gray-10 yxt-weight-5" },
                          [_vm._v("400-8397-880")]
                        ),
                    _c("div", { staticClass: "color-gray-7" }, [
                      _vm._v("9:00～18:30"),
                    ]),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]
      ),
      _c(
        "yxt-drawer",
        {
          ref: "sidebar",
          staticClass: "yxtbiz-sidebar-drawer",
          style: _vm.isExistTopNav
            ? `padding-top: ${_vm.offsetTop}px`
            : `padding-top: 0px`,
          attrs: {
            title: "帮助中心",
            visible: _vm.drawer,
            "before-close": _vm.handleCloseDrawer,
            direction: "rtl",
            size: "280px",
            modal: false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
            open: _vm.handleOpen,
          },
        },
        [
          _c("yxt-scrollbar", { attrs: { "fit-height": true } }, [
            _c("div", { staticClass: "yxtbiz-supportslidbar__wrap" }, [
              _c("div", { staticClass: "sidebar-packup" }, [
                _c(
                  "span",
                  {
                    staticClass: "packup",
                    on: { click: _vm.handleCloseDrawer },
                  },
                  [
                    _vm._v("收起"),
                    _c("i", { staticClass: "yxt-icon-arrow-right" }),
                  ]
                ),
              ]),
              _vm.showNav && _vm.navigations && _vm.navigations.length > 0
                ? _c(
                    "div",
                    { key: "navigations", staticClass: "module" },
                    [
                      _c("header", [
                        _c("i", { staticClass: "nav-icon" }),
                        _vm._v("功能导航"),
                      ]),
                      _c("navigation", { attrs: { data: _vm.navigations } }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showNav && _vm.hotIssues && _vm.hotIssues.length > 0
                ? _c(
                    "div",
                    { key: "hotIssues", staticClass: "module" },
                    [
                      _c("header", [
                        _c("i", { staticClass: "hot-qa-icon" }),
                        _vm._v("热门问题"),
                      ]),
                      _c("contents", {
                        attrs: {
                          active: _vm.active,
                          data: _vm.hotIssues,
                          type: "hotIssues",
                        },
                        on: { select: _vm.handleSelect },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.showNav && _vm.tutorials && _vm.tutorials.length > 0
                ? _c(
                    "div",
                    { key: "tutorials", staticClass: "module" },
                    [
                      _c("header", [
                        _c("i", { staticClass: "course-icon" }),
                        _vm._v("使用教程"),
                      ]),
                      _c("contents", {
                        attrs: { active: _vm.active, data: _vm.tutorials },
                        on: { select: _vm.handleSelect },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.showNav && _vm.issues && _vm.issues.length > 0
                ? _c(
                    "div",
                    { key: "issues", staticClass: "module" },
                    [
                      _c("header", [
                        _c("i", { staticClass: "hot-qa-icon" }),
                        _vm._v("常见问题"),
                      ]),
                      _c("contents", {
                        attrs: { active: _vm.active, data: _vm.issues },
                        on: { select: _vm.handleSelect },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "footer-container",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "button",
                {
                  staticClass: "support-center",
                  attrs: { type: "button" },
                  on: { click: _vm.handleShowCenter },
                },
                [
                  _c("i", { staticClass: "center-icon" }),
                  _vm._v("帮助中心\n      "),
                ]
              ),
              _vm.showCustomService
                ? _c("span", { staticClass: "split-line" })
                : _vm._e(),
              _vm.showCustomService
                ? _c(
                    "button",
                    {
                      staticClass: "support-customerService",
                      attrs: { type: "button" },
                    },
                    [
                      _c("i", { staticClass: "custom-icon" }),
                      _vm._v("联系客服\n      "),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _vm.showAiDialog
        ? _c("yxtbiz-ai-robot", {
            on: {
              close: function ($event) {
                _vm.showAiDialog = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }