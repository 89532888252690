var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "yxt-row",
        { attrs: { gutter: 40 } },
        [
          _c(
            "yxt-col",
            {
              staticStyle: { "max-height": "650px", overflow: "auto" },
              attrs: { span: 8 },
            },
            [
              _c("pos-tree", {
                attrs: {
                  functionCode: _vm.functionCode,
                  dataPermissionCode: _vm.dataPermissionCode,
                  filterable: true,
                },
                on: { nodeClick: _vm.selectCatalog },
              }),
            ],
            1
          ),
          _c(
            "yxt-col",
            { attrs: { span: 16 } },
            [
              _c("yxt-input", {
                attrs: {
                  placeholder: _vm.$t("biz_udp_enter_positionname"),
                  size: "small",
                  searchable: "",
                },
                on: { search: _vm.searchPostion },
                model: {
                  value: _vm.searchValue,
                  callback: function ($$v) {
                    _vm.searchValue = $$v
                  },
                  expression: "searchValue",
                },
              }),
              _c(
                "yxt-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "posTable",
                  staticClass: "mt20",
                  attrs: { data: _vm.positions },
                  on: {
                    "select-all": _vm.selectAllPosition,
                    select: _vm.selectPosition,
                  },
                },
                [
                  _c("yxt-table-column", {
                    attrs: { type: "selection", width: "40" },
                  }),
                  _c("yxt-table-column", {
                    attrs: { label: _vm.$t("biz_udp_pos_name"), prop: "name" },
                  }),
                  _c("yxt-table-column", {
                    attrs: {
                      label: _vm.$t("biz_udp_occupation_level"),
                      prop: "gradeList",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                (scope.row.gradeList || [])
                                  .map((item) => item.name)
                                  .join() || "--"
                              )
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("yxt-table-column", {
                    attrs: {
                      label: _vm.$t("biz_udp_pos_category"),
                      prop: "catalogName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mt10 clearfix" },
                [
                  _c("yxt-pagination", {
                    staticClass: "pull-right",
                    attrs: {
                      background: "",
                      small: "",
                      "page-size": _vm.posData.limit,
                      "page-sizes": [10, 15, 20],
                      "current-page": _vm.page,
                      layout: "prev, pager, next,sizes,jumper",
                      "page-count": _vm.totalPage,
                    },
                    on: {
                      "size-change": _vm.sizeChange,
                      "current-change": _vm.pageChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }