// import Personalitytemplate from './src/main';
import I18nCustomTemplate from '../i18n-custom-template/src/main.vue';

const Personalitytemplate = Object.assign({}, I18nCustomTemplate, {name: 'YxtbizPersonalityTemplate'});

/* istanbul ignore next */
Personalitytemplate.install = function(Vue) {
  Vue.component(Personalitytemplate.name, Personalitytemplate);
};

export default Personalitytemplate;
