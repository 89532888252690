<template>
  <div class="more_position">
    <div class="table_wrapper">
      <yxt-row type='flex'>
        <yxtbiz-group-org-select 
          class='mr12'
          :functionCode="functionCode"
          :dataPermissionCode="dataPermissionCode"
          @change="handleOrgChange"
          v-if='enableGroupCorp && visibleOrgSelector'
          :selectFirst='true'
          :clearable="false">
          </yxtbiz-group-org-select>
        <yxt-input
          class="keyword"
          @keyup.enter.native="getPositionData(true)"
          v-model="params.name"
          :placeholder="$t('biz_udp_enter_occupationname')"
          style="width: 240px;"
          searchable
          @search='getPositionData(true)'
        >
        </yxt-input>
      </yxt-row>
      <yxt-table
        ref="multipleTable"
        class="person-table"
        :height="tableHeight"
        @select="handleSingleSeletct"
        @select-all="handleSelectAll"
        row-key="id"
        v-loading="loading"
        :data="tableData"
        style="width:100%;"
      >
        <yxt-table-column
        reserve-selection
          class-name="table-selection-odd"
          type="selection"
          :show-overflow-tooltip='false'
          width="40"
        >
        </yxt-table-column>
        <yxt-table-column
          prop="name"
          show-overflow-tooltip
          :label="$t('biz_udp_occupation_level')"
          min-width="180"
        >
        </yxt-table-column>
        <template slot="empty">
          <div style="line-height:normal">
            <yxt-empty />
          </div>
        </template>
      </yxt-table>
      <div class="yxtbiz-page__content">
        <div class="yxtbiz-page__content-right">
          <yxt-button
            type="text"
            class="lh32"
            :class="loadingAll?'color-primary-4 disabled':''"
            @click="loadAll"
            :disabled="!tableData.length"
            >{{$t('biz_udp_all_filter_results')}}</yxt-button>
            <div class="yxtbiz-loading__all" v-loading="loadingAll"></div>
        </div>
        <yxt-pagination
          small
          class="pages"
          :page-size="params.limit"
          :page-sizes="[5, 10, 20, 30, 50, 100]"
          @size-change="handleSizeChange"
          :current-page.sync="currentPage"
          layout="total, prev, pager, next"
          @current-change="handleCurrentChange"
          :total="total"
          simple-total
        >
        </yxt-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getLevel } from '../service';
import echoMixin from '../mixin/echo';
import resizeTable from '../../../user-selector/mixins/resizeTable';
import authMixin from '../mixin/auth';
import { Table, TableColumn, Pagination, Empty } from 'yxt-pc';
import { mapGetters } from 'vuex';

export default {
  name: 'Role',
  components: {
    YxtTable: Table,
    YxtTableColumn: TableColumn,
    YxtPagination: Pagination,
    YxtEmpty: Empty
  },
  mixins: [echoMixin, resizeTable, authMixin],
  data() {
    return {
      tableHeight: 0,
      refTable: 'multipleTable',
      currentPage: 1,
      total: 0,
      loading: true,
      params: {
        name: '',
        limit: 20,
        offset: 0
      },
      tableData: [],
      loadingAll: false
    };
  },
  mounted() {
    if (!(this.enableGroupCorp && this.visibleOrgSelector)) this.getPositionData();
  },
  computed: {
    ...mapGetters('navManageStore')
  },
  methods: {
    handleOrgChange(data) {
      this.params.targetOrgId = data.orgId;
      this.targetOrgName = data.orgName;
      this.params.name = '';
      this.getPositionData();
    },
    loadAll() {
      this.loadingAll = true;
      const params = Object.assign({}, this.params, {
        limit: this.total,
        offset: 0
      });
      params.navCode = this.functionCode;
      params.dataPermissionCode = this.dataPermissionCode;
      getLevel(params, this.enableGroupCorp).then(res => {
        if (res) {
          let data = res.datas.map(item => {
            item['_type'] = 4;
            item.orgId = this.params.targetOrgId;
            item.orgName = this.targetOrgName;
            return item;
          });
          this.$refs['multipleTable'].clearSelection();
          this.$refs['multipleTable'].toggleAllSelection();
          this.selectTotal(data);
        }
      }).catch(e => {
        console.error(e);
      }).then(() => {
        this.loadingAll = false;
      });
    },
    getPositionData(reset = false) {
      this.loading = true;
      if (reset) {
        this.params.offset = 0;
        this.currentPage = 1;
      }
      getLevel(this.params, this.enableGroupCorp).then(res => {
        if (res) {
          this.tableData = res.datas.map(item =>{
            item['_type'] = 4;
            item.orgId = this.params.targetOrgId;
            item.orgName = this.targetOrgName;
            return item;
          });
          this.total = res.paging.count;
          this.update();
        }
      }).catch(e => {
        console.error(e);
      }).then(() => {
        this.loading = false;
      });

    },
    handleCurrentChange(val) {
      this.params.offset = (val - 1) * this.params.limit;
      this.getPositionData();
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.params.limit = val;
      this.getPositionData();
    }
  }
};
</script>
