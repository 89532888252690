var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "article",
    { staticClass: "yxtbiz-ai-robot-my-comments" },
    [
      _vm.dataList.length === 0
        ? _c("EmptyData")
        : [
            _c(
              "header",
              {
                class: [
                  "color-gray-7",
                  _vm.size === "small"
                    ? "font-size-14 lh24"
                    : "font-size-16 lh28",
                ],
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$t(
                        "yxtbiz_ai_lbl_followingcomments" /* 为您找到以下{0}评论 */,
                        [_vm.filterTitle]
                      )
                    ) +
                    "\n    "
                ),
              ]
            ),
            _c(
              "main",
              [
                _c(
                  "ul",
                  _vm._l(_vm.dataList, function (item) {
                    return _c(
                      "li",
                      {
                        key: item.id,
                        staticClass: "yxtbiz-ai-robot-my-comments__item",
                      },
                      [
                        _c("comment", {
                          attrs: {
                            params: _vm.params,
                            size: _vm.size,
                            item: item,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "title",
                                fn: function ({ content }) {
                                  return _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.filterXss(content.content)
                                      ),
                                    },
                                  })
                                },
                              },
                              {
                                key: "catalogName",
                                fn: function ({ catalogName }) {
                                  return _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.filterXss(catalogName.catalogName)
                                      ),
                                    },
                                  })
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
                _vm.count > 3
                  ? _c("ViewMoreButton", {
                      on: { click: _vm.handleViewMoreClick },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }