<template>
  <base-list
    :dataList="datas"
    :size="size"
    @to-list="toList"
    @to-more="toListMore">
    <!-- slot插槽处理 -->
    <template slot-scope="{ list }">
      <span>{{ list.createFullname }}</span>
      <yxt-divider direction="vertical"></yxt-divider>
      <!-- {0} 创建 | 创建时间 -->
      <span>{{ $t('pc_biz_ote_lbl_create_time_tips', [shortDateTime(list.createTime)]) }}</span>
    </template>
  </base-list>
</template>

<script>
import CommonMixin from '../common/props-mixin';
import { shortDateTime } from '../search-exam/utils.js';
import BaseList from '../search-exam/base-list.vue';

export default {
  name: 'TrackExam',
  components: {
    BaseList
  },
  mixins: [CommonMixin],
  methods: {
    shortDateTime,
    toList(list) {
      window.open(`${window.location.origin}/ote/#/arrange/testtracking/track/student?id=${list.id}`, '_blank');
    },
    toListMore() {
      localStorage['ai-track-exam'] = JSON.stringify(this.params);
      console.log(this.params);
      window.open(`${window.location.origin}/ote/#/arrange/mgmt`, '_blank');
    }
  }
};
</script>