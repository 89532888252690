<template>
  <import :functionCode='functionCode'
          :dataPermissionCode='dataPermissionCode'
          :value="list"
          @input="input"
          @onProcess="onProcess" />
</template>
<script>
import Import from 'yxt-biz-pc/packages/user-selector/src/components/import'; // 暂时复用组件
export default {
  components: {
    Import
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    functionCode: {
      type: String,
      default: ''
    },
    dataPermissionCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {};
  },
  created() {
  },
  methods: {
    input(datas) {
      datas.forEach(item => {
        if (!item['_type']) {
          // 这里只对没有任何类型的数据进行赋值
          item['_type'] = 5;
        }
      });
      this.$emit('update', datas);
    },
    // 导入流程阶段状态变化回调
    onProcess(processing) {
      this.$emit('onProcess', processing);
    },
    // 防止外部调用报错
    closeTag() {

    }
  }
};
</script>
