<template>
  <div
    ref="currentEle"
    class="yxtbiz-pos-tree"
    :class="{'yxtbiz-dept-tree--autosize': autosize}"
    v-loading="loading"
  >
  <yxtbiz-group-org-select
      v-if='enableGroupCorp && visibleOrgSelector'
      @change='handleOrgChange'
      :setCurrentOrg='true'
      :selectFirst="true"
      :clearable="false"
      :functionCode="functionCode"
      :dataPermissionCode="dataPermissionCode"
      class='mr12 mb12'
      style='width: 100%'
      />
    <yxt-input
      :style="{width: wrapWidth + 'px'}"
      v-if="filterable"
      ref="input"
      class="yxtbiz-dept-tree__input"
      :placeholder="placeholder"
      :size="size"
      v-model="filterText"
      @search="filter"
      searchable
    ></yxt-input>
    <yxtbiz-funs-tree
      ref="tree"
      :props="props"
      :highlight-current="highlightCurrent"
      node-key="id"
      :wrap-width="wrapWidth"
      :check-strictly="checkStrictly"
      :show-checkbox="showCheckbox"
      :default-expanded-keys="expandTempShow"
      @node-click="nodeClick"
      :functions="functions"
      :functionsRender="functionsRender"
      @node-expand="nodeExpand"
      @node-collapse="nodeCollapse"
      :filter-node-method="filterNode"
      @check="handlerCheck"
      @check-change="handleCheckChange"
      :native="native"
      :dropdownToBody="dropdownToBody"
      :default-checked-keys="defaultCheckedKeys"
      :virtual="true"
    ></yxtbiz-funs-tree>
  </div>
</template>

<script>
import { getPositionTree } from './service';
import treeMixin from 'yxt-biz-pc/src/mixins/manualTree';
import { i18n } from '../../common-util/changeLangs';
export default {
  name: 'YxtbizPosTree',
  mixins: [treeMixin],
  props: {
    categoryOnly: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: function() {
        return i18n.t('biz_udp_enter_pos_search');
      }
    },
    isAuthInterface: {
      // 是否使用带权限的岗位树接口
      type: Boolean,
      default: false
    },
    noPositions: {
      type: Boolean,
      default: false
    },
    filterPositionOnly: {
      type: Boolean,
      default: false
    },
    targetOrgId: {
      type: Number | String
    },
    enableGroupCorp: {
      type: Boolean,
      default: false
    },
    visibleOrgSelector: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    targetOrgId(value) {
      this.currentOrgId = value;
      this.dataInit();
    }
  },
  data() {
    return {
      currentOrgId: ''
    };
  },
  created() {
    this.currentOrgId = this.targetOrgId;
    if (!this.categoryOnly) {
      this.props.nocheck = data => {
        return data.type === 0;
      };
    }
    this.props = {...this.props, ...this.treeProps};
  },
  methods: {
    handleOrgChange(data) {
      this.currentOrgId = data.orgId;
      this.filterText = '';
      this.dataInit();
      this.$emit('org-change', data);
    },
    dataInit() {
      getPositionTree({ targetOrgId: this.currentOrgId, includeFlag: !this.categoryOnly, navCode: this.functionCode, dataPermissionCode: this.dataPermissionCode, isAuthInterface: this.isAuthInterface })
        .then(res => {
          if (this.noPositions) {
            res.datas.push({
              name: `【${this.$t('biz_udp_noposition')}】`,
              id: '_NO_POSITIONS',
              type: 0
            });
          }
          let root = { name: this.$t('biz_udp_pos_system'), id: '', children: res.datas, type: 0, nocheck: true };
          this.originData = [root];
          if (this.originData) {
            this.data = this.originData;
            this.$refs.tree && this.$refs.tree.setHugeData(this.data);
            this.$nextTick(() => {
              this.expandTempShow = [...this.expandTemp];
              if (this.$refs.tree) {
                if (this.setRootDefault) {
                  this.$refs.tree.setCurrentKey('');
                  this.nodeClick(
                    this.originData[0],
                    this.$refs.tree.getNode(''),
                    this,
                    true
                  );
                }
                if (this.showCheckbox) {
                  this.setDisabledList();
                }
              }
              this.$emit('inited');
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    filterNode(value, data) {
      if (this.filterPositionOnly && !this.categoryOnly) {
        const isCategory = data.type === 0;
        if (!value) return true;
        return data.name.indexOf(value) !== -1 && !isCategory;
      } else {
        if (!value) return true;
        return data.name.indexOf(value) !== -1;
      }
    }
  },
  mounted() { }
};
</script>
