<template>
  <div class="yxtbiz-ai-robot__input-magic">
    <div class="yxtbiz-ai-robot__input-magic__wrap">
      <yxt-dropdown ref="dropRefs" class="yxtbiz-ai-robot__wrap--magic" :loading="loading"  placement="top-start" trigger="click" @visible-change="visibleChange" @command="item => $emit('change', item)">
        <div class="yxtbiz-ai-robot__input-magic--common hand">
          <yxt-svg
            :remote-url="svgRemoteUrl"
            :width="16"
            :height="16"
            icon-class="common-stick" />
          <span class="ml4">{{$t('pc_biz_lbl_common_stick').d('常用应用')}}</span>
        </div>
        <yxt-dropdown-menu slot="dropdown" class="yxtbiz-ai-robot__input-dropdown" :class="{'yxtbiz-ai-robot__input-dropdown--large': size === 'large'}">
          <yxt-empty v-if="!info.length">
            <div slot="image" class="yxtbiz-ai-robot__input-dropdown--empty"><img  :src="$staticBaseUrl + '/ufd/55a3e0/img/ai-empty-app.jpg'" /></div>
            <div slot="text" class="mt12">{{$t('pc_biz_msg_empty_common_app').d('暂无常用应用')}}<p>{{$t('pc_biz_msg_empty_common_app1').d('可前往百宝箱添加')}}</p></div>
          </yxt-empty>
          <yxt-dropdown-item v-else :command='item' v-for="item in info" :key="item.id">
            <yxt-image :src="item.icon || ($staticBaseUrl + 'ufd/55a3e0/ai-select.png')" class="yxtbiz-ai-robot__wrap--img"></yxt-image><span class="text-26 font-size-16 v-mid">
              {{ item.appName }}</span>
            </yxt-dropdown-item>
        </yxt-dropdown-menu>
      </yxt-dropdown>
      <div class="yxtbiz-ai-robot__input-magic--common ml12 hand" @click="handleOpenTreasure">
        <yxt-svg
          :remote-url="svgRemoteUrl"
          :width="16"
          :height="16"
          icon-class="chest-stick" />
        <span class="ml4">{{$t('pc_biz_lbl_treasure_chest').d('百宝箱')}}</span>
      </div>
    </div>
    <Treasure-dialog :visible.sync="visible" :size="size" @operation="handleOperation" />
  </div>
</template>

<script>
import { postTreasureList } from '../service';
import TreasureDialog from './treasureDialog.vue';

export default {
  name: 'InputMagic',
  components: {
    TreasureDialog
  },
  props: {
    size: {
      type: String,
      default: 'small',
      validator: val => ['small', 'large'].includes(val)
    }
  },
  data() {
    this.svgRemoteUrl = this.$staticBaseUrl + 'ufd/55a3e0';
    return {
      info: [],
      visible: false,
      loading: false
    };
  },
  methods: {
    show() {
      this.$refs.dropRefs.show();
    },
    handleOperation(item) {
      this.visible = false;
      this.$emit('change', {
        showType: 'treasure',
        ...item
      });
    },
    visibleChange(v) {
      if (v) {
        this.getShortcutInfo();
      }
    },
    getShortcutInfo() {
      this.loading = true;
      postTreasureList({type: 1}).then(res=>{
        this.info = [];
        if (res && res.length) {
          res.forEach(item=>{
            (item.appBeanList || []).filter(sItem=>{
              if (sItem.categoryId === '0' || sItem.permission) {
                this.info.push({
                  categoryCode: item.categoryCode,
                  ...sItem
                });
              }
            });
          });
        }
      }).finally(()=>{
        this.loading = false;
      });
    },
    handleOpenTreasure() {
      this.visible = true;
    }
  }
};
</script>
<style scoped>
.yxtbiz-ai-robot__wrap--img {
  width: 16px; 
  height: 16px;
  vertical-align: middle;
  margin-right: 8px;
  padding: 6px;
  background: #F4F5F6;
  border-radius: 4px;
}
</style>