import { globalApi, miscApi } from 'yxt-biz-pc/packages/api';

export const searchLangKey = (module, params) => { // 获取key
  return globalApi.post(`org/langkey/${module}/search`, params);
};

export const saveLangKey = (module, params) => { // 修改key
  return globalApi.post(`org/langkey/${module}/save`, params);
};

export const searchLangs = () => { // 查询语种列表
  return globalApi.get('langs/search');
};

export const serchOrgLangs = (orgId) => {
  return miscApi.get(`/org/orgname?orgId=${orgId}`);
};
