var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: [_vm.ns.b(), { small: _vm.isSmall }] },
    [
      _c("div", { class: [_vm.ns.e("msg"), "color-gray-10"] }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.empty
                ? _vm.$t("pc_biz_tcm_searchEmpty" /** 抱歉，未找到相关内容*/)
                : _vm.$t("pc_biz_tcm_searchResult" /** 为您找到以下微课：*/)
            ) +
            "\n  "
        ),
      ]),
      _vm._l(_vm.list, function (item) {
        return _c(
          "div",
          {
            key: item.id,
            class: _vm.ns.e("card"),
            on: {
              click: function ($event) {
                return _vm.toTcmDetail(item)
              },
            },
          },
          [
            _c(
              "div",
              { class: _vm.ns.e("cover") },
              [
                _c("yxt-image", {
                  attrs: {
                    src: item.courseCover || _vm.defaultCover,
                    fit: "cover",
                    lazy: "",
                  },
                }),
              ],
              1
            ),
            _c("div", { class: _vm.ns.e("detail") }, [
              _c("div", { class: _vm.ns.e("title") }, [
                _c("span", { class: _vm.ns.e("title-con") }, [
                  _vm._v(_vm._s(item.courseName)),
                ]),
              ]),
              _c("div", { class: _vm.ns.e("subtitle") }, [
                _c("div", { class: _vm.ns.e("creator") }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "pc_biz_tcm_creator",
                          [item.createUserName] /** 创建者：{0} */
                        )
                      ) +
                      "\n        "
                  ),
                ]),
                _c("div", { class: _vm.ns.e("line") }, [_vm._v("|")]),
                _c("div", { class: _vm.ns.e("time") }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "pc_biz_tcm_time",
                          [
                            _vm.getDateTimeFormatted({
                              startTime: item.createTime,
                            }),
                          ] /** 时间：{0} */
                        )
                      ) +
                      "\n        "
                  ),
                ]),
              ]),
            ]),
          ]
        )
      }),
      _vm.hasMore
        ? _c(
            "div",
            { class: _vm.ns.e("more"), on: { click: _vm.toMyTcm } },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("pc_biz_tcm_viewmore" /* 查看更多 */))),
              ]),
              _c("yxt-svg", {
                class: _vm.ns.e("more-icon"),
                attrs: {
                  width: "16px",
                  height: "16px",
                  "icon-class": "arrow_right",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }