<template>
  <div ref="container" class="yxtf-infinite-list-loading">
    <span class="yxt-infinite-loading-circle">
      <svg viewBox="0 0 16 16">
        <circle cx="8" cy="8" r="7" fill="none" class="path"></circle>
      </svg>
    </span>
  <span class="color-gray-7 font-size-14">{{ $t('pc_biz_ai_loadding').d('加载中') }}</span></div>
</template>

<script>
export default {
  name: 'InfiniteScroll',
  data() {
    return {
      observer: null
    };
  },
  mounted() {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // 目标元素进入视窗
          console.log('Element is visible');
          this.handlerScroll();
        } else {
          // 目标元素离开视窗
          console.log('Element is not visible');
        }
      });
    });

    observer.observe(this.$refs.container);
  },
  methods: {
    handlerScroll() {
      console.log('observe more');
      this.$emit('more');
    }
  },
  beforeDestroy() {
    this.observer && this.observer.disconnect();
  }
};
</script>
