<template>
  <div>
    <!-- 非isv -->
    <div v-if="!isISV">
      <!-- 当前用户勾选平台&&要素开通（只有一个平台||绚星学堂不展示） -->
      <div v-if="showChangeProduct && !isStu" class="nav-production-enter">
        <yxt-dropdown trigger="hover" @command="commandChange">
          <span
            class="color-gray-9"
            :class="[{ tiny: tiny, 'is-stu': isStu }]"
            >{{ $t(currentItem.langkey).d(currentItem.name) }}</span
          >
          <yxt-dropdown-menu slot="dropdown" max-height="300" max-width="300">
            <yxt-dropdown-item
              :class="[
                currentItem.code === item.code ? 'color-primary-6-i' : ''
              ]"
              :key="index"
              v-for="(item, index) in productionList"
              :command="item"
            >
              <span> {{ $t(item.langkey).d(item.name) }}</span>
            </yxt-dropdown-item>
          </yxt-dropdown-menu>
        </yxt-dropdown>
      </div>
    </div>
    <!-- isv -->
    <div
      v-if="(isISV && (xuanxingIsShow || isHA)) || onlyOneXuanxingUniversity"
      @click="openxxsch"
      class="yxtbiz-nav-top-stu__my-panel_schenter hand color-gray-9"
    >
      {{ $t('pc_biz_xxschool').d('绚星学堂') }}
    </div>
  </div>
</template>

<script>
import { productionUtil } from '../../../common-util/productionUtil';
import { checkTimeOutFnc } from '../../../common-util/getFactor';
import { enmuFunctions } from '../../../function-points';
import toThirdAuth from '../../../common-util/toThirdAuth';
import { platformDirect } from './common';
import { mapGetters } from 'vuex';
import { getOrgParams } from './service';
import { getOrgpParameter } from '../../../common-util/service';
const xuanxingUniversity = {
  name: '绚星学堂',
  code: 'xuanxing_university',
  langkey: 'pc_biz_xxschool'
};
export default {
  props: {
    isStu: {
      type: Boolean,
      default: false
    },
    tiny: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentItem: {},
      productionList: [],
      showHelpCenterInHa: false
    };
  },
  computed: {
    ...mapGetters('navManageStore', ['isISV']),
    isZh() {
      return (
        !window.localStorage.getItem('yxtLang') ||
        (window.localStorage.getItem('yxtLang') &&
          window.localStorage.getItem('yxtLang') === 'zh')
      );
    },
    isHA() {
      return (
        window.localStorage.getItem('yxtLang') === 'ha' &&
        this.showHelpCenterInHa &&
        checkTimeOutFnc(enmuFunctions.XUANXING_UNIVERSITY_SWITCH) === 2 &&
        !this.isStu
      );
    },
    // 是否显示绚星学堂，绚星学堂入口满足中文+购买+未过期+管理端就是显示，其余的就隐藏
    xuanxingIsShow() {
      return (
        this.isZh &&
        checkTimeOutFnc(enmuFunctions.XUANXING_UNIVERSITY_SWITCH) === 2 &&
        !this.isStu
      );
    },
    // 是否显示切换平台
    showChangeProduct() {
      return this.productionList.length > 1;
    },
    // 是否只有绚星
    onlyOneXuanxingUniversity() {
      return (
        this.productionList.length === 1 &&
        this.productionList[0].code === xuanxingUniversity.code
      );
    }
  },
  created() {
    this.getCurrentItem();
    this.getHelperCenterStatus();
  },
  methods: {
    getHelperCenterStatus() {
      getOrgParams('show_helper_center_status', localStorage.orgId)
        .then(res => {
          this.showHelpCenterInHa = res.value === '1';
          this.getProductionList();
        })
        .catch(() => {
          this.getProductionList();
        });
    },
    getCurrentItem() {
      const productionInfo = productionUtil.getProductionInfo();
      this.currentItem = productionInfo;
    },
    async getProductionList() {
      const list = productionUtil.getUserProductList();
      // 奇点的入口需要满足 增购&& 机构参：isOpenTalent===1
      let isOpenTalentRes;
      try {
        isOpenTalentRes = await getOrgpParameter(
          'isOpenTalent',
          localStorage.orgId
        );
      } catch (error) {
        isOpenTalentRes = {
          value: null
        };
      }
      if (isOpenTalentRes && Number(isOpenTalentRes.value) === 1) {
        for (let i = list.length - 1; i >= 0; i--) {
          if (list[i] && list[i].code === 'sp') {
            list.splice(i, 1);
          }
        }
      }
      this.productionList = list;
      const isXuanxing = productionUtil.isOneProduct('xxv2');
      // 绚星学堂只在绚星云学习展示
      (this.xuanxingIsShow || this.isHA) &&
        isXuanxing &&
        this.productionList.push(xuanxingUniversity);
    },
    commandChange(item) {
      if (item.code === this.currentItem.code) return;
      // 绚星学堂打开新页面,不修改当前code
      if (item.code === xuanxingUniversity.code) {
        this.openxxsch();
      } else {
        this.currentItem = item;
        // 产品线跳转逻辑是:学员端跳转学员端，管理端跳转管理端
        if (this.isStu) {
          platformDirect(2, '', item.allPath);
        } else {
          platformDirect(1, '', item.allPath);
        }
      }
    },
    async openxxsch() {
      try {
        const res = await toThirdAuth();
        window.open(res.url);
      } catch (e) {
        this.$message.error(e.message);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.nav-production-enter {
  .is-stu {
    font-size: 16px;
  }

  .tiny {
    font-size: 12px;
  }
}
</style>
