<template>
  <div>
    <yxt-row :gutter="40">
      <yxt-col :span="8" style="max-height:650px;overflow:auto">
        <pos-tree
          :functionCode='functionCode'
          :dataPermissionCode='dataPermissionCode'
          @nodeClick="selectCatalog" :filterable="true"></pos-tree>
      </yxt-col>
      <yxt-col :span="16">
        <yxt-input
          :placeholder="$t('biz_udp_enter_positionname')"
          v-model="searchValue"
          size="small"
          searchable
          @search="searchPostion"
        >
        </yxt-input>
        <yxt-table
          ref="posTable"
          @select-all="selectAllPosition"
          @select="selectPosition"
          v-loading="loading"
          class="mt20"
          :data="positions"
        >
          <yxt-table-column type="selection" width="40"></yxt-table-column>
          <yxt-table-column :label="$t('biz_udp_pos_name')" prop="name"></yxt-table-column>
          <yxt-table-column :label="$t('biz_udp_occupation_level')" prop="gradeList">
             <template slot-scope="scope">{{ (scope.row.gradeList || []).map(item => item.name).join() || '--' }}</template>
          </yxt-table-column>
          <yxt-table-column
            :label="$t('biz_udp_pos_category')"
            prop="catalogName"
          ></yxt-table-column>
        </yxt-table>
        <div class="mt10 clearfix">
          <yxt-pagination
            class="pull-right"
            background
            small
            :page-size="posData.limit"
            :page-sizes="[10, 15, 20]"
            :current-page="page"
            @size-change="sizeChange"
            @current-change="pageChange"
            layout="prev, pager, next,sizes,jumper"
            :page-count="totalPage"
          ></yxt-pagination>
        </div>
      </yxt-col>
    </yxt-row>
  </div>
</template>

<script>
import posTree from '../../../pos-tree';
import { getPositionList } from '../service';

export default {
  components: {
    posTree
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'positions'
    },
    functionCode: {
      type: String,
      default: ''
    },
    dataPermissionCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      searchValue: '',
      positions: [],
      selectedPostions: {},
      loading: true,
      totalPage: 0,
      page: 1,
      posData: {
        limit: 10,
        offset: 0,
        name: '',
        catalogId: ''
      }
    };
  },
  created() {
    // 选中数组转化为对象，方便查找
    this.formatPositions(this.value);
    this.getData();
  },
  methods: {
    formatPositions(positions) {
      this.selectedPostions = {};
      positions.forEach(position => {
        this.selectedPostions[position.id] = position;
      });
    },
    selectCatalog(item) {
      this.posData.catalogId = item.id;
      this.posData.offset = 0;
      this.page = 1;
      this.getData();
    },
    searchPostion() {
      this.posData.name = this.searchValue;
      this.getData();
    },
    getData() {
      this.loading = true;
      getPositionList(this.posData)
        .then(result => {
          this.positions = result.datas;
          this.positions.forEach(position => {
            position.nodeType = 'position';
          });
          this.totalPage = result.paging.pages;
          this.loading = false;
          this.$nextTick(() => {
            this.checkStatus();
          });
        })
        .catch(e => {
          console.log(e);
        });
    },
    sizeChange(size) {
      this.posData.limit = size;
      this.page = 1;
      this.posData.offset = 0;
      this.getData();
    },
    pageChange(page) {
      this.page = page;
      this.posData.offset = (page - 1) * this.posData.limit;
      this.getData();
    },
    selectAllPosition(items) {
      let isAdd = items.length !== 0;
      this.positions.forEach(position => {
        if (!isAdd) {
          delete this.selectedPostions[position.id];
        } else {
          this.selectedPostions[position.id] = position;
        }
      });

      this.updatePostion(this.positions, isAdd);
    },
    selectPosition(item, row) {
      let isAdd = item.some(v => {
        return v.id === row.id;
      });
      if (item.length === 0) {
        isAdd = false;
        delete this.selectedPostions[row.id];
      } else {
        this.selectedPostions[row.id] = row;
      }
      this.updatePostion(row, isAdd);
    },
    updatePostion(items, isAdd) {
      this.$emit('input', Object.values(this.selectedPostions));
      this.$emit('update', this.type, items, isAdd);
    },
    checkStatus() {
      this.positions.forEach(position => {
        if (this.selectedPostions.hasOwnProperty(position.id)) {
          this.$refs['posTable'].toggleRowSelection(position, true);
        } else {
          this.$refs['posTable'].toggleRowSelection(position, false);
        }
      });
    }
  },
  watch: {
    value(v) {
      this.formatPositions(v);
      this.checkStatus();
    }
  }
};
</script>

<style lang="scss" scoped></style>
