<template>
<div class="common-answer width-percent-100">
    <div class="is-content is-answer-content" :class="{'is-expanded': isExpanded }">
        <msgToolBtn :like="like" :msg="msg" v-show="!isSearch && !msg.isFunction" @toolClick="toolClick" :isanswer="true" />
        <div ref="content" class="answer-content origin-content" v-html="content"></div>
        <div v-show="needShowMoreCover && isSearch" class="is-more-cover">
            <div></div>
            <div class="text-center hand" @click="isExpanded = !isExpanded">
                <yxtf-link class="font-size-12" type="primary" :underline="false">{{ isExpanded ? $t('pc_biz_ai_retract').d('收起') : $t('pc_biz_ai_expand_all').d('展开全部') }}</yxtf-link>
                <i :class="isExpanded ? 'yxt-icon-arrow-up' : 'yxt-icon-arrow-down'"></i>
            </div>
        </div>
        <sourceContent v-if="msg.contentDetail && msg.contentDetail.linkList && msg.contentDetail.linkList.length" :msg="msg" :contentDetail="msg.contentDetail" :isSearch="isSearch" :fullscreen="fullscreen" />
    </div>
    <div :class="{'is-like': true, 'last-is-like': lastMsg }" v-if="like && !msg.isFunction">
        <yxt-svg v-if="like === 1" :remote-url='h5Svg' :width="20" :height="20" icon-class="icon-satisfied" />
        <yxt-svg v-if="like === 2" :remote-url='h5Svg' :width="20" :height="20" icon-class="icon-dissatisfied" />
    </div>
    <div class="answer-footer" v-if="msg.answerStatus === 1 && !msg.isFunction && !isWaitAnswer">
        <span>
            <span class="re-answer" v-if="lastMsg" @click="toolClick">
                <yxtf-svg class="icon-copy" width="20px" height="20px" icon-class="turn-right" />
                <span> {{ $t('pc_biz_ai_reanswer').d('重新回答') }}</span>
            </span>
        </span>
        <span v-if="!like" class="like-btn">
            <span @click="handleLike(1)" class="like-btn-item">
                <yxtf-svg :remote-url='svgUrl' width="20px" height="20px" icon-class="good" />
            </span>
            <span @click="handleLike(2)" class="like-btn-item">
                <yxtf-svg :remote-url='svgUrl' class="transformY-1" width="20px" height="20px" icon-class="not-good" />
            </span>
        </span>
    </div>
</div>
</template>

<script>
import {
  submitQuestionLike,
  getStaticBaseUrl
} from '../../service';
import sourceContent from '../sourceContent.vue';
import msgToolBtn from '../msgToolBtn.vue';
import { markdownParse } from '../../utils';
export default {
  name: 'commonAnswer',
  props: {
    isWaitAnswer: { // 是否回答等待中
      type: Boolean,
      default: false
    },
    fullscreen: { // 是否是大屏状态
      type: Boolean,
      default: false
    },
    msg: { // 回答的消息体
      type: Object,
      default: () => {}
    },
    isSearch: { // 是否是搜索引用
      type: Boolean,
      default: false
    },
    lastMsg: { // 当前是否是最后一条回答消息
      type: Boolean,
      default: false
    }
  },
  components: {
    sourceContent,
    msgToolBtn
  },
  data() {
    return {
      svgUrl: `${getStaticBaseUrl()}ufd/407a24/assistent/pc/svg/`,
      h5Svg: `${getStaticBaseUrl()}ufd/407a24/assistent/h5/svg/`,
      like: this.msg.chatLikeFlag, // 点赞状态 1-like  2-dislike
      needShowMoreCover: false, // 是否需要折叠消息体内容
      isExpanded: false // 是否展开
    };
  },
  computed: {
    content() {
      const content = this.msg.contentDetail ? this.msg.contentDetail.content : '';
      return markdownParse(content);
    },
    likeIconClass() {
      if (this.like === 0) return ['like', 'like'];
      return this.like === 1 ? ['like-hover', 'like-disable'] : ['like-disable', 'like-hover'];
    }
  },
  watch: {
    msg: {
      immediate: true,
      handler(v) {
        v.answerStatus && this.$nextTick(this.updateMoreCoverShow); // 更新消息体
      }
    },
    'msg.chatLikeFlag'(v) { // 更新点赞状态
      this.like = v;
    }
  },
  methods: {
    // 计算更新折叠
    updateMoreCoverShow() {
      const content = this.$refs.content;
      this.needShowMoreCover = content ? content.scrollHeight > 230 : false;
    },
    handleLike(like) {
      if (this.like) return;
      let likeAnswer = true;
      if (like === 2) {
        likeAnswer = false;
        this.$emit('feedback');
      }
      submitQuestionLike({
        chatContentId: this.msg.chatContentId || this.msg.chatRecordId,
        likeAnswer: likeAnswer
      }).then(() => {
        this.like = like;
      }).catch(err => {
        if (err && err.error && err.error.message) {
          this.$message.error(err.error.message);
        }
      });
    },
    // 工具按钮交互事件
    toolClick(type) {
      if (type === 'copy') {
        this.copyText(this.$refs.content);
      } else {
        this.$emit('resend', 'onceAgin');
      }
    },
    copyText(copyDom) {
      // 创建text area
      const textArea = document.createElement('textarea');
      textArea.value = copyDom.innerText;
      console.log('textArea.value', textArea.value);
      textArea.style.position = 'absolute';
      textArea.style.opacity = '0';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select(); // 选中文本
      document.execCommand('Copy'); // 执行浏览器复制命令
      this.$message.success(this.$t('pc_biz_copy_success').d('复制成功！'));
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
