import SupportSidebar from './src/main';

/* istanbul ignore next */
SupportSidebar.install = function(Vue) {
  Vue.component(SupportSidebar.name, SupportSidebar);
};

SupportSidebar.setConfig = (config) => {
  SupportSidebar.env = config.env || 'dev';
};

export default SupportSidebar;
