var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { "ai-share-cotent": true, "ai-fullshare": _vm.bFullscreen } },
    [
      _c(
        "div",
        { staticClass: "ai-share", attrs: { id: "aishare" } },
        [
          _c(
            "div",
            { staticClass: "ai-share-portrait" },
            [
              _vm.settings.assistHeadImg
                ? _c("yxtf-portrait", {
                    staticClass: "mr12",
                    attrs: {
                      size: "44px",
                      username: "AI",
                      imgUrl: _vm.settings.assistHeadImg,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("div", { staticClass: "ai-title mt12" }, [
            _vm._v(
              _vm._s(
                _vm.$t("pc_biz_ai_share_title").d("企业员工的专属助理备份")
              )
            ),
          ]),
          _c("div", { staticClass: "ai-share-tips" }, [
            _vm._v(
              _vm._s(
                _vm.$t("pc_biz_ai_share_tips").d("答疑解惑，让知识无限延伸备份")
              )
            ),
          ]),
          _vm.url
            ? _c("yxtbiz-qrcode", {
                ref: "qrcode",
                staticClass: "ai-qrcode",
                attrs: {
                  size: 148,
                  url: _vm.url,
                  isProject: "",
                  logo: "https://stc.yxt.com/ufd/407a24/assistent/pc/img/avatar.jpg",
                  "hide-link": "",
                  "hide-download": "",
                  "use-svg": "",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "share-footer" },
        [
          _c(
            "yxt-button",
            {
              staticClass: "share-btn",
              attrs: { round: "" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.copyLink.apply(null, arguments)
                },
              },
            },
            [_vm._v(_vm._s("复制链接"))]
          ),
          _c(
            "yxt-button",
            {
              staticClass: "share-btn",
              attrs: { type: "primary", round: "" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.downloadCode.apply(null, arguments)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("pc_biz_ai_save_img").d("保存图片")))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }