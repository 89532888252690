
import { getAllNavTitle } from './src/services';
import utils from 'yxt-biz-pc/packages/common-util/utils';

export default {
  /**
   *
   * @param {*} title
   * @returns
   */
  setTitle(title) {
    console.warn('setTitle', title);
    let siteName = localStorage.getItem('siteName');
    if (siteName === 'null') siteName = '';
    if (siteName && title) title += ` - ${siteName}`;
    document.title = title;
  },
  setTitleByPageCodeReject: null,
  /**
   *
   * @param {*} code
   * @param {*} defaultTitle
   * @param {*} noneOfParamNoChange
   * @returns
   */
  setTitleByPageCode(code, defaultTitle = '', noneOfParamNoChange = false) {
    console.warn('setTitleByPageCode', code, defaultTitle, noneOfParamNoChange);
    if (noneOfParamNoChange === true && !code && !defaultTitle) return;

    if (this.setTitleByPageCodeReject) {
      // 取消上一次写入title
      this.setTitleByPageCodeReject('cancel');
    }

    const self = this;

    return new Promise((resolve, reject) => {
      self.setTitleByPageCodeReject = reject;
      if (code) {
        self.getTitleByCode(code).then(resolve);
      } else {
        resolve();
      }
    })
      .then(title => self.setTitle(title || defaultTitle))
      .catch((e) => {
        if (e !== 'cancel') {
          console.error(e);
          return;
        }
        console.warn('setTitleByPageCode', e.message);
      }).finally(() => {
        self.setTitleByPageCodeReject = null;
      });
  },
  navCodeNameMap: null,
  navCodeNamePadding: null,
  async getTitleByCode(code) {
    if (!utils.getUserToken()) {
      console.error('getTitleByCode error. token is undefined');
      return '';
    }

    if (this.navCodeNamePadding) await this.navCodeNamePadding;

    if (this.navCodeNameMap === null) {
      this.navCodeNamePadding = getAllNavTitle();
      try {
        this.navCodeNameMap = await this.navCodeNamePadding || {};
        this.navCodeNamePadding = null;
      } catch (e) {
        console.error('获取导航title错误', e);
        this.navCodeNamePadding = null;
      }
    }

    /**
     * pc端 强制先加载导航，computePagecodes 所依赖的 导航 state 已经写入数据
     */
    const name = this.navCodeNameMap && this.navCodeNameMap[code] || '';
    if (!name) {
      console.error('没有从导航匹配到title');
    }

    return name;
  }
};
