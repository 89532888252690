import NavMain from './src/v2/nav-main';
import StopNotice from './src/v2/stop-notice.vue';
import AuditCenterEnter from './src/v2/audit-center-enter.vue';
import NavOrgListStu from './src/v2/nav-org-list-stu.vue';
import WorkBench from './src/v2/work-bench.vue';
import * as navCommon from './src/v2/common';
import * as navService from './src/v2/service';

/* istanbul ignore next */
NavMain.install = function(Vue) {
  Vue.component(NavMain.name, NavMain);
};

NavMain._shareToDevelopAgain = {
  components: {
    StopNotice,
    AuditCenterEnter,
    NavOrgListStu,
    WorkBench
  },
  navCommon,
  navService
};

export {
  NavMain
};

export default NavMain;
