var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "check_more_department" },
    [
      _c("yxtbiz-dept-tree", {
        ref: "deptTree",
        staticClass: "department_tree",
        attrs: {
          targetOrgId: _vm.targetOrgId,
          disabled: _vm.disabled,
          visibleOrgSelector: _vm.visibleOrgSelector,
          visibleOrgIds: _vm.visibleOrgIds,
          enableGroupCorp: _vm.enableGroupCorp,
          filterable: "",
          size: "",
          placeholder: _vm.$t("biz_udp_enter_deptmentname"),
          "check-strictly": !!_vm.optionsComputed.checkStrictly,
          "show-checkbox": "",
          "children-included": _vm.optionsComputed.childrenIncluded,
          functions: !!_vm.optionsComputed.childrenIncluded,
          functionCode: _vm.functionCode,
          dataPermissionCode: _vm.dataPermissionCode,
          count: _vm.showDeptCount,
          checkedLinkage: "1",
          list: _vm.list,
        },
        on: {
          "org-change": _vm.handleOrgChange,
          close: _vm.closeTag,
          check: _vm.handleCheck,
          clearNode: _vm.handleCheck,
          inited: _vm.init,
          includeChanged: _vm.handleTypeChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }