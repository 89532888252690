<template>
<div :class="{'ai-share-content': true,'ai-fullshare': bFullscreen}" class="">
    <div class="ai-share" id="aishare">
        <div class="ai-share-portrait">
          <yxtf-portrait v-if="settings.assistHeadImg" size="44px" class="mr12"  username="AI" :imgUrl="settings.assistHeadImg" />
        </div>
        <div class="ai-title mt12">{{ $t('pc_biz_ai_share_title').d('企业员工的专属助理备份') }}</div>
        <div class="ai-share-tips">{{ $t('pc_biz_ai_share_tips').d('答疑解惑，让知识无限延伸备份') }}</div>
        <yxtbiz-qrcode v-if="url" :size="148" :url="url" ref="qrcode" isProject class="ai-qrcode" :logo="avatarUrl"  hide-link hide-download use-svg></yxtbiz-qrcode>
    </div>
    <div class="share-footer">
        <yxt-button round class="share-btn" @click.stop="copyLink">{{ $t('pc_biz_qrcode_lbl_copy').d('复制链接') }}</yxt-button>
        <yxt-button type="primary" round class="share-btn" @click.stop="downloadCode">{{ $t('pc_biz_ai_save_img').d('保存图片') }}</yxt-button>
    </div>
</div>
</template>

<script>
import domtoimage from '../ai-tools/dom-image.js';
import {
  getShareUrl,
  getStaticBaseUrl
} from '../service';
export default {
  props: {
    bFullscreen: {
      type: Boolean,
      default: false
    }
  },
  inject: ['settings'],
  data() {
    return {
      url: '',
      avatarUrl: `${getStaticBaseUrl()}ufd/407a24/assistent/pc/img/avatar.jpg`
    };
  },
  mounted() {
    this.getShareUrl();
    console.log('domtoimage', domtoimage);
  },
  methods: {
    getShareUrl() {
      getShareUrl().then(res => {
        if (res) {
          this.url = res;
        }
        console.log('getShareUrl', res);
      });
    },
    downloadCode() {
      domtoimage.toPng(document.getElementById('aishare'), {
        quality: 0.92
      })
        .then((dataUrl) => {
          // 保存图片
          let saveFile = (data) => {
            let img = new Image();
            img.src = data;
            img.onload = () => {
              // 缩放图片
              const dataUrl = this.scalcImage(img);
              this.download(dataUrl);
            };
          };
          saveFile(dataUrl);
        });
    },
    // 下载图片
    async download(dataUrl) {
      console.log('dataUrl', dataUrl);
      let blob_ = await this.base64toBlob(dataUrl); // 转换为 blob
      let link = document.createElement('a');
      link.setAttribute('href', window.URL.createObjectURL(blob_));
      link.setAttribute('download', this.getFilename());
      link.click();
    },
    // base64转blob
    base64toBlob(dataurl) {
      return new Promise((resolve, reject) => {
        fetch(dataurl).then(res => {
          resolve(res.blob());
        }).catch(() => {
          reject(new Error('转换Blob失败'));
        });
      });
    },
    // 缩放图片到0.8
    scalcImage(img) {
      let canvas = document.createElement('canvas'); // 创建canvas元素
      let scale = 1.2;
      let width = img.width * scale; // 确保canvas的尺寸和图片一样
      let height = img.height * scale;
      canvas.width = width;
      canvas.height = height;
      canvas.getContext('2d').drawImage(img, 0, 0, width, height); // 将图片绘制到canvas中
      const dataUrl = canvas.toDataURL('image/png'); // 转换图片为dataURL
      return dataUrl;
    },
    getFilename() {
      // 获取年月日作为文件名
      const timers = new Date();
      const fullYear = timers.getFullYear();
      const month = timers.getMonth() + 1;
      const date = timers.getDate();
      const randoms = Math.random() + '';
      // 年月日加上随机数
      let numberFileName =
                fullYear + '' + month + date + randoms.slice(3, 10);
      return numberFileName + '.png';
    },
    copyLink() {
      this.copyText();
    },
    copyText() {
      // 创建text area
      const textArea = document.createElement('textarea');
      textArea.value = this.url;
      console.log('textArea.value', textArea.value);
      textArea.style.position = 'absolute';
      textArea.style.opacity = '0';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select(); // 选中文本
      document.execCommand('Copy'); // 执行浏览器复制命令
      this.$message.success(this.$t('pc_biz_copy_success').d('复制成功！'));
    }
  }
};
</script>

<style lang="scss" scoped>
.ai-share-content {
    position: absolute;
    z-index: 999;
    right: 0px;
    top: 63px;
    border-radius: 12px;
    &.ai-fullshare {
      position: absolute;
      border-radius: 12px;
      z-index: 999 ;
      right: -149px ;
      background: #fff;
      top: -415px ;
    }
    .ai-share {
        width: 300px;
        min-height: 398px;
        padding-bottom: 78px;
        box-sizing: border-box;
        padding-top: 15px;
        background: linear-gradient(135deg, #efe9ff 0%, #f1f4fd 50%, #d4e8ff 100%);
        border-radius: 12px;
        z-index: 999;
        box-shadow: 0px 4px 24px 0px rgba(41,49,54,0.12);
        .ai-share-portrait {
          margin-top: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .ai-title {
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            max-width: 300px;
            white-space: break-spaces;
            color: #262626;
            line-height: 24px;
        }

        .ai-share-tips {
            font-size: 14px;
            font-weight: 400;
            margin-top: 4px;
            text-align: center;
            max-width: 300px;
            white-space: break-spaces;
            color: #757575;
            line-height: 22px;
            margin-bottom: 2px;
        }
        .ai-qrcode {
            .yxtbiz-qrcode__img {
                svg {
                    border: 8px solid #fff !important;
                    border-radius: 4px !important;
                }
            }
        }

    }
    .share-footer {
        position: absolute;
        display: flex;
        bottom: 20px;
        width: 100%;
        justify-content: center;
        text-align: center;
    }
}

</style>
