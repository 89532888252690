var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "yxtbiz-ai-robot__dialog",
      class: { "fullscreen-dialog": _vm.bFullscreen },
    },
    [
      _c("div", { staticClass: "yxtbiz-ai-robot__dialog--header" }, [
        _c(
          "div",
          { staticClass: "yxtbiz-ai-robot__dialog--header-left" },
          [
            !_vm.bFullscreen && _vm.settings.assistHeadImg
              ? _c("yxtf-portrait", {
                  staticClass: "mr12 hand",
                  attrs: {
                    size: "small",
                    username: "AI",
                    imgUrl: _vm.settings.assistHeadImg,
                  },
                  on: { click: _vm.showChatList },
                })
              : _vm._e(),
            !_vm.bFullscreen
              ? _c("span", [_vm._v(_vm._s(_vm.title))])
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "yxtbiz-ai-robot__dialog--header-right" },
          [
            _c("header-icon", {
              staticClass: "mr16",
              attrs: {
                size: _vm.iconSize,
                "icon-name": "share",
                "pop-text": _vm.$t("pc_biz_ai_share").d("分享"),
                remote: "",
              },
              on: { click: _vm.share },
            }),
            _vm.showShare ? _c("Share") : _vm._e(),
            !_vm.max
              ? [
                  _c("header-icon", {
                    staticClass: "mr16",
                    attrs: {
                      remote: "",
                      size: _vm.iconSize,
                      "icon-name": _vm.bFullscreen ? "ai-window" : "ai-screen",
                      "pop-text": _vm.bFullscreen
                        ? _vm.$t("pc_biz_ai_small_window").d("小窗口")
                        : _vm.$t("pc_biz_ai_full_screen").d("全屏"),
                    },
                    on: { click: _vm.changeFullSize },
                  }),
                  _c("header-icon", {
                    staticClass: "mr16",
                    attrs: {
                      remote: "",
                      size: _vm.iconSize,
                      "icon-name": "ai-closed",
                      "pop-text": _vm.$t("pc_biz_ai_close").d("关闭"),
                    },
                    on: { click: _vm.handleClose },
                  }),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "yxtbiz-ai-robot__dialog--body",
          style: _vm.aiBodyStyle,
        },
        [
          _c(
            "yxtf-scrollbar",
            {
              ref: "scrollbar",
              staticClass: "yxtbiz-ai-robot-scrollbar",
              attrs: { "fit-height": true },
            },
            [
              !_vm.noMoreHistory
                ? _c("infinite-scroll", { on: { more: _vm.getHistory } })
                : _vm._e(),
              _c("div", { class: { "ml24 mr24": _vm.bFullscreen } }, [
                _c(
                  "ul",
                  { ref: "dialogBody", staticClass: "is-msg-list" },
                  _vm._l(_vm.msgList, function (msg, index) {
                    return _c(
                      "li",
                      {
                        key: index,
                        staticClass: "width-percent-100",
                        class: {
                          "yxtbiz-ai-robot__dialog--msg-question":
                            msg.chatType === 0,
                        },
                      },
                      [
                        _c("Message", {
                          attrs: {
                            msg: msg,
                            lastMsg: index === _vm.msgList.length - 1,
                            isWaitAnswer: _vm.isWaitAnswer,
                            fullscreen: _vm.bFullscreen,
                          },
                          on: {
                            feedback: function ($event) {
                              return _vm.showFeedbackDialog(index)
                            },
                            promot: _vm.onPromot,
                            resend: (val) => _vm.resend(msg, val, index),
                            scrollToEnd: _vm.scrollToEnd,
                            submit: _vm.handleSubmitMessage,
                            promotEmpty: _vm.onPromotEmpty,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { ref: "aiFooter", staticClass: "yxtbiz-ai-robot__dialog--footer" },
        [
          _vm.settings.hasShortcutInstruction === 1
            ? _c("Input-magic", {
                ref: "inputMagicRef",
                attrs: { size: _vm.bFullscreen ? "large" : "small" },
                on: { change: _vm.handleChangeMagic },
              })
            : _vm._e(),
          _c(
            "div",
            {
              class: {
                "textarea-content": true,
                "textarea-focus": _vm.focus,
                "more-lines":
                  _vm.inputHeight > 52 ||
                  (_vm.magicTag && _vm.magicTag.appName),
              },
            },
            [
              _vm.isWaitAnswer
                ? _c(
                    "span",
                    {
                      staticClass: "stop-answer",
                      class: {
                        "stop-instruction-answer":
                          _vm.settings.hasShortcutInstruction === 1,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.stopAIAnswer()
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "block" }),
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("pc_biz_lbl_stop_reply").d("停止回答")
                            )
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "yxtbiz-ai-robot__wrap" },
                [
                  _vm.magicTag
                    ? _c(
                        "div",
                        { staticClass: "yxtbiz-ai-robot__wrap--tag" },
                        [
                          _c(
                            "yxt-tag",
                            {
                              attrs: { closable: "" },
                              on: {
                                close: function ($event) {
                                  _vm.magicTag = null
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.magicTag.appName || ""))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.question,
                        expression: "question",
                      },
                    ],
                    ref: "textarea",
                    staticClass: "no-shortcut-textarea",
                    class: {
                      "no-question": !_vm.isSendShow,
                      "font-size-14": !_vm.bFullscreen,
                    },
                    attrs: {
                      maxlength: "2000",
                      placeholder: _vm.getInputHolder,
                    },
                    domProps: { value: _vm.question },
                    on: {
                      keydown: _vm.keydown,
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.question = $event.target.value
                        },
                        _vm.handleInputQuestion,
                      ],
                      focus: function ($event) {
                        _vm.focus = true
                      },
                      blur: function ($event) {
                        _vm.focus = false
                      },
                    },
                  }),
                  _vm.isSendShow
                    ? _c(
                        "yxt-tooltip",
                        {
                          attrs: {
                            content: _vm
                              .$t("pc_biz_ai_send_wait")
                              .d("暂时不能发送，请稍后再试！"),
                            placement: "top",
                            disabled: !_vm.isWaitAnswer,
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              class: {
                                "ai-robot-btn-send": true,
                                "disbale-send": _vm.isWaitAnswer,
                              },
                              on: {
                                click: function ($event) {
                                  _vm.isWaitAnswer ? null : _vm.sendMsg()
                                },
                              },
                            },
                            [_c("img", { attrs: { src: _vm.sendSvg } })]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }