var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "yxt-select",
        {
          attrs: {
            "value-key": "id",
            "prop-label": "value",
            multiple: "",
            size: "small",
          },
          on: { "remove-tag": _vm.removeTag },
          model: {
            value: _vm.dateString,
            callback: function ($$v) {
              _vm.dateString = $$v
            },
            expression: "dateString",
          },
        },
        [
          _c(
            "yxt-option",
            {
              staticStyle: { height: "auto", margin: "0 -20px" },
              attrs: { value: _vm.dateString, label: "value", disabled: "" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "bg-white",
                  staticStyle: { padding: "10px 15px" },
                },
                [
                  _c("yxt-date-picker", {
                    attrs: {
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      type: "daterange",
                      "range-separator": "-",
                      "start-placeholder": _vm.$t("pc_biz_udp_tip_startPicker"),
                      "end-placeholder": _vm.$t("pc_biz_udp_tip_endPicker"),
                    },
                    on: { change: _vm.dateChange },
                    model: {
                      value: _vm.date,
                      callback: function ($$v) {
                        _vm.date = $$v
                      },
                      expression: "date",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }