<template>
  <div class="checkedlist">
    <div class="header">
      <span v-if="tabs.length > 1">
        <span>{{ $t('pc_biz_signup_lbl_checked').d('已选') }}</span>
        <span v-if="limit > 0" style="color: #999">
          <span style="color: #262626">：{{checkedDataCount}}</span>
           /{{limit}}</span>
      </span>
      <span v-else>
        <span>{{$t('pc_biz_signup_lbl_checked').d('已选')}}{{checkedData[tab0Type].length ? `：${checkedData[tab0Type].length}`:'：0'}}</span>
        <span v-if="limit > 0" style="color: #999"> /{{limit}}</span>
      </span>
      <yxt-button type="text" @click="clearAll" v-if="showClearAllBtn">{{$t('pc_biz_labelling_clearall').d('清空')}}</yxt-button>
    </div>
    <main class="checkedlist-main">
      <div class="checkedlist-main-placeholder" v-if="!showClearAllBtn">{{$t('biz_udp_please_select').d('请选择')}}...</div>
      <div class="group-main" v-for="(group, index) in checkedList" :key="index">
        <div class="group-main-title" v-if="tabs.length > 1">
          <yxt-svg class="mr5 v-mid" width="14px" height="14px" :icon-class="group.iconClass" />
          <span>{{group.name}}：</span>
          <span>{{group.count}}</span>
        </div>
        <div class="group-main-content">
          <yxt-tag
          v-for="(item, key) in group.data.slice(0, group.showCount)"
          :key="key"
          class="mr5"
          size="small"
          type="info"
          :disableTransitions="true"
          style="margin-bottom: 8px;max-width: 100%;"
          closable
          @close="deleteItem(group.type, item)"
          >
            <span class="checkedlist-tag">
              <span v-if="group.type === 'department'">
                <yxt-tooltip  class="item" effect="dark" placement="top">
                  <template slot="content">
                    <span>
                      {{splicingOrgName(item.orgName)}}
                      <yxtbiz-dept-name :name="item.selectName" />
                    </span>
                  </template>
                  <span>
                    {{splicingOrgName(item.orgName)}}
                    <yxtbiz-dept-name :name="item.selectName" />
                  </span>
                </yxt-tooltip>
              </span>
              <span v-else-if="group.type === 'persons'">
                <yxt-tooltip  class="item" effect="dark" placement="top">
                  <template slot="content">
                    <span>
                      {{splicingOrgName(item.orgName)}}
                      <yxtbiz-user-name :name="item.selectName" />
                    </span>
                  </template>
                  <span>
                    {{splicingOrgName(item.orgName)}}
                    <yxtbiz-user-name :name="item.selectName" />
                  </span>
                </yxt-tooltip>
              </span>
              <span v-else>
                <yxt-tooltip  class="item" effect="dark" placement="top">
                  <template slot="content">
                    {{splicingOrgName(item.orgName)}}
                    <span>{{item.selectName}}</span>
                  </template>
                  <span>{{splicingOrgName(item.orgName)}}{{item.selectName}}</span>
                </yxt-tooltip>
              </span>
            </span>
            <span
                v-if="item.includeAll === 1"
                class="ml10"
                style="color: #BFBFBF;"
            >{{item.includeAll === 1 ? $t('biz_udp_subsequent_all') /*后续全部*/: ''}}</span>
          </yxt-tag>
          <div v-if="group.showCount < group.count">
            <yxt-tag
              class="mr5"
              size="small"
              type="info"
              :disableTransitions="true"
              style="margin-bottom: 8px;cursor: pointer;"
              @click="() => { group.showCount += 50;showCountObj[group.type] += 50; }"
              >
                <span class="checkedlist-tag">{{$t('biz_pc_expand_more').d('展开更多')}}</span>
                <i class="yxt-icon-arrow-down" style="margin-left: 6px;"></i>
            </yxt-tag>
          </div>
          <div v-if="group.count > 6 && group.showCount >= group.count">
            <yxt-tag
              class="mr5"
              size="small"
              type="info"
              :disableTransitions="true"
              style="margin-bottom: 8px;cursor: pointer;"
              @click="() => { group.showCount = 6;showCountObj[group.type] = 6; }"
              >
                <span class="checkedlist-tag">{{$t('app_common_yxt_btn_close').d('收起')}}</span>
                <i class="yxt-icon-arrow-up" style="margin-left: 6px;"></i>
            </yxt-tag>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import openDataMixin from '../../mixins/openData';
import SelectorPropsMixin from '../../mixins/selector-props';
import { i18n } from 'yxt-biz-pc/packages/common-util/changeLangs';
import { TYPEVALUE } from '../selector-main';
// 这里通过函数去生成国际化名称，防止直接使用时国际化未加载完成，把执行时机放在调用的时刻
const TYPE_NAME = {
  persons: () => i18n.t('biz_udp_person').d('人员'),
  userGroup: () => i18n.t('biz_udp_usergroup').d('用户组'),
  relationDynamicUserGroup: () => i18n.t('pc_udp_common_tit_dynamic_group').d('动态用户组'),
  department: () => i18n.t('biz_udp_deptment').d('部门'),
  position: () => i18n.t('biz_udp_position').d('岗位'),
  role: () => i18n.t('biz_udp_role').d('角色'),
  level: () => i18n.t('biz_udp_occupation_level').d('职级'),
  groupPerson: () => i18n.t('pc_biz_udp_lbl_groupPerson').d('群成员'),
  customTab: () => i18n.t('biz_udp_person').d('人员')
};

const DATA_NAME = {
  // 选中数据各tab页对应的名称字段
  persons: 'fullname',
  userGroup: 'name',
  relationDynamicUserGroup: 'name',
  department: 'name',
  position: 'name',
  role: 'name',
  level: 'name',
  groupPerson: 'fullname',
  customTab: 'fullname'
};

const ICON_CLASS = {
  persons: 'person-center',
  userGroup: 'usergroup',
  relationDynamicUserGroup: 'usergroup',
  department: 'dept',
  position: 'position',
  role: 'role',
  level: 'pos-grade',
  groupPerson: 'person-center',
  customTab: 'person-center'
};

export default {
  mixins: [openDataMixin, SelectorPropsMixin],
  props: {
    checkedData: {
      type: Object,
      default: () => {}
    },
    tabs: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    getCustomTab() {
      // 获取自定义tab数据
      return this.tabs.find(item => item.type === 'customTab');
    },
    showClearAllBtn() {
      for (const type in this.checkedData) {
        if (this.checkedData[type].length) return true;
      }
      return false;
    },
    checkedDataCount() {
      let count = 0;
      // 已选数量
      for (const type in this.checkedData) {
        if (this.checkedData[type].length) {
          count += this.checkedData[type].length;
        };
      }
      return count;
    },
    tab0Type() {
      if (typeof this.tabs[0] === 'string') {
        return TYPEVALUE[this.tabs[0]];
      } else {
        if (this.tabs[0].type === 'userGroup' && this.tabs[0].isPerson) {
          return TYPEVALUE['userGroup.person'];
        }
        return TYPEVALUE[this.tabs[0].type];
      }
    }
  },
  data() {
    return {
      checkedList: [],
      showCountObj: {},
      checkedStack: [] // 加入顺序
    };
  },
  created() {
    console.log(this.limit, 'this.limit', TYPE_NAME, 'TYPE_NAME');
    this.checkedList = this.transformData(this.checkedData);
  },
  watch: {
    checkedData: {
      deep: true,
      handler(result) {
        this.checkedList = this.transformData(result);
        console.log(this.checkedList, 'this.checkedList');
      }
    }
  },
  methods: {
    transformData(dataObj) {
      console.log(dataObj, 'dataObj');
      const checkedMap = new Map();
      for (const key in dataObj) {
        if (!this.showCountObj[key]) this.showCountObj[key] = 6;
        if (dataObj[key] && dataObj[key].length) {
          if (this.checkedStack.indexOf(key) === -1) this.checkedStack.push(key);
          const data = dataObj[key].map(item => ({
            ...item,
            selectName: item[key === 'customTab' && this.getCustomTab.dataName ? this.getCustomTab.dataName : DATA_NAME[key]] || item.name
          }));
          console.log(TYPE_NAME[key], 'TYPE_NAME[key]');
          checkedMap.set(key, {
            type: key,
            name: key === 'customTab' && this.getCustomTab.typeName ? this.getCustomTab.typeName : TYPE_NAME[key](),
            iconClass: ICON_CLASS[key],
            count: dataObj[key].length,
            showCount: this.showCountObj[key],
            get data() {
              return data;
            }
          });
        } else {
          // 表示这个分组没数据了，从顺序栈中移除
          if (this.checkedStack.indexOf(key) > -1) {
            this.checkedStack.splice(this.checkedStack.indexOf(key), 1);
          }
        }
      }
      const checkedList = this.checkedStack.map(item => checkedMap.get(item));
      return checkedList;
    },
    splicingOrgName(orgName) {
      if (orgName && this.enableGroupCorp) {
        return `${orgName}-`;
      }
      return '';
    },
    deleteItem(type, item) {
      const data = this.checkedData[type];
      const newData = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].id !== item.id) {
          newData.push(data[i]);
        }
      }
      this.checkedData[type] = newData;
      this.$emit('change', { type, deleteItem: item });
    },
    clearAll() {
      // 清除所有已选项
      for (const key in this.checkedData) {
        this.checkedData[key] = [];
      }
      this.$emit('change', { type: 'all' });
    }
  }
};
</script>
<style lang="scss" scoped>
  .checkedlist {
    padding: 24px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .header {
      display: flex;
      padding-bottom: 16px;
      justify-content: space-between;
      > span {
        font-weight: 500;
        color: #262626;
        line-height: 22px;
      }
    }
    .checkedlist-main {
      flex: 1;
      height: 100%;
      padding-right: 24px;
      margin-right: -24px;
      overflow: auto;
      .checkedlist-main-placeholder {
        color: #bfbfbf;
        font-size: 14px;
      }
    }
    .group-main {
      margin-bottom: 16px;
      &-title {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #595959;
        line-height: 22px;
        margin-bottom: 8px;
      }
      &—content {
        display: flex;
        justify-content: space-around;
      }
    }
    .checkedlist-tag{
      text-overflow: ellipsis;
      flex: 1 1 auto;
      overflow: hidden;
    }
  }
</style>