<!-- 工作台入口 -->
<template>
<span class="yxtbiz-nav-work-bench" v-show="workList.length" :class="[{'is-stu':isStu,'tiny':tiny}]">
  <yxt-divider direction="vertical"></yxt-divider>
  <!-- 新手引导 -->
  <!-- 学员端 -->
  <yxtf-dropdown v-if="isStu" trigger="hover" custom-elm placement="bottom" class="pd10" :class="{'yxtbiz-nav-work-bench__guard-svg':workBenchGuardVisible}">
    <span class="hover-primary-6-i color-gray-9">
      <yxtf-badge  :is-dot="!!toDoSum && !workBenchGuardVisible" class="audit-center-enter" :class="[{'is-stu':isStu,'tiny':tiny}]">
        <yxtf-svg
          :width="size"
          :height="size"
          :remote-url="workbenchIconUrl"
          class="color-primary-6"
          icon-class="nav-workbench--new-10-11-4"
        />
      </yxtf-badge>
    <span class="ml8 lbl hand" :style="`font-size:${fontSizeLbl};`">{{$t('biz_workbench_nav_title').d('工作台')}}</span>
    </span>
    <yxtf-dropdown-menu slot="dropdown"  class="nav-workbench-dropdown" :class="[{'is-stu':isStu,'tiny':tiny}]">
      <yxtf-dropdown-item :style="padding" v-for="item in workList" :key="item.code">
      <div  @click="openPage(item)">
        <a-link :page-node="transformPageNode(item)" :url="item.pageUrl" class="nav-workbench-dropdown_name" :style="`font-size:${fontSize};`">{{item.name}}</a-link>
        <yxt-badge 
        class="ml10" 
        v-if="isAuditCenter(item) && toDoSum" 
        :max="99"
        :value="toDoSum" 
        :hidden="!toDoSum"  
        size="mini"/></div> 
      </yxtf-dropdown-item>
    </yxtf-dropdown-menu>
    </yxtf-dropdown>
    <!-- 管理端 -->
    <yxt-dropdown v-else trigger="hover" custom-elm placement="bottom" class="pd10" :class="{'yxtbiz-nav-work-bench__guard-svg':workBenchGuardVisible}">
    <span class="hover-primary-6-i color-gray-9">
      <yxt-badge  :is-dot="!!toDoSum && !workBenchGuardVisible" class="audit-center-enter" :class="[{'is-stu':isStu,'tiny':tiny}]">
        <yxt-svg
          :width="size"
          :height="size"
          :remote-url="workbenchIconUrl"
          class="color-primary-6"
          icon-class="nav-workbench--new-10-11-4"
        />
      </yxt-badge>
    <span class="ml8 lbl hand" :style="`font-size:${fontSizeLbl};`">{{$t('biz_workbench_nav_title').d('工作台')}}</span>
    </span>
    <yxt-dropdown-menu slot="dropdown"  class="nav-workbench-dropdown" :class="[{'is-stu':isStu,'tiny':tiny}]">
      <yxt-dropdown-item style="padding: 0" v-for="item in workList" :key="item.code">
      <div  @click="openPage(item)" :style="padding">
        <a-link :page-node="transformPageNode(item)" :url="item.pageUrl" class="nav-workbench-dropdown_name" :style="`font-size:${fontSize};`">{{item.name}}</a-link>
        <yxt-badge 
        class="ml10" 
        v-if="isAuditCenter(item) && toDoSum" 
        :max="99"
        :value="toDoSum" 
        :hidden="!toDoSum"  
        size="mini"/></div> 
      </yxt-dropdown-item>
    </yxt-dropdown-menu>
    </yxt-dropdown>
</span>
</template>

<script>
import { getAuditCount } from './service';
import { CoreSvgPath } from './const';
import { goSubNavPage } from './common';
import { defaultPath, staticBaseUrlPath } from './const';
import ALink from './alink.vue';
export default {
  components: {
    ALink
  },
  data() {
    return {
      toDoSum: 0,
      mediaPath: CoreSvgPath,
      navList: [],
      workBenchGuardVisible: false,
      orgId: window.localStorage.getItem('orgId'),
      workList: [],
      workbenchIconUrl: `${staticBaseUrlPath}assets/cc14e4ef/d6fc89f0/`
    };
  },
  props: {
    isStu: {
      type: Boolean,
      default: false
    },
    mainPathname: {
      type: String,
      default: ''
    },
    tiny: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    size() {
      return this.isStu ? '24px' : this.manageSize;
    },
    manageSize() {
      // return this.tiny ? '16px' : '18px';
      return '18px';
    },
    fontSize() {
      // return this.tiny ? '12px' : '14px';
      return '14px';
    },
    fontSizeLbl() {
      return this.isStu ? '16px' : this.fontSize;
    },
    padding() {
      // return this.isStu ? 'padding:2px 20px;' : this.managePadding;
      return 'padding:2px 20px;';
    },
    managePadding() {
      return this.tiny ? 'padding:6px 20px;' : 'padding:10px 20px;';
    },
    workbenchNavList() {
      return this.$store.state.navManageStore.workbenchNavList;
    }
  },
  watch: {
    workbenchNavList: {
      immediate: true,
      handler(val) {
        if (!val) return;
        window.localStorage.setItem(`${defaultPath}nav_datas_5`, JSON.stringify(val));
        this.workList = val.filter(i=>i.parentId === this.orgId);
      }
    }
  },

  mounted() {
    this.getAuditCountByMe();
  },

  methods: {
    // 获取审核中心数量
    getAuditCountByMe() {
      getAuditCount().then(res => {
        this.toDoSum = res.count;
      });
    },
    isAuditCenter(item) {
      return item && item.code === 'pc_audit_center';
    },
    transformPageNode(page) {
      if (this.isAuditCenter(page)) {
        page.pageUrl = this.toDoSum ? '/audit-center/#/my-audit' : '/audit-center/#/my-apply';
      }
    },
    openPage(page) {
      // 记录当前是学员端还是管理端
      if (this.isAuditCenter(page)) {
        page.pageUrl = this.toDoSum ? '/audit-center/#/my-audit' : '/audit-center/#/my-apply';
      }
      window.localStorage.setItem('workbenchEntry', window.location.href);
      return goSubNavPage(page);
    }
    // confirm() {
    //   window.localStorage.setItem('coreGuard', 1);
    //   this.workBenchGuardVisible = false;
    // }
  }
};

</script>
<style lang="scss" scoped>
.audit-center-enter {
  cursor: pointer;
  height: 18px;
  &.is-stu {
    width: 24px;
    height: 24px;
  }
  &.tiny {
    height: 18px;
  }
}

.yxtbiz-nav-work-bench {
  .lbl {
    font-size: 14px;
    vertical-align: middle
  }
  .yxt-divider--vertical {
    margin-left: 4px;
    margin-right: 20px;
  }
  &.is-stu {
  .lbl {
    font-size: 16px;
  }
  .yxt-divider--vertical {
    margin-left: 10px;
  }
  }
}

</style>
<style lang="scss">
.nav-workbench-dropdown {
  top: 48px !important;
  margin-right: 34px;
  &.tiny {
    top: 32px !important;
  }
  &.is-stu {
    top: 63px !important;
  }
  overflow: hidden;
  .yxt-dropdown-menu__item {
    padding: 0 42px;
  }
  .yxt-badge__content {
    position:initial;
    vertical-align: text-top;
    height: 16px;
    line-height: 16px;
  }
  .nav-workbench-dropdown_name {
    display: inline-block;
    min-width: 56px;
  } 
  
}
</style>
