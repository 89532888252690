<!-- 创建时间2024/01/04 10:25:51 -->
<!-- 创建人：Zhao bing bing -->
<!-- 组件描述：上传知识 -->
<template>
  <KngRelated type="uploadKnowledge" v-bind="$attrs" v-on="$listeners"/>
</template>

<script>
import KngRelated from '../common/kng/kng-releated.vue';
export default {
  name: 'KngLibUpload',
  components: {
    KngRelated
  }
};
</script>