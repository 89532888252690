<template>
  <yxt-drawer
    @close="close"
    :title="title || $t('pc_biz_core_tit_msgCollector')"
    :has-scroll="true"
    :visible.sync="showDrawer"
    size="480px"
  >
    <div>
      <dl class="yxt-biz-msgselector-item">
        <dt class="color-gray-10">{{ $t('pc_biz_core_lbl_requiredInfo') }}</dt>
        <dd :key="index" v-for="(item, index) in required">
          <span>
            {{ item.label }}
          </span>
          <span
            v-if="!unRemoveSet.has(item.value)"
            class="yxt-biz-color-danger hand"
            @click="remove(item, 1)"
          >
            <yxt-svg
              width="18px"
              height="18px"
              class="v-mid"
              icon-class="minus-circle-o"
            />
            <span class="ml5 v-mid">{{ $t('pc_biz_core_lbl_remove') }}</span>
          </span>
        </dd>
      </dl>
      <dl class="yxt-biz-msgselector-item">
        <dt class="color-gray-10">{{ $t('pc_biz_core_lbl_optionalInfo') }}</dt>
        <dd :key="index" v-for="(item, index) in optional">
          <span>
            {{ item.label }}
          </span>
          <span class="yxt-biz-color-danger hand" @click="remove(item, 2)">
            <yxt-svg
              width="18px"
              height="18px"
              class="v-mid"
              icon-class="minus-circle-o"
            />
            <span class="ml5 v-mid">{{ $t('pc_biz_core_lbl_remove') }}</span>
          </span>
        </dd>
      </dl>
      <dl class="yxt-biz-msgselector-item">
        <dt class="color-gray-10">{{ $t('pc_biz_core_lbl_notAddedInfo') }}</dt>
        <dd :key="index" v-for="(item, index) in unselectlist">
          <span>
            {{ item.label }}
          </span>
          <span class="color-primary-6 hand">
            <span @click="add(item, 1)">
              <yxt-svg
                width="18px"
                height="18px"
                class="v-mid"
                icon-class="plus-circle-o"
              />
              <span class="ml5 v-mid">{{
                $t('pc_biz_core_lbl_requiredItem')
              }}</span>
            </span>
            <span class="ml24" @click="add(item, 2)">
              <yxt-svg
                width="18px"
                height="18px"
                class="v-mid"
                icon-class="plus-circle-o"
              />
              <span class="ml5 v-mid">{{
                $t('pc_biz_core_lbl_optionalItem')
              }}</span>
            </span>
          </span>
        </dd>
      </dl>
    </div>
    <div slot="footer">
      <yxt-button plain @click="close">{{
        $t('pc_biz_utility_btn_cancel')
      }}</yxt-button>
      <yxt-button @click="select" type="primary">{{
        $t('pc_biz_core_btn_confirm')
      }}</yxt-button>
    </div>
  </yxt-drawer>
</template>

<script>
import { getExtSpars } from './service';
// const requiredKey = ['name', 'mobile'];
const requiredKey = [];
export default {
  name: 'YxtbizMsgCollectorV2',
  props: {
    visible: {
      default: false,
      type: Boolean
    },
    title: {
      default: '',
      type: String
    },
    defaultData: {
      type: Object,
      default() {
        return {
          required: []
        };
      }
    },
    unRemove: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      showDrawer: this.visible,
      required: [],
      optional: [],
      unselectlist: [],
      unRemoveSet: new Set([]),
      // 系统内置字段
      all: [
        {
          label: this.$t('pc_biz_core_lbl_name'),
          value: 'fullname'
        },
        {
          label: this.$t('pc_biz_core_lbl_phone'),
          value: 'mobile'
        },
        { label: this.$t('pc_biz_core_lbl_gender'), value: 'sex' },
        { label: this.$t('pc_biz_core_lbl_mail'), value: 'email' },
        { label: this.$t('pc_biz_core_lbl_company'), value: 'company' },
        { label: this.$t('pc_biz_core_lbl_department'), value: 'department' },
        { label: this.$t('pc_biz_core_lbl_userNo'), value: 'userNo' },
        { label: this.$t('pc_biz_core_lbl_position'), value: 'position' },
        {
          label: this.$t('pc_biz_core_lbl_identitycard'),
          value: 'idNo'
        },
        // { label: th  is.$t('pc_biz_core_lbl_leavingmsg'), value: 'leavingmsg' },
        { label: this.$t('pc_biz_core_lbl_city'), value: 'city' }
      ],
      spares: [] // 外部账号扩展字段
    };
  },
  computed: {
    items() {
      return {
        required: this.required.map(item => item.value),
        optional: this.optional.map(item => item.value)
      };
    }
  },
  created() {
    this.getOrgSparList();
  },
  methods: {
    async getOrgSparList() {
      try {
        const { datas } = await getExtSpars();
        this.spares = datas.map(i=>{
          return {
            label: i.name,
            value: i.spareKey
          };
        });
        this.unselectlist = this.unselectlist.concat(this.spares);
        this.all = this.all.concat(this.spares);
        this.init(this.defaultData);
      } catch (e) {}
    },
    init(data) {
      let selected = [];
      /* eslint-disable no-undef */
      let required = data.required
        ? Array.from(new Set([].concat(requiredKey, data.required)))
        : requiredKey;

      selected = selected.concat(required);
      this.required = this.all.filter(node => required.includes(node.value));

      if (data.optional) {
        selected = selected.concat(data.optional);
        this.optional = this.all.filter(node =>
          data.optional.includes(node.value)
        );
      }

      this.unselectlist = this.all.filter(node => {
        return !selected.includes(node.value);
      });
    },
    close() {
      // eslint-disable-next-line no-undef
      let requried = new Set([]);
      this.defaultData.required &&
        this.defaultData.required.forEach(key => requried.add(key));
      requried = Array.from(requried);
      this.required = this.all.filter(node => requried.includes(node.value));
      this.optional = this.defaultData.optional
        ? this.all.filter(node =>
          this.defaultData.optional.includes(node.value)
        )
        : [];
      this.unselectlist = this.all.filter(node => {
        return ![]
          .concat(this.items.required, this.items.optional)
          .includes(node.value);
      });
      this.$emit('update:visible', false);
    },
    add(item, type) {
      // 1: requried, 2: optional
      this.addItem(item, type === 1 ? 'required' : 'optional');
      this.removeItem(item, 'unselectlist');
    },
    remove(item, type) {
      this.removeItem(item, type === 1 ? 'required' : 'optional');
      this.addItem(item, 'unselectlist');
    },
    addItem(item, field) {
      this[field].push(item);
    },
    removeItem(item, field) {
      this[field] = this[field].filter(node => {
        return node.value !== item.value;
      });
    },
    select() {
      this.$emit('select', this.items);
      this.close();
    }
  },
  watch: {
    visible(v) {
      this.showDrawer = v;
      if (v) {
        this.unRemoveSet = new Set(this.unRemove);
      }
    },
    defaultData: {
      immediate: true,
      handler(data) {
        this.init(data);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
