var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yxtbiz-select-exams" },
    [
      _c(
        "div",
        { staticClass: "clearfix font-size-14" },
        [
          _c("catalogDropdown", {
            staticClass: "pull-left mr12",
            attrs: {
              data: _vm.datasCatalog,
              dropToolTip: _vm.$t("pc_biz_lbl_selectcatalogforsearch", [
                _vm.$t("pc_biz_ote_lbl_exam"),
              ]),
              catalogId: _vm.datasSearch.catalogId,
              catalogName: _vm.datasSearch.catalogName,
            },
            on: {
              "update:catalogId": function ($event) {
                return _vm.$set(_vm.datasSearch, "catalogId", $event)
              },
              "update:catalog-id": function ($event) {
                return _vm.$set(_vm.datasSearch, "catalogId", $event)
              },
              "update:catalogName": function ($event) {
                return _vm.$set(_vm.datasSearch, "catalogName", $event)
              },
              "update:catalog-name": function ($event) {
                return _vm.$set(_vm.datasSearch, "catalogName", $event)
              },
              change: _vm.selectCatalog,
            },
          }),
          _c("yxt-input", {
            staticClass: "pull-left",
            staticStyle: { width: "240px" },
            attrs: {
              placeholder: _vm.$t("pc_biz_lbl_inputnameforsearch", [
                _vm.$t("pc_biz_ote_lbl_exampapername"),
              ]),
              searchable: "",
              maxlength: "50",
            },
            on: { search: _vm.examNameSearch },
            model: {
              value: _vm.datasSearch.name,
              callback: function ($$v) {
                _vm.$set(
                  _vm.datasSearch,
                  "name",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "datasSearch.name",
            },
          }),
        ],
        1
      ),
      _c(
        "yxt-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: _vm.refTable,
          staticClass: "mt16",
          attrs: {
            height: _vm.tableHeight,
            data: _vm.datasList,
            "default-sort": { prop: "updateTime", order: "desc" },
          },
          on: {
            "sort-change": _vm.sortChange,
            "select-all": _vm.selectAll,
            select: _vm.select,
          },
        },
        [
          _c("yxt-table-column", {
            attrs: {
              selectable: _vm.selectable,
              type: "selection",
              "show-overflow-tooltip": false,
              width: "38",
              align: "right",
              "clear-padding": "left-right",
            },
          }),
          _c("yxt-table-column", {
            attrs: {
              label: _vm.$t("pc_biz_ote_lbl_exampapername"),
              "show-overflow-tooltip": false,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "yxt-tooltip",
                      {
                        staticClass: "ellipsis",
                        attrs: {
                          "open-filter": "",
                          "max-width": 300,
                          effect: "dark",
                          content: scope.row.name,
                          placement: "top",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "color-primary-6 hand",
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.goExamPreview(scope.row.id)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.name) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("yxt-table-column", {
            attrs: {
              label: _vm.$t("pc_biz_ote_lbl_exampapertype"),
              prop: "buildType",
              align: "left",
              width: "160",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.buildType === 0
                      ? _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("pc_biz_ote_lbl_exampapertype0"))
                          ),
                        ])
                      : _vm._e(),
                    scope.row.buildType === 1
                      ? _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("pc_biz_ote_lbl_exampapertype1"))
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("yxt-table-column", {
            attrs: {
              label: _vm.$t("pc_biz_ote_lbl_updatetime"),
              prop: "updateTime",
              width: "140",
              sortable: "custom",
              formatter: _vm.shortDate,
              align: "left",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt16 clearfix" },
        [
          _c("yxt-pagination", {
            staticClass: "pull-right",
            attrs: {
              "page-size": _vm.datasSearch.limit,
              total: _vm.count,
              "current-page": _vm.page,
              layout: "total, prev, pager, next",
            },
            on: {
              "current-change": _vm.pageChange,
              "size-change": _vm.sizeChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }