var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yxtbiz-ai-robot__confirm width-percent-100" },
    [
      _c("simpleAnswer", {
        staticClass: "mb8",
        attrs: { msg: _vm.getTitleMsg },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isShow,
              expression: "isShow",
            },
          ],
          staticClass: "yxtbiz-ai-robot__form",
        },
        [
          _c(
            "yxt-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.ruleForm,
                "label-position": "top",
                rules: _vm.rules,
                size: "small",
              },
            },
            [
              _vm._l(_vm.formData, function (form, index) {
                return [
                  form.componentType === "input"
                    ? _c(
                        "yxt-form-item",
                        {
                          key: index,
                          staticClass: "mb16",
                          attrs: { label: form.labelName, prop: form.name },
                        },
                        [
                          _c("yxt-input", {
                            attrs: {
                              maxlength: "200",
                              "show-word-limit": "",
                              placeholder: _vm.$t(
                                "pc_biz_core_tip_placeholder"
                              ),
                              size: "small",
                              disabled: _vm.disabled,
                            },
                            on: { input: (v) => (_vm.ruleForm[form.name] = v) },
                            model: {
                              value: form.value,
                              callback: function ($$v) {
                                _vm.$set(form, "value", $$v)
                              },
                              expression: "form.value",
                            },
                          }),
                        ],
                        1
                      )
                    : form.componentType === "input-number"
                    ? _c(
                        "yxt-form-item",
                        {
                          key: index,
                          staticClass: "mb16",
                          attrs: { label: form.labelName, prop: form.name },
                        },
                        [
                          _c("yxt-input-number", {
                            staticClass: "w120",
                            attrs: {
                              size: "small",
                              disabled: _vm.disabled,
                              min: 1,
                              max: 200,
                            },
                            on: { input: (v) => (_vm.ruleForm[form.name] = v) },
                            model: {
                              value: form.value,
                              callback: function ($$v) {
                                _vm.$set(form, "value", $$v)
                              },
                              expression: "form.value",
                            },
                          }),
                        ],
                        1
                      )
                    : form.componentType === "select"
                    ? _c(
                        "yxt-form-item",
                        {
                          key: index,
                          staticClass: "mb16",
                          attrs: { label: form.labelName, prop: form.name },
                        },
                        [
                          _c(
                            "yxt-select",
                            {
                              staticClass: "width-percent-100",
                              attrs: {
                                size: "small",
                                clearable: "",
                                disabled: _vm.disabled,
                                placeholder: _vm.$t("pc_biz_lbl_selecttip"),
                              },
                              on: {
                                change: (v) => (_vm.ruleForm[form.name] = v),
                              },
                              model: {
                                value: form.value,
                                callback: function ($$v) {
                                  _vm.$set(form, "value", $$v)
                                },
                                expression: "form.value",
                              },
                            },
                            _vm._l(form.list, function (item) {
                              return _c("yxt-option", {
                                key: item.value,
                                attrs: {
                                  label: item.labelName,
                                  value: item.value,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : form.componentType === "date"
                    ? _c(
                        "yxt-form-item",
                        {
                          key: index,
                          staticClass: "mb16",
                          attrs: { label: form.labelName, prop: form.name },
                        },
                        [
                          _c("yxt-date-picker", {
                            staticClass: "width-percent-100-i",
                            attrs: {
                              type: "date",
                              size: "small",
                              format: "yyyy-MM-dd",
                              "value-format": "yyyy-MM-dd",
                              disabled: _vm.disabled,
                              placeholder: _vm.$t("pc_biz_lbl_selecttip"),
                            },
                            on: {
                              change: (v) => (_vm.ruleForm[form.name] = v),
                            },
                            model: {
                              value: form.value,
                              callback: function ($$v) {
                                _vm.$set(form, "value", $$v)
                              },
                              expression: "form.value",
                            },
                          }),
                        ],
                        1
                      )
                    : [
                        form.componentType
                          ? _c(form.componentType, {
                              key: index,
                              tag: "component",
                              attrs: {
                                config: form,
                                ruleForm: _vm.ruleForm,
                                disabled: _vm.disabled,
                              },
                              on: { change: (v) => _vm.handleChange(form, v) },
                              model: {
                                value: form.value,
                                callback: function ($$v) {
                                  _vm.$set(form, "value", $$v)
                                },
                                expression: "form.value",
                              },
                            })
                          : _vm._e(),
                      ],
                ]
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "btn-item" },
            [
              _c(
                "yxt-button",
                {
                  attrs: { disabled: _vm.disabled, plain: "" },
                  on: { click: _vm.handleReset },
                },
                [_vm._v(_vm._s(_vm.$t("pc_comp_table_resetFilter").d("重置")))]
              ),
              _c(
                "yxt-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.disabled,
                    loading: _vm.loading,
                  },
                  on: { click: _vm.submit },
                },
                [_vm._v(_vm._s(_vm.$t("pc_biz_common_btn_sure").d("确定")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }