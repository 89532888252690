<!--
 * @Author: qlm
 * @Description: description
 * @Date: 2020-07-16 10:24:12
 * @LastEditors: qlm
 * @LastEditTime: 2020-07-20 17:05:52
 * @FilePath: /yxt-biz-pc/packages/user-selector/src/components/checkList.vue
--> 
<template>
  <component :class="{'is-scrolling': isScrolling}" :is="tag">
    <div :style='frontStyle'></div>
    <slot :list='renderList'></slot>
    <div :style='footerStyle'></div>
  </component>
</template>

<script>
// import { debounce, throttle } from 'throttle-debounce';
const ITEM_HEIGHT = 29;
const SPLIT_LENGTH = 2;
export default {
  props: {
    data: {
      type: Array,
      default() {
        return [];
      }
    },
    tag: {
      type: String,
      default: 'div'
    },
    keeps: {
      type: Number,
      default: 120
    }
  },
  data() {
    return {
      start: 0,
      lastOffset: 0,
      buffer: Math.ceil(this.keeps / 3)
    };
  },
  computed: {
    isScrolling() {
      return this.data.length > 120;
    },
    renderList() {
      return this.data.length > 0 ? this.data.slice(this.start, this.start + this.keeps - 1) : [];
    },
    frontStyle() {
      return {
        height: `${ITEM_HEIGHT * this.start / SPLIT_LENGTH}px`
      };
    },
    footerStyle() {
      return {
        height: (this.start + this.keeps) >= this.data.length ? '0px' : `${Math.ceil((this.data.length - this.start - this.keeps) / SPLIT_LENGTH) * ITEM_HEIGHT}px`
      };
    }
  },
  mounted() {
    this.scrollNode = this.getScrollParent();
    this.timer = null;
    this.scrollNode = this.getScrollParent();
    this.throttledScrollHandler = () => {
      if (this.timer) window.cancelAnimationFrame(this.timer);
      this.timer = requestAnimationFrame(this.scrollHandler) ;
    };
    this.scrollNode.addEventListener('scroll', this.throttledScrollHandler);
  },
  beforeDestroy() {
    this.scrollNode.removeEventListener('scroll', this.throttledScrollHandler);
  },
  methods: {
    scrollHandler() {
      const scrollTop = this.scrollNode.scrollTop;
      this.start = this.getOffsetIndex(scrollTop);
      this.lastOffset = scrollTop;
    },
    update() {
      this.scrollHandler();
    },
    getOffsetIndex(scrollTop) {
      const offsetIndex = Math.floor(scrollTop / ITEM_HEIGHT) * 2;
      if (this.lastOffset < scrollTop) {
        // 向下滚动
        if (offsetIndex < this.start + this.buffer) {
          return this.start;
        } else {
          return offsetIndex;
        }
      } else {
        if (offsetIndex > this.start) {
          return this.start;
        } else {
          return Math.max(offsetIndex - this.buffer, 0);
        }
      }
    },
    getScrollParent() {
      let node = this.$el;
      while (node) {
        const overflow = window.getComputedStyle(node)['overflow-y'];
        if (overflow === 'auto' || overflow === 'scroll') {
          return node;
        }

        node = node.parentNode;
      }

      return document.documentElement;
    }
  },
  watch: {
    data() {
      this.update();
    }
  }
};
</script>
