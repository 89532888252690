<template>
  <div class="yxtbiz-funs-tree__slot">
    <yxt-tooltip
        placement="top-start"
        :max-width="500">
      <span slot="content"><yxtbiz-dept-name :name="node.label" /></span>
      <span><slot :data="data" :node="node">{{ node.label }}</slot></span>
    </yxt-tooltip>
    <span class="tree-tools" @click.stop>
      <yxt-dropdown custom-elm v-if="functions && funs.length > 0" :dropdownToBody="dropdownToBody" @visible-change="visibleChange">
        <span class="color-gray-6 hover-primary-6 yxtbiz-funs-tree__set"><yxt-svg width="16px" height="16px" icon-class ="set" /></span>
        <yxt-dropdown-menu slot="dropdown">
          <yxt-dropdown-item
            v-for="(btn, index) in funs"
            :key="index"
            :disabled="btn.disabled || false"
            @click.native="dropdownItemHandle(btn)"
          ><i :class="btn.icon" v-if="btn.icon" class="mr5"></i><span>{{btn.label}}</span></yxt-dropdown-item>
        </yxt-dropdown-menu>
      </yxt-dropdown>
    </span>
  </div>
</template>

<script>

export default {
  props: {
    node: {
      type: Object,
      default: () => {
        return {};
      }
    },
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    functionsRender: {},
    functions: {},
    placement: {},
    settingIcon: {
      default: 'yxt-icon-setting'
    },
    dropdownToBody: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      funs: []
    };
  },
  methods: {
    defaultHandler() {},
    dropdownItemHandle(btn) {
      if (btn.disabled) return;
      btn.handler ? btn.handler(this.node, this.data) : this.defaultHandler();
    },
    visibleChange(bool) {
      if (bool) {
        this.showDropdown();
      }
    },
    showDropdown() {
      if (!this.functions) {
        return;
      }
      if (this.functionsRender) {
        let funs = this.functionsRender(this.node, this.data);
        if (Array.isArray(funs)) {
          this.funs = funs;
        }
      }
    }
  },
  computed: {
  },
  mounted() {
    if (!this.data.includeAll) {
      this.data.includeAll = 0;
    }
  },
  watch: {
    functionsRender: {
      immediate: true,
      handler: function() {
        this.showDropdown();
      }
    }
  }
};
</script>
