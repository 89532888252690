<template>
  <!-- 回答 -->
  <answer v-if="msg.chatType === 1" :isWaitAnswer="isWaitAnswer" :show-portrait="fullscreen" @resend="val => $emit('resend', val)" :fullscreen="fullscreen" :msg="msg"  @feedback="$emit('feedback')"></answer>
  <!-- 提问 -->
  <question v-else-if="msg.chatType === 0" :show-portrait="fullscreen" :fullscreen="fullscreen" :msg="msg.content" @resend="$emit('resend')"></question>
  <div v-else class="system-msg">{{ msg.content }}</div>
</template>

<script>
import Answer from './answer.vue';
import Question from './question.vue';
export default {
  name: 'AiRobotMessage',
  components: {
    Answer,
    Question
  },
  props: {
    isWaitAnswer: {
      type: Boolean,
      default: false
    },
    msg: {
      type: Object,
      default: () => {}
    },
    fullscreen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  }
};
</script>
