<template>
  <div class="yxtbiz-person-range-selector" v-if="isDirectManage || isHavaManagePermission">
    <label class="font-size-14 color-gray-10"><slot></slot></label>
    <yxt-popover
      popper-class="select-popper"
      v-model="showPopper"
      :append-to-body="false"
      placement="bottom-start"
      :visible-arrow="false"
      trigger="manual"
    >
      <div class="pl24 pr24">
        <yxt-input
          class="search-input"
          :placeholder="$t('biz_udp_enter_deptmentname')"
          :size="size"
          v-model="filterText"
        >
        </yxt-input>
        <div class="tree-wrapper">
          <!-- <yxt-checkbox class="direct" v-model="checked">直属员工</yxt-checkbox> -->
          <yxt-tree
            show-checkbox
            wrap-width="320"
            :check-strictly="true"
            node-key="id"
            @check-change="handleTreeCheck"
            :default-expanded-keys="['']"
            class="filter-tree mt16"
            :data="data"
            :props="defaultProps"
            :filter-node-method="filterNode"
            ref="tree"
          >
            <span
              class="yxtbiz-prs__node"
              slot-scope="{ node }"
            >
              <span :title="node.label" class="ellipsis node-label">{{ node.label }}</span>
              <yxt-checkbox
                @change="checkChange"
                @click.native.stop
                v-if="!node.data.id"
                class="all-select"
                v-model="checked"
              >{{$t('biz_udp_all_selected')}}</yxt-checkbox>
            </span>
          </yxt-tree>
          <!-- <yxt-checkbox class="all-select" v-model="checked">选中全部</yxt-checkbox> -->

        </div>
      </div>

      <yxt-input
        slot="reference"
        @click.native="show"
        size="medium"
        class="yxtbiz-prs__search"
        :placeholder="$t('biz_udp_select_personrange')"
        suffix-icon="yxt-icon-arrow-down"
        readonly
        v-model="value"
      >
      <template slot="suffix"></template>
      </yxt-input>
      <div class="button-wrapper">
        <yxt-button @click="cancel" plain>{{$t('pc_biz_udp_btn_cancel')}}</yxt-button>
        <yxt-button
          @click="confirm"
          type="primary"
        >{{$t('pc_biz_udp_btn_confirm')}}</yxt-button>

      </div>
    </yxt-popover>
  </div>
</template>

<script>
// eslint-disable-next-line
import { getAuthSelectData, getDeptTree } from './service';
import { i18n } from '../../common-util/changeLangs';
import {
  Tree
} from 'yxt-pc';
const DIRECT_OBJ = {
  id: '000',
  name: i18n.t('biz_udp_direct_employee') // 直属员工
};
function formatObj(obj, name) {
  let arr = [];
  arr.push(obj);
  if (obj.hasOwnProperty(name) && Array.isArray(obj[name])) {
    obj[name].map(item => {
      arr = arr.concat(formatObj(item, name));
    });
  }
  return arr;
}
// const
export default {
  name: 'YxtbizPersonRangeSelector',
  components: {
    YxtTree: Tree
  },
  model: {
    prop: 'checkValue',
    event: 'check'
  },
  props: {
    size: {
      type: String,
      default: 'small'
    },
    checkValue: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      // 是否有管辖部门
      isHavaManagePermission: '',
      // 是否直属经理
      isDirectManage: '',
      showPopper: false,
      filterText: '',
      checked: false,
      data: [

      ],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      deptIds: []
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  computed: {
    value() {
      return this.checkValue.reduce((a, b, index) => {
        let s = index ? ',' : '';
        return `${a}${s}${b.name}`;
      }, '');
    }
  },
  mounted() {
    this.getAuthSelectData();
  },
  methods: {
    show() {
      this.showPopper = true;
      this.filterText = '';
      this.$refs['tree'].setCheckedNodes(this.checkValue);
    },
    cancel() {
      this.showPopper = false;
    },
    handleTreeCheck(a, b) {
      if (a.id !== DIRECT_OBJ.id) {
        if (!b) {
          this.checked = false;
        } else {
          // 是否全选
          const nodes = this.$refs['tree'].getCheckedNodes().filter(item => item.id !== DIRECT_OBJ.id);
          if (nodes.length === this.flatData.length) this.checked = true;
        }
      }
    },
    confirm() {
      const nodes = this.$refs['tree'].getCheckedNodes();
      this.$emit('check', nodes);
      this.showPopper = false;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    getAuthSelectData() {
      let params = {type: 4};
      getAuthSelectData(params).then(res => {
        this.isHavaManagePermission = res.isHavaManagePermission;
        this.isDirectManage = res.isDirectManage;
        if (this.isDirectManage) {
          this.data.unshift(DIRECT_OBJ);
        }
        if (this.isHavaManagePermission) {
          this.getDeptTree();
        }
      });
    },
    getDeptTree() {
      getDeptTree({scope: 4, parentId: ''}).then(res => {
        if (res.datas && res.datas.length !== 0) {
          res.datas[0].name = this.$t('biz_udp_my_department');
          res.datas[0].nocheck = true;
          this.flatData = formatObj(res.datas[0], 'children').slice(1);
          this.data = this.data.concat(res.datas);
        }
      });
    },
    checkChange(val) {
      if (val) {
        const nodes = this.$refs['tree'].getCheckedNodes();
        this.$refs['tree'].setCheckedNodes(this.flatData);
        if (nodes.some(item => item.id === DIRECT_OBJ.id)) {
          this.$refs['tree'].setChecked(DIRECT_OBJ.id, true);
        }
      } else {
        const nodes = this.$refs['tree'].getCheckedNodes();
        if (nodes.some(item => item.id === DIRECT_OBJ.id)) {
          this.$refs['tree'].setCheckedNodes([DIRECT_OBJ]);
        } else {
          this.$refs['tree'].setCheckedNodes([]);
        }
      }
    }
  }
};
</script>
