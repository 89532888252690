<template>
  <div class="contents">
    <ul>
      <li v-for="(d,index) in data" :key="index">
        <p @click="handleClick(d)"><i :class="showIcon(d)"></i>{{d.name || d.title}}</p>
        <div class="content-desc">
          <template v-if="d.bodyType===0 || type === 'hotIssues'">
            <div v-if="active===d.id" class="desc" v-html="displaySummary(d)"></div>
            <yxt-button v-if="active===d.id && d.summary && d.summary.length>150" type="text" @click="handleShowDetail(d)">查看</yxt-button>
          </template>
          <template v-else>
            <div v-if="active===d.id" class="video-cover">
              <h2>{{d.name || d.title}}</h2>
              <button type="button" class="play-button" @click="handleShowVideo(d)">立即观看</button>
            </div>
          </template>
        </div>
      </li>
    </ul>
    <div class="video-wrap" v-if="showVideo">
      <div class="video-play-wrap">
        <i class="yxt-icon-circle-close" @click="handleCloseVideo"></i>
        <yxtbiz-video
          v-if="videoObjects.length > 0"
          ref="video"
          :options="videoObjects"
          width="640"
          height="359.62"
          playRate
          @onBeforePlay="onBeforePlay"
          @onQualityChange="onQualityChange"
          @onTime="onTime"
          @onError="onError">
        </yxtbiz-video>
      </div>
    </div>
  </div>
</template>

<script>
import { getVideoUrl } from '../service';
export default {
  props: {
    data: Array,
    type: String,
    active: String
  },
  data() {
    return {
      showVideo: false,
      videoObjects: []
    };
  },
  methods: {
    // 显示图标
    showIcon(d) {
      return d.id === this.active ? 'yxt-icon-arrow-down' : 'yxt-icon-arrow-right';
    },
    // 查看内容
    handleClick(item) {
      if (!item) return;
      if (this.active === item.id) {
        this.$emit('select', '');
        return;
      }
      this.$emit('select', item.id);
    },
    // 查看全部
    handleShowDetail(item) {
      if (!item) return;
      if (this.type !== 'hotIssues') {
        return window.open(`${window.location.origin}/support/#/detail?contentId=${item.id}`, '_blank');
      }

      if (item.directoryId) {
        window.open(`${window.location.origin}/support/#/detail?issuesId=${item.id}&directoryId=${item.directoryId}&contentId=${item.contentId}`, '_blank');
      } else {
        window.open(`${window.location.origin}/support/#/detail?issuesId=${item.id}&contentId=${item.contentId}`, '_blank');
      }
    },
    // 显示内容为150个字符，超出...
    displaySummary(item) {
      if (!item) return '';
      if (!item.summary) return '';
      // 管理端未使用br直接拼接，考虑截取问题
      return item.summary.length > 150 ? item.summary.substring(0, 147).replace(/\r\n/ig, '<br/>') + '...' : item.summary.replace(/\\r\\n/ig, '<br/>');
    },
    // 关闭video播放界面
    handleCloseVideo() {
      this.showVideo = false;
    },
    // video播放界面
    handleShowVideo(item) {
      if (!item) return;
      this.showVideo = true;
      getVideoUrl(item.id).then(res=>{
        this.videoObjects = [{'fileFullUrl': res.url, 'label': '标准', tokenText: res.tokenText}];
      });
    },
    onBeforePlay(event) {

    },
    onQualityChange(event) {

    },
    onTime(event) {

    },
    onError(event) {

    }
  }
};
</script>
