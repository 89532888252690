var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "yxtbiz-group-org-select",
      class: { "yxtbiz-group-org-select-single": !_vm.multiple },
    },
    [
      _vm.multiple
        ? _c("multiple-select", {
            ref: "multipleSelect",
            attrs: {
              functionCode: _vm.functionCode,
              dataPermissionCode: _vm.dataPermissionCode,
              selected: _vm.selected,
              disabled: _vm.disabled,
              checkStrictly: _vm.checkStrictly,
              setCurrentOrg: _vm.setCurrentOrg,
              placeholder: _vm.getPlaceholder,
            },
            on: { selectedChange: _vm.selectedChange },
          })
        : _c("single-select", {
            attrs: {
              size: _vm.size,
              selectFirst: _vm.selectFirst,
              clearable: _vm.clearable,
              functionCode: _vm.functionCode,
              dataPermissionCode: _vm.dataPermissionCode,
              hideOrgIds: _vm.hideOrgIds,
              disabledOrgIds: _vm.disabledOrgIds,
              setCurrentOrg: _vm.setCurrentOrg,
              disabled: _vm.disabled,
              defaultId: _vm.defaultId,
              targetOrgId: _vm.targetOrgId,
              placeholder: _vm.getPlaceholder,
              allgroup: _vm.allgroup,
              visibleOrgIds: _vm.visibleOrgIds,
            },
            on: {
              orgInvalid: () => _vm.$emit("orgInvalid"),
              change: _vm.singleChange,
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }