/*
 * @Author: your name
 * @Date: 2021-09-01 17:08:53
 * @LastEditTime: 2022-02-14 15:07:40
 * @LastEditors: 张青
 * @Description: In User Settings Edit
 * @FilePath: \yxt-biz-pc\packages\user-center-nav\src\service.js
 */
import { rewardApi, udpApi } from 'yxt-biz-pc/packages/api';

// 已获得勋章数
export const getUserMedalCount = (orgId, userId) => {
  return rewardApi.get(`/user/medal/label?orgId=${orgId}&userId=${userId}`);
};

// 获取当前用户信息
export const getCurrUserInfo = () => {
  // return udpApi.get('/users/self');
  return udpApi.get('/users/detail?withPosResp=1');
};
