<template>
  <div class="yxtbiz-search__selections" :style="{marginTop: offsetTop + 5 + 'px'}" :class="[popperClass]">
    <yxt-scrollbar>
      <div class="yxtbiz-search__wrap" :style="{maxHeight: 40 * 10 + 16 + 36 * 2 + 'px'}">
        <div v-if="searchKey === '' || searchKey === null || searchKey === undefined">
          <ul class="yxtbiz-search__group" v-if="userHistories && userHistories.length && !hotOnly">
            <li class="yxtbiz-search__group-label color-gray-6">历史搜索<div class="yxtbiz-search__delete"><yxt-svg @click.native="deleteSearchHistories" class='color-gray-6 hover-primary-6' width="16px" height="16px" icon-class="delete" /></div></li>
            <li
              :style="{width: innerWidth}"
              class="yxtbiz-search__item color-gray-10 hover-primary-6 hover-bg-primary-1"
              v-for="item in userHistories"
              :key="item.id"
              @click="selectedOption(item)"
            >
              <div class="yxtbiz-search__ellipsis" v-if="searchKey === '' || searchKey === undefined || searchKey === null" v-text="item.name"></div>
              <div class="yxtbiz-search__ellipsis" v-else v-html="textRender(item.name)"></div>
            </li>
          </ul>
          <ul class="yxtbiz-search__group" v-if="orgHistories && orgHistories.length">
            <li class="yxtbiz-search__group-label color-gray-6" v-if="!hotOnly">热门搜索</li>
            <li :style="{width: innerWidth}"
              class="yxtbiz-search__item color-gray-10 hover-primary-6 hover-bg-primary-1"
              v-for="item in orgHistories"
              :key="item.id"
              @click="selectedOption(item)"
            >
              <div class="yxtbiz-search__ellipsis" v-if="searchKey === '' || searchKey === undefined || searchKey === null" v-text="item"></div>
              <div class="yxtbiz-search__ellipsis" v-else v-html="textRender(item)"></div>
            </li>
          </ul>
        </div>
        <ul v-else-if="!isTransition" class="pt8 pb8">
          <li
            :style="{width: innerWidth}"
            class="yxtbiz-search__item color-gray-10 hover-primary-6 hover-bg-primary-1"
            v-for="item in orgHistories"
            :key="item.id"
            @click="selectedOption(item)"
          >
            <div class="yxtbiz-search__ellipsis" v-if="searchKey === '' || searchKey === undefined || searchKey === null" v-text="item"></div>
            <div class="yxtbiz-search__ellipsis" v-else v-html="textRender(item)"></div>
          </li>
        </ul>
      </div>
    </yxt-scrollbar>
  </div>
</template>

<script>
import Popper from 'yxt-biz-pc/src/utils/vue-popper.js';
import { deleteSearchHistories } from './service';
import htmlEncode from 'yxt-biz-pc/src/utils/htmlEncode';
import { Scrollbar } from 'yxt-pc';
export default {
  name: 'SearchSelections',
  componentName: 'SearchSelections',
  components: {
    YxtScrollbar: Scrollbar
  },
  mixins: [Popper],
  props: {
    searchKey: {},
    options: {
      type: Object,
      default: () => {
        return {};
      }
    },
    appendToBody: {
      type: Boolean,
      default: true
    },
    placement: {
      default: 'bottom-start'
    },
    boundariesPadding: {
      default: 0
    },
    popperOptions: {
      default() {
        return {
          gpuAcceleration: false
        };
      }
    },
    visibleArrow: {
      default: false
    },
    offsetTop: {
      type: Number,
      default: 0
    },
    isTransition: {
      type: Boolean
    },
    hotOnly: {
      type: Boolean,
      default: false // 只显示热门搜索，此时会隐藏历史搜索及分组标题
    }
  },
  data() {
    return {
      innerWidth: ''
    };
  },
  computed: {
    popperClass() {
      return this.$parent.popperClass;
    },
    userHistories() {
      if (!this.options.userHistories) {
        return [];
      }
      return this.options.userHistories.length > 5 ? this.options.userHistories.slice(0, 5) : this.options.userHistories;
    },
    orgHistories() {
      if (!this.options.orgHistories) {
        return [];
      }
      if (this.searchKey || this.searchKey === 0) {
        return this.options.orgHistories.length > this.limit ? this.options.orgHistories.slice(0, this.limit) : this.options.orgHistories;
      } else {
        return this.options.orgHistories.length > 5 ? this.options.orgHistories.slice(0, 5) : this.options.orgHistories;
      }

    },
    highlight() {
      return this.$parent.highlight;
    },
    limit() {
      return this.$parent.limit;
    }
  },
  mounted() {
    this.referenceElm = this.$parent.$refs.reference.$el;
    this.$parent.popperElm = this.popperElm = this.$el;
    this.$on('updatePopper', () => {
      if (this.$parent.isShowList) this.updatePopper();
    });
    this.$on('destroyPopper', this.destroyPopper);
  },
  methods: {
    deleteSearchHistories() {
      if (!this.userHistories || !this.userHistories.length) {
        return;
      }
      this.$confirm('此操作将永久删除用户搜索历史, 是否继续?', '确定要清空用户搜索历史吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteSearchHistories().then(() => {
          this.$message({
            type: 'success',
            message: '已清空!'
          });
          this.$emit('update:options', {
            userHistories: [],
            orgHistories: this.options.orgHistories
          });
        }).catch(() => {});
      }).catch(() => {
      });
    },
    selectedOption(item) {
      if (typeof item === 'object') {
        this.$emit('selected', item.name);
      } else {
        this.$emit('selected', item);
      }
    },
    textRender(text) {
      if (!text || !this.highlight) {
        return text;
      }
      if (this.isTransition) {
        return text;
      }
      if (this.searchKey === '' || this.searchKey === undefined || this.searchKey === null) {
        return text;
      }
      let encodeText = this.getEncodeText(text);
      let searchKeyEncode = this.getEncodeText(this.searchKey);
      let splitArr = encodeText.split(searchKeyEncode);
      if (splitArr.length <= 1) {
        return encodeText;
      }
      let joinText = '';
      splitArr.forEach((v, i) => {
        joinText += `<span class="color-gray-10">${v}</span>`;
        if (i !== splitArr.length - 1) {
          joinText += `<span class="color-primary-6">${searchKeyEncode}</span>`;
        }
      });
      return joinText;
    },
    getEncodeText(text) {
      return htmlEncode.htmlEncode(text);
    }
  },
  watch: {
    '$parent.inputWidth'() {
      if (!this.$parent.$refs.reference || !this.$parent.$refs.reference.$el) {
        return;
      }
      // const referenceWidth = this.$parent.$refs.reference.$el.getBoundingClientRect().width + 'px';
      const inputWidth = this.$parent.$refs.reference.$refs.input.getBoundingClientRect().width + 'px';
      this.innerWidth = inputWidth;
    }
  }
};
</script>
