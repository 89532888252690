var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "clickoutside",
          rawName: "v-clickoutside",
          value: _vm.handleClose,
          expression: "handleClose",
        },
      ],
      class: [
        "yxtbiz-search",
        _vm.size ? `yxtbiz-search--${_vm.size}` : "yxtbiz-search--medium",
        _vm.softFocus ? "yxt-biz-search--focus" : "",
      ],
      on: {
        mouseenter: _vm.handleMouseenter,
        mouseleave: _vm.handleMouseleave,
      },
    },
    [
      _c(
        "yxtf-input",
        {
          ref: "reference",
          attrs: {
            size: _vm.size,
            searchable: _vm.searchable,
            placeholder: _vm.placeholder,
            radusType: _vm.radusType,
            styleType: _vm.styleType,
            pendType: _vm.pendType,
            maxlength: _vm.max,
          },
          on: {
            search: _vm.enterSearch,
            focus: _vm.handleFocus,
            blur: _vm.handleBlur,
            input: _vm.handleInput,
          },
          model: {
            value: _vm.searchKey,
            callback: function ($$v) {
              _vm.searchKey = $$v
            },
            expression: "searchKey",
          },
        },
        [
          _c("template", { slot: "append" }, [_vm._t("default")], 2),
          _c(
            "template",
            { slot: "suffix" },
            [
              _vm.showClear
                ? _c("i", {
                    staticClass:
                      "yxt-input__icon yxt-icon-error yxt-input__clear",
                    on: {
                      mousedown: function ($event) {
                        $event.preventDefault()
                      },
                      click: _vm.clear,
                    },
                  })
                : _vm._e(),
              _vm._t("suffix"),
            ],
            2
          ),
        ],
        2
      ),
      _vm.isShowSearchFind
        ? _c(
            "transition",
            {
              attrs: { name: "yxt-zoom-in-top" },
              on: { "after-leave": _vm.doDestroy },
            },
            [
              _c("search-selections", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isShowList,
                    expression: "isShowList",
                  },
                ],
                ref: "popper",
                attrs: {
                  searchKey: _vm.searchKey,
                  options: _vm.options,
                  appendToBody: "",
                  hotOnly: _vm.hotOnly,
                  offsetTop: _vm.offsetTop,
                  isTransition: _vm.isTransition,
                },
                on: {
                  "update:options": function ($event) {
                    _vm.options = $event
                  },
                  selected: _vm.handleSelected,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }