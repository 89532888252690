<template>
    <div class="study-center-nav font-size-14 color-gray-10 bg-white">
        <ul>
            <li v-for="(item, index) in navList" :key="item.name" class="tab-item hand hover-bg-primary-1 hover-primary-6"
                :class="{'color-primary-6 bg-primary-1': pageCode === item.pageCode}"
                @click="changeTab(index,item)">
                <div>
                    <yxtf-svg  :class="[pageCode === item.pageCode ? 'color-primary-6' : 'default-color']"
                               :remote-url='mediaUrl'
                               class='hover-primary-6 tab-icon'
                               width="22px" height="22px"
                               :icon-class="item.icon" />
                  <span class="tab-label yxt-weight-4" :class="{'yxt-weight-6': pageCode === item.pageCode}">{{item.name}}</span>
                </div>
                <span class="tab-count standard-size-16"
                      v-if="item.count"
                      :class="[pageCode === item.pageCode ? 'bg-white color-primary-6' : 'bg-gray-2 color-gray-8 ']">{{item.count | max99}}</span>
            </li>
        </ul>
    </div>
</template>

<script>
import { getEnrollCount, getKngPanel, getUemCount, getSurveyCount, getO2oCount, getBbsCount, getCollectCount, getCerCount } from './service';
import { FrontSvgIcon } from 'yxt-pc';
const baseCommon = (typeof window !== 'undefined' && window.feConfig && window.feConfig.common);

export default {
  name: 'YxtbizStudyCenterNav',
  components: {
    YxtfSvg: FrontSvgIcon
  },
  props: {
    pageCode: {
      type: String,
      required: true
    },
    mediaUrl: {
      type: String,
      default: `${(baseCommon && window.feConfig.common.staticBaseUrl) || 'https://stc.yxt.com/'}ufd/2338a3/common/pc/svg`
    }
  },
  data() {
    return {
      currentIndex: '',
      data: [
        {name: '项目中心', icon: 'workbench-center', url: '/o2o/#/stu/list', pageCode: 'o2o_self_project', count: 0},
        {name: '考试中心', icon: 'examination-centre', url: '/ote/#/myexam', pageCode: 'ote_my_exam_center', count: 0},
        {name: '自学知识', icon: 'self-learning', url: '/kng/#/selfstudy', pageCode: 'kng_self_study', count: 0},
        {name: '报名中心', icon: 'registration-center', url: '/enroll/#/', pageCode: 'enroll_center', count: 0},
        {name: '调研&评价', icon: 'research', url: '/survey/#/', pageCode: 'survey_center', count: 0},
        {name: '帖子&问答', icon: 'Q-A', url: '/bbs/#/my', pageCode: 'bbs_my_publish', count: 0},
        {name: '知识分享', icon: 'knowledge-sharing', url: '/kng/#/share', pageCode: 'kng_share', count: 0},
        {name: '知识评论', icon: 'comments', url: '/kng/#/mycomments', pageCode: 'kng_my_comments', count: 0},
        {name: '学习笔记', icon: 'study-notes', url: '/kng/#/notelist', pageCode: 'kng_note_list', count: 0},
        {name: '我的收藏', icon: 'my-collection', url: '/utility/#/collect/kng', pageCode: 'utility_collec_kng', count: 0},
        // {name: '错题集', icon: 'wrong-question', url: '', pageCode: '', count: 0},
        // {name: '学习成就', icon: 'learning-achievement', url: '', pageCode: '', count: 0},
        {name: '我的证书', icon: 'my-certificate', url: '/cer/#/mycer', pageCode: 'cer_my_cer', count: 0},
        // {name: '我的勋章', icon: 'my-medal', url: '', pageCode: '', count: 0},
        // {name: '学习档案', icon: 'study-file', url: '', pageCode: '', count: 0},
        {name: '学习历史', icon: 'history', url: '/#/studyhistory', pageCode: 'core_study_center', count: 0}
      ]
    };
  },
  computed: {
    navList() {
      return this.data;
    }
  },
  filters: {
    max99(value) {
      return value < 99 ? value : '99+';
    }
  },
  created() {
    getEnrollCount().then(count => {
      this.$set(this.data[3], 'count', count.data);
    });
    getBbsCount().then(res => {
      this.$set(this.data[5], 'count', res.paging.count);
    });
    getKngPanel().then(({ selfStudyCount }) => {
      this.$set(this.data[2], 'count', selfStudyCount);
    });
    getUemCount().then(({ count }) => {
      this.$set(this.data[1], 'count', count);
    });
    getSurveyCount().then(({ data }) => {
      this.$set(this.data[4], 'count', data);
    });
    getO2oCount().then(({ projectCount }) => {
      this.$set(this.data[0], 'count', projectCount);
    });
    getKngPanel().then((res) => {
      this.$set(this.data[6], 'count', res.uploadCount);
    });
    getCollectCount().then((res) => {
      this.$set(this.data[9], 'count', res.count);
    });
    getCerCount().then((res) => {
      this.$set(this.data[10], 'count', res.count);
    });
  },
  methods: {
    changeTab(index, { url }) {
      if (!url) return;
      window.location.href = url;
    }
  }
};
</script>


