var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-list", {
    attrs: {
      dataList: _vm.datas,
      size: _vm.size,
      tagLists: _vm.tagLists,
      searchName: "pc_biz_o2o_lbl_practice",
      tagType: "praStatus",
      listId: "trackPraId",
      listName: "praName",
    },
    on: { "to-list": _vm.toList, "to-more": _vm.toListMore },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ list }) {
          return [
            _c("span", [_vm._v(_vm._s(list.createFullname || "--"))]),
            _c("yxt-divider", { attrs: { direction: "vertical" } }),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t("pc_biz_ote_lbl_create_time_tips", [
                    _vm.shortDateTime(list.createTime),
                  ])
                )
              ),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }