var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "div",
        { staticClass: "yxtbiz-i18n-custom-template" },
        [
          _c(
            "yxt-drawer",
            _vm._b(
              {
                attrs: {
                  visible: _vm.visible,
                  size: _vm.size,
                  "before-close": _vm.beforeDrawClose,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.visible = $event
                  },
                  close: _vm.close,
                },
              },
              "yxt-drawer",
              _vm.$attrs,
              false
            ),
            [
              _c(
                "div",
                { attrs: { slot: "title" }, slot: "title" },
                [
                  _c("span", { staticClass: "font-size-20" }, [
                    _vm._v(_vm._s(_vm.title)),
                  ]),
                  _c(
                    "yxt-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: _vm.$t("biz_msg_drawerTip"),
                        placement: "top",
                      },
                    },
                    [
                      _c(
                        "span",
                        [
                          _c("yxt-svg", {
                            staticClass:
                              "yxtbiz-i18n-custom-template__svgIcon color-gray-6 hover-primary-6",
                            attrs: {
                              width: "20px",
                              height: "20px",
                              "icon-class": "question-cirlce-o",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "yxtbiz-i18n-custom-template__main" },
                [
                  _vm.showForm
                    ? _c(
                        "yxt-form",
                        {
                          ref: "form",
                          attrs: {
                            "label-width": "100px",
                            model: _vm.templateDrawer.formData,
                            enableDetectFieldsChange: "",
                          },
                        },
                        _vm._l(
                          _vm.templateDrawer.fields,
                          function (field, index) {
                            return _c(
                              "yxt-form-item",
                              {
                                key: field.prop,
                                class: "form-item-" + field.prop,
                                attrs: {
                                  label: field.label,
                                  "lable-width": "120px",
                                  prop: field.prop,
                                  rules: field.rules,
                                  "label-tooltip":
                                    field.type === "textarea"
                                      ? _vm.$t("biz_msg_customMsgTip")
                                      : "",
                                },
                              },
                              [
                                field.type === "richeditor"
                                  ? _c("yxtbiz-richeditor", {
                                      ref: "control",
                                      refInFor: true,
                                      attrs: {
                                        content:
                                          _vm.templateDrawer.formData[
                                            field.prop
                                          ],
                                        "function-name": "richeditor",
                                        "max-length": field.maxlength,
                                        "tools-height": "58px",
                                        height: "500px",
                                        "org-code": "xxv2",
                                        showI18nBtn: _vm.enableI18n,
                                        filterContent: false,
                                        "app-code": "core",
                                        "module-name": "msg",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.handleEditorChange(
                                            $event,
                                            index
                                          )
                                        },
                                      },
                                      nativeOn: {
                                        "!click": function ($event) {
                                          return _vm.showLangDrawer(
                                            $event,
                                            "richeditor"
                                          )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _vm.enableI18n
                                  ? [
                                      (_vm.tunnelName &&
                                        field.type === "textarea") ||
                                      field.type === "text"
                                        ? _c("yxtbiz-i18n-input", {
                                            ref: "control",
                                            refInFor: true,
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              maxlength:
                                                typeof field.maxlength ===
                                                "object"
                                                  ? field.maxlength[_vm.curLang]
                                                  : field.maxlength,
                                              "show-word-limit":
                                                field.showWordLimit,
                                              placeholder: field.placeholder,
                                              type: field.type,
                                            },
                                            nativeOn: {
                                              "!click": function ($event) {
                                                return _vm.showLangDrawer(
                                                  $event,
                                                  field.type
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.templateDrawer.formData[
                                                  field.prop
                                                ],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.templateDrawer.formData,
                                                  field.prop,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "templateDrawer.formData[field.prop]",
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  : [
                                      (_vm.tunnelName &&
                                        field.type === "textarea") ||
                                      field.type === "text"
                                        ? _c("yxt-input", {
                                            ref: "control",
                                            refInFor: true,
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              maxlength:
                                                typeof field.maxlength ===
                                                "object"
                                                  ? field.maxlength[_vm.curLang]
                                                  : field.maxlength,
                                              "show-word-limit":
                                                field.showWordLimit,
                                              placeholder: field.placeholder,
                                              type: field.type,
                                            },
                                            model: {
                                              value:
                                                _vm.templateDrawer.formData[
                                                  field.prop
                                                ],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.templateDrawer.formData,
                                                  field.prop,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "templateDrawer.formData[field.prop]",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                              ],
                              2
                            )
                          }
                        ),
                        1
                      )
                    : _vm._e(),
                  _vm.langDrawer.visible
                    ? _c(
                        "div",
                        { staticClass: "lang-drawer-wrap" },
                        [
                          _c("yxtbiz-i18n-lang", {
                            attrs: {
                              visible: _vm.langDrawer.visible,
                              "data-list": _vm.currentFields,
                              module: _vm.module,
                              "drawer-class": "i18ncustom-langconf-drawer",
                              size: _vm.size,
                            },
                            on: {
                              "update:visible": function ($event) {
                                return _vm.$set(
                                  _vm.langDrawer,
                                  "visible",
                                  $event
                                )
                              },
                              confirm: _vm.handleConfirmLang,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.tipInfoName || _vm.ifThirdImage
                ? _c(
                    "div",
                    { staticClass: "lang-drawer-pic" },
                    [
                      _c("yxtbiz-upload-image", {
                        attrs: {
                          list: _vm.list,
                          limit: 1,
                          size: 1024,
                          suggest: { w: 600, h: 256 },
                          filters: ".jpg,.png",
                          "no-preview": "",
                        },
                        on: {
                          fileAdded: _vm.fileAdded,
                          fileRemoved: _vm.fileRemoved,
                        },
                      }),
                      _vm.tipInfoName
                        ? _c("span", { staticClass: "lang-drawer-pic_tip" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("biz_msg_tip_uploadTip", [
                                  _vm.tipInfoName,
                                ])
                              )
                            ),
                          ])
                        : _vm.ifThirdImage
                        ? _c("span", { staticClass: "lang-drawer-pic_tip" }, [
                            _vm._v(_vm._s(_vm.$t("pc_biz_imgcropper_title"))),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "yxt-button",
                    { attrs: { plain: "" }, on: { click: _vm.close } },
                    [_vm._v(_vm._s(_vm.$t("pc_biz_udp_btn_cancel")))]
                  ),
                  _c(
                    "yxt-button",
                    { attrs: { type: "primary" }, on: { click: _vm.save } },
                    [_vm._v(_vm._s(_vm.$t("pc_biz_core_btn_confirm")))]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }