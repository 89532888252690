<template>
  <base-list
    :dataList="datas"
    :size="size"
    :tagLists="tagLists"
    searchName="pc_biz_o2o_lbl_practice"
    tagType="upmStatus"
    listId="praId"
    listName="praName"
    @to-list="toList"
    @to-more="toListMore">
    <!-- slot插槽处理 -->
    <template slot-scope="{ list }">
      <span>{{ $t('pc_biz_ote_lbl_minutes' /* 时间 */) }}：{{ getDateTimeFormatted({startTime: list.entryStartTime, endTime: list.entryEndTime}) }}</span>
    </template>
  </base-list>
</template>

<script>
import CommonMixin from '../common/props-mixin';
import utils from 'yxt-biz-pc/packages/common-util/utils.js';
import BaseList from '../search-exam/base-list.vue';

export default {
  name: 'SearchPractice',
  mixins: [CommonMixin],
  components: {
    BaseList
  },
  data() {
    const tagLists = {
      0: {
        label: 'pc_biz_ls_notStart', // 未开始
        type: 'info'
      },
      1: {
        label: 'pc_biz_cer_lbl_expired', // 已过期
        type: 'info'
      },
      2: {
        label: 'pc_biz_ote_lbl_status2', // 进行中
        type: ''
      },
      3: {
        label: 'pc_biz_shoptour_lbl_finished', // 已完成
        type: 'success'
      }
    };

    return {
      tagLists
    };
  },

  methods: {
    getDateTimeFormatted: utils.getDateTimeFormatted,
    toList(list) {
      window.open(`${window.location.origin}/ote/#/stu/practicepreview?praId=${list.praId}`, '_blank');
    },
    toListMore() {
      localStorage['ai-search-practice'] = JSON.stringify(this.params);
      console.log(this.params);
      window.open(`${window.location.origin}/ote/#/stu/myexam?pra=1`, '_blank');
    }
  }
};
</script>
