<template>
  <div class="yxtbiz-ai-robot-demo" :class="{ 'is-fullscreen': bFullscreen, 'is-max': max }">
    <draggable-container :bFullscreen="bFullscreen" @resize="() => $refs.msgDialog.scrollToEnd()">
      <broadside v-if="bFullscreen" @clearHistory="clearHistory" :bFullscreen="bFullscreen" :title="title" ></broadside>
      <msg-dialog ref="msgDialog" :title="title" :sid="sessionId" :max="max" :bFullscreen="bFullscreen" @fullscreen="v => bFullscreen = v" @feedback="feedbackDialogVisible = true" @close="closeAI"></msg-dialog>
    </draggable-container>
    <feedback-dialog :visible.sync="feedbackDialogVisible" @success="feedbackSuccess"></feedback-dialog>
  </div>
</template>

<script>
import { getChatSetting, getUid } from './service';
import FeedbackDialog from './components/feedbackDialog.vue';
import MsgDialog from './components/msgDialog.vue';
import DraggableContainer from './components/draggableContainer.vue';
import broadside from './components/broadside.vue';

export default {
  name: 'YxtbizAiRobotDemo',
  components: {
    broadside,
    MsgDialog,
    FeedbackDialog,
    DraggableContainer
  },
  props: {
    isShare: {
      type: Boolean,
      default: false
    },
    max: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default() {
        return this.$t('pc_biz_ai_title').d('企业AI助手');
      }
    },
    sessionId: {
      type: String,
      default: () => {
        return getUid();
      }
    }
  },
  watch: {
    visible(v) {
      if (v) this.$refs.msgDialog && this.$refs.msgDialog.scrollToEnd();
    }
  },
  provide() {
    return {
      settings: this.settings
    };
  },
  data() {
    return {
      feedbackDialogVisible: false,
      bFullscreen: false,
      visible: false,
      settings: {
        answerInteract: '', // 未知
        publicResourceEnabled: false, // 是否开通互联网
        factorOpenFlag: false, // 是否开通
        assistEnabled: false, // 是否开启
        accessEnabled: false, // 是否有权限
        assistHeadImg: 'https://stc.yxt.com/ufd/407a24/assistent/pc/img/avatar.jpg',
        assistWele: '吃饱了，打个嗝儿~', // 助手欢迎语
        cheatTips: '',
        fastFeedbackList: [] // 反馈提示词
      }
    };
  },
  created() {
    this.bFullscreen = !!this.max;
    // TODO: 配置接口联调
  },
  methods: {
    clearHistory() {
      this.$refs.msgDialog.clearHistory();
    },
    // 获取AI助手配置
    getChatSetting() {
      getChatSetting().then(res => {
        if (res) {
          this.settings.answerInteract = !!res.answerInteract;
          this.settings.assistEnabled = !!res.assistEnabled;
          this.settings.factorOpenFlag = !!res.factorOpenFlag;
          this.settings.accessEnabled = !!res.accessEnabled;
          this.settings.publicResourceEnabled = !!res.publicResourceEnabled;
          this.settings.assistHeadImg = res.assistHeadImg || 'https://stc.yxt.com/ufd/407a24/assistent/pc/img/avatar.jpg';
          this.settings.assistWele = res.assistWele;
          this.settings.cheatTips = res.cheatTips;
          this.settings.fastFeedbackList = res.fastFeedbackList || [];
          if (this.visible) { // 页面展示则提示，不展示则不提示
            if (!res.factorOpenFlag) {
              this.$message.warning(this.$t('pc_biz_ai_not_buy_tips').d('未增购AI助手！'));
              return;
            }
            if (!res.assistEnabled) {
              this.$message.warning(this.$t('pc_biz_ai_status_tips').d('暂未开启AI助手！'));
              return;
            }
            if (!res.accessEnabled) { // 无权限的用户
              if (this.isShare) { // 全屏分享页面直接去无权限页面
                this.$emit('no-premission');
              } else { // 非分享页给提示
                this.$message.warning(this.$t('pc_biz_ai_no_premission').d('暂无AI助手的使用权限！'));
                return;
              }
            }
          }
        }
      });
    },
    closeAI() {
      this.$emit('close');
    },
    feedbackSuccess(content) {
      // 更新子消息反馈状态
      this.$refs.msgDialog.updateFeedback(content);
      this.feedbackDialogVisible = false;
    }
  }
};
</script>
