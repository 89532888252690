<template>
  <div class="yxtbiz-user-group-tree" ref="currentEle" :class="{'yxtbiz-dept-tree--autosize': autosize}" v-loading="loading">
    <yxtbiz-group-org-select
      v-if='enableGroupCorp && visibleOrgSelector'
      @change='handleOrgChange'
      :setCurrentOrg='true'
      :clearable="false"
      :selectFirst="true"
      class='mr12 mb12'
      :functionCode="functionCode"
      :dataPermissionCode="dataPermissionCode"
      style='width: 100%'
      />
    <yxt-input
      v-if="filterable"
      ref="input"
      class="yxtbiz-dept-tree__input"
      :placeholder="placeholder"
      v-model="filterText"
      :size="size"
      :style="{width: wrapWidth + 'px'}"
      @search="filter"
      searchable
    >
    </yxt-input>
    <yxtbiz-funs-tree
      ref="tree"
      :props="props"
      :highlight-current="highlightCurrent"
      :data="data"
      node-key="id"
      :wrap-width="wrapWidth"
      :check-strictly="checkStrictly"
      :show-checkbox="showCheckbox"
      :default-expanded-keys="expandTempShow"
      @node-click="nodeClick"
      :functions="functions"
      :functionsRender="functionsRender"
      @node-expand="nodeExpand"
      @node-collapse="nodeCollapse"
      :filter-node-method="filterNode"
      @check="handlerCheck"
      @check-change="handleCheckChange"
      :native="native"
      :dropdownToBody="dropdownToBody"
    ></yxtbiz-funs-tree>
  </div>
</template>

<script>
  import { getUserGroupCatalogsTree } from './service';
  import treeMixin from 'yxt-biz-pc/src/mixins/tree';
  import { i18n } from '../../common-util/changeLangs';

  export default {
    name: 'YxtbizUserGroupTree',
    mixins: [treeMixin],
    props: {
      showCheckbox: {
        default: false,
        type: Boolean
      },
      filterable: {
        default: false,
        type: Boolean
      },
      placeholder: {
        type: String,
        default: function() {
          return i18n.t('biz_udp_usergroup_catalogname');
        }
      },
      functions: {
        default: false,
        type: Boolean
      },
      functionsRender: {
        type: Function,
        default: () => {
          return () => {
            return [];
          };
        }
      },
      defaultExpandedKeys: {
        type: Array,
        default: () => {
          return [''];
        }
      },
      leafFilterLevel: {
        type: Number
      },
      targetOrgId: {
        type: Number | String
      },
      enableGroupCorp: {
        type: Boolean,
        default: false
      },
      visibleOrgSelector: {
        type: Boolean,
        default: false
      },
      functionCode: {
        type: String,
        default: ''
      },
      dataPermissionCode: {
        type: String,
        default: ''
      }
    },
    watch: {
      targetOrgId(value) {
        this.currentOrgId = value;
        this.dataInit();
      }
    },
    data() {
      return {
        currentOrgId: ''
      };
    },
    created() {
      this.currentOrgId = this.targetOrgId;
      this.props = {...this.props, ...this.treeProps};
    },
    methods: {
      handleOrgChange(data) {
        this.currentOrgId = data.orgId;
        this.filterText = '';
        this.dataInit();
        this.$emit('org-change', data);
      },
      dataInit() {
        getUserGroupCatalogsTree({ targetOrgId: this.currentOrgId, parentId: '', navCode: this.functionCode, dataPermissionCode: this.dataPermissionCode }, this.enableGroupCorp)
          .then(res => {
            let root = { name: this.$t('biz_udp_group_lbl_catalogrootname'), id: '', children: res.datas, nocheck: true };
            this.originData = [root];
            if (this.originData) {
              // this.data = this.originData;
              this.data = this.leafFilterLevel ? this.getLeafFilterLevelData(this.originData) : this.originData;
              this.$nextTick(() => {
                this.expandTempShow = [...this.expandTemp];
                if (this.$refs.tree) {
                  if (this.setRootDefault) {
                    this.$refs.tree.setCurrentKey('');
                    // this.nodeClick(this.originData[0], this.$refs.tree.getNode(''), this, true);
                  }
                  if (this.showCheckbox) {
                    this.setDisabledList();
                  }
                }
                this.$emit('inited');
              });
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      },
      getLeafFilterLevelData(datas) {
        if (this.leafFilterLevel === 1) {
          datas[0].children.forEach(v => {
            v.children = [];
          });
          return datas;
        } else {
          return datas;
        }
      }
    }
  };
</script>
