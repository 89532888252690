var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.navs && _vm.navs.length > 0
    ? _c(
        "div",
        {
          staticClass: "yxtbiz-nav-tab",
          class: { "yxtbiz-nav-tab--app": _vm.type === 2 },
        },
        [
          _vm.type === 1
            ? _c(
                "yxt-tabs",
                {
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                _vm._l(_vm.navs, function (menu) {
                  return _c("yxt-tab-pane", {
                    key: menu.id,
                    attrs: {
                      "show-tooltip": "",
                      data: menu,
                      name: menu.code,
                      label: _vm._f("Globalize")(menu, "name"),
                    },
                  })
                }),
                1
              )
            : _vm.type === 2
            ? _c(
                "yxt-tabs",
                {
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                _vm._l(_vm.navs, function (menu) {
                  return _c("yxt-tab-pane", {
                    key: menu.id,
                    attrs: {
                      "show-tooltip": "",
                      data: menu,
                      name: menu.code,
                      label: _vm._f("Globalize")(menu, "name"),
                    },
                  })
                }),
                1
              )
            : _vm._e(),
          _vm._visibleOrgSelector
            ? _c("yxtbiz-group-org-select", {
                staticClass: "mr24 yxtbiz-nav-tab--org_select",
                style: { width: _vm.selectorWidth },
                attrs: {
                  selectFirst: _vm.selectFirst,
                  clearable: _vm.clearable,
                  placeholder: _vm.placeholder,
                  setCurrentOrg: _vm.setCurrentOrg,
                  functionCode: _vm.functionCode,
                  dataPermissionCode: _vm.dataPermissionCode,
                },
                on: { change: _vm.change },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }