var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "yxtbiz-ai-robot__biz-answer" }, [
    _c(
      "div",
      {
        staticClass:
          "is-content is-answer-content yxtbiz-ai-robot__biz-answer--content",
      },
      [
        _vm.incorrectText
          ? _c("div", { staticClass: "ph12 mb12" }, [
              _vm._v(_vm._s(_vm.incorrectText)),
            ])
          : _vm._e(),
        _c(
          "div",
          { ref: "content", staticClass: "answer-content" },
          [_vm._t("default")],
          2
        ),
      ]
    ),
    _vm.msg.answerStatus === 1 && !_vm.msg.isFunction && !_vm.isWaitAnswer
      ? _c("div", { staticClass: "answer-footer" }, [
          _c("span", [
            _vm.lastMsg
              ? _c(
                  "span",
                  { staticClass: "re-answer", on: { click: _vm.toolClick } },
                  [
                    _c("yxtf-svg", {
                      staticClass: "icon-copy",
                      attrs: {
                        width: "20px",
                        height: "20px",
                        "icon-class": "turn-right",
                      },
                    }),
                    _c("span", [
                      _vm._v(
                        " " + _vm._s(_vm.$t("pc_biz_ai_reanswer").d("重新回答"))
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }