<template>
    <div class="hover-btn-tools">
        <yxtf-tooltip class="item" :content="$t('pc_biz_ai_copy').d('复制')" placement="top">
            <span @click="$emit('toolClick', 'copy')">
              <yxt-svg
                    :remote-url='svgUrl'
                    :width="20"
                    :height="20"
                    icon-class="copy"
                  />
            </span>
        </yxtf-tooltip>
        <template v-if="!isanswer">
            <yxtf-tooltip class="item" :content="$t('pc_biz_ai_search').d('搜一搜')" placement="top" >
                <span @click="$emit('toolClick', 'search')">
                  <yxt-svg
                    :remote-url='svgUrl'
                    :width="20"
                    :height="20"
                    icon-class="search"
                  />
                </span>
            </yxtf-tooltip>
        </template>
    </div>
</template>
<script>
import { getStaticBaseUrl } from '../service';
export default {
  props: {
    isanswer: {
      type: Boolean,
      default: false
    },
    like: Number,
    msg: Object
  },
  data() {
    return {
      svgUrl: `${getStaticBaseUrl()}ufd/407a24/assistent/pc/svg/`
    };
  }
};
</script>