var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.isISV
      ? _c("div", [
          _vm.showChangeProduct && !_vm.isStu
            ? _c(
                "div",
                { staticClass: "nav-production-enter" },
                [
                  _c(
                    "yxt-dropdown",
                    {
                      attrs: { trigger: "hover" },
                      on: { command: _vm.commandChange },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "color-gray-9",
                          class: [{ tiny: _vm.tiny, "is-stu": _vm.isStu }],
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm
                                .$t(_vm.currentItem.langkey)
                                .d(_vm.currentItem.name)
                            )
                          ),
                        ]
                      ),
                      _c(
                        "yxt-dropdown-menu",
                        {
                          attrs: {
                            slot: "dropdown",
                            "max-height": "300",
                            "max-width": "300",
                          },
                          slot: "dropdown",
                        },
                        _vm._l(_vm.productionList, function (item, index) {
                          return _c(
                            "yxt-dropdown-item",
                            {
                              key: index,
                              class: [
                                _vm.currentItem.code === item.code
                                  ? "color-primary-6-i"
                                  : "",
                              ],
                              attrs: { command: item },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t(item.langkey).d(item.name))
                                ),
                              ]),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ])
      : _vm._e(),
    (_vm.isISV && (_vm.xuanxingIsShow || _vm.isHA)) ||
    _vm.onlyOneXuanxingUniversity
      ? _c(
          "div",
          {
            staticClass:
              "yxtbiz-nav-top-stu__my-panel_schenter hand color-gray-9",
            on: { click: _vm.openxxsch },
          },
          [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("pc_biz_xxschool").d("绚星学堂")) +
                "\n  "
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }