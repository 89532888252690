export const useNamespace = (block) => {
  block = ['yxtbiz-ai-robot', block].join('-');
  const b = () => {
    return `${block}`;
  };
  const e = (element) => {
    return `${block}__${element}`;
  };

  const em = (element, modify) => {
    return `${block}__${element} ${block}__${element}--${modify}`;
  };
  const bm = (modify) => {
    return `${block}--${modify}`;
  };
  const bem = (block, element, modify) => {
    return `${block}__${element}--${modify}`;
  };
  const is = (status, flag) => {
    return flag ? `is-${status}` : '';
  };
  return {
    root: b,
    b,
    e,
    m: em,
    em,
    bm,
    bem,
    is
  };
};
