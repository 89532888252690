<template>
  <div class="yxtbiz-ai-robot-cer-list standard-size-14">
    <header class="mb12 ph12 color-gray-7" :class="{'standard-size-16': size === 'large'}">{{ $t('pc_biz_cer_lbl_search_cer_for_you').d('为您找到以下证书：') }}</header>
    <!-- 证书列表 -->
    <div class="yxtbiz-ai-robot-cer-list-cell" v-for="item in datas" :key="item.cerIssueId" @click="toDetail(item)">
      <yxt-image class="yxtbiz-ai-robot-cer-list-cover" width="68px" height="68px" :src="item.userCerUrl || `${$staticBaseUrl}ufd/55a3e0/cer/img/ai-cer-empty.png`" fit="cover" />
      <div class="ml12 flex-1 flex yxtbiz-ai-robot-cer-list-content">
        <div class="ellipsis-2" :class="{'standard-size-16': size === 'large'}">{{ item.cerName }}</div>
        <div v-if="size === 'small'" class="mt4 standard-size-12 color-gray-7">
            <div>{{ $t('pc_biz_cer_lbl_cer_id').d('证书编号') }}：{{ item.no }}</div>
            <div class="mt2">{{ $t('pc_biz_cer_lbl_time').d('时间') }}：{{ item.expiredTime ? `${item.issueTime}~${item.expiredTime}` : $t('pc_biz_cer_lbl_no_expired_time').d('永久有效')}}</div>
        </div>
        <div v-else class="mt8 standard-size-12 color-gray-7 flex yxtbiz-ai-robot-cer-list-content-footer">
            <div>{{ $t('pc_biz_cer_lbl_cer_id').d('证书编号') }}：{{ item.no }}</div><yxt-divider direction="vertical"/><div>{{ $t('pc_biz_cer_lbl_time').d('时间') }}：{{ item.expiredTime ? `${item.issueTime}~${item.expiredTime}` : $t('pc_biz_cer_lbl_no_expired_time').d('永久有效')}}</div>
        </div>
      </div>
    </div>
    <div class="yxtbiz-ai-robot__kng-more color-gray-8" @click="toMore">
      {{ $t('pc_biz_ote_lbl_viewmore').d('查看更多') }}
      <yxt-svg :remote-url="`${$staticBaseUrl}ufd/55a3e0`" width="16px" height="16px" icon-class="arrow_right" />
    </div>
  </div>
</template>

<script>
import CommonMixin from '../common/props-mixin';

export default {
  name: 'CerList',
  mixins: [CommonMixin],
  methods: {
    toDetail(item) {
      const { cerIssueId } = item;
      window.open(`/cer/#/mycer/preview?id=${cerIssueId}&designMode=0`);
    },
    toMore() {
      window.open('/cer/#/mycer'); // 我的证书
    }
  }
};
</script>
<style lang="scss" scoped>
.yxtbiz-ai-robot-cer-list {
  border-radius: 0 12px 12px 12px;

  .lh28 {
    line-height: 28px;
  }

  .yxtbiz-ai-robot-cer-list-cover{
    width: 68px;
    height: 68px;
    border-radius: 4px;
  }

  .yxtbiz-ai-robot-cer-list-cell {
    padding: 12px 12px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;

    &:hover {
      background-color: #f4f5f6;
    }

    .lh20 {
      line-height: 20px;
    }
  }
  .yxtbiz-ai-robot-cer-list-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    &-footer{
      display: flex;
      align-items: center;
    }
  }
}
</style>