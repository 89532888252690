<template>
  <div v-if="isShowDrawer" class="yxtbiz-i18n-lang">
    <yxt-drawer
      :title="getTranslate('pc_biz_core_tip_i18n')"
      :visible.sync="visible"
      :before-close="beforeClose"
      :class="drawerClass"
      v-bind="$attrs"
      v-loading="loading"
      :size="$attrs.size || '640px'"
    >
      <div v-for="item in listData" :key="item.id" class="yxtbiz-i18n-lang__border-bottom">
        <!-- 文本 -->
        <div v-if="item.type != 'image'">
          <h3 class="yxtbiz-i18n-lang__drawer-title">{{ item.name }}</h3>
          <yxt-form :model="item.ruleForm" :rules="item.rules" :ref="'ruleForm' + item.id" label-width="100px" class="yxtbiz-i18n-lang__drawer-form">
            <yxt-form-item v-for="lang in langList" :key="item.id" :label="lang.langName" :prop="lang.langTag">
              <yxt-input v-if="item.type != 'richeditor'" v-model="item.ruleForm[lang.langTag]" :type="item.type" :placeholder="getTranslate('pc_biz_core_tip_placeholder')" :show-word-limit="(item.rule && item.rule.showWordLimit)" :maxlength="(item.rule && item.rule.maxlength && item.rule.maxlength[lang.langTag]) || '2000'" v-bind="item.rule"></yxt-input>
              <!-- 富文本 -->
              <yxtbiz-richeditor v-if="item.type == 'richeditor'" :ref="'richeditor' + lang.langTag + item.id" function-name="richeditor" org-code="xxv2" app-code="core" module-name="msg" v-bind="item.rule" :content="item.ruleForm[lang.langTag]" @change="(ctx) => changeCtx(item, lang.langTag, ctx)" class="richeditor-class"></yxtbiz-richeditor>
            </yxt-form-item>
          </yxt-form>
        </div>
        <!-- 图片 -->
        <div v-if="item.type == 'image'">
          <h3 class="yxtbiz-i18n-lang__drawer-title">
            <h3 class="yxtbiz-i18n-lang__drawer-title">{{ item.name }}</h3>
            <span v-if="(item.rule && item.rule.suggest && item.rule.suggest.w && item.rule.suggest.h) || item.rule.filters || item.rule.size">（
              <span v-if="item.rule && item.rule.suggest && item.rule.suggest.w && item.rule.suggest.h">{{ getTranslate('pc_biz_core_tip_size') }}：{{ item.rule.suggest && item.rule.suggest.w || '750' }}*{{ item.rule.suggest ** item.rule.suggest.h || '348' }}px；</span>
              <span v-if="item.rule && item.rule.filters">{{ getTranslate('pc_biz_imgcropper_lbl_filter') }}：{{ item.rule.filters || '.jpg,.gif,.png,.jpeg,.bmp,.svg' }}；</span>
              <span v-if="item.rule && item.rule.size">{{ getTranslate('pc_biz_core_lbl_sizesnotexceed') }}{{ item.rule.size || '512' }}kb</span>）
            </span>
          </h3>
          <yxt-form :model="item.ruleForm" :rules="imgRules" :ref="'ruleForm' + idx" label-width="100px" class="yxtbiz-i18n-lang__drawer-form yxtbiz-i18n-lang__drawer-form-img">
            <yxt-form-item v-for="lang in langList" :key="lang.langTag" :label="lang.langName" :prop="lang.langTag">
              <yxtbiz-upload-image
                v-bind="item.rule"
                :list="item.ruleForm[lang.langTag] ? [{url: item.ruleForm[lang.langTag] || ''}] : []"
                @fileAdded="({index, url, file}) => fileAdded({index, url, file}, item.langKey, lang.langTag)"
                @fileRemoved="() => fileRemoved(item.langKey, lang.langTag)"
                @update="update"
                @uploadStart="onUploadStart"
                @uploadComplete="onUploadComplete"
              >
              </yxtbiz-upload-image>
            </yxt-form-item>
          </yxt-form>
        </div>
      </div>
      <div slot="footer">
        <yxt-button plain @click="handleClose">{{ getTranslate('pc_biz_ote_btn_cancle') }}</yxt-button>
        <yxt-button type='primary' @click="complete">{{ getTranslate('pc_biz_core_btn_confirm') }}</yxt-button>
      </div>
    </yxt-drawer>
  </div>
</template>

<script>
import { searchLangKey, saveLangKey, searchLangs, serchOrgLangs } from './service';
import { getLanguage } from 'yxt-i18n/es';

export default {
  name: 'YxtbizI18nLang',
  props: {
    drawerClass: String,
    visible: {
      type: Boolean,
      default: false
    },
    orgId: {
      type: String,
      default: window.localStorage.getItem('orgId')
    },
    groupCode: {
      type: String,
      default: ''
    },
    isSave: {
      type: Boolean,
      default: true
    },
    dataList: {
      type: Object,
      default: () => {}
    },
    module: {
      type: String, // xuanxing(主站) op(boss平台)
      default: 'xuanxing'
    }
  },
  data() {
    return {
      text: '',
      langList: [],
      imgRules: {
        zh: [
          { required: true, message: this.getTranslate('pc_biz_imgcropper_tip_upload'), trigger: 'change' }
        ]
      },
      listData: {},
      hasData: null,
      isShowDrawer: false,
      loading: false
    };
  },
  computed: {
    locale() {
      return getLanguage();
    }
  },
  created() {
    this.initData();
  },
  methods: {
    initData() { // 初始化数据判断
      let oldArr = [];
      Array.isArray(this.dataList) ? oldArr = this.deepClone(this.dataList) : oldArr.push(this.deepClone(this.dataList));
      let keys = oldArr.map(item => item.langKey).filter(item => item) || [];
      // 对传入数据进行校验
      if (!keys.length) {
        this.$emit('update:visible', false);
        throw new Error('请填入必填字段langKeys');
      }
      // 判断传入的key的数量进行校验
      if (keys.length !== oldArr.length && keys.length) {
        this.$emit('update:visible', false);
        throw new Error('请检查传入的数据格式');
      }
      this.searchKey(oldArr);
    },
    getDataInit(data) { // 初始化数据结构
      data.forEach((item, idx) => {
        let trans = [];
        item.id = idx;
        item.ruleForm = {};
        if (item.type !== 'image') {
          item.rules = {};
        }
        if (typeof item.trans !== 'object') {
          trans = [
            {
              langTag: this.locale,
              transContent: item.trans
            }
          ];
        } else {
          Array.isArray(item.trans) ? trans = item.trans : trans.push(item.trans);
        }
        item.trans = trans;
      });
      return data;
    },
    isRequired(lang) {
      if (this.module === 'xuanxing') return true;
      return ['zh', 'ha', 'en'].includes(lang.langTag);
    },
    async getLangList(data) { // 获取语言列表处理，第二次处理数据,根据语种列表增加form表单的rules
      let lists = await this.deepClone(await this.getDataInit(data));
      let ajax = this.module === 'xuanxing' ? serchOrgLangs(this.orgId) : searchLangs();
      await ajax.then((res) => {
        this.langList = res.lang4Get || res.datas;
        lists.forEach((item) => {
          let langObj = {};
          let maxlength = item.rule && item.rule.maxlength || 2000;
          this.langList.forEach((lang, index) => {
            let transContent = item.trans.find(tran => tran.langTag === lang.langTag);
            item.ruleForm[lang.langTag] = (transContent && transContent.transContent) || '';
            if (item.type !== 'image') {
              let rules = null;
              let textRules = [ // 文本校验
                { required: this.isRequired(lang), validator: this.isRequired(lang) ? this.checkEmpty : null, message: `${this.getTranslate('pc_biz_core_tip_placeholder')}${this.module === 'xuanxing' ? lang.langName : lang.description}`, trigger: 'blur' }
              ];
              let richRules = [ // 富文本校验
                { required: this.isRequired(lang), validator: this.isRequired(lang) ? (rule, value, callback) => this.checkRich(rule, value, callback, item) : null, message: `${this.getTranslate('pc_biz_core_tip_placeholder')}${this.module === 'xuanxing' ? lang.langName : lang.description}`, trigger: 'blur' }
              ];
              if (item.type === 'richeditor') {
                rules = richRules;
              } else {
                if (typeof maxlength !== 'object') {
                  langObj[lang.langTag] = maxlength;
                  if (item.rule) {
                    item.rule.maxlength = langObj;
                  } else {
                    item.rule = {
                      maxlength: langObj
                    };
                  }
                }
                rules = textRules;
              }
              // 对必填的处理
              if (Array.isArray(item.isNoMustLangs)) {
                let isNoMust = item.isNoMustLangs.find(isNo => isNo === lang.langTag);
                if (!isNoMust) {
                  if (item.type === 'text') {
                    let lengthRule = [{ max: item.rule.maxlength[lang.langTag], message: this.getTranslate('pc_biz_nav_msg_lengthlimit', item.rule.maxlength[lang.langTag]), trigger: 'blur' }];
                    let rulesArr = rules.concat(lengthRule);
                    item.rules[lang.langTag] = rulesArr;
                  } else {
                    item.rules[lang.langTag] = rules;
                  }
                } else {
                  let noMustRules = [
                    { required: false },
                    { max: item.rule.maxlength[lang.langTag], message: this.getTranslate('pc_biz_nav_msg_lengthlimit', item.rule.maxlength[lang.langTag]), trigger: 'blur' }
                  ];
                  if (item.type === 'text') {
                    item.rules[lang.langTag] = noMustRules;
                  }
                }
              } else {
                if (item.type === 'text') {
                  let lengthRule = [{ max: item.rule.maxlength[lang.langTag], message: this.getTranslate('pc_biz_nav_msg_lengthlimit', item.rule.maxlength[lang.langTag]), trigger: 'blur' }];
                  let rulesArr = rules.concat(lengthRule);
                  item.rules[lang.langTag] = rulesArr;
                } else {
                  item.rules[lang.langTag] = rules;
                }
              }
            }
          });
        });
      }).catch(err => {
        this.$emit('update:visible', false);
        this.$message.error(err.error ? err.error.message : err);
      });
      return lists;
    },
    async searchKey(data) { // 通过key查询，第三次处理数据,赋值给初始化的值
      this.listData = await this.getLangList(data);
      let keys = this.listData.map(item => item.langKey).filter(item => item) || [];
      await searchLangKey(this.module, keys).then(res => {
        this.isShowDrawer = true;
        this.listData.forEach(list => {
          res.keys.forEach(item => {
            if (list.langKey === item.langKey) {
              if (item.trans) {
                for (let trans in item.trans) {
                  if (trans !== this.locale) {
                    list.trans.push(trans);
                  }
                  if (!list.ruleForm[trans]) list.ruleForm[trans] = item.trans[trans];
                }
              }
            }
          });
        });
      }).catch((err) => {
        this.$emit('update:visible', false);
        this.$message.error(err.error ? err.error.message : err.message);
      });
      this.hasData = this.useDataChange(this.listData);
    },
    deepClone(target) { // 深复制
      if (!target || typeof target !== 'object') {
        return target;
      }
      const resObj = Array.isArray(target) ? [] : {};
      for (const k in target) {
        resObj[k] = this.deepClone(target[k]);
      }
      return resObj;
    },
    getTranslate(key, maxlength) { // 多语言的兜底
      const transArr = [
        {
          name: '多语言维护',
          langKey: 'pc_biz_core_tip_i18n'
        },
        {
          name: '请输入',
          langKey: 'pc_biz_core_tip_placeholder'
        },
        {
          name: '尺寸',
          langKey: 'pc_biz_core_tip_size'
        },
        {
          name: '大小不超过',
          langKey: 'pc_biz_core_lbl_sizesnotexceed'
        },
        {
          name: '取消',
          langKey: 'pc_biz_ote_btn_cancle'
        },
        {
          name: '确定',
          langKey: 'pc_biz_core_btn_confirm'
        },
        {
          name: '请上传图片',
          langKey: 'pc_biz_imgcropper_tip_upload'
        },
        {
          name: '维护成功',
          langKey: 'pc_biz_core_tip_success'
        },
        {
          name: '当前操作尚未保存，是否确认取消？',
          langKey: 'pc_biz_core_msg_confirmcancletip'
        },
        {
          name: '确认取消？',
          langKey: 'pc_biz_core_msg_cancel'
        },
        {
          name: '长度过长',
          langKey: 'pc_biz_nav_msg_lengthlimit'
        }
      ];
      // 区分使用平台
      if (this.module === 'xuanxing') {
        if (maxlength) {
          return this.$t(key, {0: maxlength});
        } else {
          return this.$t(key);
        }
      } else {
        let obj = transArr.find(item => item.langKey === key);
        return obj ? obj.name : key;
      }
    },
    checkEmpty(rule, value, callback) { // 校验文本为空
      value = value.trim();
      if (value && value.length) {
        callback();
      } else {
        return callback(new Error());
      }
    },
    checkRich(rule, value, callback, item) { // 校验富文本为空
      if (this.$refs['richeditor' + rule.field + item.id][0].getText()) {
        callback();
      } else {
        return callback(new Error());
      }
    },
    async complete() { // 保存
      let keys = this.listData.map(item => item.langKey).filter(item => item) || [];
      let isSuccess = true;
      for (let refs in this.$refs) {
        if (refs.indexOf('ruleForm') > -1) {
          this.$refs[refs][0].validate((valid) => {
            if (!valid) isSuccess = false;
          });
        }
      }
      if (isSuccess) {
        let data = this.deepClone(this.listData);
        data.forEach(item => {
          item.trans = [];
          for (let key in item.ruleForm) {
            item.trans.push({
              'langTag': key,
              'transContent': item.ruleForm[key]
            });
          }
        });
        let finData = {
          orgId: this.orgId,
          keys: []
        };
        finData.keys = data.map(item => {
          return {
            langKey: item.langKey,
            trans: item.trans,
            groupCode: this.groupCode
          };
        });
        if (this.isSave && keys.length) {
          this.loading = true;
          await saveLangKey(this.module, finData).then(res => {
            this.loading = false;
            this.$message.success(this.getTranslate('pc_biz_core_tip_success'));
          }).catch(err => {
            this.loading = false;
            this.$message.error(err.error ? err.error.message : err.message);
          });
        }
        this.$emit('update:visible', false);
        this.isShowDrawer = false;
        this.$emit('confirm', finData);
      }
    },
    fileAdded({ url }, langKey, langTag) { // 上传图片
      this.listData.find(item => item.langKey === langKey).ruleForm[langTag] = url;
    },
    fileRemoved(langKey, langTag) { // 移除图片
      this.listData.find(item => item.langKey === langKey).ruleForm[langTag] = '';
    },
    useDataChange(form) { // 判断数据是否变化
      const old = JSON.stringify(form);
      return target => {
        return old !== JSON.stringify(target);
      };
    },
    beforeCloseMixin(isState, done) { // drawer关闭前和取消时的判断
      if (isState) {
        this.$confirm(this.getTranslate('pc_biz_core_msg_confirmcancletip'), this.getTranslate('pc_biz_core_msg_cancel'), {
          confirmButtonText: this.getTranslate('pc_biz_core_btn_confirm'),
          cancelButtonText: this.getTranslate('pc_biz_ote_btn_cancle'),
          type: 'warning'
        }).then(() => {
          done();
        }).catch(() => {});
      } else {
        done();
      }
    },
    beforeClose() { // 关闭之前的处理
      let state = this.hasData(this.listData);
      this.beforeCloseMixin(state, () => {
        this.$emit('update:visible', false);
      });
    },
    handleClose() { // 关闭drawer
      let state = this.hasData(this.listData);
      this.beforeCloseMixin(state, () => {
        this.isShowDrawer = false;
        this.$emit('update:visible', false);
      });
    },
    changeCtx(item, langTag, value) {
      item.ruleForm[langTag] = value;
    }
  }
};
</script>

