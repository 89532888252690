<template>
  <yxtbiz-import-proc ref="importProc" app-code="udp" :template-url="templateUrl" @ready="ready" @reset="resetData" @uploaded="uploaded" @onProcess="onProcess">
    <div slot="uploaded" class="yxt-upload-container">
      <yxt-row type="flex" align="middle" class="color-gray-10 yxt-upload-item-title font-size-12">
        {{ file.name }}
        <i class="yxt-icon-close hand standard-size-12 color-gray-8 hover-primary-6 ml16" @click="reset" />
      </yxt-row>
      <yxt-row class="mt24">
        <yxt-button type="primary" @click="handleImport">{{$t('pc_biz_udp_import_btn_confirmimport',/* 确定导入 */)}}</yxt-button>
      </yxt-row>
    </div>
    <div v-if="importResult && importResult.isSuccess" slot="success">
      <yxt-row type="flex" align="middle" class="color-gray-10 yxt-upload-item-title">
        <span class="yxt-color-success">{{ importResult.successCount }}</span>
        {{$t('pc_biz_udp_import_tip_successcount',/* 条导入成功 */)}}
        <template v-if="importResult.failedCount">
          {{$t('pc_biz_udp_tip_comma',/* ， */)}}<span class="yxt-color-danger">{{ importResult.failedCount }}</span>
          {{$t('pc_biz_udp_import_tip_failedcount',/* 条导入失败 */)}}
        </template>
        <template v-if="importResult.repeatCount">
          {{$t('pc_biz_udp_tip_comma',/* ， */)}}<span class="yxt-color-danger">{{ importResult.repeatCount }}</span>
          {{$t('pc_biz_udp_import_tip_repeatcount',/* 条重复数据 */)}}
        </template>
      </yxt-row>
      <yxt-row type="flex" align="middle" class="standard-size-12 lh20 mt4">
        <yxt-link :underline="false" type="success">
          <i class="yxt-icon-success" />
        </yxt-link>
        <span class="color-gray-8 ml3">{{$t('pc_biz_import_tip_importsuccess',/* 导入成功 */)}}</span>
        <yxt-link class="standard-size-12 ml8" type="primary" :underline="false" @click="downloadError(importResult.filePath)" v-if="importResult.filePath">
          {{$t('pc_biz_udp_import_btn_downloadfaileddata',/* 下载失败数据 */)}}
        </yxt-link>
      </yxt-row>
    </div>
  </yxtbiz-import-proc>
</template>
<script>
import yxtbizImportProc from 'yxt-biz-pc/packages/import-proc';
import { importUser } from '../service';
export default {
  components: {
    yxtbizImportProc
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    functionCode: {
      type: String,
      default: ''
    },
    dataPermissionCode: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'persons'
    }
  },

  data() {
    this.importKeyMsg = {
      'apis.udp.user.import.validation.template.invalid': this.$t('pc_biz_udp_import_msg_templateerror'/* 非正常数据模板文件，请重新选择文件！ */),
      'apis.udp.import.search.size.limited': this.$t('pc_biz_udp_import_msg_maxsize'/* 单个文件，每次最多只可导入{0}条数据！ */, { 0: '5000' }),
      'apis.udp.import.nodata': this.$t('pc_biz_udp_import_msg_accountempty'/* 文件账号列为空，请重新选择文件！ */)
    };
    this.clicked = false;
    this.timeStamp = '';
    return {
      selectedPersons: {},
      templateUrl: `${this.$staticBaseUrl}ufd/2338a3/udp/pc/other/%E5%AF%BC%E5%85%A5%E7%94%A8%E6%88%B7%E6%A8%A1%E6%9D%BF.xls`, // 导入用户模板.xls i18n-key: pc_biz_udp_import_file
      file: {},
      importer: null,
      importResult: null,
      processing: false
    };
  },
  computed: {
  },
  created() {
    // 选中数组转化为对象，方便查找
    this.formatUsers(this.value);
  },
  watch: {
    value(v) {
      this.formatUsers(v);
    }
  },
  methods: {
    formatUsers(users) {
      this.selectedPersons = {};
      users.forEach(user => {
        this.selectedPersons[user.id] = user;
      });
    },
    ready(data) {
      this.importer = data;
    },
    resetData() {
      this.file = {};
      this.importResult = null;
      this.timeStamp = new Date().valueOf();
    },
    reset() {
      this.resetData();
      this.importer.reset();
    },
    throttle(method) {
      clearTimeout(method.tId);
      method.tId = setTimeout(() => {
        method();
      }, 1000);
    },
    // 下载异常说明
    downloadError(url) {
      if (this.clicked) {
        this.$message.warning(this.$t('pc_biz_import_msg_downloading'/* 正在下载，请稍后 */));
      } else {
        window.location.href = url;
      }
      this.clicked = true;
      this.throttle(() => {
        this.clicked = false;
      });
    },
    async handleImport() {
      let timeStap = this.timeStamp;
      this.importer.verifying();
      let resultData = await importUser({
        fileId: this.file.id,
        navCode: this.functionCode,
        dataPermissionCode: this.dataPermissionCode
      }).catch((error)=>{
        return error;
      });
      // 非当前流程的校验跳出
      if (timeStap !== this.timeStamp) return;
      // 处理API返回结果
      resultData = this.getImportResult(resultData);
      // 导入完成后需要默认显示的内容（失败用默认样式，成功用插槽自定义样式）
      let completeData = null;
      if (resultData.isSuccess) {
        // 成功处理
        this.importResult = resultData;
        this.updateUsers(resultData.datas);
      } else {
        // 失败处理
        completeData = {
          isSuccess: false,
          title: resultData.title,
          message: resultData.message,
          linkUrl: resultData.filePath,
          linkMessage: this.$t('pc_biz_import_btn_downloaderrorfile'/* 下载失败数据 */)
        };
      }
      this.importer.completed(completeData);
    },
    uploaded(next, file) {
      this.file = file;
    },
    getImportResult(data) {
      let result = {
        isSuccess: false,
        title: this.$t('pc_biz_udp_import_msg_templateerror'/* 非正常数据模板文件，请重新选择文件！ */),
        message: this.$t('pc_biz_udp_import_tip_verifyfailed'/* 检查失败 */)
      };
      if (data) {
        if (data.error) {
          result.title = this.importKeyMsg[data.error.key] || result.title;
        } else {
          data.datas = data.datas || [];
          result = {
            isSuccess: true,
            ...data
          };
        }
      }
      return result;
    },
    // 选中人员
    updateUsers(items) {
      items.forEach((user)=>{
        this.selectedPersons[user.id] = user;
      });
      this.$emit('input', Object.values(this.selectedPersons));
      this.$emit('update', this.type, items, true);
    },
    // 导入流程阶段状态变化回调
    onProcess(processing) {
      this.processing = processing;
      this.$emit('onProcess', processing);
    }
  }
};
</script>
