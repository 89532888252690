var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yxt-user-selector-wrap" },
    [
      _c(
        "yxt-tabs",
        {
          attrs: {
            "drawer-top": _vm.drawerTop,
            "before-leave": _vm.tabBeforeLeave,
          },
          on: { "tab-click": (name) => _vm.$emit("tab-click", name) },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        _vm._l(_vm.setTabs, function (tab, index) {
          return _c(
            "yxt-tab-pane",
            { key: index, attrs: { name: tab.value } },
            [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _vm._v(_vm._s(tab.label)),
              ]),
              _c(
                "yxt-row",
                {
                  staticClass: "yxtbiz-user-selector-container",
                  attrs: { type: "flex" },
                },
                [
                  _vm.activeName === tab.value
                    ? [
                        _vm.$slots[tab.value]
                          ? _vm._t(tab.value)
                          : _c(tab.component, {
                              tag: "component",
                              attrs: {
                                targetOrgId: _vm.targetOrgId,
                                disabled: _vm.disabledOrgSelect,
                                isOrgSelectAlone: _vm.isOrgSelectAlone,
                                enableGroupCorp: _vm.enableGroupCorp,
                                disabledOrgIds: _vm.disabledOrgIds,
                                visibleOrgIds: _vm.visibleOrgIds,
                                functionCode: _vm.functionCode,
                                dataPermissionCode: _vm.dataPermissionCode,
                                scope: _vm.scope,
                                type: tab.model,
                                isShowDeptment: _vm.isShowDeptment,
                                relationTips: _vm.relationTips,
                                projectGroupId: _vm.projectGroupId,
                              },
                              on: {
                                update: _vm.update,
                                onProcess: _vm.onProcess,
                                "org-change": _vm.orgChange,
                              },
                              model: {
                                value: _vm.selectedProps[tab.model],
                                callback: function ($$v) {
                                  _vm.$set(_vm.selectedProps, tab.model, $$v)
                                },
                                expression: "selectedProps[tab.model]",
                              },
                            }),
                      ]
                    : _vm._e(),
                  _c(
                    "aside",
                    { staticClass: "yxt-user-selection" },
                    [
                      _c(
                        "yxt-scrollbar",
                        { attrs: { "fit-height": true } },
                        [
                          _c("check-list", {
                            attrs: { data: _vm.list },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return _vm._l(scope.list, function (item) {
                                      return _c(
                                        "yxt-tooltip",
                                        {
                                          key: item.id,
                                          attrs: {
                                            placement: "top",
                                            effect: "dark",
                                          },
                                        },
                                        [
                                          _c(
                                            "template",
                                            { slot: "content" },
                                            [
                                              _c("yxtbiz-user-name", {
                                                staticClass:
                                                  "yxtbiz-user-selector-tag",
                                                attrs: {
                                                  name: _vm.enableGroupCorp
                                                    ? item.combinationName
                                                    : item.fullname ||
                                                      item.name,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "yxt-tag",
                                            {
                                              staticClass: "mr5",
                                              class: {
                                                autoWidth: _vm.enableGroupCorp,
                                              },
                                              staticStyle: {
                                                "margin-bottom": "5px",
                                              },
                                              attrs: {
                                                size: "small",
                                                type: "info",
                                                disableTransitions: true,
                                                closable: "",
                                              },
                                              on: {
                                                close: function ($event) {
                                                  return _vm.deleteItem(item)
                                                },
                                              },
                                            },
                                            [
                                              _c("yxt-svg", {
                                                staticClass: "mr5 v-mid",
                                                attrs: {
                                                  width: "14px",
                                                  height: "14px",
                                                  "icon-class":
                                                    _vm.getTypeIcon(item),
                                                },
                                              }),
                                              !_vm.isOpenData ||
                                              item.nodeType === "userGroup"
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "yxtbiz-user-selector-tag",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("tag")(item)
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _c("yxtbiz-user-name", {
                                                    staticClass:
                                                      "yxtbiz-user-selector-tag",
                                                    attrs: {
                                                      name: _vm.enableGroupCorp
                                                        ? item.combinationName
                                                        : item.fullname,
                                                    },
                                                  }),
                                            ],
                                            1
                                          ),
                                        ],
                                        2
                                      )
                                    })
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "yxt-button",
                        {
                          staticClass:
                            "yxtbiz-user-clear font-size-12 color-gray-9 hover-primary-6",
                          attrs: {
                            type: "text",
                            disabled: _vm.list.length === 0,
                          },
                          on: { click: _vm.clear },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("biz_udp_clear_all")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }