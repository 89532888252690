<template>
    <div v-if="msg.answerStatus === 0" class="is-answer-loading w122">
        <div class="is-answer-loading-text">{{ $t('pc_biz_ai_thinking').d('正在思考') }}</div>
        <div class="answer-loading">
            <div></div>
        </div>
    </div>
    <div v-else-if="msg.answerStatus === 2" class="is-content origin-content">
      {{ content }}
      <template v-if="otherSourceAnswer">
          <div class="online-title color-gray-7 mt12">{{ $t('pc_biz_ass_lbl_onlineasktitle'/** 我们为您从互联网中找到相关解答： */) }}</div>
          <div class="mt4" v-html="otherSourceAnswer"></div> 
      </template>
        <!-- <yxt-button class="internet-btn" v-if="settings.publicResourceEnabled" type="text" @click="$emit('resend', 'askInternet', msg)">&nbsp;{{ $t('pc_biz_ai_question_tips').d('互联网问问') }}</yxt-button> -->
    </div>
    <div v-else-if="msg.answerStatus === 6" class="is-content">{{ content }}<yxt-button class="internet-btn" type="text" @click="goUrl('https://yxt.yunxuetang.cn/tlive/#/detail?id=1714835430057140226')">&nbsp;{{ '查看详情' }}</yxt-button></div>
    <div v-else class="is-content">
        {{ content || settings.assistWele || $t('pc_biz_ai_question_default_tips').d('你好，我是AI助手，很高兴为你服务！') }}
    </div>
</template>
<script>
import { markdownParse } from '../../utils';
export default {
  name: 'simpleAnswer',
  props: {
    fullscreen: {
      type: Boolean,
      default: false
    },
    msg: { // 回答的消息体
      type: Object,
      default: () => {}
    }
  },
  inject: ['settings'],
  data() {
    return {
      asked: false
    };
  },
  watch: {
    msg: {
      deep: true,
      immediate: true,
      handler(val) {
        if (this.msg.answerStatus === 2 && this.settings.publicResourceEnabled && !this.msg.isHistory && !this.asked) {
          this.asked = true;
          setTimeout(() => this.$root.$emit('ASK_INTERNET', this.msg));
        }
      }
    }
  },
  computed: {
    content() {
      const content = this.msg.contentDetail ? this.msg.contentDetail.content : '';
      return content;
    },
    otherSourceAnswer() {
      const content = this.msg.contentDetail ? this.msg.contentDetail.otherSourceAnswer : '';
      return markdownParse(content);
    }
  },
  methods: {
    goUrl(url) {
      window.open(url, '_blank');
    }
  }
};
</script>