<template>
  <div class="yxtbiz-user-center-nav">
    <div class="user-block">
      <yxtf-portrait
        size="80px"
        class="mb12"
        :username="fullname"
        style="display: block"
        :imgUrl="imgUrl"
      ></yxtf-portrait>
      <yxtf-tooltip effect="ellipsis" placement="top">
        <div slot="content"><yxtbiz-user-name :name="fullname" /></div>
        <div class="standard-size-18 mb12 yxtf-weight-5 fullname">
          <yxtbiz-user-name :name="fullname" />
        </div>
      </yxtf-tooltip>
      <yxtf-tooltip effect="ellipsis" placement="top">
        <div slot="content">
          <yxtbiz-dept-name :name="deptName" />
          <span v-if="deptName && positionName"> | </span>{{ positionName }}
        </div>
        <div class="color-gray-8 mb24 department">
          <yxtbiz-dept-name :name="deptName" />
          <span v-if="deptName && positionName">&nbsp;|&nbsp;</span>
          <span>{{ positionName }}</span>
        </div>
      </yxtf-tooltip>
      <div v-if="!hiddenUserLink" class="text-center">
        <yxtf-button
          type="primary"
          size="small"
          style="height:36px; font-size: 14px;"
          @click="go('/#/userindex')"
          >{{ $t('biz_core_homepage_title') /** 个人主页 */ }}</yxtf-button
        >
        <yxtf-button
          v-if="!hiddenMyArchive"
          type="primary"
          size="small"
          style="height:36px; font-size: 14px; margin-left: 8px;"
          @click="go(`/archive/#/archivedetail?id=${userId}`)"
          >{{ $t('biz_core_homepage_archive') /** 我的档案 */ }}</yxtf-button
        >
      </div>
    </div>
    <ul class="nav-list">
      <li
        v-for="item in navListFilter(navList1)"
        :key="item.pageCode"
        class="item mb20"
        :class="{ active: pageCode === item.pageCode }"
        @click="go(item.url)"
      >
        <span class="nav-name standard-size-16">{{ $t(item.name) }}</span>
      </li>
      <div class="nav-divide-line"></div>
      <li
        v-for="item in navListFilter(navList2)"
        :key="item.pageCode"
        class="item"
        :class="{ active: pageCode === item.pageCode }"
        @click="go(item.url)"
      >
        <span class="nav-name standard-size-16">{{ $t(item.name) }}</span>
      </li>
      <div class="nav-divide-line"></div>
      <li
        v-for="item in navListFilter(navList3)"
        :key="item.pageCode"
        class="item"
        :class="{ active: pageCode === item.pageCode }"
        @click="go(item.url)"
      >
        <span class="nav-name standard-size-16">{{ $t(item.name) }}</span>
      </li>
      <div class="nav-divide-line"></div>
      <li
        v-for="item in navListFilter(navList4)"
        :key="item.pageCode"
        class="item"
        :class="{ active: pageCode === item.pageCode }"
        @click="go(item.url)"
      >
        <span class="nav-name standard-size-16">{{ $t(item.name) }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { FrontPortrait, FrontTooltip, FrontButton } from 'yxt-pc';
import {
  common,
  enmuFunctions,
  preCheckFunctions
} from 'yxt-biz-pc/packages/common-util/index.common';

import { getUserMedalCount, getCurrUserInfo } from './service';
import { productionUtil } from '../../common-util/productionUtil';

export default {
  name: 'YxtbizUserCenterNav',
  components: {
    YxtfPortrait: FrontPortrait,
    YxtfTooltip: FrontTooltip,
    YxtfButton: FrontButton
  },
  props: {
    pageCode: {
      type: String,
      required: true
    }
  },
  mixins: [common.genIsMixin()],
  computed: {
    hiddenMyArchive() {
      return this.isFunPointHide(enmuFunctions.MY_ARCHIVES);
    },
    hiddenUserLink() {
      console.log(this.isFunPointHide(enmuFunctions.ORGANIZATIONAL_STRUCTRUE));
      return this.isFunPointHide(enmuFunctions.ORGANIZATIONAL_STRUCTRUE);
    },
    navListFilter() {
      return list => {
        return list.filter(item => {
          return !(
            (item.isHideFunc && item.isHideFunc()) ||
            this.isFunPointHide(item.funPointCode)
          );
        });
      };
    }
  },
  data() {
    return {
      orgId: '',
      userId: '',
      fullname: '',
      deptName: '',
      positionName: '',
      imgUrl: '',
      navList1: [
        {
          name: 'biz_nav_study_history', // 学习历史
          url: '/study/#/history',
          pageCode: 'study_history',
          funPointCode: enmuFunctions.ORGANIZATIONAL_STRUCTRUE
        },
        {
          name: 'biz_nav_study_note', // 学习笔记
          url: '/kng/#/studynote',
          pageCode: 'kng_studynote',
          funPointCode: enmuFunctions.LEARNING_NOTE
        },
        {
          name: 'pc_biz_te_lbl_teaching_information', // 教学信息
          url: '/te/#/teachinfo',
          pageCode: 'te_teachinfo',
          funPointCode: enmuFunctions.ORGANIZATIONAL_STRUCTRUE
        },
        {
          name: 'biz_collect_nav_title', // 我的收藏
          url: '/utility/#/collect',
          pageCode: 'utility_collect',
          funPointCode: enmuFunctions.ORGANIZATIONAL_STRUCTRUE
        },
        {
          name: 'biz_nav_mywrongs', // 我的错题
          url: '/ote/#/mywrongs',
          pageCode: 'ote_mywrongs',
          funPointCode: enmuFunctions.MY_MISTAKES
        },
        {
          isHideFunc: () => {
            return (
              this.isOpenData ||
              this.isFunPointHide(enmuFunctions.REPORT_ASSISTANT_USER)
            );
          },
          name: 'biz_nav_report_help', // 报告助手
          url: '/report/#/assistant/report',
          pageCode: 'report_help',
          funPointCode: enmuFunctions.ORGANIZATIONAL_STRUCTRUE
        }
      ],
      navList2: [
        {
          name: 'biz_nav_my_comments', // 我的评论
          url: '/kng/#/mycomments',
          pageCode: 'kng_myComments',
          funPointCode: enmuFunctions.ORGANIZATIONAL_STRUCTRUE
        },
        {
          name: 'biz_nav_my_discussion', // 我的讨论
          url: '/bbs/#/myDiscussion',
          pageCode: 'bbs_myDis',
          funPointCode: enmuFunctions.DISCUSSION_MY_DISCUSSION
        }
      ],
      navList3: [
        {
          name: 'biz_message_nav_title', // 消息中心
          url: '/#/immsg',
          pageCode: 'immsg',
          isHideFunc: () => {
            return !this.isOpenIM;
          }
        }
      ],
      navList4: [
        {
          name: 'biz_core_personal_info', // 个人资料
          url: '/#/uinfo',
          pageCode: 'userinfonew'
        },
        {
          name: 'biz_privacy_nav_title', // 隐私设置
          url: '/#/privacysetting',
          pageCode: 'privacysetting',
          funPointCode: enmuFunctions.ORGANIZATIONAL_STRUCTRUE
        },
        {
          name: 'biz_accountsafe_nav_title', // 账户安全
          url: '/#/accountsafe',
          pageCode: 'accountsafe',
          isHideFunc: () => {
            return (
              this.isOpenData ||
              this.isFunPointHide(enmuFunctions.ORGANIZATIONAL_STRUCTRUE)
            );
          }
        }
      ]
    };
  },
  async created() {
    await preCheckFunctions();
    await this.getCurrUserInfo();
    await this.medalPermission();
  },
  methods: {
    go(url) {
      const defaultProductInfo = productionUtil.getProductionInfo();
      const defaultProductPath = defaultProductInfo.path
        ? `/${defaultProductInfo.path}`
        : '';
      const spPath = ['/#/uinfo', '/#/immsg']; // 奇点展示这两个路径
      if (spPath.includes(url)) {
        window.location.href = `${defaultProductPath}${url}`;
      } else {
        window.location.href = url;
      }
    },
    async getCurrUserInfo() {
      try {
        const {
          id,
          fullname,
          orgId,
          loginDeptName,
          loginPositionName,
          imgUrl
        } = await getCurrUserInfo();
        if (
          loginDeptName &&
          typeof loginDeptName === 'string' &&
          loginDeptName.length
        ) {
          this.deptName = loginDeptName.split('->').pop();
        }
        this.positionName = loginPositionName;
        this.userId = id;
        this.fullname = fullname;
        this.orgId = orgId;
        this.imgUrl = imgUrl;
      } catch (error) {
        this.$message.error(error.message);
      }
    },
    medalPermission() {
      if (this.isFunPointHide(enmuFunctions.MY_REWARD_MGMT)) return;
      getUserMedalCount(this.orgId, this.userId).then(res => {
        if (res && res.medalNum > -1) {
          const medalpermission = {
            name: 'biz_nav_my_medal', // 我的勋章
            url: '/reward/#/medalwall',
            pageCode: 'reward_medalwall'
          };
          this.navList1.unshift(medalpermission);
        }
      });
    }
  }
};
</script>
