import { udpApi } from 'yxt-biz-pc/packages/api';

// 获取外部账号信息
export const getVisitorInfo = data => {
  return udpApi.post('/external/users/detail', data);
};

// 保存外部账号信息
export const saveVisitorExtInfo = data => {
  return udpApi.post('/external/users/update/ext', data);
};

