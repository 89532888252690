<template>
  <div class="yxtbiz-i18n-custom-template-wrap">
    <custom-template v-if="$attrs.visible" v-on="$listeners"  v-bind="$attrs"></custom-template>
  </div>
</template>

<script>
import CustomTemplate from './custom-template.vue';
/**
 * 消息公告 自定义消息模板组件
 */

export default {
  name: 'YxtbizI18nCustomTemplate',
  components: {
    CustomTemplate
  }
};
</script>