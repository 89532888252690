<template>
    <ClickToJump :message="$t('pc_biz_tcm_createJumpTip' /* 好的,正在为您唤起创建微课页面 */)" :target-url="targetUrl" :datas="datas" />
  </template>
     
  <script>
    import ClickToJump from '../common/click-to-jump.vue';
    import propsMixin from '../common/props-mixin.js';

    export default {
      name: 'CreateTcm',
      components: {
        ClickToJump
      },
      mixins: [propsMixin],
      data() {
        return {
          targetUrl: this.mode === 'manage' ? `${window.location.origin}/tcm/#/list?isCreate=1` : `${window.location.origin}/tcm/#/center?isCreate=1`
        };
      }
    };
  </script>