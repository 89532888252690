<template>
  <div class="yxtbiz-area-code-select" :class="{'yxtbiz-area-code-select-48':height48, 'yxtbiz-area-code-select-isforbidden': disable}">
    <yxt-form>
      <yxt-input :size="size"  :placeholder="isXuanxing ? $t('common_i18n_country_insertPhone').d('请输入手机号'):'请输入手机号'"
                v-model="phoneCode.phone"
                clearable
                :disabled="disable"
                autocomplete="off"
                @input="inputChange('')"
                class="input-with-drop">
        <yxt-dropdown class="dropdownFull" custom-elm @visible-change="change" placement="bottom-start" @command="inputChange"  slot="prepend">
          <div :class="disable?'isNotallow':'ispointer'">
            <div class="yxtbiz-area-code-select_codeItem">+{{ Number(phoneCode.areaCode||86)}} </div>
            <template  v-if="!disable">
              <yxt-svg width="12px"
                      height="12px"
                      class="svgIcon"
                      icon-class="arrow-down" />
            </template>
          </div>
          <yxt-dropdown-menu slot="dropdown" v-if="!disable">
            <yxt-dropdown-item
              v-if="areaCodeList.length>0"
              :command="item"
              v-for="(item, index) in areaCodeList"
              :key="index.id"
              class="yxtbiz-area-code-select_chooseItem yxtbiz-area-code-select_chooseListBox"
              >
                <span class="yxtbiz-area-code-select_areaCode">+{{ item.areaCode }}</span>
                <span :title="isXuanxing ? $t(item.countryKey):item.countryName">{{ isXuanxing?$t(item.countryKey):item.countryName }}</span>
            </yxt-dropdown-item>
            <div v-else class="yxtbiz-area-code-select__nodata">
              <yxt-empty></yxt-empty>
            </div>
          </yxt-dropdown-menu>
        </yxt-dropdown>
      </yxt-input>
    </yxt-form>
  </div>
</template>

<script>
import { getAreaCodeList } from './service.js';
export default {
  name: 'YxtbizAreaCodeSelect',
  props: {
    phoneCode: Object,
    height48: Boolean,
    disable: Boolean,
    module: { //  用户判断是否是绚星2.0
      type: Boolean,
      default: 'xuanxing'
    },
    size: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showList: false,
      isloading: false,
      areaCodeList: []
    };
  },
  computed: {
    isXuanxing() {
      return this.module === 'xuanxing';
    }
  },
  created() {
    this.initParams();
    this.getAreaList();
  },
  methods: {
    initParams() {
      if (!this.phoneCode) this.phoneCode = {};
      if (!this.phoneCode.areaCode) this.phoneCode.areaCode = '+86';
      if (!this.phoneCode.phone) this.phoneCode.phone = '';
    },
    inputChange(item) {
      // 根据item的值来判断是点击下拉列表还是输入框的改变
      // this.phoneCode.areaCode = `+${ item && item.areaCode || 86}`;
      if (item) {
        this.phoneCode.areaCode = `+${item.areaCode}`;
      } else {
        let phoneS = String(this.phoneCode.phone).replace(/[^\d{5,20}]/g, '');
        this.phoneCode.phone = phoneS.length <= 20 ? phoneS : phoneS.slice(0, 20);
      }
      this.$emit('areaCodeChange', this.phoneCode);
      this.$emit('getFullPhone', `${this.phoneCode.areaCode}-${this.phoneCode.phone}`);
    },
    getAreaList() {
      this.isloading = true;
      getAreaCodeList().then(res => {
        this.areaCodeList = res.data;
        this.isloading = false;
      }).catch(() => {
        this.isloading = false;
        this.areaCodeList = [];
      });
    }
  }
};
</script>
<style lang="scss">
.yxtbiz-area-code-select {
  box-sizing: border-box;
  width: 320px;
  position: relative;
  .ispointer{
    cursor: pointer;
  }
  .isforbidden{
    cursor: not-allowed;
  }
  .input-with-drop {
    .yxt-input-group__prepend {
      background: #fff;
      padding: 0 0 0 0px;
      width: 64px;
    }
  }
  .yxtbiz-area-code-select_codeItem {
    display: inline-block;
    text-align: left;
    margin-left: 12px;
  }
  .svgIcon {
    position: relative;
    top: 2px;
  }
}
.yxtbiz-area-code-select-isforbidden{
  .input-with-drop {
    .yxt-input-group__prepend {
      cursor: not-allowed;
      background: #f5f5f5;
      .yxtbiz-area-code-select_codeItem{
        color: #bfbfbf;
      }
    }
  }
}
.yxtbiz-area-code-select-48{
  .yxt-input__inner {
    height: 48px;
  }
    height: 48px;
}

.yxtbiz-area-code-select_chooseItem {
  box-sizing: border-box;
  width: 300px;
  padding-left: 12px;
  height: 26px;
  color: #595959;
  line-height: 26px;
  font-size: 12px;
  text-overflow: ellipsis;
  font-family: PingFangSC-Regular, PingFang SC;
  white-space: nowrap;
  overflow: hidden;
  .yxtbiz-area-code-select_areaCode{
    display: inline-block;
    width: 58px;
  }
}

.yxtbiz-area-code-select .input-with-drop .yxtf-input-group__prepend{
  padding: 2px 0 2px 0;
}

.dropdownFull{
  width: 100%;
  display: block;

}
.yxtbiz-area-code-select-48 .dropdownFull{
  height: 42px;
  line-height: 42px;
}
.yxtbiz-area-code-select_chooseListBox{
  box-sizing: border-box;
  padding-right: 6px !important;
  width: 318px;
}
.yxtbiz-area-code-select__nodata{
  width: 317px;
  height: 200px;
  text-align: center;
}
</style>
