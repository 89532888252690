var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "yxt-select",
    {
      attrs: { "value-key": "value", "prop-label": "label" },
      on: { change: _vm.change },
      model: {
        value: _vm.selected,
        callback: function ($$v) {
          _vm.selected = $$v
        },
        expression: "selected",
      },
    },
    _vm._l(_vm.sex, function (item, index) {
      return _c("yxt-option", {
        key: index,
        attrs: { value: item, label: item.label },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }