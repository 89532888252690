<template>
  <yxt-select
    ref="grade-select"
    popper-class="yxtbiz-grade-select"
    v-model="selected"
    value-key="id"
    prop-label="name"
    :clearable="true"
    @clear="clear"
    :collapse-tags="true"
    multiple
    filterable
    :filter-method="filterMethod"
    @change="change"
    @remove-tag="removeTag"
  >
    <div v-if="filterOptions.length" class="lh44" slot="fixed-header">
      <yxt-checkbox
        :indeterminate="isIndeterminate"
        :key="key"
        v-model="isAll"
        @change="changeAll"
        >{{ $t('pc_biz_udp_lbl_selectAll') }}</yxt-checkbox
      >
    </div>
    <yxt-option
      :key="item.id"
      :value="item.id"
      v-for="item in filterOptions"
      :label="item.name"
      style="height: auto; max-height: 200px; overflow: auto"
    >
      <yxt-checkbox
        :change-text-color="true"
        @change="changeGrade($event, item)"
        :value="testChecked(item)"
        style="width:100%"
      >
        <div v-if="item.newLabel" v-html="item.newLabel"></div>
        <div v-else>
          {{ item.name }}
        </div>
      </yxt-checkbox>
    </yxt-option>
  </yxt-select>
</template>

<script>
import { getGradeList } from '../service';
import { Select, Option } from 'yxt-pc';
export default {
  components: {
    YxtSelect: Select,
    YxtOption: Option
  },
  created() {
    getGradeList({ limit: 10000, offset: 0 }, this.enableGroupCorp).then(
      result => {
        this.grades = [...result.datas];
        this.filterOptions = [...result.datas];
        this.setSelected();
      }
    );
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    enableGroupCorp: {
      defalut: false
    }
  },
  data() {
    return {
      selected: [],
      grades: [],
      isAll: false,
      key: null,
      filterOptions: []
    };
  },
  computed: {
    isIndeterminate() {
      return (
        this.selected.length > 0 &&
        this.selected.length < this.filterOptions.length
      );
    }
  },
  methods: {
    setSelected() {
      let selected = [];
      if (this.value.length !== 0) {
        this.isAll = this.grades.every(item => {
          return this.value.find(id => item.id === id);
        });
        this.grades.forEach(item => {
          if (this.value.indexOf(item.id) !== -1) {
            selected.push(item);
          }
        });
      } else {
        this.isAll = false;
      }
      this.selected = selected;
    },
    change(items) {
      this.isAll = this.grades.every(item => {
        return items.find(node => item.id === node.id);
      });
      this.$emit('input', items.map(item => item.id));
    },
    changeAll(v) {
      this.isAll = v;
      this.checkAllStatus();
    },
    checkAllStatus() {
      if (this.isAll) {
        this.selected = this.selected.concat(this.filterOptions);
      } else {
        this.selected = [];
      }
      this.$emit('input', this.selected.map(item => item.id));
    },
    changeGrade(checked, item) {
      if (this.testChecked(item)) {
        this.selected = this.selected.filter(node => node.id !== item.id);
      } else {
        this.selected.push(item);
      }
      this.change(this.selected);
    },
    testChecked(item) {
      let index = this.selected.findIndex(node => {
        return node.id === item.id;
      });
      return index > -1;
    },
    removeTag(tag) {
      /* eslint-disable no-undef */
      let s = new Set(this.selected.map(item => item.id));
      s.delete(tag.id);
      this.$emit('input', Array.from(s));
    },
    clear() {
      this.$emit('input', []);
    },
    filterMethod(val) {
      // this.filterOptions = this.grades.filter(item => {
      //   return item.name.toLowerCase().includes(val.toLowerCase());
      // });this.currentOption=[]
      let arr = [];
      this.grades.forEach(item => {
        if (item.name.includes(val)) {
          item.newLabel = item.name.replace(
            val,
            `<span style='color:var(--color-primary)'>${val}</span>`
          );
          arr.push(item);
        }
      });
      this.filterOptions = arr;
      this.isAll = this.grades.length === this.selected.length;
      this.key = new Date().getTime();
    }
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.setSelected();
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
