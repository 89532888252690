var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yxtbiz-upload-image" },
    [
      !_vm.simple
        ? _c(
            "ul",
            [
              _vm._l(_vm.imglist, function (item, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    staticClass: "yxtbiz-upload-image__list",
                    style: _vm.liStyle,
                    on: {
                      click: function ($event) {
                        return _vm.onPreview(index)
                      },
                    },
                  },
                  [
                    _c("yxt-image", {
                      attrs: { src: item.url, fit: "contain" },
                    }),
                    !_vm.noOperation
                      ? _c(
                          "span",
                          {
                            staticClass: "yxtbiz-upload-image__list-operation",
                          },
                          [
                            _c("i", {
                              staticClass: "yxt-icon-close",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.onDelete(index)
                                },
                              },
                            }),
                            _vm.isNeedUpload
                              ? _c("i", {
                                  staticClass: "yxt-icon-refresh-left",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.onEdit(index)
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm.setMain
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "yxtbiz-upload-image__list-main",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.changeMainIndex(index)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        index == 0
                                          ? _vm.$t(
                                              "pc_biz_uploadimg_tip_main" /* 主图 */
                                            )
                                          : _vm.$t(
                                              "pc_biz_uploadimg_btn_main" /* 设为主图 */
                                            )
                                      )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
              _c(
                "li",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showUploadBtn,
                      expression: "showUploadBtn",
                    },
                  ],
                  staticClass: "yxtbiz-upload-image__picture-card",
                  style: _vm.liStyle,
                  on: { click: _vm.onCreate },
                },
                [_c("i", { staticClass: "yxt-icon-plus" })]
              ),
            ],
            2
          )
        : _vm._e(),
      _vm.simple || (_vm.isNeedUpload && _vm.noCrop)
        ? _c(
            "yxtbiz-upload",
            {
              attrs: {
                "config-key": "ImageConfigKey",
                appCode: _vm.appCode,
                source: _vm.source,
                "module-name": _vm.moduleName,
                "function-name": _vm.functionName,
                filters: _vm.filters,
                "max-size": _vm.size,
                multipe: _vm.multipe && !_vm.simple,
                "files-added": _vm.onFileAdded,
                "file-filtered": _vm.onFileAdded,
                "before-upload": _vm.onBeforeUpload,
                "on-uploaded": _vm.onUploaded,
                "on-progress": _vm.onProgress,
                isV1: _vm.isV1,
              },
            },
            [
              _c(
                "yxt-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.simple,
                      expression: "simple",
                    },
                  ],
                  ref: "bizUpload",
                  attrs: { plain: "" },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("pc_biz_uploadimg_btn_upload" /* 上传附件 */))
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.simple
        ? _c(
            "ul",
            _vm._l(_vm.imglist, function (item, index) {
              return _c(
                "li",
                { key: index, staticClass: "yxtbiz-upload-image__simple" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "yxtbiz-upload-image__simple-title",
                      on: {
                        click: function ($event) {
                          return _vm.onPreview(index)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "yxt-icon-paperclip" }),
                      _c("span", { attrs: { title: item.name } }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                      _c("i", {
                        staticClass: "yxt-icon-close",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.onDelete(index)
                          },
                        },
                      }),
                    ]
                  ),
                  item.percentage != "ok"
                    ? _c("yxt-progress", {
                        attrs: { percentage: item.percentage },
                      })
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      !_vm.simple && !_vm.noPreview
        ? _c("yxtbiz-image-viewer", {
            ref: "imageViewer",
            attrs: { "no-list": "", data: _vm.imglist },
          })
        : _vm._e(),
      _vm.isNeedUpload && !_vm.noCrop
        ? _c("yxtbiz-image-cropper", {
            attrs: {
              src: _vm.src,
              "app-code": _vm.appCode,
              source: _vm.source,
              "module-name": _vm.moduleName,
              "function-name": _vm.functionName,
              size: _vm.size,
              rate: _vm.rate,
              filters: _vm.filters,
              suggest: _vm.suggest,
              visible: _vm.show,
              isV1: _vm.isV1,
              oversizeMsg: _vm.oversizeMsg,
              filterMsg: _vm.filterMsg,
            },
            on: {
              "update:visible": function ($event) {
                _vm.show = $event
              },
              complete: _vm.complete,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }