<!-- 创建时间2024/01/22 10:25:45 -->
<!-- 创建人：Zhao bing bing -->
<!-- 组件描述：自定义字数限制 -->
<template>
  <yxt-form-item class="mb16" :label="config.labelName"
                 :prop="config.name">
    <yxt-input v-model="value_"
               :maxlength="maxlength"
               show-word-limit
               :placeholder="$t('pc_biz_core_tip_placeholder')"
               size="small"
               :disabled="disabled"/>         
  </yxt-form-item>
</template>

<script>
export default {
  name: 'LimitInput',
  props: {
    value: {
      type: [Number, String],
      default: ''
    },
    config: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxlength: {
      type: Number,
      default: 50
    }
  },
  data() {
    return {};
  },
  computed: {
    value_: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
        this.change(val);
      }
    }
  },
  methods: {
    change(val) {
      this.$emit('change', val);
    }
  }
};
</script>