var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "yxtbiz-ai-robot-collect-item width-percent-100 over-hidden",
      on: {
        click: function ($event) {
          return _vm.toDetail(_vm.data)
        },
      },
    },
    [
      _vm.coverUrl(_vm.data)
        ? _c(
            "div",
            { staticClass: "yxtbiz-ai-robot-collect-community__img" },
            [
              _c("yxt-image", {
                staticClass: "yxtbiz-ai-robot-bbs__cover mr12",
                attrs: { src: _vm.coverUrl(_vm.data), alt: "", fit: "cover" },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "over-hidden" }, [
        _vm.data.postType === 2 || _vm.data.postType === 9
          ? _c("div", [
              _c(
                "div",
                {
                  class: [
                    _vm.size === "small"
                      ? "ellipsis-2 font-size-14 lh22"
                      : "ellipsis font-size-16  lh24",
                    "color-gray-10",
                  ],
                },
                [
                  _c(
                    "yxtf-tag",
                    {
                      staticClass: "mr8 vertical-align__middle",
                      attrs: { type: "warning", size: "mini" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.data.postType === 9
                              ? _vm.$t("pc_biz_collect_lbl_answer")
                              : _vm.$t("pc_biz_collect_lbl_discuss")
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.data.title) },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm.data.postType === 1
          ? _c(
              "div",
              {
                class: [
                  _vm.size === "small"
                    ? "ellipsis-2 font-size-14 lh22"
                    : "ellipsis font-size-16  lh24",
                  "color-gray-10",
                ],
              },
              [
                _c(
                  "yxtf-tag",
                  {
                    staticClass: "mr8",
                    attrs: { type: "warning", size: "mini" },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("pc_biz_collect_lbl_discuss")) +
                        "\n      "
                    ),
                  ]
                ),
                _c("span", { domProps: { innerHTML: _vm._s(_vm.data.title) } }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          {
            class: [
              _vm.size === "small" ? "mt4" : "mt8",
              "yxtbiz-flex-center lh20 color-gray-7 font-size-12",
            ],
          },
          [
            !_vm.data.anonymous
              ? _c(
                  "span",
                  { staticClass: "ellipsis maxwidth200" },
                  [
                    _c("yxtbiz-user-name", {
                      attrs: { name: _vm.data.createUsername },
                    }),
                  ],
                  1
                )
              : _c("span", { staticClass: "ellipsis maxwidth200" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("pc_biz_collect_anonymousUser"))
                  ),
                ]),
            _c("yxt-divider", { attrs: { direction: "vertical" } }),
            _c("span", { staticClass: "ellipsis mr20" }, [
              _vm._v(_vm._s(_vm._f("formatTime")(_vm.data.createTime))),
            ]),
            _vm.size !== "small"
              ? _c("span", { staticClass: "ellipsis maxwidth300" }, [
                  _vm._v("\n        " + _vm._s(_vm.data.boardName)),
                ])
              : _vm._e(),
          ],
          1
        ),
        _vm.size === "small"
          ? _c(
              "div",
              { staticClass: "ellipsis mt4 lh20 color-gray-7 font-size-12" },
              [_vm._v("\n      " + _vm._s(_vm.data.boardName) + "\n    ")]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }