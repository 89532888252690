<template>
  <div class="over-hidden">
    <div class="yxtbiz-ai-robot-comment-item mb4 break">
      <div
        :class="[
          size === 'small'
            ? 'ellipsis-3 font-size-14 lh22'
            : 'ellipsis-2 font-size-16  lh24',
          'color-gray-10'
        ]"
      >
        <yxtf-tag
          v-if="item.sticky === 1"
          type="warning"
          size="mini"
          class="mr8"
        >
          {{ $t('yxtbiz_ai_comments_lbl_excellent') }}
        </yxtf-tag>
        <yxtf-tag
          v-if="
            handlerStatusToText(item.auditConclusion, item.status) &&
            typeTabsValue !== '3'
          "
          type="info"
          size="mini"
          class="mr8"
        >
          {{ handlerStatusToText(item.auditConclusion, item.status) }}
        </yxtf-tag>
        <slot :content="item" name="title"></slot>
      </div>
    </div>
    <div class="mb4 yxtbiz-ai-robot-comment-item">
      <rate v-if="isCourse" :score="item.score" class="mr14" />
      <div class="lh20 color-gray-7 font-size-12">{{ item.createTime }}</div>
    </div>
    <div class="yxtbiz-ai-robot-comment-item lh20 color-gray-7 font-size-12">
      <div
        class="ellipsis mr4 hand maxwidth200 hover-primary-6"
        :class="[
          size === 'small'
            ? 'maxwidth200'
            : 'maxwidth300'
        ]"
        @click="openKng(item)"
      >
        【{{ handlerTypeToText(item) }}】<span v-html="item.targetName"></span>
      </div>
      <yxt-divider direction="vertical"></yxt-divider>
      <div class="ellipsis">
        <slot :catalogName="item" name="catalogName"></slot>
      </div>
    </div>
  </div>
</template>
<script>
import rate from './rate';

export default {
  props: {
    item: {
      type: Object
    },
    params: {
      type: Object,
      default: () => ({})
    }, // 用于跳转等功能带参数使用
    size: {
      type: String,
      default: 'small',
      validator: val => ['small', 'large'].includes(val)
    }
  },
  computed: {
    isCourse() {
      return this.params.commentType === '1';
    },
    typeTabsValue() {
      return this.params.commentType;
    }
  },
  components: {
    rate
  },
  data() {
    return {};
  },
  methods: {
    linkSubString(url, data) {
      if (url === null || url === '') {
        return url;
      }
      let queryString = '';
      if (typeof data === 'object') {
        for (const i in data) {
          queryString += i + '=' + data[i] + '&';
        }
      }
      if (url.indexOf('?') > url.indexOf('/')) {
        url += '&';
      } else {
        url += '?';
      }
      if (queryString !== '') {
        queryString = queryString.substr(0, queryString.length - 1);
      }
      url += queryString;
      return url;
    },
    convertTypeToTxtBBs(targetType) {
      let txt = '';
      switch (targetType) {
        case 1:
          txt = this.$t('pc_biz_collect_lbl_post' /* 帖子 */);
          break;
        case 7:
          txt = this.$t('yxtbiz_ai_lbl_answer' /* 回答 */);
          break;
        case 11:
          txt = this.$t('pc_biz_collect_lbl_comments' /* 书评 */);
          break;
        case 12:
          txt = this.$t('pc_biz_collect_lbl_notes' /* 笔记 */);
          break;
        default:
          txt = this.$t('pc_biz_collect_lbl_post' /* 帖子 */);
          break;
      }
      return txt;
    },
    // kngType 对应的展示名
    convertTypeToTxt(kngtype) {
      let txt = '';
      switch (kngtype) {
        case 0:
          txt = this.$t('yxtbiz_ai_lbl_course' /** 课程 */);
          break;
        case 1:
          txt = this.$t('pc_biz_knglib_lbl_doc' /* 文档 */);
          break;
        case 2:
          txt = this.$t('pc_biz_knglib_lbl_video' /* 视频 */);
          break;
        case 3:
          txt = this.$t('pc_biz_knglib_lbl_audio' /* 音频 */);
          break;
        case 4:
          txt = this.$t('pc_biz_knglib_lbl_weike' /* 微课 */);
          break;
        case 5:
          txt = 'SCORM';
          break;
        case 6:
          txt = 'HTML';
          break;
        case 7:
          txt = this.$t('pc_biz_kng_lbl_ziptype' /* 压缩包 */);
          break;
        case 8:
          txt = this.$t('pc_biz_knglib_lbl_newlink' /** 外链 */);
          break;
        case 90:
          txt = this.$t('pc_biz_o2o_lbl_practice' /* 练习 */);
          break;
        case 91:
          txt = this.$t('pc_biz_o2o_lbl_exam' /* 考试 */);
          break;
        default:
          txt = '-';
          break;
      }
      return txt;
    },
    // 所属课程/课程分类：课程类型（视频、文档、图片、课程、音频、微课、HTML、Scorm、压缩文件）
    handlerTypeToText(item) {
      const { extType, targetType } = item;
      if (this.typeTabsValue === '3') {
        // 社区
        return this.convertTypeToTxtBBs(targetType);
      } else {
        if (extType === 0) {
          return this.$t('yxtbiz_ai_lbl_course');
        }
        return this.convertTypeToTxt(extType);
      }
    },

    // 状态：指评论的状态，已发布（不展示状态），待审核，被举报（合规管理中屏蔽该内容，在课程页面不展示个人中心保留显示），已删除状态在课程页面和个人中心页面均不展示；
    // 两个字段控制显示
    // 审核结果(-1-查全部,0-待审核,1-通过,2-不通过,3-系统屏蔽)
    // 状态(0-屏蔽,1-正常,2-举报后屏蔽)
    handlerStatusToText(auditConclusion, status) {
      if (auditConclusion === 0) {
        return this.$t('yxtbiz_ai_commentmgmt_lbl_audit_type1');
      }
      if (status === 2 || status === 0) {
        return this.$t('yxtbiz_ai_mgmt_lbl_status_report');
      }
      return false;
    },
    // 跳转到知识详情页
    async openKng(item) {
      const { targetId: id } = item;
      let hash, href;
      switch (this.typeTabsValue) {
        case '1': {
          hash = `/scan?kngId=${id}`;
          href = `${window.location.protocol}//${window.location.host}/kng/#${hash}`;
          break;
        }
        case '2':
          hash = `/detail?kngId=${id}`;
          href = `${window.location.protocol}//${window.location.host}/knglib/#${hash}`;
          break;
        case '3':
          hash = `/postdetail/${id}?&type=2`;
          href = `${window.location.protocol}//${window.location.host}/bbs/#${hash}`;
          break;
        default:
          break;
      }
      window.open(href);
    }
  }
};
</script>
<style lang="scss" scoped>
.yxtbiz-ai-robot-comment-item {
  display: flex;
  align-items: center;
}

.maxwidth200 {
  max-width: 200px;
}

.maxwidth300 {
  max-width: 300px;
}
</style>
