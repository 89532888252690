var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        "ai-source-content": true,
        "ai-source-content-fullscreen": _vm.fullscreen,
        "ai-source-content-search": _vm.isSearch,
      },
    },
    [
      !_vm.msg.isFunction
        ? _c("div", { staticClass: "ai-content-source" }, [
            _vm._v(_vm._s(_vm.$t("pc_biz_ai_content_source").d("内容来源:"))),
          ])
        : _vm._e(),
      _vm._l(_vm.contentDetail.linkList, function (source, index) {
        return _c(
          "div",
          {
            key: index,
            on: {
              click: function ($event) {
                return _vm.goUrl(source.goUrl)
              },
            },
          },
          [
            source.goUrl
              ? [
                  source.sourceType
                    ? _c("div", { staticClass: "link-content" }, [
                        _c(
                          "div",
                          { staticClass: "d-content-link" },
                          [
                            _c("yxt-svg", {
                              staticClass: "link-svg",
                              attrs: {
                                width: "26px",
                                height: "26px",
                                "remote-url": _vm.h5Svg,
                                "icon-class": "ai-link",
                              },
                            }),
                            _c("span", { staticClass: "link-text" }, [
                              _vm._v(_vm._s(source.goUrl)),
                            ]),
                          ],
                          1
                        ),
                      ])
                    : _c(
                        "div",
                        { staticClass: "d-content" },
                        [
                          _c("div", { staticClass: "d-content-left" }, [
                            source.coverUrl
                              ? _c("img", { attrs: { src: source.coverUrl } })
                              : _c("img", { attrs: { src: _vm.linkBgUrl } }),
                          ]),
                          _c(
                            "div",
                            {
                              class: {
                                "d-content-right": true,
                                "d-content-right-2": !source.description,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  class: {
                                    "source-title": true,
                                    "source-title-2": !source.description,
                                  },
                                },
                                [_vm._v(_vm._s(source.name))]
                              ),
                              source.description
                                ? _c(
                                    "div",
                                    { staticClass: "source-description" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getSource(source.description)
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              source.lecturesName ||
                              source.contributorsName ||
                              source.createFullName
                                ? _c(
                                    "div",
                                    {
                                      class: {
                                        "source-learning-info": true,
                                        "source-learning-info-2":
                                          !source.description,
                                      },
                                    },
                                    [
                                      source.lecturesName
                                        ? [
                                            _vm._v(
                                              _vm._s(
                                                _vm
                                                  .$t("pc_biz_ai_lecturer")
                                                  .d("讲师") +
                                                  ": " +
                                                  source.lecturesName
                                              )
                                            ),
                                          ]
                                        : source.contributorsName
                                        ? [
                                            _vm._v(
                                              _vm._s(
                                                _vm
                                                  .$t("pc_biz_ai_contributor")
                                                  .d("贡献者") +
                                                  ": " +
                                                  source.contributorsName
                                              )
                                            ),
                                          ]
                                        : source.createFullName
                                        ? [
                                            _vm._v(
                                              _vm._s(
                                                _vm
                                                  .$t("pc_biz_ai_founder")
                                                  .d("创建人") +
                                                  ": " +
                                                  source.createFullName
                                              )
                                            ),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _c("yxt-svg", {
                            staticClass: "link-right",
                            attrs: { "icon-class": "arrow-right" },
                          }),
                        ],
                        1
                      ),
                ]
              : _vm._e(),
          ],
          2
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }