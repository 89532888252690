<template>
  <div>
    <yxt-dialog v-if="size === 'large'"
      @closed="toggleDomClass(false)"
      :visible.sync="customVisible"
      custom-class="yxtbiz-ai-robot__treasure"
      :modal-append-to-body="false"
      :cutline="false">
      <template slot="title">
        <div class="yxtbiz-ai-robot__treasure--title">{{$t('pc_biz_lbl_treasure_chest').d('百宝箱')}}</div>
        <div class="yxtbiz-ai-robot__treasure--search">
          <yxt-input v-model.trim="content" :placeholder="$t('pc_biz_msg_placeholder_txt').d('请输入搜索关键词')" searchNoIcon @search="postTreasureList">
            <i slot="suffix" class="yxt-input__icon yxt-icon-search hand" @click="postTreasureList"></i>
          </yxt-input>
        </div>
        <div class="yxtbiz-ai-robot__treasure--tab">
          <yxt-tabs v-model="activeTab" @tab-click="handleTabsClick">
            <yxt-tab-pane :label="$t('pc_biz_lbl_winnow').d('精选')" name="tab1"></yxt-tab-pane>
            <yxt-tab-pane :label="$t('pc_biz_lbl_usual').d('我的常用')" name="tab2"></yxt-tab-pane>
          </yxt-tabs>
        </div>
      </template>
      <div class="yxtbiz-ai-robot__treasure-body">
        <div v-if="loading" class="pos-center" v-loading="loading" yxt-loading-background="transparent"></div>
        <Treasure-list v-else :list="list" @toggleCollect="toggleCollect" @operation="handleOperation" />
      </div>
    </yxt-dialog>
    <div v-else>
      <transition name="slide">
        <div v-if="customVisible" class="yxtbiz-ai-robot__treasure-small">
          <div class="yxtbiz-ai-robot__treasure-small--header">
            <div
              @click="customVisible = false"
                class="yxtbiz-ai-robot__treasure-small--back"
              >
              <i class="yxt-icon-arrow-left font-size-24 font-bold"></i>
              <span class="ml2">
                {{$t('pc_comp_pageHeader_title').d('返回')}}
              </span>
            </div>
            {{$t('pc_biz_lbl_treasure_chest').d('百宝箱')}}
          </div>
          <div class="yxtbiz-ai-robot__treasure-small--nav">
            <yxt-tabs v-model="activeTab" @tab-click="handleTabsClick" height-size="small">
              <yxt-tab-pane :label="$t('pc_biz_lbl_winnow').d('精选')" name="tab1"></yxt-tab-pane>
              <yxt-tab-pane :label="$t('pc_biz_lbl_usual').d('我的常用')" name="tab2"></yxt-tab-pane>
            </yxt-tabs>
            <div class="yxtbiz-ai-robot__treasure-small--search">
              <yxt-input v-model.trim="content" :placeholder="$t('pc_biz_msg_placeholder_txt').d('请输入内容文字')" searchNoIcon @search="postTreasureList">
                <i slot="suffix" class="yxt-input__icon yxt-icon-search hand" @click="postTreasureList"></i>
              </yxt-input>
            </div>
          </div>
          <div class="yxtbiz-ai-robot__treasure-small--body">
            <yxt-scrollbar
              class="yxtbiz-ai-robot-scrollbar"
              ref="scrollbar"
              :fit-height="true"
            >
            <div v-if="loading" class="pos-center" v-loading="loading" yxt-loading-background="transparent"></div>
              <Treasure-list v-else :list="list" @toggleCollect="toggleCollect" @operation="handleOperation" />
            </yxt-scrollbar>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import TreasureList from './treasureList.vue';
import { getParentNode } from '../utils';
import { postTreasureList, postTreasureSubscribe } from '../service';

export default {
  name: 'TreasureDialog',
  components: {
    TreasureList
  },
  data() {
    return {
      content: '',
      activeTab: '',
      loading: false,
      list: []
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'small',
      validator: val => ['small', 'large'].includes(val)
    }
  },
  computed: {
    customVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      }
    }
  },
  watch: {
    customVisible(v) {
      if (v) {
        this.activeTab = 'tab1';
        this.$nextTick(()=>{
          const el = getParentNode(this.$el, 'yxtbiz-ai-robot--container');
          el && el.appendChild(this.$el);
          if (this.size === 'large') {
            this.toggleDomClass(v);
          }
          this.postTreasureList();
        });
      } else {
        Object.assign(this.$data, this.$options.data.call(this));
      }
    }
  },
  methods: {
    handleOperation(item) {
      this.$emit('operation', item);
    },
    handleTabsClick() {
      this.postTreasureList();
      const scrollElement =
        this.$refs.scrollbar && this.$refs.scrollbar.$el.firstChild;
      scrollElement && (scrollElement.scrollTop = 0);
    },
    postTreasureList() {
      this.loading = true;
      postTreasureList({
        type: this.activeTab === 'tab1' ? 0 : 1,
        keyword: this.content
      }).then(res=>{
        this.list = (res || []).map((item, index)=>{
          item.appBeanList = (item.appBeanList || []).map(sItem=>{
            // const { navAuthCode, operAuthCode } = sItem;
            // if (sItem.categoryId !== 0 && (navAuthCode && operAuthCode)) {
            //   return {
            //     ...sItem,
            //     permission: !commonUtil.checkActionPermission(navAuthCode, operAuthCode)
            //   };
            // }
            // return sItem;
            return {
              categoryCode: item.categoryCode,
              ...sItem
            };
          });

          return {
            ...item,
            index
          };
        });
      }).finally(()=>{
        this.loading = false;
      });
    },
    toggleCollect(item, sItem) {
      const { categoryId, id, identCode, subscribe } = sItem;
      const sub = subscribe === 0 ? 1 : 0;
      postTreasureSubscribe({
        categoryId,
        id,
        identCode,
        subscribe: sub
      }).then(()=>{
        sItem.subscribe = sub;
        if (sItem.subscribe === 1 && this.activeTab === 'tab2') {
          const findItem = this.list.find(l=>l.categoryName === item.categoryName && l.index === item.index);
          if (findItem) {
            findItem.appBeanList = findItem.appBeanList.filter(s=>s.id !== sItem.id);
            if (findItem.appBeanList.length === 0) {
              this.list = this.list.filter(l=>!(l.categoryName === item.categoryName && l.index === item.index));
            }
          }
        }

        this.$message.success(this.$t(sub === 0 ? 'pc_biz_msg_collect_success' : 'pc_biz_msg_cancel_collect_success'));
      });
    },
    toggleDomClass(v) {
      this.$nextTick(()=>{
        const treasureDom = this.$el.querySelector('.yxt-dialog__wrapper');
        if (treasureDom) {
          if (v) {
            treasureDom.style.justifyContent = 'center';
          } else {
            treasureDom.style.justifyContent = '';
          }
        }
      });
    }
  },
  beforeDestroy() {
    this.toggleDomClass(false);
  }
};
</script>
