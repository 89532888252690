var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "yxtf-dialog",
    {
      staticClass: "yxtbiz-nav-global-notice-opts",
      attrs: {
        visible: _vm.visible,
        "show-close": false,
        "close-on-click-modal": false,
        top: "100px",
        "padding-size": "empty",
        width: "440px",
        "modal-append-to-body": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "yxtbiz-nav-global-notice-opts__title" }, [
        _c("div", { staticClass: "yxtbiz-nav-global-notice-opts__title-ctx" }, [
          _vm._v(_vm._s(_vm.content.title)),
        ]),
        _c(
          "div",
          { staticClass: "yxtbiz-nav-global-notice-opts__title-btn" },
          [
            _c(
              "yxt-button",
              { attrs: { type: "text" }, on: { click: _vm.closeNotice } },
              [
                _c("i", {
                  staticClass: "yxt-icon-close",
                  attrs: { width: "16px", height: "16px" },
                }),
              ]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "yxtbiz-nav-global-notice-opts-big__wrapper" },
        [
          _c("yxt-scrollbar", { attrs: { "fit-height": true } }, [
            _c("div", {
              staticClass: "wrapper-rich-content",
              domProps: { innerHTML: _vm._s(_vm.content.content) },
            }),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "yxtbiz-nav-global-notice-opts__footer" },
        [
          _c("yxt-button", { on: { click: _vm.closeNotice } }, [
            _vm._v(_vm._s(_vm.$t("pc_biz_notice_btn_close" /* 关闭 */))),
          ]),
          _c(
            "yxt-button",
            { attrs: { type: "primary" }, on: { click: _vm.checkNotice } },
            [
              _vm._v(
                _vm._s(
                  _vm.content.buttonDesc
                    ? _vm.content.buttonDesc
                    : _vm.$t("pc_biz_notice_btn_check" /* 查看 */)
                )
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }