<template>
  <div class="yxtbiz-global-notice">
    <!-- 运营公告-大弹框 -->
    <opts-big-dialog v-if="isOptsBig" :visible.sync="isOptsBig" :content="optsNotice" @closeNotice="closeNotice" @check="showDetail"></opts-big-dialog>
    <!-- 运营公告-小弹框 -->
    <opts-small-dialog v-if="isOptsSmall" :visible.sync="isOptsSmall" :content="optsNotice" @closeNotice="closeNotice" @check="showDetail"></opts-small-dialog>
    <!-- 发版公告,关闭后下次不再展示 -->
    <yxtf-dialog v-if="notice && notice.content && notice.title" :visible.sync="dialogVisible" :show-close="false"  :close-on-click-modal="false" class="yxtbiz-nav-global-notice-vs" top="100px" padding-size='empty' width="680px"  :modal-append-to-body='false'>
      <yxt-button type="text" class="yxtbiz-nav-global-notice-vs-close" @click="closeNotice(notice)">
        <i class="yxt-icon-close" width="16px" height="16px"></i>
      </yxt-button>
      <img :src="notice.styleUrl" width="680" height="176" />
      <div class='yxtbiz-nav-global-notice-vs-wrapper'>
        <yxt-scrollbar :fit-height="true">
          <div class="yxtbiz-nav-global-notice-vs-header">{{notice.title}}</div>
          <div class="yxtbiz-nav-global-notice-vs-content" v-html="notice.content"></div>
        </yxt-scrollbar>
      </div>
      <div class="yxtbiz-nav-global-notice-vs-footer">
        <yxt-button v-if="isShowDownLoad" @click="download">{{$t('pc_biz_download_immediate')}}</yxt-button>
        <yxt-button type="primary" @click="showDetail(notice)">{{$t('pc_biz_notice_btn_check')}}</yxt-button>
      </div>
    </yxtf-dialog>
  </div>
</template>

<script>
import { setReadedClosed, getNoticeRelationDetail, getNoticeDownLoadUrl} from './service';
import OptsBigDialog from './opts-big-dialog.vue';
import OptsSmallDialog from './opts-small-dialog.vue';
import noticeMixin from './noticeMixin';
import { getLanguage } from 'yxt-biz-pc/packages/common-util/changeLangs';
import commonUtil from 'yxt-biz-pc/packages/common-util/index';
import { defaultProductAllPath } from './const';
// 全局公告组件
export default {
  name: 'YxtbizGlobalNotice',
  mixins: [noticeMixin],
  components: {
    OptsBigDialog,
    OptsSmallDialog
  },
  data() {
    return {
      isOptsBig: false,
      isOptsSmall: false,
      dialogVisible: false,
      relationDetail: null,
      isShowDownLoad: true,
      upgradeFileId: ''
    };
  },
  created() {
    // this.getRelationDetail();
  },
  methods: {
    // 获取关联内容详情
    async getRelationDetail() {
      if (this.notice) {
        await getNoticeRelationDetail(this.notice.relationId, this.notice.relationType).then(res=>{
          this.relationDetail = res;
          let yxtLang = getLanguage() || 'zh';
          res.upgradeFiles.forEach(item=>{
            if (item.langTag === yxtLang && item.upgradeFileId.length > 0) {
              this.upgradeFileId = item.upgradeFileId;
              this.isShowDownLoad = true;
            }
          });
        }).catch(e=>{});
        return this.relationDetail;
      }
    },

    // 发版公告下载
    async download() {
      // 神策埋点-公告下载
      this.submitSensors('msgdown', { msgcode: this.notice.id, msgtl: this.notice.title });
      if (!this.debounce(this.notice.id)) return;
      // 关联内容类型，0-更新预告，1-更新说明
      const fileId = await this.getFileId();
      if (!fileId) {
        this.$message.warning(this.$t('pc_biz_core_offline_disallow_download'));
        return;
      };
      getNoticeDownLoadUrl(fileId).then(res=>{
        if (!res.downloadUrl) {
          this.$message.warning(this.$t('pc_biz_core_offline_disallow_download'));
          return;
        }
        this.setReadClose(this.notice.id);
        window.location.href = res.downloadUrl;
        this.notice = null;
      });
    },
    async getFileId() {
      const relationDetail = this.relationDetail || await this.getRelationDetail();
      let fileId;
      if (relationDetail) {
        fileId = this.upgradeFileId;
      }
      if (!relationDetail || !relationDetail.status || !fileId) {
        return;
      }
      return fileId;
    },
    // 发版公告查看
    async showDetail(notice) {
      let xuanxingUrl = '';
      if (notice && notice.connectAddr && notice.connectType === 1) { // 有链接地址且类型是非普通类型
        try {
          const {url} = await commonUtil.toThirdAuth(notice.connectAddr);
          xuanxingUrl = url;
        } catch (e) {
          this.$message.error(e.message || e.error.message);
          return;
        }
      }
      // 神策埋点-查看公告详情
      this.submitSensors('msgopen', { msgcode: notice.id, msgtl: notice.title });
      if (!this.debounce(notice.id)) return;
      this.setReadClose(notice.id);
      if (notice.noticeType === 2) { // 发版公告
        const fileId = await this.getFileId();
        // const url = `${window.location.origin}/support/#/preview?fileId=${fileId}&recordId=${notice.relationId}` ;
        const url = `${defaultProductAllPath}/support/#/preview?fileId=${fileId}&recordId=${notice.relationId}` ;
        window.open(url, '_blank');
        this.notice = null;
      } else if (notice.noticeType === 3) { // 运营公告
        this.isOptsBig = false;
        this.isOptsSmall = false;
        if (notice && notice.connectAddr && notice.connectType === 0) { // 有链接地址且类型是普通类型
          window.open(notice.connectAddr, '_blank');
        } else if (notice && notice.connectAddr && notice.connectType === 1) { // 有链接地址且类型是非普通类型
          window.open(xuanxingUrl, '_blank');
        } else {
          // const url = `${window.location.origin}/#/user/noticecenter?activeId=${notice.id}`;
          const url = `${defaultProductAllPath}/#/user/noticecenter?activeId=${notice.id}`;
          window.open(url, '_blank');
        }
      }
    },

    // 发版公告-已读并关闭
    setReadClose(id) {
      this.dialogVisible = false;
      setReadedClosed({
        noticeId: id,
        read: 1,
        close: 1
      }).then(res=>{
        this.setLastReadedId(id);
        this.$store.dispatch('navManageStore/getUnreadNoticeCnt');
      });
    }
  }
};
</script>
<style lang="scss">
.yxtbiz-nav-global-notice-vs {
    .yxtf-dialog {
      max-width: 680px !important;
    }
  }
</style>
