<template>
  <div class="standard-size-14 yxtbiz-ai-robot__coure">
    <EmptyData v-if="dataList.length === 0" />
    <template v-else>
      <div :class="size === 'small'? 'font-size-14 lh22': 'font-size-16 lh24'" class="color-gray-7 yxtbiz-ai-robot__coure-title">
        {{ $t('pc_biz_ote_lbl_search_content', [$t('pc_biz_o2o_lbl_weike')]) }}
      </div>
      <div v-for="item in dataList" :key="item.id" @click="toDetail(item)">
        <div class="yxtbiz-ai-robot__coure-cell">
          <yxt-image class="yxtbiz-ai-robot__course-cover" v-if="item.imgUrl" :src="item.imgUrl" fit="cover" />
          <div class="ml12 yxtbiz-ai-robot__course-contain">
            <div :class="size === 'small'? 'font-size-14 lh22': 'font-size-16 lh24'" class="ellipsis-2 mb4" v-html="item.title"></div>
            <!-- <div v-if="item.summary" class="summary">
              {{ item.summary }}
            </div> -->
            <div v-if="size !== 'small'" class="coure-flex-center mt4">
              <div class="standard-size-12 color-gray-7 mt4 ellipsis-1">
                {{ item.lecturer ? $t('pc_biz_ai_lecturer').d('讲师') + ': ' + item.lecturer : ''  }}
                <yxt-divider direction="vertical" v-if="item.lecturer"></yxt-divider>
                {{$t('pc_biz_cer_lbl_time').d('时间') + '：' + (item.createTime || item.publishTime).replace(/\.\d+/, '') }}
              </div>
            </div>
            <div v-else class="mt4">
              <div v-if="item.lecturer" class="standard-size-12 color-gray-7 mt4 ellipsis-1">
                {{ $t('pc_biz_ai_lecturer').d('讲师') + ': ' + item.lecturer  }}
              </div>
              <div class="standard-size-12 color-gray-7 mt4 ellipsis-1">
                {{$t('pc_biz_cer_lbl_time').d('时间') + '：' + (item.createTime || item.publishTime).replace(/\.\d+/, '') }}
              </div>
            </div>
          </div>
        </div>
        <template v-if="item.courseContents && item.courseContents.length">
          <yxtf-row v-if="size === 'small'" :gutter="8" class="yxtbiz-ai-robot__microlecture">
            <yxtf-col
              v-for="(item1, index) of item.courseContents.slice(0, 3)"
              :key="index"
              :span="24"
              class="mt8 hand"
            >
              <div class="search-sub-item-small p12" @click="goDetail(item.id, item1.startTime, item.searchKey)">
                <span class="font-size-12 text-26 font-bold">{{ item1.startTime | getHMS }}</span>
                <!-- <yxtf-tooltip
                  open-filter
                  :content="item1.highlightText"
                  placement="top"
                >
                </yxtf-tooltip> -->
                <span class="search-sub-item-small-text flex-1 ml12 text-59" v-html="item1.highlightText" />
              </div>
            </yxtf-col>
            <yxtf-col
              v-if="item.courseContents.length > 3"
              :span="24"
              class="mt8 text-8c"
            >
              <div class="search-sub-item p12 search-sub-item-small-empty text-center hand hover-primary-6">
                <span class="v-mid" @click="toDetail(item)">{{ $t('pc_core_graphicmsg_checkdetail') }}</span>
                <yxtf-svg
                  class="ml4 v-mid"
                  width="16px"
                  height="16px"
                  icon-class="right"
                />
              </div>
            </yxtf-col>
          </yxtf-row>
          <yxtf-row v-else :gutter="8" class="yxtbiz-ai-robot__microlecture">
            <yxtf-col
              v-for="(item1, index) of item.courseContents.slice(0, 3)"
              :key="index"
              :span="6"
              class="mt8 hand"
            >
              <div class="search-sub-item p12" @click="goDetail(item.id, item1.startTime, item.searchKey)">
                <span class="font-size-12 text-26 font-bold">{{ item1.startTime | getHMS }}</span>
                <!-- <yxtf-tooltip
                  open-filter
                  :content="item1.highlightText"
                  placement="top"
                >
                </yxtf-tooltip> -->
                <span class="search-sub-item-text flex-1 ml12 text-59" v-html="item1.highlightText" />
              </div>
            </yxtf-col>
            <yxtf-col
              v-if="item.courseContents.length > 3"
              :span="6"
              class="mt8 text-8c"
            >
              <div class="search-sub-item p12 search-sub-item-empty text-center hand hover-primary-6">
                <span class="v-mid" @click="toDetail(item)">{{ $t('pc_core_graphicmsg_checkdetail') }}</span>
                <yxtf-svg
                  class="ml4 v-mid"
                  width="16px"
                  height="16px"
                  icon-class="right"
                />
              </div>
            </yxtf-col>
          </yxtf-row>
        </template>
      </div>
      <div v-if="datas.paging.count > 3" class="yxtbiz-ai-robot__course-more color-gray-8" @click="toMore">
        {{ $t('pc_biz_lbl_common_viewmore') }}
        <yxt-svg :remote-url="`${$staticBaseUrl}ufd/55a3e0`" width="16px" height="16px" icon-class="arrow_right" />
      </div>
    </template>
  </div>
</template>

<script>
import EmptyData from '../common/empty-data.vue';
export default {
  name: 'SearchWeike',
  components: {
    EmptyData
  },
  filters: {
    getHMS(time) {
      let hour = parseInt(time / 3600, 10);
      if (hour < 10) {
        hour = '0' + hour;
      }
      let min = parseInt(time % 3600 / 60, 10);
      if (min < 10) {
        min = '0' + min;
      }
      let sec = parseInt(time % 3600 % 60, 10);
      if (sec < 10) {
        sec = '0' + sec;
      }
      return hour + ':' + min + ':' + sec;
    }
  },
  props: {
    datas: {
      type: Object,
      default: ()=>{
        return {datas: []};
      }
    },
    params: {
      type: Object,
      default: ()=>{}
    },
    size: {
      type: String,
      default: 'small',
      validator: val => ['small', 'large'].includes(val)
    }
  },
  data() {
    console.log(this.datas);
    return {};
  },
  computed: {
    dataList() {
      return this.datas.datas.slice(0, 3);
    }
  },
  methods: {
    toDetail(item) {
      window.open(`/tcm-room/#/?courseId=${item.id}&type=backend`);
    },
    toMore() {
      window.open(`/search/#/gs/microlecture?keyword=${this.params.searchKey}`);
    },
    goDetail(id, time, searchKey) {
      window.open(`/tcm-room/#/?courseId=${id}&type=front&searchStr=${searchKey}&targetTime=${time}`);
    }
  }
};
</script>
<style scoped lang="scss">
.yxtbiz-ai-robot__microlecture *{
  box-sizing: border-box;
}
.yxtbiz-ai-robot__microlecture{
  margin-bottom: 12px;
  padding: 0 12px;
}
.yxtbiz-ai-robot__coure{
  width: 100%;
}
.yxtbiz-ai-robot__coure-title{
  padding: 0px 12px 12px;
}
.summary {
  flex: 1;
  min-width: 0;
  font-size: 12px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #757575;
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.yxtbiz-ai-robot__course-contain{
  flex-grow: 1;
  overflow: hidden;
}
.yxtbiz-ai-robot__coure-cell {
  display: flex;
  overflow: hidden;
  align-items: center;
  border-radius: 8px;
  padding: 12px;
  cursor: pointer;
  &:hover {
    background-color: #f4f5f6;
  }
}
.yxt-divider {
  background-color: #d8d8d8;
}
.yxt-progress__text {
  font-size: 12px !important;
  line-height: 20px;

  &>span {
    font-size: 12px !important;
  }
}
.coure-flex-center {
  display: flex;
  align-items: center;
}
.yxtbiz-ai-robot__course-cover {
  width: 112px;
  height: 63px;
  border-radius: 4px;
  flex-shrink: 0;
  flex-grow: 0;
}
.yxtbiz-ai-robot__course-more {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: #f4f5f6;
  }
}
.search-sub-item {
  height: 56px;
  background-color: #F4F5F6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  &-text {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    min-width: 0;
  }
}
.search-sub-item-small {
  height: 36px;
  background-color: #F5F5F5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  &-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
    min-width: 0;
    word-wrap: normal;
    word-break: normal;
  }
  &-empty {
    line-height: 36px;
    height: 36px;
    justify-content: center;
  }
}
.search-sub-item-empty {
  line-height: 56px;
  height: 56px;
  justify-content: center;
}
</style>
