var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yxtbiz-sponsor-choose" },
    [
      _c(
        "yxt-drawer",
        _vm._b(
          {
            attrs: {
              title: _vm.$t("pc_biz_enroll_tit_selectaudit").d("选择审核人"),
              size: "480px",
              visible: _vm.isShowModal,
              "before-close": _vm.closeDrawer,
            },
            on: {
              "update:visible": function ($event) {
                _vm.isShowModal = $event
              },
            },
          },
          "yxt-drawer",
          _vm.modal.options,
          false
        ),
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "color-gray-10 modal-content-title" }, [
              _vm._v(
                _vm._s(_vm.$t("pc_biz_common_tit_all_flow").d("审核全流程"))
              ),
            ]),
            _c(
              "div",
              { staticClass: "modal-content-flow" },
              [
                _c("div", { staticClass: "flow-item" }, [
                  _c("div", { staticClass: "left" }, [
                    _c("div", { staticClass: "icon" }),
                    _c("div", { staticClass: "line" }),
                  ]),
                  _c("div", { staticClass: "right" }, [
                    _c("div", { staticClass: "right-content" }, [
                      _c("span", { staticClass: "color-gray-10 flow-name" }, [
                        _vm._v(
                          _vm._s(_vm.$t("pc_biz_common_tip_start").d("发起"))
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._l(_vm.nodeList, function (item, index) {
                  return _c("div", { key: index }, [
                    _vm.getAuditStatus(item)
                      ? _c("div", { staticClass: "flow-item" }, [
                          _c("div", { staticClass: "left" }, [
                            _c("div", { staticClass: "icon" }),
                            _c("div", { staticClass: "line" }),
                          ]),
                          _c("div", { staticClass: "right" }, [
                            _c("div", { staticClass: "right-content" }, [
                              _c(
                                "div",
                                { staticClass: "flow-name color-gray-7" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm
                                        .$t("pc_biz_common_tit_audit")
                                        .d("审核")
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "flow-person color-gray-9" },
                                [_vm._v(_vm._s(_vm.getAuditName(item)))]
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    item.auditPersType === 4
                      ? _c("div", { staticClass: "flow-item" }, [
                          _c("div", { staticClass: "left" }, [
                            _c("div", { staticClass: "icon-choose" }, [
                              _c("div", { staticClass: "icon-inner" }),
                            ]),
                            _c("div", { staticClass: "line" }),
                          ]),
                          _c("div", { staticClass: "right" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "right-content right-content-choose",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "right-content-choose-inner bg-gray-1",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flow-name color-gray-7 flow-name-choose",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm
                                              .$t("pc_biz_common_tit_audit")
                                              .d("审核")
                                          ) + "\n                  "
                                        ),
                                      ]
                                    ),
                                    !item.selectuser || !item.selectuser.length
                                      ? _c(
                                          "yxt-button",
                                          {
                                            attrs: { type: "second" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openUserDialog(item)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm
                                                  .$t(
                                                    "pc_biz_common__select_user"
                                                  )
                                                  .d("选择人员")
                                              ) + "\n                  "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    item.selectuser && item.selectuser.length
                                      ? _c(
                                          "yxt-button",
                                          {
                                            attrs: { type: "second" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openUserDialog(item)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm
                                                  .$t(
                                                    "pc_biz_common_re_select_user"
                                                  )
                                                  .d("重新选择")
                                              ) + "\n                  "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      { staticClass: "flow-select-user" },
                                      _vm._l(
                                        item.selectuser,
                                        function (innerItem) {
                                          return _c(
                                            "yxt-tag",
                                            {
                                              key: innerItem.id,
                                              staticClass:
                                                "flow-select-user-item mb8",
                                              attrs: {
                                                closable: "",
                                                type: "info",
                                              },
                                              on: {
                                                close: function ($event) {
                                                  return _vm.closeSelectItem(
                                                    item,
                                                    innerItem.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm.isOpenData
                                                ? _c("yxtbiz-user-name", {
                                                    attrs: {
                                                      name: innerItem.fullname,
                                                    },
                                                  })
                                                : _c("span", [
                                                    _vm._v(
                                                      _vm._s(innerItem.fullname)
                                                    ),
                                                  ]),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    item.auditPersType === 3
                      ? _c("div", { key: item.id, staticClass: "flow-item" }, [
                          _c("div", { staticClass: "left" }, [
                            _c("div", { staticClass: "icon" }, [
                              _c("div", { staticClass: "icon-inner" }),
                            ]),
                            _c("div", { staticClass: "line" }),
                          ]),
                          _c("div", { staticClass: "right" }, [
                            _c("div", { staticClass: "right-content" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flow-name color-gray-7 flow-name-choose",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm
                                        .$t("pc_biz_common_tit_audit")
                                        .d("审核")
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "person-list" },
                                [
                                  _vm._l(item.appters, function (innerItem) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          key: innerItem.userId,
                                          staticClass: "person-list-item",
                                        },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _c("yxtf-portrait", {
                                                staticClass: "user-icon",
                                                attrs: {
                                                  size: "small",
                                                  username: innerItem.userName,
                                                  imgUrl: innerItem.pic,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm.isOpenData
                                            ? _c("yxtbiz-user-name", {
                                                staticClass:
                                                  "user-name color-gray-9",
                                                attrs: {
                                                  name: innerItem.fullName,
                                                },
                                              })
                                            : _c("span", [
                                                _vm._v(
                                                  _vm._s(innerItem.fullName)
                                                ),
                                              ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  }),
                                ],
                                2
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ])
                }),
                _c("div", { staticClass: "flow-item" }, [
                  _c("div", { staticClass: "left" }, [
                    _c("div", { staticClass: "icon" }),
                  ]),
                  _c("div", { staticClass: "right" }, [
                    _c("div", { staticClass: "right-content" }, [
                      _c("span", { staticClass: "color-gray-10 flow-name" }, [
                        _vm._v(
                          _vm._s(_vm.$t("pc_biz_common_tip_end").d("结束"))
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ],
              2
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("yxtf-button", { on: { click: _vm.closeDialog } }, [
                _vm._v(_vm._s(_vm.$t("pc_biz_common_cancel_mini").d("取消"))),
              ]),
              _c(
                "yxtf-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirmModal } },
                [_vm._v(_vm._s(_vm.$t("pc_biz_common_btn_sure").d("确定")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "yxt-drawer",
        {
          attrs: { title: "", visible: _vm.isShowUserDialog, size: "960px" },
          on: {
            "update:visible": function ($event) {
              _vm.isShowUserDialog = $event
            },
          },
        },
        [
          _vm.isShowUserDialog
            ? _c("custom-user-selector", {
                ref: "selector",
                attrs: {
                  selected: _vm.tableUsers,
                  customNodeId: _vm.customNodeId,
                  multiple: _vm.multiple,
                },
              })
            : _vm._e(),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "yxt-button",
                { attrs: { plain: "" }, on: { click: _vm.closeUserDialog } },
                [_vm._v(_vm._s(_vm.$t("pc_biz_common_cancel_mini").d("取消")))]
              ),
              _c(
                "yxt-button",
                { attrs: { type: "primary" }, on: { click: _vm.getUsers } },
                [_vm._v(_vm._s(_vm.$t("pc_biz_common_btn_sure").d("确定")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }