var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yxtbiz-ai-robot__input-magic" },
    [
      _c(
        "div",
        { staticClass: "yxtbiz-ai-robot__input-magic__wrap" },
        [
          _c(
            "yxt-dropdown",
            {
              ref: "dropRefs",
              staticClass: "yxtbiz-ai-robot__wrap--magic",
              attrs: {
                loading: _vm.loading,
                placement: "top-start",
                trigger: "click",
              },
              on: {
                "visible-change": _vm.visibleChange,
                command: (item) => _vm.$emit("change", item),
              },
            },
            [
              _c(
                "div",
                { staticClass: "yxtbiz-ai-robot__input-magic--common hand" },
                [
                  _c("yxt-svg", {
                    attrs: {
                      "remote-url": _vm.svgRemoteUrl,
                      width: 16,
                      height: 16,
                      "icon-class": "common-stick",
                    },
                  }),
                  _c("span", { staticClass: "ml4" }, [
                    _vm._v(
                      _vm._s(_vm.$t("pc_biz_lbl_common_stick").d("常用应用"))
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "yxt-dropdown-menu",
                {
                  staticClass: "yxtbiz-ai-robot__input-dropdown",
                  class: {
                    "yxtbiz-ai-robot__input-dropdown--large":
                      _vm.size === "large",
                  },
                  attrs: { slot: "dropdown" },
                  slot: "dropdown",
                },
                [
                  !_vm.info.length
                    ? _c("yxt-empty", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "yxtbiz-ai-robot__input-dropdown--empty",
                            attrs: { slot: "image" },
                            slot: "image",
                          },
                          [
                            _c("img", {
                              attrs: {
                                src:
                                  _vm.$staticBaseUrl +
                                  "/ufd/55a3e0/img/ai-empty-app.jpg",
                              },
                            }),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "mt12",
                            attrs: { slot: "text" },
                            slot: "text",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm
                                  .$t("pc_biz_msg_empty_common_app")
                                  .d("暂无常用应用")
                              )
                            ),
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm
                                    .$t("pc_biz_msg_empty_common_app1")
                                    .d("可前往百宝箱添加")
                                )
                              ),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._l(_vm.info, function (item) {
                        return _c(
                          "yxt-dropdown-item",
                          { key: item.id, attrs: { command: item } },
                          [
                            _c("yxt-image", {
                              staticClass: "yxtbiz-ai-robot__wrap--img",
                              attrs: {
                                src:
                                  item.icon ||
                                  _vm.$staticBaseUrl +
                                    "ufd/55a3e0/ai-select.png",
                              },
                            }),
                            _c(
                              "span",
                              { staticClass: "text-26 font-size-16 v-mid" },
                              [_vm._v("\n            " + _vm._s(item.appName))]
                            ),
                          ],
                          1
                        )
                      }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "yxtbiz-ai-robot__input-magic--common ml12 hand",
              on: { click: _vm.handleOpenTreasure },
            },
            [
              _c("yxt-svg", {
                attrs: {
                  "remote-url": _vm.svgRemoteUrl,
                  width: 16,
                  height: 16,
                  "icon-class": "chest-stick",
                },
              }),
              _c("span", { staticClass: "ml4" }, [
                _vm._v(_vm._s(_vm.$t("pc_biz_lbl_treasure_chest").d("百宝箱"))),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("Treasure-dialog", {
        attrs: { visible: _vm.visible, size: _vm.size },
        on: {
          "update:visible": function ($event) {
            _vm.visible = $event
          },
          operation: _vm.handleOperation,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }