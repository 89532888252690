// range-selector 属性参数
export default {
  props: {
    multiple: {
      // 是否多选
      type: Boolean,
      default: true
    },
    limit: {
      // 限制数量，0为不限制
      type: Number,
      default: 0
    },
    showTab: {
      // 单个tab页是是否显示tab
      type: Boolean,
      default: false
    },
    tabs: {
      // 组件需要显示的tab
      type: Array,
      default: () => ['persons']
    },
    functionCode: String, // 功能点code
    dataPermissionCode: String, // 数据权限code
    enableGroupCorp: {
      type: Boolean,
      default: false
    }, // 是否是集团版
    isOrgSelectAlone: {
      type: Boolean,
      default: false
    },
    visibleOrgSelector: {
      type: [String, Boolean],
      default: 'show'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    targetOrgId: '', // 指定机构id
    disabledOrgIds: {
      type: Array,
      default: () => []
    },
    visibleOrgIds: {
      type: Array,
      default: () => []
    }
  }
};
