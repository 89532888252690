var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loading
    ? _c("div", {
        directives: [
          {
            name: "floading",
            rawName: "v-floading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "yxtbiz-nav-top-stu",
      })
    : !!_vm.customCode
    ? _c("code-viewer", {
        staticClass: "yxtbiz-nav-top-stu",
        attrs: { source: _vm.customCode },
      })
    : _c(
        "div",
        {
          staticClass: "yxtbiz-nav-top-stu",
          class: { "yxtbiz-nav-top-stu--preview": _vm.previewMode },
        },
        [
          _c(
            "yxt-sticky",
            {
              attrs: { resizable: "", autoSize: "", allowance: 1 },
              on: { change: _vm.headerFixChange },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "div",
                    {
                      staticClass: "yxtbiz-nav-top-stu__main",
                      class: { "yxtbiz-nav-top-stu__main--fixed": _vm.isFixed },
                    },
                    [
                      _c(
                        "div",
                        { on: { click: _vm.goStuIndex } },
                        [
                          _c("a-link", {
                            staticClass: "yxtbiz-nav-top-stu__logo hand",
                            style: `background-image: url(${_vm.logo || ""})`,
                            attrs: {
                              url: `${_vm.defaultProductPath}/#/studyindex?norequest=1`,
                              mode: "flex",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "yxtbiz-nav-top-stu__menus",
                          attrs: { id: _vm.navMenusAllId },
                        },
                        [
                          _c("div", {
                            staticClass:
                              "yxtbiz-nav-top-stu__menu-bar bg-primary-6",
                            class: {
                              "yxtbiz-nav-top-stu__menu-bar--hide":
                                !_vm.menuBarWidth,
                            },
                            style: {
                              width: `${_vm.menuBarWidth}px`,
                              transform: `translateX(${_vm.menuBarLeft}px)`,
                            },
                          }),
                          _vm._l(_vm.navsOnMenu, function (menu, idx) {
                            return [
                              menu.isDecorate && _vm.pcPageIndexToggle === 1
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "yxtbiz-nav-top-stu__menu hover-primary-6",
                                      class: {
                                        "yxtbiz-nav-top-stu__menu--active":
                                          menu.checked &&
                                          menu.mainObj.code ===
                                            _vm.pageCodes[0],
                                      },
                                      attrs: { "data-code": menu.code },
                                      on: {
                                        click: () =>
                                          _vm.linkTo(menu, menu.mainObj),
                                      },
                                    },
                                    [
                                      _c(
                                        "yxtf-tooltip",
                                        {
                                          attrs: {
                                            "open-filter": true,
                                            content: menu.name,
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _c(
                                            "a-link",
                                            {
                                              staticClass:
                                                "yxtbiz-nav-top-stu__menu-name decorate-menu-nav-name",
                                              attrs: {
                                                url: menu.pageUrl,
                                                "page-node": menu,
                                              },
                                              on: {
                                                mouseenter: () =>
                                                  _vm.setMenuBarIndex(
                                                    menu.code,
                                                    true,
                                                    true
                                                  ),
                                                mouseover: () =>
                                                  _vm.setMenuBarIndex(
                                                    menu.code,
                                                    false,
                                                    true
                                                  ),
                                                mouseleave: () =>
                                                  _vm.setMenuBarIndex(
                                                    _vm.pageCodes[0],
                                                    false,
                                                    true
                                                  ),
                                              },
                                            },
                                            [_vm._v(_vm._s(menu.name))]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.menuSet.max >= idx + 1,
                                          expression: "menuSet.max >= idx + 1",
                                        },
                                      ],
                                      key: menu.id,
                                      staticClass:
                                        "yxtbiz-nav-top-stu__menu hover-primary-6",
                                      class: {
                                        "yxtbiz-nav-top-stu__menu--active":
                                          menu.code === _vm.pageCodes[0],
                                      },
                                      attrs: { "data-code": menu.code },
                                      on: { click: () => _vm.toSubPage(menu) },
                                    },
                                    [
                                      menu.code === "main_index" &&
                                      _vm.pcPageIndexToggle === 0 &&
                                      _vm.rangeTemplatesArr.length > 1
                                        ? _c(
                                            "yxtf-dropdown",
                                            {
                                              attrs: {
                                                placement: "bottom-start",
                                                "custom-elm": "",
                                                trigger: "hover",
                                              },
                                              on: {
                                                "visible-change": function (
                                                  $event
                                                ) {
                                                  _vm.isShowIndex =
                                                    !_vm.isShowIndex
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "a-link",
                                                {
                                                  staticClass:
                                                    "yxtbiz-nav-top-stu__menu-name hover-primary-6-i",
                                                  attrs: {
                                                    mode: "flex",
                                                    url: menu.pageUrl,
                                                    "page-node": menu,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(menu.name) +
                                                      "\n                "
                                                  ),
                                                  _c("yxt-svg", {
                                                    attrs: {
                                                      width: "12px",
                                                      height: "12px",
                                                      "icon-class":
                                                        !_vm.isShowIndex
                                                          ? "arrow-down"
                                                          : "arrow-up",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "yxtf-dropdown-menu",
                                                {
                                                  staticClass:
                                                    "yxtbiz-nav-top-stu__sub-menus yxtbiz-nav-top-stu__sub-menus--more yxtbiz-nav-top-stu_sub-menus--ul",
                                                  attrs: { slot: "dropdown" },
                                                  slot: "dropdown",
                                                },
                                                _vm._l(
                                                  _vm.rangeTemplatesArr,
                                                  function (item) {
                                                    return _c(
                                                      "yxtf-dropdown-item",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value: item,
                                                            expression: "item",
                                                          },
                                                        ],
                                                        key: item.id,
                                                        nativeOn: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.linkTo(
                                                              item,
                                                              menu
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "a-link",
                                                          {
                                                            class: [
                                                              "yxtbiz-nav-top-stu__pop-menu",
                                                              item.checked === 1
                                                                ? "yxtbiz-nav-top-stu__pop-menu--checked"
                                                                : "",
                                                            ],
                                                            attrs: {
                                                              url: menu.pageUrl,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.name)
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "a-link",
                                            {
                                              staticClass:
                                                "yxtbiz-nav-top-stu__menu-name",
                                              class: [
                                                "yxtbiz-nav-top-stu__menu-name",
                                                menu.code === "main_index"
                                                  ? "yxtbiz-nav-top-stu__menu-name--more"
                                                  : "",
                                              ],
                                              attrs: {
                                                mode: "flex",
                                                url: menu.pageUrl,
                                                "page-node": menu,
                                              },
                                              on: {
                                                mouseenter: () =>
                                                  _vm.setMenuBarIndex(
                                                    menu.code,
                                                    true,
                                                    true
                                                  ),
                                                mouseover: () =>
                                                  _vm.setMenuBarIndex(
                                                    menu.code,
                                                    false,
                                                    true
                                                  ),
                                                mouseleave: () =>
                                                  _vm.setMenuBarIndex(
                                                    _vm.pageCodes[0],
                                                    false,
                                                    true
                                                  ),
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("Globalize")(
                                                    menu,
                                                    "name"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                    ],
                                    1
                                  ),
                            ]
                          }),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.menuSet.more,
                                  expression: "menuSet.more",
                                },
                              ],
                              staticClass: "yxtbiz-nav-top-stu__menu",
                              attrs: { id: _vm.navMenuMoreId },
                            },
                            [
                              _c(
                                "yxtf-dropdown",
                                {
                                  attrs: {
                                    placement: "bottom-start",
                                    "custom-elm": "",
                                    trigger: "hover",
                                  },
                                  on: {
                                    "visible-change": function ($event) {
                                      _vm.isShowMore = !_vm.isShowMore
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "yxtbiz-nav-top-stu__menu-name hover-primary-6-i",
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t("pc_biz_nav_btn_more")
                                          ) +
                                          "\n                "
                                      ),
                                      _c("yxt-svg", {
                                        attrs: {
                                          width: "12px",
                                          height: "12px",
                                          "icon-class": !_vm.isShowMore
                                            ? "arrow-down"
                                            : "arrow-up",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "yxtf-dropdown-menu",
                                    {
                                      staticClass:
                                        "yxtbiz-nav-top-stu__sub-menus yxtbiz-nav-top-stu__sub-menus--more",
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown",
                                    },
                                    [
                                      _vm._l(
                                        _vm.navsOnMenu,
                                        function (sMenu, sIndex) {
                                          return [
                                            sIndex + 1 > _vm.menuSet.max
                                              ? _c(
                                                  "yxtf-dropdown-item",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: sMenu.showed,
                                                        expression:
                                                          "sMenu.showed",
                                                      },
                                                    ],
                                                    key: sMenu.id,
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        return (() =>
                                                          _vm.toSubPage(
                                                            sMenu
                                                          )).apply(
                                                          null,
                                                          arguments
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "a-link",
                                                      {
                                                        staticClass:
                                                          "yxtbiz-nav-top-stu__pop-menu",
                                                        attrs: {
                                                          url: sMenu.pageUrl,
                                                          "page-node": sMenu,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f("Globalize")(
                                                              sMenu,
                                                              "name"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                      !_vm.previewMode
                        ? _c(
                            "div",
                            { staticClass: "yxtbiz-nav-top-stu__my-panel" },
                            [
                              _c("nav-production-enter", {
                                attrs: { isStu: true },
                              }),
                              _c("nav-ai-helper", {
                                attrs: { isXxv2: _vm.isXxv2, isStu: true },
                              }),
                              !_vm.EDITION_TOOL && _vm.isXxv2
                                ? _c(
                                    "yxtf-tooltip",
                                    {
                                      attrs: {
                                        content: _vm
                                          .$t("pc_biz_nav_tip_searchfor")
                                          .d("搜索"),
                                      },
                                    },
                                    [
                                      _c("yxt-svg", {
                                        staticClass:
                                          "yxtbiz-nav-top-stu__my-panel-item hover-primary-6-i color-gray-9",
                                        attrs: {
                                          width: "24px",
                                          height: "24px",
                                          "remote-url": _vm.strStcOssCorePcSvg,
                                          "icon-class": "nav-search",
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            ;(_vm.isSearching = true),
                                              (_vm.keyword = "")
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.isOpenIM && _vm.showIm
                                ? _c(
                                    "yxtf-tooltip",
                                    {
                                      attrs: {
                                        content: _vm
                                          .$t("biz_message_nav_title")
                                          .d("消息中心"),
                                      },
                                    },
                                    [
                                      _vm.$slots.imbell
                                        ? _vm._t("imbell")
                                        : _vm._e(),
                                      _c("yxt-im-bell", {
                                        staticClass:
                                          "yxtbiz-nav-top-stu__my-panel-item hover-primary-6-i color-gray-9",
                                        staticStyle: { height: "24px" },
                                      }),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _c(
                                "yxtf-dropdown",
                                {
                                  staticClass:
                                    "yxtbiz-nav-top-stu__my-panel-item",
                                  attrs: {
                                    trigger: "hover",
                                    "custom-elm": "",
                                    "hide-on-click": false,
                                  },
                                },
                                [
                                  _c(
                                    "yxtf-portrait",
                                    {
                                      staticClass:
                                        "hand yxtbiz-nav-top-stu__my-avatar",
                                      attrs: {
                                        shape: "circle",
                                        size: "small",
                                        username: _vm.userInfo.userShortName,
                                        imgUrl: _vm.userInfo.headPictureUrl,
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: { src: _vm.strAvatar },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "yxtf-dropdown-menu",
                                    {
                                      staticClass:
                                        "yxtbiz-nav-top-stu__sub-menus yxtbiz-nav-top-stu__sub-menus--my",
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown",
                                    },
                                    [
                                      _c("nav-org-list-stu", {
                                        attrs: {
                                          className:
                                            "yxtbiz-nav-top-stu__pop-menu color-gray-9",
                                        },
                                      }),
                                      !_vm.hideUsercenterBtn
                                        ? _c(
                                            "yxtf-dropdown-item",
                                            {
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.goPersonCenter.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "yxtbiz-nav-top-stu__pop-menu",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "pc_biz_nav_btn_usercenter"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.$attrs.isOpenI18n
                                        ? _c(
                                            "yxtf-dropdown-item",
                                            [
                                              _c(
                                                "yxtbiz-nav-lang",
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      isStu: true,
                                                      trigger: "hover",
                                                      placement: "left",
                                                      "append-to-body": false,
                                                    },
                                                  },
                                                  "yxtbiz-nav-lang",
                                                  _vm.$attrs,
                                                  false
                                                ),
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "yxtbiz-nav-top-stu__pop-menu color-gray-9",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "pc_biz_core_showlanguage"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.schedules.isManage
                                        ? _c(
                                            "yxtf-dropdown-item",
                                            {
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.goManageCenter.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "yxtbiz-nav-top-stu__pop-menu",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "pc_biz_nav_btn_gomgmt"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      !_vm.isISV && !_vm.isHideLogout
                                        ? _c(
                                            "yxtf-dropdown-item",
                                            {
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.logout.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "yxtbiz-nav-top-stu__pop-menu",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "pc_biz_nav_btn_logout"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("work-bench", {
                                staticClass: "hover-primary-6-i color-gray-9",
                                attrs: {
                                  mainPathname: _vm.mainPathname,
                                  isStu: true,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                  !_vm.previewMode
                    ? [
                        [
                          _vm.$slots.announcement
                            ? _vm._t("announcement")
                            : _vm.isOpenIM
                            ? _c("yxt-im-announcement", {
                                attrs: { showLamp: !_vm.osmShow },
                              })
                            : _vm._e(),
                        ],
                        _vm.OSMEnable !== 0
                          ? _c("stop-notice", {
                              attrs: { isManager: false },
                              on: { show: (s) => (_vm.osmShow = s) },
                            })
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "yxtbiz-nav-top-stu__searching" },
            [
              _c("yxt-collapse-transition", [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isSearching,
                        expression: "isSearching",
                      },
                    ],
                    staticClass: "yxtbiz-nav-top-stu__searching-container",
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isSearching,
                            expression: "isSearching",
                          },
                        ],
                        staticClass: "yxtbiz-nav-top-stu__searching-content",
                      },
                      [
                        _c("yxt-svg", {
                          staticClass:
                            "hand hover-primary-6-i color-gray-9 yxtbiz-nav-top-stu__search-icon",
                          attrs: {
                            width: "24px",
                            height: "24px",
                            "remote-url": _vm.strStcOssCorePcSvg,
                            "icon-class": "nav-search",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return (() => _vm.goSearch()).apply(
                                null,
                                arguments
                              )
                            },
                          },
                        }),
                        _vm.isSearching
                          ? _c("yxtbiz-search", {
                              ref: "defaultSearch",
                              staticClass: "yxtbiz-nav-top-stu__search",
                              attrs: {
                                popperClass:
                                  "yxtbiz-nav-top-stu__search-popper",
                                searchable: false,
                                hotOnly: "",
                                placeholder: _vm.searchPlaceholder,
                              },
                              on: { selected: _vm.goSearch },
                              model: {
                                value: _vm.keyword,
                                callback: function ($$v) {
                                  _vm.keyword = $$v
                                },
                                expression: "keyword",
                              },
                            })
                          : _vm._e(),
                        _c("yxt-svg", {
                          staticClass:
                            "color-gray-9 hover-primary-6-i yxtbiz-nav-top-stu__search-close hand",
                          attrs: {
                            width: "20px",
                            height: "20px",
                            "icon-class": "close",
                          },
                          nativeOn: {
                            click: function ($event) {
                              _vm.isSearching = false
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
              _c(
                "transition",
                { attrs: { name: "yxtbiz-nav-top-stu__searching-fade-in" } },
                [
                  _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isSearching,
                        expression: "isSearching",
                      },
                    ],
                    staticClass: "yxtbiz-nav-top-stu__searching-modal",
                    on: {
                      click: function ($event) {
                        _vm.isSearching = false
                      },
                    },
                  }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }