let dragEnable = false

export function setDragEnable(v) {
    dragEnable = v
}

export function dragMove(container, target, callback) {
    const barHeight = 40
    let isMoving = false
    const startPoint = {
        x: 0,
        y: 0
    }
    if(!container || !target) return
    if(target) {
        target.addEventListener('mousedown', function(e) {
            if(!dragEnable) return
            isMoving = true
            startPoint.x = e.clientX - target.offsetLeft
            startPoint.y = e.clientY - target.offsetTop
            
            const offsetParent = target.offsetParent
            const offsetRect = offsetParent.getBoundingClientRect()
            const targetRect = target.getBoundingClientRect()
            target.style.left = `${targetRect.left - offsetRect.left}px`
            target.style.top = `${targetRect.top - offsetRect.top}px`
            target.style.bottom = ''
        })

        document.addEventListener('mousemove', function(e) {
            if(!dragEnable) return
            if(!isMoving) return
            let left, top
            let savedX = null
            let savedTop = null
            left = e.clientX - startPoint.x
            top = e.clientY - startPoint.y
            target.style.left = `${left}px`
            target.style.top = `${top}px`
            savedX = left

            if(left >= container.offsetWidth - target.offsetWidth) {
                savedX = container.offsetWidth - target.offsetWidth
                target.style.left = `${savedX}px`
                 
            }
            if(top >= container.offsetHeight - target.offsetHeight - barHeight) {
                savedTop = container.offsetHeight- target.offsetHeight - barHeight
                target.style.top = `${savedTop}px`
            }

            if(left <= 0) {
                savedX = 0
                target.style.left = '0px'
            }

            if(top <= 0) {
                savedTop = 0
                target.style.top = '0px'
            }

            if(callback && callback instanceof Function) {
                callback({
                    centerX: savedX + target.offsetWidth / 2,
                    savedTop
                })
            }
        })

        document.addEventListener('mouseup', function(e) {
            if(!dragEnable) return
            isMoving = false
        })
    }
}
