<template>
  <div class="yxtbiz-upload-image">
    <ul v-if="!simple">
      <li v-for="(item, index) in imglist" :style="liStyle" :key="index" class="yxtbiz-upload-image__list" @click="onPreview(index)">
        <yxt-image
          :src="item.url"
          fit="contain"
        ></yxt-image>
        <span v-if="!noOperation" class="yxtbiz-upload-image__list-operation">
          <i @click.stop="onDelete(index)" class="yxt-icon-close"></i>
          <i v-if="isNeedUpload" @click.stop="onEdit(index)" class="yxt-icon-refresh-left"></i>
          <span v-if="setMain" @click.stop="changeMainIndex(index)" class="yxtbiz-upload-image__list-main">{{index==0?$t('pc_biz_uploadimg_tip_main'/* 主图 */):$t('pc_biz_uploadimg_btn_main'/* 设为主图 */)}}</span>
        </span>
      </li>
      <li v-show="showUploadBtn" :style="liStyle" class="yxtbiz-upload-image__picture-card" @click="onCreate">
        <i class="yxt-icon-plus"></i>
      </li>
    </ul>
    <yxtbiz-upload
      v-if="simple || (isNeedUpload && noCrop)"
      config-key="ImageConfigKey"
      :appCode="appCode"
      :source="source"
      :module-name="moduleName"
      :function-name="functionName"
      :filters="filters"
      :max-size="size"
      :multipe="multipe && !simple"
      :files-added="onFileAdded"
      :file-filtered="onFileAdded"
      :before-upload="onBeforeUpload"
      :on-uploaded="onUploaded"
      :on-progress="onProgress"
      :isV1="isV1"
    >
      <yxt-button plain ref="bizUpload" v-show="simple">{{$t('pc_biz_uploadimg_btn_upload'/* 上传附件 */)}}</yxt-button>
    </yxtbiz-upload>
    <ul v-if="simple">
      <li v-for="(item, index) in imglist" :key="index" class="yxtbiz-upload-image__simple">
        <span class="yxtbiz-upload-image__simple-title" @click="onPreview(index)">
          <i class="yxt-icon-paperclip"></i>
          <span :title="item.name">{{item.name}}</span>
          <i @click.stop="onDelete(index)" class="yxt-icon-close"></i>
        </span>
        <yxt-progress v-if="item.percentage != 'ok'" :percentage="item.percentage"></yxt-progress>
      </li>
    </ul>
    <yxtbiz-image-viewer v-if="!simple && !noPreview" ref="imageViewer" no-list :data="imglist"></yxtbiz-image-viewer>
    <yxtbiz-image-cropper
      v-if="isNeedUpload && !noCrop"
      :src="src"
      :app-code="appCode"
      :source="source"
      :module-name="moduleName"
      :function-name="functionName"
      :size="size"
      :rate="rate"
      :filters="filters"
      :suggest="suggest"
      :visible.sync="show"
      :isV1="isV1"
      :oversizeMsg="oversizeMsg"
      :filterMsg="filterMsg"
      @complete="complete">
    </yxtbiz-image-cropper>
  </div>
</template>

<script>
import YxtbizImageCropper from 'yxt-biz-pc/packages/image-cropper';
import YxtbizImageViewer from 'yxt-biz-pc/packages/image-viewer';
import YxtbizUpload from 'yxt-biz-pc/packages/upload';
import {
  Progress,
  Image
} from 'yxt-pc';
export default {
  name: 'YxtbizUploadImage',
  components: {
    YxtbizImageCropper,
    YxtbizImageViewer,
    YxtbizUpload,
    YxtProgress: Progress,
    YxtImage: Image
  },
  props: {
    target: {
      type: String || Object,
      default: ''
    },
    simple: Boolean,
    source: {
      type: String,
      default: '501'
    },
    appCode: {
      type: String,
      default: 'demo'
    },
    moduleName: {
      type: String,
      default: 'test_module'
    },
    functionName: {
      type: String,
      default: 'test_function'
    },
    suggest: {
      type: Object,
      default() {
        return {
          w: 750,
          h: 348
        };
      }
    },
    rate: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 512
    },
    filters: {
      type: String,
      default: '.jpg,.gif,.png,.jpeg,.bmp,.svg'
    },
    list: {
      type: Array,
      default: () => []
    },
    limit: {
      type: Number,
      default: 1
    },
    noUpload: {
      type: Boolean,
      default: false
    },
    noPreview: {
      type: Boolean,
      default: false
    },
    noCrop: {
      type: Boolean,
      default: false
    },
    noOperation: {
      type: Boolean,
      default: false
    },
    setMain: Boolean,
    multipe: Boolean,
    width: {
      type: Number | String,
      default: 120
    },
    height: {
      type: Number | String,
      default: 120
    },
    oversizeMsg: String,
    filterMsg: String,
    limitMsg: String,
    isV1: Boolean
  },
  data() {
    return {
      isNeedUpload: !this.noUpload,
      imglist: [],
      editIndex: 0,
      src: '',
      show: false,
      mainIndex: 0,
      showUploadBtn: true
    };
  },
  mounted() {
    this.uploadings = {}; // 上传中的图片
    this.imglist = [...this.list.slice(0, this.maxLimit)];
    this.checkUploadBtn();
  },
  watch: {
    list(val) {
      this.imglist = [...val.slice(0, this.maxLimit)];
      this.checkUploadBtn();
    }
  },
  computed: {
    maxLimit() {
      return this.limit < 10 ? this.limit : 10;
    },
    liStyle() {
      return `width:${this.width}px;height:${this.height}px`;
    }
  },
  methods: {
    checkUploadBtn() {
      this.showUploadBtn = this.isNeedUpload && this.imglist.length < this.maxLimit;
    },
    changeMainIndex(index) {
      if (index > 0) {
        this.imglist.unshift(this.imglist[index]);
        this.imglist.splice(index + 1, 1);
        this.$emit('update', this.imglist, this.target);
      }
    },
    onCreate() {
      if (this.loading) return;
      this.editIndex = this.multipe ? -1 : this.imglist.length;
      if (this.noCrop) {
        this.$refs.bizUpload.$el.click();
      } else {
        this.src = '';
        this.show = true;
      }
    },
    onEdit(index) {
      if (this.loading) return;
      this.editIndex = index;
      if (this.noCrop) {
        this.$refs.bizUpload.$el.click();
      } else {
        this.src = this.imglist[index].url;
        this.show = true;
      }
    },
    onDelete(index) {
      this.imglist.splice(index, 1);
      this.$emit('fileRemoved', index, this.target);
      !this.simple && this.checkUploadBtn();
    },
    complete(src, file) {
      if (file) {
        file.fullUrl = src;
        this.onUploaded(file);
      }
    },
    onPreview(index) {
      !this.simple && !this.noPreview && this.$refs.imageViewer.view(index);
    },
    onBeforeUpload(file) {
      if (Object.keys(this.uploadings).length === 0) {
        // 多选情况下 新的上传
        this.$emit('uploadStart', this.target);
      }
      this.uploadings[file.uuid] = 'start';
    },
    onUploaded(file) {
      delete this.uploadings[file.uuid];
      this.loading = false;
      if (this.simple) {
        const index = this.imglist.findIndex(v => v.id === file.id);
        index > -1 && (this.imglist[index].url = file.fullUrl);
        this.$emit('fileAdded', { index, url: file.fullUrl, file }, this.target);
      } else {
        if (this.editIndex === -1) {
          if (!this.showUploadBtn) return;
          this.editIndex = this.imglist.length;
          this.imglist.push({ url: file.fullUrl, id: file.id, name: file.name });
        } else {
          this.$set(this.imglist, this.editIndex, { url: file.fullUrl, id: file.id, name: file.name });
        }
        this.$emit('fileAdded', { index: this.editIndex, url: file.fullUrl, file }, this.target);
        this.editIndex++;
        this.checkUploadBtn();
      }
      if (Object.keys(this.uploadings).length === 0) {
        // 队列文件都上传完成
        this.$emit('uploadComplete', this.target);
      }
    },
    onFileAdded(files) {
      const checkError = file => {
        if (file.error === 'oversize') {
          this.$message.error(this.oversizeMsg || this.$t('pc_biz_imgcropper_msg_oversize'/* 您选择的内容大小已超过允许的大小，请确认! */));
          file.abort();
          return false;
        } else if (file.error === 'forbidden') {
          this.$message.error(this.filterMsg || this.$t('pc_biz_imgcropper_msg_type'/* 您选择的内容格式不符合，请确认! */));
          file.abort();
          return false;
        } else {
          this.loading = true;
          return true;
        }
      };
      if (files instanceof Array) {
        if ((this.editIndex === -1 ? files.length : files.length - 1) + this.imglist.length > this.limit) {
          this.$message.error(this.limitMsg || this.$t('pc_biz_uploadimg_msg_overcount'/* 超出数量限制，请重新选择！! */));
          files.forEach(v => v.abort());
          return;
        }
        for (let file of files) {
          checkError(file);
        }
      } else if (checkError(files)) {
        this.simple && this.imglist.push({
          id: files.id,
          name: files.name,
          percentage: 0
        });
      }
    },
    onProgress(file, progress, event) {
      const item = this.imglist.find(v => v.id === file.id);
      if (item) {
        progress = progress.toFixed(2) * 100;
        item.percentage = progress;
        if (progress === 100) {
          setTimeout(() => {
            item.percentage = 'ok';
          }, 1000);
        }
      }
    }
  }
};
</script>
