<!-- 左侧资源选择 -->
<template>
  <div class="yxtbiz-group-source-selector-body-source">
    <!-- 课程分类树结构 -->
    <yxtbiz-select-kng-catelog-source 
      ref="catelogTree"
      :check-strictly="true"
      v-if="sourceType === '4'"   
      :checkLists.sync="cateList"
      :disabledList="disabledIds"
      @node-change="checkCatelog"
      needParent>
    </yxtbiz-select-kng-catelog-source>
    <component v-else :is="comp.compName" :value="list.filter(i => i.sourceType === sourceType)" @select="select" v-bind="comp.params" :selectable="selectable"></component>
  </div>
</template>

<script>
import selectExams from '../../../exam-selector/src/selectExams.vue';
import selectCers from '../../../certificate-selector/src/selectCers.vue';
import selectQuesLib from '../../../question-selector/src/selectQuesLib.vue';
import Component from 'yxt-biz-pc/examples/pages/en-US/component.vue';
import { components } from '../config';
export default {
  data() {
    return {
      list: [],
      components
    };
  },
  model: {
    // model选项可以自定义prop名称和event名称
    prop: 'value',
    event: 'select'
  },
  props: {
    value: {
      type: Array,
      default: []
    },
    sourceType: {
      type: String,
      default: ''
    },
    disabledIds: {
      type: Array,
      default: []
    }
  },
  components: {
    selectQuesLib,
    selectExams,
    selectCers,
    Component
  },

  computed: {
    // 当前选择资源组件
    comp() {
      return components.find(i=>i.sourceType === this.sourceType);
    },
    selectedIds() {
      return new Set(this.list.map(i => i.id));
    },

    cateList() {
      return this.list.filter(i => i.sourceType === '4').map(item => item.id);
    },
    tableRef() {
      if (this.sourceType === '4') {
        return this.$refs.catelogTree.$refs.kngTree;
      }
      this.$refs.table.$refs.stb;
    }
  },
  watch: {
    value(val) {
      this.list = val;
    }
  },
  created() {
    this.list = this.value;
  },
  mounted() {
  },

  methods: {
    selectable(row) {
      return !this.disabledIds.includes(row.id);
    },
    // 选中
    select(arr) {
      arr.forEach(item => {
        if (!this.selectedIds.has(item.id)) {
          this.list.push(
            Object.assign(
              {
                sourceType: this.sourceType
              },
              item
            )
          );
        }
      });
      this.list = this.list.filter(item => {
        return !(item.sourceType === this.sourceType && !arr.find(i => i.id === item.id));
      });
      this.$emit('update:value', this.list);
    },
    // 选中、取消选中课程分类
    checkCatelog([item, data]) {
      // 勾选后自动选中子节点
      if (data.checkedKeys.includes(item.id)) {
        let selected = [this.getItem(item)];
        item.children && item.children.forEach(i => {
          !this.disabledIds.includes(i.id) && !this.selectedIds.has(i.id) && selected.push(this.getItem(i));
        });
        this.list = this.list.concat(selected);
      } else {
        // 取消勾选
        let ids = [item.id];
        if (item.children) {
          item.children.forEach(i => {
            if (this.selectedIds.has(i.id) && !this.disabledIds.includes(i.id)) {
              ids.push(i.id);
            }
          });
        }
        this.list = this.list.filter(i=>!(i.sourceType === this.sourceType && ids.includes(i.id)));
      }
      this.$emit('update:value', this.list);
    },
    getItem(item) {
      return {
        id: item.id,
        name: item.label,
        sourceType: this.sourceType
      };
    },
    // 取消选中
    toggleRowSelection(val) {
      if (!val) {
        this.sourceType === '4' ? this.tableRef.setCheckedNodes([]) : this.tableRef.clearSelection();
        this.list = [];
      } else {
        this.sourceType === '4' ? this.tableRef.cancelTreeSelection(val) : this.tableRef.toggleRowSelection(val);
        this.list = this.list.filter(item => val.id !== item.id);
      }
    }
  }
};
</script>