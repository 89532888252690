var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "yxt-form-item",
    { attrs: { label: _vm.config.labelName, prop: _vm.config.name } },
    [
      _c(
        "yxt-select",
        {
          staticClass: "width-percent-100",
          attrs: {
            filterable: "",
            size: "small",
            disabled: _vm.disabled || _vm.loading,
          },
          model: {
            value: _vm.value_,
            callback: function ($$v) {
              _vm.value_ = $$v
            },
            expression: "value_",
          },
        },
        _vm._l(_vm.list, function (item) {
          return _c("yxt-option", {
            key: item.id,
            attrs: { label: item.label, value: item.id },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }