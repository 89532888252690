<!-- 创建时间2023/11/17 14:19:22 -->
<!-- 创建人：Zhao bing bing -->
<!-- 组件描述：查找课件-->
<template>
  <KngRelated type="searchCourseware" v-bind="$attrs" v-on="$listeners"/>
</template>

<script>
import KngRelated from '../common/kng/kng-releated.vue';
export default {
  name: 'KngSearchCourseware',
  components: {
    KngRelated
  }
};
</script>