var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "hand" },
    [
      _c(
        "yxt-badge",
        {
          staticClass: "yxtbiz-nav-top-notice-bell",
          attrs: { value: _vm.noticeCount, max: 99, hidden: !_vm.noticeCount },
          nativeOn: {
            click: function ($event) {
              return _vm.page2NoticeCenter.apply(null, arguments)
            },
          },
        },
        [
          _c("yxt-svg", {
            staticClass: "hover-primary-6-i color-gray-9",
            attrs: {
              "remote-url": _vm.mediaPath,
              width: "18px",
              height: "18px",
              "icon-class": "nav-msg",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }