import { udpApi } from 'yxt-biz-pc/packages/api';

/**
 * 获取岗位树
 * @param query
 * @return {*}
 */
export const getPositionTree = (query) => {
  let path = 'postioncatalogs';
  if (query.isAuthInterface) {
    // 采用新的岗位树接口
    path = 'postioncatalogs/permission/tree';
  }
  return udpApi.get(path, {params: query});
};
