import { i18n } from 'yxt-biz-pc/packages/common-util/changeLangs';
import Spares from './view/spares.vue'; // 人
import CheckedList from './components/checkedlist.vue';
import EventBus from './eventBus';

// 此处label用函数执行，原因是在这里使用i18n可能国际化json还没有下载下来会导致不翻译，直接写成方法在render的时候执行
export const Tabs = [
  {
    label: () => i18n.t('pc_biz_udp_lbl_quickSelect').d('快速选人'),
    value: 'spares',
    component: Spares
  }
];

export default {
  components: {
    CheckedList
  },
  mixins: [],
  data() {
    return {
      selectedSpares: [],
      selectDataStatus: {
        outside: false,
        inside: false
      } // 是否是外部改变，这个参数用于判断是不是直接改变了v-model的变量还是通过内部change触发改变的
    };
  },
  props: {
    selected: {
      type: Array,
      default: []
    },
    multiple: {
      // 是否多选
      type: Boolean,
      default: true
    },
    limit: {
      // 限制数量，0为不限制
      type: Number,
      default: 0
    }
  },
  methods: {
    selectChange(selected) {
      const selectedData = [];
      for (const key in selected) {
        if (selected[key].selected.length) {
          selectedData.push(selected[key]);
        }
      }
      this.selectedSpares = selectedData;
      this.$emit('change', selectedData);
    },
    selectedChange(deleted) {
      EventBus.$emit('extend-field-change', deleted);
    }
  },
  render() {
    return (
      <div class="yxtbiz-range-selector">
        <div class="yxtbiz-range-selector-main" style={{ height: '100%' }}>
          <div class="yxtbiz-range-selector-main-content">
            <Spares
              ref='spares'
              limit={this.limit}
              value={this.selected}
              selectData={this.selectData}
              onChange={this.selectChange}
            />
          </div>
          <div class="yxtbiz-range-selector-main-checkedlist">
            <CheckedList limit={this.limit} selected={this.selectedSpares} onChange={this.selectedChange} />
          </div>
        </div>
      </div>
    );
  }
};
