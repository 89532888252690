var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yxtbiz-ai-robot__search-teas" },
    [
      _vm.dataList.length === 0
        ? _c("EmptyData")
        : [
            _c(
              "div",
              {
                staticClass: "pb12 ph20 color-gray-7",
                class:
                  _vm.size === "small"
                    ? "font-size-14 lh22"
                    : "font-size-16 lh24",
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$t("pc_biz_ote_lbl_search_content", [
                        _vm.$t("pc_audit_tit_audit").d("审核"),
                      ])
                    ) +
                    "\n    "
                ),
              ]
            ),
            _vm._l(_vm.dataList.slice(0, 3), function (list) {
              return _c(
                "div",
                {
                  key: list.execId,
                  staticClass: "ph8",
                  on: {
                    click: function ($event) {
                      return _vm.toDetail(list)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "yxtbiz-ai-robot__teas-list" }, [
                    _c(
                      "div",
                      {
                        staticClass: "ellipsis",
                        class:
                          _vm.size === "small"
                            ? "font-size-14 lh22"
                            : "font-size-16 lh24",
                      },
                      [
                        _c(
                          "yxtf-tag",
                          {
                            staticClass: "v-mid",
                            attrs: {
                              type: _vm.auditTagStatus[list.myStatus][1],
                              size: "mini",
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(_vm.auditTagStatus[list.myStatus][0])
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                        _c("span", { staticClass: "v-mid color-gray-10" }, [
                          _vm._v(_vm._s(list.tmplName)),
                        ]),
                      ],
                      1
                    ),
                    _vm.size !== "small"
                      ? _c(
                          "div",
                          { staticClass: "font-size-12 lh20 color-gray-7 mt8" },
                          [
                            _c(
                              "span",
                              { staticStyle: { "margin-right": "20px" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("pc_biz_survey_lbl_type" /* 名称 */)
                                  ) +
                                    "：" +
                                    _vm._s(list.tmplName)
                                ),
                              ]
                            ),
                            _c("span", [_vm._v(_vm._s(list.initUser))]),
                            _c("yxt-divider", {
                              attrs: { direction: "vertical" },
                            }),
                            _c("span", [
                              _vm._v(
                                _vm._s(list.initTime + " " + _vm.$t("pc_setup"))
                              ),
                            ]),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          { staticClass: "font-size-12 lh20 color-gray-7 mt4" },
                          [
                            _c(
                              "span",
                              { staticStyle: { "margin-right": "20px" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("pc_biz_survey_lbl_type" /* 名称 */)
                                  ) +
                                    "：" +
                                    _vm._s(list.tmplName)
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "mt4 search-audit-small" },
                              [
                                _c("span", { staticClass: "ellipsis" }, [
                                  _vm._v(_vm._s(list.initUser)),
                                ]),
                                _c("yxt-divider", {
                                  attrs: { direction: "vertical" },
                                }),
                                _c(
                                  "span",
                                  { staticClass: "search-audit-small-nowrap" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        list.initTime + " " + _vm.$t("pc_setup")
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                  ]),
                ]
              )
            }),
            _vm.datas.paging.count > 3
              ? _c(
                  "div",
                  {
                    staticClass:
                      "text-center color-gray-8 hand yxtbiz-ai-robot__teas-more",
                    on: { click: _vm.toMore },
                  },
                  [
                    _c("span", { staticClass: "lh22 v-mid" }, [
                      _vm._v(
                        _vm._s(_vm.$t("pc_biz_ote_lbl_viewmore" /* 查看更多 */))
                      ),
                    ]),
                    _c("yxt-svg", {
                      staticClass: "v-mid",
                      attrs: {
                        width: "16px",
                        height: "16px",
                        "icon-class": "arrow_right",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }