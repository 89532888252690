var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yxtbiz-global-notice" },
    [
      _vm.isOptsBig
        ? _c("opts-big-dialog", {
            attrs: { visible: _vm.isOptsBig, content: _vm.optsNotice },
            on: {
              "update:visible": function ($event) {
                _vm.isOptsBig = $event
              },
              closeNotice: _vm.closeNotice,
              check: _vm.showDetail,
            },
          })
        : _vm._e(),
      _vm.isOptsSmall
        ? _c("opts-small-dialog", {
            attrs: { visible: _vm.isOptsSmall, content: _vm.optsNotice },
            on: {
              "update:visible": function ($event) {
                _vm.isOptsSmall = $event
              },
              closeNotice: _vm.closeNotice,
              check: _vm.showDetail,
            },
          })
        : _vm._e(),
      _vm.notice && _vm.notice.content && _vm.notice.title
        ? _c(
            "yxtf-dialog",
            {
              staticClass: "yxtbiz-nav-global-notice-vs",
              attrs: {
                visible: _vm.dialogVisible,
                "show-close": false,
                "close-on-click-modal": false,
                top: "100px",
                "padding-size": "empty",
                width: "680px",
                "modal-append-to-body": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "yxt-button",
                {
                  staticClass: "yxtbiz-nav-global-notice-vs-close",
                  attrs: { type: "text" },
                  on: {
                    click: function ($event) {
                      return _vm.closeNotice(_vm.notice)
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass: "yxt-icon-close",
                    attrs: { width: "16px", height: "16px" },
                  }),
                ]
              ),
              _c("img", {
                attrs: {
                  src: _vm.notice.styleUrl,
                  width: "680",
                  height: "176",
                },
              }),
              _c(
                "div",
                { staticClass: "yxtbiz-nav-global-notice-vs-wrapper" },
                [
                  _c("yxt-scrollbar", { attrs: { "fit-height": true } }, [
                    _c(
                      "div",
                      { staticClass: "yxtbiz-nav-global-notice-vs-header" },
                      [_vm._v(_vm._s(_vm.notice.title))]
                    ),
                    _c("div", {
                      staticClass: "yxtbiz-nav-global-notice-vs-content",
                      domProps: { innerHTML: _vm._s(_vm.notice.content) },
                    }),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "yxtbiz-nav-global-notice-vs-footer" },
                [
                  _vm.isShowDownLoad
                    ? _c("yxt-button", { on: { click: _vm.download } }, [
                        _vm._v(_vm._s(_vm.$t("pc_biz_download_immediate"))),
                      ])
                    : _vm._e(),
                  _c(
                    "yxt-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.showDetail(_vm.notice)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("pc_biz_notice_btn_check")))]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }