<template>
  <div class="yxtbiz-qrcode">
    <template v-if="name">
      <div v-if="isProject" class="yxtbiz-qrcode__project">
        <div class="yxtbiz-qrcode__project-name">{{ name }}</div>
        <div v-if="projectTip" class="yxtbiz-qrcode__project-tip">{{ projectTip }}</div>
      </div>
      <div v-else class="yxtbiz-qrcode__name">{{name}}</div>
    </template>
    <div class="yxtbiz-qrcode__img">
      <div ref="qrcode"></div>
      <div class="d-none" ref="hidQrcode"></div>
    </div>
    <slot name="below-code"></slot>
    <div v-if="!hideLink" class="yxtbiz-qrcode__link">
      <div class="yxtbiz-qrcode__link-text">{{$t('pc_biz_qrcode_lbl_link')}}</div>
      <div class="yxtbiz-qrcode__link-url">
        <input ref="inputUrl" :value="this.url" readonly />
      </div>
      <div class="yxtbiz-qrcode__link-copy color-primary-6" @click="copyUrl">{{$t('pc_biz_qrcode_lbl_copy')}}</div>
    </div>
    <div v-if="!hideDownload" class="yxtbiz-qrcode__download">
      <label>{{$t('pc_biz_qrcode_lbl_download')}}</label>
      <span class="color-primary-6" @click="download(200)">200x200</span>
      <span class="color-primary-6" @click="download(400)">400x400</span>
      <span class="color-primary-6" @click="download(800)">800x800</span>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import QRCode from './qrcode';

export default {
  name: 'YxtbizQrcode',
  props: {
    name: String,
    url: {
      type: String,
      required: true
    },
    size: {
      type: Number,
      validator: (value) => {
        return value === null || value > 0;
      }
    },
    padding: {
      type: Number,
      default: 30,
      validator: (value) => {
        return value === null || value >= 0;
      }
    },
    logo: String,
    logoSize: {
      type: Number,
      default: 0.2,
      validator: (value) => {
        return value > 0 && value <= 0.5;
      }
    },
    hideLink: Boolean,
    hideDownload: Boolean,
    useSvg: Boolean,
    isProject: {
      type: Boolean,
      default: false
    },
    projectTip: String
  },
  data() {
    return {
      qrcode: null,
      logoImg: null
    };
  },
  mounted() {
    this.createQrCode();
  },
  methods: {
    createQrCode() {
      const options = {
        text: this.url,
        padding: this.padding,
        useSVG: this.useSvg
      };
      if (this.size) {
        options.width = this.size;
        options.height = this.size;
      }
      this.qrcode = new QRCode(this.$refs.qrcode, options);
      this.qrcode._oDrawing._fSuccess = () => {
        console.log('二维码生成成功');
        if (this.logo && !this.logoImg) {
          this.getLogoResource();
        }
      };
    },
    getLogoResource() {
      this.logoImg = new Image();
      this.logoImg.onload = () => {
        this.addLogo(this.qrcode._oDrawing._elCanvas);
      };

      axios.get(this.logo, {
        responseType: 'arraybuffer'
      }).then(res => {
        this.logoImg.canDownload = !window.navigator.msSaveBlob; // IE下载不能加logo
        this.logoImg.src = 'data:image/png;base64,' + window.btoa(new window.Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
      }).catch(() => {
        // logo不允许跨域，则logo只能显示，下载的二维码没有logo
        this.logoImg.src = this.logo;
      });
    },
    addLogo(canvas) {
      if (!this.logoImg) {
        return;
      }
      const size = canvas.width * this.logoSize;
      this.logoImg.width = size;
      this.logoImg.height = size;
      canvas.getContext('2d').drawImage(this.logoImg, (canvas.width - size) / 2, (canvas.height - size) / 2, size, size);
    },
    copyUrl() {
      const selection = window.getSelection();
      selection.removeAllRanges();
      this.$refs.inputUrl.select(); // 选择对象
      document.execCommand('Copy'); // 执行浏览器复制命令
      selection.removeAllRanges(); // 清空选择
      this.$message.success(this.$t('pc_biz_qrcode_tip_copySuccess'));
    },
    download(size) {
      this.$refs.hidQrcode.innerHTML = '';
      const hidQrcode = new QRCode(this.$refs.hidQrcode, {
        text: this.url,
        width: size,
        height: size,
        padding: size * 0.1
      });
      hidQrcode._oDrawing._fSuccess = () => { // 二维码加载完成
        if (this.logoImg && this.logoImg.canDownload) {
          this.addLogo(hidQrcode._oDrawing._elCanvas);
        }

        let fileName = this.name && this.name.length > 100 ? this.name.substr(0, 100) : this.name;
        fileName = `${fileName ? fileName + '-' : ''}${size}.png`;

        // canvas转换为blob，canvas.toBlob IE不支持。。。
        const base64 = hidQrcode._oDrawing._elCanvas.toDataURL('image/png');
        const raw = window.atob(base64.split(';base64,')[1]);
        const rawLength = raw.length;
        const uInt8Array = new window.Uint8Array(rawLength);
        for (var i = 0; i < rawLength; ++i) {
          uInt8Array[i] = raw.charCodeAt(i);
        }
        const blob = new window.Blob([uInt8Array], { type: 'image/png' });

        // 下载图片
        if (window.navigator.msSaveBlob) {
          window.navigator.msSaveBlob(blob, fileName);
        } else {
          const aLink = document.createElement('a');
          aLink.download = fileName;
          aLink.href = window.URL.createObjectURL(blob);
          aLink.dispatchEvent(new window.MouseEvent('click', { bubbles: true, cancelable: true, view: window })); // 兼容火狐
        }
      };
    }
  }
};
</script>
