export const bindPlayerCustom = function (player, vueInstance) {
  try {
    // 防止重复初始化，防止点位重复
    if (player.yxtCustom) return
    player.yxtCustom = {
      // 时间轴内容，用于存放打点信息
      timeContainerEl: player.getContainer().querySelector('.jw-slider-container'),
      setCustomCues: setCustomCues.bind(player),
      time: {
        cues: [],
        generateCues: generateCues.bind(player)
      }
    };
    player.yxtCustom.tooltip = new CueTootip(player);
  } catch (e){ }
}
class Cue {
  constructor(cue, duration) {
    this.time = cue.time;
    this.text = cue.text;
    this.url = cue.url;
    this.onClick = cue.onClick;
    this.progress = cue.time * 100 / duration
    this.el = document.createElement("div");
    this.el.className = "jw-custom-cue";
    this.el.style = `left: ${this.progress}%`;
    this.el.time = this.time;
    this.el.progress = this.progress;
  }
} 
class CueTootip {
  constructor(player) {
    const oldTooltip = player.yxtCustom.timeContainerEl && player.yxtCustom.timeContainerEl.querySelector('.jw-custom-tooltip')
    if (oldTooltip) {
      oldTooltip.remove()
    }
    const tooltip  = document.createElement("div");
    tooltip.className = "jw-custom-tooltip";
    tooltip.style = 'display: none;'
    
    const itemEl = document.createElement("div");
    itemEl.className = "jw-custom-tooltip-item";

    const itemImgEl = document.createElement("div");
    itemImgEl.className = "jw-custom-tooltip-item-img";


    const itemTxtEl = document.createElement("div");
    itemTxtEl.className = "jw-custom-tooltip-item-txt";
    const itemTxtTimeEl = document.createElement("div");
    itemTxtTimeEl.className = "jw-custom-tooltip-item-txt-time";
    const itemTxtContentEl = document.createElement("div");
    itemTxtContentEl.className = "jw-custom-tooltip-item-txt-content ellipsis-2";

    const itemTxtArrowEl = document.createElement("div");
    itemTxtArrowEl.className = "jw-custom-tooltip-item-arrow";
    

    itemTxtEl.appendChild(itemTxtTimeEl);
    itemTxtEl.appendChild(itemTxtContentEl);
    itemTxtEl.appendChild(itemTxtArrowEl);

    itemEl.appendChild(itemImgEl);
    itemEl.appendChild(itemTxtEl);
    tooltip.appendChild(itemEl);

    this.el = tooltip;
    this.itemEl = itemEl;
    this.imgEl = itemImgEl;
    this.txtEl = itemTxtEl;
    this.txtTimeEl = itemTxtTimeEl;
    this.txtArrowEl = itemTxtArrowEl;
    this.txtContentEl = itemTxtContentEl;

    player.yxtCustom.timeContainerEl.appendChild(tooltip)
  }
}

const getTimeString =function (time) {
  const h = Math.floor(time / (60 * 60))
  const m = Math.floor((time % (60 * 60)) / 60).toString()
  const s = (time % 60).toString()
  return [h, m, s].filter(Boolean).map(d => d.toString().padStart(2, '0')).join(':')
}

// tooltip允许的最大宽度, 内部图片的宽度
const tooltipMaxWidth = 176;

const excToolTip = function (cue) {
  this.yxtCustom.tooltip.el.style = `display: block; left: ${cue.progress}%`;
  // 先禁止换行，用于计算宽度
  this.yxtCustom.tooltip.itemEl.style = 'white-space: nowrap;';
  this.yxtCustom.tooltip.txtTimeEl.innerText = getTimeString(cue.time);
  this.yxtCustom.tooltip.txtContentEl.innerText = cue.text;
  if (cue.url) {
    this.yxtCustom.tooltip.imgEl.style = `width: ${tooltipMaxWidth}px; height: ${tooltipMaxWidth / 16 * 9}px; display: block; margin: 0px auto; background: url('${cue.url}') no-repeat center;`;
  } else {
    this.yxtCustom.tooltip.imgEl.style = 'width: 0; height: 0;'
  }
  // 定位容错处理
  excTooltipPosition.bind(this)(cue)
  this.yxtCustom.timeContainerEl.querySelector('.jw-icon-tooltip').style = 'display:none;';
}

// 定位容错处理
const excTooltipPosition = function(cue) {
  // // arrow 点位的宽度
  // const arrowWidth = 8;
  // arrow 点位的半度
  const halfArrowWidth =  8 / 2;
  // tooltip style
  let style = '';
  // arrow style
  let arrowStyle = '';
  // time width 时间轴宽度
  const containerWidth = this.yxtCustom.timeContainerEl.offsetWidth
  // tooltip 的宽度
  let itemWidth = this.yxtCustom.tooltip.itemEl.offsetWidth

  // 计算宽度，防止文字挤压，一柱擎天
  if (itemWidth > tooltipMaxWidth) {
    itemWidth = tooltipMaxWidth
    style += `width: ${tooltipMaxWidth}px;`;
  } else {
    style += 'white-space: nowrap;'
  }

  // item的半宽
  let halfItemWidth = itemWidth / 2;

  // cue offset left 点位到左侧开始距离
  const cueOffsetLeft = containerWidth * cue.progress / 100;
  // cue offset rigth 点位到右侧结束距离
  const cueOffsetRight = containerWidth - cueOffsetLeft;

  // // cue offset left 点位到右侧结束距离
  // const cueOffsetRigth = containerWidth - cueOffsetLeft

  // 溢出处理
  if (cueOffsetLeft < halfItemWidth) {
    // 左侧溢出时，则定位到左侧边缘
    style += `left: -${cue.progress}%;`
    // 箭头位置与cue位置一致
    let arrowPosions = cueOffsetLeft - halfArrowWidth;
    // 防止箭头溢出
    if (arrowPosions < halfArrowWidth) arrowPosions = halfArrowWidth;
    arrowStyle = `left: ${arrowPosions}px`
  } else if (cueOffsetRight < halfItemWidth) {
    // 右侧溢出时，则定位到左侧边缘
    style += `right: ${cue.progress}%;`
    // 防止箭头溢出
    // 箭头位置与cue位置一致
    let arrowPosions = cueOffsetRight - halfArrowWidth;
    // 防止箭头溢出
    if (arrowPosions < halfArrowWidth) arrowPosions = halfArrowWidth;
    arrowStyle = `right: ${arrowPosions}px`
  }  else {
    // 正常情况居中对齐
    style += `left: -${halfItemWidth}px;`
    arrowStyle = `left: ${halfItemWidth - halfArrowWidth}px`
  }
  // tooltip style
  this.yxtCustom.tooltip.itemEl.style = style;

  // arrow style 处理底部箭头
  this.yxtCustom.tooltip.txtArrowEl.style = arrowStyle;

}

// 设置打点文案与图片
// cues :[{time:100, text:'123', url: 'https://...'}]
const setCustomCues = function (cues){
  if (!this.yxtCustom || !this.yxtCustom.time) return;
  if (cues && cues.length) {
    const list = cues.map(d => new Cue(d, this.getDuration()));
    this.yxtCustom.time.cues.push(...list);
  } else {
    // 传入空时，销毁所有打点
    this.yxtCustom.time.cues.forEach((cue)=>{
      cue.el.parentNode && cue.el.parentNode.removeChild(cue.el)
    })
    this.yxtCustom.time.cues = [];
  }
  this.yxtCustom.time.generateCues();
}


const generateCues = function () {
  if (!this.yxtCustom || !this.yxtCustom.time) return;
  const cues = this.yxtCustom.time.cues;
  cues.forEach(cue => {
    this.yxtCustom.timeContainerEl.appendChild(cue.el);
    cue.el.addEventListener('mouseover', (event) => {
      excToolTip.bind(this)(cue)
      event.stopPropagation();
      event.preventDefault();

    });
    cue.el.addEventListener('mouseout', (event) => {
      this.yxtCustom.tooltip.el.style = 'display: none;'
      this.yxtCustom.timeContainerEl.querySelector('.jw-icon-tooltip').style.display = '';
      event.stopPropagation();
      event.preventDefault();
    });
    
    // cue.el.addEventListener('click', (event) => {
    //   if (cue.onClick && typeof cue.onClick === 'function') {
    //     // onClick成功执行，并向外抛出回调事件时，不执行seek
    //     if (!cue.onClick(cue)) this.seek(cue.time);
    //   } else {
    //     this.seek(cue.time);
    //   } 
    // });
    // 使用此事件触发点击效果
    // 播放器监听此事件，用于阻止其他默认事件
    cue.el.addEventListener('pointerdown', (event) => {
      if (cue.onClick && typeof cue.onClick === 'function' && cue.onClick(cue)) {
        event.stopPropagation();
        event.preventDefault();
      }
    });
  })
}
