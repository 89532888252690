var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "ai-robot-chats",
      class: { "ai-robot-chats--small": _vm.smallClient },
    },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        (_vm.visible || !_vm.smallClient) && !_vm.isCollapse
          ? _c(
              "div",
              {
                staticClass: "broadside-content",
                class: { "broadside-content--small": _vm.smallClient },
              },
              [
                _c(
                  "div",
                  { staticClass: "flex-1 height-0 ai-robot-chat__container" },
                  [
                    _vm.smallClient
                      ? _c(
                          "div",
                          {
                            staticClass: "back-wrap flex align-items-center",
                            on: { click: _vm.back },
                          },
                          [
                            _c("i", {
                              staticClass:
                                "yxt-icon-arrow-left font-size-20 font-bold",
                            }),
                            _c("span", { staticClass: "ml2" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t(
                                      "pc_comp_pageHeader_title" /** 返回 */
                                    )
                                  ) +
                                  "\n          "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "ai-robot-info" },
                      [
                        _vm.settings.assistHeadImg
                          ? _c("yxtf-portrait", {
                              attrs: {
                                size: "medium",
                                username: "AI",
                                imgUrl: _vm.settings.assistHeadImg,
                              },
                            })
                          : _vm._e(),
                        _c("span", { staticClass: "mt12 ai-title" }, [
                          _vm._v(_vm._s(_vm.title)),
                        ]),
                        _c(
                          "yxt-button",
                          {
                            staticClass: "ai-robot-create__btn",
                            attrs: { type: "second" },
                            on: { click: _vm.createChat },
                          },
                          [
                            _c("yxt-svg", {
                              staticClass: "v-mid mb4",
                              attrs: {
                                width: "14px",
                                height: "14px",
                                "icon-class": "plus",
                              },
                            }),
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t("pc_biz_ai_createChat" /** 新建对话 */)
                                ) +
                                "\n          "
                            ),
                          ],
                          1
                        ),
                        _c(
                          "yxt-divider",
                          { attrs: { "line-color": "#D9D9D9" } },
                          [
                            _vm.chatList.length
                              ? [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "pc_biz_ai_displayLimit_tips",
                                          [30] /** 仅展示最近{0}条对话 */
                                        )
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              : [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "pc_biz_ai_no_history" /** 暂无历史对话 */
                                        )
                                      ) +
                                      "\n            "
                                  ),
                                ],
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex-1 height-0 overflow-hidden" },
                      [
                        _c("yxt-scrollbar", { attrs: { "fit-height": true } }, [
                          _c(
                            "ul",
                            { staticClass: "ai-robot-chat__list" },
                            _vm._l(_vm.chatList, function (chat) {
                              return _c(
                                "li",
                                {
                                  key: chat.chatSessionId,
                                  staticClass: "flex",
                                  class: {
                                    active: _vm.chatId === chat.chatSessionId,
                                    "chat-edit": chat.isEdit,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectChat(chat)
                                    },
                                  },
                                },
                                [
                                  !_vm.isBatchDelete
                                    ? _c("yxt-svg", {
                                        staticClass: "chat-icon mr12 mt10",
                                        attrs: {
                                          "remote-url": _vm.mediaPath,
                                          "icon-class": "ai-chat-icon",
                                          width: "24px",
                                          height: "24px",
                                        },
                                      })
                                    : _c("yxt-checkbox", {
                                        staticClass: "batch-checkbox",
                                        attrs: { size: "medium" },
                                        on: { change: _vm.handleChecked },
                                        nativeOn: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                          },
                                        },
                                        model: {
                                          value: chat.selected,
                                          callback: function ($$v) {
                                            _vm.$set(chat, "selected", $$v)
                                          },
                                          expression: "chat.selected",
                                        },
                                      }),
                                  _c(
                                    "div",
                                    { staticClass: "flex-1 chat-content" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex align-items-center chat-name__wrap",
                                        },
                                        [
                                          !chat.isEdit
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "standard-size-14 ellipsis flex-1",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(chat.name) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              )
                                            : _c("yxt-input", {
                                                staticClass:
                                                  "chat-name__input flex-1",
                                                attrs: {
                                                  size: "small",
                                                  placeholder: _vm.$t(
                                                    "pc_biz_lbl_please_input_name" /** 请输入名称 */
                                                  ),
                                                  maxlength: "50",
                                                  "show-word-limit": "",
                                                },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                  },
                                                },
                                                model: {
                                                  value: chat.name,
                                                  callback: function ($$v) {
                                                    _vm.$set(chat, "name", $$v)
                                                  },
                                                  expression: "chat.name",
                                                },
                                              }),
                                          !chat.isEdit && !_vm.isBatchDelete
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "operate-icons color-gray-8 align-items-center standard-size-16",
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "yxt-icon-edit",
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.edit(chat)
                                                      },
                                                    },
                                                  }),
                                                  _c(
                                                    "yxtf-popconfirm",
                                                    {
                                                      attrs: {
                                                        placement: "top",
                                                        title: _vm.$t(
                                                          "pc_biz_ai_deleteChat_confirm" /** 删除后无法恢复，是否继续删除？ */
                                                        ),
                                                        "confirm-button-text":
                                                          _vm.$t(
                                                            "pc_biz_ai_delete" /** 删除 */
                                                          ),
                                                      },
                                                      on: {
                                                        confirm: function (
                                                          $event
                                                        ) {
                                                          return _vm.deleteChat(
                                                            chat
                                                          )
                                                        },
                                                      },
                                                      nativeOn: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "yxt-icon-delete ml8",
                                                        attrs: {
                                                          slot: "reference",
                                                        },
                                                        slot: "reference",
                                                      }),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : chat.isEdit
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "f-mt5 flex align-items-center operate-icons operate-icons--edit standard-size-16",
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "yxt-icon-check mr4",
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.saveName(
                                                          chat
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _c("i", {
                                                    staticClass:
                                                      "yxt-icon-close",
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.saveName(
                                                          chat,
                                                          false
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "color-gray-7 standard-size-12 mt4",
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(chat.lastTalkTime) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "batch-container color-gray-9" },
                  [
                    !_vm.isBatchDelete
                      ? [
                          _c("div", { staticClass: "flex-1" }, [
                            _c(
                              "span",
                              {
                                staticClass: "hover-primary-6",
                                on: { click: _vm.batchDelete },
                              },
                              [
                                _vm.chatList.length
                                  ? _c("i", {
                                      staticClass:
                                        "yxt-icon-delete ml8 hand font-size-16",
                                    })
                                  : _vm._e(),
                                _vm.chatList.length
                                  ? _c("span", { staticClass: "hand" }, [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "pc_biz_ai_batchDelete" /** 批量删除 */
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                          !_vm.smallClient
                            ? _c("yxt-svg", {
                                staticClass: "hand hover-primary-6",
                                attrs: {
                                  "remote-url": _vm.mediaPath,
                                  "icon-class": "ai-robot-retract",
                                  width: "20px",
                                  height: "20px",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    _vm.isCollapse = true
                                  },
                                },
                              })
                            : _vm._e(),
                        ]
                      : [
                          _c(
                            "div",
                            { staticClass: "flex-1" },
                            [
                              _c(
                                "yxt-checkbox",
                                {
                                  attrs: { indeterminate: _vm.isIndeterminate },
                                  on: { change: _vm.handleCheckAllChange },
                                  model: {
                                    value: _vm.checkAll,
                                    callback: function ($$v) {
                                      _vm.checkAll = $$v
                                    },
                                    expression: "checkAll",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "pc_biz_udp_lbl_selectAll" /** 全选 */
                                        )
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "batch-confirm" },
                            [
                              _c(
                                "yxt-button",
                                {
                                  staticClass: "color-gray-9",
                                  attrs: { type: "text2" },
                                  on: { click: _vm.cancel },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "pc_biz_ai_btn_cancel" /** 取消 */
                                        )
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _c("yxt-divider", {
                                attrs: { direction: "vertical" },
                              }),
                              _c(
                                "yxtf-popconfirm",
                                {
                                  attrs: {
                                    placement: "top",
                                    title: _vm.$t(
                                      "pc_biz_ai_deleteChat_confirm" /** 删除后无法恢复，是否继续删除？ */
                                    ),
                                    "confirm-button-text": _vm.$t(
                                      "pc_biz_ai_delete" /** 删除 */
                                    ),
                                  },
                                  on: { confirm: _vm.submitBatchDelete },
                                },
                                [
                                  _c(
                                    "yxt-button",
                                    {
                                      staticClass: "color-gray-9",
                                      attrs: {
                                        slot: "reference",
                                        type: "text2",
                                        disabled: !_vm.checkedChatList.length,
                                      },
                                      slot: "reference",
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "pc_biz_ai_confirmDelete" /** 确认删除 */
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                  ],
                  2
                ),
              ]
            )
          : _vm._e(),
      ]),
      _vm.isCollapse && !_vm.smallClient
        ? _c(
            "div",
            {
              staticClass: "ai-robot-chats__expand",
              on: {
                click: function ($event) {
                  _vm.isCollapse = false
                },
              },
            },
            [
              _c("div", { staticClass: "expand-text" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("pc_comp_sideBar_open" /** 展开 */)) +
                    "\n    "
                ),
              ]),
              _c("yxt-svg", {
                attrs: {
                  "remote-url": _vm.mediaPath,
                  "icon-class": "ai-robot-expand",
                  width: "20px",
                  height: "20px",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }