<!-- 追踪项目 -->
<template>
  <article class="yxtbiz-ai-robot-track-projectplan">
    <EmptyData v-if="!isNotEmpty" />
    <template v-else>
      <header
        :class="[
          'color-gray-7',
          size === 'small' ? 'font-size-14 lh24' : 'font-size-16 lh28'
        ]"
      >
        {{ $t('pc_biz_found_projectsplan' /* 为您找到以下培训计划：*/) }}
      </header>
      <main>
        <div class="mb4" v-if="isShowTab">
          <yxtf-tag
            effect="point"
            :type="selectTab === 1 ? 'info' : ''"
            @click="selectTab = 0"
            >{{
              $t('pc_biz_lbl_organizationaltrainplan' /* 组织培训计划*/)
            }}</yxtf-tag
          >
          <yxtf-tag
            effect="point"
            :type="selectTab === 0 ? 'info' : ''"
            @click="selectTab = 1"
            >{{
              $t('pc_biz_lbl_Individualtrainplan' /* 个人培训计划*/)
            }}</yxtf-tag
          >
        </div>
        <ul>
          <li
            v-for="project in projectList"
            :key="project.projectId"
            @click="handleProjectClick(project)"
            class="yxtbiz-ai-robot-track-projectplan__item"
          >
            <div class="over-hidden">
              <div
                :class="[
                  size === 'small'
                    ? 'ellipsis-2 font-size-14 lh22'
                    : 'ellipsis font-size-16  lh24',
                  'color-gray-10'
                ]"
              >
                <yxt-tag
                  :type="project.showStatus === 1 ? '' : 'info'"
                  class="mr6 yxtbiz-ai-robot-track-projectplan__item-tag"
                  size="mini"
                  >{{ filterProjectStatus(project.showStatus) }}</yxt-tag
                >
                <yxt-tag
                  v-if="project.delayStatus === 1"
                  type="danger"
                  class="mr6 yxtbiz-ai-robot-track-projectplan__item-tag"
                  size="mini"
                  >{{ $t('pc_biz_lbl_delay' /* 已延期*/) }}</yxt-tag
                >
                {{ project.planName }}
              </div>
              <div
                :class="[
                  size === 'small' ? 'mt4' : 'mt8',
                  'yxtbiz-flex-center lh20 color-gray-7 font-size-12'
                ]"
              >
                <div>
                  {{ $t('pc_biz_lbl_completionStatus' /* 完成状态：*/) }}
                  {{
                    project.finishedStatus
                      ? $t('pc_biz_study_lbl_complete' /* 已完成*/)
                      : $t('pc_biz_study_lbl_uncompleted' /* 未完成*/)
                  }}
                </div>
                <yxt-divider direction="vertical"></yxt-divider>
                <div>
                  {{ $t('pc_biz_lbl_finishRate' /* 完成率：*/) }}
                  {{ project.completeRate?project.completeRate:0 }}%
                </div>
              </div>
            </div>
          </li>
        </ul>
        <ViewMoreButton v-if="count > 3" @click="handleViewMoreClick" />
      </main>
    </template>
  </article>
</template>

<script>
import ViewMoreButton from '../common/view-more-button';
import EmptyData from '../common/empty-data.vue';
import utils from 'yxt-biz-pc/packages/common-util/utils.js';
import propsMixin from '../common/props-mixin.js';
import qs from 'qs';

export default {
  name: 'TrackProjectPlan',
  components: {
    ViewMoreButton,
    EmptyData
  },
  mixins: [propsMixin],
  data() {
    return {
      selectTab: 0
    };
  },
  created() {},
  computed: {
    isShowTab() {
      return (
        this.datas.orgPlanList.length > 0 && this.datas.perPlanList.length > 0
      );
    },
    count() {
      return this.dataList.length;
    },
    dataList() {
      return (
        (this.isSelectedOne
          ? this.datas.orgPlanList
          : this.datas.perPlanList) || []
      );
    },
    projectList() {
      return this.dataList.slice(0, 3) || [];
    },
    isSelectedOne() {
      return this.selectTab === 0 && this.datas.orgPlanList.length > 0;
    },
    isNotEmpty() {
      return (
        this.datas &&
        ((this.datas.orgPlanList && this.datas.orgPlanList.length) ||
          (this.datas.perPlanList && this.datas.perPlanList.length))
      );
    }
  },
  methods: {
    filterProjectStatus(status) {
      const aMap = {
        0: this.$t('pc_biz_study_lbl_notyetgegun' /* 未开始 */),
        1: this.$t('pc_o2o_lbl_ongoing' /* 进行中 */),
        2: this.$t('pc_o2o_lbl_projectover' /* 已结束 */)
      };
      return aMap[status];
    },
    getDateTimeFormatted: utils.getDateTimeFormatted,
    handleProjectClick(project) {
      const url = `${window.location.origin}/o2o/#/checkPlan?${qs.stringify({
        id: project.planId,
        isEdit: 'detail',
        showCreate: false
      })}`; // 项目详情地
      window.open(url, '_blank');
    },
    handleViewMoreClick() {
      const {
        projectPlanRunningStatus, // 计划执行状态，1：未开始、2：进行中、3：已结束
        projectPlanApproveStatus, // 计划的审批状态，1：待审核、2：审核中、3. 已通过、4.未通过
        projectPlanFinishStatus, // 计划完成状态，1：未完成 2. 已完成
        ifPlanDelay, // 计划是否延期，1：延期、2：未延期
        searchKey // 培训计划搜索的关键词
      } = this.params;
      let projectListUrl;
      let auditStatus = '';
      if (projectPlanApproveStatus) {
        auditStatus = Number(projectPlanApproveStatus) - 1;
      }
      let showStatus = '';
      if (projectPlanRunningStatus) {
        showStatus = Number(projectPlanRunningStatus) - 1;
      }
      let finishedStatus = '';
      if (projectPlanFinishStatus) {
        finishedStatus = Number(projectPlanFinishStatus) - 1;
      }
      let delayStatus = '';
      if (ifPlanDelay) {
        delayStatus = Number(ifPlanDelay) - 1;
      }
      if (this.isSelectedOne) {
        projectListUrl = `${window.location.origin}/o2o/#/plan?${qs.stringify({
          keyword: searchKey,
          auditStatus,
          showStatus,
          delayStatus,
          finishedStatus
        })}`;
      } else {
        projectListUrl = `${
          window.location.origin
        }/o2o/#/plan/personal?${qs.stringify({
          keyword: searchKey,
          auditStatus,
          showStatus,
          delayStatus,
          finishedStatus
        })}`;
      }

      window.open(projectListUrl, '_blank');
    }
  }
};
</script>

<style lang="scss" scoped>
.yxtbiz-ai-robot-track-projectplan {
  &__cover {
    width: 112px;
    height: 64px;
    border-radius: 4px;
    flex-shrink: 0;
  }

  > header {
    padding-left: 12px;
    margin-bottom: 12px;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 12px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background: #f4f5f6;
    }
  }

  &__item-tag {
    vertical-align: text-top;
  }

  .yxtf-tag {
    border-radius: 8px;
}

}
</style>