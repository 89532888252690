<template>
  <yxt-row type='flex' class="yxt-user-selector-container yxt-user-multi-container">
    <div class="yxtbiz-user-search-wrap">
      <yxtbiz-group-org-select 
        class='mr12'
        size="small"
        :functionCode="functionCode"
        :dataPermissionCode="dataPermissionCode"
        @change="handleOrgChange"
        v-if='enableGroupCorp && visibleOrgSelector'
        :selectFirst='true'
        :clearable="false">
        </yxtbiz-group-org-select>
      <yxt-input
        class="keyword"
        size="small"
        @keyup.enter.native="getLevelData(true)"
        v-model="params.name"
        :placeholder="$t('biz_udp_enter_occupationname')"
        style="width: 240px;"
        searchable
        @search='getLevelData(true)'
      >
      </yxt-input>
    </div>
    <yxt-table
      :ref="refTable"
      class="mt16 yxt-user-table"
      :height="tableHeight"
      @select="selectLevel"
      @select-all="selectAllLevel"
      row-key="id"
      v-loading="loading"
      :data="level"
      style="width:100%;"
    >
      <yxt-table-column
        v-if="multiple"
        :show-overflow-tooltip="false"
        class-name="yxtbiz-table-selection"
        type="selection"
        width="42"
        reserve-selection
        clear-padding="right"
      ></yxt-table-column>
      <yxt-table-column
        v-else
        :show-overflow-tooltip="false"
        width="40"
      >
        <template slot-scope="scope">
          <yxt-radio v-model="singleSelect" @change="selectLevel(scope.row.id, scope.row)" :label="scope.row.id"></yxt-radio>
        </template>
      </yxt-table-column>
      <yxt-table-column
        width="18"
        align="right"
        clear-padding="left-right"
        v-if="multiple"
        >
        <template slot="header">
          <yxt-dropdown placement="bottom-start" @command="tableHeaderCommand">
              <span> </span>
              <yxt-dropdown-menu slot="dropdown">
                <yxt-dropdown-item command="selectCurrent">{{$t('pc_biz_enroll_select_current_page').d('选择本页')}}</yxt-dropdown-item>
                <yxt-dropdown-item command="selectAll" >{{$t('pc_biz_enroll_select_all').d('选择全部')}}</yxt-dropdown-item>
                <yxt-dropdown-item command="clearSelect" >{{$t('pc_biz_enroll_clear_all').d('清空所选')}}</yxt-dropdown-item>
              </yxt-dropdown-menu>
          </yxt-dropdown>
        </template>
      </yxt-table-column>
      <yxt-table-column
        prop="name"
        show-overflow-tooltip
        :label="$t('biz_udp_occupation_level')"
        min-width="180"
      >
      </yxt-table-column>
      <template slot="empty">
        <div style="line-height:normal">
          <yxt-empty />
        </div>
      </template>
    </yxt-table>
    <yxt-row type='flex' align='middle' class="yxtbiz-user-selector-mask mt16">
      <yxt-row class="col-flex-1" type='flex' align='middle'>
      </yxt-row>
      <yxt-pagination
        small
        class="pages"
        :page-size="params.limit"
        :page-sizes="[5, 10, 20, 30, 50, 100]"
        @size-change="handleSizeChange"
        :current-page.sync="currentPage"
        layout="total, prev, pager, next"
        @current-change="handleCurrentChange"
        :total="total"
        simple-total
      >
      </yxt-pagination>
    </yxt-row>
  </yxt-row>
</template>

<script>
import { getLevel } from '../service';
import resizeTable from '../../mixins/resizeTable';
import authMixin from '../../mixins/auth';
import commonMixin from '../../mixins/common';
import { Table, TableColumn, Pagination, Empty } from 'yxt-pc';
import { mapGetters } from 'vuex';

export default {
  name: 'Role',
  components: {
    YxtTable: Table,
    YxtTableColumn: TableColumn,
    YxtPagination: Pagination,
    YxtEmpty: Empty
  },
  mixins: [resizeTable, authMixin, commonMixin],
  data() {
    return {
      tableHeight: 0,
      refTable: 'level',
      currentPage: 1,
      total: 0,
      loading: true,
      params: {
        name: '',
        limit: 20,
        offset: 0
      },
      level: []
    };
  },
  created() {
    if (!(this.enableGroupCorp && this.visibleOrgSelector)) this.getLevelData();
  },
  computed: {
    ...mapGetters('navManageStore')
  },
  watch: {
    selectData: {
      deep: true,
      handler() {
        console.log('监听器也触发啦');
        this.performanceUpateSelected('level');
        // 基础组件 toggleRowSelection 有个坑，传入的row必须是当前table所使用的那个数组中的row
        // 否则不管用，列如 persons[0] 与 row哪怕长得一样，persons[0]可以生效而row不生效，故这里
        // 将把获取到的数据进行重新处理
        this.level = [...this.level]; // 一种解决方案，直接把当前table数据重新赋值，即可解决
      }
    }
  },
  methods: {
    tableHeaderCommand(row) {
      this[row]();
    },
    selectCurrent() {
      // 选择本页
      this.selectCurrData('level');
    },
    selectAll() {
      // 选择所有
      this.loadAll();
    },
    clearSelect() {
      // 清空所选
      this.$refs[this.refTable].clearSelection();
      this.$emit('change', [], 'level');
    },
    selectAllLevel(item) {
      this.$emit('change', item, 'level');
    },
    selectLevel(item, row) {
      // 选中数据
      if (Array.isArray(item)) {
        // 多选
        this.$emit('change', item, 'level');
      } else {
        this.$emit('change', [row], 'level');
      }
    },
    handleOrgChange(data) {
      this.params.targetOrgId = data.orgId;
      this.targetOrgName = data.orgName;
      this.params.name = '';
      this.getLevelData();
    },
    async loadAll() {
      this.loading = true;
      const limit = 500;
      const reqCount = Math.ceil(this.total / limit); // 循环次数
      const reqArrs = []; // 请求数组
      for (let i = 0; i < reqCount; i++) {
        const params = Object.assign({}, this.params, {
          limit: limit,
          offset: limit * i
        });
        params.navCode = this.functionCode;
        params.dataPermissionCode = this.dataPermissionCode;
        reqArrs.push(params);
      }
      try {
        const results = await Promise.all(
          reqArrs.map(params => getLevel(params, this.enableGroupCorp))
        );
        const dataSource = [];
        results.forEach(res => {
          dataSource.push(...res.datas);
        });
        let data = dataSource.map(item => {
          item['_type'] = 4;
          item.orgId = this.params.targetOrgId;
          item.orgName = this.targetOrgName;
          return item;
        });
        this.selectAllData(data, 'level');
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    getLevelData(reset = false) {
      this.loading = true;
      if (reset) {
        this.params.offset = 0;
        this.currentPage = 1;
      }
      getLevel(this.params, this.enableGroupCorp).then(res => {
        if (res) {
          this.level = res.datas.map(item =>{
            item['_type'] = 4;
            item.orgId = this.params.targetOrgId;
            item.orgName = this.targetOrgName;
            return item;
          });
          this.total = res.paging.count;
          this.performanceUpateSelected('level');
        }
      }).catch(e => {
        console.error(e);
      }).then(() => {
        this.loading = false;
      });

    },
    handleCurrentChange(val) {
      this.params.offset = (val - 1) * this.params.limit;
      this.getLevelData();
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.params.limit = val;
      this.getLevelData();
    }
  }
};
</script>
